import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import {
    Form,
    Row,
    Col,
    Button,
    Table,
    Modal
} from 'antd';
import { expenseOrderDetailRequest, listByOrderNoRequest } from '../../../api/api'
import Mechanism from './mechanism'
import './style.less'
const CostDetail = (props) => {

    let [count, setCount] = useState(1);
    const [lists, setLists] = useState([])
    const [data, setData] = useState(null)
    const [operate, setOperate] = useState(null)
    const [detailVisible, setDetailVisible] = useState(false);
    const [row, setRow] = useState(null)
    const navigate = useNavigate()
    const { state } = useLocation();
    useEffect(() => {
        console.log(props)
        expenseOrderDetail()
        listByOrderNo()
    }, [])
    const listByOrderNo = () => {

        (async _ => {
            return await listByOrderNoRequest({
                orderNo: state && state.costRuleNo,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setOperate(res.data[0])
            } else {

            }
        });
    }
    const expenseOrderDetail = () => {
        (async _ => {
            return await expenseOrderDetailRequest({
                costRuleNo: state && state.costRuleNo,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                res.data.fundCostDetails.map((v) => {
                    v.payeeMerchantNames = [v.payeeMerchantNames]
                    v.typeNames = [v.businessTypeNames]
                    v.businessTypeNosArry = [v.businessTypeNos]
                    v.payeeMerchantCodesArry = [v.payeeMerchantCodes]
                })
                setData(res.data)
                setLists(res.data.fundCostDetails)

            } else {

            }
        });
    }
    const columns = [
        {
            title: '批次号',
            dataIndex: 'costRuleNo',
            key: 'costRuleNo',
            width: 250,
            render: (text, row) => (
                < div >
                    {

                        typeof (row.costRuleNo) == 'string' ?
                            <span>{row.costRuleNo}</span>
                            : ''

                    }
                </div >
            ),
        },
        {
            title: '分账类型',
            dataIndex: 'shareType',
            key: 'shareType',
            render: (text, row) => (
                <div>
                    {row.shareType == 0 ? '按订单' : ''}
                    {row.shareType == 1 ? '按金额' : ''}
                </div>
            ),
        },
        {
            title: '单据类型',
            dataIndex: 'typeNames',
            key: 'typeNames',
            render: (text, row) => (
                <div>
                    {
                        row.typeNames ?
                            <div>
                                {
                                    row.typeNames.map((v) => {
                                        return <div>{v}</div>
                                    })
                                }
                            </div>
                            :
                            <div>--</div>
                    }


                </div>
            ),
        },
        {
            title: '收支类型',
            dataIndex: 'expenseReceiptType',
            key: 'expenseReceiptType',
            render: (text, row) => (
                <div>
                    {row.expenseReceiptType == 0 ? '定额' : ''}
                    {row.expenseReceiptType == 1 ? '比例' : ''}
                    {row.expenseReceiptType == 2 ? '区间比例' : ''}
                    {row.expenseReceiptType == 3 ? '区间定额' : ''}
                </div>
            ),
        },
        {
            title: '单据金额',
            dataIndex: 'expensePaySectionJson',
            key: 'expensePaySectionJson',
            render: (text, row) => (
                <>
                    {
                        row.shareType == 0 ?
                            <div>
                                {
                                    row.expenseReceiptType == 2 || row.expenseReceiptType == 3 ?
                                        <div>
                                            {
                                                JSON.parse(row.expensePaySectionJson).map((v, index) => {
                                                    return <div>
                                                        {row.expenseReceiptType == 2 ? '比例:' : '区间:'}{v.startPrice}~{v.endPrice}/{v.number}{row.expenseReceiptType == 2 ? '%' : '元'}
                                                    </div>
                                                })
                                            }


                                        </div>
                                        :
                                        <div>{row.expenseReceiptType == 0 ? '￥' : null}{row.expensePaySectionJson}{row.expenseReceiptType == 1 ? '%' : ''}</div>
                                }

                            </div>
                            :
                            <div>
                                {row.shareMoneyType == 0 ? '不足不扣' : '补偿扣款'}/￥{row.expensePaySectionJson}
                            </div>
                    }

                </>

            ),
        },
        {
            title: '结算周期',
            dataIndex: 'settleType',
            key: 'settleType',
            render: (text, row) => (
                <div>
                    {
                        row.settleType ?
                            <div>
                                {
                                    row.settleType == 1 ?
                                        <div>
                                            每:{row.settleValue}号
                                        </div> : null
                                }
                                {
                                    row.settleType == 2 ?
                                        <div>
                                            每周:星期{row.settleValue}
                                        </div> : null
                                }
                                {
                                    row.settleType == 3 ?
                                        <div>
                                            每月:{row.settleValue}号
                                        </div> : null
                                }
                            </div> :
                            <div>--</div>
                    }


                </div>
            ),
        },
        {
            title: '收入机构',
            dataIndex: 'payeeMerchantNames',
            key: 'payeeMerchantNames',
            width: 200,
            render: (text, row) => (
                <div className='ellipsis'>
                    <a href='#' onClick={() => {
                        setRow(row.payeeMerchantNames.toString().split(','))
                        setDetailVisible(true)
                    }}>{row.payeeMerchantNames}</a>
                </div>
            ),
        },
        {
            title: '收入账户',
            dataIndex: 'accountType',
            key: 'accountType',
            render: (text, row) => (
                <div>
                    {row.accountType == 0 ? '店铺待结算余额' : ''}
                    {row.accountType == 4 ? '钱包待结算余额' : ''}
                    {row.accountType == 1 ? '店铺余额' : ''}
                    {row.accountType == 2 ? '钱包余额' : ''}
                    {row.accountType == 7 ? '营销余额待结算' : ''}
                </div>
            ),
        },
        {
            title: '单据有效期',
            dataIndex: 'date',
            key: 'date',
            width: 200,
            render: (text, row) => (
                <div>
                    {
                        row.expireType == 0 ?
                            <div>
                                {row.expireStartTimeStr}~{row.expireEndTimeStr}
                            </div> : null
                    }
                    {
                        row.expireType == 1 ?
                            <div>
                                {row.expireStartTimeStr}~长期有效
                            </div> : null
                    }
                    {
                        row.expireType == 2 ?
                            <div>
                                {row.expireStartTimeStr}~一次性费用
                            </div> : null
                    }

                </div>
            ),
        },
        {
            title: '状态',
            dataIndex: 'costDetailStatus',
            key: 'costDetailStatus',
            render: (text, row) => (
                <div>
                    {row.costDetailStatus == 0 ? '未开启' : ''}
                    {row.costDetailStatus == 1 ? '进行中' : ''}
                    {row.costDetailStatus == 2 ? '到期停用' : ''}
                    {row.costDetailStatus == 3 ? '手动停用' : ''}

                </div>
            ),
        },

    ];
    const detailHandleOk = () => {
        setDetailVisible(false);
    };

    const detailhandleCancel = () => {
        setDetailVisible(false);
    };
    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            <div>
                <Form
                    name="basic"
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    autoComplete="off"
                >
                    <Row align="middle" justify="space-between" style={{ background: '#F7F8FA', height: 56, paddingLeft: 16, paddingRight: 16 }} >
                        <Col span={12} style={{ fontWeight: 600 }}>费用单号:{data && data.costRuleNo}</Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            {data && data.costStatus == 0 ? '未开启' : ''}
                            {data && data.costStatus == 1 ? '进行中' : ''}
                            {data && data.costStatus == 2 ? '停用' : ''}
                        </Col>
                    </Row>
                    <div style={{ width: '100%', marginLeft: 20, marginTop: 20, marginBottom: 20 }} className='borderBottom'>基本信息</div>
                    <Form.Item label="单据名称：" >
                        {data && data.costName}
                    </Form.Item>
                    <Form.Item label="支出机构：">
                        {data && data.payerMerchantNames}
                    </Form.Item>
                    <Form.Item label="支出账户：">
                        {data && data.accountType == 0 ? '店铺待结算余额' : ''}
                        {data && data.accountType == 4 ? '钱包待结算余额' : ''}
                        {data && data.accountType == 1 ? '店铺余额' : ''}
                        {data && data.accountType == 2 ? '钱包余额' : ''}
                        {data && data.accountType == 7 ? '营销余额待结算' : ''}
                        {data && data.accountType == 8 ? '营销余额' : ''}
                    </Form.Item>
                    <div style={{ width: '100%', marginTop: 20, marginLeft: 20, marginBottom: 20 }} className='borderBottom'>分账规则</div>
                    <Table
                        locale={{
                            emptyText: "没有更多数据了"
                        }}
                        style={{ marginTop: 20 }}
                        columns={columns}
                        dataSource={lists}
                        rowKey='id'
                        scroll={{
                            x: 1600,
                        }}
                        pagination={false}
                    />
                    <div style={{ width: '100%', marginTop: 20, marginLeft: 20, marginBottom: 20 }} className='borderBottom'>创建信息</div>
                    <div style={{ marginTop: 16, marginLeft: 60 }}>
                        <div>操作人: {operate && operate.operatorName}</div>
                        <div style={{ marginTop: 16 }}>操作人: {operate && operate.createTime}</div>
                        <div style={{ marginTop: 16 }}>操作内容: {operate && operate.remark}</div>
                    </div>
                    <Row style={{ marginTop: '2vh' }}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Button
                                onClick={() => {
                                    navigate(-1)
                                }}
                            >
                                取消
                            </Button>

                        </Col>
                    </Row>
                </Form>
            </div>
            <Modal
                title="收入机构详情"
                visible={detailVisible}
                onOk={detailHandleOk}
                onCancel={detailhandleCancel}
                width={300}
                footer={null}
                getContainer={false}  //1.排除警告
            >
                <Mechanism data={row} callback={(state) => {
                    console.log(state)

                }}
                    closeDialog={() => {
                        setDetailVisible(false)
                    }} />
            </Modal>


        </div>
    )
}

export default CostDetail;