import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment'
import Compressor from 'compressorjs'
import {
    Form,
    Row,
    Col,
    Button,
    Table,
    DatePicker,
    Input,
    Select,
    message,
    Popconfirm,
    Tabs,
    Radio,
    Steps,
    Popover,
    Modal,
    Typography,
    Upload,
    Space,
    Cascader
} from 'antd';
import { UploadOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
    certificateTypeListRequest,
    areaListRequest,
    bankCardNameRequest,
    savePersonSecondRequest,
    incompleteDetailRequest,
    savePersonFirstRequest,
    saveSubmitPersonRequest,
    authBaseDetailRequest,
    editPersonRequest
} from '../../api/api'

import host from '../../util/host';
import { buyCard } from '../../util/tools'
const { Option } = Select;
const { Step } = Steps;
const { Title } = Typography;
const dateFormat = 'YYYY/MM/DD';
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};
const IndividualSettlement = () => {
    const [current, setCurrent] = useState(0);
    const [data, setData] = useState(null)
    const [data1, setData1] = useState(null)
    const [date, setDate] = useState(null)
    const [merchantList, setMerchantList] = useState([])
    const [typeList, setTypeList] = useState(Array)
    const [imageData1, setImageData1] = useState();
    const [imageData2, setImageData2] = useState();
    const [imageData3, setImageData3] = useState();
    const [imageData4, setImageData4] = useState();
    const [imageData5, setImageData5] = useState();
    const [imageData6, setImageData6] = useState();
    const [loading1, setLoading1] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(false)
    const [loading4, setLoading4] = useState(false)
    const [loading5, setLoading5] = useState(false)
    const [loading6, setLoading6] = useState(false)
    const [options, setOptions] = useState([])
    const [radio, setRadio] = useState(0)
    const [bankCardList, setBankCardList] = useState([])
    const [personageId, setPersonageId] = useState(null)
    const [detail, setDetail] = useState(null)
    const [editData, setEditData] = useState(null)
    const [legalCertTypeName, setLegalCertTypeName] = useState(null)
    const [bankName, setBankName] = useState(null)
    const superMerchantCode = localStorage.appid
    const navigate = useNavigate();
    const [form1] = Form.useForm()
    const [form2] = Form.useForm()
    const [form3] = Form.useForm()
    const { state } = useLocation()
    console.log("🚀 ~ IndividualSettlement ~ state:", state)

    // 优先取传入的门店
    const merchantCode = state.merchantCode || localStorage.merchantCode
    const merchantName = state.merchantName || ''

    useEffect(() => {
        console.log(state);
        certificateTypeList()
        areaList()
        bankCardName()
        if (state.edit == 1) {
            authBaseDetail()
        } else {
            incompleteDetail()
        }
    }, [])
    useEffect(() => {
        console.log(data)
    }, [data])
    const authBaseDetail = () => {
        (async _ => {
            return await authBaseDetailRequest({
                merchantCode: merchantCode,
                superMerchantCode: superMerchantCode,
                id: state.baseId ? state.baseId : state.id,
                employeeId: state && state.employeeId
            });
        })().then(res => {
            console.log(res)
            if (res.code == 0) {
                form1.setFieldsValue({
                    legalCertType: res.data.legalCertType,
                    legalName: res.data.legalName,
                    legalCertNo: res.data.legalCertNo,
                    certValidityType: Number(res.data.legalCertValidityType),
                    date1: res.data.legalCertBeginDate ? moment(res.data.legalCertBeginDate, dateFormat) : undefined,
                    date2: res.data.legalCertEndDate ? moment(res.data.legalCertEndDate, dateFormat) : undefined,
                    img1: {
                        liantuo_picture: res.data.certPhotoAUrl,
                        channel_picture_id: res.data.certPhotoAId
                    },
                    img2: {
                        liantuo_picture: res.data.certPhotoBUrl,
                        channel_picture_id: res.data.certPhotoBId
                    }
                })
                form2.setFieldsValue({
                    legalCertType: res.data.legalCertType,
                    legalName: res.data.legalName,
                    legalCertNo: res.data.legalCertNo,
                    contactName: res.data.contactName,
                    contactMobile: res.data.contactMobile,
                    bankCode: res.data.bankCode,
                    cardNo: res.data.cardNo,
                    seat: res.data.provId ? [res.data.provId, res.data.areaId] : undefined,
                    img3: {
                        liantuo_picture: res.data.contactAUrl,
                        channel_picture_id: res.data.contactAId
                    },
                    img4: {
                        liantuo_picture: res.data.contactBUrl,
                        channel_picture_id: res.data.contactBId
                    },
                    img5: {
                        liantuo_picture: res.data.contactBUrl,
                        channel_picture_id: res.data.contactBId
                    },
                    img6: {
                        liantuo_picture: res.data.contactBUrl,
                        channel_picture_id: res.data.contactBId
                    }
                })
                // setDate1(res.data.legalCertBeginDate ? res.data.legalCertBeginDate : '起始')
                // setDate2(res.data.legalCertValidityType == 1 ? '长期' : res.data.legalCertEndDate ? res.data.legalCertEndDate : '截止')
                setImageData1({
                    liantuo_picture: res.data.certPhotoAUrl,
                    channel_picture_id: res.data.certPhotoAId
                })
                setImageData2({
                    liantuo_picture: res.data.certPhotoBUrl,
                    channel_picture_id: res.data.certPhotoBId
                })
                setImageData3({
                    liantuo_picture: res.data.contactAUrl,
                    channel_picture_id: res.data.contactAId
                })
                setImageData4({
                    liantuo_picture: res.data.contactBUrl,
                    channel_picture_id: res.data.contactBId
                })
                setImageData5({
                    liantuo_picture: res.data.bankCardAUrl,
                    channel_picture_id: res.data.bankCardAId
                })
                setImageData6({
                    liantuo_picture: res.data.bankCardBUrl,
                    channel_picture_id: res.data.bankCardBId
                })
                setRadio(res.data.legalCertValidityType)
                setEditData(res.data)
                setDetail(res.data)
                setBankName(res.data.bankName)
            } else {

            }
        });
    }
    const onFinish1 = (values) => {
        console.log(values);
        values.certPhotoAUrl = imageData1 && imageData1.liantuo_picture
        values.certPhotoAId = imageData1 && imageData1.channel_picture_id
        values.certPhotoBUrl = imageData2 && imageData2.liantuo_picture
        values.certPhotoBId = imageData2 && imageData2.channel_picture_id
        values.legalCertBeginDate = values.certValidityType == 0 ? values.date1 && values.date1.format("YYYY-MM-DD") : values.date3 && values.date3.format("YYYY-MM-DD")
        values.legalCertEndDate = values.date2 && values.date2.format("YYYY-MM-DD")
        values.openStatus = detail && detail.openStatus ? detail.openStatus : 'N'
        values.id = state.edit == 0 ? undefined : detail.id
        values.operaterId = localStorage.operatorId
        values.operaterName = localStorage.operatorname
        values.openType = 2;
        values.merchantCode = merchantCode
        values.superMerchantCode = superMerchantCode
        values.legalCertTypeName = legalCertTypeName
        values.employeeId = state && state.employeeId
        delete values.date1
        delete values.date1
        delete values.date3
        delete values.img1
        delete values.img2;
        if (state.edit == 1) {
            setCurrent(1)
            setData(values);
        } else {
            (async _ => {
                return await savePersonFirstRequest(values);
            })().then(res => {
                console.log(res);
                if (res.code == 0) {
                    setData(values);
                    setCurrent(1)
                    setPersonageId(res.data);
                } else {
                }
            });
        }


    }
    const onFinish2 = (values) => {
        console.log(values);
        console.log(state);
        if (state.edit == 1) {
            values.bankName = bankName;
            values.bankAreaName = editData && editData.bankAreaName;
            values.openStatus = editData && editData.openStatus;
            setCurrent(2)
            setData1(values);
        } else {
            (async _ => {
                return await savePersonSecondRequest(
                    {
                        ...data,
                        ...values,
                        openType: 2,
                        id: personageId ? personageId : detail.id,
                        operaterId: localStorage.operatorId,
                        operaterName: localStorage.operatorname,
                        merchantCode: merchantCode,
                        superMerchantCode,
                        openStatus: detail && detail.openStatus ? detail.openStatus : 'N',
                        contactAUrl: imageData3 && imageData3.liantuo_picture,
                        contactAId: imageData3 && imageData3.channel_picture_id,
                        contactBUrl: imageData4 && imageData4.liantuo_picture,
                        contactBId: imageData4 && imageData4.channel_picture_id,
                        bankCardAUrl: imageData5 && imageData5.liantuo_picture,
                        bankCardAId: imageData5 && imageData5.channel_picture_id,
                        bankCardBUrl: imageData6 && imageData6.liantuo_picture,
                        bankCardBId: imageData6 && imageData6.channel_picture_id,
                        provId: values.seat[0],
                        areaId: values.seat[1],
                        cardType: 1,
                        openStatus: data && data.openStatus ? data.openStatus : 'N',
                        legalName: data.legalName,
                    }
                );
            })().then(res => {
                if (res.code == 0) {
                    setData1(res.data);
                    setCurrent(2)
                } else {
                    message.error(res.msg)
                }
            });
        }


    }
    const onFinish3 = () => {
        let params = {
            merchantCode: merchantCode,
            superMerchantCode: superMerchantCode,
            id: data1.id,
        };
        (async _ => {
            return await saveSubmitPersonRequest(params);
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                if (state && state.edit == 1) {
                    navigate(-1)
                } else {
                    navigate(-2)
                }

            } else {
                if (state && state.edit == 1) {
                    navigate(-1)
                } else {
                    navigate(-2)
                }
                message.error(res.msg)
            }
        });

    }
    const editPerson = () => {
        console.log(data, 'datadatadata');
        console.log(data1, 'data1data1data1data1');
        console.log(data1, 'data1data1data1data1');
        let params = {
            ...data,
            ...data1,
            contactAUrl: imageData3 && imageData3.liantuo_picture,
            contactAId: imageData3 && imageData3.channel_picture_id,
            contactBUrl: imageData4 && imageData4.liantuo_picture,
            contactBId: imageData4 && imageData4.channel_picture_id,
            bankCardAUrl: imageData5 && imageData5.liantuo_picture,
            bankCardAId: imageData5 && imageData5.channel_picture_id,
            bankCardBUrl: imageData6 && imageData6.liantuo_picture,
            bankCardBId: imageData6 && imageData6.channel_picture_id,
            provId: data1.seat[0],
            areaId: data1.seat[1],
            id: editData && editData.id,
        };

        (async _ => {
            return await editPersonRequest(params);
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                if (state && state.edit == 1) {
                    navigate(-1)
                } else {
                    navigate(-2)
                }

            } else {
                if (state && state.edit == 1) {
                    navigate(-1)
                } else {
                    navigate(-2)
                }
                message.error(res.msg)
            }
        });
    }
    const incompleteDetail = () => {
        let params = {
            merchantCode: merchantCode,
            superMerchantCode: superMerchantCode,
            openType: 2,
            employeeId: state && state.employeeId,
        };
        (async _ => {
            return await incompleteDetailRequest(params);
        })().then(res => {
            if (res.code == 0) {
                form1.setFieldsValue({
                    legalCertType: res.data.legalCertType,
                    legalName: res.data.legalName,
                    legalCertNo: res.data.legalCertNo,
                    certValidityType: Number(res.data.legalCertValidityType),
                    date1: res.data.legalCertBeginDate ? moment(res.data.legalCertBeginDate, dateFormat) : undefined,
                    date2: res.data.legalCertEndDate ? moment(res.data.legalCertEndDate, dateFormat) : undefined,
                    img1: {
                        liantuo_picture: res.data.certPhotoAUrl,
                        channel_picture_id: res.data.certPhotoAId
                    },
                    img2: {
                        liantuo_picture: res.data.certPhotoBUrl,
                        channel_picture_id: res.data.certPhotoBId
                    }
                })
                form2.setFieldsValue({
                    legalCertType: res.data.legalCertType,
                    legalName: res.data.legalName,
                    legalCertNo: res.data.legalCertNo,
                    contactName: res.data.contactName,
                    contactMobile: res.data.contactMobile,
                    bankCode: res.data.bankCode,
                    cardNo: res.data.cardNo,
                    seat: res.data.provId ? [res.data.provId, res.data.areaId] : undefined,
                    img3: {
                        liantuo_picture: res.data.contactAUrl,
                        channel_picture_id: res.data.contactAId
                    },
                    img4: {
                        liantuo_picture: res.data.contactBUrl,
                        channel_picture_id: res.data.contactBId
                    },
                    img5: {
                        liantuo_picture: res.data.bankCardAUrl,
                        channel_picture_id: res.data.bankCardAId
                    },
                    img6: {
                        liantuo_picture: res.data.bankCardBUrl,
                        channel_picture_id: res.data.bankCardBId
                    }
                })
                // setDate1(res.data.legalCertBeginDate ? res.data.legalCertBeginDate : '起始')
                // setDate2(res.data.legalCertValidityType == 1 ? '长期' : res.data.legalCertEndDate ? res.data.legalCertEndDate : '截止')
                setImageData1({
                    liantuo_picture: res.data.certPhotoAUrl,
                    channel_picture_id: res.data.certPhotoAId
                })
                setImageData2({
                    liantuo_picture: res.data.certPhotoBUrl,
                    channel_picture_id: res.data.certPhotoBId
                })
                setImageData3({
                    liantuo_picture: res.data.contactAUrl,
                    channel_picture_id: res.data.contactAId
                })
                setImageData4({
                    liantuo_picture: res.data.contactBUrl,
                    channel_picture_id: res.data.contactBId
                })
                setImageData5({
                    liantuo_picture: res.data.bankCardAUrl,
                    channel_picture_id: res.data.bankCardAId
                })
                setImageData6({
                    liantuo_picture: res.data.bankCardBUrl,
                    channel_picture_id: res.data.bankCardBId
                })
                setDetail(res.data);
                setRadio(res.data.legalCertValidityType)
                // setData(res.data)
                // setCheck(res.data.legalCertValidityType == 1 ? true : false)
                // if (res.data.legalCertValidityType == 1) {
                //     setCqValue(['长期'])
                // }
                // setStatus(true)
            } else {
                // setStatus(true)

            }
        });
    }

    const steps = [
        {
            title: '1.填写个人信息',
            content: '',
        },
        {
            title: '2.填写基本信息',
            content: '',
        },
        {
            title: '3.预览信息',
            content: '',
        },
    ];
    const certificateTypeList = () => {
        (async _ => {
            return await certificateTypeListRequest();
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setTypeList(res.data)

            } else {

            }
        });
    }

    const bankCardName = () => {
        (async _ => {
            return await bankCardNameRequest();
        })().then(res => {
            if (res.code == 0) {
                res.data.map((v) => {
                    v.label = v.bankName
                    v.value = v.dgBankCode
                })
                setBankCardList(res.data)
            } else {

            }
        });
    }



    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('请上传JPG/PNG文件!');
        }
        const isLt6M = file.size / 1024 / 1024 < 6;
        if (!isLt6M) {
            message.error('图片大小不能大于6MB!');
        }
        return isJpgOrPng && isLt6M;
    }
    const handleChange = (info) => {
        console.log(info.file)
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            // getBase64(info.file.originFileObj, (url) => {
            //     console.log(url)

            // });
        }
    };
    const areaList = () => {

        (async _ => {
            return await areaListRequest();
        })().then(res => {
            if (res.code == 0) {
                res.data.map((v) => {
                    v.label = v.provinceName
                    v.value = v.provinceCode
                    v.children = v.cities.map((i) => {
                        return {
                            label: i.cityName,
                            value: i.cityCode,
                        }

                    })
                })
                setOptions(res.data)

            } else {
            }
        });
    }
    const uploadButton1 = (
        <div>
            {loading1 ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    const uploadButton2 = (
        <div>
            {loading2 ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    const uploadButton3 = (
        <div>
            {loading3 ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    const uploadButton4 = (
        <div>
            {loading4 ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    const uploadButton5 = (
        <div>
            {loading5 ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    const uploadButton6 = (
        <div>
            {loading6 ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            <Steps current={current}
                type="navigation"
                className="site-navigation-steps"
                // onChange={(value) => {
                //     console.log(value)
                //     setCurrent(value);
                // }}
                style={{ width: 800, marginLeft: 20 }}
            >
                {steps.map(item => (
                    <Step key={item.title} title={item.title} />
                ))}
            </Steps>
            {
                current == 0 ?
                    <Form
                        name="basic"
                        {...layout}
                        form={form1}
                        onFinish={onFinish1}
                        initialValues={{
                            certValidityType: 0,
                        }}
                    >

                        <Title level={5} style={{ marginTop: 16, marginLeft: 16, marginBottom: 20 }}>个人信息</Title>

                        {/* 测试门店是否代入成功， 不需要可注释掉 */}
                        { merchantCode && merchantName ? <Form.Item label="机构名称">{merchantName}</Form.Item> : null }
                        <Form.Item
                            name="legalCertType"
                            label="证件类型"
                            rules={[{ required: true, message: '请选择证件类型' }]}
                        >
                            <Select placeholder="请选择"
                                style={{ width: 200 }}
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                                onChange={(e) => {
                                    console.log(e);
                                    let arr = []

                                }}
                            >
                                {
                                    typeList && typeList.map((v) => {
                                        return <Option rowKey={v.constantCode} key={v.constantCode} value={v.constantCode}>{v.constantName}</Option>
                                    })
                                }


                            </Select>
                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: '-0.2vh' }}
                            name="img1"
                            label="证件照正面"
                            rules={[{ required: true, message: '请上传证件照!' }]}
                        >
                            <Upload
                                // name="file"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={`${host}/channel/upload`}
                                headers={{ 'token': localStorage.token, }}
                                beforeUpload={beforeUpload}
                                onChange={(info) => {
                                    if (info.file.status === 'uploading') {
                                        setLoading1(true);
                                        return;
                                    }
                                    if (info.file.status === 'done') {
                                        console.log(info.file.response.data);
                                        form1.setFieldsValue({
                                            legalName: form1.getFieldsValue().legalName ? form1.getFieldsValue().legalName : info.file.response.data.legalName,
                                            legalCertNo: form1.getFieldsValue().legalCertNo ? form1.getFieldsValue().legalCertNo : info.file.response.data.legalCertNo,
                                        })
                                        setImageData1(info.file.response.data)
                                        // Get this url from response in real world.
                                        setLoading1(false);
                                        // getBase64(info.file.originFileObj, (url) => {
                                        //     console.log(url)

                                        // });
                                    }

                                }}
                                data={file => ({ // data里存放的是接口的请求参数
                                    picture: file,
                                    merchantCode: merchantCode,
                                    superMerchantCode: superMerchantCode,
                                    pictureType: "13"
                                })}
                            >
                                {imageData1 && imageData1.liantuo_picture ? (
                                    <img
                                        src={imageData1 && imageData1.liantuo_picture}
                                        alt="avatar"
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    />
                                ) : (
                                    uploadButton1
                                )}

                            </Upload>

                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: '-0.2vh', color: '#949494', fontSize: 12 }}
                            label=" "
                            colon={false}
                            rules={[{ required: false, message: '请上传银行回单!' }]}
                        >
                            如你的证件类型是身份证，请在此处上传人像面，若为复印件，需加盖公司鲜章。注意：证件盖章不支持电子章。<span style={{ color: '#155BD4' }}>查看示例</span>

                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: '-0.2vh' }}
                            name="img2"
                            label="证件照反面"
                            rules={[{ required: true, message: '请上传证件照!' }]}
                        >
                            <Upload
                                name="file"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={`${host}/channel/upload`}
                                headers={{ 'token': localStorage.token, }}
                                beforeUpload={beforeUpload}
                                onChange={(info) => {
                                    if (info.file.status === 'uploading') {
                                        setLoading2(true);
                                        return;
                                    }
                                    if (info.file.status === 'done') {
                                        console.log(info.file.response.data);
                                        let data = info.file.response.data
                                        form1.setFieldsValue({
                                            legalCertValidityType: data.legalCertValidityType,
                                            date1: data.legalCertBeginDate ? moment(data.legalCertBeginDate, dateFormat) : undefined,
                                            date2: data.legalCertEndDate ? moment(data.legalCertEndDate, dateFormat) : undefined,
                                        })
                                        setRadio(data.legalCertValidityType)
                                        setImageData2(info.file.response.data)
                                        setLoading2(false);
                                    }
                                }}
                                data={file => ({ // data里存放的是接口的请求参数
                                    picture: file,
                                    merchantCode: merchantCode,
                                    superMerchantCode: superMerchantCode,
                                    pictureType: "14"
                                })}
                            >
                                {imageData2 && imageData2.liantuo_picture ? (
                                    <img
                                        src={imageData2 && imageData2.liantuo_picture}
                                        alt="avatar"
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    />
                                ) : (
                                    uploadButton2
                                )}

                            </Upload>

                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: '-0.2vh', color: '#949494', fontSize: 12 }}
                            label=" "
                            colon={false}
                            rules={[{ required: false, message: '请上传银行回单!' }]}
                        >
                            如你的证件类型是身份证，请在此处上传人像面，若为复印件，需加盖公司鲜章。注意：证件盖章不支持电子章。<span style={{ color: '#155BD4' }}>查看示例</span>

                        </Form.Item>
                        <Form.Item label="个人姓名"
                            name="legalName"
                            rules={[{ required: true, message: '请输入个人姓名!' }]}
                        >
                            <Input style={{ width: 200 }} />
                        </Form.Item>
                        <Form.Item label="证件号码"
                            name="legalCertNo"
                            rules={[{ required: true, message: '请输入证件号码!' }]}
                        >
                            <Input style={{ width: 200 }} />
                        </Form.Item>
                        <Form.Item
                            label="证件有效期"
                            rules={[{ required: true, message: '请选择!' }]}
                        >
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <Form.Item
                                        name="certValidityType"
                                        label=""
                                        rules={[{ required: true, message: '请选择!' }]}
                                    >
                                        <Radio.Group
                                            style={{ marginTop: 6 }}
                                            value={radio}
                                            onChange={(e) => {
                                                console.log(e.target.value);
                                                setRadio(e.target.value)
                                                // form1.setFieldsValue({
                                                //     certValidityType: e.target.value
                                                // })
                                            }}
                                        >
                                            <Space direction="vertical">
                                                <Radio value={0}>区间有效</Radio>
                                                <Radio value={1} style={{ marginTop: 24 }}>长期有效</Radio>
                                            </Space>

                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                                <div>
                                    <div style={{ display: 'flex' }}>
                                        <Form.Item
                                            name="date1"
                                            label=""
                                            rules={[{ required: radio == 0, message: '请选择开始时间!' }]}
                                            colon={false}
                                        >
                                            <DatePicker disabled={radio == 1} placeholder='请选择开始日期' style={{ width: 200 }} />
                                        </Form.Item>
                                        <Form.Item
                                            name="date2"
                                            label=""
                                            rules={[{ required: radio == 0, message: '请选择结束时间!' }]}
                                            colon={false}
                                        >
                                            <DatePicker disabled={radio == 1} placeholder='请选择结束日期' style={{ width: 200 }} />
                                        </Form.Item>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <Form.Item
                                            name="date3"
                                            label=""
                                            rules={[{ required: radio == 1, message: '请选择开始时间!' }]}
                                            colon={false}
                                        >
                                            <DatePicker disabled={radio == 0} placeholder='请选择日期' style={{ width: 200 }} />
                                        </Form.Item>

                                    </div>
                                </div>

                            </div>

                        </Form.Item>
                        <Form.Item
                            label=" "
                            colon={false}
                        >
                            <Button type="primary" htmlType='submit'>下一步</Button>
                        </Form.Item>


                    </Form > : null
            }
            {
                current == 1 ?
                    <Form
                        name="basic"
                        {...layout}
                        form={form2}
                        onFinish={onFinish2}
                        initialValues={{
                            shareType: 0,
                            expenseReceiptType: 0,
                            expireType: 0,
                        }}
                    >

                        <Title level={5} style={{ marginTop: 16, marginLeft: 16, marginBottom: 20 }}>联系人信息</Title>
                        <Form.Item
                            style={{ marginBottom: '-0.2vh' }}
                            name="img3"
                            label="证件照正面"
                            rules={[{ required: true, message: '请上传银行回单!' }]}
                        >
                            <Upload
                                name="file"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={`${host}/channel/upload`}
                                headers={{ 'token': localStorage.token, }}
                                beforeUpload={beforeUpload}
                                onChange={(info) => {
                                    if (info.file.status === 'uploading') {
                                        setLoading3(true);
                                        return;
                                    }
                                    if (info.file.status === 'done') {
                                        console.log(info.file.response.data);
                                        form2.setFieldsValue({
                                            contactName: form2.getFieldsValue().contactName ? form2.getFieldsValue().contactName : info.file.response.data.legalName,
                                        })
                                        setImageData3(info.file.response.data)
                                        setLoading3(false);
                                    }
                                }}
                                data={file => ({ // data里存放的是接口的请求参数
                                    picture: file,
                                    merchantCode: merchantCode,
                                    superMerchantCode: superMerchantCode,
                                    pictureType: 14,
                                })}
                            >
                                {imageData3 && imageData3.liantuo_picture ? (
                                    <img
                                        src={imageData3 && imageData3.liantuo_picture}
                                        alt="avatar"
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    />
                                ) : (
                                    uploadButton3
                                )}
                            </Upload>

                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: '-0.2vh', color: '#949494', fontSize: 12 }}
                            label=" "
                            colon={false}
                            rules={[{ required: false, message: '请上传银行回单!' }]}
                        >
                            如你的证件类型是身份证，请在此处上传人像面，若为复印件，需加盖公司鲜章。注意：证件盖章不支持电子章。<span style={{ color: '#155BD4' }}>查看示例</span>

                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: '-0.2vh' }}
                            name="img4"
                            label="证件照反面"
                            rules={[{ required: true, message: '请上传银行回单!' }]}
                        >
                            <Upload
                                name="file"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={`${host}/channel/upload`}
                                headers={{ 'token': localStorage.token, }}
                                beforeUpload={beforeUpload}
                                onChange={(info) => {
                                    if (info.file.status === 'uploading') {
                                        setLoading4(true);
                                        return;
                                    }
                                    if (info.file.status === 'done') {
                                        console.log(info.file.response.data);
                                        setImageData4(info.file.response.data)
                                        setLoading4(false);
                                    }
                                }}
                                data={file => ({ // data里存放的是接口的请求参数
                                    picture: file,
                                    merchantCode: merchantCode,
                                    superMerchantCode: superMerchantCode,
                                    pictureType: 14,
                                })}
                            >
                                {imageData4 && imageData4.liantuo_picture ? (
                                    <img
                                        src={imageData4 && imageData4.liantuo_picture}
                                        alt="avatar"
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    />
                                ) : (
                                    uploadButton4
                                )}
                            </Upload>

                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: '-0.2vh', color: '#949494', fontSize: 12 }}
                            label=" "
                            colon={false}
                            rules={[{ required: false, message: '请上传银行回单!' }]}
                        >
                            如你的证件类型是身份证，请在此处上传人像面，若为复印件，需加盖公司鲜章。注意：证件盖章不支持电子章。<span style={{ color: '#155BD4' }}>查看示例</span>

                        </Form.Item>
                        <Form.Item
                            name="contactName"
                            label="联系人姓名"
                            rules={[{ required: true, message: '请选择证件类型' }]}
                        >
                            <Input placeholder="请输入个人姓名" style={{ width: 200 }} />
                        </Form.Item>
                        <Form.Item
                            name="contactMobile"
                            label="手机号"
                            rules={[{ required: true, message: '请输入证件号码' }]}
                        >
                            <Input placeholder="请输入证件号码" style={{ width: 200 }} />
                        </Form.Item>

                        <Title level={5} style={{ marginTop: 16, marginLeft: 16, marginBottom: 20 }}>结算账户信息</Title>
                        <Form.Item
                            style={{ marginBottom: '-0.2vh' }}
                            name="img5"
                            label="结算卡正面"
                            rules={[{ required: true, message: '请上传结算卡正面!' }]}
                        >
                            <Upload
                                name="file"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={`${host}/channel/upload`}
                                headers={{ 'token': localStorage.token, }}
                                beforeUpload={beforeUpload}
                                onChange={(info) => {
                                    if (info.file.status === 'uploading') {
                                        setLoading5(true);
                                        return;
                                    }
                                    if (info.file.status === 'done') {
                                        console.log(info.file.response.data);
                                        form2.setFieldsValue({
                                            cardNo: form2.getFieldsValue().cardNo ? form2.getFieldsValue().cardNo : info.file.response.data.cardNo,
                                            bankCode: form2.getFieldsValue().bankCode ? form2.getFieldsValue().bankCode : info.file.response.data.bankCode,
                                        })
                                        setImageData5(info.file.response.data)
                                        setLoading5(false);
                                    }
                                }}
                                data={file => ({ // data里存放的是接口的请求参数
                                    picture: file,
                                    merchantCode: merchantCode,
                                    superMerchantCode: superMerchantCode,
                                    pictureType: 18,
                                })}
                            >
                                {imageData5 && imageData5.liantuo_picture ? (
                                    <img
                                        src={imageData5 && imageData5.liantuo_picture}
                                        alt="avatar"
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    />
                                ) : (
                                    uploadButton5
                                )}
                            </Upload>

                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: '-0.2vh' }}
                            name="img6"
                            label="结算卡反面"
                            rules={[{ required: true, message: '请上传银行回单!' }]}
                        >
                            <Upload
                                name="file"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={`${host}/channel/upload`}
                                headers={{ 'token': localStorage.token, }}
                                beforeUpload={beforeUpload}
                                onChange={(info) => {
                                    if (info.file.status === 'uploading') {
                                        setLoading6(true);
                                        return;
                                    }
                                    if (info.file.status === 'done') {
                                        console.log(info.file.response.data);
                                        setImageData6(info.file.response.data)
                                        setLoading6(false);
                                    }
                                }}
                                data={file => ({ // data里存放的是接口的请求参数
                                    picture: file,
                                    merchantCode: merchantCode,
                                    superMerchantCode: superMerchantCode,
                                    pictureType: 19,
                                })}
                            >
                                {imageData6 && imageData6.liantuo_picture ? (
                                    <img
                                        src={imageData6 && imageData6.liantuo_picture}
                                        alt="avatar"
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    />
                                ) : (
                                    uploadButton6
                                )}

                            </Upload>

                        </Form.Item>
                        <Form.Item label="银行户名"
                            name="legalName"
                            rules={[{ required: false, message: '请输入个人姓名!' }]}
                        >
                            <Form.Item label=""
                                name="legalName"
                            >
                                {data && data.legalName}
                            </Form.Item>
                            <Form.Item
                                style={{ marginTop: '-30px' }}
                                label=""
                                name=""
                            >
                                <span style={{ color: '#999999', fontSize: '12px' }}>如需修改，请修改个人姓名信息</span>
                            </Form.Item>
                        </Form.Item>
                        <Form.Item label="开户银行"
                            name="bankCode"
                            rules={[{ required: true, message: '请选择开户银行!' }]}
                            style={{ marginTop: '-30px' }}
                        >
                            <Select placeholder="请选择"
                                style={{ width: 200 }}
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                                onChange={(e) => {
                                    bankCardList && bankCardList.map((v) => {
                                        if (e == v.dgBankCode) {
                                            setBankName(v.bankName)
                                        }
                                    })
                                }}
                            >
                                {
                                    bankCardList && bankCardList.map((v) => {
                                        return <Option rowKey={v.dgBankCode} key={v.dgBankCode} value={v.dgBankCode}>{v.bankName}</Option>
                                    })
                                }



                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="seat"
                            label="银行所在省市"
                            rules={[{ required: true, message: '请选择转账用途!' }]}
                        >
                            <Cascader options={options}
                                placeholder="请选择"
                                style={{ width: 180 }}
                            />

                        </Form.Item>
                        <Form.Item
                            name="cardNo"
                            label="银行卡号"
                            rules={[{ required: true, message: '请输入银行卡号' }]}
                        >
                            <Input placeholder="请输入银行卡号" style={{ width: 200 }} />
                        </Form.Item>

                        <Form.Item
                            label=" "
                            colon={false}
                        >
                            <Button type="primary" htmlType='submit'>下一步</Button>
                        </Form.Item>


                    </Form > : null
            }
            {
                current == 2 ?
                    <Form
                        name="basic"
                        {...layout}
                        form={form1}
                        onFinish={onFinish3}
                    >

                        <Title level={5} style={{ marginTop: 16, marginLeft: 16, marginBottom: 20 }}>个人信息</Title>

                        {/* 测试门店是否代入成功， 不需要可注释掉 */}
                        { merchantCode && merchantName ? <Form.Item label="机构名称">{merchantName}</Form.Item> : null }
                        
                        <Form.Item
                            label="证件类型"
                        >
                            {data && data.legalCertTypeName}
                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: '-0.2vh' }}
                            label="证件照正面"
                        >

                            <img
                                src={imageData1 && imageData1.liantuo_picture}
                                alt="avatar"
                                style={{
                                    width: 100,
                                    height: 100
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: '-0.2vh' }}
                            label="证件照反面"
                        >
                            <img
                                src={imageData2 && imageData2.liantuo_picture}
                                alt="avatar"
                                style={{
                                    width: 100,
                                    height: 100
                                }}
                            />
                        </Form.Item>
                        <Form.Item label="个人姓名"
                        >
                            {data && data.legalName}
                        </Form.Item>
                        <Form.Item label="证件号码"
                        >
                            {data && data.legalCertNo}
                        </Form.Item>
                        <Form.Item
                            name=""
                            label="证件有效期"
                        >
                            {data && data.legalCertValidityType == 0 ? '区间有效' : ''} {data && data.legalCertBeginDate}~{data && data.legalCertEndDate}
                        </Form.Item>
                        <Title level={5} style={{ marginTop: 16, marginLeft: 16, marginBottom: 20 }}>联系人信息</Title>

                        <Form.Item
                            label="法人姓名"
                        >
                            {data1 && data1.contactName}
                        </Form.Item>
                        <Form.Item
                            label="手机号"
                        >
                            {data1 && data1.contactMobile}
                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: '-0.2vh' }}
                            label="证件照正面"
                        >
                            <img
                                src={imageData3 && imageData3.liantuo_picture}
                                alt="avatar"
                                style={{
                                    width: 100,
                                    height: 100
                                }}
                            />

                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: '-0.2vh' }}
                            label="证件照反面"
                        >
                            <img
                                src={imageData4 && imageData4.liantuo_picture}
                                alt="avatar"
                                style={{
                                    width: 100,
                                    height: 100
                                }}
                            />

                        </Form.Item>
                        <Title level={5} style={{ marginTop: 16, marginLeft: 16, marginBottom: 20 }}>结算账户信息</Title>
                        <Form.Item label="银行户名"
                        >
                            <Form.Item label=""
                            >
                                {data1 && data1.legalName}
                            </Form.Item>
                            <Form.Item
                                style={{ marginTop: '-30px' }}
                            >
                                <span style={{ color: '#999999', fontSize: '12px' }}>如需修改，请修改个人姓名信息</span>
                            </Form.Item>
                        </Form.Item>
                        <Form.Item label="开户银行"
                            style={{ marginTop: '-30px' }}
                        >
                            {data1 && data1.bankName}
                        </Form.Item>
                        <Form.Item
                            label="银行所在省市"
                        >
                            {data1 && data1.bankAreaName}
                        </Form.Item>
                        <Form.Item
                            label="银行卡号"
                        >
                            {data1 && data1.cardNo}
                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: '-0.2vh' }}
                            label="结算卡正面"
                        >
                            <img
                                src={imageData5 && imageData5.liantuo_picture}
                                alt="avatar"
                                style={{
                                    width: 100,
                                    height: 100
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: '-0.2vh' }}
                            label="结算卡反面"
                        >
                            <img
                                src={imageData6 && imageData6.liantuo_picture}
                                alt="avatar"
                                style={{
                                    width: 100,
                                    height: 100
                                }}
                            />

                        </Form.Item>


                        <Form.Item
                            label=" "
                            colon={false}
                            style={{ marginTop: 20 }}
                        >
                            {
                                state && state.edit == 1 ?
                                    <Button type="primary" onClick={() => {
                                        editPerson()
                                    }}>提交</Button>
                                    :
                                    <Button type="primary" htmlType='submit'>提交</Button>
                            }


                        </Form.Item>


                    </Form > : null
            }




        </div >
    )
}

export default IndividualSettlement;