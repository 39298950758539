import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import {
    Form,
    Row,
    Col,
    Button,
    Input,
    Select,
    Table,
    Popconfirm,
    DatePicker,
    Modal,
    message
} from 'antd';
import { tradeorderListRequest, settleMerchantListRequest } from '../../api/api'
import { useCallbackState } from '../../util/useCallbackState'
import { roundFun } from '../../util/tools'

const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
};
const { RangePicker } = DatePicker;
const CreditBalance = () => {
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [list, setList] = useState([])
    const [merchantList, setMerchantList] = useState([])
    const [merchantCode, setMerchantCode] = useState(null)
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [total, setTotal] = useState()
    const [init, setInit] = useState(true)
    const navigate = useNavigate()
    const { state } = useLocation()
    const { search } = useLocation()
    const [form] = Form.useForm();
    const columns = [
        {
            title: '门店名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
        },
        {
            title: '订单编号/退款单号',
            dataIndex: 'orderNo',
            key: 'orderNo',
            render: (text, row) => {
                return (
                    <div>
                        {row.refundType == 1 ?
                            <div>
                                <div>{row.relationOrderNo}</div>
                                <div>{row.orderNo}</div>
                            </div> :
                            <div>
                                <div>{row.orderNo}</div>
                            </div>
                        }


                    </div>
                )
            }
        },
        {
            title: '下单时间',
            dataIndex: 'tradeTime',
            key: 'tradeTime',
        },
        {
            title: '订单类型',
            dataIndex: 'orderType',
            key: 'orderType',
            render: (text, row) => {
                return (
                    <div>
                        {row.orderType == 0 ? '消费收银' : ''}
                        {row.orderType == 1 ? '会员充值' : ''}
                        {row.orderType == 2 ? '付费会员' : ''}
                        {row.orderType == 3 ? '付费卡券' : ''}
                        {row.orderType == 4 ? '线上商城' : ''}
                        {row.orderType == 5 ? '活动订单' : ''}
                        {row.orderType == 6 ? '押金订单' : ''}
                        {row.orderType == 7 ? '酒店订单' : ''}
                        {row.orderType == 8 ? '采购订单' : ''}
                        {row.orderType == 9 ? '合伙人管理费' : ''}
                    </div>
                )
            }
        },
        {
            title: '实收金额(元)',
            dataIndex: 'receiptAmount',
            key: 'receiptAmount',
            render: (text, row) => {
                return (
                    <div>
                        {
                            row.refundType == 1 ?
                                <span style={{ color: '#FF0000' }}>-{row.refundsAmount}</span>
                                :
                                <span style={{ color: '#06D61E' }}>+{row.receiptAmount}</span>
                        }
                    </div>
                )
            }
        },
        {
            title: '手续费',
            dataIndex: 'thirdFee',
            key: 'thirdFee',
            render: (text, row) => (
                <div>
                  <span style={{ color: '#06D61E' }}>{text}</span>
                </div>
            ),
        },
        {
            title: '赠送金额',
            dataIndex: 'memberGiveAmount',
            key: 'memberGiveAmount',
            render: (text, row) => (
                <div>
                    {
                            row.refundType == 1 ?
                                <span style={{ color: '#06D61E' }}>{row.refundsMemberGiveAmount == 0 ? '0.00' :row.refundsMemberGiveAmount }</span>
                                :
                                <span style={{ color: '#06D61E' }}>{row.memberGiveAmount  == 0 ? '0.00':row.memberGiveAmount}</span>
                        }
                </div>
            ),
        },
        {
            title: '订单状态',
            dataIndex: 'orderStatus',
            key: 'orderStatus',
            render: (text, row) => {
                return (
                    <div>
                        {
                            row.refundType == 1 ?
                                <div>
                                    {row.refundsStatus == 0 ? '未退款' : ''}
                                    {row.refundsStatus == 1 ? '已退款' : ''}
                                    {row.refundsStatus == 2 ? '部分退款' : ''}
                                    {row.refundsStatus == 3 ? '退款失败' : ''}
                                    {row.refundsStatus == 4 ? '退款中' : ''}
                                </div>
                                :
                                <div>
                                    {row.orderStatus == 0 ? '未完成' : ''}
                                    {row.orderStatus == 1 ? '已完成' : ''}
                                    {row.orderStatus == 2 ? '已退款' : ''}
                                    {row.orderStatus == 3 ? '已关闭' : ''}
                                    {row.orderStatus == 4 ? '已撤销' : ''}
                                </div>
                        }
                    </div>
                )


            }
        },
        {
            title: '结算状态',
            dataIndex: 'settleStatus',
            key: 'settleStatus',
            render: (text, row) => {
                return <div>
                    {row.settleStatus == 0 ? '未结算' : ''}
                    {row.settleStatus == 1 ? '已结算' : ''}
                    {row.settleStatus == 2 ? '结算失败' : ''}
                    {row.settleStatus == 3 ? '无需结算' : ''}
                </div>
            }
        },
        {
            title: '支付方式',
            dataIndex: 'payType',
            key: 'payType',
            render: (text, row) => (
                <div>
                    {row.payType == 0 ? '微信' : ''}
                    {row.payType == 1 ? '支付宝' : ''}
                    {row.payType == 2 ? '会员' : ''}
                    {row.payType == 3 ? '银行卡' : ''}
                    {row.payType == 4 ? '现金' : ''}
                    {row.payType == 5 ? '云闪付' : ''}
                    {row.payType == 6 ? '漕河泾(饿了么)' : ''}
                    {row.payType == 17 ? '翼支付' : ''}
                    {row.payType == 8 ? '组合支付' : ''}
                    {row.payType == 9 ? '授信支付' : ''}
                    {row.payType == 100 ? '余额支付' : ''}

                </div>
            ),
        },
        {
            title: '付款人',
            dataIndex: 'memberName',
            key: 'memberName',
        },
        {
            title: '操作人',
            dataIndex: 'operatorName',
            key: 'operatorName',
        },
        {
            title: '三方交易单号',
            dataIndex: 'thirdTradeNo',
            key: 'thirdTradeNo',
        },
        /*{
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 100,
            align: 'right',
            render: (text, row) => (
                <>
                    <Button type='link' onClick={() => {
                        navigate("/ws/transactionRecords/transactionDetails", { replace: false, state: row })
                    }}>详情</Button>
                </>

            ),

        },*/
    ];

    useEffect(() => {
            getTradeorderList()
            if (init) {
                settleMerchantList();
            }
           console.log(localStorage.merchantCode);
    }, [currentPage, pageNum])
   
    const settleMerchantList = () => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            if (res.code == 0) {
                setMerchantList(res.data)
                setInit(false)
            } else {

            }
        });
    }
    const onFinish = (values) => {
        // const rangeValue = values['date'];
        // values.startTime = rangeValue && rangeValue[0].format('YYYY-MM-DD HH:mm:ss');
        // values.endTime = rangeValue && rangeValue[1].format('YYYY-MM-DD HH:mm:ss');
        values.currentPage = 1
        delete values.date;
        getTradeorderList(values)
    };
    const getTradeorderList = (data) => {
        const rangeValue = form.getFieldValue("date")
        let params = {
            ...data,
            currentPage: data && data.currentPage ? data.currentPage : currentPage,
            pageSize: pageNum,
            merchantCode:localStorage.appid === localStorage.merchantCode ? merchantCode :localStorage.merchantCode,
            superMerchantCode: localStorage.appid,
            startTime: rangeValue && rangeValue.length > 0 ? rangeValue[0].format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss'),
            endTime: rangeValue && rangeValue.length > 0 ? rangeValue[1].format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss'),
        };
        (async _ => {
            return await tradeorderListRequest(params);
        })().then(res => {
            if (res.code == 0) {
                setList(res.data.lists)
                setTotal(res.data.totalCount)
            } else {
                message.error(res.msg)
            }
        });
    }
    const inSevenDays = () => {
        form.setFieldsValue({
            date: [moment(moment().subtract(7, "days").format(dateFormat), dateFormat), moment(moment().format(dateFormat), dateFormat)]
        })
    }
    const yesterday = () => {
        form.setFieldsValue({
            date: [moment(moment().subtract(1, "days").format(dateFormat), dateFormat), moment(moment().subtract(1, "days").format(dateFormat), dateFormat)]
        })
    }
    const today = () => {
        form.setFieldsValue({
            date: [moment(moment().format(dateFormat), dateFormat), moment(moment().format(dateFormat), dateFormat)]
        })
    }
    const timeChange = (date) => {
        setStartDate(date && date[0].format("YYYY-MM-DD"))
        setEndDate(date && date[1].format('YYYY-MM-DD'))
    }
    const onChangPage = (currentPage, pageNum) => {
        setCurrentPage(currentPage, () => {
            // getTradeorderList()
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        setPageNum((currentPage, pageNum), () => {
            // getTradeorderList()
        })
    }
    return (
        <div style={{ background: '#ffffff', padding: 16, marginTop: 16 }}>

            <Form
                form={form}
                {...layout}
                name="advanced_search"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                style={{ background: '#F7F8FA', padding: 16, paddingBottom: 0 }}
                initialValues={{
                    orderType: null,
                    payType: null,
                    orderStatus: null,
                    settleStatus: null,
                    merchantCode:null,
                    date: [moment(moment().format(dateFormat), dateFormat), moment(moment().format(dateFormat), dateFormat)]
                }}
            >
                <Row style={{ marginBottom: '-1vh' }}>
                    {
                        localStorage.hxState == 'true' ?
                            <Col span={6}>
                                <Form.Item
                                    name="merchantCode"
                                    label="商户："
                                >
                                    <Select placeholder="请选择"
                                        style={{ width: 180 }}
                                        onChange={(code) => {
                                            setMerchantCode(code)
                                        }}
                                        allowClear
                                        optionFilterProp="children"
                                        showSearch
                                        filterOption={(input, option) => option.children.includes(input)}
                                    >
                                        <Option rowKey={null} key={null} value={null}>全部</Option>
                                        {
                                            merchantList && merchantList.map((v) => {
                                                return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                            })
                                        }

                                    </Select>
                                </Form.Item>
                            </Col>
                            :
                            <Col span={6}>
                                <Form.Item
                                    label="商户："
                                >
                                    {localStorage.merchantName}
                                </Form.Item>
                            </Col>
                    }

                </Row>
                <Row style={{ marginBottom: '-1vh' }}>
                    <Col span={6}>
                        <Form.Item
                            name="date"
                            label="下单时间："

                        >
                            <div style={{ display: 'flex', alignItems: 'center', width: 400, }}>
                                <Form.Item
                                    name="date"
                                    noStyle
                                >
                                    <RangePicker style={{ width: 250 }} onChange={timeChange} />
                                </Form.Item>
                                <Form.Item
                                    // name="date"
                                    noStyle
                                >
                                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }} onClick={today} type="link">今天</span>
                                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }} onClick={yesterday} type="link">昨天</span>
                                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }} onClick={inSevenDays} type="link">近7天</span>
                                </Form.Item>
                            </div>
                        </Form.Item>
                    </Col>
                    {/* <Col span={8}>
                        <Form.Item
                            wrapperCol={{
                                offset: 3,
                                span: 8,
                            }}
                        >
                            <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }} onClick={today} type="link">今天</span>
                            <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }} onClick={yesterday} type="link">昨天</span>
                            <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }} onClick={inSevenDays} type="link">近7天</span>
                        </Form.Item>
                    </Col> */}

                </Row>
                <Row style={{ marginBottom: '-1vh' }}>
                    <Col span={6}>
                        <Form.Item
                            name="orderNo"
                            label="订单编号："
                        >
                            <Input style={{ width: 180 }} />
                        </Form.Item>
                    </Col>
                    <Col span={6} >
                        <Form.Item
                            name="orderType"
                            label="订单类型："
                        >
                            <Select style={{ width: 180 }}>
                                <Option value={null}>全部</Option>
                                <Option value="0">消费收银</Option>
                                <Option value="1">会员充值</Option>
                                <Option value="2">付费会员</Option>
                                <Option value="3">付费卡券 </Option>
                                <Option value="4">线上商城</Option>
                                <Option value="5">活动订单</Option>
                                <Option value="6">押金订单</Option>
                                <Option value="7">酒店订单</Option>
                                <Option value="8">采购订单</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="payType"
                            label="支付方式："
                        >
                            <Select style={{ width: 180 }}>
                                <Option value={null}>全部</Option>
                                <Option value="0">微信</Option>
                                <Option value="1">支付宝</Option>
                                <Option value="2">会员</Option>
                                <Option value="3">银行卡</Option>
                                <Option value="4">现金</Option>
                                <Option value="5">云闪付</Option>
                                <Option value="6">漕河泾(饿了么)</Option>
                                <Option value="7">组合支付</Option>
                                <Option value="17">翼支付</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '-1vh' }}>
                    <Col span={6}>
                        <Form.Item
                            name="orderStatus"
                            label="订单状态："
                        >
                            <Select style={{ width: 180 }}>
                                <Option value={null}>全部</Option>
                                <Option value="1">已完成</Option>
                                <Option value="2">已退款</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="settleStatus"
                            label="结算状态："
                        >
                            <Select style={{ width: 180 }}>
                                <Option value={null}>全部</Option>
                                <Option value="0">未结算</Option>
                                <Option value="1">已结算</Option>
                                <Option value="2">结算失败</Option>
                                <Option value="3">无需结算</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <Form.Item
                            wrapperCol={{
                                offset: 6,
                                span: 18,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                筛选
                            </Button>
                            {/* <Button
                            style={{ margin: '0 8px' }}

                        >
                            导出
                        </Button> */}
                            {/* <Button type="link"  >
                            查看已导出列表
                        </Button> */}
                            <Button type="link" style={{ marginLeft: 20 }} onClick={() => {
                                form.resetFields();
                            }} >
                                重置筛选条件
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                style={{ marginTop: 16 }}
                columns={columns}
                dataSource={list}
                rowKey='id'
                scroll={{
                    x: 1800,
                }}
                locale={{
                    emptyText: "没有更多数据了"
                }}
                pagination={{
                    total,
                    showSizeChanger: true,
                    current: currentPage,
                    pageSize: pageNum,
                    // position: ["bottomCenter"],
                    showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                    onChange: (k, v) => onChangPage(k, v),
                    onShowSizeChange: (k, v) => onChangPageNum(k, v),
                }}
            />

        </div >
    )
}
export default CreditBalance;