import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import './style.less'
import {
    Form,
    Image,
    Input,
    Button,
    message,
    Row,
    Col
} from 'antd';
import { orderSaveRequest } from '../../../../api/api'
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
};
const LnstitutionsInList = (props) => {
    useEffect(() => {
        console.log(props)
    }, [])
    const bookRechargeProcess = () => {
        (async _ => {
            return await orderSaveRequest(props.data);
        })().then(res => {
            if (res.code == 0) {
                props.callback(true)
                props.closeDialog()
                message.success(res.msg)
            } else {
                message.error(res.msg)
            }
        });
    }

    const cancel = (status) => {
        props.closeDialog()
    }

    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            <Form
                name="basic"
                {...layout}
                style={{ width: 600 }}
            >
                <Row >
                    <Col span={12}>
                        <Form.Item label="付款机构" >
                            {/* {localStorage.merchantName} */}
                            {props && props.data.payerMerchantName ? props.data.payerMerchantName:localStorage.merchantName}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="收款机构">
                            {props && props.data.payeeMerchantName}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item label="付款账户" >
                            {props && props.data.payerAccountType == 1 ? '店铺余额' : '钱包余额'}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="收款账户">
                            {props && props.data.payerAccountType == 1 ? '店铺余额' : '钱包余额'}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item label="账户余额" >
                            <span style={{ fontWeight: '600' }}>￥{props && props.data.yue}</span> 元
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="转账金额" >
                            <span style={{ fontWeight: '600' }}>￥{props && props.data.amount}</span> 元
                        </Form.Item>
                    </Col>
                    {/* <Col span={12}>
                            <Form.Item label="预估手续费">
                                {props && props.data.accountType == 2 ? '钱包余额' : ''}
                            </Form.Item>
                        </Col> */}
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item label="转账用途" >
                            {props && props.data.transferPurposeName}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item label="转账附言" >
                            {props && props.data.remark}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div style={{ textAlign: 'right' }}>
                <Button type="primary" onClick={() => { bookRechargeProcess() }}>确认转账</Button>
                <Button style={{ marginLeft: 20 }} onClick={() => { cancel() }}>取消</Button>
            </div>


        </div>
    )
}

export default LnstitutionsInList;