import { Row, Col, Button, Form, Table, Select, message } from 'antd';
import { Link, useNavigate, useLocation, } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { creditAccountViewRequest, settleMerchantListRequest } from '../../../api/api'
import { useCallbackState } from '../../../util/useCallbackState'
const { Option } = Select;
const AvailableFunds = () => {
    const [form] = Form.useForm();
    const { search } = useLocation()
    const { state } = useLocation();
    const navigate = useNavigate()
    const [data, setData] = useState()
    const [list, setList] = useState()
    const [merchantList, setMerchantList] = useState([])
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [total, setTotal] = useState()
    const [init, setInit] = useState(true)
    const columns = [
        {
            title: '商户名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
            align: 'left',

        },
        {
            title: '授信额度(元)',
            dataIndex: 'totalBalance',
            key: 'totalBalance',
            align: 'left'
        },
        {
            title: '可用额度(元)',
            dataIndex: 'availableBalance',
            key: 'availableBalance',
            align: 'left'
        },
        {
            title: '待还款余额(元)',
            dataIndex: 'freezenBalance',
            key: 'freezenBalance',
            align: 'left',
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 140,
            align: 'right',
            render: (text, row) => (
                <>
                    <span style={{ color: '#165BD4', cursor: 'pointer' }}
                        onClick={() => {
                            navigate('/ws/creditAccount/credit', { replace: false, state: row })
                        }}
                    >详情</span>
                </>

            ),

        },
    ];
    useEffect(() => {
            getCreditAccountViewRequest()
            if (init) {
                settleMerchantList();
            }
    }, [currentPage, pageNum]);
 
    const onFinish = (values) => {
        values.currentPage = 1
        getCreditAccountViewRequest(values)
    };
    const settleMerchantList = () => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode: localStorage.merchantCode,
                superMerchantCode: localStorage.appid
            });
        })().then(res => {
            if (res.code == 0) {
                setInit(false)
                setMerchantList(res.data)
            } else {

            }
        });
    }
    const getCreditAccountViewRequest = (data) => {
        let params = {
            ...data,
            currentPage: data && data.currentPage ? data.currentPage : currentPage,
            pageSize: pageNum,
            merchantCode: localStorage.merchantCode,
            superMerchantCode: localStorage.appid,
        };
        (async _ => {
            return await creditAccountViewRequest(params);
        })().then(res => {
            if (res.code == 0) {
                setData(res.data)
                setList(res.data.list)
                setTotal(res.data.totalCount)
            } else {

            }
        });
    }
    const onChangPage = (currentPage, pageNum) => {
        setCurrentPage(currentPage, () => {
            // getCreditAccountViewRequest()
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        setPageNum((currentPage, pageNum), () => {
            // getCreditAccountViewRequest()
        })
    }
    return (
        <div style={{ background: '#ffffff', padding: 16, marginTop: 10 }}>
            <Row justify='space-between' style={{ background: '#F7F8FA', padding: 20, }}>
                <Col span={12}>
                    <div>
                        <span>授信额度(元)</span>
                    </div>
                    <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{data && data.creditBalance}</div>
                </Col>
                <Col span={12}>
                    <Row>
                        <Col span={12}>
                            <div><span>可用额度(元)</span></div>
                            <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{data && data.availableBalance}</div>
                        </Col>
                        <Col span={12}>
                            <div><span>待还款金额(元)</span>
                                <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 20 }}
                                    onClick={() => {
                                        navigate('/ws/creditAccount/historyBill')
                                    }}
                                >账单记录</span>
                                <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 20 }}
                                    onClick={() => {
                                        navigate('/ws/statementList', { replace: false, state: { accountType: '6', tag: 1 } })
                                    }}
                                >还款记录</span>
                            </div>
                            <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{data && data.repaidBalance}</div>
                        </Col>
                    </Row>
                </Col>
                {/* <Col span={6}>1</Col>
                <Col span={6}>1</Col> */}
            </Row >
            <Row style={{ marginTop: 16 }}>
                <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}
                    initialValues={{
                        subMerchantCode: null
                    }}
                >
                    <Form.Item
                        name="subMerchantCode"
                        label="商户名称："
                        rules={[{ required: false, message: 'Please input your username!' }]}
                    >
                        <Select
                            placeholder="请选择"
                            style={{ width: 250 }}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.includes(input)}

                        >
                            <Option key={null} value={null}>全部</Option>
                            {
                                merchantList && merchantList.map((v) => {
                                    return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                })
                            }

                        </Select>

                    </Form.Item>
                    <Form.Item shouldUpdate>
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            筛选
                        </Button>
                        {/* <Button
                            style={{ marginLeft: 20 }}
                        >
                            导出
                        </Button>
                        <Button
                            style={{ marginLeft: 20 }}
                            type="link"
                        >
                            查看已导出列表
                        </Button> */}
                        <Button
                            style={{ marginLeft: 20 }}
                            type="link"
                            onClick={() => {
                                form.setFieldsValue({
                                    merchantCode: localStorage.merchantCode
                                })
                            }}

                        >
                            重置筛选条件
                        </Button>
                    </Form.Item>
                </Form>

            </Row>
            <Table
                style={{ marginTop: 16 }}
                columns={columns}
                dataSource={list}
                rowKey="id"
                locale={{
                    emptyText: "没有更多数据了"
                }}
                pagination={{
                    total,
                    showSizeChanger: true,
                    current: currentPage,
                    pageSize: pageNum,
                    // position: ["bottomCenter"],
                    showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                    onChange: (k, v) => onChangPage(k, v),
                    onShowSizeChange: (k, v) => onChangPageNum(k, v),
                }}
            />
        </div >
    )
}
export default AvailableFunds