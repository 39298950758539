import React, { useState, useEffect, useReducer } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { Popconfirm, Modal, Form, Row, Col, Input, Select, message, Button, Table } from 'antd';
import {
    bankCardNameListRequest,
    settleMerchantListRequest,
    bankCardNameRequest,
    authBankListPCRequest,
    deleteRequest
} from '../../api/api'
import { useCallbackState } from '../../util/useCallbackState'
import './style.less'
const Option = Select.Option
const dateFormat = 'YYYY-MM-DD';
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

const BankCard = () => {
    const [form] = Form.useForm();
    const { state } = useLocation();
    const [list, setList] = useState([])
    const [merchantList, setMerchantList] = useState([])
    const [merchantCode, setMerchantCode] = useCallbackState(null)
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [bankCardList, setBankCardList] = useState()
    const [total, setTotal] = useState()
    const [init, setInit] = useState(true)
    const superMerchantCode = localStorage.appid;
    const navigate = useNavigate();
    const columns = [
        {
            title: '所属机构',
            dataIndex: 'merchantName',
            key: 'merchantName',
        },
        {
            title: '主体类型',
            dataIndex: 'openTypeName',
            key: 'openTypeName',
        },
        {
            title: '卡类型',
            dataIndex: 'cardType',
            key: 'cardType',
            render(text) {
                return (
                    <div>
                        <div>{text == 0 ? '对公' : null}</div>
                        <div>{text == 1 ? '对私' : null}</div>
                        <div>{text == 2 ? '对私非法人' : null}</div>
                    </div>

                )
            },
        },

        {
            title: '银行户名',
            dataIndex: 'cardName',
            key: 'cardName',
        },
        {
            title: '开户银行',
            dataIndex: 'bankName',
            key: 'bankName',
        },
        {
            title: '银行卡号',
            dataIndex: 'cardNo',
            key: 'cardNo',
        },
        {
            title: '状态',
            dataIndex: 'openStatusName',
            key: 'openStatusName',
        },
        {
            title: '操作',
            dataIndex: '',
            key: '',
            render: (text, row) => (
                <>
                    {/* {
                        row.bankEnterType == "1" ?
                            <span style={{ color: '#165BD4', cursor: 'pointer' }} onClick={() => {
                                row.edit = 0
                                navigate('/ws/enterpriseCard', { replace: false, state: row })
                            }}>编辑</span> : null
                    } */}
                    {
                        <span style={{ color: '#165BD4', cursor: 'pointer' }} onClick={() => {
                            row.edit = 1
                            if (row.openType == 2) {
                                navigate('/ws/individualSettlement', { replace: false, state: row })
                            } else {
                                navigate('/ws/enterpriseSettlement', { replace: false, state: row })
                            }

                        }}>编辑</span>

                    }
                    {
                        row.openStatus == 'F' ?
                            <Popconfirm

                                title="是否确认删除?"
                                onConfirm={() => {
                                    (async _ => {
                                        return await deleteRequest({
                                            id: row.id
                                        });
                                    })().then(res => {
                                        console.log(res)
                                        if (res.code == 0) {
                                            getBankCardList()
                                            message.success(res.msg)
                                        } else {
                                            message.error(res.msg)
                                        }
                                    });
                                }}
                                onCancel={() => {

                                }}
                                okText="是"
                                cancelText="否"
                            >
                                <a style={{ marginLeft: 10 }} href="#">删除</a>
                            </Popconfirm> : null
                    }

                </>
            ),
        },
    ];
    useEffect(() => {
        bankCardNameList()
        getBankCardList()
        settleMerchantList()
        bankCardName();
    }, [])
    const bankCardName = () => {
        (async _ => {
            return await bankCardNameRequest();
        })().then(res => {
            if (res.code == 0) {
                setBankCardList(res.data)
            } else {
                // message.error(res.msg)
            }
        });
    }
    const settleMerchantList = () => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode: localStorage.merchantCode,
                superMerchantCode,
            });
        })().then(res => {
            if (res.code == 0) {
                setInit(false)
                setMerchantList(res.data)
            } else {
                // message.error(res.msg)
            }
        });
    }

    useEffect(() => {
        getBankCardList({ currentPage, pageNum })
    }, [currentPage, pageNum])

    const onChangPage = (currentPage, pageNum) => {
        // setInit(false)
        setCurrentPage(currentPage)
        setPageNum(pageNum)
    }
    const onChangPageNum = (currentPage, pageNum) => {
        // setInit(false)
        setCurrentPage(currentPage)
        setPageNum(pageNum)
    }

    const getBankCardList = (data) => {
        console.log(data);
        (async _ => {
            return await authBankListPCRequest({
                ...data,
                merchantCode: localStorage.merchantCode,
                subMerchantCode: data && data.subMerchantCode,
                superMerchantCode: localStorage.appid,
                employeeId: localStorage.salesEmployeeId != 'null' ? localStorage.salesEmployeeId : null,
                currentPage: data && data.currentPage ? data.currentPage : currentPage,
                pageSize: data && data.pageNum ? data.pageNum : pageNum,
            });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setList(res.data.lists)
                setTotal(res.data.totalCount)
                setCurrentPage(res.data.pageNumber)
            } else {

            }
        });
    }

    const bankCardNameList = () => {
        (async _ => {
            return await bankCardNameListRequest({
                merchantCode: localStorage.merchantCode,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setBankCardList(res.data)
            } else {

            }
        });
    }

    const onFinish = (values) => {
        const rangeValue = values['date'];
        values.startTime = rangeValue && rangeValue[0].format('YYYY-MM-DD HH:mm:ss');
        values.endTime = rangeValue && rangeValue[1].format('YYYY-MM-DD HH:mm:ss');
        values.currentPage = 1;
        delete values.date
        getBankCardList(values)
    };
    const goAdd = () => {
        navigate('/ws/guidePage', { replace: false, state: { edit: 0 } })
    }
    return (
        <>
            <div style={{ background: '#ffffff', paddingTop: 1, marginTop: 16 }}>
                {
                    localStorage.hxState == 'true' && localStorage.salesEmployeeId == 'null' ?
                        <Row style={{ marginBottom: '-1vh', margin: 16, }}>
                            <Button type='primary' onClick={goAdd}>添加</Button>
                        </Row> : null
                }
                <Form
                    form={form}
                    {...layout}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                    style={{ background: '#F7F8FA', margin: 16, padding: 16, paddingBottom: 0 }}
                    initialValues={{
                        subMerchantCode: null,
                        bankCode: null,
                        openType: null,
                        cardType: null,
                        openStatus: null,
                    }}
                >

                    {
                        localStorage.salesEmployeeId == "null" ?
                            <Row style={{ marginBottom: '-1vh' }}>
                                {
                                    localStorage.hxState == 'true' ?
                                        <Col span={6}>
                                            <Form.Item
                                                name="subMerchantCode"
                                                label="商户："
                                            >
                                                <Select placeholder="请选择"
                                                    style={{ width: 180 }}
                                                    onChange={(code) => {
                                                        setMerchantCode(code)
                                                    }}
                                                    allowClear
                                                    optionFilterProp="children"
                                                    showSearch
                                                    filterOption={(input, option) => option.children.includes(input)}
                                                >
                                                    <Option value={null}>全部</Option>
                                                    {
                                                        merchantList && merchantList.map((v) => {
                                                            return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                                        })
                                                    }

                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        :
                                        <Col span={6}>
                                            <Form.Item
                                                label="商户："
                                            >
                                                {localStorage.merchantName}
                                            </Form.Item>
                                        </Col>

                                }

                            </Row> : null
                    }




                    <Row style={{ marginBottom: '-1vh' }}>
                        <Col span={6} >
                            <Form.Item
                                name="cardName"
                                label="银行户名"
                            >
                                <Input placeholder='请输入' style={{ width: 180 }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="cardNo"
                                label="卡号"
                            >

                                <Input placeholder='请输入' style={{ width: 180 }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="bankCode"
                                label="开户银行"
                            >

                                <Select placeholder="请选择"
                                    style={{ width: 180 }}
                                    allowClear
                                    optionFilterProp="children"
                                    showSearch
                                    filterOption={(input, option) => option.children.includes(input)}
                                >
                                    {
                                        bankCardList && bankCardList.map((v) => {
                                            return <Option rowKey={v.dgBankCode} key={v.dgBankCode} value={v.dgBankCode}>{v.bankName}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '-1vh' }}>
                        <Col span={6}>
                            <Form.Item
                                name="openType"
                                label="主体类型"
                            >
                                <Select style={{ width: 180 }}>
                                    <Option value={null}>全部</Option>
                                    <Option value="0">企业</Option>
                                    <Option value="1">个体户</Option>
                                    <Option value="2">个人</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="cardType"
                                label="卡类型"
                            >
                                <Select style={{ width: 180 }}>
                                    <Option value={null}>全部</Option>
                                    <Option value={0}>对公</Option>
                                    <Option value={1}>对私</Option>
                                    <Option value={2}>对公非法人</Option>

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="openStatus"
                                label="状态"
                            >
                                <Select style={{ width: 180 }}>
                                    <Option value={null}>全部</Option>
                                    <Option value={'S'}>成功</Option>
                                    <Option value={'F'}>失败</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '-1vh' }}>
                        <Col span={12}>
                            <Form.Item
                                wrapperCol={{
                                    offset: 3,
                                    span: 20,
                                }}
                            >
                                <Button type="primary" htmlType="submit">
                                    筛选
                                </Button>
                                <Button type="link"
                                    style={{ marginLeft: 10 }}
                                    onClick={() => {
                                        form.resetFields();
                                        if (merchantCode) {
                                            setMerchantCode(null, (merchantCode) => {
                                                getBankCardList({ subMerchantCode: merchantCode, currentPage: 1 });
                                            })
                                        } else {
                                            getBankCardList({ currentPage: 1 });
                                        }


                                    }} >
                                    重置筛选条件
                                </Button>


                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Table
                    locale={{
                        emptyText: "没有更多数据了"
                    }}
                    rowKey='id'
                    style={{ marginTop: 16, margin: '0 16px' }}
                    columns={columns}
                    dataSource={list}

                    pagination={{
                        total,
                        showSizeChanger: true,
                        current: currentPage,
                        pageSize: pageNum,
                        // position: ["bottomCenter"],
                        showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                        onChange: (k, v) => onChangPage(k, v),
                        onShowSizeChange: (k, v) => onChangPageNum(k, v),
                    }}
                />



            </div >

        </>

    )
}

export default BankCard;