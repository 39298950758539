import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
    Form,
    Row,
    Col,
    Button,
    Input,
    Select,
    Table,
    Popconfirm,
    message,
    Modal
} from 'antd';
import { expenseListRequest, expenseDeleteRequest, settleMerchantListRequest } from '../../../api/api'
import { useCallbackState } from '../../../util/useCallbackState'
import Detail from '../detail/index'
const { Option } = Select;
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
};
const LnstitutionsInList = () => {
    const [list, setList] = useState([])
    const [merchantList, setMerchantList] = useState([])
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [total, setTotal] = useState(0)
    const [init, setInit] = useState(true)
    const [detailVisible, setDetailVisible] = useState(false);
    const [row, setRow] = useState(null)
    const navigate = useNavigate()
    const { search } = useLocation()
    const columns = [
        {
            title: '支出方',
            dataIndex: 'payerMerchantName',
            key: 'payerMerchantName',
        },
        {
            title: '收入方',
            dataIndex: 'receiptMerchantName',
            key: 'receiptMerchantName',
        },
        {
            title: '单据类型',
            dataIndex: 'expenseTypeName',
            key: 'expenseTypeName',
        },
        {
            title: '单据名称',
            dataIndex: 'expenseName',
            key: 'expenseName',
        },
        {
            title: '收支类型',
            dataIndex: 'expensePayType',
            key: 'expensePayType',
            render: (text, row) => (
                <div>
                    {row.expensePayType == 1 ? '定额' : ''}
                    {row.expensePayType == 2 ? '比例' : ''}
                    {row.expensePayType == 3 ? '区间' : ''}
                </div>
            ),
        },
        {
            title: '金额',
            dataIndex: 'index',
            key: 'index',
            render: (text, row) => (
                <div>
                    {row.expensePayType == 1 ? <span>{row.expensePayAmount}</span> : ''}
                    {row.expensePayType == 2 ? <span>{row.expensePayProportion}%</span> : ''}
                    {row.expensePayType == 3 ? <span>{row.expensePaySectionJson}</span> : ''}
                </div>
            ),

        },
        {
            title: '状态',
            dataIndex: 'expenseStatus',
            key: 'expenseStatus',
            render: (text, row) => (
                <div>
                    {row.expenseStatus == 0 ? '未开启' : ''}
                    {row.expenseStatus == 1 ? '进行中' : ''}
                    {row.expenseStatus == 2 ? '停用' : ''}
                    {row.expenseStatus == 3 ? '停用' : ''}
                    {row.expenseStatus == 4 ? '停用' : ''}

                </div>
            ),
        },

        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 120,
            align: 'right',
            render: (text, row) => (
                <div>

                    {
                        localStorage.appid == localStorage.merchantCode && row.expenseStatus == 0 ?
                            <span style={{ color: '#165BD4', cursor: 'pointer' }} onClick={() => goEdit(row)}>编辑</span>
                            : ''
                    }
                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }} onClick={() => detailShowModal(row)}>详情</span>
                    {/* <Link style={{ marginLeft: 10 }} to="/ws/bills/detail" > </Link> */}
                    {
                        localStorage.hxState == 'true' && row.expenseStatus == 1 ?
                            <Popconfirm
                                title="是否确认停用?"
                                onConfirm={() => {
                                    expenseDelete(row)
                                }}
                                onCancel={() => {

                                }}
                                okText="是"
                                cancelText="否"
                            >
                                <a style={{ marginLeft: 10 }} href="#">停用</a>
                            </Popconfirm> : ''
                    }
                </div>





            ),

        },
    ];

    useEffect(() => {
        getExpenseListRequest();
        if (init) {
            settleMerchantList();
        }
    }, [currentPage, pageNum,])
    const detailShowModal = (data) => {
        setRow(data)
        setDetailVisible(true);
    };

    const detailHandleOk = () => {
        setDetailVisible(false);
    };

    const detailhandleCancel = () => {
        setDetailVisible(false);
    };
    const [form] = Form.useForm();

    const goDetail = (row) => {
        navigate('/ws/bills/detail', { replace: false, state: row })
    }
    const goEdit = (row) => {
        navigate('/ws/bills/add', { replace: false, state: row })
    }


    const settleMerchantList = () => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode: localStorage.merchantCode,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            if (res.code == 0) {
                setInit(false)
                setMerchantList(res.data)
            } else {

            }
        });
    }
    const expenseDelete = (row) => {
        (async _ => {
            return await expenseDeleteRequest({ id: row.id });
        })().then(res => {
            if (res.code == 0) {
                getExpenseListRequest()
            } else {
                message.error(res.msg)
            }
        });
    }
    const onFinish = (values) => {
        values.currentPage = 1;
        getExpenseListRequest(values)
    };
    const add = () => {
        navigate('/ws/bills/add')
    }
    const getExpenseListRequest = (data) => {
        let values = form.getFieldValue()
        let params = {
            ...data,
            ...values,
            currentPage: data && data.currentPage ? data.currentPage : currentPage,
            pageSize: pageNum,
            superMerchantCode: localStorage.appid
        };
        (async _ => {
            return await expenseListRequest(params);
        })().then(res => {
            if (res.code == 0) {
                setList(res.data.expenseVOs)
                setTotal(res.data.totalCount)
            } else {

            }
        });
    }
    const onChangPage = (currentPage, pageNum) => {
        setCurrentPage(currentPage, () => {
            // getExpenseListRequest()
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        setPageNum((currentPage, pageNum), () => {
            // getExpenseListRequest()
        })
    }
    return (
        <div style={{ background: '#ffffff', padding: 16, marginTop: 16 }}>
            <Row>
                {localStorage.appid == localStorage.merchantCode ?
                    <Button type='primary' onClick={add}>新建费用单</Button>
                    : ''
                }
            </Row>

            <Form
                {...layout}
                form={form}
                onFinish={onFinish}
                initialValues={{
                    expenseStatus: null,
                    merchantCode: localStorage.merchantCode
                }}
                style={{ marginTop: 16, background: '#F7F8FA', padding: 16, paddingBottom: 0, }}
            >
                <Row style={{ marginBottom: '-1vh' }}>
                    {
                        localStorage.hxState == 'true' ?
                            <Col span={8}>
                                <Form.Item
                                    name="merchantCode"
                                    label="商户"
                                >
                                    <Select placeholder="请选择"
                                        style={{ width: 200 }}
                                        allowClear
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.includes(input)}
                                    >
                                        {
                                            merchantList && merchantList.map((v) => {
                                                return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                            })
                                        }

                                    </Select>
                                </Form.Item>
                            </Col>
                            :
                            <Col span={8}>
                                <Form.Item
                                    label="机构名称"
                                >
                                    {localStorage.merchantName}
                                </Form.Item>
                            </Col>
                    }

                    <Col span={8}>
                        <Form.Item
                            name="expenseStatus"
                            label="单据状态："
                        // style={{ marginLeft: 30 }}
                        >
                            <Select style={{ width: 200 }}>
                                <Option value={null}>全部</Option>
                                <Option value="0">未开启</Option>
                                <Option value="1">进行中</Option>
                                <Option value="2">到期停用</Option>
                                <Option value="3">手动停用</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="expenseName"
                            label="费用单名称："
                        // style={{ marginLeft: 30 }}
                        >
                            <Input style={{ width: 200 }} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{ marginBottom: '-1.5vh' }}>
                    <Col span={8}>
                        <Form.Item
                            wrapperCol={{
                                offset: 6,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                筛选
                            </Button>
                            {/* <Button
                                style={{ margin: '0 8px' }}

                            >
                                导出
                            </Button>
                            <Button type="link"  >
                                查看已导出列表
                            </Button> */}
                            <Button type="link" onClick={() => {
                                form.setFieldsValue({
                                    expenseStatus: null,
                                    merchantCode: localStorage.merchantCode,
                                    expenseName: ''
                                })
                                getExpenseListRequest()
                            }} >
                                重置筛选条件
                            </Button>
                        </Form.Item>

                    </Col>
                </Row>
                <Row>

                </Row>
            </Form>

            <Table
                locale={{
                    emptyText: "没有更多数据了"
                }}
                style={{ marginTop: 20 }}
                columns={columns}
                dataSource={list}
                rowKey='id'
                scroll={{
                    x: 1300,
                }}
                pagination={{
                    total,
                    showSizeChanger: true,
                    current: currentPage,
                    pageSize: pageNum,
                    // position: ["bottomCenter"],
                    showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                    onChange: (k, v) => onChangPage(k, v),
                    onShowSizeChange: (k, v) => onChangPageNum(k, v),
                }}
            />
            <Modal
                title="详情"
                visible={detailVisible}
                onOk={detailHandleOk}
                onCancel={detailhandleCancel}
                width={1200}
                footer={null}
            >
                <Detail data={row} />
            </Modal>
        </div >
    )
}
export default LnstitutionsInList;