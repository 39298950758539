import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
    Form,
    Input,
    Switch,
    Row,
    Col,
    Button,
    Tabs,
    Table,
    Modal,
    Radio,
    Space,
    message
} from 'antd';
import { marketingViewRequest, employeeViewRequest } from '../../api/api'

const { TabPane } = Tabs;
const FormItem = Form.Item;
const Search = Input.Search;



const StoreValue = () => {
    const { state } = useLocation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [radioValue, setRadioValue] = useState(1);
    const [list, setList] = useState([])
    const [data, setData] = useState(null)
    const [accountType, setAccountType] = useState("8")
    const { search } = useLocation()
    const myId = localStorage.salesEmployeeId != 'null' ? localStorage.salesEmployeeId : null;
    let navigate = useNavigate();
    useEffect(() => {
        console.log(state);
        if (localStorage.hxState == 'true') {
            if (state && state.accountType == 0) {
                getIncomeView()
            } else {
                employeeView()
            }
        } else {
            if (myId) {
                employeeAccount()
            } else {
                if (state && state.accountType == 0) {
                    getIncomeView()
                } else {
                    employeeView()
                }

            }
        }
    }, [accountType])

    const onChange = (checked) => {
        setAccountType(checked)
    };

    const columns = [
        {
            title: '时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '订单类型',
            dataIndex: 'busiType',
            key: 'busiType',
        },
        {
            title: '支付方式｜支付单号',
            key: 'orderNo',
            dataIndex: 'orderNo',
            render: (text, row) => {
                return (
                    <div>
                        <div>{row.orderNo}</div>
                        <div style={{ color: '#969799' }}>{row.thirdTradeNo}</div>
                    </div>
                )
            }
        },
        {
            title: '金额(元)',
            dataIndex: 'amount',
            key: 'amount',
            render: (text, row) => {
                return (
                    <div>
                        {row.type == 0 ? <span style={{ color: '#0FAF09' }}>+{row.amount}</span> : <span style={{ color: '#AF0909' }}>-{row.amount}</span>}
                    </div>
                )
            }
        },
        {
            title: '余额(元)',
            dataIndex: 'afCaBalance',
            key: 'afCaBalance',
        },
        {
            title: '状态',
            key: 'orderNo',
            dataIndex: 'orderNo',
            render: (text, row) => {
                return (
                    <div>
                        <div>{row.orderNo}</div>
                        <div style={{ color: '#969799' }}>{row.thirdTradeNo}</div>
                    </div>
                )
            }
        },
    ];
    const getIncomeView = (data) => {
        (async _ => {
            return await marketingViewRequest(
                {
                    ...data,
                    accountType,
                    merchantCode: state && state.merchantCode ? state.merchantCode : localStorage.merchantCode,
                    superMerchantCode: localStorage.appid,
                    pageSize: 10,
                    currentPage: 1,
                }
            );
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setList(res.data.list)
                setData(res.data)
            } else {
                message.error(res.msg)
            }
        });
    }
    const employeeView = (data) => {
        (async _ => {
            return await employeeViewRequest(
                {
                    ...data,
                    accountType,
                    merchantCode: state && state.row.merchantCode ? state.row.merchantCode : localStorage.merchantCode,
                    superMerchantCode: localStorage.appid,
                    pageSize: 10,
                    currentPage: 1,
                }
            );
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setList(res.data.list)
                setData(res.data)
            } else {
                message.error(res.msg)
            }
        });
    }

    const employeeAccount = (data) => {
        (async _ => {
            return await employeeViewRequest(
                {
                    ...data,
                    accountType,
                    merchantCode: state && state.employeeId ? undefined : state && state.row.merchantCode ? state.row.merchantCode : localStorage.merchantCode,
                    superMerchantCode: localStorage.appid,
                    employeeId: state && state.employeeId ? state.employeeId : myId,
                    pageSize: 10,
                    currentPage: 1,
                }
            );
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setList(res.data.list)
                setData(res.data)
            } else {
                message.error(res.msg)
            }
        });
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const radioChange = (e) => {
        setRadioValue(e.target.value);
    };
    const goWithdrawalRecord = () => {
        navigate('/ws/balance/withdrawal', { replace: false, state: { defaultActiveKey: 2, tag: 1, } })
    }

    const goWithdrawal = () => {
        navigate('/ws/balance/withdrawal', { replace: false, state: { data: state && state.row, defaultActiveKey: 1, tag: 1, } })
    }

    const goSettlementRecords = () => {
        navigate('/ws/balance/settlementRecords', { replace: false, state: { ...state, accountType: 7 } })
    }
    return (
        <div style={{ height: '100vh', background: '#ffffff', paddingBottom: 20 }}>

            <Row align='middle' style={{ padding: 16, marginTop: 10 }}>
                <Row align='middle' style={{ padding: '10px 10px', width: '100%', height: '100%', background: '#F7F8FA' }}>
                    <div style={{ background: '#145BD5', height: 14, width: 3, }}></div><div style={{ marginLeft: 10, fontWeight: 500 }}>{state && state.accountType == 0 ? '门店' : '员工'}营销余额</div>
                </Row>
            </Row>
            <Row style={{ background: '#F7F8FA', padding: 20, margin: 16 }}>
                <Col span={6}>
                    <div>
                        <span>营销可用余额(元)</span>
                        <span style={{ marginLeft: 10, color: '#165BD4', cursor: 'pointer' }} onClick={goWithdrawalRecord}>提现记录</span>
                    </div>
                    <Row align='middle' style={{ marginTop: 10 }}>
                        <span style={{ fontSize: 24, fontWeight: 500 }}>{data && data.availableBalance}</span>
                        <Button type='primary' style={{ marginLeft: 10, }} onClick={goWithdrawal}>提现</Button>
                    </Row>

                </Col>
                <Col span={6}>
                    <div>
                        <span>待结算金额(元)</span>
                        {
                            localStorage.salesEmployeeId == 'null' ?
                                <span style={{ marginLeft: 10, color: '#165BD4', cursor: 'pointer' }} onClick={goSettlementRecords}>结算记录</span>
                                : null
                        }
                    </div>
                    <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{data && data.settleBalance}</div>
                </Col>
                <Col span={6}>
                    <div>
                        <span>不可用余额(元)</span>
                        <span style={{ marginLeft: 10, color: '#165BD4', cursor: 'pointer' }} onClick={() => {
                            navigate('/ws/balance/availableAndAvailable', { replace: false, state: { ...state, tag: 1, }, })
                        }}>明细</span>
                    </div>
                    <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{data && data.freezenBalance}</div>
                </Col>
            </Row>
            <Row align='middle' style={{ padding: 16, paddingTop: 0 }}>
                <Row align='middle' justify='space-between' style={{ padding: '10px 10px', width: '100%', height: '100%', background: '#F7F8FA' }}>
                    <Row align='middle' >
                        <div style={{ background: '#145BD5', height: 14, width: 3, }}></div><div style={{ marginLeft: 10, fontWeight: 500 }}>最近收支记录</div>
                    </Row>

                    <Col>
                        <Button type='link'
                            onClick={() => {
                                if (localStorage.salesEmployeeId == 'null') {
                                    navigate('/ws/statementList', { replace: false, state: { ...state.row, accountType: '8', tag: 1, } })

                                } else {
                                    navigate('/ws/statementList', { replace: false, state: { accountType: '8', tag: 1, } })
                                }

                            }}
                        >全部收支记录</Button>
                    </Col>
                </Row>
            </Row>
            <Row style={{ padding: "0px 16px", marginTop: 10 }}>
                <Tabs onChange={onChange} type="card" defaultValue="">
                    <TabPane tab="营销可用余额" key="8">
                    </TabPane>
                    <TabPane tab="待结算金额" key="7">
                    </TabPane>
                </Tabs>
            </Row>
            <Row style={{ padding: "0px 10px" }}>
                <Col span={24}>
                    <Table
                        locale={{
                            emptyText: "没有更多数据了"
                        }}
                        rowKey='id'
                        columns={columns}
                        dataSource={list}
                        pagination={false}
                    />
                </Col>

            </Row>
            <Modal title="选择还款方式" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Radio.Group onChange={radioChange} value={radioValue}>
                    <Space direction="vertical">
                        <div>
                            <Radio value={1}><span>可用店铺余额(¥19999.00)</span><span style={{ color: '#D70000', marginLeft: 100 }}>余额不足</span></Radio>
                        </div>

                        <Radio value={2}>在线支付</Radio>
                    </Space>
                </Radio.Group>
                <div>图片</div>
            </Modal>
        </div >

    )
}
export default StoreValue;
