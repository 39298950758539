import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment'
import {
    Form,
    Row,
    Col,
    Button,
    Table,
    DatePicker,
    Input,
    Select,
    message,
    Popconfirm,
    Tabs,
    Radio,
    Steps,
    Popover,
    Modal
} from 'antd';
import {
    bookAccountDetailRequest
} from '../../../api/api'
import './style.less'

const AccountDetail = () => {
    const { state } = useLocation()
    const [data, setData] = useState(null)


    useEffect(() => {
        bookAccountDetail()
    }, [])


    const bookAccountDetail = () => {
        (async _ => {
            return await bookAccountDetailRequest({ id: state.id });
        })().then(res => {
            console.log(res)
            if (res.code == 0) {
                setData(res.data)
            } else {
                message.error(res.msg)
            }
        });
    }



    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            <div>
                <Form
                    name="basic"
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        span: 6,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    autoComplete="off"
                >
                    <div style={{ width: '100%', marginLeft: 20, marginTop: 20, marginBottom: 20 }} className='borderBottom'>基本信息</div>

                    <Form.Item
                        label="账户名称"
                        rules={[
                            {
                                required: false,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        {data && data.accountName}
                    </Form.Item>
                    <Col style={{ marginBottom: '-2vh' }}>
                        <Form.Item
                            label="账户状态"
                            rules={[{ required: false, message: '请输入提现金额!' }]}
                        >
                            <div style={{ display: 'flex' }}>
                                <Form.Item
                                >
                                    {data && data.accountStatus == 1 ? '已启用' : '已停用'}
                                </Form.Item>
                                {/* <Form.Item
                                    noStyle
                                >
                                    <Button type='link'
                                        onClick={() => {
                                        }}>启用</Button>

                                </Form.Item> */}
                            </div>
                        </Form.Item>
                    </Col>
                    <div style={{ width: '100%', marginTop: 20, marginLeft: 20, marginBottom: 20 }} className='borderBottom'>入驻信息</div>
                    <Col>
                        <Form.Item

                            label="账户描述" >

                            {data && data.accountDescription}
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            label="订单类型"
                            rules={[{ required: false, message: '请输入提现金额!' }]}
                        >
                            充值
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            label="银行卡"
                            rules={[{ required: false, message: '请输入提现金额!' }]}
                        >
                            <div className='bankcard'>
                                <div className='title'>
                                    <div className='title-left'>{data && data.bankName}</div>
                                    <div className='title-right'>{data && data.bankType == 1 ? '对公' : '对私'}</div>
                                </div>
                                <div className='main'>
                                    <div className='main1'>
                                        {data && data.companyName}
                                    </div>
                                    <div className='main2'>
                                        {data && data.bankCardNo}
                                    </div>
                                    <div className='main3'>
                                        {data && data.bankBranchName}
                                    </div>
                                </div>
                            </div>
                        </Form.Item>
                    </Col>
                    {/* <Form.Item label="描述：">
                                --
                            </Form.Item> */}
                    <div style={{ width: '100%', marginTop: 20, marginLeft: 20, marginBottom: 20 }} className='borderBottom'>创建信息</div>
                    <Col>
                        <Form.Item label="创建人：">
                            {state && state.operator ? state.operator : localStorage.operatorname ? localStorage.operatorname : undefined}
                        </Form.Item>
                    </Col>
                    <Col >
                        <Form.Item label="创建时间：">
                            {state && state.createTime ? state.createTime : moment().format('YYYY-MM-DD HH:mm:ss')}
                        </Form.Item>
                    </Col>


                </Form>
            </div>



        </div>
    )
}

export default AccountDetail;