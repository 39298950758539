import React, { useState, useEffect, useReducer } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import {
    Form,
    Row,
    Col,
    Button,
    Input,
    Select,
    Table,
    Popconfirm,
    DatePicker,
    Modal,
    message
} from 'antd';
import {
    settleMerchantListRequest,
    bookAccountListRequest,
    bookOrderListRequest,
    getBusiListRequest
} from '../../../api/api'
import { useCallbackState } from '../../../util/useCallbackState'
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};
const { RangePicker } = DatePicker;
const KeepAccountsList = () => {
    const [form] = Form.useForm();
    const { state } = useLocation();
    const [typeList, setTypeList] = useState([])
    const [list, setList] = useState([])
    const [merchantList, setMerchantList] = useState([])
    const [merchantCode, setMerchantCode] = useCallbackState(state && state.merchantCode ? state.merchantCode : localStorage.merchantCode)
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [total, setTotal] = useState()
    const [init, setInit] = useState(true)
    const superMerchantCode = localStorage.appid;
    const [accountList, setAccountList] = useState([])
    const [accountName, setAccountName] = useState(null)
    const [accountNo, setAccountNo] = useState(state && state.accountNo ? state.accountNo : null)
    const { search } = useLocation()
    useEffect(() => {
        getBusiList()
    }, [])
    useEffect(() => {
        console.log(state);
        bookOrderList()
        bookAccountList();
        if (init) {
            settleMerchantList();
        }

    }, [currentPage, pageNum])
    const columns = [
        {
            title: '账本所属店铺',
            dataIndex: 'merchantName',
            key: 'merchantName',
        },
        {
            title: '记账时间',
            dataIndex: 'bookTime',
            key: 'bookTime',
        },

        {
            title: '发生金额(元)',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: '余额(元)',
            dataIndex: 'afAmount',
            key: 'afAmount',
        },
        {
            title: '记账账户',
            dataIndex: 'accountName',
            key: 'accountName',
        },
        {
            title: '收支类型',
            dataIndex: 'bookType',
            key: 'bookType',
            render: (text, row) => {
                return (
                    <div>
                        {row.bookType == 0 ? '入账' : ''}
                        {row.bookType == 1 ? '出账' : ''}

                    </div>
                )
            },
        },
        {
            title: '订单类型',
            dataIndex: 'orderTypeName',
            key: 'orderTypeName',
        },
        {
            title: '名称',
            dataIndex: 'bookName',
            key: 'bookName',
        },
        {
            title: '订单编号/退款单号',
            dataIndex: 'logStatus',
            key: 'logStatus',
            render: (text, row) => {
                return (
                    <div>
                        <div>{row.orderNo}</div>
                        <div>{row.payTradeNo}</div>
                    </div>
                )
            }
        },
        {
            title: '交易对象',
            key: 'tradeObjectName',
        },
        {
            title: '支付方式',
            key: 'payType',
            render: (text, row) => {
                return (
                    <div>
                        {row.payType == 0 ? '微信' : ''}
                        {row.payType == 1 ? '支付宝' : ''}
                        {row.payType == 3 ? '银行卡' : ''}
                        {row.payType == 4 ? '现金' : ''}
                        {row.payType == 5 ? '店铺余额' : ''}
                    </div>
                )
            },
        },
        {
            title: '交易发生的店铺',
            key: 'tradeMerchantName',
            render: (text, row) => {
                return (
                    <div>{row.tradeMerchantName}</div>
                )
            }
        },

    ];
    const getBusiList = () => {

        (async _ => {
            return await getBusiListRequest({});
        })().then(res => {
            if (res.code == 0) {
                setTypeList(res.data)
            } else {

            }
        });
    }
    const bookAccountList = (code) => {
        (async _ => {
            return await bookAccountListRequest({
                merchantCode: code ? code : localStorage.merchantCode,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            console.log(res)
            if (res.code == 0) {
                setAccountList(res.data.lists)
            } else {

            }
        });
    }
    const bookOrderList = (data) => {
        let values = form.getFieldValue();
        (async _ => {
            return await bookOrderListRequest({
                ...data,
                ...values,
                merchantCode: localStorage.merchantCode,
                superMerchantCode: localStorage.appid,
                subMerchantCode: merchantCode,
                pageSize: pageNum,
                currentPage: currentPage,
                accountNo,
                accountName,
            });
        })().then(res => {
            console.log(res)
            if (res.code == 0) {
                setList(res.data.lists)
                setTotal(res.data.totalCount)
            } else {
                // message.error(res.msg)
            }
        });
    }


    const settleMerchantList = () => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode: localStorage.merchantCode,
                superMerchantCode,
            });
        })().then(res => {
            if (res.code == 0) {
                setInit(false)
                setMerchantList(res.data)
            } else {
                // message.error(res.msg)
            }
        });
    }

    const onChangPage = (currentPage, pageNum) => {
        // setInit(false)
        setCurrentPage(currentPage, () => {
            // getAccountLogList()
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        // setInit(false)
        setPageNum((currentPage, pageNum), () => {
            // getAccountLogList()
        })
    }
    const onFinish = (values) => {
        const rangeValue = values && values['date'];
        values.startTime = rangeValue && rangeValue[0].format('YYYY-MM-DD HH:mm:ss');
        values.endTime = rangeValue && rangeValue[1].format('YYYY-MM-DD HH:mm:ss');
        values.currentPage = 1;
        setCurrentPage(1)
        delete values.date
        bookOrderList(values)
    };

    const inSevenDays = () => {
        form.setFieldsValue({
            date: [moment(moment().subtract(7, "days").format("YYYY-MM-DD"), dateFormat), moment(moment().format('YYYY-MM-DD'), dateFormat)]
        })
    }
    const yesterday = () => {
        form.setFieldsValue({
            date: [moment(moment().subtract(1, "days").format("YYYY-MM-DD"), dateFormat), moment(moment().subtract(1, "days").format('YYYY-MM-DD'), dateFormat)]
        })
    }
    const today = () => {
        form.setFieldsValue({
            date: [moment(moment().format('YYYY-MM-DD'), dateFormat), moment(moment().format('YYYY-MM-DD'), dateFormat)]
        })


    }

    return (
        <div style={{ background: '#ffffff', paddingTop: 1, marginTop: 16 }}>
            <Form
                form={form}
                {...layout}
                name="advanced_search"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                style={{ background: '#F7F8FA', margin: 16, padding: 16, paddingBottom: 0 }}
                initialValues={{
                    accountNo: state && state.accountNo ? state.accountNo : null,
                    bookType: null,
                    orderType: null,
                    payType: null,
                    subMerchantCode: localStorage.merchantCode
                }}
            >
                <Row style={{ marginBottom: '-1vh' }}>
                    {
                        localStorage.hxState == 'true' ?
                            <Col span={6}>
                                <Form.Item
                                    name="subMerchantCode"
                                    label="商户："
                                >
                                    <Select placeholder="请选择"
                                        style={{ width: 180 }}
                                        onChange={(code) => {
                                            setMerchantCode(code)
                                            setMerchantCode(code, (data) => {
                                                bookAccountList(data)
                                            })
                                        }}
                                        allowClear
                                        optionFilterProp="children"
                                        showSearch
                                        filterOption={(input, option) => option.children.includes(input)}
                                    >
                                        {
                                            merchantList && merchantList.map((v) => {
                                                return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                            })
                                        }

                                    </Select>
                                </Form.Item>
                            </Col>
                            :
                            <Col span={6}>
                                <Form.Item
                                    label="商户："
                                >
                                    {localStorage.merchantName}
                                </Form.Item>
                            </Col>

                    }

                </Row>

                <Row style={{ marginBottom: '-1vh' }}>
                    <Col span={6}>
                        <Form.Item
                            name="date"
                            label="账单时间："
                        >
                            <div style={{ display: 'flex', alignItems: 'center', width: 400, }}>
                                <Form.Item
                                    name="date"
                                    noStyle
                                >
                                    <RangePicker style={{ width: 250 }} />
                                </Form.Item>
                                <Form.Item
                                    // name="date"
                                    noStyle
                                >
                                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }}
                                        onClick={today} >今天</span>
                                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }}
                                        onClick={yesterday} >昨天</span>
                                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }}
                                        onClick={inSevenDays} >近7天</span>
                                </Form.Item>
                            </div>



                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '-1vh' }}>
                    <Col span={6}>
                        <Form.Item
                            name="orderNo"
                            label="单号："
                        >
                            <Input style={{ width: 180 }} />
                        </Form.Item>
                    </Col>
                    <Col span={6} >
                        <Form.Item
                            name="accountNo"
                            label="记账账户："
                        >
                            <Select style={{ width: 180 }} onChange={(e) => {
                                accountList && accountList.map((v) => {
                                    if (v.accountNo == e) {
                                        setAccountName(v.accountName)
                                        setAccountNo(e)
                                    }
                                })

                            }}>
                                <Option rowKey={null} key={null} value={null}>全部</Option>
                                {
                                    accountList && accountList.map((v) => {
                                        return (
                                            <Option value={v.accountNo} key={v.accountNo}>{v.accountName}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="bookType"
                            label="收支类型："
                        >

                            <Select placeholder="请选择"
                                style={{ width: 180 }}
                                allowClear
                            >
                                <Option rowKey={null} key={null} value={null}>全部</Option>
                                <Option rowKey={0} key={0} value={0}>收入</Option>
                                <Option rowKey={1} key={1} value={1}>支出</Option>

                            </Select>
                        </Form.Item>
                    </Col>


                </Row>
                <Row style={{ marginBottom: '-1vh' }}>
                    <Col span={6}>
                        <Form.Item
                            name="orderType"
                            label="订单类型："
                        >
                            <Select placeholder="请选择"
                                style={{ width: 180 }}
                                // mode="multiple"
                                allowClear
                                optionFilterProp="children"
                                showSearch
                                filterOption={(input, option) => option.children.includes(input)}
                            >
                                <Option key={null} value={null}>全部</Option>
                                {
                                    typeList && typeList.map((v) => {
                                        return <Option rowKey={v.busiType} key={v.busiType} value={v.busiType}>{v.busiTypeName}</Option>
                                    })
                                }

                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="payType"
                            label="支付方式："
                        >
                            <Select style={{ width: 180 }} >
                                <Option value="0" key="0">微信</Option>
                                <Option value="1" key="1">支付宝</Option>
                                <Option value="3" key="3">银行卡</Option>
                                <Option value="4" key="4">现金</Option>
                                <Option value="5" key="5">店铺余额</Option>
                            </Select>
                        </Form.Item>
                    </Col>


                </Row>
                <Row style={{ marginBottom: '-1vh' }}>
                    <Col span={6}>
                        <Form.Item
                            wrapperCol={{
                                offset: 6,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                筛选
                            </Button>
                            <Button type="link" onClick={() => {
                                form.resetFields();
                            }} >
                                重置筛选条件
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                locale={{
                    emptyText: "没有更多数据了"
                }}
                rowKey='id'
                style={{ marginTop: 16, margin: '0 16px' }}
                columns={columns}
                dataSource={list}
                scroll={{
                    x: 1600,
                }}
                pagination={{
                    total,
                    showSizeChanger: true,
                    current: currentPage,
                    pageSize: pageNum,
                    // position: ["bottomCenter"],
                    showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                    onChange: (k, v) => onChangPage(k, v),
                    onShowSizeChange: (k, v) => onChangPageNum(k, v),
                }}
            />

        </div >
    )
}
export default KeepAccountsList;