import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment'
import Compressor from 'compressorjs'
import {
    Form,
    Row,
    Col,
    Button,
    Table,
    DatePicker,
    Input,
    Select,
    message,
    Popconfirm,
    Tabs,
    Radio,
    Steps,
    Popover,
    Modal,
    Typography,
    Upload,
    Space,
    Cascader
} from 'antd';
import { UploadOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
    certificateTypeListRequest,
    areaListRequest,
    bankCardNameRequest,
    incompleteDetailRequest,
    editCompanyRequest,
    authBaseDetailRequest,
    saveCompanyFirstRequest,
    saveCompanySecondRequest,
    branchBankListRequest,
    saveCompanyThirdRequest,
    saveSubmitCompanyRequest
} from '../../api/api'

import host from '../../util/host';
import { buyCard } from '../../util/tools'
const { Option } = Select;
const { Step } = Steps;
const { Title } = Typography;
const { TextArea } = Input;
const dateFormat = 'YYYY/MM/DD';
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};
const EnterpriseSettlement = () => {
    const [current, setCurrent] = useState(0);
    const [data1, setData1] = useState(null)
    const [data2, setData2] = useState(null)
    const [data3, setData3] = useState(null)
    const [date, setDate] = useState(null)
    const [merchantList, setMerchantList] = useState([])
    const [typeList, setTypeList] = useState(Array)
    const [imageData1, setImageData1] = useState();
    const [imageData2, setImageData2] = useState();
    const [imageData3, setImageData3] = useState();
    const [imageData4, setImageData4] = useState();
    const [imageData5, setImageData5] = useState();
    const [imageData6, setImageData6] = useState();
    const [imageData7, setImageData7] = useState();
    const [loading1, setLoading1] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(false)
    const [loading4, setLoading4] = useState(false)
    const [loading5, setLoading5] = useState(false)
    const [loading6, setLoading6] = useState(false)
    const [loading7, setLoading7] = useState(false)
    const [options, setOptions] = useState([])
    const [options2, setOptions2] = useState([])

    const [radio, setRadio] = useState(0)
    const [radio1, setRadio1] = useState(0)
    const [bankCardList, setBankCardList] = useState([])
    const [personageId, setPersonageId] = useState(null)
    const [detail, setDetail] = useState(null)
    const [editData, setEditData] = useState(null)
    const [legalCertTypeName, setLegalCertTypeName] = useState(null)
    const [bankName, setBankName] = useState(null)
    const [step1ID, setStep1ID] = useState(null)
    const [branchName, setBranchName] = useState([])
    const [cardType, setCardType] = useState(0)
    const superMerchantCode = localStorage.appid
    const navigate = useNavigate();
    const [form1] = Form.useForm()
    const [form2] = Form.useForm()
    const [form3] = Form.useForm()
    const [form4] = Form.useForm()

    const { state } = useLocation()
    console.log("🚀 ~ EnterpriseSettlement ~ state:", state)
    
    // 优先取传入的门店
    const merchantCode = state.merchantCode || localStorage.merchantCode
    const merchantName = state.merchantName || ''

    useEffect(() => {
        console.log(state);
        certificateTypeList()
        areaList()
        areaList2()
        bankCardName()
        if (state && state.edit == 1) {
            authBaseDetail()
        } else {
            incompleteDetail()
        }
    }, [])
    const branchBankList = (data) => {
        (async _ => {
            return await branchBankListRequest({ bankCode: data.code, branchName: data.name });
        })().then(res => {
            if (res.code == 0) {
                res.data.map((v) => {
                    v.label = v.branchName
                    v.value = v.dgBankCode
                })
                setBranchName(res.data)
                // setBankCardList([res.data])
            } else {

            }
        });
    }
    const incompleteDetail = () => {
        let params = {
            merchantCode: merchantCode,
            superMerchantCode: superMerchantCode,
            openType: state && state.openType,
            employeeId: state && state.employeeId,
        };
        (async _ => {
            return await incompleteDetailRequest(params);
        })().then(res => {
            if (res.code == 0) {
                console.log(res.data.cardName)
                form1.setFieldsValue({
                    shortName: res.data.shortName,
                    regName: res.data.regName,
                    legalCertType: 1,
                    legalName: res.data.legalName,
                    licenseCode: res.data.licenseCode,
                    licenseValidityType: Number(res.data.legalCertValidityType),
                    date1: res.data.legalCertValidityType == 0 ? res.data.licenseBeginDate ? moment(res.data.licenseBeginDate, dateFormat) : undefined : undefined,
                    date2: res.data.legalCertValidityType == 0 ? res.data.licenseEndDate ? moment(res.data.licenseEndDate, dateFormat) : undefined : undefined,
                    date3: res.data.legalCertValidityType == 1 ? res.data.licenseBeginDate ? moment(res.data.licenseBeginDate, dateFormat) : undefined : undefined,
                    address: res.data.regProvId ? [res.data.regProvId, res.data.regAreaId, res.data.regDistrictId] : undefined,
                    regDetail: res.data.regDetail,
                    img1: {
                        liantuo_picture: res.data.licensePhotoUrl,
                        channel_picture_id: res.data.licensePhotoId
                    },

                })
                form2.setFieldsValue({
                    legalCertType: res.data.legalCertType ? res.data.legalCertType.toString() : undefined,
                    legalName: res.data.legalName,
                    legalCertNo: res.data.legalCertNo,
                    date1: res.data.legalCertBeginDate ? moment(res.data.legalCertBeginDate, dateFormat) : undefined,
                    date2: res.data.legalCertEndDate ? moment(res.data.legalCertEndDate, dateFormat) : undefined,
                    date3: res.data.legalCertBeginDate ? moment(res.data.legalCertBeginDate, dateFormat) : undefined,
                    img2: {
                        liantuo_picture: res.data.certPhotoAUrl,
                        channel_picture_id: res.data.certPhotoAId
                    },
                    img3: {
                        liantuo_picture: res.data.certPhotoBUrl,
                        channel_picture_id: res.data.certPhotoBId
                    },
                })
                form3.setFieldsValue({
                    contactName: res.data.contactName,
                    contactMobile: res.data.contactMobile,
                    cardType: res.data.cardType,
                    bankCode: res.data.bankCode,
                    branchName: res.data.branchName,
                    cardNo: res.data.cardNo,
                    cardName: res.data.cardName,
                    address2: res.data && res.data.provId ? [res.data.provId, res.data.areaId] : undefined,
                    img4: {
                        liantuo_picture: res.data.contactAUrl,
                        channel_picture_id: res.data.contactAId
                    },
                    img5: {
                        liantuo_picture: res.data.contactBUrl,
                        channel_picture_id: res.data.contactBId
                    },
                    img6: {
                        liantuo_picture: res.data.bankCardAUrl,
                        channel_picture_id: res.data.bankCardAId
                    },
                    img7: {
                        liantuo_picture: res.data.bankCardBUrl,
                        channel_picture_id: res.data.bankCardBId
                    },
                })
                setImageData1({
                    liantuo_picture: res.data.licensePhotoUrl,
                    channel_picture_id: res.data.licensePhotoId
                })
                setImageData2({
                    liantuo_picture: res.data.certPhotoAUrl,
                    channel_picture_id: res.data.certPhotoAId
                })
                setImageData3({
                    liantuo_picture: res.data.certPhotoBUrl,
                    channel_picture_id: res.data.certPhotoBId
                })
                setImageData4({
                    liantuo_picture: res.data && res.data.contactAUrl,
                    channel_picture_id: res.data && res.data.contactAId,
                })
                setImageData5({
                    liantuo_picture: res.data && res.data.contactBUrl,
                    channel_picture_id: res.data && res.data.contactBId,
                })
                setImageData6({
                    liantuo_picture: res.data && res.data.bankCardAUrl,
                    channel_picture_id: res.data && res.data.bankCardAId,
                })
                setImageData7({
                    liantuo_picture: res.data && res.data.bankCardBUrl,
                    channel_picture_id: res.data && res.data.bankCardBId,
                })
                setDetail(res.data);
                setRadio(res.data.legalCertValidityType ? res.data.legalCertValidityType : 0)
                setRadio1(res.data.certValidityType ? res.data.certValidityType : 0)
                setCardType(res.data.cardType)
            } else {
                // setStatus(true)

            }
        });
    }
    const authBaseDetail = () => {
        (async _ => {
            return await authBaseDetailRequest({
                merchantCode: merchantCode,
                superMerchantCode: superMerchantCode,
                id: state.baseId ? state.baseId : state.id,
                employeeId: state && state.employeeId
            });
        })().then(res => {
            if (res.code == 0) {
                form1.setFieldsValue({
                    shortName: res.data.shortName,
                    regName: res.data.regName,
                    legalCertType: 1,
                    legalName: res.data.legalName,
                    licenseCode: res.data.licenseCode,
                    licenseValidityType: Number(res.data.licenseValidityType),
                    date1: res.data.licenseValidityType == 0 ? res.data.licenseBeginDate ? moment(res.data.licenseBeginDate, dateFormat) : undefined : undefined,
                    date2: res.data.licenseValidityType == 0 ? res.data.licenseEndDate ? moment(res.data.licenseEndDate, dateFormat) : undefined : undefined,
                    date3: res.data.licenseValidityType == 1 ? res.data.licenseBeginDate ? moment(res.data.licenseBeginDate, dateFormat) : undefined : undefined,
                    address: res.data.regProvId ? [res.data.regProvId, res.data.regAreaId, res.data.regDistrictId] : undefined,
                    regDetail: res.data.regDetail,
                    img1: {
                        liantuo_picture: res.data.licensePhotoUrl,
                        channel_picture_id: res.data.licensePhotoId
                    },
                })
                form2.setFieldsValue({
                    legalCertType: res.data.legalCertType ? res.data.legalCertType.toString() : undefined,
                    legalName: res.data.legalName,
                    legalCertNo: res.data.legalCertNo,
                    certValidityType: Number(res.data.legalCertValidityType),
                    date1: res.data.legalCertValidityType == 0 ? res.data.legalCertBeginDate ? moment(res.data.legalCertBeginDate, dateFormat) : undefined : undefined,
                    date2: res.data.legalCertValidityType == 0 ? res.data.legalCertEndDate ? moment(res.data.legalCertEndDate, dateFormat) : undefined : undefined,
                    date3: res.data.legalCertValidityType == 1 ? res.data.legalCertBeginDate ? moment(res.data.legalCertBeginDate, dateFormat) : undefined : undefined,
                    img2: {
                        liantuo_picture: res.data.certPhotoAUrl,
                        channel_picture_id: res.data.certPhotoAId
                    },
                    img3: {
                        liantuo_picture: res.data.certPhotoBUrl,
                        channel_picture_id: res.data.certPhotoBId
                    },
                })
                form3.setFieldsValue({
                    contactName: res.data.contactName,
                    contactMobile: res.data.contactMobile,
                    cardType: res.data.cardType,
                    bankCode: res.data.bankCode,
                    branchName: res.data.branchName,
                    cardNo: res.data.cardNo,
                    cardName: res.data.cardName,
                    address2: res.data && res.data.provId ? [res.data.provId, res.data.areaId] : undefined,
                    img4: {
                        liantuo_picture: res.data.contactAUrl,
                        channel_picture_id: res.data.contactAId
                    },
                    img5: {
                        liantuo_picture: res.data.contactBUrl,
                        channel_picture_id: res.data.contactBId
                    },
                    img6: {
                        liantuo_picture: res.data.bankCardAUrl,
                        channel_picture_id: res.data.bankCardAId
                    },
                    img7: {
                        liantuo_picture: res.data.bankCardBUrl,
                        channel_picture_id: res.data.bankCardBId
                    },
                })
                setImageData1({
                    liantuo_picture: res.data.licensePhotoUrl,
                    channel_picture_id: res.data.licensePhotoId
                })
                setImageData2({
                    liantuo_picture: res.data.certPhotoAUrl,
                    channel_picture_id: res.data.certPhotoAId
                })
                setImageData3({
                    liantuo_picture: res.data.certPhotoBUrl,
                    channel_picture_id: res.data.certPhotoBId
                })
                setImageData4({
                    liantuo_picture: res.data && res.data.contactAUrl,
                    channel_picture_id: res.data && res.data.contactAId,
                })
                setImageData5({
                    liantuo_picture: res.data && res.data.contactBUrl,
                    channel_picture_id: res.data && res.data.contactBId,
                })
                setImageData6({
                    liantuo_picture: res.data && res.data.bankCardAUrl,
                    channel_picture_id: res.data && res.data.bankCardAId,
                })
                setImageData7({
                    liantuo_picture: res.data && res.data.bankCardBUrl,
                    channel_picture_id: res.data && res.data.bankCardBId,
                })
                setEditData(res.data)
                setDetail(res.data);
                setBankName(res.data.bankName);
                setCardType(res.data.cardType)
                setLegalCertTypeName(res.data.legalCertTypeName);
                setRadio(res.data.licenseValidityType ? res.data.licenseValidityType : 0)
                setRadio1(res.data.legalCertValidityType ? res.data.legalCertValidityType : 0)
            } else {

            }
        });
    }
    const onFinish1 = (values) => {
        console.log(detail.id);
        values.licensePhotoUrl = imageData1 && imageData1.liantuo_picture
        values.licensePhotoId = imageData1 && imageData1.channel_picture_id
        values.licenseBeginDate = values.licenseValidityType == 0 ? values.date1 && values.date1.format("YYYY-MM-DD") : values.date3 && values.date3.format("YYYY-MM-DD")
        values.licenseEndDate = values.date2 && values.date2.format("YYYY-MM-DD")
        values.openStatus = detail && detail.openStatus ? detail.openStatus : 'N'
        values.id = detail && detail.id
        values.operaterId = localStorage.operatorId
        values.operaterName = localStorage.operatorname
        values.openType = state && state.openType;
        values.merchantCode = merchantCode
        values.superMerchantCode = superMerchantCode
        values.legalCertTypeName = legalCertTypeName
        values.employeeId = state && state.employeeId
        values.regProvId = values.address ? values.address[0] : undefined
        values.regAreaId = values.address ? values.address[1] : undefined
        values.regDistrictId = values.address ? values.address[2] : undefined
        values.regAreaName = editData && editData.regAreaName
        delete values.date1
        delete values.date1
        delete values.date3
        delete values.img1
        delete values.img2;
        if (state.edit == 1) {
            setCurrent(1)
            setData1(values);
        } else {
            (async _ => {
                return await saveCompanyFirstRequest(values);
            })().then(res => {
                if (res.code == 0) {
                    setData1(values);
                    setCurrent(1)
                    setStep1ID(res.data);
                } else {
                }
            });
        }


    }
    const onFinish2 = (values) => {
        if (state.edit == 1) {
            values.bankName = bankName;
            values.bankAreaName = editData && editData.bankAreaName;
            values.openStatus = editData && editData.openStatus;
            values.legalCertBeginDate = values.certValidityType == 0 ? values.date1 && values.date1.format("YYYY-MM-DD") : values.date3 && values.date3.format("YYYY-MM-DD");
            values.legalCertEndDate = values.date2 ? values.date2.format("YYYY-MM-DD") : undefined;
            values.certPhotoAId = values.img2 && values.img2.channel_picture_id;
            values.certPhotoAUrl = values.img2 && values.img2.liantuo_picture;
            values.certPhotoBId = values.img3 && values.img3.channel_picture_id;
            values.certPhotoBUrl = values.img3 && values.img3.liantuo_picture;
            setCurrent(2)
            setData2(values);
        } else {
            let params = {
                ...data1,
                ...values,
                openType: state && state.openType,
                id: step1ID ? step1ID : detail.id,
                operaterId: localStorage.operatorId,
                operaterName: localStorage.operatorname,
                merchantCode: merchantCode,
                superMerchantCode,
                openStatus: detail && detail.openStatus ? detail.openStatus : 'N',
                certPhotoAUrl: imageData2 && imageData2.liantuo_picture,
                certPhotoAId: imageData2 && imageData2.channel_picture_id,
                certPhotoBUrl: imageData3 && imageData3.liantuo_picture,
                certPhotoBId: imageData3 && imageData3.channel_picture_id,
                legalCertBeginDate: values.certValidityType == 0 ? values.date1 && values.date1.format("YYYY-MM-DD") : values.date3 && values.date3.format("YYYY-MM-DD"),
                legalCertEndDate: values.date2 ? values.date2.format("YYYY-MM-DD") : undefined,
                legalCertTypeName,
                // cardType: 1,
                openStatus: data1 && data1.openStatus ? data1.openStatus : 'N',

            };
            (async _ => {
                return await saveCompanySecondRequest(params);
            })().then(res => {
                if (res.code == 0) {
                    setData2(params);
                    setCurrent(2)
                } else {
                    message.error(res.msg)
                }
            });
        }


    }
    const onFinish3 = (values) => {
        console.log(values);
        let params = {
            ...values,
            ...data1,
            ...data2,
            contactAUrl: imageData4 && imageData4.liantuo_picture,
            contactAId: imageData4 && imageData4.channel_picture_id,
            contactBUrl: imageData5 && imageData5.liantuo_picture,
            contactBId: imageData5 && imageData5.channel_picture_id,
            bankCardAUrl: imageData6 && imageData6.liantuo_picture,
            bankCardAId: imageData6 && imageData6.channel_picture_id,
            bankCardBUrl: imageData7 && imageData7.liantuo_picture,
            bankCardBId: imageData7 && imageData7.channel_picture_id,
            merchantCode: merchantCode,
            superMerchantCode: superMerchantCode,
            openStatus: detail && detail.openStatus ? detail.openStatus : 'N',
            bankName,
            id: step1ID ? step1ID : data1.id,
            provId: values.address2 && values.address2[0],
            areaId: values.address2 && values.address2[1],
            bankAreaName: editData && editData.bankAreaName,
            cardName: values.cardName,
        };
        console.log(params);
        if (state.edit == 1) {
            setCurrent(3)
            setData3(params);
        } else {
            (async _ => {
                return await saveCompanyThirdRequest(params);
            })().then(res => {
                if (res.code == 0) {
                    setData3(res.data);
                    setCurrent(3)
                } else {
                    message.error(res.msg)
                }
            });
        }
    }
    const onFinish4 = (values) => {
        let params = {
            merchantCode: merchantCode,
            superMerchantCode: superMerchantCode,
            id: data3.id,
        };
        (async _ => {
            return await saveSubmitCompanyRequest(params);
        })().then(res => {
            if (res.code == 0) {
                if (state && state.edit == 1) {
                    navigate(-1)
                } else {
                    navigate(-2)
                }

            } else {
                if (state && state.edit == 1) {
                    navigate(-1)
                } else {
                    navigate(-2)
                }
                message.error(res.msg)
            }
        });

    }


    const editPerson = () => {
        let params = {
            ...data3,
            id: editData && editData.id,
        };
        console.log(params);
        (async _ => {
            return await editCompanyRequest(params);
        })().then(res => {
            if (res.code == 0) {
                if (state && state.edit == 1) {
                    navigate(-1)
                } else {
                    navigate(-2)
                }

            } else {
                if (state && state.edit == 1) {
                    navigate(-1)
                } else {
                    navigate(-2)
                }
                message.error(res.msg)
            }
        });
    }


    const steps = [
        {
            title: '1.填写主体信息',
            content: '',
        },
        {
            title: '2.填写法人信息',
            content: '',
        },
        {
            title: '2.填写基本信息',
            content: '',
        },
        {
            title: '3.预览信息',
            content: '',
        },
    ];
    const certificateTypeList = () => {
        (async _ => {
            return await certificateTypeListRequest();
        })().then(res => {
            if (res.code == 0) {
                setTypeList(res.data)

            } else {

            }
        });
    }

    const bankCardName = () => {
        (async _ => {
            return await bankCardNameRequest();
        })().then(res => {
            if (res.code == 0) {
                res.data.map((v) => {
                    v.label = v.bankName
                    v.value = v.dgBankCode
                })
                setBankCardList(res.data)
            } else {

            }
        });
    }



    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('请上传JPG/PNG文件!');
        }
        const isLt6M = file.size / 1024 / 1024 < 6;
        if (!isLt6M) {
            message.error('图片大小不能大于6MB!');
        }
        return isJpgOrPng && isLt6M;
    }

    const areaList = () => {

        (async _ => {
            return await areaListRequest();
        })().then(res => {
            if (res.code == 0) {
                res.data.map((v) => {
                    v.label = v.provinceName
                    v.value = v.provinceCode
                    v.children = v.cities.map((i) => {
                        return {
                            label: i.cityName,
                            value: i.cityCode,
                            children: i.areas.map((x) => {
                                return {
                                    label: x.name,
                                    value: x.code,
                                    id: x.id,
                                }
                            })
                        }

                    })
                })
                setOptions(res.data)

            } else {
            }
        });
    }
    const areaList2 = () => {

        (async _ => {
            return await areaListRequest();
        })().then(res => {
            if (res.code == 0) {
                res.data.map((v) => {
                    v.label = v.provinceName
                    v.value = v.provinceCode
                    v.children = v.cities.map((i) => {
                        return {
                            label: i.cityName,
                            value: i.cityCode,
                        }

                    })
                })
                setOptions2(res.data)

            } else {
            }
        });
    }
    const uploadButton1 = (
        <div>
            {loading1 ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    const uploadButton2 = (
        <div>
            {loading2 ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    const uploadButton3 = (
        <div>
            {loading3 ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    const uploadButton4 = (
        <div>
            {loading4 ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    const uploadButton5 = (
        <div>
            {loading5 ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    const uploadButton6 = (
        <div>
            {loading6 ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    const uploadButton7 = (
        <div>
            {loading7 ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            <Steps current={current}
                type="navigation"
                className="site-navigation-steps"
                style={{ width: 800, marginLeft: 20 }}
            >
                {steps.map(item => (
                    <Step key={item.title} title={item.title} />
                ))}
            </Steps>
            {
                current == 0 ?
                    <Form
                        name="basic"
                        {...layout}
                        form={form1}
                        onFinish={onFinish1}
                        initialValues={{
                            // legalCertType: 1,
                        }}
                    >

                        <Title level={5} style={{ marginTop: 16, marginLeft: 16, marginBottom: 20 }}>主体信息</Title>

                        {/* 测试门店是否代入成功， 不需要可注释掉 */}
                        { merchantCode && merchantName ? <Form.Item label="机构名称">{merchantName}</Form.Item> : null }
                        <Form.Item
                            name="legalCertType"
                            label="证件类型"
                            rules={[{ required: true, message: '请选择证件类型' }]}
                        >
                            <Select placeholder="请选择"
                                style={{ width: 200 }}
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                            >
                                <Option rowKey={1} key={1} value={1}>多证合一营业执照</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: '-0.2vh' }}
                            name="img1"
                            label="社会信用代码证"
                            rules={[{ required: true, message: '请上传证件照!' }]}
                        >
                            <Upload
                                // name="file"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={`${host}/channel/upload`}
                                headers={{ 'token': localStorage.token, }}
                                beforeUpload={beforeUpload}
                                onChange={(info) => {
                                    console.log(info);
                                    if (info.file.status === 'uploading') {
                                        setLoading1(true);
                                        return;
                                    }
                                    if (info.file.status === 'done') {
                                        setImageData1(info.file.response.data)
                                        if (info.file.response.code == 0) {
                                            let data = info.file.response.data;
                                            form1.setFieldsValue({
                                                regName: form1.getFieldsValue().regName ? form1.getFieldsValue().regName : info.file.response.data && info.file.response.data.regName,
                                                regDetail: form1.getFieldsValue().regDetail ? form1.getFieldsValue().regDetail : info.file.response.data && info.file.response.data.regDetail,
                                                licenseCode: form1.getFieldsValue().licenseCode ? form1.getFieldsValue().licenseCode : info.file.response.data && info.file.response.data.licenseCode,
                                                date1: data && data.licenseValidityType == 0 ? info.file.response.data.licenseBeginDate && form1.getFieldsValue().date1 ? moment(form1.getFieldsValue().date1, dateFormat) : info.file.response.data && moment(info.file.response.data.licenseBeginDate, dateFormat) : undefined,
                                                date2: data && data.licenseValidityType == 0 ? info.file.response.data.licenseEndDate && form1.getFieldsValue().date2 ? moment(form1.getFieldsValue().date2, dateFormat) : info.file.response.data && moment(info.file.response.data.licenseEndDate, dateFormat) : undefined,
                                                date3: data && data.licenseValidityType == 1 ? info.file.response.data.licenseBeginDate && form1.getFieldsValue().date3 ? moment(form1.getFieldsValue().date3, dateFormat) : info.file.response.data && moment(info.file.response.data.licenseBeginDate, dateFormat) : undefined,
                                                licenseValidityType: form1.getFieldsValue().licenseValidityType ? form1.getFieldsValue().licenseValidityType : info.file.response.data && info.file.response.data.licenseValidityType
                                            })
                                            setRadio(data && info.file.response.data.licenseValidityType ? info.file.response.data.licenseValidityType : 0)
                                            setLoading1(false);
                                        } else {
                                            setLoading1(false);
                                            message.error(info.file.response.msg)
                                        }

                                    }



                                }}
                                data={file => ({ // data里存放的是接口的请求参数
                                    picture: file,
                                    merchantCode: merchantCode,
                                    superMerchantCode: superMerchantCode,
                                    pictureType: "01"
                                })}
                            >
                                {imageData1 && imageData1.liantuo_picture ? (
                                    <img
                                        src={imageData1 && imageData1.liantuo_picture}
                                        alt="avatar"
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    />
                                ) : (
                                    uploadButton1
                                )}

                            </Upload>

                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: '-0.2vh', color: '#949494', fontSize: 12 }}
                            label=" "
                            colon={false}
                            rules={[{ required: false, message: '请上传银行回单!' }]}
                        >
                            必须为彩色图片且小于6M，若为复印件，需加盖公司红章，文件格式为png、jpeg或jpg。
                        </Form.Item>
                        <Form.Item label="企业名称"
                            name="regName"
                            rules={[{ required: true, message: '请输入企业名称!' }]}
                        >
                            <Input style={{ width: 200 }} />
                        </Form.Item>
                        <Form.Item label="经营简称"
                            name="shortName"
                            rules={[{ required: true, message: '请输入经营简称!' }]}
                        >
                            <Input style={{ width: 200 }} />
                        </Form.Item>
                        <Form.Item label="统一社会信用代码"
                            name="licenseCode"
                            rules={[{ required: true, message: '请输入证件号码!' }]}
                        >
                            <Input style={{ width: 200 }} />
                        </Form.Item>
                        <Form.Item
                            style={{ marginTop: '-20px', color: '#949494', fontSize: 12 }}
                            label=" "
                            colon={false}
                            rules={[{ required: false, message: '请上传银行回单!' }]}
                        >
                            请输入营业执照上18位统一社会信用代码
                        </Form.Item>
                        <Title level={5} style={{ marginTop: 16, marginLeft: 16, marginBottom: 20 }}>更多信息</Title>
                        <Form.Item
                            name="address"
                            label="注册地址"
                            rules={[{ required: true, message: '请选择转账用途!' }]}
                        >
                            <Cascader options={options}
                                placeholder="请选择"
                                style={{ width: 200 }}
                            />

                        </Form.Item>
                        <Form.Item
                            name="regDetail"
                            style={{ marginTop: '-20px', color: '#949494', fontSize: 12 }}
                            label=" "
                            colon={false}
                            rules={[{ required: false, message: '请上传银行回单!' }]}
                        >
                            <TextArea showCount maxLength={100} style={{ width: 200 }} />
                        </Form.Item>
                        <Form.Item
                            style={{ marginTop: '-10px', color: '#949494', fontSize: 12 }}
                            label=" "
                            colon={false}
                            rules={[{ required: false, message: '请上传银行回单!' }]}
                        >
                            注册地址需与营业执照登记住所一致
                        </Form.Item>
                        <Form.Item
                            label="营业期限"
                            rules={[{ required: true, message: '请选择!' }]}
                        >
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <Form.Item
                                        name="licenseValidityType"
                                        label=""
                                        rules={[{ required: true, message: '请选择!' }]}
                                    >
                                        <Radio.Group
                                            style={{ marginTop: 6 }}
                                            value={radio}
                                            onChange={(e) => {
                                                setRadio(e.target.value)
                                            }}
                                        >
                                            <Space direction="vertical">
                                                <Radio value={0}>区间有效</Radio>
                                                <Radio value={1} style={{ marginTop: 24 }}>长期有效</Radio>
                                            </Space>

                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                                <div>
                                    <div style={{ display: 'flex' }}>
                                        <Form.Item
                                            name="date1"
                                            label=""
                                            rules={[{ required: radio == 0, message: '请选择开始时间!' }]}
                                            colon={false}
                                        >
                                            <DatePicker disabled={radio == 1} placeholder='请选择开始日期' style={{ width: 200 }} />
                                        </Form.Item>
                                        <Form.Item
                                            name="date2"
                                            label=""
                                            rules={[{ required: radio == 0, message: '请选择结束时间!' }]}
                                            colon={false}
                                        >
                                            <DatePicker disabled={radio == 1} placeholder='请选择结束日期' style={{ width: 200 }} />
                                        </Form.Item>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <Form.Item
                                            name="date3"
                                            label=""
                                            rules={[{ required: radio == 1, message: '请选择开始时间!' }]}
                                            colon={false}
                                        >
                                            <DatePicker disabled={radio == 0} placeholder='请选择日期' style={{ width: 200 }} />
                                        </Form.Item>

                                    </div>
                                </div>

                            </div>

                        </Form.Item>
                        <Form.Item
                            label=" "
                            colon={false}
                        >
                            <Button type="primary" htmlType='submit'>下一步</Button>
                        </Form.Item>


                    </Form > : null
            }
            {
                current == 1 ?
                    <Form
                        name="basic"
                        {...layout}
                        form={form2}
                        onFinish={onFinish2}
                        initialValues={{
                            shareType: 0,
                            expenseReceiptType: 0,
                            expireType: 0,
                            certValidityType: 0,
                        }}
                    >

                        <Title level={5} style={{ marginTop: 16, marginLeft: 16, marginBottom: 20 }}>法人信息</Title>
                        <Form.Item
                            name="legalCertType"
                            label="证件类型"
                            rules={[{ required: true, message: '请选择证件类型' }]}
                        >
                            <Select placeholder="请选择"
                                style={{ width: 200 }}
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                                onChange={(e) => {
                                    typeList && typeList.map((v) => {
                                        if (e == v.constantCode) {
                                            setLegalCertTypeName(v.constantName)
                                        }
                                    })

                                }}
                            >
                                {
                                    typeList && typeList.map((v) => {
                                        return <Option rowKey={v.constantCode} key={v.constantCode} value={v.constantCode}>{v.constantName}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            style={{ marginTop: '-20px', color: '#949494', fontSize: 12 }}
                            label=" "
                            colon={false}
                            rules={[{ required: false, message: '请上传银行回单!' }]}
                        >
                            你可上传身份证、护照、港澳同胞通行证、外国人居留证、港澳台居民证、台湾居民证等有效证件
                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: '-0.2vh' }}
                            name="img2"
                            label="证件照正面"
                            rules={[{ required: true, message: '请上传银行回单!' }]}
                        >
                            <Upload
                                name="file"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={`${host}/channel/upload`}
                                headers={{ 'token': localStorage.token, }}
                                beforeUpload={beforeUpload}
                                onChange={(info) => {
                                    if (info.file.status === 'uploading') {
                                        setLoading2(true);
                                        return;
                                    }
                                    if (info.file.status === 'done') {
                                        if (info.file.response.code == 0) {
                                            form2.setFieldsValue({
                                                legalName: form2.getFieldsValue().legalName ? form2.getFieldsValue().legalName : info.file.response.data.legalName,
                                                legalCertNo: form2.getFieldsValue().legalCertNo ? form2.getFieldsValue().legalCertNo : info.file.response.data.legalCertNo,
                                            })
                                            setImageData2(info.file.response.data)
                                            setLoading2(false);
                                        } else {
                                            setLoading2(false);
                                            message.error(info.file.response.msg)
                                        }

                                    }
                                }}
                                data={file => ({ // data里存放的是接口的请求参数
                                    picture: file,
                                    merchantCode: merchantCode,
                                    superMerchantCode: superMerchantCode,
                                    pictureType: form2.getFieldsValue().legalCertType && form2.getFieldsValue().legalCertType.toString() == '00' ? "13" : "20",
                                })}
                            >
                                {imageData2 && imageData2.liantuo_picture ? (
                                    <img
                                        src={imageData2 && imageData2.liantuo_picture}
                                        alt="avatar"
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    />
                                ) : (
                                    uploadButton2
                                )}
                            </Upload>

                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: '-0.2vh', color: '#949494', fontSize: 12 }}
                            label=" "
                            colon={false}
                            rules={[{ required: false, message: '请上传银行回单!' }]}
                        >
                            如你的证件类型是身份证，请在此处上传人像面，若为复印件，需加盖公司鲜章。注意：证件盖章不支持电子章。<span style={{ color: '#155BD4' }}>查看示例</span>

                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: '-0.2vh' }}
                            name="img3"
                            label="证件照背面"
                            rules={[{ required: true, message: '请上传证件照背面!' }]}
                        >
                            <Upload
                                name="file"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={`${host}/channel/upload`}
                                headers={{ 'token': localStorage.token, }}
                                beforeUpload={beforeUpload}
                                onChange={(info) => {
                                    if (info.file.status === 'uploading') {
                                        setLoading3(true);
                                        return;
                                    }
                                    if (info.file.status === 'done') {
                                        if (info.file.response.code == 0) {
                                            setImageData3(info.file.response.data)
                                            setLoading3(false);
                                            let data = info.file.response.data
                                            form2.setFieldsValue({
                                                certValidityType: form2.getFieldsValue().legalCertValidityType ? form2.getFieldsValue().legalCertValidityType : info.file.response.data.legalCertValidityType,
                                                date1: data && data.legalCertValidityType == 0 ? form2.getFieldsValue().date1 ? moment(form2.getFieldsValue().date1, dateFormat) : info.file.response.data && moment(info.file.response.data.legalCertBeginDate, dateFormat) : undefined,
                                                date2: data && data.legalCertValidityType == 0 ? form2.getFieldsValue().date2 ? moment(form2.getFieldsValue().date2, dateFormat) : info.file.response.data && moment(info.file.response.data.legalCertEndDate, dateFormat) : undefined,
                                                date3: data && data.legalCertValidityType == 1 ? form2.getFieldsValue().date3 ? moment(form2.getFieldsValue().date3, dateFormat) : info.file.response.data && moment(info.file.response.data.legalCertEndDate, dateFormat) : undefined,
                                            })
                                            setLoading2(false);
                                        } else {
                                            setLoading2(false);
                                            message.error(info.file.response.msg)
                                        }

                                    }
                                }}
                                data={file => ({ // data里存放的是接口的请求参数
                                    picture: file,
                                    merchantCode: merchantCode,
                                    superMerchantCode: superMerchantCode,
                                    pictureType: form2.getFieldsValue().legalCertType && form2.getFieldsValue().legalCertType.toString() == '00' ? "14" : "20",
                                })}
                            >
                                {imageData3 && imageData3.liantuo_picture ? (
                                    <img
                                        src={imageData3 && imageData3.liantuo_picture}
                                        alt="avatar"
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    />
                                ) : (
                                    uploadButton3
                                )}
                            </Upload>

                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: '-0.2vh', color: '#949494', fontSize: 12 }}
                            label=" "
                            colon={false}
                            rules={[{ required: false, message: '请上传银行回单!' }]}
                        >
                            如你的证件类型是身份证，请在此处上传人像面，若为复印件，需加盖公司鲜章。注意：证件盖章不支持电子章。<span style={{ color: '#155BD4' }}>查看示例</span>

                        </Form.Item>
                        <Form.Item
                            name="legalName"
                            label="法人姓名"
                            rules={[{ required: true, message: '请选择证件类型' }]}
                        >
                            <Input placeholder="请输入个人姓名" style={{ width: 200 }} />
                        </Form.Item>
                        <Form.Item
                            name="legalCertNo"
                            label="证件号码"
                            rules={[{ required: true, message: '请输入证件号码' }]}
                        >
                            <Input placeholder="请输入证件号码" style={{ width: 200 }} />
                        </Form.Item>
                        <Form.Item
                            label="证件有效期"
                            rules={[{ required: true, message: '请选择!' }]}
                        >
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <Form.Item
                                        name="certValidityType"
                                        label=""
                                        rules={[{ required: true, message: '请选择!' }]}
                                    >
                                        <Radio.Group
                                            style={{ marginTop: 6 }}
                                            value={1}
                                            onChange={(e) => {
                                                setRadio1(e.target.value)
                                            }}
                                        >
                                            <Space direction="vertical">
                                                <Radio value={0}>区间有效</Radio>
                                                <Radio value={1} style={{ marginTop: 24 }}>长期有效</Radio>
                                            </Space>

                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                                <div>
                                    <div style={{ display: 'flex' }}>
                                        <Form.Item
                                            name="date1"
                                            label=""
                                            rules={[{ required: radio1 == 0, message: '请选择开始时间!' }]}
                                            colon={false}
                                        >
                                            <DatePicker disabled={radio1 == 1} placeholder='请选择开始日期' style={{ width: 200 }} />
                                        </Form.Item>
                                        <Form.Item
                                            name="date2"
                                            label=""
                                            rules={[{ required: radio1 == 0, message: '请选择结束时间!' }]}
                                            colon={false}
                                        >
                                            <DatePicker disabled={radio1 == 1} placeholder='请选择结束日期' style={{ width: 200 }} />
                                        </Form.Item>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <Form.Item
                                            name="date3"
                                            label=""
                                            rules={[{ required: radio1 == 1, message: '请选择开始时间!' }]}
                                            colon={false}
                                        >
                                            <DatePicker disabled={radio1 == 0} placeholder='请选择日期' style={{ width: 200 }} />
                                        </Form.Item>

                                    </div>
                                </div>

                            </div>

                        </Form.Item>


                        <Form.Item
                            label=" "
                            colon={false}
                        >
                            <Button type="primary" htmlType='submit'>下一步</Button>
                        </Form.Item>


                    </Form > : null
            }
            {
                current == 2 ?
                    <Form
                        name="basic"
                        {...layout}
                        form={form3}
                        onFinish={onFinish3}
                    >

                        <Title level={5} style={{ marginTop: 16, marginLeft: 16, marginBottom: 20 }}>联系人信息</Title>
                        <Form.Item
                            style={{ marginBottom: '-0.2vh' }}
                            name="img4"
                            label="证件照正面"
                            rules={[{ required: true, message: '请上传银行回单!' }]}
                        >
                            <Upload
                                name="file"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={`${host}/channel/upload`}
                                headers={{ 'token': localStorage.token, }}
                                beforeUpload={beforeUpload}
                                onChange={(info) => {
                                    if (info.file.status === 'uploading') {
                                        setLoading4(true);
                                        return;
                                    }
                                    if (info.file.status === 'done') {
                                        if (info.file.response.code == 0) {
                                            let data = info.file.response.data;
                                            form3.setFieldsValue({
                                                contactName: form3.getFieldsValue().contactName ? form3.getFieldsValue().contactName : data.legalName,
                                            })
                                            setImageData4(info.file.response.data)
                                            setLoading4(false);
                                        } else {
                                            setLoading4(false);
                                            message.error(info.file.response.msg)
                                        }

                                    }
                                }}
                                data={file => ({ // data里存放的是接口的请求参数
                                    picture: file,
                                    merchantCode: merchantCode,
                                    superMerchantCode: superMerchantCode,
                                    pictureType: 13,
                                })}
                            >
                                {imageData4 && imageData4.liantuo_picture ? (
                                    <img
                                        src={imageData4 && imageData4.liantuo_picture}
                                        alt="avatar"
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    />
                                ) : (
                                    uploadButton4
                                )}
                            </Upload>

                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: '-0.2vh', color: '#949494', fontSize: 12 }}
                            label=" "
                            colon={false}
                            rules={[{ required: false, message: '请上传银行回单!' }]}
                        >
                            如你的证件类型是身份证，请在此处上传人像面，若为复印件，需加盖公司鲜章。注意：证件盖章不支持电子章。<span style={{ color: '#155BD4' }}>查看示例</span>

                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: '-0.2vh' }}
                            name="img5"
                            label="证件照背面"
                            rules={[{ required: true, message: '请上传证件照背面!' }]}
                        >
                            <Upload
                                name="file"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={`${host}/channel/upload`}
                                headers={{ 'token': localStorage.token, }}
                                beforeUpload={beforeUpload}
                                onChange={(info) => {
                                    if (info.file.status === 'uploading') {
                                        setLoading5(true);
                                        return;
                                    }
                                    if (info.file.status === 'done') {
                                        setImageData5(info.file.response.data)
                                        setLoading5(false);
                                    }
                                }}
                                data={file => ({ // data里存放的是接口的请求参数
                                    picture: file,
                                    merchantCode: merchantCode,
                                    superMerchantCode: superMerchantCode,
                                    pictureType: 14,
                                })}
                            >
                                {imageData5 && imageData5.liantuo_picture ? (
                                    <img
                                        src={imageData5 && imageData5.liantuo_picture}
                                        alt="avatar"
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    />
                                ) : (
                                    uploadButton5
                                )}
                            </Upload>

                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: '-0.2vh', color: '#949494', fontSize: 12 }}
                            label=" "
                            colon={false}
                            rules={[{ required: false, message: '请上传银行回单!' }]}
                        >
                            如你的证件类型是身份证，请在此处上传人像面，若为复印件，需加盖公司鲜章。注意：证件盖章不支持电子章。<span style={{ color: '#155BD4' }}>查看示例</span>

                        </Form.Item>
                        <Form.Item
                            name="contactName"
                            label="联系人姓名"
                            rules={[{ required: true, message: '请输入联系人姓名' }]}
                        >
                            <Input placeholder="请输入联系人姓名" style={{ width: 200 }} />
                        </Form.Item>
                        <Form.Item
                            name="contactMobile"
                            label="手机号"
                            rules={[{ required: true, message: '请输入手机号' }]}
                        >
                            <Input placeholder="请输入手机号" style={{ width: 200 }} />
                        </Form.Item>
                        <Title level={5} style={{ marginTop: 16, marginLeft: 16, marginBottom: 20 }}>结算账户信息</Title>

                        <Form.Item
                            name="cardType"
                            label="卡类型"
                            rules={[{ required: true, message: '请选择证件类型' }]}
                        >
                            <Select placeholder="请选择"
                                style={{ width: 200 }}
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                                onChange={(e) => {
                                    setCardType(e)
                                    form3.setFieldsValue({
                                        cardName: data1 && data1.regName
                                    })
                                }}
                            >
                                <Option rowKey={0} key={0} value={0}>对公</Option>
                                <Option rowKey={1} key={1} value={1}>对私</Option>
                                <Option rowKey={2} key={2} value={2}>对私非法人</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: '-0.2vh' }}
                            name="img6"
                            label="结算卡正面"
                            rules={[{ required: true, message: '请上传证件照背面!' }]}
                        >
                            <Upload
                                name="file"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={`${host}/channel/upload`}
                                headers={{ 'token': localStorage.token, }}
                                beforeUpload={beforeUpload}
                                onChange={(info) => {
                                    if (info.file.status === 'uploading') {
                                        setLoading6(true);
                                        return;
                                    }
                                    if (info.file.status === 'done') {
                                        if (info.file.response.code == 0) {
                                            let data = info.file.response.data
                                            form3.setFieldsValue({
                                                cardNo: form3.getFieldsValue().cardNo ? form3.getFieldsValue().cardNo : data.cardNo,
                                                bankCode: form3.getFieldsValue().bankCode ? form3.getFieldsValue().bankCode : data.bankCode,
                                            })
                                            setBankName(data.bankName)
                                            branchBankList({ code: data.bankCode, name: data.bankName })
                                            setImageData6(info.file.response.data)
                                            setLoading6(false);
                                        } else {
                                            setLoading6(false);
                                            message.error(info.file.response.msg)
                                        }

                                    }
                                }}
                                data={file => ({ // data里存放的是接口的请求参数
                                    picture: file,
                                    merchantCode: merchantCode,
                                    superMerchantCode: superMerchantCode,
                                    pictureType: 18,
                                })}
                            >
                                {imageData6 && imageData6.liantuo_picture ? (
                                    <img
                                        src={imageData6 && imageData6.liantuo_picture}
                                        alt="avatar"
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    />
                                ) : (
                                    uploadButton6
                                )}
                            </Upload>

                        </Form.Item>
                        {
                            cardType != 0 ?
                                <Form.Item
                                    style={{ marginBottom: '-0.2vh' }}
                                    name="img7"
                                    label="结算卡背面"
                                    rules={[{ required: true, message: '请上传证件照背面!' }]}
                                >
                                    <Upload
                                        name="file"
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        action={`${host}/channel/upload`}
                                        headers={{ 'token': localStorage.token, }}
                                        beforeUpload={beforeUpload}
                                        onChange={(info) => {
                                            if (info.file.status === 'uploading') {
                                                setLoading7(true);
                                                return;
                                            }
                                            if (info.file.status === 'done') {
                                                setImageData7(info.file.response.data)
                                                setLoading7(false);
                                            }
                                        }}
                                        data={file => ({ // data里存放的是接口的请求参数
                                            picture: file,
                                            merchantCode: merchantCode,
                                            superMerchantCode: superMerchantCode,
                                            pictureType: 19,
                                        })}
                                    >
                                        {imageData7 && imageData7.liantuo_picture ? (
                                            <img
                                                src={imageData7 && imageData7.liantuo_picture}
                                                alt="avatar"
                                                style={{
                                                    width: '100%',
                                                    height: '100%'
                                                }}
                                            />
                                        ) : (
                                            uploadButton7
                                        )}
                                    </Upload>

                                </Form.Item> : null
                        }

                        {
                            cardType == 2 ?
                                <Form.Item
                                    name="cardName"
                                    label="银行户名"
                                    rules={[{ required: true, message: '请选择证件类型' }]}
                                >
                                    <Input placeholder='请输入' style={{ width: 200 }} />
                                </Form.Item>
                                :
                                <Form.Item
                                    name="cardName"
                                    label="银行户名"
                                    rules={[{ required: cardType == 2, message: '请选择证件类型' }]}
                                >
                                    {data1 && data1.regName}
                                </Form.Item>
                        }

                        <Form.Item label="开户银行"
                            name="bankCode"
                            rules={[{ required: true, message: '请选择开户银行!' }]}
                        >
                            <Select placeholder="请选择"
                                style={{ width: 200 }}
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                                onChange={(e) => {
                                    bankCardList && bankCardList.map((v) => {
                                        if (e == v.dgBankCode) {
                                            setBankName(v.bankName)
                                            branchBankList({ code: v.dgBankCode, name: v.dgBankName })
                                        }
                                        form3.setFieldsValue({
                                            branchName: undefined
                                        })
                                    })
                                }}
                            >
                                {
                                    bankCardList && bankCardList.map((v) => {
                                        return <Option rowKey={v.dgBankCode} key={v.dgBankCode} value={v.dgBankCode}>{v.bankName}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="支行名称"
                            name="branchName"
                            rules={[{ required: true, message: '请选择支行名称!' }]}
                        >
                            <Select placeholder="请选择"
                                style={{ width: 200 }}
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                                onChange={(e) => {
                                    branchName && branchName.map((v) => {
                                        if (e == v.branchCode) {
                                            setBankName(v.bankName)
                                        }
                                    })
                                }}
                            >
                                {
                                    branchName && branchName.map((v) => {
                                        return <Option rowKey={v.branchName} key={v.branchName} value={v.branchName}>{v.branchName}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="address2"
                            label="银行所在省市"
                            rules={[{ required: true, message: '请选择!' }]}
                        >
                            <Cascader options={options2}
                                placeholder="请选择"
                                style={{ width: 200 }}
                            />

                        </Form.Item>
                        <Form.Item
                            name="cardNo"
                            label="银行卡号"
                            rules={[{ required: true, message: '请选择!' }]}
                        >
                            <Input
                                placeholder="请选择"
                                style={{ width: 200 }}
                            />

                        </Form.Item>

                        <Form.Item
                            label=" "
                            colon={false}
                            style={{ marginTop: 20 }}
                        >

                            <Button type="primary" htmlType='submit'>下一步</Button>


                        </Form.Item>


                    </Form > : null
            }
            {
                current == 3 ?
                    <Form
                        name="basic"
                        {...layout}
                        form={form4}
                        onFinish={onFinish4}
                    >
                        <Title level={5} style={{ marginTop: 16, marginLeft: 16, marginBottom: 20 }}>主体信息</Title>

                        {/* 测试门店是否代入成功， 不需要可注释掉 */}
                        { merchantCode && merchantName ? <Form.Item label="机构名称">{merchantName}</Form.Item> : null }
                        <Form.Item
                            label="证件类型"
                            rules={[{ required: false, message: '请选择证件类型' }]}
                        >
                            多证合一营业执照

                        </Form.Item>
                        <Form.Item
                            name="img1"
                            label="社会信用代码证"
                        >
                            <img
                                src={imageData1 && imageData1.liantuo_picture}
                                alt="avatar"
                                style={{
                                    width: '100px',
                                    height: '100px'
                                }}
                            />

                        </Form.Item>
                        <Form.Item label="企业名称"
                            rules={[{ required: true, message: '请输入企业名称!' }]}
                        >
                            {data3 && data3.regName}
                        </Form.Item>
                        <Form.Item label="经营简称"
                            rules={[{ required: true, message: '请输入经营简称!' }]}
                        >
                            {data3 && data3.shortName}
                        </Form.Item>
                        <Form.Item label="统一社会信用代码"
                            name="licenseCode"
                            rules={[{ required: false, message: '请输入证件号码!' }]}
                        >
                            {data3 && data3.licenseCode}
                        </Form.Item>

                        <Title level={5} style={{ marginTop: 16, marginLeft: 16, marginBottom: 20 }}>更多信息</Title>
                        <Form.Item
                            label="银行所在省市"
                            rules={[{ required: true, message: '请选择转账用途!' }]}
                        >
                            {data3 && data3.regAreaName}
                        </Form.Item>
                        <Form.Item
                            label="详细地址"
                            rules={[{ required: false, message: '请上传银行回单!' }]}
                        >
                            {data3 && data3.regDetail}
                        </Form.Item>
                        <Form.Item
                            label="营业期限"
                            rules={[{ required: true, message: '请选择!' }]}
                        >
                            {data3 && data3.licenseBeginDate}~{data3 && data3.licenseValidityType == 0 ? data3.licenseEndDate : ''}{data3 && data3.licenseValidityType == 1 ? '长期' : ''}
                        </Form.Item>

                        <Title level={5} style={{ marginTop: 16, marginLeft: 16, marginBottom: 20 }}>法人信息</Title>
                        <Form.Item
                            name="legalCertType"
                            label="证件类型"
                            rules={[{ required: false, message: '请选择证件类型' }]}
                        >
                            {data3 && data3.legalCertTypeName}
                        </Form.Item>
                        <Form.Item
                            name="img2"
                            label="证件照正面"
                        >
                            <img
                                src={imageData2 && imageData2.liantuo_picture}
                                alt="avatar"
                                style={{
                                    width: '100px',
                                    height: '100px'
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="img3"
                            label="证件照背面"
                        >
                            <img
                                src={imageData3 && imageData3.liantuo_picture}
                                alt="avatar"
                                style={{
                                    width: '100px',
                                    height: '100px'
                                }}
                            />

                        </Form.Item>
                        <Form.Item
                            name="legalName"
                            label="法人姓名"
                        >
                            {data3 && data3.legalName}
                        </Form.Item>
                        <Form.Item
                            label="证件号码"
                        >
                            {data3 && data3.legalCertNo}
                        </Form.Item>
                        <Form.Item
                            label="证件有效期"
                            rules={[{ required: true, message: '请选择!' }]}
                        >
                            {data3 && data3.legalCertBeginDate}~{data3 && data3.certValidityType == 0 ? data3.legalCertEndDate : ''}{data3 && data3.certValidityType == 1 ? '长期' : ''}
                        </Form.Item>
                        <Title level={5} style={{ marginTop: 16, marginLeft: 16, marginBottom: 20 }}>联系人信息</Title>
                        <Form.Item
                            name="img4"
                            label="证件照正面"
                        >
                            <img
                                src={imageData4 && imageData4.liantuo_picture}
                                alt="avatar"
                                style={{
                                    width: '100px',
                                    height: '100px'
                                }}
                            />

                        </Form.Item>
                        <Form.Item
                            name="img5"
                            label="证件照背面"
                        >
                            <img
                                src={imageData5 && imageData5.liantuo_picture}
                                alt="avatar"
                                style={{
                                    width: '100px',
                                    height: '100px'
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="联系人姓名"
                        >
                            {data3 && data3.contactName}
                        </Form.Item>
                        <Form.Item
                            label="手机号"
                        >
                            {data3 && data3.contactMobile}
                        </Form.Item>
                        <Title level={5} style={{ marginTop: 16, marginLeft: 16, marginBottom: 20 }}>结算账户信息</Title>

                        <Form.Item
                            label="卡类型"
                        >
                            {data3 && data3.cardType == 0 ? '对公' : ''}
                            {data3 && data3.cardType == 1 ? '对私' : ''}
                            {data3 && data3.cardType == 2 ? '对私非法人' : ''}
                        </Form.Item>
                        <Form.Item
                            name="img6"
                            label="结算卡正面"
                        >
                            <img
                                src={imageData6 && imageData6.liantuo_picture}
                                alt="avatar"
                                style={{
                                    width: '100px',
                                    height: '100px'
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="结算卡背面"
                        >
                            <img
                                src={imageData7 && imageData7.liantuo_picture}
                                alt="avatar"
                                style={{
                                    width: '100px',
                                    height: '100px'
                                }}
                            />
                        </Form.Item>

                        {
                            data3 && data3.cardType == 2 ?
                                <Form.Item
                                    label="银行户名"
                                >
                                    {data3 && data3.cardName}
                                </Form.Item>
                                :
                                <Form.Item
                                    label="银行户名"
                                >
                                    {data3 && data3.regName}
                                </Form.Item>
                        }

                        <Form.Item label="开户银行"
                        >
                            {data3 && data3.bankName}
                        </Form.Item>
                        <Form.Item label="支行名称"
                        >
                            {data3 && data3.branchName}
                        </Form.Item>
                        <Form.Item
                            name="address2"
                            label="银行所在省市"
                        >
                            {data3 && data3.bankAreaName}
                        </Form.Item>
                        <Form.Item
                            label="银行卡号"
                        >
                            {data3 && data3.cardNo}
                        </Form.Item>

                        <Form.Item
                            label=" "
                            colon={false}
                            style={{ marginTop: 20 }}
                        >
                            {
                                state && state.edit == 1 ?
                                    <Button type="primary" onClick={() => {
                                        editPerson()
                                    }}>提交</Button>
                                    :
                                    <Button type="primary" htmlType='submit'>提交</Button>

                            }



                        </Form.Item>


                    </Form > : null
            }



        </div >
    )
}

export default EnterpriseSettlement;