import React, { useState, useEffect, useReducer } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import {
    Form,
    Row,
    Col,
    Button,
    Input,
    Select,
    Table,
    Popconfirm,
    DatePicker,
    Modal,
    message
} from 'antd';
import { accountLogListRequest, getConstantsRequest, accountLogEmployeeRequest, settleMerchantListRequest, getBusiListRequest, relationRecordRequest, getSourceListRequest, listExportRequest } from '../../api/api'
import { useCallbackState } from '../../util/useCallbackState'
import Detail from './detail/index'
import ExportList from '../../components/exportList'
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};
const { RangePicker } = DatePicker;
const StatementList = () => {
    const [form] = Form.useForm();
    const { state } = useLocation();
    const [startDate, setStartDate] = useState('')
    const [typeList, setTypeList] = useState([])
    const [endDate, setEndDate] = useState('')
    const [list, setList] = useState([])
    const [guanlianList, setGuanlianList] = useState([])
    const [merchantList, setMerchantList] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [detailVisible, setDetailVisible] = useState(false);
    const [exportVisible, setExportVisible] = useState(false);
    const [row, setRow] = useState(null)
    const [orderSource, setOrderSource] = useState([])
    const [merchantCode, setMerchantCode] = useCallbackState(state && state.merchantCode ? state.merchantCode : localStorage.hxState == 'true' ? null : localStorage.merchantCode)
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [total, setTotal] = useState()
    const [glTotal, setglTotal] = useState()
    const [init, setInit] = useState(true)
    const [accountTypeList, setAccountTypeList] = useState([])
    const superMerchantCode = localStorage.appid;
    const { search } = useLocation()
    const navigate = useNavigate()
    const forceUpdate = useReducer((bool) => !bool)[1]
    useEffect(() => {
        if (init) {
            settleMerchantList();
        }
        console.log(state)
        getConstants();
        getBusiList();
        if (localStorage.salesEmployeeId == "null") {
            getAccountLogList()
        } else {
            accountLogEmployee()
        }

        getSourceList();
    }, [currentPage, pageNum])
    const columns = [
        {
            title: '类型',
            dataIndex: 'busiType',
            key: 'busiType',
        },
        {
            title: '入账时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 200,
        },

        {
            title: '业务单号｜支付流水号',
            dataIndex: 'orderNo',
            key: 'orderNo',
            width: 250,
            render: (text, row) => (
                <div>
                    <div >{row.orderNo}</div>
                    <div style={{ color: '#969799' }}>{row.thirdTradeNo}</div>
                </div>
            ),
        },
        {
            title: '账户',
            dataIndex: 'accountType',
            key: 'accountType',
            render: (text, row) => {
                return (
                    <>
                        <div>
                            {row.accountType == 0 ? '店铺余额待结算' : ''}
                            {row.accountType == 1 ? '店铺余额' : ''}
                            {row.accountType == 2 ? '钱包余额' : ''}
                            {row.accountType == 3 ? '储值余额' : ''}
                            {row.accountType == 4 ? '钱包余额待结算' : ''}
                            {row.accountType == 5 ? '收益余额' : ''}
                            {row.accountType == 6 ? '授信余额' : ''}
                            {row.accountType == 7 ? '营销余额待结算' : ''}
                            {row.accountType == 8 ? '营销余额' : ''}
                        </div>
                    </>

                )
            }
        },
        {
            title: '收支类型',
            dataIndex: 'address',
            key: '3',
            render: (text, row) => {
                return (
                    <div>{row.type == 0 ? '收入' : '支出'}</div>
                )
            }
        },
        {
            title: '金额(元)',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: '余额(元)',
            dataIndex: 'afCaBalance',
            key: 'afCaBalance',
        },
        {
            title: '支付方式',
            dataIndex: 'payType',
            key: 'payType',
            render: (text, row) => {
                return (
                    <div>
                        {row.payType == 0 ? '微信' : ''}
                        {row.payType == 1 ? '支付宝' : ''}
                        {row.payType == 2 ? '会员卡' : ''}
                        {row.payType == 3 ? '银行卡' : ''}
                        {row.payType == 4 ? '现金' : ''}
                        {row.payType == 5 ? '云闪付' : ''}
                        {row.payType == 6 ? '漕河泾(饿了么)' : ''}
                        {row.payType == 8 ? '组合支付' : ''}
                        {row.payType == 17 ? '翼支付' : ''}
                        {row.payType == 10 ? '余额支付' : ''}
                        {row.payType == 9 ? '授信支付' : ''}

                    </div>
                )
            },
        },
        {
            title: '收款方',
            dataIndex: 'payeeMerchantName',
            key: 'payeeMerchantName',
            width: 250,
        },
        {
            title: '付款方',
            dataIndex: 'payMerchantName',
            key: 'payMerchantName',
            width: 250,
        },
        {
            title: '交易流水状态',
            dataIndex: 'logStatus',
            key: 'logStatus',
            render: (text, row) => {
                return (
                    <div>
                        {row.logStatus == 0 ? '处理中' : ''}
                        {row.logStatus == 1 ? '已完成' : ''}
                        {row.logStatus == 2 ? '失败' : ''}

                    </div>
                )
            }
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 150,
            align: 'right',
            render: (text, row) => (
                <>
                    <span style={{ color: '#165BD4', cursor: 'pointer' }} onClick={() => {
                        detailShowModal(row)
                        // navigate('/ws/statementList/statementListdetail', { replace: false, state: row })
                    }}>详情</span>
                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }} onClick={() => {
                        showModal(row)
                    }} >关联记录</span>
                </>

            ),

        },
    ];
    const columns1 = [
        {
            title: '类型',
            dataIndex: 'busiType',
            key: 'busiType',
        },
        {
            title: '时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '业务单号｜支付流水号',
            dataIndex: 'address',
            key: '2',
            render: (text, row) => (
                <div>
                    <div >{row.orderNo}</div>
                    <div style={{ color: '#969799' }}>{row.thirdTradeNo}</div>
                </div>
            ),
        },
        {
            title: '账户',
            dataIndex: 'accountType',
            key: 'accountType',
            render: (text, row) => {
                return (
                    <>
                        {row.type == '0' ?
                            <div>

                                {row.payeeAccountType == 0 ? '店铺余额待结算' : ''}
                                {row.payeeAccountType == 1 ? '店铺余额' : ''}
                                {row.payeeAccountType == 2 ? '钱包余额' : ''}
                                {row.payeeAccountType == 4 ? '钱包余额待结算' : ''}
                                {row.payeeAccountType == 5 ? '收益余额' : ''}
                                {row.payeeAccountType == 6 ? '授信余额' : ''}
                                {row.payeeAccountType == 7 ? '营销余额待结算' : ''}
                                {row.payeeAccountType == 8 ? '营销余额' : ''}
                            </div>
                            :
                            <div>
                                {row.accountType == 0 ? '店铺余额待结算' : ''}
                                {row.accountType == 1 ? '店铺余额' : ''}
                                {row.accountType == 2 ? '钱包余额' : ''}
                                {row.accountType == 4 ? '钱包余额待结算' : ''}
                                {row.accountType == 5 ? '收益余额' : ''}
                                {row.accountType == 6 ? '授信余额' : ''}
                                {row.accountType == 7 ? '营销余额待结算' : ''}
                                {row.accountType == 8 ? '营销余额' : ''}
                            </div>
                        }
                    </>
                )
            }
        },
        {
            title: '收支类型',
            dataIndex: 'address',
            key: '3',
            render: (text, row) => {
                return (
                    <div>{row.type == 0 ? '收入' : '支出'}</div>
                )
            }
        },

        {
            title: '金额(元)',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: '余额(元)',
            dataIndex: 'afCaBalance',
            key: 'afCaBalance',
        },
        {
            title: '支付方式',
            dataIndex: 'payType',
            key: 'payType',
            render: (text, row) => {
                return (
                    <div>
                        {row.payType == 0 ? '微信' : ''}
                        {row.payType == 1 ? '支付宝' : ''}
                        {row.payType == 2 ? '会员' : ''}
                        {row.payType == 3 ? '银行卡' : ''}
                        {row.payType == 4 ? '现金' : ''}
                        {row.payType == 5 ? '云闪付' : ''}
                        {row.payType == 6 ? '漕河泾(饿了么)' : ''}
                        {row.payType == 7 ? '组合支付' : ''}
                        {row.payType == 17 ? '翼支付' : ''}

                    </div>
                )
            }
        },
        {
            title: '收款方',
            dataIndex: 'payeeMerchantName',
            key: 'payeeMerchantName',
            width: 250,
        },
        {
            title: '付款方',
            dataIndex: 'payMerchantName',
            key: 'payMerchantName',
            width: 250,
        },
        {
            title: '交易流水状态',
            dataIndex: 'logStatus',
            key: 'logStatus',
            render: (text, row) => {
                return (
                    <div>
                        {/* {row.logStatus == 0 ? '处理中' : ''}
                        {row.logStatus == 1 ? '已完成' : ''}
                        {row.logStatus == 2 ? '失败' : ''} */}
                        已完成
                    </div>
                )
            }
        },
    ];
    const getConstants = () => {
        (async _ => {
            return await getConstantsRequest({
                constantType: "FD_ACCOUNT"
            });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setAccountTypeList(res.data.items)
            } else {
            }
        });
    }

    const getSourceList = () => {
        (async _ => {
            return await getSourceListRequest();
        })().then(res => {
            if (res.code == 0) {
                setOrderSource(res.data)
            } else {
                message.error(res.msg)
            }
        });
    }
    const relationRecord = (data) => {
        (async _ => {
            return await relationRecordRequest(
                {
                    orderNo: data.orderNo
                }
            );
        })().then(res => {
            if (res.code == 0) {
                setGuanlianList(res.data.lists)
                setglTotal(res.data.totalCount)
            } else {
                message.error(res.msg)
            }
        });
    }

    const showModal = (data) => {
        relationRecord(data)
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };



    const detailShowModal = (data) => {
        setRow(data)
        setDetailVisible(true);
    };

    const detailHandleOk = () => {
        setDetailVisible(false);
    };

    const detailhandleCancel = () => {
        setDetailVisible(false);
    };
    const exportHandleOk = () => {
        setExportVisible(false);
    };
    const exporthandleCancel = () => {
        setExportVisible(false);
    };
    const getBusiList = () => {

        (async _ => {
            return await getBusiListRequest({});
        })().then(res => {
            if (res.code == 0) {
                setTypeList(res.data)
            } else {

            }
        });
    }
    const settleMerchantList = () => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode: localStorage.merchantCode,
                superMerchantCode,
            });
        })().then(res => {
            if (res.code == 0) {
                setInit(false)
                setMerchantList(res.data)
            } else {
                // message.error(res.msg)
            }
        });
    }

    const accountLogEmployee = (data) => {
        let values = form.getFieldValue()
        console.log(values);
        if (!values.orderNo) {
            delete values.orderNo
        }
        let params = {
            ...values,
            currentPage: data && data.currentPage ? data.currentPage : currentPage,
            pageSize: pageNum,
            superMerchantCode: localStorage.appid,
            accountType: values && values.accountType ? values.accountType : state && state.accountType && state.accountType.toString() && state.accountType.toString(),
            busiTypes: values && values.busiTypes ? values.busiTypes : state && state.businessTradeType && [state.businessTradeType],
            startDate: form.getFieldValue('date') && form.getFieldValue('date')[0].format('YYYY-MM-DD HH:mm:ss') ? form.getFieldValue('date')[0].format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss'),
            startEnd: form.getFieldValue('date') && form.getFieldValue('date')[1].format('YYYY-MM-DD HH:mm:ss') ? form.getFieldValue('date')[1].format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss'),
            employeeId: localStorage.salesEmployeeId != "null" ? localStorage.salesEmployeeId : null,
            merchantCode
            // type: state && state.type ? state.type : values.type,
        };
        (async _ => {
            return await accountLogEmployeeRequest(params);
        })().then(res => {
            if (res.code == 0) {
                setList(res.data.lists)
                setTotal(res.data.totalCount)
            } else {
                message.error(res.msg)
            }
        });
    }
    const getAccountLogList = (data) => {

        let values = form.getFieldValue()
        console.log(values);
        if (!values.orderNo) {
            delete values.orderNo
        }
        let params = {
            ...values,
            currentPage: data && data.currentPage ? data.currentPage : currentPage,
            pageSize: pageNum,
            superMerchantCode: localStorage.appid,
            accountType: values && values.accountType ? values.accountType : state && state.accountType && state.accountType.toString() && state.accountType.toString(),
            busiTypes: values && values.busiTypes ? values.busiTypes : state && state.businessTradeType && [state.businessTradeType],
            startTime: form.getFieldValue('date') && form.getFieldValue('date')[0].format('YYYY-MM-DD HH:mm:ss') ? form.getFieldValue('date')[0].format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss'),
            endTime: form.getFieldValue('date') && form.getFieldValue('date')[1].format('YYYY-MM-DD HH:mm:ss') ? form.getFieldValue('date')[1].format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss'),
            // type: state && state.type ? state.type : values.type,
            merchantCode,
        };
        (async _ => {
            return await accountLogListRequest(params);
        })().then(res => {
            if (res.code == 0) {
                setList(res.data.lists)
                setTotal(res.data.totalCount)
            } else {
                message.error(res.msg)
            }
        });
    }
    const onChangPage = (currentPage, pageNum) => {
        // setInit(false)
        setCurrentPage(currentPage, () => {
            // getAccountLogList()
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        // setInit(false)
        setPageNum((currentPage, pageNum), () => {
            // getAccountLogList()
        })
    }
    const onFinish = (values) => {
        console.log(!values.orderNo);
        const rangeValue = values['date'];
        values.startTime = rangeValue && rangeValue[0].format('YYYY-MM-DD HH:mm:ss');
        values.endTime = rangeValue && rangeValue[1].format('YYYY-MM-DD HH:mm:ss');
        values.currentPage = 1;
        delete values.date
        if (localStorage.salesEmployeeId == "null") {
            getAccountLogList()
        } else {
            accountLogEmployee()
        }
    };

    const inSevenDays = () => {
        form.setFieldsValue({
            date: [moment(moment().subtract(7, "days").format("YYYY-MM-DD"), dateFormat), moment(moment().format('YYYY-MM-DD'), dateFormat)]
        })
    }
    const yesterday = () => {
        form.setFieldsValue({
            date: [moment(moment().subtract(1, "days").format("YYYY-MM-DD"), dateFormat), moment(moment().subtract(1, "days").format('YYYY-MM-DD'), dateFormat)]
        })
    }
    const today = () => {
        form.setFieldsValue({
            date: [moment(moment().format('YYYY-MM-DD'), dateFormat), moment(moment().format('YYYY-MM-DD'), dateFormat)]
        })


    }
    const timeChange = (date) => {
        setStartDate(date && date[0].format("YYYY-MM-DD"))
        setEndDate(date && date[1].format('YYYY-MM-DD'))
    }
    const exportFunc = () => {
        let values = form.getFieldValue()
        console.log(localStorage.operatorId)
        let params = {
            ...values,
            superMerchantCode: localStorage.appid,
            accountType: values && values.accountType ? values.accountType : state && state.accountType && state.accountType.toString() && state.accountType.toString(),
            busiTypes: values && values.busiTypes ? values.busiTypes : state && state.businessTradeType && [state.businessTradeType],
            startTime: form.getFieldValue('date') && form.getFieldValue('date')[0].format('YYYY-MM-DD HH:mm:ss') ? form.getFieldValue('date')[0].format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss'),
            endTime: form.getFieldValue('date') && form.getFieldValue('date')[1].format('YYYY-MM-DD HH:mm:ss') ? form.getFieldValue('date')[1].format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss'),
            operatorId: localStorage.operatorId,
            operatorName: localStorage.operatorname,
            merchantCode,
        };
        delete params.date;
        (async _ => {
            return await listExportRequest(params);
        })().then(res => {
            if (res.code == 0) {
                message.success(res.msg)
            } else {
                message.error(res.msg)
            }
        });

    }
    return (
        <div style={{ background: '#ffffff', paddingTop: 1, marginTop: 16 }}>
            <Form
                form={form}
                {...layout}
                name="advanced_search"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                style={{ background: '#F7F8FA', margin: 16, padding: 16, paddingBottom: 0 }}
                initialValues={{
                    accountType: state && state.accountType && state.accountType.toString() ? state.accountType.toString() : null,
                    merchantCode: state && state.merchantCode ? state.merchantCode : null,
                    busiTypes: state && state.businessTradeType ? [state.businessTradeType] : undefined,
                    date: state && state.statisticsDate ? [moment(state.statisticsDate, dateFormat), moment(state.statisticsDate, dateFormat)] : state && state.startTime ? [moment(state.startTime, dateFormat), moment(state.startTime, dateFormat)] : [moment(moment().format('YYYY-MM-DD'), dateFormat), moment(moment().format('YYYY-MM-DD'), dateFormat)],
                    payType: null,
                    orderSource: null,
                    type: state && state.type ? state.type : null,
                }}
            >
                {
                    localStorage.salesEmployeeId == 'null' ?
                        <Row style={{ marginBottom: '-1vh' }}>
                            {
                                localStorage.hxState == 'true' ?
                                    <Col span={6}>
                                        <Form.Item
                                            name="merchantCode"
                                            label="商户："
                                        >
                                            <Select placeholder="请选择"
                                                style={{ width: 180 }}
                                                onChange={(code) => {
                                                    setMerchantCode(code)
                                                }}
                                                allowClear
                                                optionFilterProp="children"
                                                showSearch
                                                filterOption={(input, option) => option.children.includes(input)}
                                            >
                                                <Option rowKey={null} key={null} value={null}>全部</Option>
                                                {
                                                    merchantList && merchantList.map((v) => {
                                                        return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                                    })
                                                }

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    :
                                    <Col span={6}>
                                        <Form.Item
                                            label="商户："
                                        >
                                            {localStorage.merchantName}
                                        </Form.Item>
                                    </Col>

                            }

                        </Row> : null

                }

                <Row style={{ marginBottom: '-1vh' }}>
                    <Col span={6}>
                        <Form.Item
                            name="date"
                            label="账单时间："
                        >
                            <div style={{ display: 'flex', alignItems: 'center', width: 400, }}>
                                <Form.Item
                                    name="date"
                                    noStyle
                                >
                                    <RangePicker onChange={timeChange} style={{ width: 250 }} />
                                </Form.Item>
                                <Form.Item
                                    // name="date"
                                    noStyle
                                >
                                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }}
                                        onClick={today} >今天</span>
                                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }}
                                        onClick={yesterday} >昨天</span>
                                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }}
                                        onClick={inSevenDays} >近7天</span>
                                </Form.Item>
                            </div>



                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{ marginBottom: '-1vh' }}>
                    <Col span={6} >
                        <Form.Item
                            name="accountType"
                            label="账户："
                        >
                            <Select style={{ width: 180 }}>
                                <Option key={null} value={null}>全部</Option>
                                {
                                    accountTypeList && accountTypeList.map((v) => {
                                        return <Option rowKey={v.constantCode} key={v.constantCode} value={v.constantCode}>{v.constantName}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="busiTypes"
                            label="类型："
                        >

                            <Select placeholder="请选择"
                                style={{ width: 180 }}
                                mode="multiple"
                                allowClear
                                optionFilterProp="children"
                                showSearch
                                filterOption={(input, option) => option.children.includes(input)}
                            >
                                {
                                    typeList && typeList.map((v) => {
                                        return <Option rowKey={v.busiType} key={v.busiType} value={v.busiType}>{v.busiTypeName}</Option>
                                    })
                                }

                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="type"
                            label="收支类型："
                        >

                            <Select placeholder="请选择"
                                style={{ width: 180 }}
                                allowClear
                            >
                                <Option rowKey={null} key={null} value={null}>全部</Option>
                                <Option rowKey={0} key={0} value={0}>收入</Option>
                                <Option rowKey={1} key={1} value={1}>支出</Option>

                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '-1vh' }}>
                    <Col span={6}>
                        <Form.Item
                            name="payType"
                            label="支付方式："
                        >
                            <Select style={{ width: 180 }}>
                                <Option value={null}>全部</Option>
                                <Option value="0">微信</Option>
                                <Option value="1">支付宝</Option>
                                <Option value="2">会员</Option>
                                <Option value="3">银行卡</Option>
                                <Option value="4">现金</Option>
                                <Option value="5">云闪付</Option>
                                <Option value="6">漕河泾(饿了么)</Option>
                                <Option value="7">组合支付</Option>
                                <Option value="8">余额支付</Option>
                                <Option value="9">授信支付</Option>
                                <Option value="17">翼支付</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="orderSource"
                            label="渠道："
                        >
                            <Select style={{ width: 180 }}>

                                <Option value={null}>全部</Option>
                                {
                                    orderSource && orderSource.map((v) => {
                                        return <Option rowKey={v.orderSource} key={v.orderSource} value={v.orderSource}>{v.orderSourceName}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="orderNo"
                            label="单号："
                        >
                            <Input style={{ width: 180 }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '-1vh' }}>
                    <Col span={12}>
                        <Form.Item
                            wrapperCol={{
                                offset: 3,
                                span: 20,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                筛选
                            </Button>
                            <Button
                                style={{ marginLeft: 20 }}
                                onClick={() => {
                                    exportFunc()
                                }}>
                                导出
                            </Button>
                            <Button type="link"
                                style={{ marginLeft: 10 }}
                                onClick={() => {
                                    form.resetFields();
                                    getAccountLogList()
                                }} >
                                重置筛选条件
                            </Button>
                            <Button type="link" onClick={() => {
                                setExportVisible(true)
                            }} >
                                查看已导出列表
                            </Button>

                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                locale={{
                    emptyText: "没有更多数据了"
                }}
                rowKey='id'
                style={{ marginTop: 16, margin: '0 16px' }}
                columns={columns}
                dataSource={list}
                scroll={{
                    x: 2000,
                }}
                pagination={{
                    total,
                    showSizeChanger: true,
                    current: currentPage,
                    pageSize: pageNum,
                    // position: ["bottomCenter"],
                    showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                    onChange: (k, v) => onChangPage(k, v),
                    onShowSizeChange: (k, v) => onChangPageNum(k, v),
                }}
            />
            <Modal
                title="所有关联记录"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={1200}
                footer={null}
            >
                <Table
                    locale={{
                        emptyText: "没有更多数据了"
                    }}
                    rowKey='id'
                    style={{ marginTop: 20 }}
                    columns={columns1}
                    dataSource={guanlianList}
                    scroll={{
                        x: 2000,
                    }}
                    pagination={{
                        glTotal,
                        showSizeChanger: true,
                        current: currentPage,
                        pageSize: pageNum,
                        // position: ["bottomCenter"],
                        showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                        onChange: (k, v) => onChangPage(k, v),
                        onShowSizeChange: (k, v) => onChangPageNum(k, v),
                    }}
                />
            </Modal>
            <Modal
                title="详情"
                visible={detailVisible}
                onOk={detailHandleOk}
                onCancel={detailhandleCancel}
                width={1200}
                footer={null}
            >
                <Detail data={row} />
            </Modal>
            <Modal
                title="导出列表"
                visible={exportVisible}
                onOk={exportHandleOk}
                onCancel={exporthandleCancel}
                width={1200}
                footer={null}
                key={Math.random(10)}
            >
                <ExportList type={1} merchantCode={form.getFieldValue().merchantCode} />
            </Modal>
        </div >
    )
}
export default StatementList;