import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  Form,
  Input,
  Switch,
  Row,
  Col,
  Button,
  Tabs,
  Table,
  message,
  Modal,
  Radio,
  Space,
  Skeleton
} from 'antd';
import { creditDetailRequest } from '../../../api/api'
import host from '../../../util/host';




const BillingDetails = () => {
  const [list, setList] = useState([])
  const [data, setData] = useState([])
  let navigate = useNavigate();
  const { state } = useLocation();
  useEffect(() => {
    getCreditDetail()
  }, [])
  const getCreditDetail = () => {
    (async _ => {
      return await creditDetailRequest(
        {
          billId: state.billId ? state.billId : state.id,
        }
      );
    })().then(res => {
      if (res.code == 0) {
        setList(res.data)
      } else {
        message.error(res.msg)
      }
    });
  }
  const columns = [
    {
      title: '类型',
      dataIndex: 'busiType',
      key: 'busiType',
    },
    {
      title: '入账时间',
      dataIndex: 'createTime',
      key: 'createTime',
    },

    {
      title: '订单编号｜支付单号',
      dataIndex: 'orderNo',
      key: 'orderNo',
      render: (text, row) => {
        return (
          <div>
            <div>{row.orderNo}</div>
            <div style={{ color: '#969799' }}>{row.thirdTradeNo}</div>
          </div>
        )
      }
    },
    {
      title: '账户',
      dataIndex: 'accountType',
      key: 'accountType',
      render: (text, row) => {
        return (
          <div>
            {row.accountType == 0 ? '店铺余额待结算' : ''}
            {row.accountType == 1 ? '可用账户' : ''}
            {row.accountType == 2 ? '钱包账户' : ''}
            {row.accountType == 3 ? '信用可提现账户' : ''}
            {row.accountType == 4 ? '钱包余额待结算' : ''}
            {row.accountType == 5 ? '收益账户' : ''}
            {row.accountType == 6 ? '授信账户' : ''}
            {row.accountType == 7 ? '营销余额待结算' : ''}
            {row.accountType == 8 ? '营销余额' : ''}
          </div>
        )
      }
    },
    {
      title: '收支类型',
      dataIndex: 'orderNo',
      key: 'orderNo',
      render: (text, row) => {
        return (
          <div>{row.type == 0 ? '入账' : '出账'}</div>
        )
      }
    },
    {
      title: '金额(元)',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: '余额(元)',
      dataIndex: 'afCaBalance',
      key: 'afCaBalance',
    },
    {
      title: '支付方式',
      dataIndex: 'payType',
      key: 'payType',
      render: (text, row) => {
        return (
          <div>
            {row.payType == 0 ? '微信' : ''}
            {row.payType == 1 ? '支付宝' : ''}
            {row.payType == 2 ? '会员' : ''}
            {row.payType == 3 ? '银行卡' : ''}
            {row.payType == 4 ? '现金' : ''}
            {row.payType == 5 ? '云闪付' : ''}
            {row.payType == 6 ? '漕河泾(饿了么)' : ''}
            {row.payType == 7 ? '组合支付' : ''}
            {row.payType == 17 ? '翼支付' : ''}

          </div>
        )
      }
    },
    {
      title: '操作',
      key: 'action',
      align: 'right',
      render: (text, row) => (
        <>
          <span style={{ color: '#165BD4', cursor: 'pointer' }} onClick={() => {
            navigate("/ws/statementList", { replace: false, state: { ...state, ...row } })
          }}>详情</span>
          {/* <Link to="/ws/statementList">详情</Link> */}
          {/* <Link style={{ marginLeft: 10 }} to="">关联记录</Link> */}
        </>

      ),
    },
  ];

  return (
    <div style={{ height: '100vh', background: '#ffffff', paddingBottom: 20 }}>

      <Row align='middle' style={{ width: '100%', padding: '10px 0', marginTop: 10, }}>
        <Row justify='space-between' style={{ width: '100%', background: '#F7F8FA', padding: 10, margin: '0 10px' }}>
          <Col span={8}>
            <div style={{ fontSize: 24, fontWeight: 500, }}>
              {state && state.startDate && state.startDate.substring(0, 10)}~{state && state.endDate && state.endDate.substring(0, 10)} 账单汇总详情
            </div>
            <div>
              <span style={{ color: '#969799' }}>商户名称：</span>
              <span>{state && state.merchantName}</span>
            </div>

          </Col>
          <Col span={8}>
            <div style={{ fontSize: 24, fontWeight: 500, color: '#F7F8FA' }}>1</div>
            <div>
              <span style={{ color: '#969799' }}>账单周期：</span>
              <span>{state && state.startDate}至{state && state.endDate}</span>
            </div>
          </Col>
        </Row>
      </Row>
      {/* <Row>
        <Button type='primary' style={{ marginLeft: 10, marginTop: 10 }}>打印汇总详情</Button>
      </Row> */}
      <Row justify='space-between' style={{ background: '#F7F8FA', padding: 20, margin: 10 }}>
        <Col span={6}>
          <div>
            <span>本期账单金额(元)</span>
          </div>
          <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{state && state.oweGoodsAmount}</div>
        </Col>
        <Col span={6}>
          <div><span>本期消费金额(元)</span></div>
          <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{state && state.totalGoodsAmount}</div>
        </Col>
        <Col span={6}>
          <div><span>本期退款金额(元)</span></div>
          <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{state && state.refundGoodsAmount}</div>

        </Col>
        <Col span={6}>
          <div><span>本期已还款金额(元)</span></div>
          {/* <Row align='middle' style={{ marginTop: 10 }}> */}
          <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{state && state.rcGoodsAmount}<span style={{ color: 'red', fontSize: 12, marginLeft: 16 }}>(待还款￥{state && state.needRcGoodsAmount && state.needRcGoodsAmount.toFixed(2)})</span></div>
          {/* </Row> */}

        </Col>
      </Row>
      <Row align='middle' style={{ padding: '10px 10px', marginTop: 10 }}>
        <Row align='middle' justify='space-between' style={{ padding: '10px 10px', width: '100%', height: '100%', background: '#F7F8FA' }}>
          <Row align='middle' >
            <div style={{ background: '#145BD5', height: 14, width: 3, }}></div><div style={{ marginLeft: 10, fontWeight: 500 }}>交易记录</div>
          </Row>
        </Row>
      </Row>

      <Row style={{ padding: "0px 10px" }}>
        <Col span={24}>
          <Table
            locale={{
              emptyText: "没有更多数据了"
            }}
            columns={columns}
            rowKey='id'
            dataSource={list}
            pagination={false}
          />
        </Col>

      </Row>
    </div>

  )
}
export default BillingDetails;
