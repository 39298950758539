import { Row, Col, Button, Form, Table, Select, message, Tabs, DatePicker, Input } from 'antd';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { incomeAccountViewRequest, settleMerchantListRequest, oweListRequest } from '../../api/api'
import { useCallbackState } from '../../util/useCallbackState'
import moment from 'moment';
const dateFormat = 'YYYY-MM-DD';
const { TabPane } = Tabs;
const { Option } = Select;
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};
const { RangePicker } = DatePicker;
const AvailableFunds = () => {
    const [form] = Form.useForm();
    const [data, setData] = useState();
    const { search } = useLocation()
    const { state } = useLocation()
    const [list, setList] = useState([]);
    const [merchantList, setMerchantList] = useState([])
    const [merchantCode, setMerchantCode] = useState(localStorage.hxState == 'true' ? null : localStorage.merchantCode)
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [total, setTotal] = useState()
    const [init, setInit] = useState(true)
    const navigate = useNavigate();
    useEffect(() => {

        oweList();
        if (init) {
            settleMerchantList();
        }

    }, [currentPage, pageNum]);

    const columns = [
        {
            title: '欠款时间',
            dataIndex: 'createTime',
            key: 'createTime',
            align: 'left'
        },
        {
            title: '垫付门店',
            dataIndex: 'advancePaymentMerchantName',
            key: 'advancePaymentMerchantName',
            align: 'left'
        },
        {
            title: '欠款门店',
            dataIndex: 'merchantName',
            key: 'merchantName',
            align: 'left'
        },
        {
            title: '欠款金额(元)',
            dataIndex: 'oweAmount',
            key: 'oweAmount',
            align: 'left',
        },
        {
            title: '订单类型',
            dataIndex: 'busiTypeName',
            key: 'busiTypeName',
            align: 'left',
        },
        {
            title: '还款状态',
            dataIndex: 'oweStatus',
            key: 'oweStatus',
            align: 'left',
            render: (text, row) => {
                return (
                    <div>
                        {row.oweStatus == 0 ? '待还款' : ''}
                        {row.oweStatus == 1 ? '已还款' : ''}
                    </div>
                )
            }
        },
        {
            title: '欠款单号/业务单号',
            dataIndex: 'outOrderNoExt',
            key: 'outOrderNoExt',
            align: 'left',
        },
        // {
        //     title: '操作',
        //     key: 'operation',
        //     fixed: 'right',
        //     width: 140,
        //     align: 'right',
        //     render: () => (
        //         <>
        //             <span style={{ color: '#165BD4', cursor: 'pointer' }} onClick={() => {
        //                 navigate('/ws/revenueAccount', { replace: false, state: { tag: 1, } })
        //             }}>查看</span>
        //         </>

        //     ),

        // },
    ];

    const onFinish = (values) => {
        values.currentPage = 1;


        oweList(values)
    };
    const oweList = (data) => {
        let values = form.getFieldValue()
        const rangeValue = values['date'];
        values.startTime = rangeValue && rangeValue[0].format('YYYY-MM-DD');
        values.endTime = rangeValue && rangeValue[1].format('YYYY-MM-DD');
        let params = {
            ...data,
            ...values,
            currentPage: data && data.currentPage ? data.currentPage : currentPage,
            pageSize: pageNum,
            superMerchantCode: localStorage.appid,
        };
        (async _ => {
            return await oweListRequest(params);
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setData(res.data)
                setList(res.data.orderList)
                setTotal(res.data.totalCount)
            } else {

            }
        });
    }

    const settleMerchantList = () => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            if (res.code == 0) {
                setInit(false)
                setMerchantList(res.data)
            } else {

            }
        });
    }

    const onChangPage = (currentPage, pageNum) => {
        setCurrentPage(currentPage, () => {
            // getIncomeAccountViewRequest()
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        setPageNum((currentPage, pageNum), () => {
            // getIncomeAccountViewRequest()
        })
    }
    const inSevenDays = () => {
        form.setFieldsValue({
            date: [moment(moment().subtract(7, "days").format("YYYY-MM-DD"), dateFormat), moment(moment().format('YYYY-MM-DD'), dateFormat)]
        })
    }
    const yesterday = () => {
        form.setFieldsValue({
            date: [moment(moment().subtract(1, "days").format("YYYY-MM-DD"), dateFormat), moment(moment().subtract(1, "days").format('YYYY-MM-DD'), dateFormat)]
        })
    }
    const today = () => {
        form.setFieldsValue({
            date: [moment(moment().format('YYYY-MM-DD'), dateFormat), moment(moment().format('YYYY-MM-DD'), dateFormat)]
        })


    }
    return (
        <div style={{ background: '#ffffff', padding: 16, marginTop: 10 }}>
            <Row justify='space-between' style={{ background: '#F7F8FA', padding: 16 }}>
                <Col span={12}>
                    <div><span>代还款金额(元)</span>
                        {/* <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 20 }} onClick={goWithdrawalRecord}>提现记录</span> */}
                    </div>
                    <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{data && data.totalOweAmount}</div>
                </Col>
                <Col span={12}>
                    <Row>
                        <Col span={12}>
                            <div><span>代还款单数(元)</span>
                                {/* <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 20 }} onClick={() => {
                                    navigate('/ws/balance/availableAndAvailable', { replace: false, state: { tag: 1 } })
                                }}>明细</span> */}
                            </div>
                            <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{data && data.totalOweNum}</div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* <Tabs style={{ marginTop: 16, }} activeKey={'9'} onChange={(e) => {
                console.log(e);
            }} type="card">
                <TabPane tab="欠款记录" key='9'>
                </TabPane>
                <TabPane tab="还款记录" key='3'>
                </TabPane>
            </Tabs> */}
            <Form
                form={form}
                {...layout}
                name="advanced_search"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                style={{ background: '#F7F8FA', margin: 16, padding: 16, paddingBottom: 0, marginLeft: 0 }}
                initialValues={{
                    oweStatus: null,
                    merchantCode: null,
                    date: [moment(moment().format('YYYY-MM-DD'), dateFormat), moment(moment().format('YYYY-MM-DD'), dateFormat)],
                }}
            >


                <Row justify="start" style={{ marginBottom: '-1vh' }}>
                    <Col span={8}>
                        <Form.Item
                            name="date"
                            label="欠款时间"
                        >
                            <div style={{ display: 'flex', alignItems: 'center', width: 400, }}>
                                <Form.Item
                                    name="date"
                                    noStyle
                                >
                                    <RangePicker style={{ width: 250 }} />
                                </Form.Item>
                                <Form.Item
                                    noStyle
                                >
                                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }}
                                        onClick={today} >今天</span>
                                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }}
                                        onClick={yesterday} >昨天</span>
                                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }}
                                        onClick={inSevenDays} >近7天</span>
                                </Form.Item>
                            </div>



                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item
                            name="oweStatus"
                            label="还款状态"
                        >
                            <Select placeholder="请选择"
                                style={{ width: 200 }}
                                onChange={(code) => {
                                }}
                                allowClear
                                optionFilterProp="children"
                                showSearch
                                filterOption={(input, option) => option.children.includes(input)}
                            >
                                <Option key={null} value={null}>全部</Option>
                                <Option key={0} value={0}>待还款</Option>
                                <Option key={1} value={1}>已还款</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        {
                            localStorage.hxState == 'true' ?
                                <Form.Item
                                    name="merchantCode"
                                    label="欠款门店"
                                >
                                    <Select placeholder="请选择"
                                        style={{ width: 200 }}
                                        onChange={(code) => {
                                            setMerchantCode(code)
                                        }}
                                        allowClear
                                        optionFilterProp="children"
                                        showSearch
                                        filterOption={(input, option) => option.children.includes(input)}
                                    >
                                        <Option key={null} value={null}>全部</Option>
                                        {
                                            merchantList && merchantList.map((v) => {
                                                return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                            })
                                        }

                                    </Select>
                                </Form.Item>
                                :
                                <Form.Item
                                    name="merchantCode"
                                    label="欠款门店"
                                >
                                    <Select placeholder="请选择"
                                        style={{ width: 200 }}
                                        onChange={(code) => {
                                            setMerchantCode(code)
                                        }}
                                        allowClear
                                        optionFilterProp="children"
                                        showSearch
                                        filterOption={(input, option) => option.children.includes(input)}
                                    >
                                        {localStorage.merchantName}

                                    </Select>
                                </Form.Item>
                        }

                    </Col>
                    {/* <Col span={8}>
                        <Form.Item
                            name="orderNo"
                            label="单号"
                        >
                            <Input placeholder="请输入单号" style={{ width: 200 }} />
                        </Form.Item>
                    </Col> */}
                </Row>

                <Row style={{ marginBottom: '-1vh' }}>
                    <Col span={12}>
                        <Form.Item
                            wrapperCol={{
                                offset: 4,
                                span: 20,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                筛选
                            </Button>
                            {/* <Button
                                style={{ marginLeft: 20 }}
                                onClick={() => {
                                    exportFunc()
                                }}>
                                导出
                            </Button> */}
                            <Button type="link"
                                style={{ marginLeft: 10 }}
                                onClick={() => {
                                    form.setFieldsValue({
                                        merchantCode: null,
                                        oweStatus: null,
                                        date: [moment(moment().format('YYYY-MM-DD'), dateFormat), moment(moment().format('YYYY-MM-DD'), dateFormat)],
                                    })
                                    oweList()
                                }} >
                                重置筛选条件
                            </Button>
                            {/* <Button type="link" onClick={() => {
                            }} >
                                查看已导出列表
                            </Button> */}

                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Table
                locale={{
                    emptyText: "没有更多数据了"
                }}
                style={{ marginTop: 16 }}
                columns={columns}
                dataSource={list}
                rowKey="id"
                pagination={{
                    total,
                    showSizeChanger: true,
                    current: currentPage,
                    pageSize: pageNum,
                    // position: ["bottomCenter"],
                    showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                    onChange: (k, v) => onChangPage(k, v),
                    onShowSizeChange: (k, v) => onChangPageNum(k, v),
                }}
            />
        </div>
    )
}
export default AvailableFunds