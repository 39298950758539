import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Tabs, Table, message, Modal } from 'antd';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { balanceViewRequest, listByAccountRequest, walletViewRequest } from '../../../api/api'
import Detail from '../../StatementList/detail/index'
import { type } from '@testing-library/user-event/dist/type';
const { TabPane } = Tabs;
const AvailableBalance = () => {
    let navigate = useNavigate();
    const { state } = useLocation()
    const [list, setList] = useState([])
    const [data, setData] = useState([])
    const [accountTypeList, setAccountTypeList] = useState([0, 1])
    const [accountType, setAccountType] = useState(null)
    const [mySwitch, setMySwitch] = useState(state && state.tag == 1 ? true : localStorage.state == "1" ? true : false)
    const { search } = useLocation()
    const superMerchantCode = localStorage.appid
    useEffect(() => {
        console.log(state)
        walletView();
    }, [accountTypeList])


    const walletView = (data) => {
        (async _ => {
            return await walletViewRequest({
                ...data,
                currentPage: 1,
                pageSize: 10,
                merchantCode: state && state.merchantCode ? state.merchantCode : localStorage.merchantCode,
                superMerchantCode,
                accountTypeList: accountTypeList,
                busiTypes: [32]
            });
        })().then(res => {
            if (res.code == 0) {
                console.log(res);
                setList(res.data.list)
                setData(res.data)
            } else {

            }
        });
    }

    const columns = [
        {
            title: '时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '订单类型',
            dataIndex: 'busiType',
            key: 'busiType',
        },

        {
            title: '金额(元)',
            dataIndex: 'amount',
            key: 'amount',
            render: (text, row) => (
                <div>
                    {
                        row.type == 1 ?
                            <div style={{ color: '#FF0000' }}>-{row.amount}</div>
                            :
                            <div style={{ color: '#06D61E' }}>+{row.amount}</div>
                    }

                </div>
            ),
        },
        {
            title: '余额(元)',
            dataIndex: 'afCaBalance',
            key: 'afCaBalance',
        },
        {
            title: '业务单号｜支付流水号',
            dataIndex: 'address',
            key: '2',
            render: (text, row) => (
                <div>
                    <div >{row.orderNo}</div>
                    <div style={{ color: '#969799' }}>{row.thirdTradeNo}</div>
                </div>
            ),
        },
    ];
    const goWithdrawal = () => {
        navigate('/ws/balance/withdrawal')
    }
    const goRechargeRecord = () => {
        navigate('/ws/balance/recharge', { replace: false, state: { defaultActiveKey: 2, data: state, tag: 1, } })
    }
    const goWithdrawalRecord = () => {
        navigate('/ws/balance/withdrawal', { replace: false, state: { defaultActiveKey: 2, data: state, tag: 1, } })
    }
    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            <Row align='middle' style={{ background: '#F7F8FA', padding: 10, margin: 10 }}>
                <div style={{ background: '#145BD5', height: 14, width: 3 }}></div><div style={{ marginLeft: 10, fontWeight: 500 }}>钱包余额</div>
            </Row>
            <Row justify='space-between' style={{ background: '#F7F8FA', padding: 20, margin: '20px 10px' }}>
                <Col span={12}>
                    <div>
                        <span>可用余额(元)</span>
                        <Button type='link' onClick={goRechargeRecord}>充值记录</Button>
                        {/* <Button type='link' onClick={goWithdrawalRecord}>提现记录</Button> */}

                    </div>
                    <div style={{ marginTop: 10, display: 'flex', alignItems: 'center' }}>
                        <span style={{ fontSize: 24, fontWeight: 500, }}>{data && data.walletAvailableBalance}</span>
                        {/* <Button style={{ marginLeft: 20 }} type="primary" onClick={goRecharge}>充值</Button> */}
                        {/* <Button style={{ marginLeft: 20 }} onClick={goWithdrawal}>提现</Button> */}
                    </div>
                </Col>
                <Col span={12}>
                    <Row>
                        <Col span={12}>
                            <div>
                                <span>待结算金额(元)</span>
                                <Button type='link'
                                    onClick={() => {
                                        navigate('/ws/balance/settlementRecords', { replace: false, state: { ...state, billType: 3, accountType: 4 } })
                                    }}
                                >结算记录</Button>
                                {/* <Link style={{ marginLeft: 20 }} to=''>结算记录</Link> */}
                            </div>
                            <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{data && data.settleBalance}</div>
                        </Col>
                        <Col span={12}>
                            <div>
                                <span>不可用余额(元)</span>
                                <Button type='link'
                                    onClick={() => {
                                        navigate('/ws/balance/availableAndAvailable', { replace: false, state: { tag: 1, ...state && state }, })
                                    }}
                                >明细</Button>
                            </div>
                            <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{data && data.walletFreezenBalance}</div>
                        </Col>
                    </Row>
                </Col>
                {/* <Col span={6}>1</Col>
<Col span={6}>1</Col> */}
            </Row>
            <Row justify='space-between' align='middle' style={{ background: '#F7F8FA', padding: 10, margin: 10 }}>
                <Col>
                    <div style={{ background: '#145BD5', height: 14, width: 3 }}></div>
                </Col>
                <Col>
                    <div style={{ marginLeft: 10, fontWeight: 500, textAlign: 'right' }}>最近收支记录</div>
                </Col>
                <Col flex={1} style={{ textAlign: 'right' }}>
                    <Button type='link'
                        onClick={() => {
                            navigate('/ws/statementList', {
                                replace: false,
                                state: { tag: 1, merchantCode: state && state.merchantCode, accountType: accountType && accountType.toString() }
                            })
                        }}
                    >查看收支交易</Button>
                </Col>
            </Row>
            <Row style={{ padding: "0px 10px" }}>
                <Col span={24}>
                    <Table
                        locale={{
                            emptyText: "没有更多数据了"
                        }}
                        rowKey='id'
                        columns={columns}
                        dataSource={list}
                        pagination={false}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default AvailableBalance;