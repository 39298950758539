import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Tabs, DatePicker, message, Form, Input, Select } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { settleMerchantListRequest, bookAccountListRequest, bookOrderSaveRequest } from '../../../api/api'
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const { TabPane } = Tabs;
const { Option } = Select;
const Keep = () => {
    let navigate = useNavigate();
    const { state } = useLocation()
    const { search } = useLocation()
    const [merchantList, setMerchantList] = useState([])
    const [merchantCode, setMerchantCode] = useState(localStorage.merchantCode)
    const [merchantCode1, setMerchantCode1] = useState(localStorage.merchantCode)
    const [accountList, setAccountList] = useState([])
    const [accountName, setAccountName] = useState(null)
    const [bookType, setBookType] = useState(0)
    useEffect(() => {
        settleMerchantList();
        bookAccountList();
    }, [])
    const onFinish = (values) => {
        values.bookTime = values.bookTime && values.bookTime.format('YYYY-MM-DD HH:mm:ss');
        values.merchantCode = merchantCode;
        values.tradeMerchantCode = merchantCode1;
        values.accountName = accountName;
        values.bookType = bookType;
        values.operaterName = localStorage.operatorname;
        values.operaterId = localStorage.operatorId;
        (async _ => {
            return await bookOrderSaveRequest({
                ...values,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            if (res.code == 0) {
                navigate('/ws/keepAccountsList/list')
            } else {
                message.error(res.msg)
            }
        });
    };

    const settleMerchantList = () => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode: localStorage.merchantCode,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            if (res.code == 0) {
                setMerchantList(res.data)
            } else {

            }
        });
    }
    const bookAccountList = () => {
        (async _ => {
            return await bookAccountListRequest({
                merchantCode: localStorage.merchantCode,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            console.log(res)
            if (res.code == 0) {
                setAccountList(res.data.lists)
            } else {

            }
        });
    }

    const onChange = (key) => {
        setBookType(key)
    }



    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            <Row style={{ padding: "0px 10px" }}>
                <Tabs onChange={onChange} type="card">
                    <TabPane tab="记收入" key='0'>
                    </TabPane>
                    <TabPane tab="记支出" key='1'>
                    </TabPane>

                </Tabs>
            </Row>
            <Form {...layout} name="nest-messages" onFinish={onFinish}>
                <Row>
                    <Col span={8}>
                        <Form.Item
                            name='merchantCode'
                            label="选择机构"
                            rules={[
                                {
                                    required: localStorage.hxState == 'true' ? true : false,
                                },
                            ]}
                        >
                            {localStorage.hxState == 'true' ?

                                <Select placeholder="请选择"
                                    style={{ width: 240 }}
                                    onChange={(code) => {
                                        setMerchantCode(code)
                                    }}
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.includes(input)}
                                >

                                    {
                                        merchantList && merchantList.map((v) => {
                                            return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                        })
                                    }

                                </Select>
                                :
                                localStorage.merchantName
                            }

                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item
                            name='bookTime'
                            label="记账时间"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <DatePicker showTime style={{ width: 240 }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item
                            name='amount'
                            label="发生金额"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input prefix="￥" style={{ width: 240 }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item
                            name='accountNo'
                            label="记账账户"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select style={{ width: 240 }} onChange={(e) => {
                                accountList && accountList.map((v) => {
                                    if (v.accountNo == e) {
                                        setAccountName(v.accountName)
                                    }
                                })

                            }}>
                                {
                                    accountList && accountList.map((v) => {
                                        return (
                                            <Option value={v.accountNo} key={v.accountNo}>{v.accountName}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item
                            name='orderType'
                            label="订单类型"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select style={{ width: 240 }} >
                                <Option value="0" key="0">充值</Option>
                                <Option value="1" key="1">退款</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item
                            name='bookName'
                            label="名称"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input style={{ width: 240 }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item
                            name='orderNo'
                            label="订单单号"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input style={{ width: 240 }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item
                            name='payTradeNo'
                            label="支付流水号"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input style={{ width: 240 }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item
                            name='tradeObjectName'
                            label="交易对象"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input style={{ width: 240 }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item
                            name='payType'
                            label="支付方式"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Select style={{ width: 240 }} >
                                <Option value="0" key="0">微信</Option>
                                <Option value="1" key="1">支付宝</Option>
                                <Option value="3" key="3">银行卡</Option>
                                <Option value="4" key="4">现金</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item
                            name='tradeMerchantCode'
                            label="交易发生店铺"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            {localStorage.hxState == 'true' ?
                                <Select placeholder="请选择"
                                    allowClear
                                    style={{ width: 240 }}
                                    onChange={(code) => {
                                        setMerchantCode1(code)
                                    }}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.includes(input)}
                                >

                                    {
                                        merchantList && merchantList.map((v) => {
                                            return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                        })
                                    }

                                </Select>
                                :
                                localStorage.merchantName
                            }
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item name='remark' label="备注">
                            <Input.TextArea style={{ width: 240 }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item
                            wrapperCol={{
                                ...layout.wrapperCol,
                                offset: 8,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                提交
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>


            </Form>

        </div>
    );
}

export default Keep;