import React, { useState, useEffect, useReducer } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import {
    Form,
    Row,
    Col,
    Button,
    Input,
    Select,
    Table,
    Popconfirm,
    DatePicker,
    Modal,
    message,
    Tabs,
    Radio,
    Upload,
} from 'antd';
import { reconciliationListRequest, onlineOrderListRequest, deleteMTExcelByIdRequest, settleMerchantListRequest, getBusiListRequest, relationRecordRequest, getSourceListRequest, listExportRequest } from '../../api/api'
import { useCallbackState } from '../../util/useCallbackState'
import Detail from './detail/index'
import Buchang from './buchang/index'
import host from '../../util/host';
const { TabPane } = Tabs;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};
const { RangePicker } = DatePicker;
const StatementList = () => {
    const [form] = Form.useForm();
    const { state } = useLocation();
    const [list, setList] = useState(Array)
    const [lists, setLists] = useState(Array)
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [total, setTotal] = useState()
    const [type, setType] = useState('0')
    const [init, setInit] = useState(true)
    const [detailVisible, setDetailVisible] = useState(false)
    const [detailVisible1, setDetailVisible1] = useState(false)
    const [merchantList, setMerchantList] = useState([])
    const [row, setRow] = useState(null)
    const [loading, setLoading] = useState(false)
    const superMerchantCode = localStorage.appid;
    const navigate = useNavigate()
    const forceUpdate = useReducer((bool) => !bool)[1]
    useEffect(() => {
        settleMerchantList()
    }, [])
    useEffect(() => {
        console.log(state)
        reconciliationList()

    }, [currentPage, pageNum])
    useEffect(() => {

        reconciliationList()

    }, [type])
    const columns = [
        {
            title: '日期',
            dataIndex: 'statisticsDate',
            key: 'statisticsDate',
            width: 200,
        },
        {
            title: '银行入账(元)',
            dataIndex: 'thirdBillTotalAmout',
            key: 'thirdBillTotalAmout',
            width: 200,
        },
        {
            title: '抖音导入(元)',
            dataIndex: 'douyinTotalAmouut',
            key: 'douyinTotalAmouut',
        },

        {
            title: '差额(元)',
            dataIndex: 'balanceAmount',
            key: 'balanceAmount',
            width: 250,
            render: (text, row) => {
                return (
                    <div>
                        {
                            row.balanceAmount == 0 ?
                                <span> {row.balanceAmount}</span>
                                :
                                <span style={{ color: 'red' }}> {row.balanceAmount}</span>
                        }
                    </div>
                )
            }
        },
        {
            title: '对账状态',
            dataIndex: 'status',
            key: 'status',
            width: 200,
            render: (text, row) => {
                return (
                    <div>
                        <span style={{ color: 'red' }}>{row.status == 0 ? '未对账' : ''}</span>
                        <span>{row.status == 1 ? '对账成功' : ''}</span>
                        <span style={{ color: 'red' }}>{row.status == 2 ? '对账中' : ''}</span>
                    </div>
                )
            }
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 200,
            align: 'right',
            render: (text, row) => (
                <>
                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }} onClick={() => {
                        row.dy = true
                        row.mt = false
                        row.type = type
                        setRow(row)
                        setDetailVisible1(true)
                        // navigate('/ws/Import/detail', { replace: false, state: { ...row } })
                    }} >详情</span>

                </>

            ),

        },
    ];
    const columns1 = [
        {
            title: '日期',
            dataIndex: 'statisticsDate',
            key: 'statisticsDate',
            width: 200,
        },
        {
            title: '银行入账(元)',
            dataIndex: 'thirdBillTotalAmout',
            key: 'thirdBillTotalAmout',
            width: 200,
        },
        {
            title: <span>{type == 1 ? '美团导入(元)' : '饿了么导入(元)'}</span>,
            dataIndex: 'douyinTotalAmouut',
            key: 'douyinTotalAmouut',
        },

        {
            title: '差额(元)',
            dataIndex: 'balanceAmount',
            key: 'balanceAmount',
            width: 250,
            render: (text, row) => {
                return (
                    <div>
                        {
                            row.balanceAmount == 0 ?
                                <span> {row.balanceAmount}</span>
                                :
                                <span style={{ color: 'red' }}> {row.balanceAmount}</span>
                        }
                    </div>
                )
            }
        },
        {
            title: '对账状态',
            dataIndex: 'status',
            key: 'status',
            width: 200,
            render: (text, row) => {
                return (
                    <div>
                        <span style={{ color: 'red' }}>{row.status == 0 ? '未对账' : ''}</span>
                        <span>{row.status == 1 ? '对账成功' : ''}</span>
                        <span style={{ color: 'red' }}>{row.status == 2 ? '对账中' : ''}</span>
                        <span style={{ color: 'red' }}>{row.status == 3 ? '对账失败' : ''}</span>
                    </div>
                )
            }
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 200,
            align: 'right',
            render: (text, row) => (
                <>
                    {
                        row.status == 0 || row.status == 3 ?
                            <Upload
                                name="file"
                                accept=".xls, .xlsx"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={`${host}/onlineOrder/excel`}
                                headers={{ 'token': localStorage.token, }}
                                data={file => ({ // data里存放的是接口的请求参数
                                    type,
                                    superMerchantCode: localStorage.appid,
                                    operatorName: localStorage.operatorname,
                                    operatorId: localStorage.operatorId,
                                    reconciliacionId: row.id,
                                })}
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                            >
                                <span style={{ color: '#165BD4', cursor: 'pointer' }}>导入</span>
                            </Upload> : null
                    }
                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }} onClick={() => {
                        row.mt = true;
                        row.dy = false
                        row.type = type
                        setRow(row)
                        setDetailVisible1(true)
                    }} >详情</span>

                </>

            ),

        },
    ];

    const settleMerchantList = () => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode: localStorage.merchantCode,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            if (res.code == 0) {
                setMerchantList(res.data)
            } else {
                // message.error(res.msg)
            }
        });
    }
    const reconciliationList = (data) => {
        let values = form.getFieldValue();
        const rangeValue = values['date'];
        values.startTime = rangeValue && rangeValue[0].format('YYYY-MM-DD');
        values.endTime = rangeValue && rangeValue[1].format('YYYY-MM-DD');
        (async _ => {
            return await reconciliationListRequest({
                ...values,
                superMerchantCode: localStorage.appid,
                pageSize: pageNum,
                type,
                currentPage: data && data.currentPage ? data.currentPage : currentPage
            });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setList(res.data && res.data.lists)
                setTotal(res.data && res.data.totalCount)
            } else {
                message.error(res.msg)
            }
        });
    }




    const meituanBuchang = (data) => {
        (async _ => {
            return await deleteMTExcelByIdRequest({
                superMerchantCode: localStorage.appid,
                uploadNo: data.uploadNo,
                id: data.id
            });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                message.success(res.msg)
            } else {
                message.error(res.msg)
            }
        });
    }
    const deleteMTExcelById = (data) => {
        (async _ => {
            return await deleteMTExcelByIdRequest({
                superMerchantCode: localStorage.appid,
                id: data.id
            });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                reconciliationList()
                message.success(res.msg)
            } else {
                message.error(res.msg)
            }
        });
    }

    const detailShowModal = (data) => {
        setRow(data)
        setDetailVisible1(true);
    };





    const onChangPage = (currentPage, pageNum) => {
        setCurrentPage(currentPage, () => {
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        setPageNum((currentPage, pageNum), () => {
        })
    }
    const onFinish = (values) => {

        values.currentPage = 1;
        reconciliationList(values)

    };

    const inSevenDays = () => {
        form.setFieldsValue({
            date: [moment(moment().subtract(7, "days").format("YYYY-MM-DD"), dateFormat), moment(moment().format('YYYY-MM-DD'), dateFormat)]
        })
    }
    const yesterday = () => {
        form.setFieldsValue({
            date: [moment(moment().subtract(1, "days").format("YYYY-MM-DD"), dateFormat), moment(moment().subtract(1, "days").format('YYYY-MM-DD'), dateFormat)]
        })
    }
    const today = () => {
        form.setFieldsValue({
            date: [moment(moment().format('YYYY-MM-DD'), dateFormat), moment(moment().format('YYYY-MM-DD'), dateFormat)]
        })


    }

    const beforeUpload = (file) => {
        const isXlsx = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        if (!isXlsx) {
            message.error('请上传excel表格!');
        }

        return isXlsx;
    }
    const handleChange = (info) => {
        console.log(info);
        setLoading(true)
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            console.log(info.file.response.data);
            if (info.file.response.code == 0) {
                message.success(info.file.response.msg)
                setLoading(false)
                reconciliationList()

            } else {
                setLoading(false)
                message.error(info.file.response.msg)
            }
        }
    };
    const detailHandleOk = () => {
        setDetailVisible(false)
    }
    const detailhandleCancel = () => {
        setDetailVisible(false)
    }
    const detailHandleOk1 = () => {
        setDetailVisible1(false)
    }
    const detailhandleCancel1 = () => {
        setDetailVisible1(false)
    }
    return (
        <div style={{ background: '#ffffff', paddingTop: 1, marginTop: 16 }}>
            {type == 0 ?

                <Row style={{ marginTop: 16 }}>
                    <Upload
                        name="file"
                        accept=".xls, .xlsx"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={`${host}/onlineOrder/excel`}
                        headers={{ 'token': localStorage.token, }}
                        data={file => ({ // data里存放的是接口的请求参数
                            type,
                            superMerchantCode: localStorage.appid,
                            operatorName: localStorage.operatorname,
                            operatorId: localStorage.operatorId,
                        })}
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                    >
                        <Button type="primary" style={{ marginLeft: 16 }} loading={loading} >导入Excel</Button>
                    </Upload>
                </Row> : null
            }

            <Tabs style={{ marginTop: 16, marginLeft: 16 }} activeKey={type} onChange={(e) => {
                console.log(e);
                setType(e)
            }} type="card">
                <TabPane tab="抖音" key='0'>
                </TabPane>
                <TabPane tab="美团" key='1'>
                </TabPane>
                <TabPane tab="饿了么" key='2'>
                </TabPane>

                {/* <TabPane tab="大众点评" key='6'>
                </TabPane> */}
            </Tabs>
            <Form
                form={form}
                {...layout}
                name="advanced_search"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                style={{ background: '#F7F8FA', margin: 16, padding: 16, paddingBottom: 0, marginTop: 0 }}
                initialValues={{
                    date: [moment(moment().subtract(30, "days").format("YYYY-MM-DD"), dateFormat), moment(moment().format('YYYY-MM-DD'), dateFormat)],
                    settleStatus: null,
                    merchantCode: null,
                }}
            >


                <Row style={{ marginBottom: '-1vh' }}>
                    <Col span={8}>
                        <Form.Item
                            name="date"
                            label="日期"
                        >
                            <div style={{ display: 'flex', alignItems: 'center', width: 400, }}>
                                <Form.Item
                                    name="date"
                                    noStyle
                                >
                                    <RangePicker style={{ width: 250 }} />
                                </Form.Item>
                                <Form.Item
                                    noStyle
                                >
                                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }}
                                        onClick={today} >今天</span>
                                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }}
                                        onClick={yesterday} >昨天</span>
                                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }}
                                        onClick={inSevenDays} >近7天</span>
                                </Form.Item>
                            </div>



                        </Form.Item>
                    </Col>
                </Row>
                {/* {
                    type == 1 ?
                        <Row>

                            <Col span={8}>
                                <Form.Item
                                    name="merchantCode"
                                    label="商户："
                                >
                                    <Select placeholder="请选择"
                                        style={{ width: 250 }}
                                        onChange={(code) => {
                                        }}
                                        allowClear
                                        optionFilterProp="children"
                                        showSearch
                                        filterOption={(input, option) => option.children.includes(input)}
                                    >
                                        <Option rowKey={null} key={null} value={null}>全部</Option>
                                        {
                                            merchantList && merchantList.map((v) => {
                                                return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                            })
                                        }

                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="settleStatus"
                                    label="结算状态"
                                >
                                    <Select placeholder="请选择"
                                        style={{ width: 250 }}
                                        onChange={(code) => {
                                        }}
                                        allowClear
                                        optionFilterProp="children"
                                        showSearch
                                        filterOption={(input, option) => option.children.includes(input)}
                                    >
                                        <Option key={null} value={null}>全部</Option>
                                        <Option key={'已结算'} value={'已结算'}>已结算</Option>
                                        <Option key={'待结算'} value={'待结算'}>待结算</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        : */}
                <Row>
                    <Col span={8}>
                        <Form.Item
                            name="reconciliationStatus"
                            label="对账状态"
                        >
                            <Select placeholder="请选择"
                                style={{ width: 250 }}
                                onChange={(code) => {
                                }}
                                allowClear
                                optionFilterProp="children"
                                showSearch
                                filterOption={(input, option) => option.children.includes(input)}
                            >
                                <Option key={0} value={0}>未对账</Option>
                                <Option key={1} value={1}>对账成功</Option>
                                <Option key={2} value={2}>对账中</Option>
                                <Option key={3} value={3}>对账失败</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {/* } */}



                <Row style={{ marginBottom: '-1vh' }}>
                    <Col span={12}>
                        <Form.Item
                            wrapperCol={{
                                offset: 4,
                                span: 20,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                筛选
                            </Button>
                            {/* <Button
                                style={{ marginLeft: 20 }}
                                onClick={() => {
                                    exportFunc()
                                }}>
                                导出
                            </Button>
                            <Button type="link"
                                style={{ marginLeft: 10 }}
                                onClick={() => {
                                    form.resetFields();
                                    getAccountLogList()
                                }} >
                                重置筛选条件
                            </Button>
                            <Button type="link" onClick={() => {
                            }} >
                                查看已导出列表
                            </Button> */}

                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {
                type == 0 ?
                    <Table
                        locale={{
                            emptyText: "没有更多数据了"
                        }}
                        rowKey='id'
                        style={{ marginTop: 16, margin: '0 16px' }}
                        columns={columns}
                        dataSource={list}
                        pagination={{
                            total,
                            showSizeChanger: true,
                            current: currentPage,
                            pageSize: pageNum,
                            showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                            onChange: (k, v) => onChangPage(k, v),
                            onShowSizeChange: (k, v) => onChangPageNum(k, v),
                        }}

                    /> : null
            }
            {
                type == 1 || type == 2 ?
                    <Table
                        loading={loading}
                        locale={{
                            emptyText: "没有更多数据了"
                        }}
                        rowKey='id'
                        style={{ marginTop: 16, margin: '0 16px' }}
                        columns={columns1}
                        dataSource={list}
                        pagination={{
                            total,
                            showSizeChanger: true,
                            current: currentPage,
                            pageSize: pageNum,
                            showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                            onChange: (k, v) => onChangPage(k, v),
                            onShowSizeChange: (k, v) => onChangPageNum(k, v),
                        }}

                    /> : null
            }
            <Modal
                title="未匹配数据"
                visible={detailVisible}
                onOk={detailHandleOk}
                onCancel={detailhandleCancel}
                width={1200}
                footer={null}
            >
                <Buchang data={row}
                    callback={(state) => {
                        if (state) {
                            reconciliationList()
                        }
                    }}
                    closeDialog={() => {
                        setDetailVisible(false)
                    }}
                />
            </Modal>
            <Modal
                title="查看报表"
                visible={detailVisible1}
                onOk={detailHandleOk1}
                onCancel={detailhandleCancel1}
                key={Math.random(10)}
                width={1200}
                footer={null}
            >
                <Detail data={row}
                    callback={(state) => {
                        if (state) {
                            reconciliationList()
                        }
                    }}
                    closeDialog={() => {
                        setDetailVisible1(false)
                    }}
                />
            </Modal>
        </div >
    )
}
export default StatementList;