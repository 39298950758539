import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import NP from 'number-precision'
import {
    Form,
    Input,
    Switch,
    Row,
    Col,
    Button,
    Tabs,
    Table,
    message,
    Modal,
    Radio,
    Popconfirm,
    Select,
    Space,
    Skeleton
} from 'antd';
import { dyBuchangListRequest, relationRecordRequest, dyBuchangRequest, dyDeleteOneRequest, onlineOrderListRequest, matchMerchantListRequest } from '../../../api/api'
import { useCallbackState } from '../../../util/useCallbackState'
const { Option } = Select;

const BillingDetails = (props) => {
    const [form] = Form.useForm();
    const [list, setList] = useState([])
    const [data, setData] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [merchantList, setMerchantList] = useState([])
    const [guanlianList, setGuanlianList] = useState([])
    const [total, setTotal] = useState()
    const [detailVisible, setDetailVisible] = useState(false);
    const [row, setRow] = useState(null)
    const [type, setType] = useState('3')
    const [loading, setLoading] = useState(false)
    let navigate = useNavigate();
    const { state } = useLocation();
    useEffect(() => {
        settleMerchantList()
    }, [])
    useEffect(() => {
        onlineOrderList()
    }, [pageNum, currentPage])
    useEffect(() => {
        onlineOrderList()
    }, [type])
    const onFinish = (values) => {
        console.log(values);
        values.currentPage = 1;
        onlineOrderList(values)
    };
    const onlineOrderList = (data) => {
        let values = form.getFieldValue();
        console.log(values);
        let params = {
            ...values,
            ...data,
            superMerchantCode: localStorage.appid,
            merchantCode: localStorage.merchantCode,
            type: 0,
            pageSize: pageNum,
            currentPage: currentPage,
            uploadNo: props && props.data && props.data.uploadNo,
            dyOrderType: type,
        };
        (async _ => {
            return await onlineOrderListRequest(params);
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setList(res.data.lists)
                setTotal(res.data.totalCount)
            } else {
                message.error(res.msg)
            }
        });
    }


    const dyBuchang = () => {
        setLoading(true);
        let params = {
            superMerchantCode: localStorage.appid,
            uploadNo: props && props.data && props.data.uploadNo,
        };
        (async _ => {
            return await dyBuchangRequest(params);
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setLoading(false)
                props.callback(true)
                props.closeDialog()
            } else {
                message.error(res.msg)
                setLoading(false)
            }
        });
    }

    const columns = [
        {
            title: '门店名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
            render: (text, row) => (
                <>
                    <div>{row.merchantName ? row.merchantName : '未匹配'}</div>
                </>
            ),
        },
        {
            title: '订单编号',
            dataIndex: 'orderNo',
            key: 'orderNo',
            render: (text, row) => (
                <div>{row.refundNo ? row.refundNo : row.orderNo}</div>
            ),
        },
        {
            title: '订单类型',
            dataIndex: 'orderType',
            key: 'orderType',
            render: (text, row) => {
                return (
                    <div>
                        {row.orderType == 0 ? '外卖' : ''}
                        {row.orderType == 1 ? '外卖退款' : ''}
                        {row.orderType == 2 ? '配送费补偿' : ''}
                        {row.orderType == 3 ? '团购' : ''}
                        {row.orderType == 4 ? '团购退款' : ''}
                    </div>
                )
            }
        },

        {
            title: <span>{type == 3 || type == 4 ? '核销门店' : '配送门店'}</span>,
            dataIndex: 'verificationstore',
            key: 'verificationstore',
            render: (text, row) => (
                <div >{type == 3 || type == 4 ? row.verificationStore : row.deliveryStore}</div>
            ),
        },
        {
            title: <span>{type == 3 || type == 4 ? '核销门店ID' : '配送门店ID'}</span>,
            dataIndex: 'deliverystoreId',
            key: 'deliverystoreId',
            render: (text, row) => {
                return (
                    <div >{type == 3 || type == 4 ? row.verificationStoreId : row.deliveryStoreId}</div>
                )
            }
        },
        {
            title: <span>{type == 3 || type == 4 ? '核销时间' : '支付时间'}</span>,
            dataIndex: 'orderPaymentTime',
            key: 'orderPaymentTime',
            render: (text, row) => {
                return (
                    <div >{type == 3 || type == 4 ? row.verificationTime : row.orderPaymentTime}</div>
                )
            }
        },
        {
            title: '退款时间',
            dataIndex: 'refundTime',
            key: 'refundTime',
        },
        {
            title: '提现金额',
            dataIndex: 'withdrawalAmount',
            key: 'withdrawalAmount',
        },
        {
            title: '提现状态',
            dataIndex: 'withdrawalStatus',
            key: 'withdrawalStatus',
        },
        {
            title: '提现完成时间',
            dataIndex: 'withdrawalCompletionTime',
            key: 'withdrawalCompletionTime',
        },
        {
            title: '收款账号',
            dataIndex: 'payeeAccount',
            key: 'payeeAccount',
        },
        {
            title: '失败原因',
            dataIndex: 'errorMessage',
            key: 'errorMessage',
            fixed: 'right',
        },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            fixed: 'right',
            render: (text, row) => {
                return (
                    <Popconfirm

                        title="是否确认删除?"
                        onConfirm={() => {
                            deleteExcel(row)
                        }}
                        onCancel={() => {

                        }}
                        okText="是"
                        cancelText="否"
                    >
                        {row.errorMessage ? <a href="#">删除</a> : null}
                    </Popconfirm>
                )
            }

        },
    ];


    const columns1 = [
        {
            title: '门店名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
            render: (text, row) => (
                <>
                    <div>{row.merchantName ? row.merchantName : '未匹配'} </div>
                </>
            ),
        },
        {
            title: '订单编号',
            dataIndex: 'orderNo',
            key: 'orderNo',
            render: (text, row) => (
                <div>{row.refundNo ? row.refundNo : row.orderNo}</div>
            ),
        },
        {
            title: '订单类型',
            dataIndex: 'orderType',
            key: 'orderType',
            render: (text, row) => {
                return (
                    <div>
                        {row.orderType == 0 ? '外卖' : ''}
                        {row.orderType == 1 ? '外卖退款' : ''}
                        {row.orderType == 2 ? '配送费补偿' : ''}
                        {row.orderType == 3 ? '团购' : ''}
                        {row.orderType == 4 ? '团购退款' : ''}
                    </div>
                )
            }
        },

        {
            title: <span>{type == 3 || type == 4 ? '核销门店' : '配送门店'}</span>,
            dataIndex: 'verificationstore',
            key: 'verificationstore',
            render: (text, row) => (
                <div >{type == 3 || type == 4 ? row.verificationStore : row.deliveryStore}</div>
            ),
        },
        {
            title: <span>{type == 3 || type == 4 ? '核销门店ID' : '配送门店ID'}</span>,
            dataIndex: 'deliverystoreId',
            key: 'deliverystoreId',
            render: (text, row) => {
                return (
                    <div >{type == 3 || type == 4 ? row.verificationStoreId : row.deliveryStoreId}</div>
                )
            }
        },
        {
            title: <span>{type == 3 || type == 4 ? '核销时间' : '支付时间'}</span>,
            dataIndex: 'orderPaymentTime',
            key: 'orderPaymentTime',
            render: (text, row) => {
                return (
                    <div >{type == 3 || type == 4 ? row.verificationTime : row.orderPaymentTime}</div>
                )
            }
        },
        {
            title: '退款时间',
            dataIndex: 'refundTime',
            key: 'refundTime',
        },
        {
            title: '提现金额',
            dataIndex: 'withdrawalAmount',
            key: 'withdrawalAmount',
        },
        {
            title: '提现状态',
            dataIndex: 'withdrawalStatus',
            key: 'withdrawalStatus',
        },
        {
            title: '提现完成时间',
            dataIndex: 'withdrawalCompletionTime',
            key: 'withdrawalCompletionTime',
        },
        {
            title: '收款账号',
            dataIndex: 'payeeAccount',
            key: 'payeeAccount',
        },
        {
            title: '失败原因',
            dataIndex: 'errorMessage',
            key: 'errorMessage',
            fixed: 'right',
        },
    ];
    const deleteExcel = (data) => {
        (async _ => {
            return await dyDeleteOneRequest({
                id: data.id,
                dyOrderType: type,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                // excelList()
                onlineOrderList()
                message.success(res.msg)
            } else {
                message.error(res.msg)
            }
        });
    }

    const relationRecord = (data) => {
        let params = {
            currentPage: currentPage,
            pageSize: pageNum,
            orderNo: data.orderNo
        };
        (async _ => {
            return await relationRecordRequest(params);
        })().then(res => {
            if (res.code == 0) {
                setGuanlianList(res.data.lists)
            } else {
                message.error(res.msg)
            }
        });
    }
    const showModal = (data) => {
        relationRecord(data)
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const onChangPage = (currentPage, pageNum) => {
        setCurrentPage(currentPage, () => {
            // relationRecord()
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        setPageNum((currentPage, pageNum), () => {
            // relationRecord()
        })
    }
    const settleMerchantList = () => {
        (async _ => {
            return await matchMerchantListRequest({
                superMerchantCode: localStorage.appid,
                uploadNo: props && props.data.uploadNo
            });
        })().then(res => {
            if (res.code == 0) {
                let arr = []
                if (res.data) {
                    for (let i in res.data) {
                        arr.push({
                            merchantCode: i,
                            merchantName: res.data[i]
                        })
                    };
                }
                setMerchantList(arr)
            } else {
                // message.error(res.msg)
            }
        });
    }
    return (
        <div style={{ background: '#ffffff', paddingBottom: 20 }}>

            <Radio.Group
                onChange={(e) => {
                    console.log(111)
                    setType(e.target.value)
                }}
                style={{
                    // marginBottom: 16,
                    marginTop: 16,
                    marginLeft: 16
                }}
                value={type}
            >
                <Radio.Button value="3">正向-团购</Radio.Button>
                <Radio.Button value="4">退款-团购</Radio.Button>
                <Radio.Button value="0">正向-外卖</Radio.Button>
                <Radio.Button value="1">退款-外卖</Radio.Button>
                <Radio.Button value="2">配送费补偿</Radio.Button>
            </Radio.Group>
            <Row style={{
                marginTop: 16,
                marginLeft: 16
            }}>

                <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}
                    initialValues={{ subMerchantCode: null, errorStatus: 2 }}
                >

                    <Form.Item
                        name="subMerchantCode"
                        label=""
                        colon={false}
                        rules={[{ required: false, message: 'Please input your username!' }]}
                    >
                        <Select
                            placeholder="请选择"
                            style={{ width: 250 }}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.includes(input)}
                        >
                            <Option key={null} value={null}>全部</Option>
                            {
                                merchantList && merchantList.map((v) => {
                                    return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                })
                            }

                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="errorStatus"
                        label=""
                        colon={false}
                        rules={[{ required: false, message: 'Please input your username!' }]}
                    >
                        <Select
                            placeholder="请选择"
                            style={{ width: 250 }}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.includes(input)}
                        >
                            <Option key={null} value={null}>全部</Option>
                            <Option key={1} value={1}>成功</Option>
                            <Option key={2} value={2}>失败</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            筛选
                        </Button>
                        <Button
                            style={{ marginLeft: 20 }}
                            type="link"
                            onClick={() => {
                                form.setFieldsValue({
                                    subMerchantCode: null
                                })

                            }}
                        >
                            重置筛选条件
                        </Button>
                    </Form.Item>

                </Form>
            </Row>
            <Row style={{ padding: "0px 10px" }}>
                <Col span={24}>
                    {
                        props.data.view ?
                            <Table
                                locale={{
                                    emptyText: "没有更多数据了"
                                }}
                                columns={columns1}
                                rowKey='id'
                                dataSource={list}
                                scroll={{ x: 2000 }}
                                pagination={{
                                    total,
                                    showSizeChanger: true,
                                    current: currentPage,
                                    pageSize: pageNum,
                                    showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                                    onChange: (k, v) => onChangPage(k, v),
                                    onShowSizeChange: (k, v) => onChangPageNum(k, v),
                                }}
                            /> :
                            <Table
                                locale={{
                                    emptyText: "没有更多数据了"
                                }}
                                columns={columns}
                                rowKey='id'
                                dataSource={list}
                                scroll={{ x: 2000 }}
                                pagination={{
                                    total,
                                    showSizeChanger: true,
                                    current: currentPage,
                                    pageSize: pageNum,
                                    showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                                    onChange: (k, v) => onChangPage(k, v),
                                    onShowSizeChange: (k, v) => onChangPageNum(k, v),
                                }}
                                footer={() => {
                                    return <div><Button type='primary' loading={loading} onClick={() => {
                                        dyBuchang()
                                    }}>补偿全部数据</Button> <span style={{ color: '#999999', fontSize: 14, marginLeft: 16 }}>配置抖音门店ID后，再补偿数据</span></div>
                                }}
                            />
                    }

                </Col>

            </Row>

        </div>

    )
}
export default BillingDetails;
