import React from 'react'
import $ from 'jquery';
/**
 * 按关键字分组(将一维数组分成二维数组)
 *
 * @param {Array<String>} array
 * @param {String} key
 * @returns { false | Array<Map<String,String>>}
 */
export function splitGroup(array, key) {
  if (!array || array === undefined || array === '' || !array.length) {
    return false;
  }
  const arr = array.concat();
  const ret = [];
  loop(arr[0][key]);

  function loop(scaleplate) {
    const temp = [];
    for (let index = 0; index < arr.length; index++) {
      const element = arr[index];
      if (scaleplate === element[key]) {
        temp.push(element);
        arr.splice(index--, 1);
      }
    }
    ret.push(temp);
    if (arr.length) {
      loop(arr[0][key]);
    }
  }
  return ret;
}
export function buyCard(value) {
  //格式化银行卡号
  if (value == undefined) {
    return value;
  } else {
    return (value = value
      .replace(/\s/g, "")
      .replace(/(\d{4})(?=\d)/g, "$1 "));
  }
}
/**
 * 深度遍历定位元素
 *
 * @param {{Array<String>}} array
 * @param {String} key
 * @param {Stirng} b
 * @param {Function} func
 * @returns {any}
 */
export function findPath(array, key, b, func, path = []) {
  for (let i = 0; i < array.length; i++) {
    const element = array[i];
    if (element.children && element.children.length) {
      path.push(element[key]);
      if (element[key].toString() === b.toString()) {
        return func(path.concat());
      }
      findPath(element.children, b, func, path);
    } else if (element[key].toString() === b.toString()) {
      path.push(element[key]);
      return func(path.concat());
    }
    if (i === array.length - 1) {
      path.pop();
    }
  }
}

/**
 * 获取组件展示名称即函数字面量
 *
 * @param {*Class} WrappedComponent
 * @returns {String}
 */
export function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
/**
 * URL拼接字符串
 *
 * @param {any} url
 * @param {any} param
 * @returns {String} url
 */
export function urlAppendQuery(url, param) {
  if (!param) {
    return url;
  }
  let queryString = '';
  for (const key in param) {
    // if (param.hasOwnProperty(key)) {
    if (param[key] === false || param[key] === 0 || param[key]) {
      queryString += `&${key}=${param[key]}`;
    }
    // }
  }
  if (queryString) {
    return `${url}?${queryString.substring(1)}`;
  }
  return url;
}
/**
 * 数组转树
 * @param {*} arr
 * @param {*} opt  { key: 'id', parent: 'pid', children: 'children',});
 */
export function listToTree(arr, opt) {
  const arrNodes = JSON.parse(JSON.stringify(arr));
  if (!opt) {
    opt = {};
    opt.key = 'id';
    opt.parent = 'pId';
    opt.children = 'children';
  }
  function exists(rows, parentId) {
    for (let i = 0; i < rows.length; i++) {
      if (rows[i][opt.key] === parentId) return true;
    }
    return false;
  }

  const nodes = [];
  // get the top level nodes
  for (let i = 0; i < arrNodes.length; i++) {
    const row = arrNodes[i];
    if (!exists(arrNodes, row[opt.parent])) {
      nodes.push(row);
    }
  }

  const toDo = [];
  for (let i = 0; i < nodes.length; i++) {
    toDo.push(nodes[i]);
  }
  while (toDo.length) {
    const node = toDo.shift(); // the parent node
    // get the children nodes
    for (let i = 0; i < arrNodes.length; i++) {
      const row = arrNodes[i];
      if (row[opt.parent] === node[opt.key]) {
        if (node.children) {
          node.children.push(row);
        } else {
          node.children = [row];
        }
        toDo.push(row);
      }
    }
  }
  return nodes;
}
/**
 * 树转数组
 * @param {*} treeNodes
 * @param {*} opt  { key: 'id', parent: 'pid', children: 'children',});
 */
export function treeTolist(treeNodes, opt) {
  if (!opt) {
    opt = {};
    opt.key = 'id';
    opt.parent = 'pid';
    opt.children = 'children';
  }

  const NodeList = [];

  function appenChildren(nodes) {
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];

      // 如果没有上层节点那么就是根节点
      if (node.parentNode == null) {
        node.Level = 0;
        node[opt.parent] = 0;
      }
      // 判断是否有子节点
      if (node[opt.children] && node[opt.children].length > 0) {
        // 所有子节点
        for (let k = 0; k < node.children.length; k++) {
          node[opt.children][k][opt.parent] = node[opt.parent];
          node[opt.children][k].Level = node.Level + 1;
          node[opt.children][k].parentNode = node;
        }

        appenChildren(node.children);
      }
      if (node.hasOwnProperty('parentNode')) {
        delete node.parentNode;
      }
      if (node.hasOwnProperty(opt.children)) {
        delete node[opt.children];
      }
      NodeList.push(node);
    }
  }

  appenChildren(treeNodes);

  return NodeList;
}
export const changeDatas = (arr, key, title) => {
  const treeArr = arr && arr.map((x) => {
    const level = {};
    level.key = x[key];
    level.value = x[key];
    level.title = x[title];
    if (x.childrens && x.childrens.length > 0) {
      level.childrens = changeDatas(x.childrens, key, title);
    }
    return level;
  });
  return treeArr;
};
/**
 * 转换成标准的Tree组件数据格式
 * @param {*} arr
 * @param {*} key
 * @param {*} title
 */
export const changeData = (arr, key, title) => {
  const treeArr = arr && arr.map((x) => {
    const level = {};
    level.key = x[key];
    level.value = x[key];
    level.title = x[title];
    if (x.children && x.children.length > 0) {
      level.children = changeData(x.children, key, title);
    }
    return level;
  });
  return treeArr;
};

/**
 * 两个数相成 精确计算
 *
 * @param {any} f1
 * @param {any} f2
 * @returns
 */
export function fxf(f1, f2) {
  f1 += '';
  f2 += '';
  const f1Len = f1.split('.')[1] ? f1.split('.')[1].length : 0;
  const f2Len = f2.split('.')[1] ? f2.split('.')[1].length : 0;
  if (f1Len) {
    f1 = f1.replace('.', '');
  }
  if (f2Len) {
    f2 = f2.replace('.', '');
  }
  return (f1 * f2) / 10 ** (f1Len + f2Len);
  // return f1 * f2 / Math.pow(10, f1Len + f2Len);
}
/**
 * 对象深拷贝
 * @param {object} p
 * @returns {object}
 */
export function objDeepCopy(p) {
  const c = {};
  for (const k in p) {
    if (p.hasOwnProperty(k)) {
      c[k] = p[k];
    }
  }
  return c;
}
/**
 * 保留两位小数,可以负数
 * @param {*object} obj
 */
export function limitTowDecimalsWithMinus(value) {
  value = String(value).replace(/[^\d.-]/g, ''); // 清除"数字"和"."以外的字符
  value = value.replace(/^\./g, ''); // 验证第一个字符是数字
  value = value.replace(/\.{2,}/g, '.'); // 只保留第一个, 清除多余的
  value = value.replace(/-{2,}/g, '-'); // 只保留第一个, 清除多余的
  value = value.replace(/([.\d])-/g, '$1'); // 清除不是第一个的-号
  value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
  value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入两个小数
  return value;
}

/**
 * 保留两位小数（非负）
 * @param {*object} obj
 */
export function limitTowDecimals(value) {
  value = String(value).replace(/[^\d.]/g, ''); // 清除"数字"和"."以外的字符
  value = value.replace(/^\./g, ''); // 验证第一个字符是数字
  value = value.replace(/\.{2,}/g, '.'); // 只保留第一个, 清除多余的
  value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
  value = value.replace(/^()*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入两个小数
  return value;
}
 //四舍五入，保留位数为roundDigit
export function roundFun(numberRound,roundDigit) {
  if (numberRound>=0){
  let tempNumber = parseInt((numberRound * Math.pow(10,roundDigit)+0.5))/Math.pow(10,roundDigit);
    return tempNumber;
  } else{
    let numberRound1=-numberRound;
    let tempNumber = parseInt((numberRound1 * Math.pow(10,roundDigit)+0.5))/Math.pow(10,roundDigit);
    return -tempNumber;
  }
}
/**
 * 保留四位小数（非负）
 * @param {*object} obj
 */
export function limitTowDecimals4(value) {
  value = String(value).replace(/[^\d.]/g, ''); // 清除"数字"和"."以外的字符
  value = value.replace(/^\./g, ''); // 验证第一个字符是数字
  value = value.replace(/\.{2,}/g, '.'); // 只保留第一个, 清除多余的
  value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
  value = value.replace(/^()*(\d+)\.(\d\d\d\d).*$/, '$1$2.$3'); // 只能输入两个小数
  return value;
}
// 多位保留小数
export function limitTowDecimalseight(value, precision = 8) {
  if (value) {
    precision = Number.isNaN(+precision) ? 2 : +precision;
    value = value.toString();
    value = value.replace(/[^\d.]/g, ''); // 清除"数字"和"."以外的字符
    value = value.replace(/^\./g, ''); // 验证第一个字符是数字
    value = value.replace(/\.{2,}/g, '.'); // 只保留第一个, 清除多余的
    value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
    const reg = new RegExp(`^()*(\\d+)\\.(\\d{${precision}}).*$`);
    value = value.replace(reg, '$1$2.$3'); // 只能输入八个小数
    if (precision === 0) {
      value.replace(/\.+$/, '');
    }
  }
  return value;
}

// 保留4位小数
export function limitDecimals(value) {
  const reg = /^(\-)*(\d+)\.(\d{4}).*$/;
  if (typeof value === 'string') {
    return !isNaN(Number(value)) ? value.replace(reg, '$1$2.$3') : '';
  } else if (typeof value === 'number') {
    return !isNaN(value) ? String(value).replace(reg, '$1$2.$3') : '';
  } else {
    return '';
  }
}

/**
 * 对象、数组判空
 */
export function isEmpty(obj) {
  if (!obj || !obj.length) return true;
  return false;
}

/**
 * 拆分
 */
export function limitMax(value, mx, record, i) {
  let sum = 0;
  record.depts.forEach((dept, index) => {
    if (i !== index) {
      sum += record.depts[index].qty;
    }
  });
  let ret = '';
  const max = mx - sum;
  if (value > max) {
    ret = max;
  } else {
    ret = value;
  }
  return ret;
}

/* --------------------post下载文件
 * options:{
 * url:'',  //下载地址
 * data:{name:value}, //要发送的数据
 * method:'post'
 * }
 */
export function DownLoadFile(options) {
  // myInterceptor();

  const config = $.extend(true, { method: 'post' }, options);
  const $iframe = $('<iframe id="down-file-iframe" target="_self"/>');
  const $form = $(
    `<form target="_self" method="${config.method}" />`
  );
  $form.attr('action', config.url);
  for (const key in config.data) {
    if (config.data.hasOwnProperty(key)) {
      $form.append(
        `<input type="hidden" name="${key}" value="${config.data[key]}" />`
      );
    }
  }

  $iframe.append($form);
  $(document.body).append($iframe);
  $form[0].submit();
  // $iframe.remove();
}

/* --------------------post下载文件 主数据专用
 * options:{
 * url:'',  //下载地址
 * data:{name:value}, //要发送的数据
 * method:'post'
 * }
 */
export function DownLoadFileForCoreData(options) {
  // myInterceptor();

  const config = $.extend(true, { method: 'post' }, options);
  const $iframe = $('<iframe id="down-file-iframe" />');
  const $form = $(
    `<form target="down-file-iframe" method="${config.method}" />`
  );
  $form.attr('action', config.url);
  for (const key in config.data) {
    if (config.data.hasOwnProperty(key)) {
      if (isArray(config.data[key])) {
        for (let i = 0; i < config.data[key].length; i++) {
          if (typeof config.data[key][i] === 'object') {
            for (const key2 in config.data[key][i]) {
              if (config.data[key][i].hasOwnProperty(key2)) {
                $form.append(
                  `<input type="hidden" name="${key}[${i}].${key2}" value="${config.data[key][i][key2]}" />`
                );
              } else {
              }
            }
          } else {
            $form.append(
              `<input type="hidden" name="${key}[${i}]" value="${config.data[key][i]}" />`
            );
          }
        }
        //$form.serialize();
      } else {
        $form.append(
          `<input type="hidden" name="${key}" value="${config.data[key]}" />`
        );
      }
    }
  }

  $iframe.append($form);
  $(document.body).append($iframe);
  $form[0].submit();
  $iframe.remove();
}

// 判断对象是否是数组
export function isArray(obj) {
  return Object.prototype.toString.call(obj) === '[object Array]';
}

/**
 * 下载excel文件
 *
 */
export function DownLoadExcelFile(data) {
  // myInterceptor();
  $.post(
    data.url,
    { parameter: data.data },
    { responseparameterType: 'arraybuffer' }
  ).then((res) => {
    let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' }),
      fileName = '文件名称';

    downExcelFile(blob, fileName);

    function downExcelFile(blob, fileName) {
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(link.href);
      }
    }
  });
}

//  二进制流下载
export function downFileWithBlob(blob, fileName) {
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(link.href);
  }
}

export function getStrByParams(params) {
  let str = '';
  for (const key in params) {
    if (params[key]) {
      str += `&${key}=${params[key]}`;
    }
  }
  return str;
}

// 过滤为null值得属性 转化为undefined
export function dealNull(obj) {
  for (const i in obj) {
    if (obj[i] == null) {
      obj[i] = undefined;
      // delete obj[i];
    } else if (typeof obj[i] === 'object') {
      this.dealNull(obj[i]);
    }
  }
}

/**
 * json串 生成excel文件
 *
 */
export function jsonToExcelFile(jsonData, fileName, showLabel) {
  // 先转化json
  const arrData = jsonData;

  let excel = '<table>';

  // 设置表头
  var row = '<tr>';
  for (var i = 0, l = showLabel.length; i < l; i++) {
    row += `<td>${showLabel[i].value}</td>`;
  }

  // 换行
  excel += `${row}</tr>`;

  // 设置数据
  for (var i = 0; i < arrData.length; i++) {
    var row = '<tr>';

    for (let j = 0; j < arrData[i].length; j++) {
      const value = arrData[i][j].value === '.' ? '' : arrData[i][j].value;
      row += `<td>${value}</td>`;
    }

    excel += `${row}</tr>`;
  }

  excel += '</table>';

  let excelFile =
    "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:x='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'>";
  excelFile +=
    '<meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">';
  excelFile +=
    '<meta http-equiv="content-type" content="application/vnd.ms-excel';
  excelFile += '; charset=UTF-8">';
  excelFile += '<head>';
  excelFile += '<!--[if gte mso 9]>';
  excelFile += '<xml>';
  excelFile += '<x:ExcelWorkbook>';
  excelFile += '<x:ExcelWorksheets>';
  excelFile += '<x:ExcelWorksheet>';
  excelFile += '<x:Name>';
  excelFile += '{worksheet}';
  excelFile += '</x:Name>';
  excelFile += '<x:WorksheetOptions>';
  excelFile += '<x:DisplayGridlines/>';
  excelFile += '</x:WorksheetOptions>';
  excelFile += '</x:ExcelWorksheet>';
  excelFile += '</x:ExcelWorksheets>';
  excelFile += '</x:ExcelWorkbook>';
  excelFile += '</xml>';
  excelFile += '<![endif]-->';
  excelFile += '</head>';
  excelFile += '<body>';
  excelFile += excel;
  excelFile += '</body>';
  excelFile += '</html>';

  const uri = `data:application/vnd.ms-excel;charset=utf-8,${encodeURIComponent(
    excelFile
  )}`;

  const link = document.createElement('a');
  link.href = uri;

  link.style = 'visibility:hidden';
  link.download = `${fileName}.xls`;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 *
 * 处理缓存问题
 * 加入缓存数组中
 */
const cacheObj = {};

export function insertCache(pathName, stateData, formData = null) {
  cacheObj[pathName] = { stateData, formData };
}

// 获取缓存中的数据
export function getCacheData(pathName, that) {
  if (cacheObj.hasOwnProperty(pathName) && !!cacheObj[pathName]) {
    Object.assign(that.state, cacheObj[pathName].stateData);
    that.props.form.setFieldsValue(cacheObj[pathName].formData);
  }
}

// 主项目用
export function getCacheDataForMainProject(pathName, that) {
  if (cacheObj.hasOwnProperty(pathName) && !!cacheObj[pathName]) {
    Object.assign(that.state, cacheObj[pathName].stateData || cacheObj[pathName].formData);
    that.props.form.setFieldsValue(cacheObj[pathName].formData);
  }
}

// 获取id
export function getCacheId(pathName, that) {
  if (cacheObj.hasOwnProperty(pathName) && !!cacheObj[pathName]) {
    return cacheObj[pathName].stateData.id;
  }
}


// 删除缓存中的数据
export function delCacheData(pathName) {
  if (cacheObj.hasOwnProperty(pathName) && !!cacheObj[pathName]) {
    cacheObj[pathName] = null;
    delete cacheObj[pathName];
  }
}

export function getCacheBaseData(pathName, that) {
  if (cacheObj.hasOwnProperty(pathName) && !!cacheObj[pathName]) {
    that.props.form.setFieldsValue(cacheObj[pathName].formData);
  }
}

export function insertBaseCache(pathName, formData) {
  cacheObj[pathName] = { formData };
}



/**
 * @param func {Function} 实际要执行的函数
 * @param wait {Number} 延迟时间，也就是阀值，单位：毫秒（ms）
 * @return {Function}
 * 防抖
 */
// export function debounce(func, delay) {
//   let timer;
//   // 返回一个函数，这个函数会在一个时间区间结束后的delay毫秒时执行 func函数
//   return function() {
//     let context = this;
//     let args = arguments;
//     clearTimeout(timer);
//     timer = setTimeout(function(){
//       func.apply(context, args);
//     }, delay);
//   }
// }

export function debounce(func, wait, options = {}) {
  //声明参数
  let lastArgs, //上次调用参数
    lastThis, //上次调用this
    maxWait, //最大等待时间
    result, //返回结果
    timerId,
    lastCallTime; //上次调用debounced时间，即出发时间，不一定会调用func

  //参数初始化let
  let lastInvokeTime = 0; //上次调用func时间，即成功执行时间
  let leading = false; //是否在超时之前调用
  let maxing = false; //是否传入最大超时时间
  let trailing = true; //是否超时之后调用

  if (typeof func !== 'function') {
    throw new TypeError('debounce的第一个参数类型为funtion');
  }

  function isObject(obj) {
    //判断是否为对象
    let type = Object.prototype.toString.call(obj);
    if (type === '[object Object]') {
      return true;
    }
  }

  wait = +wait || 0;
  //+wait 作用就是转变wait的数据类型，变成Number类型 传入的执行时间间隔
  if (isObject(options)) {
    leading = !!options.leading;
    maxing = options.maxWait;
    maxWait = options.maxWait
      ? Math.max(Number(options.maxWait) || 0, wait)
      : maxWait;
    trailing = !!options.trailing || trailing;
  }

  function invokeFunc(time) {
    //调用function 参数为当前时间
    let args = lastArgs;
    let thisArg = lastThis;
    lastArgs = lastThis = undefined;
    lastInvokeTime = time; //将上次调用时间重置为当前时间
    result = func.apply(thisArg, args); //执行传入
    return result;
  }

  function leadingEdge(time) {
    //超时之前调用
    lastInvokeTime = time;
    timerId = setTimeout(timerExpired, wait); //开始timer 为trailing Edge触发函数调用设定定时器 如果leading为 true 后续执行条件不满足就不会执行func
    return leading ? invokeFunc(time) : result;
  }

  function remainingWait(time) {
    //设置还需要等待的时间
    const timeSinceLastCall = time - lastCallTime, //距离上次debounced函数被调用的时间
      timeSinceLastInvoke = time - lastInvokeTime, //距离上次func函数被执行的时间
      result = wait - timeSinceLastCall; //还需要等待的时间 计算出下一次trailing的位置
    //maxing就是options.maxWait  两种情况
    // 设置了maxing 比较下一次maxing和下一次trailing的最小值，作为下一次函数要执行的时间
    //无max 在下一次trailing时执行 timerExpired
    return maxing ? Math.min(result, maxWait - timeSinceLastInvoke) : result;
  }

  //根据时间判断func能否被执行
  function shouldInvoke(time) {
    //是否应该被调用
    const timeSinceLastCall = time - lastCallTime, //距离上次触发时间的时间
      timeSinceLastInvoke = time - lastInvokeTime; //距离上次调用func的时间
    return (
      lastCallTime === undefined || //从未执行过也就是首次调用
      timeSinceLastCall >= wait || //距离上次被调用的时间超过了wait的时间
      timeSinceLastCall < 0 || //系统时间倒退？？
      (maxing && timeSinceLastInvoke >= maxWait)
    ); //超过最大等待时间
  }

  function timerExpired() {
    //定时器的回调函数 用来判断是否执行func
    const time = Date.now();
    //在 options.trailing  edge且时间符合条件时候 调用trailingEdge函数，否则重启定时器
    if (shouldInvoke(time)) {
      return trailingEdge(time);
    }
    //重启定时器 保证下一次时延的末尾触发
    timerId = setTimeout(timerExpired, remainingWait(time));
  }

  function trailingEdge(time) {
    //超时之后被调用 trailing = true;options里声明的变量默认为 true
    timerId = undefined;
    //有lastArgs才执行，意味着只有func已经被debounced过一次以后才会在trailing edge执行
    if (trailing && lastArgs) {
      return invokeFunc(time);
    }
    lastArgs = lastThis = undefined;
    return result;
  }

  function cancle() {
    if (timerId !== undefined) {
      clearTimeout(timerId);
    }
    lastInvokeTime = 0;
    lastArgs = lastCallTime = lastThis = timerId = undefined;
  }
  function flush() {
    return timerId === undefined ? result : trailingEdge(Date.now());
  }
  function debounced() {
    const time = Date.now(), //获取到当前的时间
      isInvoking = shouldInvoke(time); //判断是否可以调用
    lastArgs = arguments;
    lastThis = this;
    lastCallTime = time; //Date.now()
    if (isInvoking) {
      if (timerId === undefined) {
        return leadingEdge(lastCallTime);
      }
      if (maxing) {
        timerId = setTimeout(timerExpired, wait);
        return invokeFunc(lastCallTime);
      }
    }
    if (timerId === undefined) {
      timerId = setTimeout(timerExpired, wait);
    }
    return result;
  }
  debounced.cancel = cancle;
  debounced.flush = flush;
  return debounced;
}

/**
 * @param func {Function} 实际要执行的函数
 * @param delay {Number} 延迟时间，也就是阀值，单位：毫秒（ms）
 * @return {Function}
 * 节流
 */
export function throttle(func, delay) {
  let last, timer; // 记录上次执行的时间

  delay || (delay = 250); // 默认间隔为250
  // 返回一个函数
  return function () {
    let context = this;
    let args = arguments;
    let now = +new Date();
    if (last && now < last + delay) {
      clearTimeout(timer);
      timer = setTimeout(function () {
        last = now;
        func.apply(context, args);
      }, delay);
    } else {
      last = now;
      func.apply(context, args);
    }
  };
}


/***
 *
 * @param accept [] :全部格式 / ['.doc','.docx'] 限定 .doc .docx
 * @returns {string}
 * @description input accept 快速格式化
 */
export function fileTypeMapping(accept) {
  const data = {
    '.xlsx':
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    '.pptx':
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    '.docx':
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    '.doc': 'application/msword',
    '.dwg': 'image/vnd.dwg',
    '.csv': 'text/csv',
    '.gif': 'image/gif',
    '.jpe': 'image/jpeg',
    '.jpeg': 'image/jpeg',
    '.jpg': 'image/jpeg',
    '.png': 'image/png',
    '.ppt': 'application/vnd.ms-powerpoint',
    '.txt': 'text/plain',
    '.xls': 'application/vnd.ms-excel',
    '.zip': 'aplication/zip',
    '.pdf': 'application/pdf',
  };
  if (!accept || accept.length === 0) {
    return '*';
  } else {
    const result = [];
    for (const prop in data) {
      if (accept.includes(prop)) {
        result.push(data[prop]);
      }
    }
    const r = [...new Set(result)];
    return r.join(',');
  }
}
/**
 * 拆分数据 多行多列
 * 针对控件Input.TextArea
 * @params data 拆分的字符串
 * @params columns  列表字段名称数组
 * @return obj
 *         {
 *             flag: true - 数组 false - 正常的字符窜
 *             arr： 数据
 *             data： 字符串
 *         }
 */
export function splitDataFromExcel(data, columns) {
  if (!!data && data.indexOf('\n') > 0) {
    // 处理批量物资名称
    let arr = [];
    const len = columns.length;
    data.split('\n').filter((v) => {
      if (!!v && v.indexOf('\t') > 0) {
        let subarr = v.split('\t'); // 制表符\t可以拆分多列数据 后续可扩展成多列组件
        if (subarr.length === len) {
          const obj = {};
          for (let i = 0; i < len; i++) {
            obj[columns[i]] = subarr[i];
          }
          arr.push(obj);
        }
      } else {
        if (len === 1) {
          arr.push(v);
        }
      }
    });
    return { flag: true, arr };
  } else {
    return { flag: false, data };
  }
}



/**
 * 全局loading，控制遮罩
 * @param flag true 显示遮罩 false 隐藏遮罩
 * @params msg 提示信息 默认‘正在执行,请稍后...’
 *
 */
export function controlLoading(flag, msg) {
  if (flag) {
    let mask_bg = document.createElement('div');
    mask_bg.id = 'mask_bg';
    mask_bg.style.position = 'absolute';
    mask_bg.style.top = '0px';
    mask_bg.style.left = '0px';
    mask_bg.style.width = '100%';
    mask_bg.style.height = '100%';
    mask_bg.style.backgroundColor = '#777';
    mask_bg.style.opacity = 0.6;
    mask_bg.style.zIndex = 10001;
    document.body.appendChild(mask_bg);

    let mask_msg = document.createElement('div');
    mask_msg.style.position = 'absolute';
    mask_msg.style.top = '35%';
    mask_msg.style.left = '42%';
    mask_msg.style.backgroundColor = 'white';
    mask_msg.style.border = '#336699 1px solid';
    mask_msg.style.textAlign = 'center';
    mask_msg.style.fontSize = '1.1em';
    mask_msg.style.fontWeight = 'bold';
    mask_msg.style.padding = '0.5em 3em 0.5em 3em';
    mask_msg.style.zIndex = 10002;
    mask_msg.innerText = msg || '正在执行,请稍后...';
    mask_bg.appendChild(mask_msg);
  } else {
    let mask_bg = document.getElementById('mask_bg');
    if (mask_bg != null) mask_bg.parentNode.removeChild(mask_bg);
  }
}

// 处理Number数字类型
/**
 * @param num 待处理的数值
 * @param count 保留的小数位 个数
 *
 **/
export function dealWithNumber(num, count = 4) {
  const num1 = parseFloat(num);
  let newNum = Math.pow(10, count);
  return !isNaN(num1) ? Math.round(num1.toFixed(count) * newNum) / newNum : 0;
}

// 处理Number数字类型
/**
 * @param num 待处理的数值
 * @param count 保留的小数位 个数
 *
 **/
export function dealWithNumberWithoutDefault(num, count = 4) {
  const num1 = parseFloat(num);
  let newNum = Math.pow(10, count);
  return !isNaN(num1)
    ? Math.round(num1.toFixed(count) * newNum) / newNum
    : undefined;
}

/**
 * 生成随机数
 * */
export function getID(length) {
  return Number(
    Math.random().toString().substr(3, length) + Date.now()
  ).toString(36);
}

/**
 * 根据数字生成大写金额
 */
export function convertCurrency(money) {
  //汉字的数字
  var cnNums = new Array(
    '零',
    '壹',
    '贰',
    '叁',
    '肆',
    '伍',
    '陆',
    '柒',
    '捌',
    '玖'
  ); //基本单位
  var cnIntRadice = new Array('', '拾', '佰', '仟'); //对应整数部分扩展单位
  var cnIntUnits = new Array('', '万', '亿', '兆'); //对应小数部分单位
  var cnDecUnits = new Array('角', '分', '毫', '厘'); //整数金额时后面跟的字符
  var cnInteger = '整'; //整型完以后的单位
  var cnIntLast = '元'; //最大处理的数字
  var maxNum = 999999999999999.9999; //金额整数部分
  var integerNum; //金额小数部分
  var decimalNum; //输出的中文金额字符串
  var chineseStr = ''; //分离金额后用的数组，预定义
  var parts;
  if (money === '') {
    return '';
  }
  money = parseFloat(money);
  if (money >= maxNum) {
    // 超出最大处理数字
    return '';
  }
  if (money === 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger;
    return chineseStr;
  }
  // 转换为字符串
  money = money.toString();
  if (money.indexOf('.') == -1) {
    integerNum = money;
    decimalNum = '';
  } else {
    parts = money.split('.');
    integerNum = parts[0];
    decimalNum = parts[1].substr(0, 4);
  } //获取整型部分转换
  if (parseInt(integerNum, 10) > 0) {
    var zeroCount = 0;
    var IntLen = integerNum.length;
    for (var i = 0; i < IntLen; i++) {
      var n = integerNum.substr(i, 1);
      var p = IntLen - i - 1;
      var q = p / 4;
      var m = p % 4;
      if (n == '0') {
        zeroCount++;
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0];
        } //归零
        zeroCount = 0;
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
      }
      if (m == 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q];
      }
    }
    chineseStr += cnIntLast;
  } //小数部分
  if (decimalNum != '') {
    var decLen = decimalNum.length;
    for (var i = 0; i < decLen; i++) {
      var n = decimalNum.substr(i, 1);
      if (n != '0') {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i];
      }
    }
  }
  if (chineseStr == '') {
    chineseStr += cnNums[0] + cnIntLast + cnInteger;
  } else if (decimalNum == '') {
    chineseStr += cnInteger;
  }
  return chineseStr;
}


export function AddThousandMark(num){
  let tag = (num || 0).toString().split(".");
  tag[0] = tag[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return tag.join('.')
}

export function DeleteThousandMark(num){
  let tag = (num||0).toString().split(',')
  return tag.join('')
}

export function cutWordsByKey(words,key){
  const arr = words.split(key||undefined)
  return <div>
    {arr.map((e,i)=>{
      return <span key={i}>
        <span>{e}</span>
        {(i+1) === arr.length ?null: <span style={{color:'red'}}>{key}</span>}
      </span>
    })}
  </div>
}

/**
 *
 * 2个数组比较
 *
 */
export function arrEquals(srcArr = [], desArr = []) {
	if (!srcArr || !desArr) {
		return false;
	}
	if(srcArr.length !== desArr.length) {
		return false;
	}
	for (let i = 0, l = srcArr.length; i < l; i++) {
		if (srcArr[i] instanceof Array && desArr[i] instanceof Array) {
			if (!srcArr[i].equals(desArr[i]))
				return false;
		} else if (srcArr[i] !== desArr[i]) {
			return false;
		}
	}
	return true;
}

export function getGroupNameByCode(code, list){
	const arr = list.filter(v=>{
		return v.value === code;
	});
	if(arr && arr.length){
		return arr[0].label;
	}else if(list.children){
		getGroupNameByCode(code, list.children);
	}
	return '';
}

export function getUrlFile(url, fileName) {
	let xhh = new XMLHttpRequest();
	xhh.open("get", url);
	xhh.setRequestHeader('X-Auth-Token', localStorage.token);
	xhh.setRequestHeader("Content-Type","application/json");
	xhh.responseType = 'blob';

	xhh.onload = function () {
		// 请求完成
		if (this.status === 200) {
			// 返回200
			var blob = this.response;
			var reader = new FileReader();
			reader.readAsDataURL(blob);    // 转换为base64，可以直接放入a表情href
			reader.onload = function (e) {
				// 转换完成，创建一个a标签用于下载
				var a = document.createElement('a');
				a.download = fileName;
				a.href = e.target.result;
				$("body").append(a);    // 修复firefox中无法触发click
				a.click();
				$(a).remove();
			}
		}
	};
	xhh.send();
}


/**
 * @description: 获取当前 url 参数 json 对象
 * @param {string} url url
 * @returns {Object} Object
 */
export function param2Obj(url) {
  if (!url) return {}
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}