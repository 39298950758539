
import React, { useState, useEffect, } from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import QRCode from 'qrcode.react';
import {
    Form,
    Row,
    Col,
    Button,
    Table,
    DatePicker,
    Input,
    Select,
    message,
    Popconfirm,
    Tabs,
    Radio,
    Upload,
    Image,
    Modal,
    InputNumber,
    Typography,
    Tooltip
} from 'antd';
import { orderCancelRequest, orderAddValidateRequest, orderListRequest, queryByTypeRequest, orderCheckRequest, getConstantsRequest, settleMerchantListRequest, } from '../../../api/api'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import host from '../../../util/host';
import { useCallbackState } from '../../../util/useCallbackState'
import Detail from './detail/index'
import Add from './add/index'
import MultiplePeopleAdd from './multiplePeopleAdd/index'
import Edit from './edit/index'
import RecordDetails from './recordDetails/index'
import { limitTowDecimals } from '../../../util/tools';
import './style.less'
const { TextArea } = Input;
const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Title } = Typography;
const FormItem = Form.Item;
const Search = Input.Search;
const dateFormat = 'YYYY-MM-DD';
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
};
const formItemLayout = {
    labelCol: {
        xs: {
            span: 8,
        },
        sm: {
            span: 2,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
};

const Recharge = () => {
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const { state } = useLocation();
    //const [state, setState] = useState(false);
    const { search } = useLocation()
    const [startDate, setStartDate] = useState(moment(moment().format('YYYY-MM-DD'), dateFormat).format("YYYY-MM-DD"))
    const [data, setData] = useState(null)
    const [endDate, setEndDate] = useState(moment(moment().format('YYYY-MM-DD'), dateFormat).format("YYYY-MM-DD"))
    const [activeKey, setActiveKey] = useState('1')
    const [merchantList, setMerchantList] = useState([])
    const [shMerchantList, setShMerchantList] = useState([])
    const [merchantCode, setMerchantCode] = useState(state && state.merchantCode ? state.merchantCode : localStorage.merchantCode)
    const [pgmerchantCode, setPgMerchantCode] = useState(state && state.merchantCode ? state.merchantCode : localStorage.merchantCode)
    const [subMerchantCode, setSubMerchantCode] = useState(localStorage.hxState == 'true' ? null : localStorage.merchantCode)
    const [merchantName, setMerchantName] = useState(localStorage.merchantName)
    const [mySwitch, setMySwitch] = useState(localStorage.state == "1" ? true : false)
    const [img, setImg] = useState(null)
    const [list, setList] = useState([])
    const [lists, setLists] = useState([])
    const [cardList, setCardList] = useState([])
    const [cardData, setCardData] = useState(null)
    const [value1, setValue1] = useState(1)
    const [value2, setValue2] = useState(0)
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [total, setTotal] = useState()
    const [detailVisible, setDetailVisible] = useState(false);
    const [row, setRow] = useState(null)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [mianqian, setMianqian] = useState(false)
    const [add, setAdd] = useState(false)
    const [duorenAdd, setDuorenAdd] = useState(false)
    const [qrCode, setQrcode] = useState(null)
    const [amount, setAmount] = useState(0)
    const [pg, setPg] = useState(null)
    const [changeCode, setChangeCode] = useState(0)
    const [constants, setConstants] = useState(Array)
    const [params, setParams] = useState(Object)
    const [payerMerchantName, setPayerMerchantName] = useState(String)
    const [payerMerchantCode, setPayerMerchantCode] = useState(String)
    const [payeeMerchantName, setPayeeMerchantName] = useState(String)
    const [transferPurposeName, setTransferPurposeName] = useState(String)
    const [renovate, setRenovate] = useState(false)
    const [selectAll, setSelectAll] = useState(false)
    const [excludeOrderNos, setExcludeOrderNos] = useState(Array)
    const [editOpen, setEditOpen] = useState(false)
    const [row2, setRow2] = useState(Object)
    const [recordDetailsOpen, setRecordDetailsOpen] = useState(false)
    const [yue, setYue] = useState(0)
    const [aipv, setAipv] = useState(Number)
    const [arr, setArr] = useState(Array)


    useEffect(() => {
            settleMerchantList()
            getConstants();
            getOrderList()
            skSettleMerchantList()
            setPayerMerchantCode(localStorage.merchantCode);
            getAmount({ accountType: value1,merchantCode:localStorage.merchantCode });
    }, [currentPage, pageNum, renovate]) //所有更新都执行


    const orderCancel = (data) => {
        (async _ => {
            return await orderCancelRequest({ orderNo: data.orderNo });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                message.success(res.msg)
                getOrderList()
            } else {
                message.error(res.msg)
            }
        });
    }
    const getOrderList = (data) => {
        let values = form2.getFieldValue();
        console.log(values);
        const rangeValue = values['date'];
        values.startTime = rangeValue && rangeValue[0].format('YYYY-MM-DD');
        values.endTime = rangeValue && rangeValue[1].format('YYYY-MM-DD');
        // delete values.date;
        (async _ => {
            return await orderListRequest({
                ...values,
                ...data,
                superMerchantCode: localStorage.appid,
                merchantCode: localStorage.hxState == 'true' ? undefined : localStorage.merchantCode,
                currentPage: currentPage,
                pageSize: pageNum,
            });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                if (selectAll) {
                    console.log(11111111)
                    let arr = []
                    res.data.items.map((v) => {
                        arr.push(v.orderNo)

                    })
                    setSelectedRowKeys([...selectedRowKeys, ...arr])

                }
                setList(res.data.items);
                setTotal(res.data.totalCount)
            } else {
                setPg(null)
            }
        });
    }

    const getConstants = () => {

        (async _ => {
            return await getConstantsRequest({
                constantType: "FD_TR_PURPOSE"
            });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setConstants(res.data.items)
            } else {
                setPg(null)
            }
        });
    }

    function cancel(e) {

    }
    const onFinish1 = (values) => {
        values.currentPage = 1
        setCurrentPage(1)
        getOrderList(values)
    }


    const settleMerchantList = () => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode :localStorage.appid,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            if (res.code == 0) {
                setMerchantList(res.data);

            } else {

            }
        });
    }
    const skSettleMerchantList = (code) => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode: localStorage.appid,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            if (res.code == 0) {
                let index = res.data.findIndex((v) => { return v.merchantCode == localStorage.merchantCode })
                res.data.splice(index, 1)
                setShMerchantList(res.data)
            } else {

            }
        });
    }

    const columns1 = (_this) => {
        return [
            {
                title: '收款机构',
                dataIndex: 'payeeMerchantName',
                key: 'payeeMerchantName',
                render: (text, row) => {
                    return (
                        <div>
                            {
                                row.payeeMerchantCodeError == "" ?
                                    <div>
                                        {row.payeeMerchantName}
                                    </div> :
                                    <div>
                                        <div>
                                            {row.payeeMerchantCode} {row.payeeMerchantName} <div style={{ color: 'red' }}>{row.payeeMerchantCodeError}</div>
                                        </div>
                                    </div>
                            }
                        </div>
                    )

                }
            },
            {
                title: '收款账户',
                dataIndex: 'payerAccountType',
                key: 'payerAccountType',
                render: (text, row) => {
                    return (
                        <div>
                            {
                                !row.payeeAccountTypeError ?
                                    <div>
                                        {row.payeeAccountName}
                                    </div> :
                                    <div>
                                        <div>
                                            {row.payeeAccountName}
                                        </div>
                                        <div style={{ color: 'red' }}>
                                            {row.payeeAccountTypeError}
                                        </div>

                                    </div>
                            }
                        </div>
                    )

                }
            },
            {
                title: '转账金额',
                dataIndex: 'amount',
                key: 'amount',
                render: (text, row) => {
                    return (
                        <div>
                            {
                                !row.amountError ?
                                    <div>
                                        {row.amount}
                                    </div> :
                                    <div>
                                        <div>
                                            {row.amount}
                                        </div>
                                        <div style={{ color: 'red' }}>
                                            {row.amountError}
                                        </div>
                                    </div>
                            }
                        </div>
                    )

                }
            },
            {
                title: '转账用途',
                dataIndex: 'transferPurposeName',
                key: 'transferPurposeName',
                render: (text, row) => {
                    return (
                        <div>
                            {
                                !row.transferPurposeError ?
                                    <div>
                                        {row.transferPurposeName}
                                    </div> :
                                    <div style={{ color: 'red' }}>
                                        <div>
                                            {row.transferPurpose}
                                        </div>
                                        <div>
                                            {row.transferPurposeError}
                                        </div>
                                    </div>
                            }
                        </div>
                    )

                }
            },
            {
                title: '转账附言',
                dataIndex: 'remark',
                key: 'remark',
                ellipsis: true,
            },

            {
                title: '操作',
                dataIndex: 'dome',
                key: 'dome',
                fixed: 'right',
                width: 150,
                render: (text, row) => {
                    return (
                        <div>
                            <a onClick={() => {
                                deleteFuc(row)
                            }}>删除</a>
                            <a style={{ marginLeft: 10 }} onClick={() => {
                                setRow(row)
                                setAdd(true)
                            }}>编辑</a>
                        </div>

                    )
                }
            },
        ]
    }
    const columns2 = (_this) => {
        return [
            {
                title: '订单编号',
                dataIndex: 'orderNo',
                key: 'orderNo',
                width: 250
            },
            {
                title: '批次号',
                dataIndex: 'batchNo',
                key: 'batchNo',
                width: 200
            },
            {
                title: '付款机构',
                dataIndex: 'payerMerchantName',
                key: 'payerMerchantName',
            },
            {
                title: '付款账户',
                dataIndex: 'payerAccountType',
                key: 'payerAccountType',
                render: (text, row) => {
                    return (
                        <div>
                            {row.payerAccountType == 1 ? '店铺余额' : ''}
                            {row.payerAccountType == 2 ? '钱包余额' : ''}
                        </div>
                    )

                }
            },
            {
                title: '收款机构',
                dataIndex: 'payeeMerchantName',
                key: 'payeeMerchantName',
                width: 150
            },
            {
                title: '收款账户',
                dataIndex: 'payeeAccountType',
                key: 'payeeAccountType',
                render: (text, row) => {
                    return (
                        <div>
                            {row.payeeAccountType == 1 ? '店铺余额' : ''}
                            {row.payeeAccountType == 2 ? '钱包余额' : ''}
                        </div>
                    )

                }
            },
            {
                title: '转账金额(元)',
                dataIndex: 'amount',
                key: 'amount',
                width: 120
            },
            {
                title: '转账用途',
                dataIndex: 'transferPurposeName',
                key: 'transferPurposeName',
            },
            {
                title: '转账附言',
                dataIndex: 'remark',
                key: 'remark',
                ellipsis: true,
            },
            {
                title: '转账操作人',
                dataIndex: 'operaterName',
                key: 'operaterName',
            },
            {
                title: '转账发起时间',
                dataIndex: 'createTime',
                key: 'createTime',
                width: 200
            },
            {
                title: '转账成功时间',
                dataIndex: 'transferFinishDate',
                key: 'transferFinishDate',
                width: 200
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (text, row) => {
                    return (
                        <div>
                            {row.status == 0 ? '未转账' : ''}
                            {row.status == 1 ? '转帐中' : ''}
                            {row.status == 2 ? '转账成功' : ''}
                            {row.status == 3 ? '转账失败' : ''}
                        </div>
                    )
                }
            },
            {
                title: '失败原因',
                dataIndex: 'errorMsg',
                key: 'errorMsg',
                width: 200
            },
            {
                title: '操作',
                dataIndex: 'dome',
                key: 'dome',
                fixed: 'right',
                width: 150,
                render: (text, row) => {
                    return (
                        <div>
                            {
                               row.status == 0 ?
                                    <Popconfirm
                                        title="是否确认取消？"
                                        onConfirm={() => { orderCancel(row) }}
                                        onCancel={cancel}
                                        okText="是"
                                        cancelText="否"
                                    >
                                        <a style={{ marginLeft: 10 }} href="#">取消</a>
                                    </Popconfirm> : null
                            }
                            {
                               row.status == 0 ?
                                    <Popconfirm
                                        title="请确认转账信息，审核后即转账"
                                        onConfirm={() => { confirm(row) }}
                                        onCancel={() => {
                                            console.log('取消')
                                        }}
                                        okText="是"
                                        cancelText="否"
                                    >
                                        <a style={{ marginLeft: 10 }} href="#">审核</a>
                                    </Popconfirm> : null

                            }
                            {
                                <a style={{ marginLeft: 10 }} onClick={() => {
                                    setRow2(row)
                                    setRecordDetailsOpen(true)
                                }}>查看</a>
                            }
                        </div>

                    )
                }
            },
        ]
    }
    const onFinish = (values) => {
        console.log(values);
        delete values.img;
        let params = {
            ...values,
            payerMerchantCode: values.payerMerchantCode  ?  values.payerMerchantCode :localStorage.merchantCode ,
            payerMerchantName,
            payeeMerchantName,
            yue,
            transferPurposeName,
            superMerchantCode: localStorage.appid,
            operaterId: localStorage.operatorId,
            orderVos: changeCode == 0 ? [{
                payeeMerchantCode: values.payeeMerchantCode,
                payeeAccountType: values.payerAccountType,
                remark: values.remark,
                transferPurpose: values.transferPurpose,
                amount: values.amount,
            }] : lists
        };
        (async _ => {
            return await orderAddValidateRequest(params);
        })().then(res => {
            if (res.code == 0) {
                // params.batchNo = res.data.batchNo
                setParams(params);
                if (changeCode == 0) {
                    setMianqian(true)
                } else {
                    setDuorenAdd(true)
                }

            } else {
                message.error(res.msg)
            }
        });
    }


    const confirm = (e) => {
        console.log(e);
        let params = {
            allChecked: 1,
            orderNoArray: [e.orderNo]
        };
        (async _ => {
            return await orderCheckRequest(params);
        })().then(res => {
            if (res.code == 0) {
                getOrderList();
                message.success(res.msg);
            } else {
                message.error(res.msg)
            }
        });

    };

    const deleteFuc = (row) => {
        console.log(row);
        if (row && row.payeeAccountTypeError || row && row.payeeMerchantCodeError || row && row.transferPurposeError) {
            setAipv(aipv - 1)
        }
        let index = lists.findIndex((v) => { return v.id == row.id })
        console.log(index);
        lists.splice(index, 1)
        setLists([...lists])
    }
    const onChange = (key) => {
        setActiveKey(key)
        if (key == 2) {
            form2.setFieldsValue({
                date: [moment(moment().format('YYYY-MM-DD'), dateFormat), moment(moment().format('YYYY-MM-DD'), dateFormat)]
            })
            getOrderList();
        }

    };
    const inSevenDays = () => {
        form2.setFieldsValue({
            date: [moment(moment().subtract(7, "days").format("YYYY-MM-DD"), dateFormat), moment(moment().format('YYYY-MM-DD'), dateFormat)]
        })
    }
    const nearlyThirtyDays = () => {
        form2.setFieldsValue({
            date: [moment(moment().subtract(30, "days").format("YYYY-MM-DD"), dateFormat), moment(moment().format('YYYY-MM-DD'), dateFormat)]
        })
    }
    const accountTypeChange = (e) => {
        console.log(payerMerchantCode);
        const merchantCode =  payerMerchantCode  ?  payerMerchantCode : localStorage.merchantCode
        getAmount({ accountType: e.target.value,merchantCode:merchantCode})
        setValue1(e.target.value)
    }
    const payerMerchantCodeChange = (e) => {
        merchantList && merchantList.map((v) => {
            if (v.merchantCode == e) {
                console.log(v.merchantName);
                setPayerMerchantName(v.merchantName);
                setPayerMerchantCode(v.merchantCode);
                getAmount({ accountType: value1,merchantCode:v.merchantCode})
            }
        })
       
    }
    const getAmount = (data) => {
        let params = {
            accountType: data.accountType ? data.accountType : value1,
            merchantCode: data.merchantCode,
        };
        (async _ => {
            return await queryByTypeRequest(params);
        })().then(res => {
            if (res.code == 0) {
                setYue(res.data.availableBalance);
            } else {
                message.error(res.msg)
            }
        });
    }
    const onChangPage = (currentPage, pageNum) => {
        console.log(currentPage);
        setCurrentPage(currentPage, () => {
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        setPageNum((currentPage, pageNum), () => {
        })
    }
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRowKeys);
            setSelectedRowKeys(selectedRowKeys)
        },
        onSelect: (record, selected, selectedRows, nativeEvent) => {
            console.log(record, selected, selectedRows, nativeEvent)
            record.checked = selected;
            console.log(record);
            let arr = []
            if (selected == false) {
                arr.push(record.orderNo)
            }
            setExcludeOrderNos(arr)

        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            console.log(selected);
            setSelectAll(selected)
        },
        onSelectNone: (a) => {
            console.log(a);
        },
        onSelectInvert: (selectedRowKeys) => {
            console.log(selectedRowKeys)
        },
        getCheckboxProps: (record) => ({
            disabled: record.status !== 0,
        }),
        // getCheckboxProps: (record) => ({
        //     disabled: record.auditStatus !== 0,
        //     // Column configuration not to be checked
        //     name: record.name,
        // }),
    };
    const mianqianHandleCancel = () => {
        setMianqian(false);
    };
    const addCancel = () => {
        setAdd(false);
    };
    const duorenAddCancel = () => {
        setDuorenAdd(false);
    };
    const editCancel = () => {
        setEditOpen(false)
    };
    const recordDetailsCancel = () => {
        setRecordDetailsOpen(false)
    }
    const batchConfirm = () => {
        let values = form2.getFieldsValue()
        console.log(values);
        console.log(values);
        const rangeValue = values['date'];
        values.startTime = rangeValue && rangeValue[0].format('YYYY-MM-DD');
        values.endTime = rangeValue && rangeValue[1].format('YYYY-MM-DD');
        let params = null;
        if (selectAll) {
            params = {
                allChecked: selectAll ? 0 : 1,
                excludeOrderNos: excludeOrderNos,
                ...values,
            };
        } else {
            params = {
                allChecked: selectAll ? 0 : 1,
                orderNoArray: selectedRowKeys,

            };
        }


        (async _ => {
            return await orderCheckRequest(params);
        })().then(res => {
            if (res.code == 0) {
                getOrderList()
                message.success(res.msg);
            } else {
                message.error(res.msg)
            }
        });
    }
    const batchCancel = () => {

    }
    // const uploadButton = (
    //     <div>
    //         {loading ? <LoadingOutlined /> : <PlusOutlined />}
    //         <div
    //             style={{
    //                 marginTop: 8,
    //             }}
    //         >
    //             Upload
    //         </div>
    //     </div>
    // );
    const beforeUpload = (file) => {
        const isXlsx = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        if (!isXlsx) {
            message.error('请上传excel表格!');
        }

        return isXlsx;
    }
    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            console.log(info.file.response.data);
            if (info.file.response.code == 0) {
                let arr = [];
                info.file.response.data.objects.map((v) => {
                    v.id = Math.random(10)
                    if (!v.payeeMerchantCodeError) {
                        arr.push(v)
                    }
                })
                setArr([...arr])
                setLoading(false);
                setLists([...lists, ...info.file.response.data.objects])
                setAipv(info.file.response.data.aipv.failCount)
            } else {
                message.error(info.file.response.msg)
            }

            // getBase64(info.file.originFileObj, (url) => {

            // });
        }
    };
    const getRowClassName = (record) => {
        // if (record) {       // 根据这一行的状态，改变不同的颜色
        return 'warning';         // 加 s.  是因为上面引入的问题
        // } else {
        // return '';
        // }
    };
    return (
        <div style={{ background: '#ffffff', paddingBottom: 20, width: '100%' }}>

            {/* <Row style={{ marginTop: 20, width: '100%' }} > */}
            <Tabs activeKey={activeKey.toString()} onChange={onChange} size="large" tabBarStyle={{ paddingLeft: 20 }} style={{ padding: 10, marginTop: 10 }} tabBarGutter={100}>
                <TabPane tab="转账" key="1">
                    <Form
                        form={form1}
                        name="time_related_controls"
                        {...formItemLayout}
                        onFinish={onFinish}
                        autoComplete="off"
                        initialValues={{
                            transferType: "0",
                            payerAccountType: 1,
                            payerMerchantCode: localStorage.merchantCode,
                        }}
                    >
                        <div style={{ marginLeft: 20, marginBottom: 16 }} >
                            <Title level={5}>转账方式</Title>
                            <div style={{ marginTop: 16 }}>
                                <Form.Item
                                    name="transferType"
                                    noStyle
                                >
                                    <Radio.Group defaultValue="0" size="large" onChange={(e) => {
                                        console.log(e)
                                        setChangeCode(e.target.value);

                                    }}>
                                        <Radio.Button value="0">单人转账</Radio.Button>
                                        <Radio.Button style={{ marginLeft: 20 }} value="1">向多人转账</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>

                            </div>
                        </div>
                        <div style={{ marginLeft: 20, marginTop: 16, marginBottom: 16 }}>
                            <Title level={5}>付款账户信息</Title>
                        </div>
                        {/*<Form.Item
                            name="payerMerchantCode"
                            label="付款机构"
                        >
                        {localStorage.merchantName}
                        </Form.Item>*/}
                        {
                            localStorage.appid === localStorage.merchantCode ?
                            <Form.Item
                            name="payerMerchantCode"
                            label="付款机构"
                            rules={[{ required: true, message: '请选择付款机构!' }]}
                        >
                            <Select placeholder="请选择"
                                style={{ width: 250 }}
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                                // onChange={(e) => {
                                //     merchantList && merchantList.map((v) => {
                                //         if (v.merchantCode == e) {
                                //             setPayerMerchantName(v.merchantName)
                                //         }
                                //     })
                                // }}
                                onChange={payerMerchantCodeChange}
                            >

                                {
                                    merchantList && merchantList.map((v) => {
                                        return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                    })
                                }

                            </Select>
                        </Form.Item>
                             :   
                             <Form.Item
                             name="payerMerchantCode"
                             label="付款机构"
                             >
                             {localStorage.merchantName}
                         </Form.Item>
                        }         
                        <Form.Item
                            name="payerAccountType"
                            label="付款账户"
                            rules={[{ required: true, message: '请选择付款账户!' }]}
                        >
                            <Radio.Group onChange={accountTypeChange}>
                                <Radio value={1}>店铺余额</Radio>
                                <Radio value={2}>钱包余额</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            name="d"
                            label="账户余额"
                        >
                            <span style={{ fontWeight: '600' }}>{yue}</span>元
                        </Form.Item>
                        {
                            changeCode == 0 ?
                                <div>
                                    <div style={{ marginLeft: 20, marginTop: 16, marginBottom: 16 }}>
                                        <Title level={5}>收款账户信息</Title>
                                    </div>
                                    <Form.Item
                                        name="payeeMerchantCode"
                                        label="收款机构"
                                        rules={[{ required: true, message: '请选择收入机构!' }]}
                                    >
                                        <Select placeholder="请选择"
                                            style={{ width: 250 }}
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.includes(input)}
                                            onChange={(e) => {
                                                merchantList && merchantList.map((v) => {
                                                    if (v.merchantCode == e) {
                                                        setPayeeMerchantName(v.merchantName)
                                                    }
                                                })
                                            }}
                                        >

                                            {
                                                merchantList && merchantList.map((v) => {
                                                    return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                                })
                                            }

                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="payeeMerchantCode"
                                        label="收款账户"
                                    >
                                        <span>{value1 == 1 ? '店铺余额' : '钱包余额'}</span>
                                    </Form.Item>
                                </div> : null
                        }

                        {
                            changeCode == 0 ?
                                <div>
                                    <div style={{ marginLeft: 20, marginTop: 16, marginBottom: 16 }}>
                                        <Title level={5}>转账信息</Title>
                                    </div>
                                    {/* <Form.Item
                                        name="d"
                                        label="当前转账限额"
                                    >
                                        <span>{amount}</span>元
                                    </Form.Item> */}
                                    <Form.Item
                                        name="amount"
                                        label="转账金额"
                                        rules={[{ required: true, message: '请输入转账金额!' }]}
                                    >
                                        <InputNumber
                                            placeholder="请输入"
                                            style={{ width: 250 }}
                                            formatter={limitTowDecimals}
                                            parser={limitTowDecimals}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="transferPurpose"
                                        label="转账用途"
                                        rules={[{ required: true, message: '请选择转账用途!' }]}
                                    >
                                        <Select placeholder="请选择"
                                            style={{ width: 250 }}
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.includes(input)}
                                            onChange={(e) => {
                                                constants && constants.map((v) => {
                                                    if (v.constantCode == e) {
                                                        setTransferPurposeName(v.constantName)
                                                    }
                                                })
                                            }}
                                        >

                                            {
                                                constants && constants.map((v) => {
                                                    return <Option rowKey={v.constantCode} key={v.constantCode} value={v.constantCode}>{v.constantName}</Option>
                                                })
                                            }

                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="remark"
                                        label="转账附言"
                                    >
                                        <TextArea
                                            allowClear
                                            autoSize={{
                                                minRows: 3, maxRows: 6
                                            }}
                                            style={{ width: 250 }}
                                            placeholder="最多可输入50个字，特殊字符会被过滤"
                                        />
                                    </Form.Item>
                                </div> : null
                        }
                        {
                            changeCode == 1 ?
                                <div>
                                    <div style={{ marginLeft: 20, marginTop: 16, marginBottom: 16 }}>
                                        <Title level={5}>收款账户信息</Title>
                                    </div>
                                    <Row justify='space-between'>
                                        <Col span={10} style={{ marginLeft: 20 }}>
                                            <Button type="primary"
                                                // disabled={!form1.getFieldValue().payerMerchantCode}
                                                onClick={() => {
                                                    setRow(null)
                                                    setAdd(true);
                                                }}>添加</Button>
                                            <Upload
                                                name="excel"
                                                accept=".xls, .xlsx"
                                                // listType="picture-card"
                                                className="avatar-uploader"
                                                showUploadList={false}
                                                action={`${host}/transfer/orderImport`}
                                                headers={{ 'token': localStorage.token, }}
                                                beforeUpload={beforeUpload}
                                                onChange={handleChange}
                                                data={file => ({ // data里存放的是接口的请求参数
                                                    superMerchantCode: localStorage.appid,
                                                    taskName: 'transferOrderExcelBeanImport',
                                                    payerMerchantCode: payerMerchantCode,
                                                    accountType: value1,
                                                })}
                                            >
                                                <Button style={{ marginLeft: 20 }}>批量导入</Button>
                                            </Upload>
                                            {/* <Button style={{ marginLeft: 20 }}>批量导入</Button> */}
                                            <Button type="link" onClick={() => {
                                                window.open(`${host}/excel/转账导入模板.xlsx`)
                                            }}>下载Excel模板</Button>
                                        </Col>
                                        <Col span={12} style={{ textAlign: 'right', marginRight: 20 }}>
                                            <Select placeholder="请选择"
                                                style={{ width: 250, textAlign: 'left' }}
                                                // rowClassName={() => { getRowClassName() }} //改变某一行的背景颜色
                                                showSearch
                                                allowClear
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.includes(input)}
                                                onChange={(e) => {
                                                    console.log(e);
                                                    if (e) {
                                                        let itmp = {}
                                                        let list = []
                                                        list = lists.filter(item => {
                                                            if (item.id === e) {
                                                                itmp = item
                                                            }
                                                            return item.id !== e
                                                        })
                                                        list.unshift(itmp)
                                                        setLists([...list])
                                                    }
                                                }}

                                            >

                                                {
                                                    arr && arr.map((v) => {
                                                        return <Option rowKey={v.id} key={v.id} value={v.id}>{v.payeeMerchantName}</Option>
                                                    })
                                                }

                                            </Select>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Table

                                            locale={{
                                                emptyText: "没有更多数据了"
                                            }}
                                            style={{ marginTop: 10, marginLeft: 20, marginRight: 20, width: '100%' }}

                                            // loading={loading}
                                            scroll={{ y: 500 }}
                                            rowKey="id"
                                            dataSource={lists}
                                            columns={columns1(this)}
                                            pagination={false}
                                        />
                                    </Row>
                                </div> : null
                        }
                        <Row style={{ marginTop: 20 }}>
                            {
                                changeCode == 0 ?
                                    <Col span={8}>
                                        <Form.Item
                                            wrapperCol={{
                                                offset: 6,
                                                span: 18,
                                            }}
                                        >
                                            <Button type="primary" htmlType="submit">
                                                提交
                                            </Button>
                                            <Popconfirm
                                                title="是否确认清空?"
                                                onConfirm={() => {
                                                    form1.resetFields()
                                                    getAmount({ accountType: 1 })
                                                }}
                                                onCancel={() => {
                                                    console.log('取消')
                                                }}
                                                okText="是"
                                                cancelText="否"
                                            >
                                                <Button style={{ marginLeft: 16 }}
                                                >
                                                    清空
                                                </Button>
                                            </Popconfirm>

                                        </Form.Item>
                                    </Col> :
                                    <Col span={24} style={{ textAlign: 'center' }}>
                                        <Tooltip title={aipv > 0 ? "有错误数据，请先修改再提交" : ""} color='red'>
                                            <Button type="primary" htmlType="submit"
                                                disabled={aipv > 0}
                                            >
                                                提交
                                            </Button>
                                        </Tooltip>
                                        <Popconfirm
                                            title="是否确认清空?"
                                            onConfirm={() => {
                                                form1.resetFields()
                                                getAmount({ accountType: 1 })
                                                setLists([])
                                                form1.setFieldsValue({
                                                    transferType: '1'
                                                })
                                                setChangeCode('1')
                                            }}
                                            onCancel={() => {
                                                console.log('取消')
                                            }}
                                            okText="是"
                                            cancelText="否"
                                        >
                                            <Button style={{ marginLeft: 16 }}
                                            >
                                                清空
                                            </Button>
                                        </Popconfirm>

                                    </Col>
                            }

                        </Row>


                    </Form>

                </TabPane>

                <TabPane tab="转账记录" key="2">
                    <Form
                        form={form2}
                        {...layout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish1}
                        style={{ background: '#F7F8FA', padding: 20, }}
                        initialValues={{
                            merchantCode: state && state.merchantCode,
                        }}
                    >
                        <Row style={{ marginBottom: '-1vh' }}>
                            <Col span={12} pull={1}>
                                <Form.Item
                                    name="date"
                                    label="转账时间："
                                    wrapperCol={{
                                        offset: 0,
                                        span: 20,
                                    }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Form.Item
                                            name="date"
                                            noStyle
                                        >
                                            <RangePicker style={{ width: 250 }} />
                                        </Form.Item>
                                        <Form.Item
                                            noStyle
                                        >
                                            <Button onClick={inSevenDays} type="link">近7天</Button>
                                            <Button onClick={nearlyThirtyDays} type="link">近30天</Button>
                                        </Form.Item>

                                    </div>

                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                {
                                    localStorage.hxState == 'true' ?
                                        <Form.Item
                                            name="payerMerchantCode"
                                            label="付款机构"
                                        >
                                            <Select placeholder="请选择"
                                                style={{ width: 250 }}
                                                showSearch
                                                allowClear
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.includes(input)}
                                                onChange={(e) => {
                                                    setSubMerchantCode(e)
                                                }}
                                            >
                                                {/* <Option key={null} value={null}>全部</Option> */}
                                                {
                                                    merchantList && merchantList.map((v) => {
                                                        return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                                    })
                                                }

                                            </Select>
                                        </Form.Item> :
                                        <Form.Item
                                            name="merchantCode"
                                            label="付款机构"
                                        >
                                            {localStorage.merchantName}
                                        </Form.Item>
                                }

                            </Col>
                            <Col span={8} >
                                <Form.Item
                                    name="payerAccountType"
                                    label="付款账户"
                                >
                                    <Select style={{ width: 250 }} allowClear>
                                        <Option value="1">店铺余额</Option>
                                        <Option value="2">钱包余额</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={8} >
                                <Form.Item
                                    name="payeeMerchantCode"
                                    label="收款机构"
                                >
                                    <Select placeholder="请选择"
                                        style={{ width: 250 }}
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.includes(input)}
                                        onChange={(e) => {
                                            setSubMerchantCode(e)
                                        }}
                                    >
                                        {/* <Option key={null} value={null}>全部</Option> */}
                                        {
                                            merchantList && merchantList.map((v) => {
                                                return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                            })
                                        }

                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="payeeAccountType"
                                    label="收款账户"
                                >
                                    <Select style={{ width: 250 }} allowClear>
                                        <Option value="1">店铺余额</Option>
                                        <Option value="2">钱包余额</Option>
                                    </Select>
                                </Form.Item>
                            </Col>


                        </Row>
                        <Row>
                            <Col span={8} >
                                <Form.Item
                                    name="orderNo"
                                    label="转账订单编号"
                                >
                                    <Input style={{ width: 250 }} placeholder="请输入" />
                                </Form.Item>
                            </Col>
                            <Col span={8} >
                                <Form.Item
                                    name="batchNo"
                                    label="转账批次号"
                                >
                                    <Input style={{ width: 250 }} placeholder="请输入" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8} >
                                <Form.Item
                                    name="status"
                                    label="转账状态"
                                >
                                    <Select style={{ width: 250 }} allowClear>

                                        <Option value="0">未转账</Option>
                                        <Option value="1">转账中</Option>
                                        <Option value="2">转账成功</Option>
                                        <Option value="3">转账失败</Option>

                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8} >
                                <Form.Item
                                    name="transferPurpose"
                                    label="转账用途"
                                >
                                    <Select placeholder="请选择"
                                        style={{ width: 250 }}
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.includes(input)}
                                        onChange={(e) => {
                                            constants && constants.map((v) => {
                                                if (v.constantCode == e) {
                                                    setTransferPurposeName(v.constantName)
                                                }
                                            })
                                        }}
                                    >

                                        {
                                            constants && constants.map((v) => {
                                                return <Option rowKey={v.constantCode} key={v.constantCode} value={v.constantCode}>{v.constantName}</Option>
                                            })
                                        }

                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item
                                    wrapperCol={{
                                        offset: 6,
                                        span: 18,
                                    }}
                                >
                                    <Button type="primary" htmlType="submit">
                                        筛选
                                    </Button>
                                    <Button type="link" onClick={() => {
                                        form2.resetFields();
                                    }} >
                                        重置筛选条件
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>


                    </Form>
                    <Table
                        rowSelection={{
                            ...rowSelection,
                        }}
                        locale={{
                            emptyText: "没有更多数据了"
                        }}
                        style={{ marginTop: 10 }}
                        // loading={loading}
                        rowKey="orderNo"
                        dataSource={list}
                        columns={columns2(this)}
                        scroll={{ x: 2400 }}
                        pagination={{
                            total,
                            showSizeChanger: true,
                            current: currentPage,
                            pageSize: pageNum,
                            showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                            onChange: (k, v) => onChangPage(k, v),
                            onShowSizeChange: (k, v) => onChangPageNum(k, v),
                        }}
                        footer={() => {
                            return (
                                selectedRowKeys.length > 0 ?
                                    <Popconfirm
                                        title="确认批量审核通过?"
                                        onConfirm={batchConfirm}
                                        onCancel={batchCancel}
                                        okText="是"
                                        cancelText="否"
                                    >
                                        <Button type="primary">批量审核通过</Button>
                                    </Popconfirm>


                                    : null
                            )
                        }}
                    />
                </TabPane>
            </Tabs >

            {/* <Modal
                title="充值审核"
                visible={detailVisible}
                onOk={detailHandleOk}
                onCancel={detailhandleCancel}
                width={600}
                footer={null}
                key={Math.random(10)}
            >
                <Detail data={row}
                    callback={(state) => {
                        if (state) {
                            bookRechargeList()

                        }
                    }}
                    closeDialog={() => {
                        setDetailVisible(false)
                    }} />
            </Modal> */}
            <Modal
                width={650}
                title="转账信息确认" visible={mianqian} onCancel={mianqianHandleCancel}
                footer={null}
            >
                <Row justify='center'>
                    <Detail data={params}
                        callback={(state) => {
                            console.log(state)
                            form1.resetFields()
                        }}
                        closeDialog={() => {
                            setMianqian(false)
                        }}
                    />
                </Row>

            </Modal>
            <Modal
                width={650}
                title="收款账户信息" visible={add} onCancel={addCancel}
                footer={null}
                getContainer={false}  //1.排除警告
            >
                <Row justify='center'>
                    <Add
                        value1={value1}
                        yue={yue}
                        data={row}
                        payerMerchantCode={form1.getFieldValue().payerMerchantCode}
                        callback={(state) => {
                            console.log(state)
                            if (state.errorNum) {
                                setAipv(aipv - 1)
                            }
                            state.payeeAccountType = value1
                            let a = [state]
                            let index = lists.findIndex((v) => { return v.id == state.id })
                            console.log(index);

                            if (index != -1) {
                                lists[index] = state
                                setLists([...lists])
                            } else {
                                console.log(1111111)
                                setLists([...lists, ...a])
                            }

                        }}
                        closeDialog={() => {
                            setAdd(false)
                        }}
                    />
                </Row>

            </Modal>

            <Modal
                width={650}
                title="收款账户信息" visible={duorenAdd} onCancel={duorenAddCancel}
                footer={null}
                getContainer={false}  //1.排除警告
            >
                <Row justify='center'>
                    <MultiplePeopleAdd data={params}
                        callback={(state) => {
                            console.log(state)
                            if (state) {
                                setRenovate(true)
                                setLists([])
                            }

                        }}
                        closeDialog={() => {
                            setDuorenAdd(false)
                        }}
                    />
                </Row>

            </Modal>
            <Modal
                width={650}
                title="收款账户信息" visible={editOpen} onCancel={editCancel}
                footer={null}
                getContainer={false}  //1.排除警告
            >
                <Row justify='center'>
                    <Edit data={row2}
                        callback={(state) => {
                            console.log(state)
                            if (state) {
                                getOrderList();
                            }

                        }}
                        closeDialog={() => {
                            setEditOpen(false)
                        }}
                    />
                </Row>

            </Modal>
            <Modal
                width={650}
                title="转账详情" visible={recordDetailsOpen} onCancel={recordDetailsCancel}
                footer={null}
                getContainer={false}  //1.排除警告
            >
                <Row justify='center'>
                    <RecordDetails data={row2}
                        callback={(state) => {
                            console.log(state)
                            if (state) {
                            }

                        }}
                        closeDialog={() => {
                            setRecordDetailsOpen(false)
                        }}
                    />
                </Row>

            </Modal>
        </div >
    )
}
export default Recharge;