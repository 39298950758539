import { Row, Col, Button, Form, Table, Select, Typography, Radio, } from 'antd';
import { Link, useNavigate, useLocation, } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { balanceAccountViewRequest, settleMerchantListRequest } from '../../api/api'
import { useCallbackState } from '../../util/useCallbackState'
const { Title } = Typography;
const { Option } = Select;
const layout = {
    labelCol: { span: 1 },
    wrapperCol: { span: 20 },
};
const GuidePage = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { search } = useLocation()
    const { state } = useLocation()
    const [list, setList] = useState([])
    const [merchantList, setMerchantList] = useState([])
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [total, setTotal] = useState()
    const [init, setInit] = useState(true)
    const [mySwitch, setMySwitch] = useState(state && state.tag == 1 ? true : localStorage.state == "1" ? true : false)
    const [radioValue, setRadioValue] = useState(0)
    useEffect(() => {
        console.log(state)
    }, [currentPage, pageNum]);

    const merchantCode = state.merchantCode || ''
    const merchantName = state.merchantName || ''

    const columns = [
        {
            title: '',
            dataIndex: 'name',
            width: 50,
            render(_, row) {
                return {
                    children: row.name,
                    props: {
                        rowSpan: row.rowSpan,
                    }
                }
            }
        },
        {
            title: <div><span style={{ color: '#333333', fontSize: '16px', fontWeight: 600 }}>认证材料</span><span style={{ color: '#999999', fontSize: '14px', }}>（点击标题即可查看示例图）</span></div>,
            dataIndex: 'borrow',
            render(text, row) {
                return <span style={{ color: '#145BD3', fontSize: '14px' }}>{row.borrow}</span>
            }
        },
        {
            title: <div><span style={{ color: '#333333', fontSize: '16px', fontWeight: 600 }}>详细描述</span><span style={{ color: '#999999', fontSize: '14px', }}>（复印件需加盖公司公章）</span></div>,
            dataIndex: 'repayment',
            render: (text, row) => {
                return (
                    <div>
                        <div>{row.text1}</div>
                        <div>{row.text2}</div>
                        <div>{row.text3}</div>
                        <div>{row.text4}</div>
                        <div>{row.text5}</div>
                    </div>
                )
            }
        },
    ];
    const data = [
        {
            key: '1',
            name: '主体资质',
            borrow: '统一社会信用代码证',
            text1: '-',
            repayment: '-',
        },
        {
            key: '2',
            name: '主体资质',
            borrow: '法人证件照正反面照片',
            text1: '· 证件包含：身份证、护照、来往内地通行证、外国人居留证、港澳台居民证等有效证件。',
            text2: '· 其中护照需要增加翻译件，加盖公司鲜章，外籍商家姓名需为中文（英文），如张三（Zhangsan）。',
            text3: '· 照片标准：四角完整，清晰可辨。若加水印需保证照片重要信息清晰可辨',
            text4: '· 法人证件非大陆身份证（如护照等）时，需要提供法人证明函。',
            text5: '· 注意：证件盖章不支持电子章。',
            repayment: '',

        },
    ];
    //合并数组单元格
    const createNewArr = (data) => {
        return data.reduce((result, item) => {
            //首先将name字段作为新数组result取出
            if (result.indexOf(item.name) < 0) {
                result.push(item.name)
            }
            return result
        }, []).reduce((result, name) => {
            //将name相同的数据作为新数组取出，并在其内部添加新字段**rowSpan**
            const children = data.filter(item => item.name === name);
            result = result.concat(
                children.map((item, index) => ({
                    ...item,
                    rowSpan: index === 0 ? children.length : 0,//将第一行数据添加rowSpan字段
                }))
            )
            return result;
        }, [])
    }

    const goNext = () => {
        if (radioValue == 2) {
            navigate('/ws/individualSettlement', { replace: false, state: { edit: 0, openType: radioValue, merchantCode, merchantName } })
        }
        if (radioValue == 0 || radioValue == 1) {
            navigate('/ws/enterpriseSettlement', { replace: false, state: { edit: 0, openType: radioValue, merchantCode, merchantName } })
        }
    }
    const goWithdrawalRecord = () => {
        navigate('/ws/balance/withdrawal', { replace: false, state: { defaultActiveKey: 2, tag: 1, row: state } })
    }

    return (
        <>
            <div style={{ background: '#ffffff', padding: 16, marginTop: 16 }}>
                <Row justify='space-between'>
                    <span style={{ fontSize: '20px', fontWeight: 500, }}>1. 选择主体类型和经营类目</span>
                </Row>
                <Form style={{ marginTop: 24 }} form={form} {...layout} name="horizontal_login"
                    initialValues={{
                        type: 0,
                    }}
                >
                    {/* 测试门店是否代入成功， 不需要可注释掉 */}
                    { merchantCode && merchantName ? <Form.Item label="机构名称">{merchantName}</Form.Item> : null }
                    <Form.Item
                        name="type"
                        label="主体类型"
                        rules={[{ required: false, message: 'Please input your username!' }]}
                    >
                        <Radio.Group value={radioValue} onChange={(e) => {
                            console.log(e);
                            setRadioValue(e.target.value)
                        }}>
                            <Radio.Button value={0}>企业</Radio.Button>
                            <Radio.Button value={1}>个体户</Radio.Button>
                            <Radio.Button value={2}>个人</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        style={{ marginTop: '-16px' }}
                        name=""
                        label=" "
                        colon={false}
                        rules={[{ required: false, message: 'Please input your username!' }]}
                    >
                        <div style={{ background: '#F7F8FA', width: 600, height: 52, lineHeight: '52px', color: '#666666', fontSize: '14px', paddingLeft: '17px' }}> “企业”在营业执照上的主体类型一般为：有限公司、有限责任公司等。</div>

                    </Form.Item>
                </Form>
            </div >
            <div style={{ background: '#ffffff', padding: 16, marginTop: 16 }}>
                <Row align='middle'>
                    <span style={{ fontSize: '20px', fontWeight: 500, }}>2.按照以下清单准备材料</span> <span style={{ color: '#145BD3', fontSize: '14px', marginLeft: '7px' }}>下载全部材料模板</span>
                </Row>
                <Table
                    columns={columns}
                    dataSource={createNewArr(data)}
                    pagination={false}
                    bordered
                    style={{ marginTop: 16 }}
                />
                <Row justify='center' style={{ marginTop: 16 }}>
                    <Col><Button type='primary' onClick={() => {
                        goNext()
                    }}>材料准备就绪，开始认证</Button></Col>
                </Row>
            </div >
        </>

    )
}
export default GuidePage