
import { Breadcrumb, Layout, Menu, Button } from 'antd';
import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router';
import { Link, useLocation, Navigate, useNavigate } from 'react-router-dom';
import './style.less'
const { Header, Content, Footer, Sider } = Layout;

const Home = () => {
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();
    const [state, setState] = useState(false);
    const [nav, setNav] = useState([])
    const a = useLocation()
    const { pathname } = useLocation()
    const breadcrumbList = [
        {
            path: '/ws/platformKanban/assetOverview',
            state: '资产总览',
        },
        {
            path: '/ws/platformKanban/availableFunds',
            state: '店铺余额',
        },
        {
            path: '/ws/balance/availableBalance',
            state: '店铺余额',
        },
        {
            path: '/ws/platformKanban/purseMoney',
            state: '钱包余额',
        },
        {
            path: '/ws/balance/walletBalance',
            state: '钱包余额',
        },
        {
            path: '/ws/platformKanban/marketing',
            state: '营销余额',
        },
        {
            path: '/ws/storeMarketing',
            state: '营销余额',
        },
        {
            path: '/ws/platformKanban/creditFunds',
            state: '授信余额',
        },
        {
            path: '/ws/creditAccount/credit',
            state: '授信余额',
        },
        {
            path: '/ws/revenueAccount',
            state: '收益余额',
        },
        {
            path: '/ws/platformKanban/earnings',
            state: '收益余额',
        },
        {
            path: '/ws/balance/withdrawal',
            state: '提现',
        },
        {
            path: '/ws/cost/list',
            state: '费用单2.0',
        },
        {
            path: '/ws/cost/edit',
            state: '费用单2.0编辑',
        },
        {
            path: '/ws/cost/deteditail',
            state: '费用单2.0详情',
        },
        {
            path: '/ws/bills/list',
            state: '费用单',
        },
        {
            path: '/ws/bills/detail',
            state: '费用单详情',
        },
        {
            path: '/ws/keepAccountsList/keep',
            state: '记账',
        },
        {
            path: '/ws/balance/recharge',
            state: '充值',
        },
        {
            path: '/ws/balance/transferAccounts',
            state: '转账',
        },
        {
            path: '/ws/statementDownload',
            state: '对账单下载',
        },
        {
            path: '/ws/statementList',
            state: '对账单明细',
        },
        {
            path: '/ws/transactionRecords',
            state: '交易记录',
        },
        {
            path: '/ws/keepAccountsList/list',
            state: '记账明细',
        },
        {
            path: '/ws/initialConfiguration/storeValue',
            state: '初始设置',
        },
        {
            path: '/ws/initialConfiguration/storeValue',
            state: '机构入驻',
        },
        {
            path: '/ws/tradeSetting/list',
            state: '交易设置',
        },
        {
            path: '/ws/account/management',
            state: '账户管理',
        },
        {
            path: '/ws/bankCard',
            state: '银行卡管理',
        },
        {
            path: '/ws/exportList',
            state: '导出列表',
        },
        {
            path: '/ws/employeeManagement',
            state: '员工列表',
        },
        {
            path: '/ws/Import',
            state: '导入列表',
        },
        {
            path: '/ws/Import/detail',
            state: '导入详情',
        },
        {
            path: '/ws/modeSettings',
            state: '模式设置',
        },
        {
            path: '/ws/balance/settlementRecords',
            state: '结算记录',
        },
        {
            path: '/ws/balance/settlementRecords/settlementDetail',
            state: '结算记录详情',
        },
        {
            path: '/ws/balance/availableAndAvailable',
            state: '不可用明细',
        },
        {
            path: '/ws/creditAccount/historyBill',
            state: '账单记录',
        },
        {
            path: '/ws/creditAccount/billingDetails',
            state: '账单记录详情',
        },
        {
            path: '/ws/advanceRepayment',
            state: '垫付还款',
        },
        {
            path: '/ws/withdrawalSettings',
            state: '提现设置',
        },
    ]
    useEffect(() => {
        console.log(pathname);

        if (localStorage.appid === localStorage.merchantCode) {
            setState(true)
        } else {
            setState(false)
        }

    }, [state])
    useEffect(() => {
        getbReadcrumb()
    }, [pathname])
    const getbReadcrumb = () => {
        let findIndex = breadcrumbList.findIndex((item, index) => { return item.path == pathname })
        let navIndex = nav.length > 0 ? nav.findIndex((item) => { return item.path == pathname }) : -1
        if (findIndex != -1) {
            console.log(11111)
            // setNav(nav.length >= 3 ? nav.concat([breadcrumbList[findIndex]]).splice(-3) : nav.concat([breadcrumbList[findIndex]]))
            setNav([breadcrumbList[findIndex]])
        }
    }
    const items = [
        {
            label: <Link to="/ws/platformKanban/assetOverview">资产总览</Link>,
            key: 1001,

        },
        {
            label: "我的账户",
            key: 1010,
            children: [
                {
                    label: <Link to={state ? "/ws/platformKanban/availableFunds" : "/ws/balance/availableBalance"}>店铺余额</Link>,
                    key: 10025,
                },
                {
                    label: <Link to={state ? "/ws/platformKanban/purseMoney" : "/ws/balance/walletBalance"}>钱包余额</Link>,
                    key: 10028,
                },
                // {
                //     label: <Link to={"/ws/platformKanban/marketing"}>营销余额</Link>,
                //     key: 10030,
                // },
                {
                    label: <Link to={state ? "/ws/platformKanban/creditFunds" : "/ws/creditAccount/credit"}>授信余额</Link>,
                    key: 10026,
                },
                {
                    label: <Link to={state ? "/ws/platformKanban/earnings" : "/ws/revenueAccount"}>收益余额</Link>,
                    key: 10027,
                },


            ]
        },

        {
            label: "资金处理",
            key: 1002,
            children: [
                {
                    label: <Link to="/ws/balance/withdrawal">提现</Link>,
                    key: 10006,
                },
                {
                    label: <Link to="/ws/bills/list">费用单</Link>,
                    key: 10007,
                },
                {
                    label: <Link to="/ws/cost/list">费用单2.0</Link>,
                    key: 10031,
                },
                {
                    label: <Link to="/ws/keepAccountsList/keep">记账</Link>,
                    key: 10018,
                },
                {
                    label: <Link to="/ws/balance/recharge">充值</Link>,
                    key: 10019,
                },
                {
                    label: <Link to="/ws/balance/transferAccounts">转账</Link>,
                    key: 10029,
                },
                {
                    label: <Link to="/ws/advanceRepayment">垫付还款</Link>,
                    key: 10032,
                },

            ]
        },
        {
            label: '资金对账',
            key: 1003,
            children: [
                {
                    label: <Link to="/ws/statementDownload">对账单下载</Link>,
                    key: 10008,
                },
                {
                    label: <Link to="/ws/statementList">对账单明细</Link>,
                    key: 10009,
                },
                {
                    label: <Link to="/ws/transactionRecords">交易记录</Link>,
                    key: 10010,
                },
                {
                    label: <Link to="/ws/keepAccountsList/list">记账明细</Link>,
                    key: 10017,
                },
            ]
        },
        {
            label: '资金设置',
            key: 1004,
            children: [
                {
                    label: <Link to="/ws/initialConfiguration/storeValue">初始设置</Link>,
                    key: 10011,
                },
                {
                    label: <Link to="/ws/institutionsIn/list" > 机构入驻</Link >,
                    key: 10012,
                },
                {
                    label: <Link to="/ws/tradeSetting/list">交易设置</Link>,
                    key: 10016,
                },
                {
                    label: <Link to="/ws/account/management">账户管理</Link>,
                    key: 10020,
                },
                {
                    label: <Link to="/ws/bankCard">银行卡管理</Link>,
                    key: 10021,
                },
                {
                    label: <Link to="/ws/withdrawalSettings">提现设置</Link>,
                    key: 10022,
                },
                
            ]
        },
        {
            label: <Link to="/ws/managementFee">管理费</Link>,
            key: 1005,
        },
        {
            label: <Link to="/ws/managementFeeList">管理费订单</Link>,
            key: 1006,
        },
        {
            label: <Link to="/ws/exportList">导出列表</Link>,
            key: 1007,
        },
        {
            label: <Link to="/ws/employeeManagement">员工管理</Link>,
            key: 1008,
        },
        {
            label: <Link to="/ws/Import">导入列表</Link>,
            key: 1009,
        },

        /**{
            label: <Link to="/ws/modeSettings">模式设置</Link>,
            key: 1011,
        },**/
    ];

    const items1 = [

        // {
        //     label: "我的账户",
        //     key: 100001,
        //     children: [
        //         {
        //             label: <Link to={"/ws/storeMarketing"}>营销余额</Link>,
        //             key: 1000001,
        //         },


        //     ]
        // },

        {
            label: "资金处理",
            key: 100002,
            children: [
                {
                    label: <Link to="/ws/balance/withdrawal">提现</Link>,
                    key: 1000002,
                },

            ]
        },
        {
            label: '资金对账',
            key: 100003,
            children: [
                {
                    label: <Link to="/ws/statementList">对账单明细</Link>,
                    key: 1000003,
                },
            ]
        },


        {
            label: <Link to="/ws/employeeManagement">员工管理</Link>,
            key: 100004,
        },

        {
            label: <Link to="/ws/bankCard">银行卡管理</Link>,
            key: 10021,
        },
    ];
    console.log('state:', state)
    if (!state) {
        items.map((v, index) => {
            if (v.key == 1004 || v.key == 1009) {
                delete items[index];
            }
            v.children && v.children.map((x, i) => {
                if (x.key == 10027 || x.key == 10031) {
                    delete v.children[i]
                }

            })
        })
    }
    if (localStorage.appid != 'EW_N7733887598') {
        items.map((v, index) => {
            if (v.key == 1005 || v.key == 1006) {
                delete items[index];
            }
        })
    }
    if (localStorage.appid == 'EW_N0518400645') {
        items.map((v, index) => {
            v.children && v.children.map((x, i) => {
                if (x.key == 10031) {
                    delete v.children[i]
                }
            })
        })
    } else {
        items.map((v, index) => {
            v.children && v.children.map((x, i) => {
                if (x.key == 10007) {
                    delete v.children[i]
                }
            })
        })
    }
    return (
        <Layout
            style={{
                minHeight: '100vh',
                color: 'ffffff'
            }}
        >
            <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                <div className="logo" />

                <Menu style={{ colro: '#ffffff' }} theme="dark" defaultSelectedKeys={['1']} mode="inline" items={localStorage.salesEmployeeId == 'null' ? items : items1}
                    onClick={(e) => {

                    }}
                />

            </Sider>
            <Layout className="site-layout">
                <Header
                    className="site-layout-background"
                    style={{
                        padding: 0,
                        textAlign: 'right',
                        paddingRight: 20
                    }}
                >
                    <Button type="primary"
                        onClick={() => {
                            localStorage.removeItem('state')
                            navigate('/login')
                        }}
                    >退出登录</Button>
                </Header>

                <Content
                    style={{
                        margin: '0 16px',
                    }}
                >
                    <Breadcrumb
                        style={{
                            margin: '16px 0',
                        }}
                    >
                        {
                            nav.length > 0 && nav.map((v, index) => {
                                return <Breadcrumb.Item key={index}>{v.state}</Breadcrumb.Item>
                            })
                        }
                    </Breadcrumb>

                    <Outlet />
                </Content>
                {/* <Footer
                    style={{
                        textAlign: 'center',
                    }}
                >
                    神奇的资金分账平台
                </Footer> */}
            </Layout>
        </Layout>
    );
};

export default Home;