import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import NP from 'number-precision'
import moment from 'moment';
import {
    Form,
    Row,
    Col,
    Button,
    Input,
    Select,
    Table,
    Popconfirm,
    DatePicker,
    message,
    Modal,
    Tabs,
    Radio
} from 'antd';
import { accountLogListRequest, settleMerchantListRequest, settleBillListRequest, b2bSettleBillListRequest } from '../../../api/api'
import { useCallbackState } from '../../../util/useCallbackState'
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
};

const CreditBalance = () => {
    const { state } = useLocation();
    const { search } = useLocation()
    const [form] = Form.useForm();
    const [total, setTotal] = useState()
    const [list, setList] = useState();
    const [lists, setLists] = useState();
    const [merchantList, setMerchantList] = useState([])
    const [merchantCode, setMerchantCode] = useState(state && state.merchantCode ? state.merchantCode : null)
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [init, setInit] = useState(true)
    const [mySwitch, setMySwitch] = useState(localStorage.state == "1" ? true : false)
    const [accountType, setAccountType] = useState(0)
    const [billType, setBillType] = useState(state && state.billType ? state.billType.toString() : 9)
    const navigate = useNavigate()
    const columns = [
        {
            title: '商户',
            dataIndex: 'merchantName',
            key: 'merchantName',
        },
        {
            title: '账单日',
            dataIndex: 'settleDate',
            key: 'settleDate',
            width: 250,
            render: (text, row) => {
                return (
                    <>
                        {
                            billType == 4 ?
                                <div>{row.startDate && row.startDate.substring(0, 10)}~{row.endDate && row.endDate.substring(0, 10)}</div>
                                :
                                <div>{row.startDate && row.startDate.substring(0, 10)}</div>
                        }
                    </>

                )
            }
        },
        {
            title: '结算单号',
            dataIndex: 'settleBillNo',
            key: 'settleBillNo',
            width: 250,
            render: text => (
                <div>
                    {text}
                </div>
            ),
        },
        {
            title: '实收金额(元)',
            dataIndex: 'totalReceiptAmount',
            key: 'totalReceiptAmount',
            render: text => (
                <div>
                    {text}
                </div>
            ),
        },
        
        {
            title: '手续费(元)',
            dataIndex: 'feeTradeAmount',
            key: 'feeTradeAmount',
            render: (text, row) => (
                <div>
                    {NP.minus(row.feeTradeAmount, row.refundFeeAmount)}
                </div>
            ),
        },
        {
            title: '费用单金额',
            dataIndex: 'costFeeAmount',
            key: 'costFeeAmount',
        },
        {
            title: '结算金额(元)',
            dataIndex: 'totalSettleAmount',
            key: 'totalSettleAmount',
            render: (text, row) => (
                <div>
                    <span style={{ color: 'red', marginRight: 10 }}>{row.errorSettleStatus != 0 ? '轧差' : null}</span>
                    <span >{text}</span>
                </div >
            ),
        },
        {
            title: '分账金额',
            dataIndex: 'splitAmount',
            key: 'splitAmount',
        },
        {
            title: '结算日',
            dataIndex: 'settleDate',
            key: 'settleDate',
        },
        {
            title: '状态',
            dataIndex: 'billStatus',
            key: 'billStatus',
            render: (text, row) => (
                <div>
                    {row.billStatus == 0 ? '未结算' : ''}
                    {row.billStatus == 1 ? '已结算' : ''}
                    {row.billStatus == 2 ? '结算失败' : ''}
                </div>
            ),
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 100,
            align: 'right',
            render: (text, row) => (
                <>
                    <span style={{ color: '#165BD4', cursor: 'pointer' }} onClick={() => {
                        navigate('/ws/balance/settlementRecords/settlementDetail', { replace: false, state: { ...row, accountType } })
                    }}>详情</span>
                </>

            ),

        },
    ];
 
    const columns1 = [
        {
            title: '类型',
            dataIndex: 'busiTypeName',
            key: 'busiTypeName',
        },
        {
            title: '入账时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 200,
        },

        {
            title: '业务单号｜支付流水号',
            dataIndex: 'orderNo',
            key: 'orderNo',
            width: 250,
            render: (text, row) => (
                <div>
                    <div >{row.orderNo}</div>
                    <div style={{ color: '#969799' }}>{row.thirdTradeNo}</div>
                </div>
            ),
        },
        {
            title: '账户',
            dataIndex: 'accountType',
            key: 'accountType',
            render: (text, row) => {
                return (
                    <>
                        <div>
                            {row.accountType == 0 ? '店铺余额待结算' : ''}
                            {row.accountType == 1 ? '店铺余额' : ''}
                            {row.accountType == 2 ? '钱包余额' : ''}
                            {row.accountType == 4 ? '钱包余额待结算' : ''}
                            {row.accountType == 5 ? '收益余额' : ''}
                            {row.accountType == 6 ? '授信余额' : ''}
                            {row.accountType == 7 ? '营销余额待结算' : ''}
                            {row.accountType == 8 ? '营销余额' : ''}
                        </div>
                    </>

                )
            }
        },
        {
            title: '收支类型',
            dataIndex: 'address',
            key: '3',
            render: (text, row) => {
                return (
                    <div>{row.type == 0 ? '收入' : '支出'}</div>
                )
            }
        },
        {
            title: '金额(元)',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: '余额(元)',
            dataIndex: 'afCaBalance',
            key: 'afCaBalance',
        },
        {
            title: '支付方式',
            dataIndex: 'payType',
            key: 'payType',
            render: (text, row) => {
                return (
                    <div>
                        {row.payType == 0 ? '微信' : ''}
                        {row.payType == 1 ? '支付宝' : ''}
                        {row.payType == 2 ? '会员' : ''}
                        {row.payType == 3 ? '银行卡' : ''}
                        {row.payType == 4 ? '现金' : ''}
                        {row.payType == 5 ? '云闪付' : ''}
                        {row.payType == 6 ? '漕河泾(饿了么)' : ''}
                        {row.payType == 7 ? '组合支付' : ''}
                        {row.payType == 17 ? '翼支付' : ''}
                        {row.payType == 8 ? '余额支付' : ''}
                        {row.payType == 9 ? '授信支付' : ''}

                    </div>
                )
            },
        },
        {
            title: '收款方',
            dataIndex: 'payeeMerchantName',
            key: 'payeeMerchantName',
            width: 250,
        },
        {
            title: '付款方',
            dataIndex: 'payMerchantName',
            key: 'payMerchantName',
            width: 250,
        },
        {
            title: '交易流水状态',
            dataIndex: 'caTradeStatus',
            key: 'caTradeStatus',
            render: (text, row) => {
                return (
                    <div>
                        {row.caTradeStatus == 0 ? '处理中' : ''}
                        {row.caTradeStatus == 1 ? '已完成' : ''}
                        {row.caTradeStatus == 2 ? '失败' : ''}

                    </div>
                )
            }
        },
    ];
    const columns2 = [
        {
            title: '商户',
            dataIndex: 'merchantName',
            key: 'merchantName',
        },
        {
            title: '账单日',
            dataIndex: 'settleDate',
            key: 'settleDate',
            render: (text, row) => {
                return (
                    <>
                        {
                            billType == 4 ?
                                <div>{row.startDate && row.startDate.substring(0, 10)}~{row.endDate && row.endDate.substring(0, 10)}</div>
                                :
                                <div>{row.startDate && row.startDate.substring(0, 10)}</div>
                        }
                    </>

                )
            }
        },
        {
            title: '结算单号',
            dataIndex: 'settleBillNo',
            key: 'settleBillNo',
            width: 250,
            render: text => (
                <div>
                    {text}
                </div>
            ),
        },
        {
            title: '实收金额(元)',
            dataIndex: 'totalReceiptAmount',
            key: 'totalReceiptAmount',
            render: text => (
                <div>
                    {text}
                </div>
            ),
        },
        {
            title: '赠送金额(元)',
            dataIndex: 'memberGiveAmount',
            key: 'memberGiveAmount',
            render: text => (
                <div>
                    {text}
                </div>
            ),
        },
        {
            title: '手续费(元)',
            dataIndex: 'memberFeeAmount',
            key: 'memberFeeAmount',
            render: (text, row) => (
                <div>
                     {text}
                </div>
            ),
        },
        {
            title: '费用单金额',
            dataIndex: 'costFeeAmount',
            key: 'costFeeAmount',
        },
        {
            title: '结算金额(元)',
            dataIndex: 'totalSettleAmount',
            key: 'totalSettleAmount',
            render: (text, row) => (
                <div>
                    <span style={{ color: 'red', marginRight: 10 }}>{row.errorSettleStatus != 0 ? '轧差' : null}</span>
                    <span >{text}</span>
                </div >
            ),
        },
        {
            title: '分账金额',
            dataIndex: 'splitAmount',
            key: 'splitAmount',
        },
        {
            title: '结算日',
            dataIndex: 'settleDate',
            key: 'settleDate',
        },
        {
            title: '状态',
            dataIndex: 'billStatus',
            key: 'billStatus',
            render: (text, row) => (
                <div>
                    {row.billStatus == 0 ? '未结算' : ''}
                    {row.billStatus == 1 ? '已结算' : ''}
                    {row.billStatus == 2 ? '结算失败' : ''}
                </div>
            ),
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 100,
            align: 'right',
            render: (text, row) => (
                <>
                    <span style={{ color: '#165BD4', cursor: 'pointer' }} onClick={() => {
                        navigate('/ws/balance/settlementRecords/settlementDetail', { replace: false, state: { ...row, accountType } })
                    }}>详情</span>
                </>

            ),

        },
    ];

    useEffect(() => {
        console.log("11"+{state});
        setAccountType(state && state.accountType)
        if (init) {
            settleMerchantList()
        }
        if (billType == '9') {
            b2bSettleBillList()
        } else {
            settleBillList()
        }
    }, [currentPage, pageNum, mySwitch])
    useEffect(() => {
        console.log(billType);
    }, [billType])
    const onFinish = (values) => {
        console.log(values);
        values.startTime = form.getFieldValue('date') && form.getFieldValue('date')[0].format('YYYY-MM-DD HH:mm:ss') ? form.getFieldValue('date')[0].format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss');
        values.endTime = form.getFieldValue('date') && form.getFieldValue('date')[1].format('YYYY-MM-DD HH:mm:ss') ? form.getFieldValue('date')[1].format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss');
        if (billType == '9') {
            accountLogList(values)
        } else {
            settleBillList(values)
        }
    };
    const settleMerchantList = () => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode: localStorage.merchantCode,
                superMerchantCode: localStorage.appid
            });
        })().then(res => {
            if (res.code == 0) {
                setInit(false)
                setMerchantList(res.data)
            } else {

            }
        });
    }
    const accountLogList = (data) => {
        let values = form.getFieldValue();
        (async _ => {
            return await accountLogListRequest({
                ...data,
                ...values,
                accountType: state.accountType,
                busiTypes: [8, 9, 33, 34, 35, 36, 37, 25],
                merchantCode: localStorage.hxState == 'true' ? merchantCode : localStorage.merchantCode,
                superMerchantCode: localStorage.appid,
                currentPage: currentPage,
                pageSize: pageNum,
            });
        })().then(res => {
            if (res.code == 0) {
                setLists(res.data.lists)
                setTotal(res.data.totalCount)
            } else {
                message.error(res.msg)
            }
        });
    }
    const settleBillList = (data) => {
        console.log(data);
        let values = form.getFieldValue()
        let params = {
            ...data,
            ...values,
            currentPage: currentPage,
            pageSize: pageNum,
            merchantCode: localStorage.hxState == 'true' ? merchantCode : localStorage.merchantCode,
            superMerchantCode: localStorage.appid,
            billType: data && data.billType ? data.billType : billType,
        };
        (async _ => {
            return await settleBillListRequest(params);
        })().then(res => {
            if (res.code == 0) {
                setList(res.data.orderList)
                setTotal(res.data.totalCount)
            } else {
                message.error(res.msg)
            }
        });
    }
    const b2bSettleBillList = (data) => {
        console.log(data);
        let values = form.getFieldValue()
        let params = {
            ...data,
            ...values,
            merchantCode: localStorage.hxState == 'true' ? merchantCode : localStorage.merchantCode,
            superMerchantCode: localStorage.appid,
            currentPage: currentPage,
            pageSize: pageNum,
        };
        (async _ => {
            return await b2bSettleBillListRequest(params);
        })().then(res => {
            if (res.code == 0) {
                setLists(res.data.lists)
                setTotal(res.data.totalCount)
            } else {
                message.error(res.msg)
            }
        });
    }

    const onChangPage = (currentPage, pageNum) => {
        setCurrentPage(currentPage, () => {
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        setPageNum((currentPage, pageNum), () => {
        })
    }
    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>

            <Form
                {...layout}
                form={form}
                name="advanced_search"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{
                    billStatus: null,
                    merchantCode: state && state.merchantCode ? state.merchantCode : null

                }}
                style={{ background: '#F7F8FA', padding: 20 }}
            >

                <Row style={{ paddingTop: 20 }}>
                    {
                        localStorage.hxState == 'true' ?
                            <Col span={8}>
                                <Form.Item
                                    name="merchantCode"
                                    label="商户名称："
                                    rules={[{ required: false, message: 'Please input your username!' }]}
                                >
                                    <Select placeholder="请选择"
                                        allowClear
                                        style={{ width: 200 }}
                                        onChange={(code) => {
                                            setMerchantCode(code)
                                        }}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.includes(input)}

                                    >
                                        <Option key={null} value={null}>全部</Option>
                                        {
                                            merchantList && merchantList.map((v) => {
                                                return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                            })
                                        }

                                    </Select>
                                </Form.Item>
                            </Col> :
                            <Col span={8}>
                                <Form.Item
                                    label="商户名称："
                                    rules={[{ required: false, message: 'Please input your username!' }]}
                                >
                                    {localStorage.merchantName}
                                </Form.Item>
                            </Col>

                    }
                    {
                        billType != 9 ?
                            <Col span={8}>
                                <Form.Item
                                    name="billStatus"
                                    label="单据状态："

                                >
                                    <Select style={{ width: 200 }}>
                                        <Option value={null}>全部</Option>
                                        <Option value="0">未结算</Option>
                                        <Option value="1">已结算</Option>
                                        <Option value="2">结算失败</Option>
                                    </Select>
                                </Form.Item>
                            </Col> : null
                    }
                    <Col span={8}>
                        <Form.Item
                            name="date"
                            label="账期："

                        >
                            <RangePicker style={{ width: 250 }} />
                            {/* <DatePicker style={{ width: 250 }} /> */}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item
                            wrapperCol={{
                                offset: 5,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                筛选
                            </Button>
                            <Button type="link" onClick={() => {
                                form.setFieldsValue({
                                    billStatus: null,
                                    merchantCode: null,
                                    date: undefined,
                                });
                                setMerchantCode(null)
                            }} >
                                重置筛选条件
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {
                state && state.accountType == 4 ?
                    <Tabs style={{ marginTop: 16 }} activeKey={billType.toString()} onChange={(e) => {
                        console.log(e);
                        if (e == '9') {
                            b2bSettleBillList()
                        } else {
                            settleBillList({ billType: e })
                        }
                        setBillType(e);
                    }} type="card">
                        <TabPane tab="抖音结算" key='3'>
                        </TabPane>
                        <TabPane tab="美团结算" key='4'>
                        </TabPane>
                        <TabPane tab="饿了么结算" key='5'>
                        </TabPane>
                        <TabPane tab="收单结算" key='6'>
                        </TabPane>
                        <TabPane tab="会员结算" key='7'>
                        </TabPane>
                        {/* <TabPane tab="采购结算" key='9'>
                        </TabPane> */}
                    </Tabs>
                    : null
            }
            {
                billType == 9 ?
                    <Table
                        locale={{
                            emptyText: "没有更多数据了"
                        }}
                        columns={columns1}
                        dataSource={lists}
                        rowKey='id'
                        scroll={{
                            x: 2000,
                        }}
                        pagination={{
                            total,
                            showSizeChanger: true,
                            current: currentPage,
                            pageSize: pageNum,
                            showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                            onChange: (k, v) => onChangPage(k, v),
                            onShowSizeChange: (k, v) => onChangPageNum(k, v),
                        }}
                    />
                    :
                    billType==7
                     ?
                     <Table
                     locale={{
                         emptyText: "没有更多数据了"
                     }}
                     columns={columns2}
                     dataSource={list}
                     rowKey='id'
                     scroll={{
                         x: 1600,
                     }}
                     pagination={{
                         total,
                         showSizeChanger: true,
                         current: currentPage,
                         pageSize: pageNum,
                         showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                         onChange: (k, v) => onChangPage(k, v),
                         onShowSizeChange: (k, v) => onChangPageNum(k, v),
                     }}
                 />:
                    <Table
                        locale={{
                            emptyText: "没有更多数据了"
                        }}
                        columns={columns}
                        dataSource={list}
                        rowKey='id'
                        scroll={{
                            x: 1600,
                        }}
                        pagination={{
                            total,
                            showSizeChanger: true,
                            current: currentPage,
                            pageSize: pageNum,
                            showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                            onChange: (k, v) => onChangPage(k, v),
                            onShowSizeChange: (k, v) => onChangPageNum(k, v),
                        }}
                    />
            }


        </div >
    )
}
export default CreditBalance;