import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment'
import QRCode from 'qrcode.react';
import './style.less'
import {
    Form,
    Row,
    Col,
    Button,
    Table,
    DatePicker,
    Input,
    Select,
    message,
    Popconfirm,
    Tabs,
    Radio,
    Steps,
    Popover,
    Modal
} from 'antd';
import {
    appletRegisterQueryRequest,
    merchQueryAndOpenPayRequest,
    refushPaySignRequest,
    openRepairRequest,
    wsbankMerchantDetailRequest,
} from '../../../api/api'
const { Step } = Steps;
const { Option } = Select;
const steps = [
    {
        title: '1.编辑基本信息',
        content: 'First-content',
    },
    {
        title: '2.编辑认证信息',
        content: 'Second-content',
    },
];
const LnstitutionsInList = () => {
    const [current, setCurrent] = useState(0);
    const [status, setStatus] = useState(null)
    const [step, setStep] = useState(1);
    const [wsIsvOrgId, setWsIsvOrgId] = useState('')
    const [outMchId, setOutMchId] = useState('')
    const [qrcode, setQrcode] = useState(undefined)
    const [data, setData] = useState(null)
    const [date, setDate] = useState(null)
    const superMerchantCode = localStorage.appid
    const navigate = useNavigate();
    const { state } = useLocation()
    const merchantCode = state && state.merchantCode


    const onFinish = () => {
    }
    useEffect(() => {
        getWsbankMerchantDetail({ merchantCode: state.merchantCode })
        appletRegisterQuery()
    }, [])

    const on = () => {
        setStep(1)
        setCurrent(0)
    }

    const getWsbankMerchantDetail = (data) => {
        (async _ => {
            return await wsbankMerchantDetailRequest(
                {
                    ...data,
                }
            );
        })().then(res => {
            if (res.code == 0) {
                setData(res.data)
                setOutMchId(res.data.outMerchantId)
                setQrcode(`alipays://platformapi/startapp?appId=2021001187624111&nbupdate=syncforce&query=outMchId%3D${res.data.outMerchantId}%26isv%3D${res.data.isvOrgId}%26app%3Dbkclfs%26t%3DREGISTER%26`)
            } else {
                message.error(res.msg)
            }
        });
    }
    const merchQueryAndOpenPay = (data) => {
        (async _ => {
            return await merchQueryAndOpenPayRequest(
                {
                    ...data,
                }
            );
        })().then(res => {
            if (res.code == 0) {
                getWsbankMerchantDetail({ merchantCode: merchantCode })
            } else {
                message.error(res.msg)
            }
        });
    }

    const next = () => {
        appletRegisterQuery()
    }
    const appletRegisterQuery = (data) => {
        (async _ => {
            return await appletRegisterQueryRequest({ merchantCode: state.merchantCode, superMerchantCode });
        })().then(res => {
            if (res.code == 0) {
                // setOutMchId(res.data) 
                if (res.data == 1) {
                    setStep(2)
                    setCurrent(1)
                    getWsbankMerchantDetail({ merchantCode: merchantCode })
                } else {
                    message.error('没有注册成功，请完成注册')
                }
                setStatus(res.data)
            } else {
                message.error(res.msg)
            }
        });
    }
    const open = (data) => {

        (async _ => {
            return await refushPaySignRequest(
                {
                    ...data,
                    // superMerchantCode: "EW_N5464054266",
                }
            );
        })().then(res => {
            if (res.code == 0) {
                setMianqian(true)
                getWsbankMerchantDetail({ merchantCode: merchantCode })
            } else {
                message.error(res.msg)
            }
        });
    }
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [mianqian, setMianqian] = useState(false)
    const openRepair = (data) => {
        (async _ => {
            return await openRepairRequest(
                {
                    ...data,
                    merchantCode: merchantCode,
                    superMerchantCode,
                }
            );
        })().then(res => {
            if (res.code == 0) {
                // setOutMchId(res.data) 
                // setStatus(res.data)
                getWsbankMerchantDetail({ merchantCode: merchantCode })
            } else {
                message.error(res.msg)
            }
        });
    }
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        openRepair({ openRepairTime: date ? date : data && data.openRepairTime })
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const mianqianHandleCancel = () => {
        setMianqian(false);
    };
    const dateChange = (date) => {
        setDate(date.format('YYYY-MM-DD HH:mm:ss'))
    }
    const onOk = (value) => {
    };
    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            <Steps current={current}
                style={{ width: 600, marginLeft: 20 }}
            >
                {steps.map(item => (
                    <Step key={item.title} title={item.title} />
                ))}
            </Steps>
            {
                step == 1 ?
                    <div>
                        <Form
                            name="basic"
                            labelCol={{
                                span: 4,
                            }}
                            wrapperCol={{
                                span: 6,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <div style={{ width: '100%', marginLeft: 20, marginTop: 20, marginBottom: 20 }} className='borderBottom'>基本信息</div>

                            <Form.Item
                                label="入驻机构："
                                name="gongyao"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please input your password!',
                                    },
                                ]}
                            >
                                {state && state.merchantName}
                            </Form.Item>
                            {/* <Form.Item label="分账银行" name="yinhang">
                                <Radio.Group>
                                    <Radio value="apple"> 网商银行 </Radio>
                                </Radio.Group>
                            </Form.Item> */}



                            <div style={{ width: '100%', marginTop: 20, marginLeft: 20, marginBottom: 20 }} className='borderBottom'>入驻信息</div>
                            <Form.Item label="支付宝扫码" >
                                {
                                    qrcode ?
                                        <QRCode
                                            value={qrcode} //value参数为字符串类型
                                            size={200} //二维码的宽高尺寸
                                            fgColor="#000000"  //二维码的颜色
                                        /> : ''
                                }

                            </Form.Item>
                            <Form.Item label="注册状态：" >
                                {/* {status && status == null ? '--' : '--'} */}
                                {status && status == 1 ? '成功' : ''}
                                {status && status == 2 ? '失败' : ''}
                                {status && status == 3 ? '处理中' : ''}
                            </Form.Item>
                            {/* <Form.Item label="描述：">
                                --
                            </Form.Item> */}

                            <Row>
                                <Col span={12} style={{ textAlign: 'center' }}>
                                    <Button type="primary"
                                        onClick={next}>下一步</Button>
                                </Col>
                            </Row>
                        </Form>
                    </div> :
                    <div>
                        <Form
                            name="basic"
                            labelCol={{
                                span: 4,
                            }}
                            wrapperCol={{
                                span: 6,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <div style={{ width: '100%', marginLeft: 20, marginTop: 20, marginBottom: 20 }} className='borderBottom'>基本信息</div>
                            <Form.Item
                                label="申请单号："
                                name="gongyao"
                            >
                                {data && data.orderNo}
                            </Form.Item>
                            <Form.Item label="余额支付：">
                                {data && data.balancePayStatus == 1 ? '已设置' : ''}
                                {data && data.balancePayStatus == 0 ?
                                    <div>
                                        <span >未设置</span>
                                        <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }}
                                            onClick={() => {
                                                merchQueryAndOpenPay({ outMerchantId: outMchId, superMerchantCode, wsMerchantId: data && data.wsMerchantId })
                                            }}>设置</span>
                                    </div> : ''}
                                {data && data.balancePayStatus == 2 ?
                                    <div>
                                        <span style={{ color: "#C00000" }}>失败</span>
                                        <span style={{ color: "#165BD4", cursor: 'pointer', marginLeft: 10 }} onClick={() => {
                                            merchQueryAndOpenPay({ outMerchantId: outMchId, superMerchantCode, wsMerchantId: data && data.wsMerchantId })
                                        }}>重试</span> </div> : ''}
                            </Form.Item>
                            <Form.Item label="余额支付免短信：">
                                {data && data.balanceNoSmsStatus == 1 ? '已设置' : ''}
                                {data && data.balanceNoSmsStatus == 0 ?
                                    <div>
                                        <span >未设置</span>
                                        <span style={{ color: "#165BD4", cursor: 'pointer', marginLeft: 10 }}
                                            onClick={() => {
                                                open({ merchantCode: merchantCode, superMerchantCode, })
                                            }}>设置</span>
                                    </div> : ''}
                                {data && data.balanceNoSmsStatus == 2 ?
                                    <div>
                                        <span style={{ color: "#C00000" }}>失败</span>
                                        <span style={{ color: "#165BD4", cursor: 'pointer', marginLeft: 10 }}
                                            onClick={() => {
                                                open({ merchantCode: merchantCode, superMerchantCode, })
                                            }}>重试</span>
                                    </div> : ''}
                                {data && data.balanceNoSmsStatus == 3 ?
                                    <div>
                                        <span>处理中</span>
                                        <span style={{ color: "#165BD4", cursor: 'pointer', marginLeft: 10 }}
                                            onClick={() => {
                                                open({ merchantCode: merchantCode, superMerchantCode, })
                                            }}>重试</span>
                                    </div> : ''}
                            </Form.Item>
                            <Form.Item label="开始补单时间：">
                                {
                                    data && data.openRepairTime ?
                                        <div>
                                            <span>{data && data.openRepairTime}</span>
                                            <span style={{ color: "#165BD4", cursor: 'pointer', marginLeft: 10 }}
                                                onClick={() => {
                                                    showModal()
                                                }}>修改</span>
                                        </div>
                                        :
                                        <div>
                                            <span>未设置</span>
                                            <span style={{ color: "#165BD4", cursor: 'pointer', marginLeft: 10 }}
                                                onClick={() => {
                                                    showModal()
                                                }}>设置</span>
                                        </div>
                                }

                            </Form.Item>
                            {/* <Form.Item
                                wrapperCol={{
                                    offset: 4,
                                    span: 16,
                                }}
                            >
                                <span style={{ color: '#969799', fontSize: 12 }}>一天只能修改一次，请谨慎修改 未结算数据会在次日结算</span>
                            </Form.Item> */}

                            <div style={{ width: '100%', marginTop: 20, marginLeft: 20, marginBottom: 20 }} className='borderBottom'>创建信息</div>
                            <Form.Item label="创建人：">
                                {data && data.operator ? data.operator : localStorage.operatorname ? localStorage.operatorname : undefined}
                            </Form.Item>
                            <Form.Item label="创建时间：">
                                {data && data.createTime ? data.createTime : moment().format('YYYY-MM-DD HH:mm:ss')}
                            </Form.Item>
                            <Row>
                                <Col span={8} style={{ textAlign: 'center' }}>
                                    {
                                        status != 1 ?
                                            <Button onClick={on}>上一步</Button> : ''
                                    }

                                    <Button type="primary" style={{ marginLeft: 20 }}
                                        disabled={!(data && data.openRepairTime && data.balancePayStatus == 1 && data.balanceNoSmsStatus == 1)}
                                        onClick={() => {
                                            navigate('/ws/institutionsIn/list')
                                        }}
                                    >完成</Button>
                                </Col>
                            </Row>
                        </Form>
                        <Modal
                            width={300}
                            title="设置开始补单时间" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                            <DatePicker showTime
                                defaultValue={data && data.openRepairTime && moment(data && data.openRepairTime, 'YYYY-MM-DD HH:mm:ss')} format={'YYYY-MM-DD HH:mm:ss'}
                                onChange={dateChange}
                                onOk={onOk} />
                            {/* <DatePicker
                                showTime
                                style={{ width: '100%' }} onChange={dateChange} /> */}
                        </Modal>
                        <Modal
                            width={300}
                            title="请用支付宝扫描二维码" visible={mianqian} onCancel={mianqianHandleCancel}
                            footer={null}
                        >

                            <Row justify='center'>
                                <QRCode
                                    value={data && data.backUrl} //value参数为字符串类型
                                    size={200} //二维码的宽高尺寸
                                    fgColor="#000000"  //二维码的颜色
                                />
                            </Row>

                        </Modal>
                    </div>
            }



        </div >
    )
}

export default LnstitutionsInList;