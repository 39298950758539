import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import {
    Form,
    Input,
    Switch,
    Row,
    Col,
    Button,
    Tabs,
    Table,
    Modal,
    Radio,
    Space,
    DatePicker,
    message,
    Select,
} from 'antd';
import { statisticsDayListRequest, settleMerchantListRequest, statisticsDayListExportRequest, getConstantsRequest } from '../../api/api'
import { useCallbackState } from '../../util/useCallbackState'
import StatementDetails from './statementDetails/index'
import ExportList from '../../components/exportList'
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const monthFormat = 'YYYY-MM';
const yearFormat = 'YYYY'
const FormItem = Form.Item;
const Search = Input.Search;



const StatementDownload = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [row, setRow] = useState(null)
    const [radioValue, setRadioValue] = useState(1);
    const [list, setList] = useState([])
    const [merchantList, setMerchantList] = useState([])
    const [merchantCode, setMerchantCode] = useState(localStorage.hxState == 'true' ? null : localStorage.merchantCode)
    const [accountType, setAccountType] = useState('1')
    const [checked, setChecked] = useCallbackState('1')
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [init, setInit] = useState(true)
    const [date, setDate] = useState(null)
    const [total, setTotal] = useState()
    const [exportVisible, setExportVisible] = useState(false);
    const [accountTypeList, setAccountTypeList] = useState([])
    const { search } = useLocation()
    const { state } = useLocation()

    useEffect(() => {
        if (init) {
            settleMerchantList();
        }
        getConstants()
        getStatisticsDayList({ accountType: 1, statisticsType: 1 });
    }, [currentPage, pageNum, checked])
    const getConstants = () => {
        (async _ => {
            return await getConstantsRequest({
                constantType: "FD_ACCOUNT"
            });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setAccountTypeList(res.data.items)
            } else {
            }
        });
    }

    const onChange = (checked) => {
        setChecked(checked, () => {
            if (checked == 2) {
                form.setFieldsValue({
                    month: [moment(moment().month(0).format("YYYY-MM"), monthFormat), moment(moment().format("YYYY-MM"), monthFormat)]
                })
            }
            if (checked == 3) {
                form.setFieldsValue({
                    years: moment(moment().format("YYYY"), yearFormat)
                })
            }

        })
    };

    const onChangPage = (currentPage, pageNum) => {
        setCurrentPage(currentPage, () => {
            // getStatisticsDayList()
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        setPageNum((currentPage, pageNum), () => {
            // getStatisticsDayList()
        })
    }
    const settleMerchantList = () => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            if (res.code == 0) {
                setMerchantList(res.data)
                setInit(false)
                // getStatisticsDayList({
                //     accountType: 1, statisticsType: 1,

                // })
            } else {

            }
        });
    }
    const columns = [
        {
            title: '店铺名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
        },
        {
            title: '日期',
            dataIndex: 'statisticsDate',
            key: 'statisticsDate',
            render: (text, row) => {
                return (
                    <div>{row.statisticsDate.substring(0, 10)}</div>
                )
            }
        },
        {
            title: '账户',
            dataIndex: 'accountType',
            key: 'accountType',
            render: (text, row) => {
                return (
                    <div>
                        {row.accountType == 0 ? '店铺余额待结算' : ''}
                        {row.accountType == 1 ? '店铺余额' : ''}
                        {row.accountType == 2 ? '钱包余额' : ''}
                        {row.accountType == 4 ? '钱包余额待结算' : ''}
                        {row.accountType == 5 ? '收益余额' : ''}
                        {row.accountType == 6 ? '授信余额' : ''}
                        {row.accountType == 7 ? '营销余额待结算' : ''}
                        {row.accountType == 8 ? '营销余额' : ''}
                    </div>
                )
            }
        },
        {
            title: '期初余额(元)',
            dataIndex: 'beginningBalance',
            key: 'beginningBalance',
            render: (text, row) => (
                <div>
                    <div>{row.beginningBalance}</div>
                </div>
            )
        },
        {
            title: '收入(元)',
            dataIndex: 'totalIncomeAmount',
            key: 'totalIncomeAmount',
            render: (text, row) => (
                <div>
                    <div style={{ color: '#0CB31F' }}>+{row.totalIncomeAmount}</div>
                    <div>{row.totalIncomeCount}笔</div>
                </div>
            )
        },
        {
            title: '支出(元)',
            key: 'totalOutAmout',
            dataIndex: 'totalOutAmout',
            render: (text, row) => (
                <div>
                    <div style={{ color: '#DF0303' }}>-{row.totalOutAmout}</div>
                    <div>{row.totalOutCount}笔</div>
                </div>
            )
        },
        {
            title: '期末余额(元)',
            dataIndex: 'endingBalance',
            key: 'endingBalance',
            render: (text, row) => (
                <div>
                    <div>{row.endingBalance}</div>
                </div>
            )
        },
        {
            title: '操作',
            key: '',
            dataIndex: '',
            align: 'right',
            render: (text, row) => {
                return (
                    <div>
                        {/* <Button type="link" onClick={() => {
                            navigate('/ws/statementDownload/statementDetails', {
                                replace: false, state: {
                                    id: row.id,
                                    startTime: row.statisticsDate,
                                    merchantCode,
                                    superMerchantCode: localStorage.appid,
                                    statisticsType: checked,
                                    accountType: row.accountType.toString(),
                                }
                            })
                        }}>详情</Button> */}
                        <span style={{ color: '#165BD4', cursor: 'pointer' }}
                            onClick={() => {
                                setIsModalVisible(true)
                                setRow({
                                    id: row.id,
                                    startTime: row.statisticsDate,
                                    merchantCode: row.merchantCode,
                                    superMerchantCode: localStorage.appid,
                                    statisticsType: checked,
                                    accountType: row.accountType.toString(),
                                })
                            }}
                        >详情</span>
                        {/* <Button type='link'>下载报表</Button>
                        <Link to="">查看报表</Link> */}
                    </div>
                )
            }
        },
    ];
    const getStatisticsDayList = (data) => {
        let startTime = moment().format('YYYY-MM-DD HH:mm:ss');
        let endTime = moment().format('YYYY-MM-DD HH:mm:ss');
        if (checked == 1) {
            startTime = form.getFieldValue('date')[0].format(dateFormat)
            endTime = form.getFieldValue('date')[1].format(dateFormat)
        } else if (checked == 2) {
            startTime = form.getFieldValue('month')[0].format(monthFormat)
            endTime = form.getFieldValue('month')[1].format(monthFormat)
        } else {
            startTime = form.getFieldValue('years').format(yearFormat)
            endTime = form.getFieldValue('years').format(yearFormat)
        }
        let params = {
            ...data,
            statisticsType: checked,
            currentPage: data && data.currentPage ? data.currentPage : currentPage,
            pageSize: pageNum,
            merchantCode: data && data.merchantCode ? data.merchantCode : merchantCode,
            superMerchantCode: localStorage.appid,
            startTime: startTime,
            endTime: endTime,
            accountType,
        };
        (async _ => {
            return await statisticsDayListRequest(params);
        })().then(res => {
            if (res.code == 0) {
                setList(res.data.lists)
                setTotal(res.data.totalCount)
            } else {
                message.error(res.msg)
            }
        });
    }
    const merchantChange = (code) => {
        setMerchantCode(code)
    }
    const accountChange = (code) => {
        setAccountType(code)
    }
    const dateChange = (date) => {
        setDate(date)
    }
    const monthChange = (month) => {
    }
    const yearChange = (years) => {
        getStatisticsDayList({
            startTime: years.format('YYYY'),
            endTime: years.format('YYYY'),
            accountType: Number(form.getFieldValue('accountType')),
            statisticsType: checked,
        })
    }
    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const radioChange = (e) => {
        setRadioValue(e.target.value);
    };

    const onFinish = (values) => {
        values.currentPage = 1;
        getStatisticsDayList(values)
    };
    const divStyle = {
        height: "80vh",
        overflowY: 'auto',

    };
    const style = {
        top: 10
    }
    const exportFunc = () => {
        let values = form.getFieldValue()
        let startTime = moment().format('YYYY-MM-DD HH:mm:ss');
        let endTime = moment().format('YYYY-MM-DD HH:mm:ss');
        if (checked == 1) {
            startTime = form.getFieldValue('date')[0].format(dateFormat)
            endTime = form.getFieldValue('date')[1].format(dateFormat)
        } else if (checked == 2) {
            startTime = form.getFieldValue('month')[0].format(monthFormat)
            endTime = form.getFieldValue('month')[1].format(monthFormat)
        } else {
            startTime = form.getFieldValue('years').format(yearFormat)
            endTime = form.getFieldValue('years').format(yearFormat)
        }
        let params = {
            ...values,
            statisticsType: checked,
            merchantCode: merchantCode,
            superMerchantCode: localStorage.appid,
            startTime: startTime,
            endTime: endTime,
            accountType,
            operatorId: localStorage.operatorId,
            operatorName: localStorage.operatorname,
        };

        delete params.date;
        (async _ => {
            return await statisticsDayListExportRequest(params);
        })().then(res => {
            if (res.code == 0) {
                message.success(res.msg)
            } else {
                message.error(res.msg)
            }
        });

    }
    const exportHandleOk = () => {
        setExportVisible(false);
    };
    const exporthandleCancel = () => {
        setExportVisible(false);
    };
    return (
        <div style={{ height: '100vh', background: '#ffffff', padding: 16, paddingBottom: 20, marginTop: 16 }}>
            <Row >
                <Row >
                    <Tabs onChange={onChange} type="card" value={checked}>
                        <TabPane tab="日汇总" key="1">
                        </TabPane>
                        <TabPane tab="月汇总" key="2">
                        </TabPane>
                        <TabPane tab="年汇总" key="3">
                        </TabPane>
                    </Tabs>
                </Row>
            </Row>
            <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}

                initialValues={{
                    date: [moment(moment().startOf('month').format('YYYY-MM-DD'), dateFormat), moment(moment().format('YYYY-MM-DD'), dateFormat)],
                    accountType: '1',
                    merchantCode: null
                }}
            >
                {
                    checked == 1 ?
                        <Form.Item
                            name="date"
                            rules={[{ required: false, message: 'Please input your username!' }]}
                        >
                            <RangePicker onChange={dateChange}

                            />

                        </Form.Item> : ''
                }

                {
                    checked == 2 ?
                        <Form.Item
                            name="month"
                            rules={[{ required: false, message: 'Please input your username!' }]}
                        >
                            <RangePicker picker="month"
                                onChange={monthChange}
                            />
                        </Form.Item> : ''
                }
                {
                    checked == 3 ?
                        <Form.Item
                            name="years"
                            rules={[{ required: false, message: 'Please input your username!' }]}
                        >
                            <DatePicker onChange={yearChange} picker="year" />
                        </Form.Item> : ''
                }
                <Form.Item
                    name="accountType"
                >
                    <Select style={{ width: 200 }}
                        onChange={(code) => {
                            accountChange(code)
                        }}
                    >
                        {
                            accountTypeList && accountTypeList.map((v) => {
                                return <Option rowKey={v.constantCode} key={v.constantCode} value={v.constantCode}>{v.constantName}</Option>
                            })
                        }
                    </Select>
                </Form.Item>
                {
                    localStorage.hxState == 'true' ?
                        <Form.Item
                            name="merchantCode"
                        >
                            <Select placeholder="请选择"
                                allowClear
                                optionFilterProp="children"
                                showSearch
                                filterOption={(input, option) => option.children.includes(input)}
                                style={{ width: 300 }}
                                onChange={(code) => {
                                    merchantChange(code)
                                }}
                            >
                                <Option rowKey={null} key={null} value={null}>全部</Option>
                                {
                                    merchantList && merchantList.map((v) => {
                                        return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                    })
                                }

                            </Select>
                        </Form.Item>
                        :
                        <Form.Item
                        >
                            {localStorage.merchantName}
                        </Form.Item>
                }

                <Form.Item>
                    <Button type="primary" htmlType='submit'>筛选</Button>
                    <Button type="primary" style={{ marginLeft: 20 }}
                        onClick={() => {
                            exportFunc()
                        }}
                    >导出</Button>
                    <Button type="link" onClick={() => {
                        setExportVisible(true)
                    }} >
                        查看已导出列表
                    </Button>
                </Form.Item>
            </Form>
            <Row style={{}}>
                <Col span={24}>
                    <Table
                        locale={{
                            emptyText: "没有更多数据了"
                        }}
                        style={{ marginTop: 20 }}
                        columns={columns}
                        dataSource={list}
                        rowKey="id"
                        pagination={{
                            total,
                            showSizeChanger: true,
                            current: currentPage,
                            pageSize: pageNum,
                            // position: ["bottomCenter"],
                            showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                            onChange: (k, v) => onChangPage(k, v),
                            onShowSizeChange: (k, v) => onChangPageNum(k, v),
                        }}
                    />
                </Col>

            </Row>
            <Modal
                title="详情"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={1200}
                bodyStyle={divStyle}
                style={style}
                footer={null}
                key={Math.random(10)}
            >
                <StatementDetails data={row} />
            </Modal>
            <Modal
                title="导出列表"
                visible={exportVisible}
                onOk={exportHandleOk}
                onCancel={exporthandleCancel}
                width={1200}
                footer={null}
                key={Math.random(10)}
            >
                <ExportList type={2} />
            </Modal>
        </div >

    )
}
export default StatementDownload;
