import { Table, Typography, Row, Col, Button, message } from 'antd';
import {
    RightOutlined
} from '@ant-design/icons';

import { useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { platformViewRequest, merchantViewRequest } from '../../../api/api'
import shezhi from '../../../images/shezhi.png'
import ruzhu from '../../../images/ruzhu.png'
import jiaoyi from '../../../images/jiaoyi.png'
const { Title, Paragraph, Text } = Typography;

const AssetOverview = () => {
    const navigate = useNavigate();
    const [data, setData] = useState(null)
    const [list, setList] = useState([])
    const { search } = useLocation();
    const [bookAccounts, setBookAccounts] = useState(null)
    useEffect(() => {
            if (localStorage.hxState == 'true') {
                getPlatformView()
            } else {
                merchantView()
            }
    }, []) //所有更新都执行
    const getPlatformView = () => {
        (async _ => {
            //return await getCurrentUserPermissionListRequest(params);
            return await platformViewRequest(
                {

                    merchantCode: localStorage.merchantCode,
                    superMerchantCode: localStorage.appid
                }
            );
        })().then(res => {
            if (res.code == 0) {
                setData(res.data)
                setList(res.data.list)
                setBookAccounts(res.data.bookAccounts)
            } else {

            }
        });
    }
    const merchantView = () => {
        (async _ => {
            //return await getCurrentUserPermissionListRequest(params);
            return await merchantViewRequest(
                {

                    merchantCode: localStorage.merchantCode,
                    superMerchantCode: localStorage.appid
                }
            );
        })().then(res => {
            if (res.code == 0) {
                setData(res.data)
                setList(res.data.list)
                setBookAccounts(res.data.bookAccounts)
            } else {

            }
        });
    }
    const columns = [
        {
            title: '商户',
            dataIndex: 'merchantName',
            key: 'merchantName',
        },
        {
            title: '账户',
            dataIndex: 'accountType',
            key: 'accountType',
            render: (text, row) => {
                return (
                    <div>
                        {row.accountType == 0 ? '店铺余额待结算' : ''}
                        {row.accountType == 1 ? '可用账户' : ''}
                        {row.accountType == 2 ? '钱包账户' : ''}
                        {row.accountType == 3 ? '信用可提现账户' : ''}
                        {row.accountType == 4 ? '钱包余额待结算' : ''}
                        {row.accountType == 5 ? '收益账户' : ''}
                        {row.accountType == 6 ? '授信账户' : ''}
                        {row.accountType == 7 ? '营销余额待结算' : ''}
                        {row.accountType == 8 ? '营销余额' : ''}
                    </div>
                )
            }
        },
        {
            title: '收入',
            dataIndex: 'incomeList',
            key: 'incomeList',
            render: (text, row) => {
                return (
                    row.incomeList ? row.incomeList.map((v) => {
                        return (
                            <Row justify="space-between">
                                <Col span={6}>{v.businessTradeTypeName}</Col>
                                <Col span={6}>{v.totalAmount}元</Col>
                                <Col span={6}>{v.totalCount}笔</Col>
                                <Col span={6}>
                                    <span style={{ color: '#165BD4', cursor: 'pointer' }}
                                        onClick={() => {
                                            navigate('/ws/statementList', { replace: false, state: v })
                                        }}
                                    >明细</span>

                                </Col>
                            </Row>
                        )
                    })
                        :
                        <div>
                            <div>0元</div>
                            <div>0笔</div>
                        </div>
                )
            },
        },
        {
            title: '支出',
            dataIndex: 'outList',
            key: 'outList',
            render: (text, row) => {
                return (
                    row.outList ? row.outList.map((v) => {
                        return (
                            <Row>
                                <Col span={6}>{v.businessTradeTypeName}</Col>
                                <Col span={6}>{v.totalAmount}元</Col>
                                <Col span={6}>{v.totalCount}笔</Col>
                                <Col span={6}>
                                    <span style={{ color: '#165BD4', cursor: 'pointer' }}
                                        onClick={() => {
                                            navigate('/ws/statementList', { replace: false, state: v })
                                        }}
                                    >明细</span>

                                </Col>
                            </Row>
                        )
                    })
                        :
                        <div>
                            <div>0元</div>
                            <div>0笔</div>
                        </div>

                )
            },
        },

    ];
    return (
        <div style={{ background: '#f7f8fa', marginTop: 16 }}
        >
            <Row justify='space-between' >
                <Col style={{ background: '#ffffff', padding: 16, width: '60%', borderBottom: '1px solid #EAEDF0', }}>
                    <Title level={5}>账户概览</Title>
                    <Row>
                        <Col span={8}>
                            <Paragraph>
                                <Text type="secondary">店铺资金（元）</Text>
                            </Paragraph>
                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                <span style={{ fontSize: 26, color: '#323233', cursor: 'pointer' }} onClick={() => {
                                    if (localStorage.hxState == "true") {
                                        navigate('/ws/platformKanban/availableFunds', { replace: false, state: { tag: 1 } })
                                    } else {
                                        navigate('/ws/balance/availableBalance', { replace: false, state: { tag: 1 } })
                                    }

                                }}>{data && data.availableBalance}</span>
                                <RightOutlined style={{ color: '#C8C9CC', marginLeft: 10 }} />
                            </div>
                        </Col>
                        {/* {
                            localStorage.hxState == "true" ?
                                <Col span={8} push={4}>
                                    <Paragraph>
                                        <Text type="secondary">收益资金（元）</Text>
                                    </Paragraph>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ fontSize: 26, color: '#323233', cursor: 'pointer' }} onClick={() => {
                                            navigate('/ws/platformKanban/earnings', { replace: false, state: { tag: 1 } })
                                        }}>{data && data.incomeBalance}</span>
                                        <RightOutlined style={{ color: '#C8C9CC', marginLeft: 10 }} />
                                    </div>
                                </Col> : ''
                        } */}

                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        {/* <Button type="primary" onClick={() => {
                                navigate('/ws/balance/recharge')
                            }}>充值</Button> */}
                        <Button onClick={() => {
                            navigate('/ws/balance/withdrawal')
                        }}>提现</Button>
                        {/* <Button style={{ marginLeft: 20 }}>转账</Button> */}
                    </Row>

                </Col>
                <Col style={{ background: '#ffffff', padding: 16, width: '39%', borderBottom: '1px solid #EAEDF0', }}>
                    <Title level={5}>账户信息</Title>
                    <Paragraph>
                        <Text type="secondary">商户名：</Text><Text >{localStorage.merchantName}</Text>
                    </Paragraph>
                    <Paragraph>
                        <Text type="secondary">编码：</Text><Text >{localStorage.merchantCode}</Text>
                    </Paragraph>
                    <Paragraph>
                        <Text type="secondary">银行卡：</Text><Text >{data && data.cardNum}张</Text>
                        <Text style={{ color: '#1B5ED5', marginLeft: 10, cursor: 'pointer' }} onClick={() => {
                            navigate('/ws/bankCard', { replace: false, state: { tag: 1, } })
                        }}>管理</Text>
                    </Paragraph>

                </Col>
            </Row>
            <Row justify='space-between'>
                <div style={{ width: '60%', background: '#ffffff', padding: 16, paddingTop: 30 }}>
                    <div style={{ background: '#ffffff', display: 'flex', padding: 16, }}>
                        <Col style={{ width: '33.3333%' }}>
                            <Paragraph>
                                <Text type="secondary">钱包资金（元）</Text>
                            </Paragraph>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ fontSize: 26, color: '#323233', cursor: 'pointer' }} onClick={() => {
                                    if (localStorage.hxState == "true") {
                                        navigate('/ws/platformKanban/purseMoney', { replace: false, state: { tag: 1 } })
                                    } else {
                                        navigate('/ws/balance/walletBalance', { replace: false, state: { tag: 1 } })
                                    }
                                }}>{data && data.walletAvailableBalance}</span>
                                <RightOutlined style={{ color: '#C8C9CC', marginLeft: 10 }} />
                            </div>
                        </Col>
                        <Col style={{ width: '33.3333%' }}>
                            <Paragraph>
                                <Text type="secondary">收益资金（元）</Text>
                            </Paragraph>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ fontSize: 26, color: '#323233', cursor: 'pointer' }} onClick={() => {
                                    if (localStorage.hxState == "true") {
                                        navigate('/ws/platformKanban/availableFunds', { replace: false, state: { tag: 1 } })
                                    } else {
                                        navigate('/ws/balance/availableBalance', { replace: false, state: { tag: 1 } })
                                    }
                                }}>{data && data.incomeBalance}</span>
                                <RightOutlined style={{ color: '#C8C9CC', marginLeft: 10 }} />
                            </div>
                        </Col>
                        <Col style={{ width: '33.3333%' }}>
                            <Paragraph>
                                <Text type="secondary">待还款资金（元）</Text>
                            </Paragraph>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ fontSize: 26, color: '#323233', cursor: 'pointer' }} onClick={() => {
                                    if (localStorage.hxState == 'true') {
                                        navigate('/ws/platformKanban/creditFunds', { replace: false, state: { tag: 1 } })
                                    } else {
                                        navigate('/ws/creditAccount/credit', { replace: false, state: { tag: 1 } })
                                    }
                                }}>{data && data.repaidBalance}</span>
                                <RightOutlined style={{ color: '#C8C9CC', marginLeft: 10 }} />
                            </div>
                        </Col>
                    </div>
                    <div style={{ background: '#ffffff', display: 'flex', padding: 16, }}>
                        {
                            bookAccounts && bookAccounts.map((item, index) => {
                                return (
                                    <Col style={{ width: '33.3333%' }} key={index}>
                                        <Paragraph>
                                            <Text type="secondary">{item.accountName}</Text>
                                        </Paragraph>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span style={{ fontSize: 26, color: '#323233', cursor: 'pointer' }} onClick={() => {
                                                // if (localStorage.hxState == "true") {
                                                navigate('/ws/keepAccountsList/list', { replace: false, state: { tag: 1, accountNo: item.accountNo } })
                                                // }
                                            }}>{item.totalBalance}</span>
                                            <RightOutlined style={{ color: '#C8C9CC', marginLeft: 10 }} />
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </div>
                </div>

                <div style={{ width: '39%', background: '#ffffff', padding: 16 }}>
                    <Row>
                        {localStorage.hxState == 'true' ?
                            <Paragraph style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div onClick={() => {
                                    navigate('/ws/initialConfiguration/storeValue', { replace: false, state: { tag: 1, } })
                                }} style={{
                                    width: 32,
                                    height: 32,
                                    background: '#D8D8D8',
                                    borderRadius: 4,
                                    cursor: 'pointer',
                                }}>
                                    <img src={shezhi} style={{ width: '100%', height: '100%' }}></img>
                                </div>
                                <Text style={{ color: '#1B5ED5' }} onClick={() => {
                                    navigate('/ws/initialConfiguration/storeValue', { replace: false, state: { tag: 1, } })
                                }}>初始设置</Text>
                            </Paragraph> : ''
                        }

                        {/* localStorage.hxState == true(商户) false(门店) */}

                        {
                            localStorage.hxState == 'true' ?  
                            <>
                                <Paragraph style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 32 }}>
                                    <div onClick={() => {
                                        navigate('/ws/institutionsIn/list', { replace: false, state: { tag: 1 } })
                                    }} style={{
                                        width: 32,
                                        height: 32,
                                        background: '#D8D8D8',
                                        borderRadius: 4,
                                        cursor: 'pointer',
                                    }}>
                                        <img src={ruzhu} style={{ width: '100%', height: '100%' }}></img>
                                    </div>
                                    <Text style={{ color: '#1B5ED5' }} onClick={() => {
                                        navigate('/ws/institutionsIn/list', { replace: false, state: { tag: 1 } })
                                    }}>机构入驻</Text>
                                </Paragraph>
                                <Paragraph style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 32 }}>
                                    <div onClick={() => {
                                        navigate('/ws/tradeSetting/list', { replace: false, state: { tag: 1 } })
                                    }} style={{
                                        width: 32,
                                        height: 32,
                                        background: '#D8D8D8',
                                        borderRadius: 4,
                                        cursor: 'pointer',
                                    }}>
                                        <img src={jiaoyi} style={{ width: '100%', height: '100%' }}></img>
                                    </div>
                                    <Text style={{ color: '#1B5ED5' }} onClick={() => {
                                        navigate('/ws/tradeSetting/list', { replace: false, state: { tag: 1 } })
                                    }}>交易设置</Text>
                                </Paragraph>
                            </> : ''
                        }
                    </Row>
                </div>


            </Row >

            <Row justify='space-between' style={{ background: '#ffffff', padding: 16, marginTop: 16 }}>
                <Col>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ fontSize: 16, fontWeight: 500, marginRight: 20 }}>收支概况</div>
                        <div style={{
                            background: '#155BD4',
                            color: 'white',
                            width: 49,
                            height: 21,
                            textAlign: 'center',
                            borderRadius: 2
                        }}>昨日</div>

                    </div>
                </Col>
                <Col>
                    <Button type="link" onClick={() => {
                        navigate('/ws/statementDownload', { replace: false, state: { bool: true } })
                    }}>查看更多<RightOutlined /></Button>
                </Col>
            </Row>
            <Table
                // style={{ margin: '0 16px 0 15px' }}
                locale={{
                    emptyText: "没有更多数据了"
                }}
                columns={columns}
                dataSource={list}
                rowKey="id"
                pagination={false}
            />

        </div >
    )
}

export default AssetOverview