
import {
    urlAppendQuery
} from '../util/tools';
import request from '../util/request';
import host from '../util/host';
import host1 from '../util/host1'
export async function platformViewRequest(params) {
    return request.POST(`${host}/platform/view`, {
        body: {
            ...params
        }
    });
}
export async function balanceAccountViewRequest(params) {
    return request.POST(`${host}/balanceAccount/view`, {
        body: {
            ...params
        }
    });
}
export async function creditAccountViewRequest(params) {
    return request.POST(`${host}/creditAccount/view`, {
        body: {
            ...params
        }
    });
}
export async function incomeAccountViewRequest(params) {
    return request.POST(`${host}/incomeAccount/view`, {
        body: {
            ...params
        }
    });
}
export async function expenseListRequest(params) {
    return request.POST(`${host}/expense/list`, {
        body: {
            ...params
        }
    });
}
export async function expenseDeleteRequest(params) {
    return request.POST(`${host}/expense/delete`, {
        body: {
            ...params
        }
    });
}
export async function expenseTypeListRequest(params) {
    return request.POST(`${host}/expenseType/list`, {
        body: {
            ...params
        }
    });
}
export async function expenseTypeEditRequest(params) {
    return request.POST(`${host}/expenseType/edit`, {
        body: {
            ...params
        }
    });
}
export async function expenseTypeDeleteRequest(params) {
    return request.POST(`${host}/expenseType/delete`, {
        body: {
            ...params
        }
    });
}
export async function balanceViewRequest(params) {
    return request.POST(`${host}/balance/view`, {
        body: {
            ...params
        }
    });
}

export async function configEditRequest(params) {
    return request.POST(`${host}/config/edit`, {
        body: {
            ...params
        }
    });
}
export async function configDetailRequest(params) {
    return request.POST(`${host}/config/detail`, {
        body: {
            ...params
        }
    });
}

export async function registerMerchantRequest(params) {
    return request.POST(`${host}/merchant/register`, {
        body: {
            ...params
        }
    });
}

export async function wsbankMerchantDetailRequest(params) {
    return request.POST(`${host}/wsbank/merchantDetail?merchantCode=${params.merchantCode}`, {
        body: {
            ...params
        }
    });
}
export async function wsbankMerchantRegisterRequest(params) {
    return request.POST(`${host}/wsbank/merchantRegister`, {
        body: {
            ...params
        }
    });
}
export async function merchQueryAndOpenPayRequest(params) {
    return request.POST(`${host}/wsbank/merchQueryAndOpenPay?outMerchantId=${params.outMerchantId}&superMerchantCode=${params.superMerchantCode}&wsMerchantId=${params.wsMerchantId}`, {
        body: {
            ...params
        }
    });
}

export async function settleConfigSaveRequest(params) {
    return request.POST(`${host}/settleConfigSave`, {
        body: {
            ...params
        }
    });
}
export async function settleConfigListRequest(params) {
    return request.POST(`${host}/settleConfigList`, {
        body: {
            ...params
        }
    });
}

export async function expenseEditRequest(params) {
    return request.POST(`${host}/expense/edit`, {
        body: {
            ...params
        }
    });
}

export async function settleMerchantListRequest(params) {
    return request.POST(`${host}/settleMerchantList`, {
        body: {
            ...params
        }
    });
}
export async function creditViewRequest(params) {
    return request.POST(`${host}/credit/view`, {
        body: {
            ...params
        }
    });
}
export async function creditDetailRequest(params) {
    return request.POST(`${host}/creditDetail`, {
        body: {
            ...params
        }
    });
}
export async function creditHistoryRequest(params) {
    return request.POST(`${host}/creditHistory`, {
        body: {
            ...params
        }
    });
}
export async function incomeViewRequest(params) {
    return request.POST(`${host}/income/view`, {
        body: {
            ...params
        }
    });
}
export async function statisticsDayListRequest(params) {
    return request.POST(`${host}/statisticsDay/list`, {
        body: {
            ...params
        }
    });
}
export async function statisticsDayDetailRequest(params) {
    return request.POST(`${host}/statisticsDay/detail`, {
        body: {
            ...params
        }
    });
}

export async function accountLogListRequest(params) {
    return request.POST(`${host}/accountLog/list`, {
        body: {
            ...params
        }
    });
}

export async function tradeorderListRequest(params) {
    return request.POST(`${host}/tradeorder/list`, {
        body: {
            ...params
        }
    });
}

export async function settleWithdrawAmountRequest(params) {
    return request.POST(`${host}/settle/withdraw/amount`, {
        body: {
            ...params
        }
    });
}

export async function settleWithdrawListRequest(params) {
    return request.POST(`${host}/settle/withdraw/list`, {
        body: {
            ...params
        }
    });
}

export async function getBusiListRequest(params) {
    return request.POST(`${host}/accountLog/getBusiList`, {
        body: {
            ...params
        }
    });
}

export async function detailByAccountRequest(params) {
    return request.POST(`${host}/statisticsDay/detailByAccount`, {
        body: {
            ...params
        }
    });
}

export async function allMerchantListRequest(params) {
    return request.POST(`${host}/allMerchantList`, {
        body: {
            ...params
        }
    });
}
export async function relationRecordRequest(params) {
    return request.POST(`${host}/accountLog/relationRecord`, {
        body: {
            ...params
        }
    });
}

export async function appletRegisterQueryRequest(params) {
    return request.POST(`${host}/wsbank/appletRegisterQuery?merchantCode=${params.merchantCode}&superMerchantCode=${params.superMerchantCode}`, {
        body: {
            ...params
        }
    });
}


export async function refushPaySignRequest(params) {
    return request.POST(`${host}/wsbank/refushPaySign?merchantCode=${params.merchantCode}&superMerchantCode=${params.superMerchantCode}`, {
        body: {
            ...params
        }
    });
}
export async function openRepairRequest(params) {
    return request.POST(`${host}/wsbank/openRepair?merchantCode=${params.merchantCode}&superMerchantCode=${params.superMerchantCode}&openRepairTime=${params.openRepairTime}`, {
        body: {
            ...params
        }
    });
}

export async function merchantListRequest(params) {
    return request.POST(`${host}/wsbank/merchantList`, {
        body: {
            ...params
        }
    });
}

export async function creditAccountRepayRequest(params) {
    return request.POST(`${host}/creditAccountRepay`, {
        body: {
            ...params
        }
    });
}

export async function freezeListRequest(params) {
    return request.POST(`${host}/freeze/list`, {
        body: {
            ...params
        }
    });
}

export async function loginRequest(params) {
    return request.POST(`${host}/login`, {
        body: {
            ...params
        }
    });
}
export async function getSourceListRequest(params) {
    return request.POST(`${host}/accountLog/getSourceList`, {
        body: {
            ...params
        }
    });
}

export async function settleConfigDetailRequest(params) {
    return request.POST(`${host}/settleConfigDetail`, {
        body: {
            ...params
        }
    });
}


export async function settleBillListRequest(params) {
    return request.POST(`${host}/settle/billList`, {
        body: {
            ...params
        }
    });
}

export async function accountListRequest(params) {
    return request.POST(`${host}/account/list`, {
        body: {
            ...params
        }
    });
}

export async function listByAccountRequest(params) {
    return request.POST(`${host}/accountLog/listByAccount`, {
        body: {
            ...params
        }
    });
}

export async function merchantViewRequest(params) {
    return request.POST(`${host}/merchant/view`, {
        body: {
            ...params
        }
    });
}


export async function accountLogDetailRequest(params) {
    return request.POST(`${host}/accountLog/detail`, {
        body: {
            ...params
        }
    });
}

export async function checkInRequest(params) {
    return request.POST(`${host}/checkIn?token=${params}`);
}

export async function huifudgMerchantListRequest(params) {
    return request.POST(`${host}/huifudg/merchantList`, {
        body: {
            ...params
        }
    });
}

export async function merchantSelectRequest(params) {
    return request.POST(`${host}/huifudg/merchantSelect`, {
        body: {
            ...params
        }
    });
}

export async function merchantRegisterRequest(params) {
    return request.POST(`${host}/huifudg/merchantRegister`, {
        body: {
            ...params
        }
    });
}


export async function updateHuifuDgRequest(params) {
    return request.POST(`${host}/huifuDg/update`, {
        body: {
            ...params
        }
    });
}
export async function bankCardNameListRequest(params) {
    return request.POST(`${host}/bankCardName/list`, {
        body: {
            ...params
        }
    });
}
export async function bankCardSaveRequest(params) {
    return request.POST(`${host}/bankCard/save`, {
        body: {
            ...params
        }
    });
}
export async function bankCardListRequest(params) {
    return request.POST(`${host}/bankCard/list`, {
        body: {
            ...params
        }
    });
}
export async function bankCardDetailRequest(params) {
    return request.POST(`${host}/bankCard/detail`, {
        body: {
            ...params
        }
    });
}
export async function bankCardDeleteRequest(params) {
    return request.POST(`${host}/bankCard/delete`, {
        body: {
            ...params
        }
    });
}
export async function bookAccountSaveRequest(params) {
    return request.POST(`${host}/bookAccount/save`, {
        body: {
            ...params
        }
    });
}
export async function bookAccountListRequest(params) {
    return request.POST(`${host}/bookAccount/list`, {
        body: {
            ...params
        }
    });
}
export async function bookAccountDetailRequest(params) {
    return request.POST(`${host}/bookAccount/detail`, {
        body: {
            ...params
        }
    });
}
export async function bookOrderSaveRequest(params) {
    return request.POST(`${host}/bookOrder/save`, {
        body: {
            ...params
        }
    });
}
export async function bookOrderListRequest(params) {
    return request.POST(`${host}/bookOrder/list`, {
        body: {
            ...params
        }
    });
}
export async function toAddRequest(params) {
    return request.POST(`${host}/bookRecharge/toAdd
    `, {
        body: {
            ...params
        }
    });
}
export async function getListRequest(params) {
    return request.POST(`${host}/bookAccount/getList
    `, {
        body: {
            ...params
        }
    });
}

export async function bookRechargeSaveRequest(params) {
    return request.POST(`${host}/bookRecharge/save
    `, {
        body: {
            ...params
        }
    });
}
export async function bookRechargeListRequest(params) {
    return request.POST(`${host}/bookRecharge/list`, {
        body: {
            ...params
        }
    });
}
export async function bookRechargeDetailRequest(params) {
    return request.POST(`${host}/bookRecharge/detail`, {
        body: {
            ...params
        }
    });
}
export async function bookRechargeProcessRequest(params) {
    return request.POST(`${host}/bookRecharge/process`, {
        body: {
            ...params
        }
    });
}
export async function listProcessRequest(params) {
    return request.POST(`${host}/bookRecharge/listProcess`, {
        body: {
            ...params
        }
    });
}
export async function walletViewRequest(params) {
    return request.POST(`${host}/wallet/view`, {
        body: {
            ...params
        }
    });
}
export async function walletAccountViewRequest(params) {
    return request.POST(`${host}/walletAccount/view`, {
        body: {
            ...params
        }
    });
}
export async function withdrawSubmitRequest(params) {
    return request.POST(`${host}/settle/withdraw/submit`, {
        body: {
            ...params
        }
    });
}
export async function withdrawAuditRequest(params) {
    return request.POST(`${host}/settle/withdraw/audit`, {
        body: {
            ...params
        }
    });
}
export async function withdrawTransferRequest(params) {
    return request.POST(`${host}/settle/withdraw/transfer`, {
        body: {
            ...params
        }
    });
}
export async function bookAccountSetDefaultRequest(params) {
    return request.POST(`${host}/bookAccount/setDefault`, {
        body: {
            ...params
        }
    });
}
export async function bankCardSetDefaultRequest(params) {
    return request.POST(`${host}/bankCard/setDefault`, {
        body: {
            ...params
        }
    });
}
export async function shopAreaListRequest(params) {
    return request.POST(`${host1}/api/shopArea/list?manageMerchantType=${params.manageMerchantType}&superMerchantCode=${params.superMerchantCode}`,
        {
            body: {
                ...params
            }
        });
}
export async function shopAreaListRequest1(params) {
    return request.POST(`${host1}/api/shopArea/list?superMerchantCode=${params.superMerchantCode}`,
        {
            body: {
                ...params
            }
        });
}
export async function groupListRequest(params) {
    return request.POST(`${host1}/api/retail/group/list?currentPage=${params.currentPage}&pageSize=${params.pageSize}&appId=${params.appId}`)
}
export async function shopManageFeeSaveRequest(params) {
    return request.POST(`${host1}/api/shopManageFee/save`, {
        body: params
    });
}
export async function shopManageFeeListRequest(params) {
    return request.POST(`${host1}/api/shopManageFee/list`, {
        body: params
    });
}
export async function shopManageFeeDetailRequest(params) {
    return request.POST(`${host1}/api/shopManageFee/detail`, {
        body: params
    })
}
export async function merchantInfoListRequest(params) {
    return request.POST(`${host1}/api/retail/merchantInfo/list?appId=${params.appId}&superMerchantCode=${params.superMerchantCode}&MerchantTypeInfo=${params.MerchantTypeInfo}`)
}
export async function shopAreaListAllRequest(params) {
    return request.POST(`${host1}/api/shopArea/listAll`)
}
export async function updateStatusRequest(params) {
    return request.POST(`${host1}/api/shopManageFee/updateStatus`, {
        body: params
    })
}
export async function shopManageFeeOrderListRequest(params) {
    return request.POST(`${host1}/api/shopManageFeeOrder/list`, {
        body: params
    })
}
export async function partnerListRequest(params) {
    return request.POST(`${host1}/api/shopManageFeeOrder/partnerList?superMerchantCode=${params.superMerchantCode}`, {
        body: params
    })
}
export async function listForManageRequest(params) {
    return request.POST(`${host1}/api/retail/merchantInfo/listForManage?appId=${params.appId}&superMerchantCode=${params.superMerchantCode}&MerchantTypeInfo=${params.MerchantTypeInfo}`)
}
export async function getBusiListExportRequest(params) {
    return request.POST(`${host}/export/getBusiList`, {
        body: {
            ...params
        }
    });
}
export async function exportListRequest(params) {
    return request.POST(`${host}/export/list`, {
        body: {
            ...params
        }
    });
}
export async function listExportRequest(params) {
    return request.POST(`${host}/accountLog/listExport`, {
        body: {
            ...params
        }
    });
}
export async function statisticsDayListExportRequest(params) {
    return request.POST(`${host}/statisticsDay/listExport`, {
        body: {
            ...params
        }
    });
}
export async function listRequest(params) {
    return request.POST(`${host}/authBank/list`, {
        body: {
            ...params
        }
    });
}
export async function bankCardNameRequest(params) {
    return request.POST(`${host}/bankCardName/list`, {
        body: {
            ...params
        }
    });
}
export async function authBankListPCRequest(params) {
    return request.POST(`${host}/authBank/listPC`, {
        body: {
            ...params
        }
    });
}
export async function merchantDetailRequest(params) {
    return request.POST(`${host}/wsbank/merchantDetail?merchantCode=${params.merchantCode}`, {
        body: {
            ...params
        }
    });
}
export async function orderSaveRequest(params) {
    return request.POST(`${host}/transfer/orderSave`, {
        body: {
            ...params
        }
    });
}
export async function getConstantsRequest(params) {
    return request.POST(`${host}/system/getConstants`, {
        body: {
            ...params
        }
    });
}
export async function orderListRequest(params) {
    return request.POST(`${host}/transfer/orderList`, {
        body: {
            ...params
        }
    });
}
export async function orderCheckBatchNoRequest(params) {
    return request.POST(`${host}/transfer/orderCheckBatchNo`, {
        body: {
            ...params
        }
    });
}
export async function orderCheckRequest(params) {
    return request.POST(`${host}/transfer/orderCheck`, {
        body: {
            ...params
        }
    });
}
export async function orderUpdateRequest(params) {
    return request.POST(`${host}/transfer/orderUpdate`, {
        body: {
            ...params
        }
    });
}
export async function orderAddValidateRequest(params) {
    return request.POST(`${host}/transfer/orderAddValidate`, {
        body: {
            ...params
        }
    });
}
export async function orderCancelRequest(params) {
    return request.POST(`${host}/transfer/orderCancel`, {
        body: {
            ...params
        }
    });
}


export async function queryByTypeRequest(params) {
    return request.POST(`${host}/account/queryByType`, {
        body: {
            ...params
        }
    });
}
export async function orderImportRequest(params) {
    return request.POST(`${host}/transfer/orderImport`, {
        body: {
            ...params
        }
    });
}
export async function expenseOrderListRequest(params) {
    return request.POST(`${host}/expense/order/list`, {
        body: {
            ...params
        }
    });
}
export async function settleMerchantListWithOutRequest(params) {
    return request.POST(`${host}/settleMerchantListWithOut`, {
        body: {
            ...params
        }
    });
}
export async function expenseOrderEditRequest(params) {
    return request.POST(`${host}/expense/order/edit`, {
        body: {
            ...params
        }
    });
}
export async function expenseOrderBusinessRequest(params) {
    return request.POST(`${host}/expense/order/business/list`, {
        body: {
            ...params
        }
    });
}
export async function expenseOrderDetailRequest(params) {
    return request.POST(`${host}/expense/order/detail`, {
        body: {
            ...params
        }
    });
}
export async function expenseOrderDeleteByCostRuleNoRequest(params) {
    return request.POST(`${host}/expense/order/deleteByCostRuleNo`, {
        body: {
            ...params
        }
    });
}
export async function expenseOrderDeleteRequest(params) {
    return request.POST(`${host}/expense/order/delete`, {
        body: {
            ...params
        }
    });
}
export async function listByOrderNoRequest(params) {
    return request.POST(`${host}/log/listByOrderNo`, {
        body: {
            ...params
        }
    });
}
export async function certificateTypeListRequest(params) {
    return request.POST(`${host}/authBase/certificateTypeList`, {
        body: {
            ...params
        }
    });
}
export async function areaListRequest(params) {
    return request.POST(`${host}/authBase/areaList`, {
        body: {
            ...params
        }
    });
}
export async function savePersonSecondRequest(params) {
    return request.POST(`${host}/authBase/savePersonSecond`, {
        body: {
            ...params
        }
    });
}
export async function incompleteDetailRequest(params) {
    return request.POST(`${host}/authBase/incompleteDetail`, {
        body: {
            ...params
        }
    });
}
export async function savePersonFirstRequest(params) {
    return request.POST(`${host}/authBase/savePersonFirst`, {
        body: {
            ...params
        }
    });
}
export async function saveSubmitPersonRequest(params) {
    return request.POST(`${host}/authBase/saveSubmitPerson`, {
        body: {
            ...params
        }
    });
}
export async function authBaseDetailRequest(params) {
    return request.POST(`${host}/authBase/detail`, {
        body: {
            ...params
        }
    });
}
export async function editPersonRequest(params) {
    return request.POST(`${host}/authBase/editPerson`, {
        body: {
            ...params
        }
    });
}
export async function branchBankListRequest(params) {
    return request.POST(`${host}/authBase/branchBankList`, {
        body: {
            ...params
        }
    });
}

export async function employeeListRequest(params) {
    return request.POST(`${host}/employee/list`, {
        body: {
            ...params
        }
    });
}
export async function listByLoginNameRequest(params) {
    return request.POST(`${host}/employee/listByLoginName`, {
        body: {
            ...params
        }
    });
}
export async function accountCreateRequest(params) {
    return request.POST(`${host}/employee/account/create`, {
        body: {
            ...params
        }
    });
}
export async function updateStatusByIdsRequest(params) {
    return request.POST(`${host}/employee/updateStatusByIds`, {
        body: {
            ...params
        }
    });
}
export async function marketingAccountRequest(params) {
    return request.POST(`${host}/marketingAccount/view`, {
        body: {
            ...params
        }
    });
}
export async function employeeAccountRequest(params) {
    return request.POST(`${host}/employeeAccount/view`, {
        body: {
            ...params
        }
    });
}
export async function marketingAccountViewRequest(params) {
    return request.POST(`${host}/marketingAccount/view`, {
        body: {
            ...params
        }
    });
}
export async function marketingViewRequest(params) {
    return request.POST(`${host}/marketing/view`, {
        body: {
            ...params
        }
    });
}
export async function employeeViewRequest(params) {
    return request.POST(`${host}/employee/view`, {
        body: {
            ...params
        }
    });
}
export async function queryByTypeAccountRequest(params) {
    return request.POST(`${host}/employee/account/queryByType`, {
        body: {
            ...params
        }
    });
}
export async function accountLogEmployeeRequest(params) {
    return request.POST(`${host}/employee/accountLog/list`, {
        body: {
            ...params
        }
    });
}

export async function bookBankCardEditRequest(params) {
    return request.POST(`${host}/authBase/bookBankCardEdit`, {
        body: {
            ...params
        }
    });
}
export async function onlineOrderListRequest(params) {
    return request.POST(`${host}/onlineOrder/List`, {
        body: {
            ...params
        }
    });
}
export async function excelListRequest(params) {
    return request.POST(`${host}/onlineOrder/excelList`, {
        body: {
            ...params
        }
    });
}
export async function dyBuchangListRequest(params) {
    return request.POST(`${host}/onlineOrder/dyBuchangList`, {
        body: {
            ...params
        }
    });
}
export async function dyBuchangRequest(params) {
    return request.POST(`${host}/onlineOrder/dyBuchang`, {
        body: {
            ...params
        }
    });
}
export async function matchMerchantListRequest(params) {
    return request.POST(`${host}/onlineOrder/matchMerchantList`, {
        body: {
            ...params
        }
    });
}
export async function deleteExcelRequest(params) {
    return request.POST(`${host}/onlineOrder/deleteExcel`, {
        body: {
            ...params
        }
    });
}
export async function configListRequest(params) {
    return request.POST(`${host}/config/list`, {
        body: {
            ...params
        }
    });
}
export async function configSelectListRequest(params) {
    return request.POST(`${host}/config/selectList`, {
        body: {
            ...params
        }
    });
}
export async function editSetModelRequest(params) {
    return request.POST(`${host}/config/editSetModel`, {
        body: {
            ...params
        }
    });
}
export async function reconciliationListRequest(params) {
    return request.POST(`${host}/onlineOrder/reconciliationList`, {
        body: {
            ...params
        }
    });
}
export async function ListZBRequest(params) {
    return request.POST(`${host}/onlineOrder/ListZB`, {
        body: {
            ...params
        }
    });
}
export async function ListDYRequest(params) {
    return request.POST(`${host}/onlineOrder/ListDY`, {
        body: {
            ...params
        }
    });
}
export async function dyDeleteOneRequest(params) {
    return request.POST(`${host}/onlineOrder/dyDeleteOne`, {
        body: {
            ...params
        }
    });
}
export async function submitreconciliationRequest(params) {
    return request.POST(`${host}/onlineOrder/submitreconciliation`, {
        body: {
            ...params
        }
    });
}
export async function oweListRequest(params) {
    return request.POST(`${host}/owe/list`, {
        body: {
            ...params
        }
    });
}
export async function detailDYRequest(params) {
    return request.POST(`${host}/accountLog/detailDY`, {
        body: {
            ...params
        }
    });
}
export async function meituanBuchangRequest(params) {
    return request.POST(`${host}/onlineOrder/meituanBuchang`, {
        body: {
            ...params
        }
    });
}
export async function deleteMTExcelByIdRequest(params) {
    return request.POST(`${host}/onlineOrder/deleteMTExcelById`, {
        body: {
            ...params
        }
    });
}
export async function meituanDeleteOneRequest(params) {
    return request.POST(`${host}/onlineOrder/meituanDeleteOne`, {
        body: {
            ...params
        }
    });
}
export async function saveCompanyFirstRequest(params) {
    return request.POST(`${host}/authBase/saveCompanyFirst`, {
        body: {
            ...params
        }
    });
}
export async function meituanEleMerchantListRequest(params) {
    return request.POST(`${host}/onlineOrder/meituanEleMerchantList`,{
        body: {
            ...params
        }
    });
}
export async function saveCompanySecondRequest(params) {
    return request.POST(`${host}/authBase/saveCompanySecond`, {
        body: {
            ...params
        }
    });
}
export async function submitreconciliationMTRequest(params) {
    return request.POST(`${host}/onlineOrder/submitreconciliationMT`, {
        body: {
            ...params
        }
    });
}
export async function saveCompanyThirdRequest(params) {
    return request.POST(`${host}/authBase/saveCompanyThird`, {
        body: {
            ...params
        }
    });
}
export async function eleDeleteOneRequest(params) {
    return request.POST(`${host}/onlineOrder/eleDeleteOne`, {
        body: {
            ...params
        }
    });
}
export async function saveSubmitCompanyRequest(params) {
    return request.POST(`${host}/authBase/saveSubmitCompany`, {
        body: {
            ...params
        }
    });
}
export async function eleBuchangRequest(params) {
    return request.POST(`${host}/onlineOrder/eleBuchang`, {
        body: {
            ...params
        }
    });
}
export async function editCompanyRequest(params) {
    return request.POST(`${host}/authBase/editCompany`, {
        body: {
            ...params
        }
    });
}
export async function submitreconciliationELERequest(params) {
    return request.POST(`${host}/onlineOrder/submitreconciliationELE`, {
        body: {
            ...params
        }
    });
}
export async function deleteRequest(params) {
    return request.POST(`${host}/authBank/delete`, {
        body: {
            ...params
        }
    });
}
export async function viewExportRequest(params) {
    return request.POST(`${host}/walletAccount/viewExport`, {
        body: {
            ...params
        }
    });
}
export async function settleConfigListExcelRequest(params) {
    return request.POST(`${host}/settleConfigListExcel`, {
        body: {
            ...params
        }
    });
}
export async function b2bSettleBillListRequest(params) {
    return request.POST(`${host}/settle/b2bSettleBillList`, {
        body: {
            ...params
        }
    });
}
export async function settingListRequest(params) {
    return request.POST(`${host}/withDraw/setting/list`, {
        body: {
            ...params
        }
    });
}
export async function settingEditRequest(params) {
    return request.POST(`${host}/withDraw/setting/edit`, {
        body: {
            ...params
        }
    });
}
export async function settingDetailRequest(params) {
    return request.POST(`${host}/withDraw/setting/detail`, {
        body: {
            ...params
        }
    });
}
export async function dgReconciliationRequest(params) {
    return request.POST(`${host}/reconciliation/pageList`, {
        body: {
            ...params
        }
    });
}
export async function dgCleanConfigRequest(params) {
        return request.POST(`${host}/huifuDg/cleanConfig`, {
            body: {
                ...params
            }
        });
    }
    