import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import './style.less'
import {
    Form,
    Image,
    Input,
    Button,
    message,
    Row,
    Col,
    Radio,
    Select,
    Upload,
} from 'antd';
import { configDetailRequest, withdrawTransferRequest, getListRequest, balanceViewRequest } from '../../../../api/api'
import host from '../../../../util/host';
import { UploadOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
const { TextArea } = Input;
const { Option } = Select;
const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const LnstitutionsInList = (props) => {
    console.log(props.data.withdrawType);
    const [auditRemark, setAuditRemark] = useState(null)
    const [list, setList] = useState([])
    const [img, setImg] = useState(null)
    const [totalBalance, setTotalBalance] = useState(0.00)
    const [loading, setLoading] = useState(false);
    const [passLoading, setPassLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [key, setKey] = useState(0)
    const [bankType, setBankType] = useState(null)
    const [availableBalance, setAvailableBalance] = useState(null)
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('请上传JPG/PNG文件!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('图片大小不能大于2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const handleChange = (info) => {
        console.log(info.file)
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            setLoading(false);
            setImageUrl(info.file.response.data);
            // getBase64(info.file.originFileObj, (url) => {
            //     console.log(url)

            // });
        }
    };
    const [form] = Form.useForm();
    useEffect(() => {
        console.log(props)
        toAdd()
        getConfigDetail();
        balanceAccountView()
    }, [])

    const rechargeFundTypeChange = (e) => {
        console.log(e);
        setKey(e.target.value)
    }
    const getConfigDetail = (code) => {
        (async _ => {
            return await configDetailRequest({
                merchantCode: localStorage.appid,
                superMerchantCode: localStorage.appid,
            }
            );
        })().then(res => {
            if (res.code == 0) {
                console.log(res);
                setBankType(res.data.bankType)
            } else {
                message.error(res.msg)
            }
        });
    }
    const balanceAccountView = () => {

        (async _ => {
            return await balanceViewRequest({
                merchantCode: localStorage.appid,
                superMerchantCode: localStorage.appid,
                currentPage: 1,
                pageSize: 10
            }
            );
        })().then(res => {
            if (res.code == 0) {
                console.log(res);
                setAvailableBalance(res.data.availableBalance)
                // setBankType(res.data.bankType)
            } else {
                message.error(res.msg)
            }
        });
    }
    const toAdd = () => {
        (async _ => {
            return await getListRequest({
                merchantCode: localStorage.merchantCode,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            if (res.code == 0) {
                console.log(res)
                setList(res.data.list)

            } else {
                message.info(res.msg)
            }
        });
    }

    const onFinish = () => {
        const values = form.getFieldValue();
        delete values.img;
        let data = {
            ...values,
            completeProofImg: imageUrl,
            merchantCode: props && props.data.merchantCode,
            remark: auditRemark,
            withdrawOrderNo: props && props.data.orderNo,
            employeeId: localStorage.operatorId,
        };
        setPassLoading(true);
        (async _ => {
            return await withdrawTransferRequest(data);
        })().then(res => {
            if (res.code == 0) {
                console.log(res)
                props.callback(true)
                props.closeDialog()
                message.success(res.msg)
                setPassLoading(false);
            } else {
                message.error(res.msg)
                setPassLoading(false);
            }
        });
    }
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    return (
        <div style={{ background: '#ffffff', padding: 10 }}>
            <div>
                <Form
                    name="basic"
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                    // autoComplete="off"
                    form={form}
                >
                    <Row>
                        <Col span={12}>
                            {
                                props && props.data.withdrawType != 0 ?
                                    <>
                                        <div style={{ width: '100%', marginLeft: 20, marginBottom: 20 }} className='borderBottom'>银行信息</div>
                                        <Form.Item label="开户姓名" >
                                            {props && props.data.payeeName}
                                        </Form.Item>
                                        <Form.Item label="提现银行">
                                            {props && props.data.bankName}
                                        </Form.Item>
                                        <Form.Item label="支行名称">
                                            {props && props.data.bankBranchName}
                                        </Form.Item>
                                        <Form.Item label="银行卡号">
                                            {props && props.data.bankCard}
                                        </Form.Item>

                                    </> : null
                            }


                            <div style={{ width: '100%', marginTop: 20, marginLeft: 20, marginBottom: 20 }} className='borderBottom'>提现信息</div>
                            <Form.Item label="商户">
                                {props && props.data.merchantName}
                            </Form.Item>
                            <Form.Item label="提现类型">
                                {props && props.data.withdrawType == 0 ? '店铺余额' : null}
                                {props && props.data.withdrawType == 1 ? '收益账户' : null}
                                {props && props.data.withdrawType == 2 ? '钱包账户' : null}
                                {props && props.data.withdrawType == 8 ? '营销余额' : null}
                            </Form.Item>
                            <Form.Item label="订单编号">
                                {props && props.data.orderNo}
                            </Form.Item>
                            <Form.Item label="申请时间">
                                {props && props.data.createTime}
                            </Form.Item>
                            <Form.Item label="金额">
                                {props && props.data.withdrawAmount}
                            </Form.Item>

                            <Form.Item label="备注">
                                <span>{props && props.data.withdrawRemark}</span>
                            </Form.Item>
                        </Col>
                        <Col span={12}>

                            <div style={{ width: '100%', marginLeft: 20, marginBottom: 20 }} className='borderBottom'></div>
                            {
                                props && props.data.withdrawType == 2 || props.data.withdrawType == 8 ?
                                    <>
                                        <Form.Item
                                            name="type"
                                            label="付款账户"
                                            rules={[{ required: true, message: '请选择付款账户!' }]}
                                        >
                                            <Radio.Group onChange={rechargeFundTypeChange}>
                                                <Radio key={0} value={0}>记账账户</Radio>
                                                {
                                                    bankType == "1" ? <Radio key={1} value={1}>店铺余额</Radio> : null
                                                }

                                            </Radio.Group>
                                        </Form.Item>
                                        {
                                            key == 0 ?
                                                <Form.Item
                                                    colon={false}
                                                    label={<span style={{ color: '#fff' }}
                                                    >银行卡</span>}
                                                    name="accountNo"
                                                >
                                                    <Select placeholder="请选择"
                                                        allowClear
                                                        style={{ width: 200 }}
                                                        onChange={(code) => {
                                                            console.log(code)
                                                            list && list.map((v) => {
                                                                if (code == v.accountNo) {
                                                                    setTotalBalance(v.totalBalance)
                                                                }
                                                            })
                                                        }}
                                                    >

                                                        {
                                                            list && list.map((v) => {
                                                                return <Option rowKey={v.accountNo} key={v.accountNo} value={v.accountNo}>{v.accountName} (*{v.bankCardNo}) {v.bankType == 0 ? '对公' : null}{v.bankType == 1 ? '对私' : null}{v.bankType == 2 ? '对私非法人' : null}</Option>
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item> : null
                                        }

                                        <Form.Item
                                            label='余额'>
                                            {key == 0 ? totalBalance : availableBalance}
                                        </Form.Item>
                                        {
                                            key == "0" ?
                                                <Form.Item
                                                    style={{ marginBottom: '-0.2vh' }}
                                                    name="img"
                                                    label="银行回单"
                                                    rules={[{ required: true, message: '请上传银行回单!' }]}
                                                >
                                                    <Upload
                                                        name="avatar"
                                                        listType="picture-card"
                                                        className="avatar-uploader"
                                                        showUploadList={false}
                                                        action={`${host}/bookRecharge/imgUpload`}
                                                        headers={{ 'token': localStorage.token, }}
                                                        beforeUpload={beforeUpload}
                                                        onChange={handleChange}
                                                        data={file => ({ // data里存放的是接口的请求参数
                                                            img: file,
                                                            merchantCode: localStorage.merchantCode
                                                        })}
                                                    >
                                                        {imageUrl ? (
                                                            <img
                                                                src={imageUrl}
                                                                alt="avatar"
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                            />
                                                        ) : (
                                                            uploadButton
                                                        )}

                                                    </Upload>

                                                </Form.Item> : null
                                        }

                                        {
                                            key == 0 ?
                                                <Form.Item

                                                    label={<span style={{ color: '#fff' }}>提示</span>}
                                                    rules={[{ required: false, message: '请上传银行回单!' }]}
                                                    colon={false}
                                                >
                                                    <span style={{ color: 'red' }}>图片不能大于2M</span>

                                                </Form.Item> : null
                                        }


                                    </> : null
                            }


                            {/* <Form.Item label="备注">
                                {
                                    props && props.data.xq == 1 ?
                                        <span>{props && props.data.withdrawRemark}</span>
                                        :
                                        <TextArea rows={4} onChange={(e) => {
                                            setAuditRemark(e.target.value)
                                        }} />
                                }

                            </Form.Item> */}
                        </Col>
                    </Row>

                </Form>
            </div>
            {
                props && props.data.xq == 1 ? null :
                    <div style={{ textAlign: 'right' }}>
                        <Button type="primary" style={{ marginLeft: 20 }}
                            onClick={() => {
                                onFinish()
                            }}
                        loading ={passLoading}>通过</Button>
                    </div>
            }



        </div >
    )
}

export default LnstitutionsInList;