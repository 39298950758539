import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useNavigate, useLocation } from 'react-router-dom';

import './style.less'
import {
    Form,
    Row,
    Col,
    Button,
    Table,
    DatePicker,
    Input,
    Select,
    message,
    Popconfirm,
    Tabs,
    Radio,
    Steps,
    Calendar,
} from 'antd';
import { settleConfigSaveRequest, settleConfigDetailRequest } from '../../../api/api'
import { useCallbackState } from '../../../util/useCallbackState'
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const { Step } = Steps;
const { Option } = Select;
const layout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 16 },
};
let children = []
for (let i = 1; i <= 31; i++) {
    children.push(<Option key={i}>{i}日</Option>);
}
const LnstitutionsInList = () => {
    const [form] = Form.useForm()
    const [datetype, setDateType] = useState('0');
    const [datetype1, setDateType1] = useState('0');
    const [datetype2, setDateType2] = useState('0');
    const [datetype3, setDateType3] = useState('0');
    const [datetype4, setDateType4] = useState('0');
    const [datetype5, setDateType5] = useState('0');

    const [data, setData] = useCallbackState(null)
    const { state } = useLocation();
    const navigate = useNavigate()
    useEffect(() => {
        settleConfigDetail({ merchantCode: state.merchantCode })
    }, [])
    const onFinish = (values) => {
        values.beginTime = values.date && values.date[0].format('YYYY-MM-DD')
        values.endTime = values.date && values.date[1].format('YYYY-MM-DD')
        values.creditAmount = values.creditAmount ? values.creditAmount : 0
        delete values.date;
        settleConfigSave(values)
    }

    const settleConfigDetail = (merchantCode) => {
        (async _ => {
            return await settleConfigDetailRequest(merchantCode);
        })().then(res => {
            if (res.code == 0) {
                form.setFieldsValue({
                    walletSettlementType: res.data && res.data.walletSettlementType,
                    walletSettlementValue: res.data && res.data.walletSettlementValue,
                    douyinSettlementType: res.data && res.data.douyinSettlementType,
                    douyinSettlementValue: res.data && res.data.douyinSettlementValue,
                    meituanSettlementType: res.data && res.data.meituanSettlementType,
                    meituanSettlementValue: res.data && res.data.meituanSettlementValue,
                    eleSettlementType: res.data && res.data.eleSettlementType,
                    eleSettlementValue: res.data && res.data.eleSettlementValue,
                    settlementType: res.data && res.data.settlementType,
                    settlementValue: res.data && res.data.settlementValue,
                    payCardSettlementType: res.data && res.data.payCardSettlementType,
                    payCardSettlementValue: res.data && res.data.payCardSettlementValue,
                    memberPaySettlementType: res.data && res.data.memberPaySettlementType,
                    memberPaySettlementValue: res.data && res.data.memberPaySettlementValue,
                    memberPayRate: res.data && res.data.memberPayRate,

                    creditAmount: res.data && res.data.creditAmount.toString(),
                    douyinMerchantId: res.data && res.data.douyinMerchantId,
                    meituanMerchantId: res.data && res.data.meituanMerchantId,
                    eleMerchantId: res.data && res.data.eleMerchantId,

                    date: res.data && res.data.creditBeginTime ? [moment(res.data && res.data.creditBeginTime, dateFormat), moment(res.data && res.data.creditEndTime, dateFormat)] : undefined,
                })
                setData(res.data, (data) => {

                });

            } else {
                message.error(res.msg)
            }
        });
    }
    const cell = () => {
        navigate(-1)
    }

    const dateType = (data) => {
        setDateType(data)
    };
    const dateType1 = (data) => {
        setDateType1(data)
    };
    const dateType2 = (data) => {
        setDateType2(data)
    };
    const dateType3 = (data) => {
        setDateType3(data)
    };
    const dateType4 = (data) => {
        setDateType4(data)
    };
    const dateType5 = (data) => {
        setDateType5(data)
    };
    const settleConfigSave = (data) => {
        (async _ => {
            return await settleConfigSaveRequest(
                {
                    ...data,
                    merchantCode: state.merchantCode,
                    merchantName: state.merchantName,
                    merchantId: state.merchantId,
                    superMerchantCode: localStorage.appid,
                    saveType: 1,
                }
            );
        })().then(res => {
            if (res.code == 0) {
                message.success(res.msg)
                navigate('/ws/tradeSetting/list')
            } else {
                message.error(res.msg)
            }
        });
    }
    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    };
    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            <div>
                <Form
                    form={form}
                    name="basic"
                    {...layout}
                    onFinish={onFinish}
                >
                    <div style={{ width: '100%', marginLeft: 20, marginTop: 20, marginBottom: 20 }} className='borderBottom'>基本信息</div>
                    <Row style={{ marginBottom: '-1vh' }}>
                        <Col span={8}>
                            <Form.Item
                                label="门店信息："
                            >
                                {state && state.merchantName}/{state && state.merchantCode}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '-30px' }}>
                        <Col span={8}>
                            <Form.Item
                                label="收银结算周期："
                                name="settlementType"
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择结算周期!',
                                    },
                                ]}>
                                <Select placeholder="请选择"
                                    style={{ width: 180 }}
                                    onChange={dateType}
                                >
                                    <Option value={0}>日</Option>
                                    <Option value={1}>周</Option>
                                    <Option value={2}>月</Option>
                                    <Option value={3}>工作日</Option>
                                    <Option value={4}>汇总日</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8} pull={2}>
                            {
                                datetype == 0 || datetype == 3 || datetype == 4 ?
                                    <Form.Item
                                        noStyle
                                        name="settlementValue"
                                        rules={[
                                            {
                                                required: false,
                                                message: '请选择!',
                                            },
                                        ]}>
                                        <Input style={{ width: 200 }} placeholder='请输入几日' />
                                    </Form.Item> : ''
                            }
                            {
                                datetype == 1 ?
                                    <Form.Item
                                        noStyle
                                        name="settlementValue"
                                        rules={[
                                            {
                                                required: false,
                                                message: '请选择!',
                                            },
                                        ]}>
                                        <Select
                                            style={{ width: 200 }}
                                            placeholder='请选择星期几'
                                        >
                                            <Option key='1'>星期一</Option>
                                            <Option key='2'>星期二</Option>
                                            <Option key='3'>星期三</Option>
                                            <Option key='4'>星期四</Option>
                                            <Option key='5'>星期五</Option>
                                            <Option key='6'>星期六</Option>
                                            <Option key='7'>星期日</Option>
                                        </Select>
                                    </Form.Item> : ''
                            }
                            {
                                datetype == 2 ?
                                    <Form.Item
                                        noStyle
                                        name="settlementValue"
                                        rules={[
                                            {
                                                required: false,
                                                message: '请选择!',
                                            },
                                        ]}>
                                        <Select
                                            style={{ width: 200 }}
                                            placeholder='请选择'
                                        >
                                            {children}
                                        </Select>
                                    </Form.Item> : ''
                            }
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 0 }}>
                        <Col span={12}>
                            <Form.Item
                                wrapperCol={{
                                    offset: 5,
                                    span: 16,
                                }}
                            >
                                <span style={{ color: '#969799', fontSize: 12 }}>一天只能修改一次，请谨慎修改 未结算数据会在次日结算</span>
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '-30px' }}>
                        <Col span={8}>
                            <Form.Item
                                label="营销结算周期："
                                name="payCardSettlementType"
                                rules={[
                                    {
                                        required: false,
                                        message: '请选择结算周期!',
                                    },
                                ]}>
                                <Select placeholder="请选择"
                                    style={{ width: 180 }}
                                    onChange={dateType1}
                                >
                                    <Option value={0}>日</Option>
                                    <Option value={1}>周</Option>
                                    <Option value={2}>月</Option>
                                    <Option value={3}>工作日</Option>
                                    <Option value={4}>汇总日</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8} pull={2}>
                            {
                                datetype1 == 0 || datetype1 == 3 || datetype1 == 4 ?
                                    <Form.Item
                                        noStyle
                                        name="payCardSettlementValue"
                                        rules={[
                                            {
                                                required: false,
                                                message: '请选择!',
                                            },
                                        ]}>
                                        <Input style={{ width: 200 }} placeholder='请输入几日' />
                                    </Form.Item> : ''
                            }
                            {
                                datetype1 == 1 ?
                                    <Form.Item
                                        noStyle
                                        name="payCardSettlementValue"
                                        rules={[
                                            {
                                                required: false,
                                                message: '请选择!',
                                            },
                                        ]}>
                                        <Select
                                            style={{ width: 200 }}
                                            placeholder='请选择星期几'
                                        >
                                            <Option key='1'>星期一</Option>
                                            <Option key='2'>星期二</Option>
                                            <Option key='3'>星期三</Option>
                                            <Option key='4'>星期四</Option>
                                            <Option key='5'>星期五</Option>
                                            <Option key='6'>星期六</Option>
                                            <Option key='7'>星期日</Option>
                                        </Select>
                                    </Form.Item> : ''
                            }
                            {
                                datetype1 == 2 ?
                                    <Form.Item
                                        noStyle
                                        name="payCardSettlementValue"
                                        rules={[
                                            {
                                                required: false,
                                                message: '请选择!',
                                            },
                                        ]}>
                                        <Select
                                            style={{ width: 200 }}
                                            placeholder='请选择'
                                        >
                                            {children}
                                        </Select>
                                    </Form.Item> : ''
                            }
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '-2vh', marginTop: 0 }}>
                        <Col span={12}>
                            <Form.Item
                                wrapperCol={{
                                    offset: 5,
                                    span: 16,
                                }}
                            >
                                <span style={{ color: '#969799', fontSize: 12 }}>一天只能修改一次，请谨慎修改 未结算数据会在次日结算</span>
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '-30px' }}>
                        <Col span={8}>
                            <Form.Item
                                label="会员结算周期："
                                name="memberPaySettlementType"
                                rules={[
                                    {
                                        required: false,
                                        message: '请选择结算周期!',
                                    },
                                ]}>
                                <Select placeholder="请选择"
                                    style={{ width: 180 }}
                                    onChange={dateType5}
                                >
                                    <Option value={0}>日</Option>
                                    <Option value={1}>周</Option>
                                    <Option value={2}>月</Option>
                                    <Option value={3}>工作日</Option>
                                    <Option value={4}>汇总日</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8} pull={2}>
                            {
                                datetype5 == 0 || datetype5 == 3 || datetype5 == 4 ?
                                    <Form.Item
                                        noStyle
                                        name="memberPaySettlementValue"
                                        rules={[
                                            {
                                                required: false,
                                                message: '请选择!',
                                            },
                                        ]}>
                                        <Input style={{ width: 200 }} placeholder='请输入几日' />
                                    </Form.Item> : ''
                            }
                            {
                                datetype5 == 1 ?
                                    <Form.Item
                                        noStyle
                                        name="memberPaySettlementValue"
                                        rules={[
                                            {
                                                required: false,
                                                message: '请选择!',
                                            },
                                        ]}>
                                        <Select
                                            style={{ width: 200 }}
                                            placeholder='请选择星期几'
                                        >
                                            <Option key='1'>星期一</Option>
                                            <Option key='2'>星期二</Option>
                                            <Option key='3'>星期三</Option>
                                            <Option key='4'>星期四</Option>
                                            <Option key='5'>星期五</Option>
                                            <Option key='6'>星期六</Option>
                                            <Option key='7'>星期日</Option>
                                        </Select>
                                    </Form.Item> : ''
                            }
                            {
                                datetype5 == 2 ?
                                    <Form.Item
                                        noStyle
                                        name="memberPaySettlementValue"
                                        rules={[
                                            {
                                                required: false,
                                                message: '请选择!',
                                            },
                                        ]}>
                                        <Select
                                            style={{ width: 200 }}
                                            placeholder='请选择'
                                        >
                                            {children}
                                        </Select>
                                    </Form.Item> : ''
                            }
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '-2vh', marginTop: 0 }}>
                        <Col span={12}>
                            <Form.Item
                                wrapperCol={{
                                    offset: 5,
                                    span: 16,
                                }}
                            >
                                <span style={{ color: '#969799', fontSize: 12 }}>一天只能修改一次，请谨慎修改 未结算数据会在次日结算</span>
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item
                                label="抖音门店ID"
                                name="douyinMerchantId"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please input your password!',
                                    },
                                ]}
                            >
                                <Input placeholder='抖音门店ID' style={{ width: 380 }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '-30px' }}>
                        <Col span={8}>
                            <Form.Item
                                label="抖音结算周期："
                                name="douyinSettlementType"
                                rules={[
                                    {
                                        required: false,
                                        message: '请选择结算周期!',
                                    },
                                ]}>
                                <Select placeholder="请选择"
                                    style={{ width: 180 }}
                                    onChange={dateType2}
                                >
                                    <Option value={0}>日</Option>
                                    <Option value={1}>周</Option>
                                    <Option value={2}>月</Option>
                                    <Option value={3}>工作日</Option>
                                    <Option value={4}>汇总日</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8} pull={2}>
                            {
                                datetype2 == 0 || datetype2 == 3 || datetype2 == 4 ?
                                    <Form.Item
                                        noStyle
                                        name="douyinSettlementValue"
                                        rules={[
                                            {
                                                required: false,
                                                message: '请选择!',
                                            },
                                        ]}>
                                        <Input style={{ width: 200 }} placeholder='请输入几日' />
                                    </Form.Item> : ''
                            }
                            {
                                datetype2 == 1 ?
                                    <Form.Item
                                        noStyle
                                        name="douyinSettlementValue"
                                        rules={[
                                            {
                                                required: false,
                                                message: '请选择!',
                                            },
                                        ]}>
                                        <Select
                                            style={{ width: 200 }}
                                            placeholder='请选择星期几'
                                        >
                                            <Option key='1'>星期一</Option>
                                            <Option key='2'>星期二</Option>
                                            <Option key='3'>星期三</Option>
                                            <Option key='4'>星期四</Option>
                                            <Option key='5'>星期五</Option>
                                            <Option key='6'>星期六</Option>
                                            <Option key='7'>星期日</Option>
                                        </Select>
                                    </Form.Item> : ''
                            }
                            {
                                datetype2 == 2 ?
                                    <Form.Item
                                        noStyle
                                        name="douyinSettlementValue"
                                        rules={[
                                            {
                                                required: false,
                                                message: '请选择!',
                                            },
                                        ]}>
                                        <Select
                                            style={{ width: 200 }}
                                            placeholder='请选择'
                                        >
                                            {children}
                                        </Select>
                                    </Form.Item> : ''
                            }
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '-2vh', marginTop: 0 }}>
                        <Col span={12}>
                            <Form.Item
                                wrapperCol={{
                                    offset: 5,
                                    span: 16,
                                }}
                            >
                                <span style={{ color: '#969799', fontSize: 12 }}>一天只能修改一次，请谨慎修改 未结算数据会在次日结算</span>
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item
                                label="美团门店ID"
                                name="meituanMerchantId"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please input your password!',
                                    },
                                ]}
                            >
                                <Input placeholder='美团门店ID' style={{ width: 380 }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '-30px' }}>
                        <Col span={8}>
                            <Form.Item
                                label="美团结算周期："
                                name="meituanSettlementType"
                                rules={[
                                    {
                                        required: false,
                                        message: '请选择结算周期!',
                                    },
                                ]}>
                                <Select placeholder="请选择"
                                    style={{ width: 180 }}
                                    onChange={dateType4}
                                >
                                    <Option value={0}>日</Option>
                                    <Option value={1}>周</Option>
                                    <Option value={2}>月</Option>
                                    <Option value={3}>工作日</Option>
                                    <Option value={4}>汇总日</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8} pull={2}>
                            {
                                datetype4 == 0 || datetype4 == 3 || datetype4 == 4 ?
                                    <Form.Item
                                        noStyle
                                        name="meituanSettlementValue"
                                        rules={[
                                            {
                                                required: false,
                                                message: '请选择!',
                                            },
                                        ]}>
                                        <Input style={{ width: 200 }} placeholder='请输入几日' />
                                    </Form.Item> : ''
                            }
                            {
                                datetype4 == 1 ?
                                    <Form.Item
                                        noStyle
                                        name="meituanSettlementValue"
                                        rules={[
                                            {
                                                required: false,
                                                message: '请选择!',
                                            },
                                        ]}>
                                        <Select
                                            style={{ width: 200 }}
                                            placeholder='请选择星期几'
                                        >
                                            <Option key='1'>星期一</Option>
                                            <Option key='2'>星期二</Option>
                                            <Option key='3'>星期三</Option>
                                            <Option key='4'>星期四</Option>
                                            <Option key='5'>星期五</Option>
                                            <Option key='6'>星期六</Option>
                                            <Option key='7'>星期日</Option>
                                        </Select>
                                    </Form.Item> : ''
                            }
                            {
                                datetype4 == 2 ?
                                    <Form.Item
                                        noStyle
                                        name="meituanSettlementValue"
                                        rules={[
                                            {
                                                required: false,
                                                message: '请选择!',
                                            },
                                        ]}>
                                        <Select
                                            style={{ width: 200 }}
                                            placeholder='请选择'
                                        >
                                            {children}
                                        </Select>
                                    </Form.Item> : ''
                            }
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '-2vh', marginTop: 0 }}>
                        <Col span={12}>
                            <Form.Item
                                wrapperCol={{
                                    offset: 5,
                                    span: 16,
                                }}
                            >
                                <span style={{ color: '#969799', fontSize: 12 }}>一天只能修改一次，请谨慎修改 未结算数据会在次日结算</span>
                            </Form.Item>

                        </Col>
                    </Row>

                    <Row>
                        <Col span={8}>
                            <Form.Item
                                label="饿了么门店ID"
                                name="eleMerchantId"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please input your password!',
                                    },
                                ]}
                            >
                                <Input placeholder='美团门店ID' style={{ width: 380 }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '-30px' }}>
                        <Col span={8}>
                            <Form.Item
                                label="饿了么结算周期："
                                name="eleSettlementType"
                                rules={[
                                    {
                                        required: false,
                                        message: '请选择结算周期!',
                                    },
                                ]}>
                                <Select placeholder="请选择"
                                    style={{ width: 180 }}
                                    onChange={dateType4}
                                >
                                    <Option value={0}>日</Option>
                                    <Option value={1}>周</Option>
                                    <Option value={2}>月</Option>
                                    <Option value={3}>工作日</Option>
                                    <Option value={4}>汇总日</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8} pull={2}>
                            {
                                datetype4 == 0 || datetype4 == 3 || datetype4 == 4 ?
                                    <Form.Item
                                        noStyle
                                        name="eleSettlementValue"
                                        rules={[
                                            {
                                                required: false,
                                                message: '请选择!',
                                            },
                                        ]}>
                                        <Input style={{ width: 200 }} placeholder='请输入几日' />
                                    </Form.Item> : ''
                            }
                            {
                                datetype4 == 1 ?
                                    <Form.Item
                                        noStyle
                                        name="eleSettlementValue"
                                        rules={[
                                            {
                                                required: false,
                                                message: '请选择!',
                                            },
                                        ]}>
                                        <Select
                                            style={{ width: 200 }}
                                            placeholder='请选择星期几'
                                        >
                                            <Option key='1'>星期一</Option>
                                            <Option key='2'>星期二</Option>
                                            <Option key='3'>星期三</Option>
                                            <Option key='4'>星期四</Option>
                                            <Option key='5'>星期五</Option>
                                            <Option key='6'>星期六</Option>
                                            <Option key='7'>星期日</Option>
                                        </Select>
                                    </Form.Item> : ''
                            }
                            {
                                datetype4 == 2 ?
                                    <Form.Item
                                        noStyle
                                        name="eleSettlementValue"
                                        rules={[
                                            {
                                                required: false,
                                                message: '请选择!',
                                            },
                                        ]}>
                                        <Select
                                            style={{ width: 200 }}
                                            placeholder='请选择'
                                        >
                                            {children}
                                        </Select>
                                    </Form.Item> : ''
                            }
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '-2vh', marginTop: 0 }}>
                        <Col span={12}>
                            <Form.Item
                                wrapperCol={{
                                    offset: 5,
                                    span: 16,
                                }}
                            >
                                <span style={{ color: '#969799', fontSize: 12 }}>一天只能修改一次，请谨慎修改 未结算数据会在次日结算</span>
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '-30px' }}>
                        <Col span={8}>
                            <Form.Item
                                label="钱包结算周期："
                                name="walletSettlementType"
                                rules={[
                                    {
                                        required: false,
                                        message: '请选择结算周期!',
                                    },
                                ]}>
                                <Select placeholder="请选择"
                                    style={{ width: 180 }}
                                    onChange={dateType3}
                                >
                                    <Option value={0}>日</Option>
                                    <Option value={1}>周</Option>
                                    <Option value={2}>月</Option>
                                    <Option value={3}>工作日</Option>
                                    <Option value={4}>汇总日</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8} pull={2}>
                            {
                                datetype3 == 0 || datetype3 == 3 || datetype3 == 4 ?
                                    <Form.Item
                                        noStyle
                                        name="walletSettlementValue"
                                        rules={[
                                            {
                                                required: false,
                                                message: '请选择!',
                                            },
                                        ]}>
                                        <Input style={{ width: 200 }} placeholder='请输入几日' />
                                    </Form.Item> : ''
                            }
                            {
                                datetype3 == 1 ?
                                    <Form.Item
                                        noStyle
                                        name="walletSettlementValue"
                                        rules={[
                                            {
                                                required: false,
                                                message: '请选择!',
                                            },
                                        ]}>
                                        <Select
                                            style={{ width: 200 }}
                                            placeholder='请选择星期几'
                                        >
                                            <Option key='1'>星期一</Option>
                                            <Option key='2'>星期二</Option>
                                            <Option key='3'>星期三</Option>
                                            <Option key='4'>星期四</Option>
                                            <Option key='5'>星期五</Option>
                                            <Option key='6'>星期六</Option>
                                            <Option key='7'>星期日</Option>
                                        </Select>
                                    </Form.Item> : ''
                            }
                            {
                                datetype3 == 2 ?
                                    <Form.Item
                                        noStyle
                                        name="walletSettlementValue"
                                        rules={[
                                            {
                                                required: false,
                                                message: '请选择!',
                                            },
                                        ]}>
                                        <Select
                                            style={{ width: 200 }}
                                            placeholder='请选择'
                                        >
                                            {children}
                                        </Select>
                                    </Form.Item> : ''
                            }
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '-2vh', marginTop: 0 }}>
                        <Col span={12}>
                            <Form.Item
                                wrapperCol={{
                                    offset: 5,
                                    span: 16,
                                }}
                            >
                                <span style={{ color: '#969799', fontSize: 12 }}>一天只能修改一次，请谨慎修改 未结算数据会在次日结算</span>
                            </Form.Item>

                        </Col>
                    </Row>
                    {/* <Form.Item
                        label="机构类型："
                    >
                        悦喵方庄店/EW_3490987076
                    </Form.Item> */}

                    <div style={{ width: '100%', marginTop: 20, marginLeft: 20, marginBottom: 20 }} className='borderBottom'>会员交易手续费</div>
                    <Row style={{ marginBottom: '-1vh' }}>
                        <Col span={8}>
                            <Form.Item name="memberPayRate" label="发卡门店消费：" >
                                <Input suffix="%" style={{ width: 300 }} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <div style={{ width: '100%', marginTop: 20, marginLeft: 20, marginBottom: 20 }} className='borderBottom'>采购授信规则</div>
                    <Row style={{ marginBottom: '-1vh' }}>
                        <Col span={8}>
                            <Form.Item name="creditAmount" label="采购授信额度：" >
                                <Input prefix="￥" style={{ width: 300 }} />
                            </Form.Item>
                        </Col>
                    </Row>


                    {/* <Form.Item label="卡类型" name="yinhang">
                        <Radio.Group>
                            <Radio value="apple"> 周卡 </Radio>
                        </Radio.Group>
                    </Form.Item> */}
                    <Row style={{ marginBottom: '-1vh' }}>
                        <Col span={8}>
                            <Form.Item label="账单日：" >
                                周一
                                {/* <DatePicker onChange={onDateChange} style={{ width: 300 }} /> */}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '-1vh' }}>
                        <Col span={8}>
                            <Form.Item label="还款日：">
                                周一
                                {/* <DatePicker onChange={onDateChange} style={{ width: 300 }} /> */}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '-1vh' }}>
                        <Col span={8}>
                            <Form.Item label="授权有效期：" name="date" >
                                <RangePicker disabledDate={disabledDate} style={{ width: 300 }} />
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* <div style={{ width: '100%', marginTop: 20, marginLeft: 20, marginBottom: 20 }} className='borderBottom'>三方手续费规则 </div>
                    <Form.Item label="采购授信额度：" name="sad"
                        rules={[
                            {
                                required: true,
                                message: '请输入采购授信额度！',
                            },
                        ]}
                    >
                        <Input addonAfter='%' placeholder='请输入采购授信额度：' style={{ width: 300 }} />
                    </Form.Item>
                    <Form.Item label="支付宝手续费：" name="sad"
                        rules={[
                            {
                                required: true,
                                message: '请输入采购授信额度！',
                            },
                        ]}
                    >
                        <Input addonAfter='%' placeholder='请输入采购授信额度：' style={{ width: 300 }} />
                    </Form.Item> */}

                    <Row style={{ marginBottom: '-1vh' }}>
                        <Col span={8} style={{ textAlign: 'center' }}>
                            <Button type="primary" htmlType='submit'>保存</Button>
                            <Button style={{ marginLeft: 20 }} onClick={cell}>取消</Button>

                        </Col>
                    </Row>
                </Form>
            </div >



        </div >
    )
}

export default LnstitutionsInList;