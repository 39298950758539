import React, { Component, useState, useEffect, } from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import {
    Form,
    Row,
    Col,
    Button,
    Table,
    DatePicker,
    Input,
    Select,
    message,
    Popconfirm,
    Tabs,
    Radio
} from 'antd';
import { freezeListRequest, settleMerchantListRequest } from '../../../api/api'
import { useCallbackState } from '../../../util/useCallbackState'
const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;
// import host from '../../../util/host';


const FormItem = Form.Item;
const Search = Input.Search;
const dateFormat = 'YYYY-MM-DD';

const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
};


const Recharge = () => {
    const [form] = Form.useForm();
    const { search } = useLocation()
    const { state } = useLocation()
    const [list, setList] = useState([])
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [total, setTotal] = useState()
    // const [merchantCode, setMerchantCode] = useState(state.state && state.state.row && state.state.row.merchantCode)
    const [merchantCode, setMerchantCode] = useState(state && state.merchantCode ? state.merchantCode : localStorage.hxState == 'true' ? null : localStorage.merchantCode)
    const [merchantList, setMerchantList] = useState([])
    const superMerchantCode = localStorage.appid

    useEffect(() => {
        console.log(state);
        settleMerchantList()
        freezeList()

    }, [currentPage, pageNum]) //所有更新都执行
    const settleMerchantList = () => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode: localStorage.merchantCode,
                superMerchantCode: localStorage.appid
            });
        })().then(res => {
            if (res.code == 0) {
                setMerchantList(res.data)
            } else {

            }
        });
    }

    const columns = [
        {
            title: '门店名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
            render: (text, row) => {
                return (
                    <div>
                        {
                            localStorage.salesEmployeeId == 'null' ?
                                <div>
                                    <div>{row.merchantName}</div>
                                    <div>{row.employeeName} </div>
                                </div>
                                :
                                <span>{row.employeeName}</span>
                        }
                    </div>
                )
            }

        },
        {
            title: '操作时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '流水号',
            dataIndex: 'orderNoExt',
            key: 'orderNoExt',
        },
        {
            title: '业务类型',
            dataIndex: 'busType',
            key: 'busType',
            render: (text, row) => {
                return (
                    <div>
                        {row.busType == 0 ? '提现' : ''}
                        {row.busType == 1 ? '转账' : ''}
                        {row.busType == 2 ? '提现转账' : ''}
                        {row.busType == 3 ? '提现手续费' : ''}
                    </div>
                )
            }
        },
        {
            title: '操作类型',
            dataIndex: 'tradeType',
            key: 'tradeType',
            render: (text, row) => {
                return (
                    <div>
                        {row.tradeType == 1 ? '冻结' : ''}
                        {row.tradeType == 2 ? '解冻' : ''}
                    </div>
                )
            }
        },

        {
            title: '金额',
            dataIndex: 'freezenAmt',
            key: 'freezenAmt',
            render: (text, row) => {
                return (
                    <div>
                        {row.tradeType == 1 ? <span>{row.freezenAmt}</span> : <span>{row.unfreezenAmt}</span>}
                    </div>
                )

            }
        },
        {
            title: '说明',
            dataIndex: 'remark',
            key: 'remark',
        },
        {
            title: '业务单号',
            dataIndex: 'orderNo',
            key: 'orderNo',
        },


    ];

    const freezeList = (data) => {
        let values = form.getFieldValue();
        let params = {
            ...data,
            ...values,
            currentPage: data && data.currentPage ? data.currentPage : currentPage,
            pageSize: pageNum,
            merchantCode: merchantCode,
            superMerchantCode,
            employeeId: localStorage.salesEmployeeId != 'null' ? localStorage.salesEmployeeId : state && state.employeeId
        };
        (async _ => {
            return await freezeListRequest(params);
        })().then(res => {
            if (res.code == 0) {
                setList(res.data.list)
                setTotal(res.data.totalCount)
            } else {

            }
        });
    }
    const onChangPage = (currentPage, pageNum) => {
        setCurrentPage(currentPage, () => {
            // freezeList()
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        setPageNum((currentPage, pageNum), () => {
            // freezeList()
        })
    }
    const onFinish = (values) => {
        values.currentPage = 1;
        setCurrentPage(1)
        freezeList(values)
    }
    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            <Form
                name="time_related_controls"
                {...layout}
                style={{ background: '#F7F8FA', padding: 20, }}
                onFinish={onFinish}
                form={form}
                initialValues={{
                    merchantCode: state && state.merchantCode ? state.merchantCode : null
                }}
            >
                <Row>

                    <Col span={8}>
                        <Form.Item
                            name="orderNoExt"
                            label="流水号："
                        >
                            <Input style={{ width: 200 }} placeholder='请输入流水号' />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="busType"
                            label="业务类型"
                        >
                            <Select style={{ width: 200 }}>
                                <Option value="0">提现</Option>
                                <Option value="1">转账</Option>
                                <Option value="2">提现转账</Option>
                                <Option value="3">提现手续费</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item
                            name="orderNo"
                            label="业务单号："
                        >
                            <Input style={{ width: 200 }} />
                        </Form.Item>
                    </Col>
                    {
                        localStorage.salesEmployeeId == 'null' ?
                            <Col span={8}>

                                {
                                    localStorage.hxState == 'true' ?
                                        <Form.Item
                                            name="merchantCode"
                                            label="商户名称："
                                            rules={[{ required: false, message: 'Please input your username!' }]}
                                        >
                                            <Select
                                                placeholder="请选择"
                                                allowClear
                                                style={{ width: 200 }}
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.includes(input)}
                                                onChange={(e) => {
                                                    console.log(e);
                                                    setMerchantCode(e)
                                                }}
                                            >
                                                <Option key={null} value={null}>全部</Option>
                                                {
                                                    merchantList && merchantList.map((v) => {
                                                        return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                                    })
                                                }

                                            </Select>

                                        </Form.Item>
                                        :
                                        <Form.Item
                                            name="merchantCode"
                                            label="商户名称："
                                            rules={[{ required: false, message: 'Please input your username!' }]}
                                        >
                                            {localStorage.merchantName}
                                        </Form.Item>
                                }

                            </Col> : null
                    }


                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item
                            wrapperCol={{
                                offset: 5,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                筛选
                            </Button>
                            <Button type="link" onClick={() => {
                                form.resetFields();
                            }}>
                                重置筛选条件
                            </Button>
                        </Form.Item>

                        {/* <Button
                            style={{ margin: '0 8px' }}

                        >
                            导出
                        </Button>
                        <Button type="link"  >
                            查看已导出列表
                        </Button> */}

                    </Col>
                </Row>
                <Row>

                </Row>
            </Form>
            <Table
                locale={{
                    emptyText: "没有更多数据了"
                }}
                style={{ marginTop: 10 }}
                // loading={loading}
                rowKey="id"
                dataSource={list}
                columns={columns}
                // scroll={{x: 900}}
                pagination={{
                    total,
                    showSizeChanger: true,
                    current: currentPage,
                    pageSize: pageNum,
                    // position: ["bottomCenter"],
                    showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                    onChange: (k, v) => onChangPage(k, v),
                    onShowSizeChange: (k, v) => onChangPageNum(k, v),
                }}
            />



        </div >
    )
}
export default Recharge;
