import React, { useState, useEffect, useReducer } from 'react'
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import './style.less'
import {
    Form,
    Row,
    Col,
    Button,
    DatePicker,
    Input,
    Select,
    message,
    Radio,
    Modal,
    Cascader,
    InputNumber
} from 'antd';
import {
    expenseTypeEditRequest,
    expenseEditRequest,
    expenseTypeListRequest,
    shopAreaListRequest,
    groupListRequest,
    shopManageFeeSaveRequest,
    merchantInfoListRequest,
    shopManageFeeDetailRequest,
    updateStatusRequest
} from '../../../api/api'
import QRCode from 'qrcode.react';
const dateFormat = 'YYYY-MM-DD';
const { Option } = Select;
const { RangePicker } = DatePicker;
const layout2 = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
};

const BillsAdd = () => {
    const [form] = Form.useForm()
    const { state } = useLocation()
    const [manageAmounts, setManageAmounts] = useState([])
    const [purchaseTaxRates, setPurchaseTaxRates] = useState([])
    const [data, setData] = useState(null)
    const [qrcode, setQrcode] = useState(undefined)
    let [merchantGroup, setMerchantGroup] = useState([]);

    const navigate = useNavigate()
    useEffect(() => {
        if (state && state.id) {
            shopManageFeeDetail()
        }
    }, [])


    const shopManageFeeDetail = (code) => {
        (async _ => {
            return await shopManageFeeDetailRequest({
                appId: localStorage.appid,
                superMerchantCode: localStorage.appid,
                id: state && state.id,
            });
        })().then(res => {
            if (res.code == "SUCCESS") {
                let row = res.result;
                let areaArr = [];
                let merchantList = [];
                res.result.merchantGroup.map((v) => {
                    merchantList.push(v.id)
                })
                areaArr.push(res.result.provinceCode);
                areaArr.push(res.result.cityCode);
                form.setFieldsValue({
                    area: areaArr,
                    manageMerchantType: res.result.manageMerchantType,
                    expenseName: merchantList,
                    manageAmount: res.result.manageAmount,
                    purchaseAmount: res.result.purchaseAmount,
                    purchaseTaxRate: res.result.purchaseTaxRate,
                    possessionDays: res.result.possessionDays,
                })
                setData(res.result)
                setMerchantGroup(res.result.merchantGroup)
                setManageAmounts(res.result.manageAmounts)
                setPurchaseTaxRates(res.result.purchaseTaxRates)
                setQrcode(`https://clubb2b.liantuofu.com/share/?&appId=${row.superMerchantCode}&operatorId=${row.operatorId}&id=${row.id}&pcode=${row.superMerchantCode}`)
                // setQrcode(`https://preclubb2b.liantuofu.com/share/?&appId=EW_N5464054266&operatorId=10235265&id=60&pcode=EW_N5464054266`)
                // setQrcode(`https://preclubb2b.liantuofu.com/aa/?&appId=${row.superMerchantCode}&operatorId=${row.operatorId}&id=${row.id}&partnerMerchantCode=${row.merchantCode}`)
            } else {
                message.error(res.msg)
            }
        });
    }

    const cell = () => {
        navigate(-1)
    }


    const updateStatus = (code) => {

        (async _ => {
            return await updateStatusRequest({
                status: code,
                id: state && state.id,
                merchantCode: data && data.merchantCode,
                superMerchantCode: data && data.superMerchantCode,
            });
        })().then(res => {
            if (res.code == "SUCCESS") {
                shopManageFeeDetail()
            } else {
                message.error(res.msg)
            }
        });
    }

    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            <div>

                <Form
                    name="dynamic_form_item"
                    form={form}
                    {...layout2}
                    initialValues={{

                    }}
                    autoComplete="off"
                >
                    <div style={{ width: '100%', marginLeft: 20, marginTop: 20, marginBottom: 20 }} className='borderBottom'>基本信息</div>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="支出机构：" >
                                总部
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="类型" name="manageMerchantType">
                                <span>
                                    {data && data.manageMerchantType == 0 ? '良记订货门店' : ''}
                                    {data && data.manageMerchantType == 1 ? '良记会员门店' : ''}
                                </span>
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row>
                        <Col span={12}>
                            <Form.Item label="区域" name="area"
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择区域!',
                                    },
                                ]}
                            >
                                <span>{data && data.merchantArea}</span>

                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={12}>
                            <Form.Item label="机构组"
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择机构组!',
                                    },
                                ]}
                                name="expenseName">
                                {
                                    merchantGroup && merchantGroup.map((v) => {
                                        return <span>{v.merchantName};</span>
                                    })
                                }

                            </Form.Item>
                        </Col>
                    </Row>



                    <div style={{ width: '100%', marginTop: 20, marginLeft: 20, marginBottom: 20 }} className='borderBottom'>缴费信息</div>

                    <Row>
                        <Col span={12}>
                            <Form.Item
                                name="manageAmount"
                                label="总部管理费"
                            >
                                {data && data.manageAmount}
                            </Form.Item>
                        </Col>
                    </Row>
                    {
                        manageAmounts && manageAmounts.map((v, index) => {
                            return <Row key={index}>
                                <Col span={12}>
                                    <Form.Item
                                        name="manageAmount"
                                        label="分账机构"
                                    >
                                        {v.splitMerchantName}  ￥{v.manageAmount}
                                    </Form.Item>
                                </Col>
                            </Row>
                        })
                    }
                    <Row>
                        <Col span={12}>
                            <Form.Item label="采购货款(最小值)" name="purchaseAmount"
                                rules={[
                                    {
                                        required: true,
                                        message: '请填写采购货款!',
                                    },
                                ]}
                            >
                                ￥{data && data.purchaseAmount}
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row>
                        <Col span={12}>
                            <Form.Item
                                name="purchaseTaxRate"
                                label="采购货款返佣"
                            >
                                {data && data.purchaseTaxRate}%
                            </Form.Item>
                        </Col>
                    </Row>
                    {
                        purchaseTaxRates && purchaseTaxRates.map((v, index) => {
                            return <Row key={index}>
                                <Col span={12}>
                                    <Form.Item
                                        name="manageAmount"
                                        label="返佣机构"
                                    >
                                        {v.splitMerchantName}  {v.purchaseTaxRate}%
                                    </Form.Item>
                                </Col>
                            </Row>
                        })
                    }
                    <div style={{ width: '100%', marginTop: 20, marginLeft: 20, marginBottom: 20 }} className='borderBottom'>交易信息</div>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="状态">
                                {data && data.status == 0 ? '进行中' : '已停用'}
                                {data && data.status == 0 ? <a style={{ marginLeft: 20 }} onClick={() => {
                                    updateStatus(1)
                                }}>停用</a> : <a style={{ marginLeft: 20 }} onClick={() => {
                                    updateStatus(0)
                                }}>启用</a>}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="未/已支付">
                                {data && data.payOrderRate}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="创建时间">
                                {data && data.createTime}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="结束时间">
                                {data && data.endTime}
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col span={12}>
                            <Form.Item label="分享">
                                <QRCode
                                    value={qrcode} //value参数为字符串类型
                                    size={200} //二维码的宽高尺寸
                                    fgColor="#000000"  //二维码的颜色
                                />
                            </Form.Item>
                        </Col>
                    </Row> */}
                    <Row>
                        {
                            state && state.edit != 1 ?
                                <Col span={24} style={{ textAlign: 'center' }}>
                                    <Button style={{ marginLeft: 20 }} onClick={cell}>取消</Button>
                                </Col> : null
                        }

                    </Row>
                </Form>
            </div>



        </div >
    )
}

export default BillsAdd;