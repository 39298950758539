import React, { useState, useEffect, useReducer } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import {
    Form,
    Row,
    Col,
    Button,
    Input,
    Select,
    Table,
    Popconfirm,
    DatePicker,
    Modal,
    message
} from 'antd';
import { shopManageFeeOrderListRequest, listForManageRequest, partnerListRequest } from '../../api/api'
import { useCallbackState } from '../../util/useCallbackState'
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};
const { RangePicker } = DatePicker;
const ManagementFeeList = () => {
    const [form] = Form.useForm();
    const { state } = useLocation();
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [list, setList] = useState([])
    const [merchantCode, setMerchantCode] = useCallbackState(state && state.merchantCode ? state.merchantCode : localStorage.merchantCode)
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [total, setTotal] = useState()
    const [glTotal, setglTotal] = useState()
    const [init, setInit] = useState(true)
    const [merchantInfo, setMerchantInfo] = useState([])
    const [introducer, setIntroducer] = useState([])
    const [jigou, setJigou] = useState([])
    const [mianqian, setMianqian] = useState(false)
    const superMerchantCode = localStorage.appid;
    const { search } = useLocation()
    const navigate = useNavigate()
    const forceUpdate = useReducer((bool) => !bool)[1]
    useEffect(() => {
        if (init) {
            getMerchantInfoList();
            getPartnerList()
        }
        shopManageFeeOrderList();
     

    }, [currentPage, pageNum])
    const columns = [
        {
            title: '订单时间',
            dataIndex: 'payTime',
            key: 'payTime',
        },
        {
            title: '总部管理费(元)',
            dataIndex: 'superManageAmount',
            key: 'superManageAmount',
        },

        {
            title: '分账机构(元)',
            dataIndex: 'details',
            key: 'details',
            width: 250,
            render: (text, row) => {
                return (
                    <div>
                        {
                            row.details.map((v, index) => {
                                return <span>{v.splitAmount}{row.details.length - 1 > index ? '/' : ''}</span>
                            })
                        }
                        {row.details.length > 0 ? <a onClick={(e) => {
                            setJigou(row.details)
                            setMianqian(true)
                            // showModal()
                        }}>查看</a> : null}
                    </div>

                )
            }
        },
        {
            title: '采购货款(元)',
            dataIndex: 'purchaseAmount',
            key: 'purchaseAmount',
        },
        {
            title: '介绍人',
            dataIndex: 'partnerMerchantName',
            key: 'partnerMerchantName',
        },
        {
            title: '订单编号/管理费单号',
            dataIndex: 'orderNo',
            key: 'orderNo',
        },
        {
            title: '付款机构',
            dataIndex: 'merchantName',
            key: 'merchantName',
        },
        {
            title: '支付方式',
            dataIndex: 'payChannel',
            key: 'payChannel',
            render: (text, row) => {
                return (
                    <div>
                        {row.payChannel == 1 ? '微信' : ''}
                        {row.payChannel == 2 ? '支付宝' : ''}

                    </div>
                )
            },
        },
        {
            title: '联系人',
            dataIndex: 'personName',
            key: 'personName',
        },
        {
            title: '联系方式',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: '支付状态',
            dataIndex: 'payStatus',
            key: 'payStatus',
            render: (text, row) => {
                return (
                    <div>
                        {row.payStatus == 0 ? '待支付' : ''}
                        {row.payStatus == 1 ? '支付中' : ''}
                        {row.payStatus == 2 ? '已支付' : ''}
                        {row.payStatus == 3 ? '已取消' : ''}

                    </div>
                )
            },
        },
    ];
    const mianqianHandleCancel = () => {
        setMianqian(false);
    };
    const getMerchantInfoList = () => {
        (async _ => {
            return await listForManageRequest({
                appId: localStorage.appid,
                superMerchantCode: localStorage.appid,
                MerchantTypeInfo: 'SHOP_INFO'
            });
        })().then(res => {
            console.log(res);
            if (res.code == "SUCCESS") {
                setInit(false)
                setMerchantInfo(res.result.merchantInfoList)
            } else {

            }
        });
    }
    const getPartnerList = () => {
        (async _ => {
            return await partnerListRequest({
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            console.log(res);
            if (res.code == "SUCCESS") {
                setInit(false)
                setIntroducer(res.result);
                // setMerchantInfo(res.result.merchantInfoList)
            } else {

            }
        });
    }
    const shopManageFeeOrderList = (data) => {
        let params = {
            ...data,
            currentPage,
            pageSize: pageNum,
            superMerchantCode: localStorage.appid,
            merchantCode: localStorage.merchantCode,
        };
        (async _ => {
            return await shopManageFeeOrderListRequest(params);
        })().then(res => {
            if (res.code == "SUCCESS") {
                console.log(res);
                setList(res.result.items)
                setTotal(res.result.totalCount)
            } else {
                // message.error(res.msg)
            }
        });
    }


    const onChangPage = (currentPage, pageNum) => {
        setCurrentPage(currentPage, () => {
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        setPageNum((currentPage, pageNum), () => {
        })
    }
    const onFinish = (values) => {
        const rangeValue = values['date'];
        values.startTime = rangeValue && rangeValue[0].format('YYYY-MM-DD');
        values.endTime = rangeValue && rangeValue[1].format('YYYY-MM-DD');
        values.currentPage = 1;
        delete values.date
        shopManageFeeOrderList(values)
    };

    const inSevenDays = () => {
        form.setFieldsValue({
            date: [moment(moment().subtract(7, "days").format("YYYY-MM-DD"), dateFormat), moment(moment().format('YYYY-MM-DD'), dateFormat)]
        })
    }
    const yesterday = () => {
        form.setFieldsValue({
            date: [moment(moment().subtract(1, "days").format("YYYY-MM-DD"), dateFormat), moment(moment().subtract(1, "days").format('YYYY-MM-DD'), dateFormat)]
        })
    }
    const today = () => {
        form.setFieldsValue({
            date: [moment(moment().format('YYYY-MM-DD'), dateFormat), moment(moment().format('YYYY-MM-DD'), dateFormat)]
        })


    }
    const timeChange = (date) => {
        setStartDate(date && date[0].format("YYYY-MM-DD"))
        setEndDate(date && date[1].format('YYYY-MM-DD'))
    }
    const reset = () => {
        form.setFieldsValue({
            subMerchantCode: undefined,
            date: undefined,
            orderNo: undefined,
            partnerCommission: undefined,
            payStatus: undefined,
            payChannel: undefined,
            partnerMerchantCode: undefined,
        })
        let values = form.getFieldValue()
        values.currentPage = 1;
        console.log(values);
        shopManageFeeOrderList(values)
    }

    return (
        <div style={{ background: '#ffffff', paddingTop: 1, marginTop: 16 }}>
            <Form
                form={form}
                {...layout}
                name="advanced_search"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                style={{ background: '#F7F8FA', margin: 16, padding: 16, paddingBottom: 0 }}
                initialValues={{
                }}
            >
                <Row style={{ marginBottom: '-1vh' }}>
                    {
                        localStorage.hxState == 'true' ?
                            <Col span={8}>
                                <Form.Item
                                    name="subMerchantCode"
                                    label="付款机构"
                                >
                                    <Select placeholder="请选择"
                                        style={{ width: 180 }}
                                        onChange={(code) => {
                                            setMerchantCode(code)
                                        }}
                                        allowClear
                                        optionFilterProp="children"
                                        showSearch
                                        filterOption={(input, option) => option.children.includes(input)}
                                    >
                                        {
                                            merchantInfo && merchantInfo.map((v) => {
                                                return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                            })
                                        }

                                    </Select>
                                </Form.Item>
                            </Col>
                            :
                            <Col span={8}>
                                <Form.Item
                                    label="付款机构"
                                >
                                    {localStorage.merchantName}
                                </Form.Item>
                            </Col>

                    }

                </Row>

                <Row style={{ marginBottom: '-1vh' }}>
                    <Col span={8}>
                        <Form.Item
                            name="date"
                            label="订单时间"
                        >
                            <div style={{ display: 'flex', alignItems: 'center', width: 400, }}>
                                <Form.Item
                                    name="date"
                                    noStyle
                                >
                                    <RangePicker onChange={timeChange} style={{ width: 250 }} />
                                </Form.Item>
                                <Form.Item
                                    // name="date"
                                    noStyle
                                >
                                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }}
                                        onClick={today} >今天</span>
                                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }}
                                        onClick={yesterday} >昨天</span>
                                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }}
                                        onClick={inSevenDays} >近7天</span>
                                </Form.Item>
                            </div>



                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{ marginBottom: '-1vh' }}>
                    <Col span={8}>
                        <Form.Item
                            name="orderNo"
                            label="单号："
                        >
                            <Input style={{ width: 180 }} />
                        </Form.Item>
                    </Col>
                    <Col span={8} >
                        <Form.Item
                            name="partnerMerchantCode"
                            label="介绍人"
                        >
                            <Select style={{ width: 180 }}>
                                {
                                    introducer && introducer.map((v) => {
                                        return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>


                </Row>
                <Row style={{ marginBottom: '-1vh' }}>
                    <Col span={8}>
                        <Form.Item
                            name="payStatus"
                            label="支付状态"
                        >

                            <Select placeholder="请选择"
                                style={{ width: 180 }}
                                allowClear
                            >
                                <Option rowKey={0} key={0} value={0}>待支付</Option>
                                <Option rowKey={1} key={1} value={1}>支付中</Option>
                                <Option rowKey={2} key={2} value={2}>已支付</Option>
                                <Option rowKey={3} key={3} value={3}>已取消</Option>

                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="payChannel"
                            label="支付方式："
                        >
                            <Select style={{ width: 180 }}>
                                <Option rowKey={1} key={1} value={1}>微信</Option>
                                <Option rowKey={2} key={2} value={2}>支付宝</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>
                <Row style={{ marginBottom: '-1vh' }}>
                    <Col span={8}>
                        <Form.Item
                            wrapperCol={{
                                offset: 6,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                筛选
                            </Button>
                            <Button type="link" onClick={() => {
                                reset();
                            }} >
                                重置筛选条件
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                locale={{
                    emptyText: "没有更多数据了"
                }}
                rowKey='id'
                style={{ marginTop: 16, margin: '0 16px' }}
                columns={columns}
                dataSource={list}
                scroll={{
                    x: 1600,
                }}
                pagination={{
                    total,
                    showSizeChanger: true,
                    current: currentPage,
                    pageSize: pageNum,
                    // position: ["bottomCenter"],
                    showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                    onChange: (k, v) => onChangPage(k, v),
                    onShowSizeChange: (k, v) => onChangPageNum(k, v),
                }}
            />

            <Modal
                width={300}
                title="分账机构" visible={mianqian} onCancel={mianqianHandleCancel}
                footer={null}
            >
                {
                    jigou && jigou.map((v, index) => {
                        return (
                            <div key={index}>{v.splitMerchantName}:{v.splitAmount}</div>
                        )
                    })
                }

            </Modal>
        </div >
    )
}
export default ManagementFeeList;