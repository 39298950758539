import React, { Component, useState, useEffect, } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
    Form,
    Row,
    Col,
    Button,
    Table,
    DatePicker,
    Input,
    Select,
    message,
    Popconfirm,
    Tabs,
    Radio,
    Card,
    Modal,
    InputNumber 
} from 'antd';
import { settleMerchantListRequest,configDetailRequest, queryByTypeAccountRequest, queryByTypeRequest, listByLoginNameRequest, walletViewRequest, balanceViewRequest, settleWithdrawListRequest, incomeViewRequest, bankCardListRequest, withdrawSubmitRequest, listRequest } from '../../../api/api'
import { useCallbackState } from '../../../util/useCallbackState'
import Detail from './detail/index'
import Transfer from './transfer/index'
import { limitTowDecimals,roundFun } from '../../../util/tools';

const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
// import host from '../../../util/host';


const FormItem = Form.Item;
const Search = Input.Search;
const dateFormat = 'YYYY-MM-DD';
const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
};
const layout2 = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
};


const Withdrawal = () => {
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const { state } = useLocation();
    const { search } = useLocation()
    const navigate = useNavigate();
    const [activeKey, setActiveKey] = useState('1')
    const [merchantList, setMerchantList] = useState([])
    const [data, setData] = useState([]);
    const [qbdata, setQbData] = useState([]);
    const [merchantCode, setMerchantCode] = useCallbackState(state && state.data ? state.data.merchantCode : localStorage.merchantCode)
    const [radioValue, setRadioValue] = useState(localStorage.salesEmployeeId != 'null' ? 8 : state && state.radio ? state.radio : localStorage.appid == merchantCode && merchantCode != 'EW_N7733887598' && localStorage.salesEmployeeId == 'null' ? 2 : 0)
    const [list, setList] = useState([])
    const [bankList, setBankList] = useState([])
    const [merchantCode2, setMerchantCode2] = useCallbackState(state && state.data ? state.data.merchantCode : (localStorage.hxState == 'true' ? null : localStorage.merchantCode))
    // const [merchantCode2, setMerchantCode2] = useCallbackState(localStorage.merchantCode)
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [total, setTotal] = useState()
    const [init, setInit] = useState(true)
    const [yesNo, setYesNo] = useState(localStorage.merchantCode == localStorage.appid)
    const [mySwitch, setMySwitch] = useState(state && state.tag == 1 ? true : localStorage.state == "1" ? true : false)
    const [cardList, setCardList] = useState([])
    const [staffList, setStaffList] = useState([])
    const [cardData, setCardData] = useState(null)
    const [detailVisible, setDetailVisible] = useState(false);
    const [transferVisible, setTransferVisible] = useState(false);
    const [yue, setYue] = useState(null)
    const [loading, setLoading] = useState(false)
    const [row, setRow] = useState(null)
    const [value2, setValue2] = useState(null)
    const [feeRateStr, setFeeRateStr] = useState(null)
    const [feeRate, setFeeRate] = useState(0)
    const [fixAmt, setFixAmt] = useState(0)
    const [feeAmt, setFeeAmt] = useState(0)
    const [totalAmt, setTotalAmt] = useState(0)

    useEffect(() => {
        console.log(radioValue);
        setYesNo((state && state.data ? state.data.merchantCode : localStorage.merchantCode) == localStorage.appid)
    }, [])
    useEffect(() => {
        if (radioValue == 0) {
            getBalanceView(state && state.data ? state.data.merchantCode : localStorage.merchantCode)
        }
        if (radioValue == 1) {
            getIncomeView()
        }
        if (radioValue == 2) {
            walletView()
        }
        if (radioValue == 8) {
            if (localStorage.salesEmployeeId == 'null') {
                getAmount()
            } else {
                queryByTypeAccount()
            }
        }
    }, [])
    useEffect(() => {
        console.log(state);

        if (init) {
            setActiveKey(state ? state.defaultActiveKey : '1')
            settleMerchantList();
        }
        if (state) {
            console.log(state)
            if (state.radio == 0) {
                getBalanceView();
            }
            if (state.radio == 1) {
                getIncomeView()
            }
            if (state.radio == 2) {
                walletView()
            }
        } else {
            getBalanceView();
        }
        getSettleWithdrawList();
        getBankCardList()
        listByLoginName();
        getConfigDetail();


    }, [currentPage, pageNum, merchantCode]) //所有更新都执行
    const columns = [
        {
            title: '申请门店',
            dataIndex: 'merchantName',
            key: 'merchantName',
            render: (text, row) => {
                return (
                    <div>
                        {row.merchantName} {row.salesEmployeeName}
                    </div>
                )
            }
        },
        {
            title: '申请时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '金额(元)',
            dataIndex: 'withdrawAmount',
            key: 'withdrawAmount',
            width: 150
        },
        {
            title: '手续费(元)',
            dataIndex: 'withdrawFeeAmount',
            key: 'withdrawFeeAmount',
            width: 150
        },
        {
            title: '总额(元)',
            dataIndex: 'totalWithdrawAmount',
            key: 'totalWithdrawAmount',
            width: 150
        },
        {
            title: '状态',
            dataIndex: 'withdrawStatus',
            key: 'withdrawStatus',
            width: 150,
            render: (text, row) => {
                return (
                    <div>
                        {row.withdrawStatus == 0 ? '提现中' : ''}
                        {row.withdrawStatus == 1 ? '已提现' : ''}
                        {row.withdrawStatus == 2 ? '提现失败' : ''}
                        {row.withdrawStatus == 3 ? '转账中' : ''}
                    </div>
                )
            }
        },
        {
            title: '审核状态',
            dataIndex: 'auditStatus',
            key: 'auditStatus',
            width: 150,
            render: (text, row) => {
                return (
                    <div>
                        {row.auditStatus == 0 ? '未审核' : ''}
                        {row.auditStatus == 1 ? '已审核' : ''}
                        {row.auditStatus == 2 ? '已拒绝' : ''}
                    </div>
                )
            }
        },
        {
            title: '到账方式',
            dataIndex: 'withdrawPayType',
            key: 'withdrawPayType',
            width: 150,
        }, {
            title: '到账银行卡',
            dataIndex: 'bankCard',
            key: 'bankCard',
        },
        {
            title: '备注',
            dataIndex: 'withdrawRemark',
            key: 'withdrawRemark',
        },
        {
            title: '附加信息',
            dataIndex: 'auditRemark',
            key: 'auditRemark',
        },
        {
            title: '操作人',
            dataIndex: 'auditOperatorName',
            key: 'auditOperatorName',
        },
        {
            title: '付款账户',
            dataIndex: 'withdrawTransferType',
            key: 'withdrawTransferType',
            render: (text, row) => {
                return (
                    <div>
                        {text == 0 ? '记账账户' : ''}
                        {text == 1 ? '店铺余额' : ''}
                    </div>
                )
            }
        },
        {
            title: '审核时间',
            dataIndex: 'auditDate',
            key: 'auditDate',
        },
        {
            title: '转账发起时间',
            dataIndex: 'wsApplyTime',
            key: 'wsApplyTime',
        },
        {
            title: '银行到账时间',
            dataIndex: 'withdrawDate',
            key: 'withdrawDate',
        },
        {
            title: '订单编号|流水号',
            dataIndex: 'orderNo',
            key: 'orderNo',
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 140,
            align: 'right',
            render: (text, row) => (
                <>
                    <span style={{ color: '#165BD4', cursor: 'pointer', marginRight: 10 }} onClick={() => {
                        // navigate('/ws/account/detail', { replace: false, state: row })、
                        row.xq = 1
                        detailShowModal(row)
                    }}>查看</span>
                    {
                        localStorage.hxState == 'true' && row.auditStatus == 0 && activeKey == 2 ?
                            <span style={{ color: '#165BD4', cursor: 'pointer', marginRight: 10 }} onClick={() => {
                                row.xq = 2
                                detailShowModal(row)
                            }}>审核</span> : null
                    }
                    {
                        localStorage.hxState == 'true' && row.withdrawStatus != 1 && row.auditStatus == 1 && row.withdrawStatus != 3 && row.withdrawStatus != 2 && activeKey == 3 ?
                            <span style={{ color: '#165BD4', cursor: 'pointer', marginRight: 10 }} onClick={() => {
                                transferShowModal(row)
                            }}>转账</span> : null
                    }
                    {/* {
                        activeKey == 3 ?
                            <span style={{ color: '#165BD4', cursor: 'pointer', }} onClick={() => {
                            }}>回单</span> : null
                    } */}

                </>

            ),

        },
    ];


    const queryByTypeAccount = (data) => {
        let params = {
            accountType: 8,
            employeeId: localStorage.salesEmployeeId,
            merchantCode: localStorage.merchantCode,
        };
        (async _ => {
            return await queryByTypeAccountRequest(params);
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setYue(res.data.availableBalance);
            } else {
                message.error(res.msg)
            }
        });
    }
    const getAmount = (data) => {

        let params = {
            accountType: data,
            merchantCode: form1.getFieldValue().merchantCode,
        };
        (async _ => {
            return await queryByTypeRequest(params);
        })().then(res => {
            if (res.code == 0) {
                setYue(res.data.availableBalance);
            } else {
                message.error(res.msg)
            }
        });
    }
    const listByLoginName = () => {
        (async _ => {
            return await listByLoginNameRequest({
                merchantCode: localStorage.merchantCode,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setStaffList(res.data && res.data.employeeList)
            } else {
                message.error(res.msg)
            }
        });
    }
    const rechargeFundTypeChange = (e) => {
        setValue2(e.target.value)
        bankList.map((v) => {
            if (v.id == e.target.value) {
                setCardList([v])
                setCardData(v)
            }
        })
    }
    const getBankCardList = () => {

        (async _ => {
            return await listRequest({
                merchantCode: merchantCode,
                superMerchantCode: localStorage.appid,
                employeeId: localStorage.salesEmployeeId != "null" ? localStorage.salesEmployeeId : null,
                openStatus: 'S',
            });
        })().then(res => {
            if (res.code == 0) {
                console.log(res.data);
                res.data.lists.map((v) => {
                    if (v.ifDefault == 1) {
                        setValue2(v.id)
                        setCardList([v])
                        setCardData(v)
                    }
                })
                setBankList(res.data.lists)
            } else {

            }
        });
    }
    const onChangPage = (currentPage, pageNum) => {
        setCurrentPage(currentPage, () => {
            // getSettleWithdrawList()
        })
        setInit(false)
    }
    const onChangPageNum = (currentPage, pageNum) => {
        setPageNum((currentPage, pageNum), () => {
            // getSettleWithdrawList()
        })
        setInit(false)
    }
    const settleMerchantList = () => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode: localStorage.merchantCode,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            if (res.code == 0) {
                setMerchantList(res.data)
            } else {

            }
        });
    }
    const merchantChange = (code) => {
        console.log(code);
        setMerchantCode(code)
        setInit(false)
        if (localStorage.appid != code) {
            setMerchantCode(code, (merchantCode) => {
                console.log(merchantCode);
                getBalanceView(merchantCode)
            })
        }

    }
    const radioChange = (e) => {
        console.log(e.target.value);
        setRadioValue(e.target.value)
        setFeeAmt(0);
        if (e.target.value == 0) {
            getBalanceView(form1.getFieldValue().merchantCode)
        }
        if (e.target.value == 1) {
            getIncomeView()
        }
        if (e.target.value == 2) {
            walletView()
        }
        if (e.target.value == 8) {
            if (localStorage.salesEmployeeId == 'null') {
                getAmount(e.target.value)
            } else {
                queryByTypeAccount(e.target.value)
            }
        }
        form1.setFieldsValue({
            withdrawAmount: '0.00',
            card: value2
        })
    }
    const walletView = (code) => {
        (async _ => {
            return await walletViewRequest({
                merchantCode: code ? code : merchantCode,
                currentPage: 1,
                pageSize: 10,
                superMerchantCode: localStorage.appid,
                busiTypes: [32]
            });
        })().then(res => {
            if (res.code == 0) {
                setQbData(res.data)
            } else {

            }
        });
    }
    const getBalanceView = (data) => {
        console.log(data);
        let params = {
            // currentPage: currentPage,
            // pageSize: pageNum,
            merchantCode: data ? data : merchantCode2,
            superMerchantCode: localStorage.appid,
        };
        (async _ => {
            return await balanceViewRequest(params);
        })().then(res => {
            if (res.code == 0) {
                setData(res.data)
                // setList(res.data.list)
            } else {

            }
        });
    }
    const getIncomeView = () => {
        (async _ => {
            return await incomeViewRequest(
                {
                    merchantCode,
                    superMerchantCode: localStorage.appid,
                }
            );
        })().then(res => {
            if (res.code == 0) {
                setData(res.data)
            } else {
                message.error(res.msg)
            }
        });
    }
    const onFinish1 = (values) => {
        let data = {
            ...values,
            merchantCode,
            appId: localStorage.appid,
            withdrawType: radioValue,
            employeeId: localStorage.operatorId,
            bankBranchName: cardData && cardData.bankBranchName ? cardData.bankBranchName : undefined,
            bankCard: cardData && cardData.bankCardNo ? cardData.bankCardNo : undefined,
            bankName: cardData && cardData.bankName ? cardData.bankName : undefined,
            bankType: cardData && cardData.bankType ? cardData.bankType : undefined,
            phone: cardData && cardData.phone ? cardData.phone : undefined,
            payeeName: cardData && cardData.bankType == 1 ? cardData && cardData.companyName ? cardData.companyName : undefined : cardData && cardData.name ? cardData.name : undefined,
            bankCardId: cardData && cardData.id ? cardData.id : undefined,
            salesEmployeeId: localStorage.salesEmployeeId != 'null' ? localStorage.salesEmployeeId : undefined
        }
        setLoading(true)
        settleWithdrawAmount(data)
    }
    const onFinish2 = (values) => {

        values.currentPage = 1;
        getSettleWithdrawList(values)
    }
    const onChange = (key) => {
        setActiveKey(key)
        if (key == 1) {
            if (radioValue == 0) {
                getBalanceView()
            }
            if (radioValue == 1) {
                getIncomeView()
            }
            if (radioValue == 2) {
                walletView()
            }

        }
        if (key == 2) {
            getSettleWithdrawList();
        }

        setRadioValue(2)
        setCardList([])
        form1.resetFields();
        merchantChange(localStorage.appid)
        setYesNo(localStorage.appid)
        console.log(11111)
        walletView(localStorage.appid)
        setRadioValue(2)
        form1.setFieldsValue({
            withdrawType: 2,
        })
    
    };
    const settleWithdrawAmount = (data) => {
        (async _ => {
            return await withdrawSubmitRequest(data);
        })().then(res => {
            if (res.code == 0) {
                setLoading(false)
                message.success('提现成功')
                if (localStorage.salesEmployeeId == "null") {
                    setActiveKey('2')
                } else {
                    setActiveKey('4')
                }

                setRadioValue(2)
                setCardList([])
                form1.resetFields();
                getSettleWithdrawList();
            } else {
                setLoading(false)
                message.error(res.msg)
            }
        });
    }
    const getSettleWithdrawList = (data) => {
        const rangeValue = form2.getFieldsValue().date;
        console.log(rangeValue);

        let params = {
            ...data,
            currentPage: data && data.currentPage ? data && data.currentPage : currentPage,
            startDate: rangeValue ? rangeValue[0].format('YYYY-MM-DD') : moment().subtract(7, "days").format("YYYY-MM-DD"),
            endDate: rangeValue ? rangeValue[1].format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
            pageSize: pageNum,
            merchantCode: merchantCode2,
            appId: localStorage.appid,
            superMerchantCode: localStorage.appid,
            salesEmployeeId: localStorage.salesEmployeeId != 'null' ? localStorage.salesEmployeeId : null
        };
        (async _ => {
            return await settleWithdrawListRequest(params);
        })().then(res => {
            if (res.code == 0) {
                setList(res.data.list)
                setTotal(res.data.totalCount)
            } else {
                message.error(res.msg)
            }
        });
    }
    const inSevenDays = () => {
        form2.setFieldsValue({
            date: [moment(moment().subtract(7, "days").format("YYYY-MM-DD"), dateFormat),
            moment(moment().format('YYYY-MM-DD'), dateFormat)]
        })
    }
    const nearlyThirtyDays = () => {
        form2.setFieldsValue({
            date: [moment(moment().subtract(30, "days").format("YYYY-MM-DD"), dateFormat),
            moment(moment().format('YYYY-MM-DD'), dateFormat)]
        })
    }

    const detailShowModal = (data) => {
        setRow(data)
        setDetailVisible(true);
    };

    const detailHandleOk = () => {
        setDetailVisible(false);
    };

    const detailhandleCancel = () => {
        setDetailVisible(false);
    };
    const transferShowModal = (data) => {
        setRow(data)
        setTransferVisible(true);
    };

    const transferHandleOk = () => {
        setTransferVisible(false);
    };

    const transferhandleCancel = () => {
        setTransferVisible(false);
    };

    const getConfigDetail = (code) => {
        (async _ => {
            return await configDetailRequest({
                merchantCode: localStorage.appid,
                superMerchantCode: localStorage.appid,
            }
            );
        })().then(res => {
            if (res.code == 0) {
                console.log("22"+res.data.feeRate);
                if(res.data.fixAmt){
                    setFixAmt(res.data.fixAmt);
                }else if(res.data.feeRate){
                    setFeeRate(res.data.feeRate);
                }
            } else {
                message.error(res.msg)
            }
        });
    }

    return (
        <div style={{ background: '#ffffff', paddingBottom: 20, width: '100%' }}>

            <Tabs activeKey={activeKey.toString()}
                onChange={onChange} size="large"
                tabBarStyle={{ paddingLeft: 20 }}
                style={{ padding: 10, marginTop: 16 }}
                tabBarGutter={100}

            >
                <TabPane tab="提现" key="1">

                    <Form
                        name="time_related_controls"
                        {...layout}
                        form={form1}
                        // wrapperCol={{ span: 8 }}
                        onFinish={onFinish1}
                        autoComplete="off"
                        initialValues={{
                            merchantCode: state && state.data ? state.data.merchantCode : localStorage.merchantCode,
                            withdrawType: localStorage.salesEmployeeId != 'null' ? 8 : state && state.radio ? state.radio : localStorage.appid == merchantCode && merchantCode != 'EW_N7733887598' && localStorage.salesEmployeeId == 'null' ? 2 : 0,
                        }}
                        style={{ marginTop: 20 }}
                    >
                        {
                            localStorage.salesEmployeeId == 'null' ?
                                <>
                                    {
                                        localStorage.hxState == 'true' ?
                                            <Col span={12}>
                                                <Form.Item
                                                    name="merchantCode"
                                                    label="商户："
                                                    rules={[{ required: true, message: '请选择商户名称!' }]}
                                                >
                                                    {

                                                        <Select placeholder="请选择"
                                                            style={{ width: 200 }}
                                                            onChange={(code) => {
                                                                console.log(code);
                                                                merchantChange(code)
                                                                setYesNo(code == localStorage.appid)
                                                                form1.setFieldsValue({
                                                                    withdrawAmount: '0.00',
                                                                    withdrawType: 0,
                                                                    card: ''
                                                                })
                                                                setCardList([])
                                                                // setCardData(null)
                                                                if (localStorage.appid == code) {
                                                                    console.log(11111)
                                                                    walletView(code)
                                                                    setRadioValue(2)
                                                                    form1.setFieldsValue({
                                                                        withdrawType: 2,
                                                                    })
                                                                    
                                                                } else {
                                                                    setRadioValue(0)
                                                                }

                                                            }}
                                                            allowClear
                                                            optionFilterProp="children"
                                                            showSearch
                                                            filterOption={(input, option) => option.children.includes(input)}
                                                        >

                                                            {
                                                                merchantList && merchantList.map((v) => {
                                                                    return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                                                })
                                                            }

                                                        </Select>

                                                    }

                                                </Form.Item>
                                            </Col>
                                            :
                                            <Col span={12}>
                                                <Form.Item
                                                    label="商户："
                                                >
                                                    {localStorage.merchantName}

                                                </Form.Item>
                                            </Col>
                                    }
                                </> : null
                        }



                        <Col span={12}>
                            <Form.Item
                                name="withdrawType"
                                label="付款账户："
                                rules={[{ required: true, message: '请选择余额账户!' }]}
                            >
                                <Radio.Group value={radioValue} onChange={radioChange} >
                                    {
                                        (localStorage.appid == merchantCode && merchantCode == 'EW_N7733887598' && localStorage.salesEmployeeId == 'null') || (localStorage.appid == merchantCode && merchantCode == 'EW_N0518400645' && localStorage.salesEmployeeId == 'null')
                                            ? <Radio value={0}>店铺余额</Radio> : null

                                    }
                                    {
                                        localStorage.appid != merchantCode ? <Radio value={0}>店铺余额</Radio> : null
                                    }
                                    {
                                        localStorage.salesEmployeeId == 'null' ?
                                            <Radio value={2}>钱包余额</Radio> : null
                                    }

                                    <Radio value={8}>营销余额</Radio>

                                    {
                                        yesNo && localStorage.salesEmployeeId == 'null' ? <Radio value={1}>收益账户</Radio> : ''
                                    }

                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="可提现金额："
                            >
                                {radioValue == 2 ? <span>{qbdata && qbdata.walletAvailableBalance}</span> : null}
                                {radioValue == 0 ? <span>{data && data.availableBalance}</span> : null}
                                {radioValue == 1 ? <span>{data && data.incomeBalance}</span> : null}
                                {radioValue == 8 ? <span>{yue}</span> : null}
                            </Form.Item>
                        </Col>
                        <Row>
                            <Col span={12}  >
                                <Form.Item
                                    name="withdrawAmount"
                                    label="本次提现："
                                    rules={[{ required: true, message: '请输入提现金额!' }]}
                                >
                                    <div style={{ display: 'flex' }}>
                                        <Form.Item
                                            name="withdrawAmount"
                                            noStyle 
                                        >
                                            <InputNumber
                                                placeholder='请输入提现金额'
                                                style={{ width: 200 }}
                                                addonAfter={'元'} 
                                                onBlur ={(e) =>{
                                                    let amt = roundFun(feeRate * e.target.value / 100,2);
                                                    setFeeAmt(amt);
                                                    setTotalAmt(roundFun(parseFloat(amt)+parseFloat(e.target.value),2));
                                                }}
                                                />
                                        </Form.Item>
                                        <Form.Item
                                            noStyle
                                        >
                                            <Button type='link'
                                                onClick={() => {
                                                    if (radioValue == 2) {
                                                        form1.setFieldsValue({
                                                            withdrawAmount: qbdata && qbdata.walletAvailableBalance
                                                        })
                                                        let amt = roundFun(feeRate * qbdata.walletAvailableBalance / 100,2);
                                                        setFeeAmt(amt);
                                                        setTotalAmt(roundFun(parseFloat(amt)+parseFloat(qbdata.walletAvailableBalance)),2);
                                                    }
                                                    if (radioValue != 2) {
                                                        form1.setFieldsValue({
                                                            withdrawAmount: radioValue == 0 ? data && data.availableBalance : data && data.incomeBalance
                                                        })
                                                        let avab = radioValue == 0 ? data && data.availableBalance : data && data.incomeBalance
                                                        let amt = roundFun(feeRate * avab/ 100,2);
                                                        setFeeAmt(amt);
                                                        setTotalAmt(roundFun(parseFloat(amt)+parseFloat(avab)),2);
                                                    }
                                                    if (radioValue == 8) {
                                                        form1.setFieldsValue({
                                                            withdrawAmount: yue
                                                        })
                                                        let amt = roundFun(feeRate * yue/ 100,2);
                                                        setFeeAmt(amt);
                                                        setTotalAmt(roundFun(parseFloat(amt)+parseFloat(yue)),2);
                                                    }
                                                    getConfigDetail();

                                                }}>全部提现</Button>

                                        </Form.Item>
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                        {
                        feeAmt != null && feeAmt >0 ? 
                        <Col span={12}  >
                        <Form.Item  label="提现手续费：">{feeAmt}</Form.Item>     
                        <Form.Item  label="提现总金额">{totalAmt}</Form.Item>     
                        </Col>
                         : null
                         }                
                        {
                            radioValue == 2 || radioValue == 8 ?
                                <Row>
                                    <Col span={12}>
                                        <Form.Item
                                            name="card"
                                            label="到账银行卡"
                                            rules={[{ required: true, message: '请选择银行卡!' }]}
                                        >
                                            {
                                                bankList.length > 0 ?
                                                    <Radio.Group onChange={rechargeFundTypeChange} >
                                                        {
                                                            bankList.map((v, index) => {
                                                                return (
                                                                    <Radio key={v.id} value={v.id}>{v.bankName}</Radio>
                                                                )
                                                            })
                                                        }
                                                    </Radio.Group>
                                                    :
                                                    <Button type='link' onClick={() => {
                                                        navigate('/ws/bankCard')
                                                    }}>新增银行卡</Button>
                                            }
                                        </Form.Item>

                                    </Col>
                                </Row> : null
                        }
                        {
                            radioValue == 2 || radioValue == 8 ?
                                <Row>
                                    <Col span={8}>
                                        {
                                            cardList.length > 0 ?
                                                <Form.Item
                                                    label={<span style={{ color: '#fff' }}>银行卡</span>}
                                                    colon={false}
                                                >
                                                    <div style={{ background: '#ffffff', display: 'flex' }}>
                                                        {
                                                            cardList.map((v, index) => {
                                                                return (
                                                                    <div className='bankCard' key={index}>
                                                                        <div className='bankCard-top'>
                                                                            <div className='title'>
                                                                                <div className='name'>{v.bankName}</div>
                                                                                <div className='type'>{v.cardTypeName}</div>
                                                                            </div>
                                                                            <div className='main'>
                                                                                <div className='gsName'>{v.cardName}</div>
                                                                                <div className='card'>{v.cardNo}</div>
                                                                                <div className='cardName'>{v.branchName}</div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </Form.Item> : ''
                                        }
                                    </Col>
                                </Row> : null
                        }
                        <Col span={12}>
                            <Form.Item
                                name="withdrawRemark"
                                label="备注："
                                rules={[{ required: false, message: 'Please input your username!' }]}
                            >
                                <TextArea rows={4} placeholder="请输入备注" maxLength={250} />
                            </Form.Item>
                        </Col>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    wrapperCol={{
                                        offset: 5,
                                        span: 16,
                                    }}
                                >
                                    <Button type='primary' htmlType='submit' loading={loading}>确认提现</Button>

                                </Form.Item>
                            </Col>
                        </Row>
                        {/* <Form.Item
                            style={{ marginLeft: 30 }}
                        >
                            <Button style={{ marginLeft: 80 }} type='primary' htmlType='submit'>确认提现</Button>
                        </Form.Item> */}
                    </Form>

                </TabPane>
                {
                    localStorage.salesEmployeeId == 'null' ?
                        <TabPane tab="提现审核" key="2">
                            <Form
                                form={form2}
                                {...layout2}
                                initialValues={{
                                    date: [moment(moment().subtract(7, "days").format("YYYY-MM-DD"), dateFormat),
                                    moment(moment().format('YYYY-MM-DD'), dateFormat)],
                                    withdrawStatus: null,
                                    merchantCode: state && state.data ? state.data.merchantCode : null,
                                    auditStatus: null,
                                    withdrawType: null
                                }}
                                name="advanced_search"
                                className="ant-advanced-search-form"
                                onFinish={onFinish2}
                                style={{
                                    background: '#F7F8FA',
                                    padding: 16,
                                    paddingBottom: 0
                                }}
                            >
                                <Row style={{ marginBottom: '-1vh', marginLeft: 10 }}>
                                    <Col span={12} pull={1}>
                                        <Form.Item
                                            name="date"
                                            label="申请时间："
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Form.Item
                                                    name="date"
                                                    noStyle
                                                >
                                                    <RangePicker style={{ width: 300 }} />
                                                </Form.Item>
                                                <Form.Item
                                                    noStyle
                                                >
                                                    <Button onClick={inSevenDays} type="link">近7天</Button>
                                                    <Button onClick={nearlyThirtyDays} type="link">近30天</Button>
                                                </Form.Item>

                                            </div>

                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: '-1vh' }}>
                                    <Col span={8}>
                                        <Form.Item
                                            name="withdrawOrderNo"
                                            label="订单编号："
                                        >
                                            <Input style={{ width: 250 }} placeholder="请输入提现订单号" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="withdrawStatus"
                                            label="提现状态："
                                        >
                                            <Select style={{ width: 250 }}>
                                                <Option value={null}>全部</Option>
                                                <Option value="0">提现中</Option>
                                                <Option value="1">已提现</Option>
                                                <Option value="2">提现失败</Option>
                                                <Option value="3">转账中</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="auditStatus"
                                            label="审核状态："
                                        >
                                            <Select style={{ width: 250 }}>
                                                <Option value={null}>全部</Option>
                                                <Option value="0">未审核</Option>
                                                <Option value="1">已审核</Option>
                                                <Option value="2">已拒绝</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: '-1vh' }}>
                                    {
                                        localStorage.hxState == 'true' ?
                                            <Col span={8}>
                                                <Form.Item
                                                    name="merchantCode"
                                                    label="商户："
                                                >
                                                    <Select placeholder="请选择"
                                                        style={{ width: 250 }}
                                                        onChange={(code) => {
                                                            setMerchantCode2(code)
                                                        }}
                                                        allowClear
                                                        optionFilterProp="children"
                                                        showSearch
                                                        filterOption={(input, option) => option.children.includes(input)}
                                                    >
                                                        <Option rowKey={null} key={null} value={null}>全部</Option>
                                                        {
                                                            merchantList && merchantList.map((v) => {
                                                                return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </Col> :
                                            <Col span={8}>
                                                <Form.Item
                                                    label="商户："
                                                >
                                                    {localStorage.merchantName}
                                                </Form.Item>
                                            </Col>

                                    }
                                    <Col span={8}>
                                        <Form.Item
                                            name="withdrawType"
                                            label="提现类型"
                                        >
                                            <Select style={{ width: 250 }}>
                                                <Option value={null}>全部</Option>
                                                <Option value="0">店铺余额</Option>
                                                <Option value="1">收益账户</Option>
                                                <Option value="2">钱包余额</Option>
                                                <Option value="8">营销余额</Option>

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={8}>
                                        <Form.Item
                                            wrapperCol={{
                                                offset: 5,
                                                span: 16,
                                            }}
                                        >
                                            <Button type="primary" htmlType="submit">
                                                筛选
                                            </Button>
                                            <Button type="link" onClick={() => {
                                                form2.setFieldsValue({
                                                    merchantCode: localStorage.merchantCode,
                                                    withdrawStatus: null,
                                                });

                                            }} >
                                                重置筛选条件
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>


                            </Form>
                            <Table
                                locale={{
                                    emptyText: "没有更多数据了"
                                }}
                                style={{ marginTop: 10 }}
                                // loading={loading}
                                rowKey="id"
                                dataSource={list}
                                columns={columns}
                                scroll={{ x: 2600 }}
                                pagination={{
                                    total,
                                    showSizeChanger: true,
                                    current: currentPage,
                                    pageSize: pageNum,
                                    // position: ["bottomCenter"],
                                    showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                                    onChange: (k, v) => onChangPage(k, v),
                                    onShowSizeChange: (k, v) => onChangPageNum(k, v),
                                }}
                            />
                        </TabPane> : null
                }
                {
                    localStorage.salesEmployeeId == 'null' ?
                        <TabPane tab="提现转账" key="3">
                            <Form
                                form={form2}
                                {...layout2}
                                initialValues={{
                                    date: [moment(moment().subtract(7, "days").format("YYYY-MM-DD"), dateFormat),
                                    moment(moment().format('YYYY-MM-DD'), dateFormat)],
                                    withdrawStatus: null,
                                    merchantCode: state && state.data ? state.data.merchantCode : null,
                                }}
                                name="advanced_search"
                                className="ant-advanced-search-form"
                                onFinish={onFinish2}
                                style={{
                                    background: '#F7F8FA',
                                    padding: 16,
                                    paddingBottom: 0
                                }}
                            >
                                <Row style={{ marginBottom: '-1vh', marginLeft: 10 }}>
                                    <Col span={12} pull={1}>
                                        <Form.Item
                                            name="date"
                                            label="申请时间："
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Form.Item
                                                    name="date"
                                                    noStyle
                                                >
                                                    <RangePicker style={{ width: 300 }} />
                                                </Form.Item>
                                                <Form.Item
                                                    noStyle
                                                >
                                                    <Button onClick={inSevenDays} type="link">近7天</Button>
                                                    <Button onClick={nearlyThirtyDays} type="link">近30天</Button>
                                                </Form.Item>

                                            </div>

                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: '-1vh' }}>
                                    <Col span={8}>
                                        <Form.Item
                                            name="withdrawOrderNo"
                                            label="订单编号："
                                        >
                                            <Input style={{ width: 250 }} placeholder="请输入提现订单号" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="withdrawStatus"
                                            label="提现状态："
                                        >
                                            <Select style={{ width: 250 }}>
                                                <Option value={null}>全部</Option>
                                                <Option value="0">未提现</Option>
                                                <Option value="1">已提现</Option>
                                                <Option value="2">提现失败</Option>
                                                <Option value="3">提现中</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="auditStatus"
                                            label="审核状态："
                                        >
                                            <Select style={{ width: 250 }}>
                                                <Option value={null}>全部</Option>
                                                <Option value="0">未审核</Option>
                                                <Option value="1">已审核</Option>
                                                <Option value="2">已拒绝</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: '-1vh' }}>
                                    {
                                        localStorage.hxState == 'true' ?
                                            <Col span={8}>
                                                <Form.Item
                                                    name="merchantCode"
                                                    label="商户："
                                                >
                                                    <Select placeholder="请选择"
                                                        style={{ width: 250 }}
                                                        onChange={(code) => {
                                                            setMerchantCode2(code)
                                                        }}
                                                        allowClear
                                                        optionFilterProp="children"
                                                        showSearch
                                                        filterOption={(input, option) => option.children.includes(input)}
                                                    >
                                                        <Option rowKey={null} key={null} value={null}>全部</Option>
                                                        {
                                                            merchantList && merchantList.map((v) => {
                                                                return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                                            })
                                                        }

                                                    </Select>
                                                </Form.Item>
                                            </Col> :
                                            <Col span={8}>
                                                <Form.Item
                                                    label="商户："
                                                >
                                                    {localStorage.merchantName}
                                                </Form.Item>
                                            </Col>

                                    }
                                    <Col span={8}>
                                        <Form.Item
                                            name="withdrawType"
                                            label="提现类型"
                                        >
                                            <Select style={{ width: 250 }}>
                                                <Option value={null}>全部</Option>
                                                <Option value="0">店铺余额</Option>
                                                <Option value="1">钱包余额</Option>
                                                <Option value="2">收益账户</Option>
                                                <Option value="8">营销余额</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={8}>
                                        <Form.Item
                                            wrapperCol={{
                                                offset: 5,
                                                span: 16,
                                            }}
                                        >
                                            <Button type="primary" htmlType="submit">
                                                筛选
                                            </Button>
                                            <Button type="link" onClick={() => {
                                                form2.setFieldsValue({
                                                    merchantCode: localStorage.merchantCode,
                                                    withdrawStatus: null,
                                                });

                                            }} >
                                                重置筛选条件
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>


                            </Form>
                            <Table
                                locale={{
                                    emptyText: "没有更多数据了"
                                }}
                                style={{ marginTop: 10 }}
                                // loading={loading}
                                rowKey="id"
                                dataSource={list}
                                columns={columns}
                                scroll={{ x: 2600 }}
                                pagination={{
                                    total,
                                    showSizeChanger: true,
                                    current: currentPage,
                                    pageSize: pageNum,
                                    // position: ["bottomCenter"],
                                    showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                                    onChange: (k, v) => onChangPage(k, v),
                                    onShowSizeChange: (k, v) => onChangPageNum(k, v),
                                }}
                            />
                        </TabPane> : null
                }


                {
                    localStorage.salesEmployeeId != 'null' ?
                        <TabPane tab="提现列表" key="4">
                            <Form
                                form={form2}
                                {...layout2}
                                initialValues={{
                                    date: [moment(moment().subtract(7, "days").format("YYYY-MM-DD"), dateFormat),
                                    moment(moment().format('YYYY-MM-DD'), dateFormat)],
                                    withdrawStatus: null,
                                    merchantCode: state && state.data ? state.data.merchantCode : null,
                                    employeeId: null
                                }}
                                name="advanced_search"
                                className="ant-advanced-search-form"
                                onFinish={onFinish2}
                                style={{
                                    background: '#F7F8FA',
                                    padding: 16,
                                    paddingBottom: 0,

                                }}
                            >
                                <Row style={{ marginBottom: '-1vh', marginLeft: 10 }}>
                                    <Col span={12} pull={1}>
                                        <Form.Item
                                            name="date"
                                            label="申请时间："
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Form.Item
                                                    name="date"
                                                    noStyle
                                                >
                                                    <RangePicker style={{ width: 300 }} />
                                                </Form.Item>
                                                <Form.Item
                                                    noStyle
                                                >
                                                    <Button onClick={inSevenDays} type="link">近7天</Button>
                                                    <Button onClick={nearlyThirtyDays} type="link">近30天</Button>
                                                </Form.Item>

                                            </div>

                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: '-1vh' }}>
                                    <Col span={8}>
                                        <Form.Item
                                            name="withdrawOrderNo"
                                            label="订单编号："
                                        >
                                            <Input style={{ width: 250 }} placeholder="请输入提现订单号" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="withdrawStatus"
                                            label="提现状态："
                                        >
                                            <Select style={{ width: 250 }}>
                                                <Option value={null}>全部</Option>
                                                <Option value="0">未提现</Option>
                                                <Option value="1">已提现</Option>
                                                <Option value="2">提现失败</Option>
                                                <Option value="3">提现中</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="auditStatus"
                                            label="审核状态："
                                        >
                                            <Select style={{ width: 250 }}>
                                                <Option value={null}>全部</Option>
                                                <Option value="0">未审核</Option>
                                                <Option value="1">已审核</Option>
                                                <Option value="2">已拒绝</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: '-1vh' }}>

                                    <Col span={8}>
                                        <Form.Item
                                            name="withdrawType"
                                            label="提现类型"
                                        >
                                            <Select style={{ width: 250 }}>
                                                <Option value={null}>全部</Option>
                                                <Option value="0">店铺余额</Option>
                                                <Option value="1">钱包余额</Option>
                                                <Option value="2">收益账户</Option>
                                                <Option value="8">营销余额</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={8}>
                                        <Form.Item
                                            wrapperCol={{
                                                offset: 5,
                                                span: 16,
                                            }}
                                        >
                                            <Button type="primary" htmlType="submit">
                                                筛选
                                            </Button>
                                            <Button type="link" onClick={() => {
                                                form2.setFieldsValue({
                                                    merchantCode: localStorage.merchantCode,
                                                    withdrawStatus: null,
                                                });

                                            }} >
                                                重置筛选条件
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>


                            </Form>
                            <Table
                                locale={{
                                    emptyText: "没有更多数据了"
                                }}
                                style={{ marginTop: 10 }}
                                // loading={loading}
                                rowKey="id"
                                dataSource={list}
                                columns={columns}
                                scroll={{ x: 2600 }}
                                pagination={{
                                    total,
                                    showSizeChanger: true,
                                    current: currentPage,
                                    pageSize: pageNum,
                                    // position: ["bottomCenter"],
                                    showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                                    onChange: (k, v) => onChangPage(k, v),
                                    onShowSizeChange: (k, v) => onChangPageNum(k, v),
                                }}
                            />
                        </TabPane> : null
                }

            </Tabs>
            <Modal
                title={row && row.xq == 1 ? '查看详情' : '提现审核'}
                visible={detailVisible}
                onOk={detailHandleOk}
                onCancel={detailhandleCancel}
                width={600}
                footer={null}
                key={Math.random(10)}
            >
                <Detail data={row}
                    callback={(state) => {
                        if (state) {
                            getSettleWithdrawList()
                            setActiveKey('3')
                        }
                    }}
                    closeDialog={() => {
                        setDetailVisible(false)
                    }} />
            </Modal>
            <Modal
                title="提现转账"
                visible={transferVisible}
                onOk={transferHandleOk}
                onCancel={transferhandleCancel}
                width={1200}
                footer={null}
                key={Math.random(10)}
            >
                <Transfer data={row}
                    callback={(state) => {
                        if (state) {
                            getSettleWithdrawList()
                        } else {
                            getSettleWithdrawList()
                        }
                    }}
                    closeDialog={() => {
                        setTransferVisible(false)
                    }} />
            </Modal>


        </div >
    )
}
export default Withdrawal;
