import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
    Form,
    Row,
    Col,
    Button,
    Input,
    Select,
    Table,
    Popconfirm,
    message,
    Modal,
    Typography,
    Upload,
    Tooltip,
    Space
} from 'antd';
import { expenseOrderEditRequest, listByOrderNoRequest, expenseOrderDeleteRequest, settleMerchantListRequest, expenseOrderDetailRequest } from '../../../api/api'
import { useCallbackState } from '../../../util/useCallbackState'
import host from '../../../util/host';
import Amount from '../amount/index';
import SmallEdit from '../smallEdit/index'
import Type from '../type/index'
import './style.less'
const { Option } = Select;
const { Title } = Typography;
const layout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 16 },
};

const CostAdd = () => {
    const [lists, setLists] = useState([])
    const [merchantList, setMerchantList] = useState([])
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [total, setTotal] = useState(0)
    const [init, setInit] = useState(true)
    const [mySwitch, setMySwitch] = useState(localStorage.state == "1" ? true : false)
    const [detailVisible, setDetailVisible] = useState(false);
    const [row, setRow] = useState(null)
    const [shMerchantList, setShMerchantList] = useState([])
    const [aipv, setAipv] = useState(Number)
    const [arr, setArr] = useState(Array)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [selectAll, setSelectAll] = useState(false)
    const [selectedRows, setSelectedRows] = useState()
    const [params, setParams] = useState(null)
    const [typeVisible, setTypeVisible] = useState(false)
    const [amountVisible, setAmountVisible] = useState(false)
    const [data, setData] = useState(null)
    const [disable, setDisable] = useState(true)
    const [disable1, setDisable1] = useState(true)
    const navigate = useNavigate()
    const { search } = useLocation()
    const { state } = useLocation()
    const [operate, setOperate] = useState([])


    const columns = [
        {
            title: '批次号',
            dataIndex: 'costDetailNo',
            key: 'costDetailNo',
            width: 250,
            render: (text, row) => (
                < div >
                    {

                        typeof (row.costDetailNo) == 'string' ?
                            <span>{row.costDetailNo}</span>
                            : ''

                    }
                </div >
            ),
        },
        {
            title: '分账类型',
            dataIndex: 'shareType',
            key: 'shareType',
            render: (text, row) => (
                <div>
                    {row.shareType == 0 ? '按订单' : ''}
                    {row.shareType == 1 ? '按金额' : ''}
                </div>
            ),
        },
        {
            title: '单据类型',
            dataIndex: 'typeNames',
            key: 'typeNames',
            width: 200,
            render: (text, row) => {
                return (
                    <div>
                        {
                            row.businessTypeNosErrorMsg == "" ?
                                <div>
                                    {row.typeNames}
                                </div> :
                                <div>
                                    <div>
                                        {row.typeNames} <div style={{ color: 'red' }}>{row.businessTypeNosErrorMsg}</div>
                                    </div>
                                </div>
                        }
                    </div>
                )

            }

        },
        {
            title: '收支类型',
            dataIndex: 'expenseReceiptType',
            key: 'expenseReceiptType',
            render: (text, row) => (
                <>
                    {
                        !row.expenseReceiptTypeErrorMsg ?
                            <div>
                                {
                                    row.shareType == 1 ?
                                        <div>定额</div>
                                        :
                                        <div>
                                            {row.expenseReceiptType == 0 ? '定额' : ''}
                                            {row.expenseReceiptType == 1 ? '比例' : ''}
                                            {row.expenseReceiptType == 2 ? '区间比例' : ''}
                                            {row.expenseReceiptType == 3 ? '区间定额' : ''}
                                        </div>
                                }
                            </div> :
                            <div>
                                <div>
                                    {row.expenseReceiptType == 0 ? '定额' : ''}
                                    {row.expenseReceiptType == 1 ? '比例' : ''}
                                    {row.expenseReceiptType == 2 ? '区间比例' : ''}
                                    {row.expenseReceiptType == 3 ? '区间定额' : ''}
                                </div>
                                <div style={{ color: 'red' }}>
                                    {row.expenseReceiptTypeErrorMsg}
                                </div>
                            </div>
                    }




                </>

            ),
        },
        {
            title: '单据金额',
            dataIndex: 'expensePaySectionJson',
            key: 'expensePaySectionJson',
            render: (text, row) => (
                <>
                    {
                        row.shareType == 0 ?
                            <div>
                                {
                                    row.expenseReceiptType == 2 || row.expenseReceiptType == 3 ?
                                        <div>
                                            {
                                                JSON.parse(row.expensePaySectionJson).map((v, index) => {
                                                    return <div>
                                                        {row.expenseReceiptType == 2 ? '比例:' : '区间:'}{v.startPrice}~{v.endPrice}/{v.number}{row.expenseReceiptType == 2 ? '%' : '元'}
                                                    </div>
                                                })
                                            }


                                        </div>
                                        :
                                        <div>{row.expenseReceiptType == 0 ? '￥' : null}{row.expensePaySectionJson}{row.expenseReceiptType == 1 ? '%' : null} </div>
                                }

                            </div>
                            :
                            <div>
                                {row.shareMoneyType == 0 ? '不足不扣' : '补偿扣款'}/￥{row.expensePaySectionJson}
                            </div>
                    }

                </>

            ),
        },
        {
            title: '结算周期',
            dataIndex: 'settleType',
            key: 'settleType',
            render: (text, row) => (
                <div>
                    {
                        row.settleType ?
                            <div>
                                {
                                    row.settleType == 1 ?
                                        <div>
                                            每日:{row.settleValue}号
                                        </div> : null
                                }
                                {
                                    row.settleType == 2 ?
                                        <div>
                                            每周:星期{row.settleValue}
                                        </div> : null
                                }
                                {
                                    row.settleType == 3 ?
                                        <div>
                                            每约:{row.settleValue}号
                                        </div> : null
                                }
                            </div> :
                            <div>--</div>
                    }


                </div>
            ),
        },
        {
            title: '收入机构',
            dataIndex: 'payeeMerchantNames',
            key: 'payeeMerchantNames',
            width: 200,
            render: (text, row) => (
                <div className='ellipsis'>
                    {

                        !row.payeeMerchantCodesErrorMsg ?
                            <div>
                                {
                                    row.payeeMerchantNames
                                }
                            </div> :
                            <div>
                                <div>
                                    {row.payeeMerchantNames} <div style={{ color: 'red' }}>{row.payeeMerchantCodesErrorMsg}</div>
                                </div>
                            </div>
                    }
                </div>
            ),
        },
        {
            title: '收入账户',
            dataIndex: 'accountType',
            key: 'accountType',
            width: 150,
            render: (text, row) => (
                <div>
                    {
                        !row.accountTypeErrorMsg ?
                            <div>
                                {row.accountType == 0 ? '店铺待结算余额' : ''}
                                {row.accountType == 4 ? '钱包待结算余额' : ''}
                                {row.accountType == 1 ? '店铺余额' : ''}
                                {row.accountType == 2 ? '钱包余额' : ''}
                                {row.accountType == 7 ? '营销余额待结算' : ''}
                                {row.accountType == 8 ? '营销余额' : ''}
                            </div> :
                            <div>
                                <div>
                                    {row.accountType == 0 ? '店铺待结算余额' : ''}
                                    {row.accountType == 4 ? '钱包待结算余额' : ''}
                                    {row.accountType == 1 ? '店铺余额' : ''}
                                    {row.accountType == 2 ? '钱包余额' : ''}
                                    {row.accountType == 7 ? '营销余额待结算' : ''}
                                    {row.accountType == 8 ? '营销余额' : ''}
                                    <div style={{ color: 'red' }}>{row.accountTypeErrorMsg}</div>
                                </div>
                            </div>
                    }
                </div>
            ),
        },
        {
            title: '单据有效期',
            dataIndex: 'date',
            key: 'date',
            width: 200,
            render: (text, row) => (
                <>
                    {
                        row.expireStartTimeErrorMsg == null ?
                            <div>
                                {
                                    row.expireType == 0 ?
                                        <div>
                                            {row.expireStartTimeStr}~{row.expireEndTimeStr}
                                        </div> : null
                                }
                                {
                                    row.expireType == 1 ?
                                        <div>
                                            {row.expireStartTimeStr}~长期有效
                                        </div> : null
                                }
                                {
                                    row.expireType == 2 ?
                                        <div>
                                            {row.expireStartTimeStr}~一次性费用
                                        </div> : null
                                }

                            </div>
                            :
                            <div>
                                <div>
                                    {
                                        row.expireType == 0 ?
                                            <div>
                                                {row.expireStartTime}-{row.expireEndTime}
                                            </div> : null
                                    }
                                    {
                                        row.expireType == 1 ?
                                            <div>
                                                {row.expireStartTime}-长期有效
                                            </div> : null
                                    }
                                    {
                                        row.expireType == 2 ?
                                            <div>
                                                {row.expireStartTime}-一次性费用
                                            </div> : null
                                    }

                                </div>
                                <div style={{ color: 'red' }}>
                                    {row.expireStartTimeErrorMsg}
                                </div>
                            </div>
                    }

                </>

            ),
        },

        {
            title: '状态',
            dataIndex: 'costDetailStatus',
            key: 'costDetailStatus',
            render: (text, row) => (
                <div>
                    {row.costDetailStatus == 0 ? '未开启' : ''}
                    {row.costDetailStatus == 1 ? '进行中' : ''}
                    {row.costDetailStatus == 2 ? '到期停用' : ''}
                    {row.costDetailStatus == 3 ? '手动停用' : ''}
                </div>
            ),
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 160,
            align: 'right',
            render: (text, row) => (
                <div style={{ textAlign: 'rigth' }}>
                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }} onClick={() => {
                        row.edit = true
                        row.payerMerchantCodesArry = form.getFieldValue().payerMerchantCodesArry
                        row.businessTypeNos = row.businessTypeNosArry ? row.businessTypeNosArry.toString() : null
                        setParams(row);
                        setDetailVisible(true)

                    }}>详情</span>
                    {
                        !row.costDetailStatus == 1 ?
                            <Popconfirm
                                title="是否确认删除?"
                                onConfirm={() => {
                                    deleteFuc(row)
                                }}
                                onCancel={() => {

                                }}
                                okText="是"
                                cancelText="否"
                            >
                                <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }}>删除</span>
                            </Popconfirm>

                            : null
                    }


                    {
                        !row.costDetailStatus == 1 ?
                            <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }} onClick={() => {
                                row.payerMerchantCodesArry = form.getFieldValue().payerMerchantCodesArry
                                row.businessTypeNos = row.businessTypeNosArry ? row.businessTypeNosArry.toString() : null
                                row.edit = false
                                setParams(row);
                                setDetailVisible(true)
                            }}>编辑</span>
                            : null
                    }
                    {/* <Link style={{ marginLeft: 10 }} to="/ws/bills/detail" > </Link> */}
                    {
                        localStorage.hxState == 'true' && row.costDetailStatus == 1 ?
                            <Popconfirm
                                title="是否确认停用?"
                                onConfirm={() => {
                                    expenseDelete(row)
                                }}
                                onCancel={() => {

                                }}
                                okText="是"
                                cancelText="否"
                            >
                                <a style={{ marginLeft: 10 }} href="#">停用</a>
                            </Popconfirm> : ''
                    }
                </div>





            ),

        },
    ];

    useEffect(() => {
        skSettleMerchantList();
        expenseOrderDetail();
        listByOrderNo();
        console.log(state);
        if (init) {
            settleMerchantList();

        }

    }, [currentPage, pageNum,])
    const listByOrderNo = () => {

        (async _ => {
            return await listByOrderNoRequest({
                orderNo: state && state.costRuleNo,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setOperate(res.data)
            } else {

            }
        });
    }
 
    const deleteFuc = (row) => {
        console.log(row);

        let index = lists.findIndex((v) => { return v.id == row.id })
        lists.splice(index, 1)
        arr.splice(index, 1)
        setLists([...lists])
        setArr([...arr])
    }
    const detailHandleOk = () => {
        setDetailVisible(false);
    };

    const detailhandleCancel = () => {
        setDetailVisible(false);
    };
    const [form] = Form.useForm();

    const expenseOrderDetail = () => {
        (async _ => {
            return await expenseOrderDetailRequest({
                costRuleNo: state && state.costRuleNo,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                res.data.fundCostDetails.map((v) => {
                    v.payeeMerchantNames = [v.payeeMerchantNames]
                    v.typeNames = v.shareType == 0 ? v.businessTypeNames : undefined
                    v.expireStartTime = v.expireStartTimeStr
                    v.id = Math.random(10)
                })
                console.log(res.data.accountType);
                setData(res.data)
                setLists(res.data.fundCostDetails)
                setArr(res.data.fundCostDetails)
                form.setFieldsValue({
                    costName: res.data.costName,
                    // accountType: res.data.accountType.toString(),
                    accountType: res.data.accountType.toString(),
                    payerMerchantCodesArry: res.data.payerMerchantCodesArry
                })
            } else {

            }
        });
    }
    const settleMerchantList = () => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode: localStorage.merchantCode,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            if (res.code == 0) {
                setInit(false)
                setMerchantList(res.data)
            } else {

            }
        });
    }
    const skSettleMerchantList = (code) => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode: localStorage.appid,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            if (res.code == 0) {
                let index = res.data.findIndex((v) => { return v.merchantCode == localStorage.merchantCode })
                res.data.splice(index, 1)
                setShMerchantList(res.data)
            } else {

            }
        });
    }
    const expenseDelete = (row) => {
        let fundCostDetails = [];
        fundCostDetails.push({ costDetailNo: row.costDetailNo });
        (async _ => {
            return await expenseOrderDeleteRequest({
                fundCostDetails,
                superMerchantCode: localStorage.appid
            });
        })().then(res => {
            if (res.code == 0) {
                expenseOrderDetail()
                message.success(res.msg)
            } else {
                message.error(res.msg)
            }
        });
    }
    const batchExpenseDelete = (row) => {
        (async _ => {
            return await expenseOrderDeleteRequest({
                fundCostDetails: selectedRows,
                superMerchantCode: localStorage.appid
            });
        })().then(res => {
            if (res.code == 0) {
                expenseOrderDetail()
                message.success(res.msg)
            } else {
                message.error(res.msg)
            }
        });
    }
    const onFinish = (values) => {
        setParams(values)
        setDetailVisible(true)
    };
    const cancel = () => {
        navigate(-1)
    }


    const beforeUpload = (file) => {
        const isXlsx = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        if (!isXlsx) {
            message.error('请上传excel表格!');
        }

        return isXlsx;
    }
    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            // setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            console.log(info.file.response.data);
            if (info.file.response.code == 0) {
                let arr = [];
                info.file.response.data.objects.map((v) => {
                    v.id = Math.random(10)
                    v.typeNames = [v.businessTypeNosNames]
                    v.businessTypeNosArry = [v.businessTypeNos]
                    v.payeeMerchantCodesArry = [v.payeeMerchantCodes]
                    v.expireStartTimeStr = v.expireStartTime
                    if (v.expireType == 1 || v.expireType == 2) {
                        v.expireEndTime = null
                    }
                    if (!v.payeeMerchantCodesErrorMsg) {
                        arr.push(v)
                    }
                })
                console.log(arr.length)
                if (arr.length > 0) {
                    setArr([...arr])
                }
                setLists([...lists, ...info.file.response.data.objects])
                // setAipv(info.file.response.data.aipv.failCount)
            } else {
                message.error(info.file.response.msg)
            }

            // getBase64(info.file.originFileObj, (url) => {

            // });
        }
    };
    const onSave = async () => {
        const values = await form.validateFields();  //2.表单验证并获取表单值
        (async _ => {
            return await expenseOrderEditRequest({
                ...values,
                fundCostDetails: lists,
                superMerchantCode: localStorage.appid,
                operatorName: localStorage.operatorname,
                operatorId: localStorage.operatorId,
                costRuleNo: state && state.costRuleNo,
            });
        })().then(res => {
            if (res.code == 0) {
                message.success(res.msg)
                navigate(-1)
            } else {
                message.error(res.msg)
            }
        });
    }
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRowKeys);
            setSelectedRowKeys(selectedRowKeys)
        },
        onSelect: (record, selected, selectedRows, nativeEvent) => {
            setSelectedRows(selectedRows)
            let disable = selectedRows.every((item) => {
                return item.costDetailStatus == 1
            })
            let disable1 = selectedRows.every((item) => {
                return item.costDetailStatus != 1
            })
            setDisable(disable)
            setDisable1(disable1)
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            console.log(selected);
            setSelectedRows(selectedRows)
            let disable = selectedRows.every((item) => {
                return item.costDetailStatus == 1
            })
            let disable1 = selectedRows.every((item) => {
                return item.costDetailStatus != 1
            })
            setDisable(disable)
            setDisable1(disable1)
        },
        onSelectNone: (a) => {
            console.log(a);
        },
        onSelectInvert: (selectedRowKeys) => {
            console.log(selectedRowKeys)
        },
        getCheckboxProps: (record) => ({
            disabled: record.costDetailStatus == 2 || record.costDetailStatus == 3,
        }),
        // getCheckboxProps: (record) => ({
        //     disabled: record.auditStatus !== 0,
        //     // Column configuration not to be checked
        //     name: record.name,
        // }),
    };
    const typeCancel = () => {
        setTypeVisible(false)
    }
    const amountCancel = () => {
        setAmountVisible(false)
    }





    return (
        <div style={{ background: '#ffffff', padding: 16, marginTop: 16 }}>


            <Form
                {...layout}
                form={form}
                onFinish={onFinish}
                initialValues={{
                    merchantCode: localStorage.merchantCode
                }}
                style={{ paddingBottom: 0 }}
            >
                <Title level={5}>基本信息</Title>
                <Form.Item
                    name="costName"
                    label="单据名称"
                    rules={[
                        {
                            required: true,
                            message: '请输入单据名称!',
                        },
                    ]}
                // style={{ marginLeft: 30 }}
                >
                    <Input style={{ width: 200 }} placeholder="请输入" disabled />
                </Form.Item>
                <Form.Item
                    name="accountType"
                    label="支出账户"
                    rules={[
                        {
                            required: true,
                            message: '请选择支出账户!',
                        },
                    ]}
                // style={{ marginLeft: 30 }}
                >
                    <Select
                        disabled
                        style={{ width: 200 }}
                        allowClear
                        placeholder="请选择">
                        <Option value="0">店铺余额-待结算</Option>
                        <Option value="4">钱包余额-待结算</Option>
                        <Option value="1">店铺余额</Option>
                        <Option value="2">钱包余额</Option>
                        <Option value="7">营销余额-待结算</Option>
                    </Select>
                </Form.Item>
                {
                    localStorage.hxState == 'true' ?
                        <Form.Item
                            style={{ marginTop: 26 }}
                            name="payerMerchantCodesArry"
                            label="支出机构"
                            rules={[
                                {
                                    required: true,
                                    message: '请选择支出机构!',
                                },
                            ]}
                        >
                            <Select placeholder="请选择"
                                disabled
                                mode="multiple"
                                style={{ width: 200 }}
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                            >
                                {
                                    merchantList && merchantList.map((v) => {
                                        return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                    })
                                }

                            </Select>
                        </Form.Item>
                        :
                        <Form.Item
                            label="机构名称"
                        >
                            {localStorage.merchantName}
                        </Form.Item>
                }

                <Title level={5}>分账规则</Title>
                <Row style={{ marginBottom: '-1.5vh', marginTop: 16 }}>
                    <Col span={12}>
                        <Form.Item
                            wrapperCol={{
                                offset: 0,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit" >
                                添加
                            </Button>
                            <Upload
                                name="excel"
                                accept=".xls, .xlsx"
                                // listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={`${host}/costRuleDetail/import`}
                                headers={{ 'token': localStorage.token, }}
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                                data={file => ({ // data里存放的是接口的请求参数
                                    superMerchantCode: localStorage.appid,
                                    accountType: form.getFieldValue().accountType,
                                    superMerchantCode: localStorage.appid,
                                    payerMerchantCodes: form.getFieldValue().payerMerchantCodesArry.toString()
                                })}
                            >
                                <Button style={{ marginLeft: 20 }}
                                // disabled={form.getFieldValue().accountType && form.getFieldValue().payerMerchantCodesArry}
                                >批量导入</Button>
                            </Upload>
                            <Button type="link" onClick={() => {
                                window.open(`${host}/excel/费用单导入模板.xlsx`)
                            }}>下载Excel模板</Button>
                        </Form.Item>

                    </Col>
                    <Col span={12} style={{ textAlign: 'right', }}>
                        <Select placeholder="请选择"
                            style={{ width: 250, textAlign: 'left' }}
                            // rowClassName={() => { getRowClassName() }} //改变某一行的背景颜色
                            showSearch
                            allowClear
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.includes(input)}
                            onChange={(e) => {
                                console.log(e);
                                if (e) {
                                    let itmp = {}
                                    let list = []
                                    list = lists.filter(item => {
                                        if (item.id === e) {
                                            itmp = item
                                        }
                                        return item.id !== e
                                    })
                                    list.unshift(itmp)
                                    setLists([...list])
                                }
                            }}

                        >

                            {
                                arr && arr.map((v) => {
                                    return <Option key={v.id} value={v.id}>{v.payeeMerchantNames}</Option>
                                })
                            }

                        </Select>
                    </Col>
                </Row>
                <Table
                    rowSelection={{
                        ...rowSelection,
                    }}
                    locale={{
                        emptyText: "没有更多数据了"
                    }}
                    style={{ marginTop: 20 }}
                    columns={columns}
                    dataSource={lists}
                    rowKey='id'
                    scroll={{
                        x: 1800,
                    }}
                    pagination={false}
                    footer={() => {
                        return (
                            <div>
                                <Popconfirm
                                    title="是否确认批量删除？"
                                    onConfirm={() => {
                                        selectedRowKeys.map((i) => {
                                            console.log(i);
                                            let index = lists.findIndex((v) => { return v.id == i })
                                            console.log(index);
                                            if (index != -1) {
                                                lists.splice(index, 1)
                                                arr.splice(index, 1)
                                                setLists([...lists])
                                                setArr([...arr])
                                            }

                                        })

                                    }}
                                    onCancel={() => {
                                        console.log('取消');
                                    }}
                                    okText="是"
                                    cancelText="否"
                                >
                                    <Button style={{ marginLeft: 0 }} disabled={disable}>批量删除</Button>
                                </Popconfirm>
                                <Popconfirm
                                    title="是否确认批量停用？"
                                    onConfirm={() => {

                                        batchExpenseDelete()

                                    }}
                                    onCancel={() => {
                                        console.log('取消');
                                    }}
                                    okText="是"
                                    cancelText="否"
                                >
                                    <Button style={{ marginLeft: 16 }} disabled={disable1}>批量停用</Button>
                                </Popconfirm>
                                <Button style={{ marginLeft: 16 }}
                                    disabled={disable}
                                    onClick={() => {
                                        setTypeVisible(true)
                                    }}
                                >收支类型</Button>
                                <Button style={{ marginLeft: 16 }} disabled={disable}
                                    onClick={() => {
                                        setAmountVisible(true)
                                    }}
                                >单据金额</Button>
                            </div>

                        )
                    }}
                />
                <Row>
                    <Col span={24} style={{ textAlign: 'center', marginTop: 16 }}>
                        <Tooltip title={aipv > 0 ? "有错误数据，请先修改再提交" : ""} color='red'>
                            <Button type="primary"
                                // disabled={aipv > 0}
                                onClick={() => {
                                    onSave()
                                }}
                            >
                                保存
                            </Button>
                        </Tooltip>

                        <Button style={{ marginLeft: 16 }}
                            onClick={() => {
                                cancel()
                            }}
                        >
                            取消
                        </Button>

                    </Col>
                </Row>
                < Title level={5} > 操作信息</Title >
                {
                    operate && operate.map((v, index) => {
                        return <div style={{ marginTop: 16, marginLeft: 60 }} key={index}>
                            <div>操作人: {v.operatorName}</div>
                            <div style={{ marginTop: 16 }}>操作时间: {v.createTime}</div>
                            <div style={{ marginTop: 16 }}>操作内容: {v.remark}</div>
                        </div>
                    })
                }


            </Form >


            <Modal
                title="分账规则"
                visible={detailVisible}
                onOk={detailHandleOk}
                onCancel={detailhandleCancel}
                width={600}
                footer={null}
                getContainer={false}  //1.排除警告
            >
                <SmallEdit data={params} accountType={form.getFieldValue().accountType} callback={(state) => {
                    console.log(state)
                    let a = state;
                    let newArr = [state]
                    let jigouArr = []
                    let index = lists.findIndex((v) => { return v.id == state.id })
                    if (index != -1) {
                        lists[index] = state
                        setLists([...lists])
                    } else {
                        a.payeeMerchantNames = a.payeeMerchantNames.toString()
                        jigouArr.push(a)
                        setArr([...arr, ...jigouArr])
                        setLists([...lists, ...newArr])
                    }
                }}
                    closeDialog={() => {
                        setDetailVisible(false)
                    }} />
            </Modal>
            <Modal
                title="批量修改收支类型"
                visible={typeVisible}
                onCancel={typeCancel}
                width={400}
                footer={null}
                getContainer={false}  //1.排除警告
            >
                <Type
                    closeDialog={() => {
                        setTypeVisible(false)
                    }}
                    callback={(state) => {
                        console.log(state)
                        lists.map((v) => {
                            if (v.shareType == 0 && v.expenseReceiptType != 2 && v.expenseReceiptType != 3) {
                                v.expenseReceiptType = state.expenseReceiptType
                            }

                        })
                        setLists([...lists])
                        message.success('批量修改成功！')
                    }}
                />
            </Modal>
            <Modal
                title="批量修改单据金额"
                visible={amountVisible}
                onCancel={amountCancel}
                width={400}
                footer={null}
                getContainer={false}  //1.排除警告
            >
                <Amount
                    closeDialog={() => {
                        setAmountVisible(false)
                    }}
                    callback={(state) => {
                        console.log(state)
                        lists.map((v) => {
                            if (v.expenseReceiptType != 2 && v.expenseReceiptType != 3) {
                                v.expensePaySectionJson = state.expensePaySectionJson
                            }

                        })
                        setLists([...lists])
                        message.success('批量修改成功！')
                    }}
                />
            </Modal>
        </div >
    )
}
export default CostAdd;