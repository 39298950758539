import React, { useState, useEffect, useReducer } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { Popconfirm, Modal, Form, Row, Col, Input, Select, message, Button, Table } from 'antd';
import {
    settleMerchantListRequest,
    settingListRequest
} from '../../api/api'
import { useCallbackState } from '../../util/useCallbackState'
import Detail from './detail/index'
const Option = Select.Option
const dateFormat = 'YYYY-MM-DD';
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

const BankCard = () => {
    const [form] = Form.useForm();
    const { state } = useLocation();
    const [list, setList] = useState([])
    const [merchantList, setMerchantList] = useState([])
    const [merchantCode, setMerchantCode] = useCallbackState(null)
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [bankCardList, setBankCardList] = useState()
    const [visible, setVisible] = useState(false)
    const [total, setTotal] = useState()
    const [row, setRow] = useState(null)
    const [init, setInit] = useState(true)
    const superMerchantCode = localStorage.appid;
    const navigate = useNavigate();
    const columns = [
        {
            title: '所属机构',
            dataIndex: 'merchantCodeNames',
            key: 'merchantCodeNames',
            render(text, row) {
                return (
                    <div>{row.merchantCodeNames ? row.merchantCodeNames : '全部'}</div>
                )
            }
        },
        {
            title: '提现限额',
            dataIndex: 'limitMinAmount',
            key: 'limitMinAmount',
            render(text, row) {
                return (
                    <div>{row.limitMinAmount}/{row.limiMaxAmount}/{row.limitEveryday}</div>
                )
            }
        },

        {
            title: '提现到账',
            dataIndex: 'dgWithdrawType',
            key: 'dgWithdrawType',
            render(text, row) {
                return (
                    <div>{row.dgWithdrawType == 0 ? 'D0' : 'D1'}/{row.walletWithdrawType == 0 ? 'D0' : 'D1'}</div>
                )
            }

        },
        {
            title: '提现审核',
            dataIndex: 'dgCheckType',
            key: 'dgCheckType',
            render(text, row) {
                return (
                    <div>
                        {row.walletCheckType.toString() ? <span>钱包余额-{row.walletCheckType == 0 ? '手动' : '自动'}</span> : null}
                        <span>{row.dgCheckType.toString() ? '/' : null}</span>
                        {row.dgCheckType.toString() ? <span>斗拱余额-{row.dgCheckType == 0 ? '手动' : '自动'}</span> : null}
                    </div>
                )
            }

        },
        {
            title: '操作',
            dataIndex: '',
            key: '',
            render: (text, row) => (
                <>
                    {
                        <span style={{ color: '#165BD4', cursor: 'pointer' }} onClick={() => {
                            row.edit = false
                            row.detail = true
                            setRow(row)
                            setVisible(true)
                        }}>详情</span>
                    }
                    {
                        <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 20 }} onClick={() => {
                            row.edit = true
                            setRow(row)
                            setVisible(true)

                        }}>编辑</span>

                    }

                </>
            ),
        },
    ];
    useEffect(() => {
        settleMerchantList()
        settingList()
    }, [])
    const settingList = (data) => {
        let params = {
            ...data,
            superMerchantCode: localStorage.appid,
            pageSize: pageNum,
            currentPage: data && data.currentPage ? data.currentPage : currentPage
        };
        (async _ => {
            return await settingListRequest(params);
        })().then(res => {
            if (res.code == 0) {
                console.log(res);
                setList(res.data.settingResponses)
                setTotal(res.data.totalCount)
            } else {
                // message.error(res.msg)
            }
        });
    }

    const settleMerchantList = () => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode: localStorage.merchantCode,
                superMerchantCode,
            });
        })().then(res => {
            if (res.code == 0) {
                setInit(false)
                setMerchantList(res.data)
            } else {
                // message.error(res.msg)
            }
        });
    }
    const onChangPage = (currentPage, pageNum) => {
        // setInit(false)
        setCurrentPage(currentPage, () => {
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        // setInit(false)
        setPageNum((currentPage, pageNum), () => {
        })
    }
    const myCancel = () => {
        setVisible(false)
    }



    const onFinish = (values) => {

        values.currentPage = 1;
        settingList(values)
    };
    const goAdd = () => {
        navigate('/ws/guidePage', { replace: false, state: { edit: 0 } })
    }
    return (
        <>
            <div style={{ background: '#ffffff', paddingTop: 1, marginTop: 16 }}>
                {
                    localStorage.hxState == 'true' && localStorage.salesEmployeeId == 'null' ?
                        <Row style={{ marginBottom: '-1vh', margin: 16, }}>
                            <Button type='primary' onClick={() => {
                                // navigate('/ws/withdrawalSettings/detail', { replace: false, state: { add: true, edit: true, detail: false } })
                                setRow({ add: true, edit: true, detail: false })
                                setVisible(true)
                            }}>提现设置</Button>
                        </Row> : null
                }
                <Form
                    form={form}
                    {...layout}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                    style={{ background: '#F7F8FA', margin: 16, padding: 16, paddingBottom: 0 }}
                    initialValues={{
                    }}
                >
                    <Row style={{ marginBottom: '-1vh' }}>
                        <Col span={6} >
                            <Form.Item
                                name="merchantCode"
                                label="商户"
                            >
                                <Select placeholder="请选择"
                                    style={{ width: 200 }}
                                    onChange={(code) => {
                                    }}
                                    // mode="multiple"
                                    allowClear
                                    optionFilterProp="children"
                                    showSearch
                                    filterOption={(input, option) => option.children.includes(input)}
                                >

                                    {
                                        merchantList && merchantList.map((v) => {
                                            return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                        })
                                    }

                                </Select>
                            </Form.Item>
                        </Col>


                    </Row>
                    <Row style={{ marginBottom: '-1vh' }}>
                        <Col span={12}>
                            <Form.Item
                                wrapperCol={{
                                    offset: 3,
                                    span: 20,
                                }}
                            >
                                <Button type="primary" htmlType="submit">
                                    筛选
                                </Button>
                                <Button type="link"
                                    style={{ marginLeft: 10 }}
                                    onClick={() => {
                                        form.resetFields();
                                        settingList()

                                    }} >
                                    重置筛选条件
                                </Button>


                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Table
                    locale={{
                        emptyText: "没有更多数据了"
                    }}
                    rowKey='id'
                    style={{ marginTop: 16, margin: '0 16px' }}
                    columns={columns}
                    dataSource={list}

                    pagination={{
                        total,
                        showSizeChanger: true,
                        current: currentPage,
                        pageSize: pageNum,
                        // position: ["bottomCenter"],
                        showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                        onChange: (k, v) => onChangPage(k, v),
                        onShowSizeChange: (k, v) => onChangPageNum(k, v),
                    }}
                />
                <Modal
                    title="提现设置"
                    visible={visible}
                    onCancel={myCancel}
                    footer={null}
                    width={800}
                    getContainer={false}  //1.排除警告
                >
                    <Detail
                        data={row}
                        closeDialog={() => {
                            setVisible(false)
                        }}
                        callback={(state) => {
                            console.log(state)
                            if (state) {
                                settingList()
                            }
                            // message.success('批量修改成功！')
                        }}
                    />
                </Modal>


            </div >

        </>

    )
}

export default BankCard;