import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import {
    Form,
    Image,
    Input,
    Button,
    message,
    Row,
    Col,
    Typography,
    Select,
    InputNumber
} from 'antd';
import { orderUpdateRequest, settleMerchantListRequest, getConstantsRequest, queryByTypeRequest } from '../../../../api/api'
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
};
const { TextArea } = Input;
const { Option } = Select;
const { Title } = Typography;
const LnstitutionsInList = (props) => {
    const [form] = Form.useForm()
    const [merchantList, setMerchantList] = useState([])
    const [constants, setConstants] = useState(Array)
    const [payerMerchantName, setPayerMerchantName] = useState(String)
    const [payeeMerchantName, setPayeeMerchantName] = useState(String)
    const [transferName, setTransferName] = useState(String)
    const [payerAccountName, setPayerAccountName] = useState(props && props.data && props.data.payerAccountType == 1 ? '店铺余额' : '钱包余额')
    const [yue, setYue] = useState(0)
    useEffect(() => {
        console.log(props)
        getConstants()
        settleMerchantList()
        getAmount()
    }, [])
    useEffect(() => {
        console.log(props)
        form.setFieldsValue({
            payerMerchantCode: props && props.data && props.data.payerMerchantCode,
            payerAccountType: props && props.data && props.data.payerAccountType,
            payeeMerchantCode: props && props.data && props.data.payeeMerchantCode,
            transferPurpose: props && props.data && props.data.transferPurpose.toString(),
            amount: props && props.data && props.data.amount,
            remark: props && props.data && props.data.remark,
        })
    }, [constants])

    const getConstants = () => {

        (async _ => {
            return await getConstantsRequest({
                constantType: "FD_TR_PURPOSE"
            });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setConstants(res.data.items)
            } else {
            }
        });
    }
    const cancel = (status) => {
        props.closeDialog()
    }
    const settleMerchantList = () => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode: localStorage.appid,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            if (res.code == 0) {
                setMerchantList(res.data)
            } else {

            }
        });
    }
    const onFinish = async () => {
        const values = await form.validateFields()  //2.表单验证并获取表单值
        console.log(values)
        values.id = props.data && props.data.id ? props.data.id : '';
        values.payeeAccountType = form.getFieldValue().payerAccountType;
        (async _ => {
            return await orderUpdateRequest(values);
        })().then(res => {
            if (res.code == 0) {
                props.callback(true)
                props.closeDialog()
                message.success(res.msg)
            } else {
                message.error(res.msg)
            }
        });


    }
    const getAmount = (data) => {
        let params = {
            accountType: form.getFieldValue().payerAccountType,
            merchantCode: form.getFieldValue().payerMerchantCode,
        };
        (async _ => {
            return await queryByTypeRequest(params);
        })().then(res => {
            if (res.code == 0) {
                setYue(res.data.availableBalance);
            } else {
                message.error(res.msg)
            }
        });
    }
    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            <Form
                name="basic"
                {...layout}
                style={{ width: 600 }}
                form={form}
                onFinish={onFinish}
            >
                <div style={{ marginLeft: 20, marginTop: 16 }}>
                    <Title level={5}>付款账户信息</Title>
                </div>
                <Form.Item
                    name="payerMerchantCode"
                    label="付款机构"
                    rules={[{ required: true, message: '请选择付款机构!' }]}
                >
                    <Select placeholder="请选择"
                        style={{ width: 250 }}
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.includes(input)}
                        onChange={(e) => {
                            merchantList && merchantList.map((v) => {
                                if (v.merchantCode == e) {
                                    setPayerMerchantName(v.merchantName)
                                }
                            })
                            getAmount()
                        }}
                    >

                        {
                            merchantList && merchantList.map((v) => {
                                return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                            })
                        }

                    </Select>
                </Form.Item>
                <Form.Item
                    name="payerAccountType"
                    label="付款账户"
                    rules={[{ required: true, message: '请选择付款机构!' }]}
                >
                    <Select placeholder="请选择"
                        style={{ width: 250 }}
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.includes(input)}
                        onChange={(e) => {
                            setPayerAccountName(e == 1 ? '店铺余额' : '钱包余额')
                            getAmount()
                        }}
                    >
                        <Option rowKey={1} key={1} value={1}>店铺余额</Option>
                        <Option rowKey={2} key={2} value={2}>钱包余额</Option>
                    </Select>
                </Form.Item>
                <div style={{ marginLeft: 20, marginTop: 16 }}>
                    <Title level={5}>收款账户信息</Title>
                </div>
                <Form.Item
                    name="payeeMerchantCode"
                    label="收款机构"
                    rules={[{ required: true, message: '请选择收入机构!' }]}
                >
                    <Select placeholder="请选择"
                        style={{ width: 250 }}
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.includes(input)}
                        onChange={(e) => {
                            merchantList && merchantList.map((v) => {
                                if (v.merchantCode == e) {
                                    setPayeeMerchantName(v.merchantName)
                                }
                            })
                        }}
                    >

                        {
                            merchantList && merchantList.map((v) => {
                                return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                            })
                        }

                    </Select>
                </Form.Item>
                <Form.Item
                    name="payeeMerchantCode"
                    label="收款账户"
                    rules={[{ required: false, message: '请选择收入机构!' }]}
                >
                    {payerAccountName}
                </Form.Item>
                <div style={{ marginLeft: 20, marginTop: 16 }}>
                    <Title level={5}>转账信息</Title>
                </div>
                <Form.Item label="转账金额"
                    name="amount"
                    rules={[{ required: true, message: '请选择收入机构!' }]}
                >
                    <InputNumber placeholder='请输入'
                        style={{ width: 250 }}
                        addonAfter="元" />


                </Form.Item>
                <Form.Item
                    name="amount"
                    colon={false}
                    label=" "
                    style={{ marginTop: '-2vh' }}
                >
                    可用余额: ￥{yue}元
                </Form.Item>
                <Form.Item
                    name="transferPurpose"
                    label="转账用途"
                    rules={[{ required: true, message: '请选择转账用途!' }]}
                >
                    <Select placeholder="请选择"
                        style={{ width: 250 }}
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.includes(input)}
                        onChange={(e) => {
                            constants && constants.map((v) => {
                                if (v.constantCode == e) {
                                    setTransferName(v.constantName)
                                }
                            })
                        }}
                    >

                        {
                            constants && constants.map((v) => {
                                return <Option rowKey={v.constantCode} key={v.constantCode} value={v.constantCode}>{v.constantName}</Option>
                            })
                        }

                    </Select>
                </Form.Item>
                <Form.Item
                    name="remark"
                    label="转账附言"
                >
                    <TextArea
                        allowClear
                        autoSize={{
                            minRows: 3, maxRows: 6
                        }}
                        style={{ width: 250 }}
                        placeholder="最多可输入50个字，特殊字符会被过滤"
                    />
                </Form.Item>

            </Form>
            <div style={{ textAlign: 'right' }}>
                <Button onClick={() => { }}>删除</Button>
                <Button style={{ marginLeft: 20 }} type="primary" htmlType='submit' onClick={onFinish}>确定</Button>
            </div>


        </div >
    )
}

export default LnstitutionsInList;