import React, { useState, useEffect, useReducer } from 'react'
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import './style.less'
import {
    Form,
    Row,
    Col,
    Button,
    DatePicker,
    Input,
    Select,
    message,
    Radio,
    Modal,
    Cascader,
    InputNumber
} from 'antd';
import {
    expenseTypeEditRequest,
    expenseEditRequest,
    expenseTypeListRequest,
    shopAreaListRequest,
    groupListRequest,
    shopManageFeeSaveRequest,
    merchantInfoListRequest,
    shopManageFeeDetailRequest
} from '../../../api/api'
const dateFormat = 'YYYY-MM-DD';
const { Option } = Select;
const { RangePicker } = DatePicker;
const layout2 = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
};

const BillsAdd = () => {
    const [form] = Form.useForm()
    const { state } = useLocation()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalForm] = Form.useForm();
    const [manageAmounts, setManageAmounts] = useState([])
    const [purchaseTaxRates, setPurchaseTaxRates] = useState([])
    const [merchantInfo, setMerchantInfo] = useState([])
    const [options, setOptions] = useState([])
    const [area, setArea] = useState([])
    const [groupList, setGroupList] = useState([])
    const [data, setData] = useState(null)
    let [count, setCount] = useState(2);
    let [count1, setCount1] = useState(2);
    let [merchantGroup, setMerchantGroup] = useState([]);

    const navigate = useNavigate()
    useEffect(() => {
        getGroupList();
        getMerchantInfoList();
        if (state && state.id) {
            shopManageFeeDetail()
        }
    }, [])
    const onFinish = (values) => {
        console.log(values);
        // if (!data) {
        //     merchantGroup.map((v) => {
        //         v.id = v.key
        //         v.merchantName = v.children
        //         delete v.children
        //         delete v.key
        //         delete v.value
        //     });
        // }
        delete values.area
        delete values.expenseName
        if (values.manageAmount && manageAmounts.length == 0) {
            message.error("管理费分账不能为空")
            return false;
        }
        if (values.purchaseTaxRate && purchaseTaxRates.length == 0) {
            message.error("采购货款返佣分账不能为空")
            return false;
        }
        let params = {
            ...values,
            possessionDays: parseFloat(values.possessionDays),
            manageAmounts: manageAmounts,
            purchaseTaxRates: purchaseTaxRates,
            provinceName: area.length > 0 ? area[0].provinceName : undefined,
            provinceCode: area.length > 0 ? area[0].provinceCode : undefined,
            cityName: area.length > 0 ? area[1].label : undefined,
            cityCode: area.length > 0 ? area[1].value : undefined,
            superMerchantCode: localStorage.appid,
            merchantCode: localStorage.merchantCode,
            merchantArea: (area.length > 0 ? area[0].provinceName : undefined) + (area.length > 0 ? area[1].label : undefined),
            merchantGroup: merchantGroup,
            operatorId: localStorage.operatorId,
            id: state && state.id ? state.id : undefined,
        };
        (async _ => {
            return await shopManageFeeSaveRequest(params);
        })().then(res => {
            if (res.code == 'SUCCESS') {
                navigate(-1)
            } else {
                message.error(res.msg)
            }
        });
    }
    const shopAreaList = (code) => {
        (async _ => {
            return await shopAreaListRequest({
                manageMerchantType: code,
                superMerchantCode: localStorage.appid
            });
        })().then(res => {
            if (res.code == "SUCCESS") {
                res.result.map((v) => {
                    v.label = v.provinceName
                    v.value = v.provinceCode
                    v.children = v.cities.map((i) => {
                        return {
                            label: i.cityName,
                            value: i.cityCode,
                            id: i.id
                        }
                    })
                })
                setOptions(res.result)
            } else {
                message.error(res.msg)
            }
        });
    }
    const shopManageFeeDetail = (code) => {
        (async _ => {
            return await shopManageFeeDetailRequest({
                appId: localStorage.appid,
                superMerchantCode: localStorage.appid,
                id: state && state.id,
            });
        })().then(res => {
            if (res.code == "SUCCESS") {
                console.log(res)
                let areaArr = [];
                let merchantList = [];
                res.result.merchantGroup.map((v) => {
                    merchantList.push(v.id)
                })
                areaArr.push(res.result.provinceCode);
                areaArr.push(res.result.cityCode);
                form.setFieldsValue({
                    area: areaArr,
                    manageMerchantType: res.result.manageMerchantType,
                    expenseName: merchantList,
                    manageAmount: res.result.manageAmount,
                    purchaseAmount: res.result.purchaseAmount,
                    purchaseTaxRate: res.result.purchaseTaxRate,
                    possessionDays: res.result.possessionDays,
                })
                setData(res.result)
                setMerchantGroup(res.result.merchantGroup)
                setManageAmounts(res.result.manageAmounts)
                setPurchaseTaxRates(res.result.purchaseTaxRates)

            } else {
                message.error(res.msg)
            }
        });
    }
    const getMerchantInfoList = () => {
        (async _ => {
            return await merchantInfoListRequest({
                appId: localStorage.appid,
                superMerchantCode: localStorage.appid,
                MerchantTypeInfo: 'SHOP_INFO'
            });
        })().then(res => {
            if (res.code == "SUCCESS") {
                setMerchantInfo(res.result.merchantInfoList)
            } else {

            }
        });
    }
    const cell = () => {
        navigate(-1)
    }





    const getGroupList = (data) => {
        (async _ => {
            return await groupListRequest({
                ...data,
                currentPage: 1,
                pageSize: 10000,
                appId: localStorage.appid
            });
        })().then(res => {
            console.log(res);
            if (res.code == "SUCCESS") {
                setGroupList(res.result.list)
            } else {
                message.error(res.msg)
            }
        });
    }



    const getFields = () => {
        const children = [];
        for (let i = 0; i < count; i++) {
            children.push(
                <Col key={i}>
                    <Form.Item
                        name={`field-${i}`}
                        label={<span style={{ color: '#ffffff' }}>分账</span>}
                        colon={false}
                    >

                        {
                            i == 0 ? <Input value={"会员门店"} style={{ width: 120 }} /> : <Input value={"总部"} style={{ width: 120 }} />
                        }
                        <InputNumber addonBefore="￥" placeholder='请输入' style={{ width: 150, marginLeft: 10 }}
                            disabled={state && state.edit == 1}
                            min={0}
                            onChange={(e) => {
                                console.log(e)
                                let value = e;
                                danju2(value, i)
                            }}
                            value={manageAmounts.length > 0 ? manageAmounts[i] && manageAmounts[i].manageAmount : undefined}
                        />
                        {/* {
                            state && state.edit != 1 ?
                                <Button type="link" onClick={() => {
                                    // console.log(children)
                                    del(i)
                                    // return children.splice(i, 1)
                                    // console.log(children.splice(i, 1))
                                }}>删除</Button>
                                : null
                        } */}
                    </Form.Item>
                </Col>,
            );
        }
        return children;
    };

    const getFields1 = () => {
        const children = [];
        for (let i = 0; i < count1; i++) {
            children.push(
                <Col key={i}>
                    <Form.Item
                        name={`field-${i}`}
                        label={<span style={{ color: '#ffffff' }}>{i == 0 ? "会员门店" : "总部"}</span>}
                        colon={false}
                    >

                        {
                            i == 0 ? <Input value={"会员门店"} style={{ width: 120 }} /> : <Input value={"总部"} style={{ width: 120 }} />
                        }
                        <InputNumber addonAfter="%" placeholder='请输入' style={{ width: 150, marginLeft: 10 }}
                            onChange={(e) => {
                                let value = e;
                                danju4(value, i)
                            }}
                            disabled={state && state.edit == 1}
                            value={purchaseTaxRates.length > 0 ? purchaseTaxRates[i] && purchaseTaxRates[i].purchaseTaxRate : undefined}
                            min={0}
                        />
                        {/* {
                            state && state.edit != 1 ?
                                <Button type="link" onClick={() => {
                                    // console.log(children)
                                    del1(i)
                                    // return children.splice(i, 1)
                                    // console.log(children.splice(i, 1))
                                }}>删除</Button> : null
                        } */}
                    </Form.Item>
                </Col>,
            );
        }
        return children;
    };
    const forceUpdate = useReducer((bool) => !bool)[1]
    const danju1 = (code, name, i) => {
        let obj = {
            splitMerchantCode: code,
            splitMerchantName: name
        }
        manageAmounts[i] = { ...manageAmounts[i], ...obj }
        setManageAmounts(manageAmounts)
    }
    const danju2 = (value, i) => {
        let obj = {
            manageAmount: value,
            splitMerchantCode: i == 0 ? 'EW_B2BMEMBERMENDIAN' : localStorage.appid,
            splitMerchantName: i == 0 ? '会员门店' : '总部',
        }
        manageAmounts[i] = { ...manageAmounts[i], ...obj }
        setManageAmounts(manageAmounts)
        forceUpdate()
    }
    const danju3 = (code, name, i) => {
        let obj = {
            splitMerchantCode: code,
            splitMerchantName: name
        }
        purchaseTaxRates[i] = { ...purchaseTaxRates[i], ...obj }
        setPurchaseTaxRates(purchaseTaxRates)
    }
    const danju4 = (value, i) => {
        let obj = {
            purchaseTaxRate: value,
            splitMerchantCode: i == 0 ? 'EW_B2BMEMBERMENDIAN' : localStorage.appid,
            splitMerchantName: i == 0 ? '会员门店' : '总部',
        }
        purchaseTaxRates[i] = { ...purchaseTaxRates[i], ...obj }
        setPurchaseTaxRates(purchaseTaxRates)
        forceUpdate()
    }


    const add = () => {
        if (count == 6) return false
        setCount(count + 1)

    }
    const add1 = () => {
        if (count1 == 3) return false
        setCount1(count1 + 1)
    }
    const del = (i) => {
        manageAmounts.splice(i, 1)
        setCount(manageAmounts.length)
    }
    const del1 = (i) => {
        purchaseTaxRates.splice(i, 1)
        setCount1(purchaseTaxRates.length)
    }
    const typeCancel = () => {
        setIsModalVisible(false)
    }

    const areaChange = (value, selectedOptions) => {
        console.log(selectedOptions);
        setArea(selectedOptions)


    };
    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            <div>

                <Form
                    name="dynamic_form_item"
                    form={form}
                    {...layout2}
                    initialValues={{

                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <div style={{ width: '100%', marginLeft: 20, marginTop: 20, marginBottom: 20 }} className='borderBottom'>基本信息</div>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="支出机构：" >
                                总部
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="类型" name="manageMerchantType">
                                {
                                    data ?
                                        <span>
                                            {data && data.manageMerchantType == 0 ? '良记订货门店' : ''}
                                            {data && data.manageMerchantType == 1 ? '良记会员门店' : ''}
                                        </span>
                                        :
                                        <Select placeholder="请选择"
                                            style={{ width: 180 }}
                                            disabled={state && state.edit == 1}
                                            allowClear
                                            onChange={(code) => {
                                                console.log(code);
                                                setOptions([])
                                                form.setFieldsValue({
                                                    area: undefined
                                                })
                                                shopAreaList(code);
                                            }}
                                        >
                                            <Option value={0} key={0}>良记订货门店</Option>
                                            <Option value={1} key={1}>良记会员门店</Option>
                                        </Select>
                                }

                            </Form.Item>
                        </Col>
                    </Row>


                    <Row>
                        <Col span={12}>
                            <Form.Item label="区域" name="area"
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择区域!',
                                    },
                                ]}
                            >
                                {
                                    data ?
                                        <span>{data && data.merchantArea}</span>
                                        :
                                        <Cascader options={options}
                                            disabled={state && state.edit == 1}
                                            onChange={areaChange} placeholder="请选择"
                                            style={{ width: 180 }}
                                        />
                                }

                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={12}>
                            <Form.Item label="机构组"
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择机构组!',
                                    },
                                ]}
                                name="expenseName">
                                <Select placeholder="请选择"
                                    style={{ width: 180 }}
                                    mode="multiple"
                                    allowClear
                                    disabled={state && state.edit == 1}
                                    onChange={(code, Option) => {
                                        console.log(Option);
                                        Option.map((v) => {
                                            v.id = v.key
                                            v.merchantName = v.children
                                            delete v.key;
                                            delete v.children;
                                            delete v.value;
                                        });
                                        setMerchantGroup(Option)
                                    }}
                                >
                                    {
                                        groupList && groupList.map((v) => {
                                            return <Option value={v.id} key={v.id}>{v.name}</Option>
                                        })
                                    }

                                </Select>

                            </Form.Item>
                        </Col>
                    </Row>



                    <div style={{ width: '100%', marginTop: 20, marginLeft: 20, marginBottom: 20 }} className='borderBottom'>创建信息</div>

                    <Row>
                        <Col span={12}>
                            <Form.Item
                                name="manageAmount"
                                label="管理费"
                            >
                                <InputNumber
                                    style={{ width: 180 }}
                                    disabled={state && state.edit == 1}
                                    placeholder='请输入管理费'
                                    addonBefore="￥"
                                    min={0}
                                />


                            </Form.Item>
                        </Col>
                        {/* <Col span={4} pull={6}>

                            {

                                state && state.edit != 1 ?
                                    <Form.Item
                                        noStyle
                                    >
                                        <Button onClick={() => {
                                            add(manageAmounts)
                                        }} type="link">添加分账</Button>
                                    </Form.Item> : null
                            }


                        </Col> */}
                    </Row>
                    <Row>
                        <Col span={12}>
                            {getFields()}
                        </Col>
                    </Row>

                    <Row>
                        <Col span={12}>
                            <Form.Item label="采购货款(最小值)" name="purchaseAmount"
                                rules={[
                                    {
                                        required: true,
                                        message: '请填写采购货款!',
                                    },
                                ]}
                            >
                                <InputNumber placeholder='采购货款(最小值)'

                                    disabled={state && state.edit == 1} style={{ width: 180 }} />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row>
                        <Col span={12}>
                            <Form.Item
                                name="purchaseTaxRate"
                                label="采购货款返佣"
                            >
                                <InputNumber style={{ width: 180 }}
                                    min={0}
                                    placeholder='请输入采购货款返佣'
                                    disabled={state && state.edit == 1} addonAfter="%" />
                            </Form.Item>
                        </Col>
                        {/* <Col span={4} pull={6}>
                            {
                                state && state.edit != 1 ?
                                    <Form.Item
                                        noStyle
                                    >
                                        <Button onClick={add1} type="link">添加分账</Button>
                                    </Form.Item> : null
                            }
                        </Col> */}
                    </Row>
                    <Row>
                        <Col span={12}>
                            {getFields1()}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>

                            <Form.Item
                                label="占有时间(介绍关系)"
                                name="possessionDays"
                                rules={[
                                    {
                                        required: false,
                                        message: '请选择!',
                                    },
                                ]}>
                                <Input addonBefore="日" style={{ width: 180 }} disabled={state && state.edit == 1} placeholder="请输入几日" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{ width: '100%', marginTop: 20, marginLeft: 20, marginBottom: 20 }} className='borderBottom'>创建信息</div>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="创建人：">
                                {state && state.operator ? state.operator : localStorage.operatorname ? localStorage.operatorname : undefined}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="创建时间：">
                                {state && state.createTime ? state.createTime : moment().format('YYYY-MM-DD HH:mm:ss')}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        {
                            state && state.edit != 1 ?
                                <Col span={24} style={{ textAlign: 'center' }}>
                                    <Button type="primary" htmlType='submit'>保存</Button>
                                    <Button style={{ marginLeft: 20 }} onClick={cell}>取消</Button>
                                </Col> : null
                        }

                    </Row>
                </Form>
            </div>



        </div>
    )
}

export default BillsAdd;