import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
    Form,
    Row,
    Col,
    Button,
    Input,
    Select,
    Table,
    Popconfirm,
    message,
    Modal,
    DatePicker,
    Tooltip
} from 'antd';
import {
    merchantListRequest,
    merchQueryAndOpenPayRequest,
    refushPaySignRequest,
    openRepairRequest,
    allMerchantListRequest,
    configDetailRequest,
    huifudgMerchantListRequest,
    updateHuifuDgRequest
} from '../../../api/api'
import { useCallbackState } from '../../../util/useCallbackState'
import QRCode from 'qrcode.react'
const { Option } = Select;
const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
};

const LnstitutionsInList = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [total, setTotal] = useState()
    const [list, setList] = useState([])
    const [data, setData] = useState(null)
    const [mianqian, setMianqian] = useState(false)
    const [qrCode, setQrcode] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [row, setRow] = useState(null)
    const [date, setDate] = useState(null)
    const [merchantdataList, setMerchantdataList] = useState([])
    const superMerchantCode = localStorage.appid
    const [init, setInit] = useState(true)
    const { search, state } = useLocation()
    const [mySwitch, setMySwitch] = useState(state && state.tag == 1 ? true : localStorage.state == "1" ? true : false)
    const [bankType, setBankType] = useState(null)

    useEffect(() => {
        configDetail()
        if (init && localStorage.hxState == 'true') {
            allMerchantList()
        }
    }, [currentPage, pageNum, mySwitch])
    const columns = [
        {
            title: '机构名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
        },
        {
            title: '机构编码',
            dataIndex: 'merchantCode',
            key: 'merchantCode',
        },
        {
            title: '余额支付',
            dataIndex: 'balancePayStatus',
            key: 'balancePayStatus',
            render: (text, row) => {
                return (
                    <div>
                        {row && row.balancePayStatus == 1 ? '已设置' : ''}
                        {row && row.balancePayStatus == 0 ? <div><span>未设置</span>
                            {
                                row.accountOpenStatus == 1 ?
                                    <span style={{ color: "#165BD4", cursor: 'pointer', marginLeft: 10 }}
                                        onClick={() => {
                                            merchQueryAndOpenPay({ outMerchantId: row.outMerchantId, superMerchantCode: localStorage.appid, wsMerchantId: row.wsMerchantId })
                                        }}
                                    >设置</span> : ''
                            }
                        </div> : ''}
                        {row && row.balancePayStatus == 2 ? <div><span style={{ color: "#C00000" }}>失败</span>
                            {
                                row.accountOpenStatus == 1 ?
                                    <span style={{ color: "#165BD4", cursor: 'pointer', marginLeft: 10 }}
                                        onClick={() => {
                                            merchQueryAndOpenPay({ outMerchantId: row.outMerchantId, superMerchantCode: localStorage.appid, wsMerchantId: row.wsMerchantId })
                                        }}>重试</span> : ''
                            }
                        </div> : ''}
                    </div>
                )
            }

        },
        {
            title: '余额支付免短信',
            dataIndex: 'address',
            key: '3',
            render: (text, row) => {
                return (
                    <div>
                        {row && row.balanceNoSmsStatus == 1 ? '已设置' : ''}

                        {row && row.balanceNoSmsStatus == 3 ?
                            <div>
                                {
                                    row.accountOpenStatus == 1 ?
                                        <span style={{ color: "#165BD4", cursor: 'pointer', marginLeft: 10 }}
                                            onClick={() => {
                                                setQrcode(row.backUrl)
                                                setMianqian(true)
                                            }}>处理中</span>
                                        : ''
                                }
                                {
                                    row.accountOpenStatus == 1 ?
                                        <span style={{ color: "#165BD4", cursor: 'pointer', marginLeft: 10 }}
                                            onClick={() => {
                                                open({ merchantCode: row.merchantCode, superMerchantCode: localStorage.appid, })
                                            }}>重试</span> : ''
                                }

                            </div> : ''}
                        {
                            row && row.balanceNoSmsStatus == 0 ? <div>

                                <span>未设置</span>
                                {
                                    row.accountOpenStatus == 1 ?
                                        <span style={{ color: "#165BD4", cursor: 'pointer', marginLeft: 10 }}
                                            onClick={() => {
                                                open({ merchantCode: row.merchantCode, superMerchantCode: localStorage.appid, })
                                            }}>设置</span> : ''
                                }

                            </div> : ''
                        }
                        {
                            row && row.balanceNoSmsStatus == 2 ? <div><span style={{ color: "#C00000" }}>失败</span>
                                {
                                    row.accountOpenStatus == 1 ?
                                        <span style={{ color: "#165BD4", cursor: 'pointer', marginLeft: 10 }}
                                            onClick={() => {
                                                open({ merchantCode: row.merchantCode, superMerchantCode: localStorage.appid, })
                                            }}>重试</span>
                                        : ''
                                }

                            </div> : ''
                        }
                    </div>
                )


            }
        },
        {
            title: '注册状态',
            dataIndex: 'accountOpenStatus',
            key: 'accountOpenStatus',
            render: (text, row) => {
                return (
                    <div>
                        {row.accountOpenStatus == 0 ? '审核中' : ''}
                        {row.accountOpenStatus == 1 ? '成功' : ''}
                        {row.accountOpenStatus == 2 ? '失败' : ''}
                        {row.accountOpenStatus == 3 ? '处理中' : ''}
                    </div>
                )
            }
        },
        {
            title: '开始补单时间',
            dataIndex: 'openRepairTime',
            key: 'openRepairTime',
            render: (text, row) => {
                return (
                    <div>
                        {
                            row && row.openRepairTime ?
                                <div>
                                    <span>{row && row.openRepairTime}</span>
                                    {/* <span>修改</span> */}
                                </div>
                                :
                                <div>
                                    <span>未设置</span>
                                    {
                                        row.accountOpenStatus == 1 ?
                                            <span style={{ color: "#165BD4", cursor: 'pointer', marginLeft: 10 }}
                                                onClick={() => {
                                                    showModal()
                                                    setRow(row.merchantCode)
                                                }}>设置</span> : ''
                                    }

                                </div>
                        }
                    </div>
                )
            }
        },
        {
            title: '创建人',
            dataIndex: 'address',
            key: '7',
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 140,
            align: 'right',
            render: (text, row) => (
                <>
                    {/* <Popconfirm
                        title="是否确认删除?"
                        onConfirm={() => {
                            console.log('confirm')
                        }}
                        onCancel={() => {

                        }}
                        okText="是"
                        cancelText="否"
                    >
                        <a href="#">删除</a>
                    </Popconfirm> */}
                    <span style={{ color: '#165BD4', cursor: 'pointer', marginRight: 10 }} onClick={() => {
                        row.ss = 2
                        navigate('/ws/institutionsIn/edit', { replace: false, state: row })
                    }}>编辑</span>
                    <span style={{ color: '#165BD4', cursor: 'pointer' }} onClick={() => {
                        navigate('/ws/institutionsIn/detail', { replace: false, state: row })
                    }}>详情</span>
                </>

            ),

        },
    ];
    const columns1 = [
        {
            title: '机构名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
        },
        {
            title: '机构编码',
            dataIndex: 'merchantCode',
            key: 'merchantCode',
        },
        {
            title: '余额支付',
            dataIndex: 'balancePayStatus',
            key: 'balancePayStatus',
            render: (text, row) => {
                return (
                    <div>
                        已开通
                    </div>
                )
            }

        },
        {
            title: '注册状态',
            dataIndex: 'accountOpenStatus',
            key: 'accountOpenStatus',
            render: (text, row) => {
                return (
                    <div>
                        成功
                    </div>
                )
            }
        },
        {
            title: '开始补单时间',
            dataIndex: 'openRepairTime',
            key: 'openRepairTime',
            render: (text, row) => {
                return (
                    <div>
                        {
                            row && row.openRepairTime ?
                                <div>
                                    <span>{row && row.openRepairTime}</span>
                                    {/* <span>修改</span> */}
                                </div>
                                :
                                <div>
                                    <span>未设置</span>
                                    <span style={{ color: "#165BD4", cursor: 'pointer', marginLeft: 10 }}
                                        onClick={() => {
                                            showModal()
                                            setRow(row)
                                        }}>设置</span>

                                </div>
                        }
                    </div>
                )
            }
        },
        {
            title: '创建人',
            dataIndex: 'address',
            key: '7',
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 190,
            align: 'center',
            render: (text, row) => (
                <>
                    <span style={{ color: '#165BD4', cursor: 'pointer', marginRight: 10 }} onClick={() => {
                        row.ss = 2
                        navigate('/ws/institutionsIn/addDG', { replace: false, state: row })
                    }}>编辑</span>
                    <span style={{ color: '#165BD4', cursor: 'pointer', marginRight: 10 }} onClick={() => {
                        row.ss = 1
                        navigate('/ws/institutionsIn/addDG', { replace: false, state: row })
                    }}>详情</span>
                    <span style={{ color: '#165BD4', cursor: 'pointer' }} onClick={() => {
                        navigate('/ws/guidePage', { replace: false, state: row })
                    }}>添加银行卡</span>
                </>

            ),

        },
    ];
    const configDetail = () => {
        (async _ => {
            return await configDetailRequest({
                merchantCode: localStorage.merchantCode,
                superMerchantCode,
            });
        })().then(res => {
            if (res.code == 0) {
                console.log(res.data.bankType)
                setBankType(res.data.bankType)
                getList()
            } else {
            }
        });
    }
  
    const onFinish = (values) => {
        values.currentPage = 1;
        merchantList(values)
    };
    const openRepair = () => {
        if (bankType == 0) {
            wsDate()
        } else {
            dgDate()
        }
    }

    //网商设置补单时间
    const wsDate = () => {
        (async _ => {
            return await openRepairRequest(
                {
                    openRepairTime: date,
                    merchantCode: row,
                    superMerchantCode,
                }
            );
        })().then(res => {
            if (res.code == 0) {
                // setOutMchId(res.data) 
                // setStatus(res.data)
                merchantList()
            } else {
                message.error(res.msg)
            }
        });
    }
    //斗拱设置补单时间
    const dgDate = () => {
        console.log(row);
        (async _ => {
            return await updateHuifuDgRequest(
                {
                    openRepairTime: date,
                    id: row.id,
                }
            );
        })().then(res => {
            if (res.code == 0) {
                // setOutMchId(res.data) 
                // setStatus(res.data)
                merchantList()
            } else {
                message.error(res.msg)
            }
        });
    }

    const allMerchantList = () => {

        (async _ => {
            return await allMerchantListRequest(
                {
                    merchantCode: localStorage.merchantCode,
                    superMerchantCode,
                }
            );
        })().then(res => {
            if (res.code == 0) {
                setMerchantdataList(res.data)
                setInit(false)
            } else {
                message.error(res.msg)
            }
        });
    }
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        openRepair()
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const open = (data) => {

        (async _ => {
            return await refushPaySignRequest(
                {
                    ...data,
                }
            );
        })().then(res => {
            if (res.code == 0) {
                setMianqian(true)
                setQrcode(res.data)
                message.success(res.msg)
            } else {
                message.error(res.msg)
            }
        });
    }
    const merchQueryAndOpenPay = (data) => {
        (async _ => {
            return await merchQueryAndOpenPayRequest(
                {
                    ...data,
                    // superMerchantCode: "EW_N5464054266",
                }
            );
        })().then(res => {
            if (res.code == 0) {
                // setData(res.data)
                merchantList()
            } else {
                message.error(res.msg)
            }
        });
    }
    const goAdd = () => {
        if (bankType == 0) {
            navigate('/ws/institutionsIn/add', { replace: false, state: { bankType } })
        } else {
            navigate('/ws/institutionsIn/addDG', { replace: false, state: { bankType } })
        }

    }
    const dateChange = (date) => {
        setDate(date.format('YYYY-MM-DD HH:mm:ss'))
    }
    const getList = (data) => {
        if (bankType == 0) {
            merchantList()
        } else {
            merchantListDG()
        }
    }
    const merchantList = (data) => {
        let params = {
            ...data,
            currentPage: data && data.currentPage ? data.currentPage : currentPage,
            pageSize: pageNum,
            superMerchantCode: localStorage.appid,
        };
        (async _ => {
            return await merchantListRequest(params);
        })().then(res => {
            if (res.code == 0) {
                setList(res.data.merchantListVos)
                setTotal(res.data.totalCount)
                setData(res.data)
            } else {
                message.error(res.msg)
            }
        });
    }
    const merchantListDG = (data) => {
        let params = {
            ...data,
            currentPage: data && data.currentPage ? data.currentPage : currentPage,
            pageSize: pageNum,
            superMerchantCode: localStorage.appid,
        };
        (async _ => {
            return await huifudgMerchantListRequest(params);
        })().then(res => {
            if (res.code == 0) {
                console.log(res)
                setList(res.data.merchantListVos)
                setTotal(res.data.totalCount)
                setData(res.data)
            } else {
                message.error(res.msg)
            }
        });
    }

    const mianqianHandleCancel = () => {
        setMianqian(false);
    };
    const onChangPage = (currentPage, pageNum) => {
        setCurrentPage(currentPage, () => {
            // merchantList()
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        setPageNum((currentPage, pageNum), () => {
            // merchantList()
        })
    }
    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    };
    return (
        <div style={{ background: '#ffffff', padding: 16, marginTop: 16 }}>
            <Row style={{ marginBottom: '-1vh' }}>

                {
                    bankType ?
                        <Button type='primary' onClick={goAdd}>机构入驻</Button>
                        :

                        <Tooltip placement="topLeft" title="请先进行初始设置">
                            <Button type='primary' onClick={goAdd} disabled>机构入驻</Button>
                        </Tooltip>
                }

                {/* <QRCode value={erweima} size={256} id="qrCode" /> */}
            </Row>
            <Form
                form={form}
                {...layout}
                name="advanced_search"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{
                    status: null
                }}
                style={{ marginTop: 16, background: '#F7F8FA', padding: 16, paddingBottom: 0 }}
            >
                <Row style={{ marginTop: 20, background: '#F7F8FA', marginBottom: '-1vh' }}>
                    {
                        localStorage.hxState == 'true' ?
                            <Col span={8}>
                                <Form.Item
                                    name="merchantCode"
                                    label="机构名称"
                                >
                                    <Select style={{ width: 200 }}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.includes(input)}

                                    >
                                        {
                                            merchantdataList && merchantdataList.map((v) => {
                                                return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col> : ''
                    }

                    <Col span={8}>
                        <Form.Item
                            name="status"
                            label="注册状态："
                        >
                            <Select style={{ width: 200 }}>
                                <Option value={null}>全部</Option>
                                <Option value="0">待审核</Option>
                                <Option value="1">审核通过</Option>
                                <Option value="2">审核失败</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '-1vh' }}>
                    <Col span={8} >
                        <Form.Item
                            wrapperCol={{
                                offset: 5,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                筛选
                            </Button>
                            {/* <Button
                                style={{ margin: '0 8px' }}

                            >
                                导出
                            </Button>
                            <Button type="link"  >
                                查看已导出列表
                            </Button> */}
                            <Button type="link" onClick={() => {
                                form.resetFields();
                            }} >
                                重置筛选条件
                            </Button>
                        </Form.Item>


                    </Col>
                </Row>
            </Form>

            <Table
                locale={{
                    emptyText: "没有更多数据了"
                }}
                style={{ marginTop: 20 }}
                columns={bankType == 0 ? columns : columns1}
                dataSource={list}
                rowKey="id"
                scroll={{
                    x: 1400,
                }}
                pagination={{
                    total,
                    showSizeChanger: true,
                    current: currentPage,
                    pageSize: pageNum,
                    // position: ["bottomCenter"],
                    showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                    onChange: (k, v) => onChangPage(k, v),
                    onShowSizeChange: (k, v) => onChangPageNum(k, v),
                }}
            />
            <Modal
                width={300}
                title="设置开始补单时间" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <DatePicker showTime style={{ width: '100%' }}
                    disabledDate={disabledDate}
                    onChange={dateChange} />
            </Modal>
            <Modal
                width={300}
                title="请用支付宝扫描二维码" visible={mianqian} onCancel={mianqianHandleCancel}
                footer={null}
            >
                <Row justify='center'>
                    <QRCode
                        value={qrCode} //value参数为字符串类型
                        size={200} //二维码的宽高尺寸
                        fgColor="#000000"  //二维码的颜色
                    />
                </Row>

            </Modal>
        </div>
    )
}
export default LnstitutionsInList;