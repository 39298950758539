import { Row, Col, Button, Form, Table, Select, message, Modal } from 'antd';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { walletAccountViewRequest, settleMerchantListRequest, viewExportRequest } from '../../../api/api'
import { useCallbackState } from '../../../util/useCallbackState'
import ExportList from '../../../components/exportList'
const { Option } = Select;
const AvailableFunds = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { search } = useLocation()
    const { state } = useLocation()
    const [list, setList] = useState([])
    const [data, setData] = useState(null)
    const [merchantList, setMerchantList] = useState([])
    const [merchantCode, setMerchantCode] = useState(localStorage.merchantCode)
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [total, setTotal] = useState()
    const [init, setInit] = useState(true)
    const [exportVisible, setExportVisible] = useState(false);

    useEffect(() => {
        console.log(state)
        getWalletView();
        if (init) {
            settleMerchantList();
        }

    }, [currentPage, pageNum]);

    const columns = [
        {
            title: '商户名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
            align: 'left'
        },
        {
            title: '钱包余额(元)',
            dataIndex: 'availableBalance',
            key: 'availableBalance',
            align: 'left'
        },
        {
            title: '待结算金额(元)',
            dataIndex: 'settleBalance',
            key: 'settleBalance',
            align: 'left',
        },
        {
            title: '不可用余额(元)',
            dataIndex: 'freezenBalance',
            key: 'freezenBalance',
            align: 'left',
        },
        {
            title: '操作',
            key: 'operation',
            // fixed: 'right',
            width: 140,
            align: 'right',
            render: (text, row) => (
                <>
                    <span style={{ color: '#165BD4', cursor: 'pointer' }} onClick={() => {

                        navigate('/ws/balance/walletBalance', { replace: false, state: { ...row, tag: 1 } })
                    }}>查看</span>
                </>

            ),

        },
    ];
    const exportHandleOk = () => {
        setExportVisible(false);
    };
    const exporthandleCancel = () => {
        setExportVisible(false);
    };

    const onFinish = (values) => {
        getWalletView(values)
    };
    const getWalletView = (data) => {
        let params = {
            ...data,
            currentPage: currentPage,
            pageSize: pageNum,
            merchantCode: merchantCode,
            superMerchantCode: localStorage.appid,
        };
        (async _ => {
            return await walletAccountViewRequest(params);
        })().then(res => {
            if (res.code == 0) {
                setData(res.data)
                setList(res.data.list)
                setTotal(res.data.totalCount)
            } else {

            }
        });
    }
    const viewExport = () => {
        let values = form.getFieldValue();
        let params = {
            ...values,
            operatorId: localStorage.operatorId,
            operatorName: localStorage.operatorname,
            superMerchantCode: localStorage.appid,
        };
        (async _ => {
            return await viewExportRequest(params);
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                message.success(res.msg)
            } else {
                message.success(res.msg)
            }
        });
    }
    const settleMerchantList = () => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode: localStorage.merchantCode,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            if (res.code == 0) {
                setInit(false)
                setMerchantList(res.data)
            } else {

            }
        });
    }
    const onChangPage = (currentPage, pageNum) => {
        setCurrentPage(currentPage, () => {
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        setPageNum((currentPage, pageNum), () => {
        })
    }

    const goRechargeRecord = () => {
        navigate('/ws/balance/recharge', { replace: false, state: { defaultActiveKey: 2, tag: 1 } })
    }
    const goWithdrawalRecord = () => {
        navigate('/ws/balance/withdrawal', { replace: false, state: { defaultActiveKey: 2, tag: 1, row: state } })
    }
    const goSettlementRecords = () => {
        navigate('/ws/balance/settlementRecords', { replace: false, state: { billType: 3, accountType: 4 } })
    }
    return (
        <div style={{ background: '#ffffff', padding: 16, marginTop: 16 }}>
            <Row justify='space-between' style={{ background: '#F7F8FA', padding: 16, }}>
                <Col span={12}>
                    <div><span>钱包余额(元)</span>
                        <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 20 }} onClick={goRechargeRecord}>充值记录</span>
                        {/* <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 20 }} onClick={goWithdrawalRecord}>提现记录</span> */}
                    </div>
                    <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{data && data.walletAvailableBalance}</div>
                </Col>
                <Col span={12}>
                    <Row>
                        <Col span={12}>
                            <div><span>待结算金额(元)</span>  <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 20 }} onClick={goSettlementRecords}>结算记录</span></div>
                            <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{data && data.settleBalance}</div>
                        </Col>
                        <Col span={12}>
                            <div><span>不可用余额(元)</span>
                                <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 20 }} onClick={() => {
                                    navigate('/ws/balance/availableAndAvailable', { replace: false, state: { tag: 1 } })
                                }}>明细</span>
                            </div>
                            <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{data && data.walletFreezenBalance}</div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ paddingTop: 16 }}>

                <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}
                    initialValues={{ subMerchantCode: null }}
                >

                    <Form.Item
                        name="subMerchantCode"
                        label="商户名称："
                        rules={[{ required: false, message: 'Please input your username!' }]}
                    >
                        <Select
                            placeholder="请选择"
                            style={{ width: 250 }}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.includes(input)}
                        >
                            <Option key={null} value={null}>全部</Option>
                            {
                                merchantList && merchantList.map((v) => {
                                    return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                })
                            }

                        </Select>
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            筛选
                        </Button>
                        <Button
                            style={{ marginLeft: 20 }}
                            onClick={() => {
                                viewExport()
                            }}
                        >
                            导出
                        </Button>
                        <Button
                            style={{ marginLeft: 20 }}
                            type="link"
                            onClick={() => {
                                setExportVisible(true);
                            }}
                        >
                            查看已导出列表
                        </Button>
                        <Button
                            style={{ marginLeft: 20 }}
                            type="link"
                            onClick={() => {
                                form.setFieldsValue({
                                    subMerchantCode: null
                                })
                                getWalletView()
                            }}
                        >
                            重置筛选条件
                        </Button>
                    </Form.Item>

                </Form>
            </Row>

            <Table
                locale={{
                    emptyText: "没有更多数据了"
                }}
                style={{ marginTop: 20 }}
                columns={columns}
                dataSource={list}
                rowKey="merchantCode"
                pagination={{
                    total,
                    showSizeChanger: true,
                    current: currentPage,
                    pageSize: pageNum,
                    showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                    onChange: (k, v) => onChangPage(k, v),
                    onShowSizeChange: (k, v) => onChangPageNum(k, v),
                }}
            />
            <Modal
                title="导出列表"
                visible={exportVisible}
                onOk={exportHandleOk}
                onCancel={exporthandleCancel}
                width={1200}
                footer={null}
                key={Math.random(10)}
            >
                <ExportList type={4} />
            </Modal>
        </div >
    )
}
export default AvailableFunds
