import { urlAppendQuery, myInterceptor } from '../util/tools';

import fetch from 'isomorphic-fetch';
import { notification } from 'antd';
function getTestUrl(config, url) {
  const regx = /((?:http|https):\/\/[^/]+)\/((?:\w|-)+)/;
  for (const key in config) {
    const match = url.match(regx);
    let origin, pattern;
    if (match) {
      origin = match[1];
      pattern = match[2];
      const path = url.replace(origin, '');
      url = `/${pattern}` === key ? `${path}` : url;
    }
    return url;
  }
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  if (response.status === 401) {
    notification.error({
      message: '认证失败，请重新登录',
    });
    window.location = '/login';
  }
  if (response.status === 403) {
    notification.error({
      message: '用户没有权限',
    });
    let nt = setTimeout(() => {
      clearTimeout(nt);
      window.location = '/';
    }, 200);
  } else if (response.status === 402) {
    notification.error({
      message: '您的账号在别处登录，请修改密码',
    });

    let nT = setTimeout(() => {
      clearTimeout(nT);
      window.location = '/login';
    }, 3000);
  } else {
    // notification.error({
    //   message: `请求错误 ${response.status}: ${response.url}`,
    //   description: response.statusText,
    // });
    // const error = new Error(response.statusText);
    // error.response = response;
    // throw error;
  }
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */

function send(url, options, autoTips) {
  // myInterceptor();
  const defaultOptions = {
    // credentials: 'include',
    headers: {
      'X-Auth-Token': localStorage.token,
      'X-Auth-Button': options.authCode, // btn 编码
      token: localStorage.token,
    },
  };

  const newOptions = { ...defaultOptions, ...options };
  if (newOptions.method === 'POST' || newOptions.method === 'PUT') {
    newOptions.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      ...newOptions.headers,
    };
    const { body } = newOptions;
    if (!!body) {
      const { pageNo, pageSize } = body;
      if (pageNo && pageSize) {
        options.body.current = pageNo;
        options.body.size = pageSize;
      }
    }
    newOptions.body = JSON.stringify(newOptions.body);
  }
  const checkBiz = (response) => {
    return response.json().then((res) => {
      if (autoTips) {
        if (!res.success) {
          notification.error({
            message: res.msg,
          });
        } else if (res.msg) {
          notification.success({
            message: res.msg,
          });
        }
      }
      return res;
    });
  };
  let newUrl = url.indexOf('?') > -1 ? `${url}&rtime=${new Date().getTime()}` : `${url}?rtime=${new Date().getTime()}`;
  return fetch(url, newOptions)
    // .then(checkStatus)
    .then(checkBiz)
    .catch((error) => {
      if (error.code) {
        notification.error({
          message: error.name,
          description: error.message,
        });
      }
      if ('stack' in error && 'message' in error) {
        // notification.error({
        //   message: `请求错误: ${url}`,
        //   description: error.message,
        // });
      }
      return error;
    });
}
const request = {
  GET(url, options, autoTips) {
    if (typeof options !== 'object') {
      return send(url, { method: 'GET' }, options);
    }
    return send(url, { ...options, method: 'GET' }, autoTips);
  },
  POST(url, options, autoTips) {
    if (typeof options !== 'object') {
      return send(url, { method: 'POST' }, options);
    }
    return send(url, { ...options, method: 'POST' }, autoTips);
  },
  PUT(url, options, autoTips) {
    if (typeof options !== 'object') {
      return send(url, { method: 'PUT' }, options);
    }
    return send(url, { ...options, method: 'PUT' }, autoTips);
  },
  DELETE(url, options, autoTips) {
    if (typeof options !== 'object') {
      return send(url, { method: 'DELETE' }, options);
    }
    return send(url, { ...options, method: 'DELETE' }, autoTips);
  },
};
request.Fetch = function Fetch(options) {
  const { method = 'GET', url, headers, body, filename } = options;
  if (!url) {
    throw new Error('没有找到有效的url');
  }
  return fetch(
    url,
    // `${url}${url.indexOf('?') > -1 ? '&' : '?'}rtime=${new Date().getTime()}`,
    {
      mode: 'cors',
      method,
      headers: {
        'X-Auth-Token': localStorage.token,
        'X-Auth-Button': options.authCode,
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        ...headers,
      },
      body: body ? JSON.stringify(body) : undefined,
    }
  )
    .then((res) => {
      return res.blob()
    })
    .then((blob) => {
      const objectUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = objectUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
}

export default request;
