import React, { lazy, useEffect, useState } from 'react';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import "./App.less";
import { checkInRequest } from './api/api'
import { message } from 'antd';

import Home from './pages/Home'
import StoreValue from './pages/initialConfiguration/storeValue'
import InitialConfigurationList from './pages/initialConfiguration/list'
import InitialConfigurationAdd from './pages/initialConfiguration/add'
import InstitutionsInList from './pages/institutionsIn/list'
import InstitutionsInDetail from './pages/institutionsIn/detail'
import InstitutionsInAdd from './pages/institutionsIn/add'
import InstitutionsInAddDG from './pages/institutionsIn/addDG'
import InstitutionsInEdit from './pages/institutionsIn/edit'
import TradeSettingList from './pages/tradeSetting/list'
import TradeSettingDetail from './pages/tradeSetting/detail'
import TradeSettingAdd from './pages/tradeSetting/add'
import BillsList from './pages/bills/list'
import BillsAdd from './pages/bills/add'
import BillsDetail from './pages/bills/detail'
import BillsType from './pages/bills/type'
import AssetOverview from './pages/platformKanban/assetOverview'
import AvailableFunds from './pages/platformKanban/availableFunds'
import PurseMoney from './pages/platformKanban/purseMoney'
import Earnings from './pages/platformKanban/earnings'
import CreditFunds from './pages/platformKanban/creditFunds'
import Marketing from './pages/platformKanban/marketing'
import AvailableBalance from './pages/balance/availableBalance'
import WalletBalance from './pages/balance/walletBalance'
import Recharge from './pages/balance/recharge'
import TransferAccounts from './pages/balance/transferAccounts'
import Withdrawal from './pages/balance/withdrawal'
import AvailableAndAvailable from './pages/balance/availableAndAvailable'
import SettlementRecords from './pages/balance/settlementRecords'
import SettlementDetail from './pages/balance/settlementRecords/settlementDetail'
import Credit from './pages/CreditAccount/credit'
import HistoryBill from './pages/CreditAccount/historyBill'
import BillingDetails from './pages/CreditAccount/billingDetails'
import RevenueAccount from './pages/RevenueAccount'
import StatementDownload from './pages/StatementDownload'
import StatementDetails from './pages/StatementDownload/statementDetails'
import StatementList from './pages/StatementList'
import StatementListdetail from './pages/StatementList/detail'
import TransactionRecords from './pages/TransactionRecords'
import TransactionDetails from './pages/TransactionRecords/transactionDetails'
import Management from './pages/Account/management'
import AccountAdd from './pages/Account/add'
import AccountDetail from './pages/Account/detail'
import BankCard from './pages/BankCard'
import BankCardDetail from './pages/BankCard/detail'
import KeepAccountsList from './pages/KeepAccounts/list'
import Keep from './pages/KeepAccounts/keep'
import ManagementFee from './pages/ManagementFee'
import ManagementFeeAdd from './pages/ManagementFee/add'
import ManagementFeeDetail from './pages/ManagementFee/detail'
import ManagementFeeList from './pages/ManagementFeeList'
import ExportList from './pages/ExportList'
import Cost from './pages/Cost/list'
import Add from './pages/Cost/add'
import CostDetail from './pages/Cost/detail'
import CostEdit from './pages/Cost/edit'
import EmployeeManagement from './pages/EmployeeManagement'
import IndividualSettlement from './pages/IndividualSettlement'
import EnterpriseCard from './pages/enterpriseCard'
import Import from './pages/Import'
import ImportDetail from './pages/Import/detail'
import AgreeOn from './pages/agreeOn'
import StoreMarketing from './pages/StoreMarketing'
import ModeSettings from './pages/ModeSettings'
import AdvanceRepayment from './pages/AdvanceRepayment'
import GuidePage from './pages/GuidePage'
import EnterpriseSettlement from './pages/EnterpriseSettlement'
import WithdrawalSettings from './pages/WithdrawalSettings'
import WithdrawalSettingsDetail from './pages/WithdrawalSettings/detail'
import ReconciliactionList from './pages/Reconciliacion'

import Login from './pages/Login'
import { param2Obj } from './util/tools';


const App = () => {
  const { search } = useLocation()
  const { state } = useLocation()
  // url参数包含 global_token 需要优先获取token参数
  const { global_token } = param2Obj(search) || {}

  const [mySwitch, setMySwitch] = useState(state && state.tag == 1 ? true : localStorage.state == "1" ? true : false)
  const navigate = useNavigate();
  useEffect(() => {
    if (global_token) {
      checkIn()
      return
    }

    if (mySwitch) {
    } else {
      if (window.location.pathname != '/login') {
        checkIn()
      }

    }
  }, [])
  const checkIn = () => {
    (async _ => {
      return await checkInRequest(search.split('=')[1]);
    })().then(res => {
      if (res.code == 0) {
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('merchantCode', res.data.merchantCode);
        localStorage.setItem('merchantName', res.data.merchantName);
        localStorage.setItem('merchantId', res.data.merchantId);
        localStorage.setItem('username', res.data.username);
        localStorage.setItem('appid', res.data.superMerchantCode);
        localStorage.setItem('partnerid', res.data.partnerId);
        localStorage.setItem('operatorname', res.data.operatorName);
        localStorage.setItem('operatorId', res.data.operatorId); localStorage.setItem('salesEmployeeId', (res.data.salesEmployeeId));
        localStorage.setItem('hxState', (res.data.superMerchantCode === res.data.merchantCode));
        setMySwitch(true)
        // 判断是否是直接打开的页面
        const isDirectOpen = !!param2Obj(search)?.direct
        if(isDirectOpen) {
          // 1 代表自己项目登录 展示左侧菜单 和 顶部退出登录
          localStorage.setItem('state', 1)
        } else {
          localStorage.removeItem('state')
        }
      } else {
        message.error(res.msg)
        navigate('/login')
      }
    });
  }
  return (
    <Routes>
      <Route path='*' element={<Navigate to="/login" />} />
      <Route path='/login' element={<Login />} />
      <Route path='/agreeOn' element={<AgreeOn />} />
      {
        // state == 1 代表自己项目登录,加载home页菜单
        localStorage.state == 1 ?
          <>
            <Route path='/ws' element={<Home />}>
              {/* <Route path='*' element={<Navigate to="/ws/platformKanban/assetOverview" />} /> */}
              {/* <Route path='/login' element={<Navigate to="/login" />} /> */}
              <Route index element={<AssetOverview />} />
              <Route path='/ws/platformKanban/assetOverview' element={<AssetOverview />} />
              <Route path='/ws/platformKanban/availableFunds' element={<AvailableFunds />} />
              <Route path='/ws/platformKanban/purseMoney' element={<PurseMoney />} />
              <Route path='/ws/platformKanban/creditFunds' element={<CreditFunds />} />
              <Route path='/ws/platformKanban/earnings' element={<Earnings />} />
              <Route path='/ws/platformKanban/marketing' element={<Marketing />} />
              <Route path='/ws/initialConfiguration/list' element={<InitialConfigurationList />} />
              <Route path='/ws/initialConfiguration/storeValue' element={<StoreValue />} />
              <Route path='/ws/initialConfiguration/add' element={<InitialConfigurationAdd />} />
              <Route path='/ws/institutionsIn/list' element={<InstitutionsInList />} />
              <Route path='/ws/institutionsIn/detail' element={<InstitutionsInDetail />} />
              <Route path='/ws/institutionsIn/add' element={<InstitutionsInAdd />} />
              <Route path='/ws/institutionsIn/addDG' element={<InstitutionsInAddDG />} />
              <Route path='/ws/institutionsIn/edit' element={<InstitutionsInEdit />} />
              <Route path='/ws/tradeSetting/list' element={<TradeSettingList />} />
              <Route path='/ws/tradeSetting/detail' element={<TradeSettingDetail />} />
              <Route path='/ws/tradeSetting/add' element={<TradeSettingAdd />} />
              <Route path='/ws/bills/list' element={<BillsList />} />
              <Route path='/ws/bills/add' element={<BillsAdd />} />
              <Route path='/ws/bills/detail' element={<BillsDetail />} />
              <Route path='/ws/bills/type' element={<BillsType />} />
              <Route path='/ws/balance/availableBalance' element={<AvailableBalance />} />
              <Route path='/ws/balance/recharge' element={<Recharge />} />
              <Route path='/ws/balance/transferAccounts' element={<TransferAccounts />} />
              <Route path='/ws/balance/withdrawal' element={<Withdrawal />} />
              <Route path='/ws/balance/walletBalance' element={<WalletBalance />} />
              <Route path='/ws/balance/availableAndAvailable' element={<AvailableAndAvailable />} />
              <Route path='/ws/balance/settlementRecords' element={<SettlementRecords />} />
              <Route path='/ws/balance/settlementRecords/settlementDetail' element={<SettlementDetail />} />
              <Route path='/ws/creditAccount/credit' element={<Credit />} />
              <Route path='/ws/creditAccount/historyBill' element={<HistoryBill />} />
              <Route path='/ws/creditAccount/billingDetails' element={<BillingDetails />} />
              <Route path='/ws/revenueAccount' element={<RevenueAccount />} />
              <Route path='/ws/statementDownload' element={<StatementDownload />} />
              <Route path='/ws/statementDownload/statementDetails' element={<StatementDetails />} />
              <Route path='/ws/statementList' element={<StatementList />} />
              <Route path='/ws/statementList/statementListdetail' element={<StatementListdetail />} />
              <Route path='/ws/transactionRecords' element={<TransactionRecords />} />
              <Route path='/ws/transactionRecords/transactionDetails' element={<TransactionDetails />} />
              <Route path='/ws/account/management' element={<Management />} />
              <Route path='/ws/account/add' element={<AccountAdd />} />
              <Route path='/ws/account/detail' element={<AccountDetail />} />
              <Route path='/ws/bankCard' element={<BankCard />} />
              <Route path='/ws/bankCard/detail' element={<BankCardDetail />} />
              <Route path='/ws/keepAccountsList/list' element={<KeepAccountsList />} />
              <Route path='/ws/keepAccountsList/keep' element={<Keep />} />
              <Route path='/ws/managementFee' element={<ManagementFee />} />
              <Route path='/ws/managementFeeList' element={<ManagementFeeList />} />
              <Route path='/ws/managementFee/add' element={<ManagementFeeAdd />} />
              <Route path='/ws/managementFee/detail' element={<ManagementFeeDetail />} />
              <Route path='/ws/exportList' element={<ExportList />} />
              <Route path='/ws/cost/list' element={<Cost />} />
              <Route path='/ws/cost/add' element={<Add />} />
              <Route path='/ws/cost/detail' element={<CostDetail />} />
              <Route path='/ws/cost/edit' element={<CostEdit />} />
              
              <Route path='/ws/employeeManagement' element={<EmployeeManagement />} />
              <Route path='/ws/individualSettlement' element={<IndividualSettlement />} />
              <Route path='/ws/storeMarketing' element={<StoreMarketing />} />
              <Route path='/ws/enterpriseCard' element={<EnterpriseCard />} />
              <Route path='/ws/import' element={<Import />} />
              <Route path='/ws/import/detail' element={<ImportDetail />} />
              <Route path='/ws/agreeOn' element={<AgreeOn />} />
              <Route path='/ws/modeSettings' element={<ModeSettings />} />
              <Route path='/ws/advanceRepayment' element={<AdvanceRepayment />} />
              <Route path='/ws/guidePage' element={<GuidePage />} />
              <Route path='/ws/enterpriseSettlement' element={<EnterpriseSettlement />} />
              <Route path='/ws/withdrawalSettings' element={<WithdrawalSettings />} />
              <Route path='/ws/withdrawalSettings/detail' element={<WithdrawalSettingsDetail />} />
              <Route path='/ws/reconciliaction' element={<ReconciliactionList />} />


            </Route>
          </>

          :
          <>

            <Route path='*' element={<Navigate to="/ws/platformKanban/assetOverview" />} />
            <Route path='/ws/platformKanban/assetOverview' element={<AssetOverview />} />
            <Route path='/ws/platformKanban/availableFunds' element={<AvailableFunds />} />
            <Route path='/ws/platformKanban/purseMoney' element={<PurseMoney />} />
            <Route path='/ws/platformKanban/creditFunds' element={<CreditFunds />} />
            <Route path='/ws/platformKanban/earnings' element={<Earnings />} />
            <Route path='/ws/platformKanban/marketing' element={<Marketing />} />
            <Route path='/ws/initialConfiguration/list' element={<InitialConfigurationList />} />
            <Route path='/ws/initialConfiguration/storeValue' element={<StoreValue />} />
            <Route path='/ws/initialConfiguration/add' element={<InitialConfigurationAdd />} />
            <Route path='/ws/institutionsIn/list' element={<InstitutionsInList />} />
            <Route path='/ws/institutionsIn/detail' element={<InstitutionsInDetail />} />
            <Route path='/ws/institutionsIn/add' element={<InstitutionsInAdd />} />
            <Route path='/ws/institutionsIn/edit' element={<InstitutionsInEdit />} />
            <Route path='/ws/tradeSetting/list' element={<TradeSettingList />} />
            <Route path='/ws/tradeSetting/detail' element={<TradeSettingDetail />} />
            <Route path='/ws/tradeSetting/add' element={<TradeSettingAdd />} />
            <Route path='/ws/bills/list' element={<BillsList />} />
            <Route path='/ws/bills/add' element={<BillsAdd />} />
            <Route path='/ws/bills/detail' element={<BillsDetail />} />
            <Route path='/ws/bills/type' element={<BillsType />} />
            <Route path='/ws/balance/availableBalance' element={<AvailableBalance />} />
            <Route path='/ws/balance/recharge' element={<Recharge />} />
            <Route path='/ws/balance/transferAccounts' element={<TransferAccounts />} />
            <Route path='/ws/balance/withdrawal' element={<Withdrawal />} />
            <Route path='/ws/balance/walletBalance' element={<WalletBalance />} />
            <Route path='/ws/balance/availableAndAvailable' element={<AvailableAndAvailable />} />
            <Route path='/ws/balance/settlementRecords' element={<SettlementRecords />} />
            <Route path='/ws/balance/settlementRecords/settlementDetail' element={<SettlementDetail />} />
            <Route path='/ws/creditAccount/credit' element={<Credit />} />
            <Route path='/ws/creditAccount/historyBill' element={<HistoryBill />} />
            <Route path='/ws/creditAccount/billingDetails' element={<BillingDetails />} />
            <Route path='/ws/revenueAccount' element={<RevenueAccount />} />
            <Route path='/ws/statementDownload' element={<StatementDownload />} />
            <Route path='/ws/statementDownload/statementDetails' element={<StatementDetails />} />
            <Route path='/ws/statementList' element={<StatementList />} />
            <Route path='/ws/statementList/statementListdetail' element={<StatementListdetail />} />
            <Route path='/ws/transactionRecords' element={<TransactionRecords />} />
            <Route path='/ws/transactionRecords/transactionDetails' element={<TransactionDetails />} />
            <Route path='/ws/account/management' element={<Management />} />
            <Route path='/ws/account/add' element={<AccountAdd />} />
            <Route path='/ws/account/detail' element={<AccountDetail />} />
            <Route path='/ws/bankCard' element={<BankCard />} />
            <Route path='/ws/bankCard/detail' element={<BankCardDetail />} />
            <Route path='/ws/keepAccountsList/list' element={<KeepAccountsList />} />
            <Route path='/ws/keepAccountsList/keep' element={<Keep />} />
            <Route path='/ws/managementFee' element={<ManagementFee />} />
            <Route path='/ws/managementFeeList' element={<ManagementFeeList />} />
            <Route path='/ws/managementFee/add' element={<ManagementFeeAdd />} />
            <Route path='/ws/managementFee/detail' element={<ManagementFeeDetail />} />
            <Route path='/ws/exportList' element={<ExportList />} />
            <Route path='/ws/cost/list' element={<Cost />} />
            <Route path='/ws/cost/add' element={<Add />} />
            <Route path='/ws/cost/detail' element={<CostDetail />} />
            <Route path='/ws/cost/edit' element={<CostEdit />} />
            <Route path='/ws/employeeManagement' element={<EmployeeManagement />} />
            <Route path='/ws/individualSettlement' element={<IndividualSettlement />} />
            <Route path='/ws/storeMarketing' element={<StoreMarketing />} />
            <Route path='/ws/enterpriseCard' element={<EnterpriseCard />} />
            <Route path='/ws/import' element={<Import />} />
            <Route path='/ws/import/detail' element={<ImportDetail />} />
            <Route path='/ws/agreeOn' element={<AgreeOn />} />
            <Route path='/ws/modeSettings' element={<ModeSettings />} />
            <Route path='/ws/advanceRepayment' element={<AdvanceRepayment />} />
            <Route path='/ws/guidePage' element={<GuidePage />} />
            <Route path='/ws/enterpriseSettlement' element={<EnterpriseSettlement />} />
            <Route path='/ws/withdrawalSettings' element={<WithdrawalSettings />} />
            <Route path='/ws/withdrawalSettings/detail' element={<WithdrawalSettingsDetail />} />
            <Route path='/ws/reconciliaction' element={<ReconciliactionList />} />
          </>
      }


    </Routes>
  )

}

export default App;
