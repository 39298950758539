import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment'
import './index.less'
import {
  Form,
  Row,
  Col,
  Button,
  Table,
  DatePicker,
  Input,
  Select,
  message,
  Popconfirm,
  Tabs,
  Radio
} from 'antd';
import { configEditRequest, configDetailRequest } from '../../../api/api'


const Add = () => {
  const [list, setList] = useState([{ idx: 0 }])
  const [data, setData] = useState(null)
  const [value, setValue] = useState(0);
  const [form] = Form.useForm()
  let navigate = useNavigate();
  const { search, state } = useLocation()
  const [mySwitch, setMySwitch] = useState(false)
  useEffect(() => {
    console.log(state)
    getConfigDetail()

  }, [mySwitch])

  const cell = () => {
    navigate(-1)
  }
  const onFinish = (values) => {
    values.repairType = 1;
    values.id = state && state.stateId;
    configEdit(values)
  }
  const getConfigDetail = () => {

    (async _ => {
      return await configDetailRequest({
        merchantCode: localStorage.merchantCode,
        superMerchantCode: localStorage.appid,
      }
      );
    })().then(res => {
      if (res.code == 0) {
        res.data.wsbankConfigVO && res.data.wsbankConfigVO.map((v) => {
          v.idx = v.id
        })
        setList(res.data.wsbankConfigVO);
        setData(res.data);
        form.setFieldsValue({
          // bankType: res.data.bankType,
          wsIsvOrgId: res.data.wsIsvOrgId,
          wsAppid: res.data.wsAppid,
          wsReqUrl: res.data.wsReqUrl,
          wsPrivateKey: res.data.wsPrivateKey,
          wsPublicKey: res.data.wsPublicKey,
          wsPartnerId: res.data.wsPartnerId,
          sftpHost: res.data.sftpHost,
          sftpPort: res.data.sftpPort,
          sftpUserName: res.data.sftpUserName,
          sftpPassword: res.data.sftpPassword,
          sftpDirectory: res.data.sftpDirectory,
          wsReceiptUrl: res.data.wsReceiptUrl,
          wsRsaPublicKey: res.data.wsRsaPublicKey,
          zhongbangBankStatus: res.data.zhongbangBankStatus,
        })
      } else {
        message.error(res.msg)
      }
    });
  }
  const configEdit = (data) => {
    // list.map((v) => {
    //   v.repairType = 1;
    // });
    (async _ => {
      return await configEditRequest(
        {
          ...data,
          appid: localStorage.appid,
          superMerchantCode: localStorage.appid,
          operater: localStorage.operatorname,
          bankType: state && state.bankType,
          merchantCode: localStorage.merchantCode,
          zhongbangBankStatus: value,
          // wsbankConfigVO: list
        }
      );
    })().then(res => {
      if (res.code == 0) {
        message.success(res.msg)
        navigate('/ws/initialConfiguration/list', { replace: false, state: { bankType: state.bankType } })
      } else {
        message.error(res.msg)
      }
    });
  }



  const radioChange = (e) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };
  return (
    <div style={{ background: '#ffffff', paddingBottom: 20 }}>
      {/* <div className='borderBottom'>基本信息</div> */}
      <Row align='middle' style={{ padding: '10px 10px', marginTop: 10, borderBottom: '1px solid #EBEDF0' }}>
        <div style={{ background: '#145BD5', height: 14, width: 3 }}></div><div style={{ marginLeft: 10, fontWeight: 500 }}>基本信息</div>
      </Row>
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 6,
        }}
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{
          zhongbangBankStatus: 0
        }}
      >
        <Form.Item style={{ marginTop: 10 }}
          // initialValues={data && data.bankType}
          label="分账银行" name="bankType"
          rules={[
            {
              required: false,
              message: '请选择分账银行！',
            },
          ]}>
          <Radio.Group defaultValue={state && state.bankType}>
            {
              state && state.bankType == 0 ?
                <Radio value="0"> 网商银行 </Radio>
                : ''
            }
            {
              state && state.bankType == 1 ?
                <Radio value="1"> 汇付斗拱 </Radio>
                : ''
            }

          </Radio.Group>
        </Form.Item>
        <Form.Item style={{ marginTop: 10 }}
          // initialValues={data && data.bankType}
          label="银行结算" name="zhongbangBankStatus"
          rules={[
            {
              required: false,
              message: '请选择分账银行！',
            },
          ]}>
          <Radio.Group onChange={radioChange} value={value}>
            <Radio value={0}>关闭</Radio>
            <Radio value={1}>开启</Radio>
          </Radio.Group>
        </Form.Item>
        {
          state && state.bankType == 1 ?
            <Form.Item
              label="门店名称"
              name="wsIsvOrgId"
              rules={[
                {
                  required: state && state.bankType == 1 ? false : true,
                  message: '请输入合作方机构号！',
                },
              ]}
            >
              {localStorage.merchantName}
            </Form.Item>
            :
            <div>
              <Form.Item
                label="合作方机构号："
                name="wsIsvOrgId"
                rules={[
                  {
                    required: true,
                    message: '请输入合作方机构号！',
                  },
                ]}
              >
                <Input placeholder='请输入ws_isv_org_id' />
              </Form.Item>

              <Form.Item
                label="网商应用ID："
                name="wsAppid"
                rules={[
                  {
                    required: true,
                    message: '请输入网商应用ID！',
                  },
                ]}
              >
                <Input placeholder='请输入ws_appid' />
              </Form.Item>
              <Form.Item
                label="ISV主站侧合作ID："
                name="wsPartnerId"
                rules={[
                  {
                    required: true,
                    message: 'ISV主站侧合作ID！',
                  },
                ]}
              >
                <Input placeholder='请输入ws_partner_id' />
              </Form.Item>
              <Form.Item
                label="接口请求地址："
                name="wsReqUrl"
                rules={[
                  {
                    required: true,
                    message: '请输入接口请求地址',
                  },
                ]}
              >
                <Input placeholder='请输入ws_req_url' />
              </Form.Item>
              <Form.Item
                label="网商私钥："
                name="wsPrivateKey"
                rules={[
                  {
                    required: true,
                    message: '请输入ws_private_key',
                  },
                ]}
              >
                <Input placeholder='请输入ws_private_key' />
              </Form.Item>
              <Form.Item
                label="网商公钥："
                name="wsPublicKey"
                rules={[
                  {
                    required: true,
                    message: '请输入ws_public_key',
                  },
                ]}
              >
                <Input placeholder='请输入ws_public_key' />
              </Form.Item>
              <Form.Item
                label="sftp_host"
                name="sftpHost"
                rules={[
                  {
                    required: true,
                    message: '请输入端口！',
                  },
                ]}
              >
                <Input placeholder='请输入sftp_host' />
              </Form.Item>
              <Form.Item
                label="sftp端口"
                name="sftpPort"
                rules={[
                  {
                    required: true,
                    message: '请输入sftp端口！',
                  },
                ]}
              >
                <Input placeholder='请输入sftp_port' />
              </Form.Item>
              <Form.Item
                label="sftp用户名"
                name="sftpUserName"
                rules={[
                  {
                    required: true,
                    message: '请输入用户名！',
                  },
                ]}
              >
                <Input placeholder='请输入sftp_user_name' />
              </Form.Item>
              <Form.Item
                label="sftp密码"
                name="sftpPassword"
                rules={[
                  {
                    required: true,
                    message: '请输入sftp密码！',
                  },
                ]}
              >
                <Input placeholder='请输入sftp_password' />
              </Form.Item>
              <Form.Item
                label="sftpDirectory"
                name="sftpDirectory"
                rules={[
                  {
                    required: true,
                    message: '请输入sftp_directory！',
                  },
                ]}
              >
                <Input placeholder='请输入sftp_directory' />
              </Form.Item>

              <Form.Item
                label="receipt_url"
                name="wsReceiptUrl"
                rules={[
                  {
                    required: true,
                    message: '请输入receipt_url！',
                  },
                ]}
              >
                <Input placeholder='请输入ws_receipt_url' />
              </Form.Item>
              <Form.Item
                label="网商RSA"
                name="wsRsaPublicKey"
                rules={[
                  {
                    required: true,
                    message: '请输入网商RSA！',
                  },
                ]}
              >
                <Input placeholder='请输入ws_rsa_public_key' />
              </Form.Item>
            </div>

        }


        <Row align='middle' style={{ padding: '10px 10px', marginTop: 10, borderBottom: '1px solid #EBEDF0' }}>
          <div style={{ background: '#145BD5', height: 14, width: 3 }}></div><div style={{ marginLeft: 10, fontWeight: 500 }}>操作信息</div>
        </Row>
        <div className='content'>
          <div className='content-left'>
            <div>操作人：</div>
            <div>操作时间：</div>
          </div>
          <div className='content-right'>
            <div>{localStorage.operatorname}</div>
            <div>{data && data.createTime ? data.createTime : moment().format('YYYY-MM-DD HH:mm:ss')}</div>
          </div>
        </div>
        <Row>

          <Col span={24} style={{ textAlign: 'center' }}>
            {
              // state && !state.stateId ?
              <Button type="primary" htmlType='submit'>保存</Button>
              // : ''
            }

            <Button type="primary" ghost style={{ marginLeft: 20 }} onClick={cell}>取消</Button>

          </Col>
        </Row>
      </Form>
    </div >

  )
}
export default Add;
