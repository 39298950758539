import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import {
    Form,
    Image,
    Input,
    Button,
    message,
    Row,
    Col,
    Typography,
    Select,
    Table
} from 'antd';
import { orderSaveRequest, settleMerchantListRequest, getConstantsRequest, orderCheckBatchNoRequest } from '../../../../api/api'
import { useCallbackState } from '../../../../util/useCallbackState'
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
};
const { TextArea } = Input;
const { Option } = Select;
const { Title } = Typography;
const LnstitutionsInList = (props) => {
    const [form] = Form.useForm()
    const [merchantList, setMerchantList] = useState([])
    const [constants, setConstants] = useState(Array)
    const [payerMerchantName, setPayerMerchantName] = useState(String)
    const [payeeMerchantName, setPayeeMerchantName] = useState(String)
    const [transferName, setTransferName] = useState(String)
    const [total, setTotal] = useState()
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const data = props.data && props.data
    useEffect(() => {
        console.log(props)
        getConstants()
        settleMerchantList()
        form.setFieldsValue({
            payeeMerchantCode: props && props.data && props.data.payeeMerchantCode,
            transferPurpose: props && props.data && props.data.transferPurpose,
            amount: props && props.data && props.data.amount,
            remark: props && props.data && props.data.remark,
        })
    }, [])
    const columns1 = (_this) => {
        return [
            {
                title: '收款机构',
                dataIndex: 'payeeMerchantName',
                key: 'payeeMerchantName',
            },
            {
                title: '收款账户',
                dataIndex: 'payerAccountType',
                key: 'payerAccountType',
                render: (text, row) => {
                    return (
                        <div>
                            {data.payerAccountType == 1 ? '店铺余额' : ''}
                            {data.payerAccountType == 2 ? '钱包余额' : ''}
                        </div>
                    )

                }
            },
            {
                title: '转账金额',
                dataIndex: 'amount',
                key: 'amount',
            },
            {
                title: '转账用途',
                dataIndex: 'transferPurposeName',
                key: 'transferPurposeName',
            },
            {
                title: '转账附言',
                dataIndex: 'remark',
                key: 'remark',
            },
        ]
    }
    const bookRechargeProcess = (status) => {
        (async _ => {
            return await orderSaveRequest(props.data);
        })().then(res => {
            if (res.code == 0) {
                props.callback(true)
                props.closeDialog()
                message.success(res.msg)
            } else {
                message.error(res.msg)
            }
        });
    }
    const onChangPage = (currentPage, pageNum) => {
        setCurrentPage(currentPage, () => {
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        setPageNum((currentPage, pageNum), () => {
        })
    }
    const getConstants = () => {

        (async _ => {
            return await getConstantsRequest({
                constantType: "FD_TR_PURPOSE"
            });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setConstants(res.data.items)
            } else {
            }
        });
    }
    const cancel = (status) => {
        props.closeDialog()
    }
    const settleMerchantList = () => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode: localStorage.appid,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            if (res.code == 0) {
                setMerchantList(res.data)
            } else {

            }
        });
    }
    const onFinish = async () => {
        (async _ => {
            return await orderSaveRequest(props.data);
        })().then(res => {
            if (res.code == 0) {
                props.callback(true)
                props.closeDialog()
                message.success(res.msg)
            } else {
                message.error(res.msg)
            }
        });
    }
    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            <Form
                name="basic"
                {...layout}
                style={{ width: 600 }}
                form={form}
                onFinish={onFinish}
            >
                <Form.Item
                    name="payeeMerchantCode"
                    label="付款机构"
                    rules={[{ required: false, message: '请选择收入机构!' }]}
                >
                    {localStorage.merchantName}
                </Form.Item>
                <Form.Item label="付款账户">
                    {props && props.data && props.data.payerAccountType && props.data.payerAccountType == 1 ? '店铺余额' : '钱包余额'}
                </Form.Item>
                <Form.Item label="账户余额"
                    name="amount"
                    rules={[{ required: false, message: '请选择收入机构!' }]}
                >
                    <span style={{ fontWeight: 600 }}> ￥{props && props.data && props.data.yue && props.data.yue}</span>元
                </Form.Item>
                <Table

                    locale={{
                        emptyText: "没有更多数据了"
                    }}
                    style={{ marginTop: 10, width: '100%' }}

                    // loading={loading}
                    rowKey="id"
                    dataSource={props.data.orderVos}
                    columns={columns1(this)}
                    pagination={false}
                />

            </Form>
            <div style={{ textAlign: 'right', marginTop: 20 }}>
                <Button onClick={() => { cancel() }}>取消</Button>
                <Button style={{ marginLeft: 20 }} type="primary" htmlType='submit' onClick={onFinish}>确定</Button>
            </div>


        </div >
    )
}

export default LnstitutionsInList;