import React, { useState, useEffect, useReducer } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import {
    Form,
    Row,
    Col,
    Button,
    Input,
    Select,
    Table,
    Popconfirm,
    DatePicker,
    Modal,
    message,
    Tabs,
    Radio,
    Upload
} from 'antd';
import { configListRequest, configSelectListRequest } from '../../api/api'
import { useCallbackState } from '../../util/useCallbackState'
import Detail from './detail/index'
import CleanDetail from './cleanModal/index'
import host from '../../util/host';
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};
const { RangePicker } = DatePicker;
const StatementList = () => {
    const [form] = Form.useForm();
    const { state } = useLocation();
    const [list, setList] = useState(Array)
    const [merchantList, setMerchantList] = useState([])
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [total, setTotal] = useState()
    const [detailVisible1, setDetailVisible1] = useState(false)
    const [row, setRow] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        configSelectList()
    }, [])
    useEffect(() => {
        configList()
    }, [currentPage, pageNum])
    const columns = [
        {
            title: '所属机构',
            dataIndex: 'superMerchantName',
            key: 'superMerchantName',
            width: 150,
        },
        {
            title: '收单模式',
            dataIndex: 'cashType',
            key: 'cashType',
            width: 100,
            render: (text, row) => {
                return (
                    <div>
                        <span>{row.cashType == 0 ? '门店收单' : ''}</span>
                        <span>{row.cashType == 1 ? '总部统收' : ''}</span>
                    </div>
                )
            }
        },

        {
            title: '采购模式',
            dataIndex: 'purchaseType',
            key: 'purchaseType',
            width: 100,
            render: (text, row) => {
                return (
                    <div>
                        <span> {row.purchaseType == 0 ? '总部统收' : ''}</span>
                    </div>
                )
            }
        },
        {
            title: '营销模式',
            dataIndex: 'paycardType',
            key: 'paycardType',
            width: 100,
            render: (text, row) => {
                return (
                    <div>
                        <span> {row.paycardType == 0 ? '门店收单' : ''}</span>
                    </div>
                )
            }
        },
        {
            title: '提现手续费',
            dataIndex: 'feeRate',
            key: 'feeRate',
            width: 100,
            render: (text, row) => {
                return (
                    <div>
                        <span> {row.feeRate==null || row.feeRate==0 ? 0.00 : row.feeRate+'%'}</span>
                    </div>
                )
            }
        },
        {
            title: '起提金额',
            dataIndex: 'withdrawStartAmt',
            key: 'withdrawStartAmt',
            width: 100,
            render: (text, row) => {
                return (
                    <div>
                        <span> {row.withdrawStartAmt==null || row.withdrawStartAmt==0 ? 0.00 : row.withdrawStartAmt}</span>
                    </div>
                )
            }
        },

        {
            title: '入金手续费',
            dataIndex: 'incomeRate',
            key: 'incomeRate',
            width: 100,
            render: (text, row) => {
                return (
                    <div>
                        <span> {row.incomeRate ==0 ? 0.00 : row.incomeRate+'%'}</span>
                    </div>
                )
            }
        },


        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            render: (text, row) => {
                return (
                    <div>
                        <span> {row.status == 0 ? '开启' : ''}</span>
                        <span> {row.status == 1 ? '关闭' : ''}</span>
                    </div>
                )
            }
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 200,
            align: 'right',
            render: (text, row) => (
                <>

                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }} onClick={() => {
                        row.detail = true
                        row.edit = false
                        setRow(row)
                        setDetailVisible1(true)
                    }} >详情</span>
                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }} onClick={() => {
                        row.edit = true
                        row.detail = false
                        setRow(row)
                        setDetailVisible1(true)
                    }}>编辑</span>
                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }} onClick={()=>showModal(row)}>清理配置</span>
                </>

            ),

        },
    ];

    const configList = (data) => {
        let values = form.getFieldValue();
        (async _ => {
            return await configListRequest({
                ...values,
                currentPage: data && data.currentPage ? data.currentPage : currentPage,
                pageSize: pageNum,
            });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setList(res.data.orderList)
                setTotal(res.data.totalCount)
            } else {
                message.error(res.msg)
            }
        });
    }
    const configSelectList = (data) => {
        (async _ => {
            return await configSelectListRequest();
        })().then(res => {
            if (res.code == 0) {
                setMerchantList(res.data.fundSuperMerchantList)
            } else {
                message.error(res.msg)
            }
        });
    }

    const onChangPage = (currentPage, pageNum) => {
        setCurrentPage(currentPage, () => {
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        setPageNum((currentPage, pageNum), () => {
        })
    }
    const onFinish = (values) => {

        values.currentPage = 1;
        configList()
    };

    const detailHandleOk1 = () => {
        setDetailVisible1(false)
    }
    const detailhandleCancel1 = () => {
        setDetailVisible1(false)
    }
    const showModal = (props) => {
        console.log(props)
        props.edit = true
        props.detail = false
        setRow(props)
        setIsModalOpen(true);
    };
      const handleOk = () => {
        setIsModalOpen(false);
        console.log("handleOk");
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };
    return (
        <div style={{ background: '#ffffff', paddingTop: 1, marginTop: 16 }}>
            <Row style={{ marginTop: 16 }}>
                <Button type="primary" style={{ marginLeft: 20 }} onClick={() => {
                    setRow({
                        edit: false,
                        detail: false,
                    })
                    setDetailVisible1(true)
                }}>模式设置</Button>
            </Row>
            <Form
                form={form}
                {...layout}
                name="advanced_search"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                style={{ background: '#F7F8FA', margin: 16, padding: 16, paddingBottom: 0 }}
                initialValues={{
                }}
            >
                <Row>
                    <Col span={8}>
                        <Form.Item
                            name="superMerchantCode"
                            label="账户名称"
                        >
                            <Select placeholder="请选择"
                                style={{ width: 200 }}
                                onChange={(code) => {
                                }}
                                allowClear
                                optionFilterProp="children"
                                showSearch
                                filterOption={(input, option) => option.children.includes(input)}
                            >

                                {
                                    merchantList && merchantList.map((v) => {
                                        return <Option rowKey={v.superMerchantCode} key={v.superMerchantCode} value={v.superMerchantCode}>{v.superMerchantName}</Option>
                                    })
                                }

                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="status"
                            label="账户状态"
                        >

                            <Select placeholder="请选择"
                                style={{ width: 180 }}
                                allowClear
                                optionFilterProp="children"
                                showSearch
                                filterOption={(input, option) => option.children.includes(input)}
                            >
                                <Option key={0} value={0}>开启</Option>
                                <Option key={1} value={1}>关闭</Option>

                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{ marginBottom: '-1vh' }}>
                    <Col span={12}>
                        <Form.Item
                            wrapperCol={{
                                offset: 4,
                                span: 20,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                筛选
                            </Button>
                        
                            <Button type="link"
                                style={{ marginLeft: 10 }}
                                onClick={() => {
                                    form.resetFields();
                                    configList()
                                }} >
                                重置筛选条件
                            </Button>

                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Table
                locale={{
                    emptyText: "没有更多数据了"
                }}
                rowKey='id'
                style={{ marginTop: 16, margin: '0 16px' }}
                columns={columns}
                dataSource={list}
                pagination={{
                    total,
                    showSizeChanger: true,
                    current: currentPage,
                    pageSize: pageNum,
                    showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                    onChange: (k, v) => onChangPage(k, v),
                    onShowSizeChange: (k, v) => onChangPageNum(k, v),
                }}

            />

            <Modal
                title="模式设置"
                visible={detailVisible1}
                onOk={detailHandleOk1}
                onCancel={detailhandleCancel1}
                key={Math.random(10)}
                width={400}
                footer={null}
            >
                <Detail data={row}
                    callback={(state) => {
                        if (state) {
                            configList()
                        }
                    }}
                    closeDialog={() => {
                        setDetailVisible1(false)
                    }}
                />
            </Modal>
            
            <Modal
                title="清理配置"
                visible={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                key={Math.random(10)}
                width={400}
                footer={null}
            >
                <CleanDetail data={row}
                    callback={(state) => {
                        if (state) {
                            configList()
                        }
                    }}
                    closeDialog={() => {
                        handleCancel()
                    }}
                />
            </Modal>
        </div >
    )
}
export default StatementList;