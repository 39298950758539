import React, { useEffect, useState, useReducer } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import {
    Form,
    Image,
    Input,
    Button,
    message,
    Row,
    Col,
    Typography,
    Select,
    InputNumber,
    Radio,
    Space,
    DatePicker
} from 'antd';
import { orderSaveRequest, settleMerchantListRequest, expenseOrderBusinessRequest } from '../../../api/api'
import { limitTowDecimals } from '../../../util/tools';
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};
const dateFormat = 'YYYY-MM-DD'
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;
const { Title } = Typography;
let children = []
for (let i = 1; i <= 31; i++) {
    children.push(<Option key={i}>{`${i}号`}</Option>);
}
const Type = (props) => {
    const [form] = Form.useForm()
    const [value, setValue] = useState(0);
    const [intervalAmount, setIntervalAmount] = useState([])
    const [radio, setRadio] = useState(0);
    const [radio1, setRadio1] = useState(0);
    const [shMerchantList, setShMerchantList] = useState([])
    const [checkCode, setCheckCode] = useState(0)
    const [datetype, setDateType] = useState('1');
    const [typeList, setTypeList] = useState([])
    const [typeNames, setTypeNames] = useState([])
    const [expireStartTime, setExpireStartTime] = useState(null)
    const [expireEndTime, setExpireEndTime] = useState(null)
    const [payeeMerchantNames, setPayeeMerchantNames] = useState([])
    let [count, setCount] = useState(1);
    let data = props ? props.data : null;
    useEffect(() => {
        console.log(props)

    }, [])




    const cancel = (status) => {
        props.closeDialog()
    }

    const onFinish = async () => {
        console.log(intervalAmount);
        const values = await form.validateFields()  //2.表单验证并获取表单值
        console.log(values);
        props.callback(values)
        props.closeDialog()
    }

    return (
        <div style={{ background: '#ffffff', padding: 10 }}>
            <Form
                name="basic"
                {...layout}
                style={{ width: 450 }}
                form={form}
                onFinish={onFinish}
                initialValues={{
                }}
            >
                <Form.Item
                    name="expenseReceiptType"
                    label="收支类型"
                    rules={[{ required: true, message: '请选择转账用途!' }]}
                >
                    <Select style={{ width: 200 }}>
                        <Option key={0} value={0}>定额</Option>
                        <Option key={1} value={1}>比例</Option>
                        {/* <Option key={2} value={2}>区间比例</Option>
                        <Option key={3} value={3}>区间定额</Option> */}
                    </Select>
                </Form.Item>
            </Form >
            <div style={{ textAlign: 'center' }}>
                <Button onClick={() => { cancel() }}>取消</Button>
                <Button style={{ marginLeft: 20 }} type="primary" htmlType='submit' onClick={onFinish}>确定</Button>
            </div>


        </div >
    )
}

export default Type;