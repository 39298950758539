import React, { useState, useEffect, useReducer } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import {
    Form,
    Row,
    Col,
    Button,
    Input,
    Select,
    Table,
    Popconfirm,
    DatePicker,
    Modal,
    message
} from 'antd';
import { configSelectListRequest, dgReconciliationRequest } from '../../api/api'
import { useCallbackState } from '../../util/useCallbackState'
import { downFileWithBlob } from '../../util/tools'
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};
const { RangePicker } = DatePicker;
const ReconciliactionList = (data) => {
    const [form] = Form.useForm();
    const { state } = useLocation();
    const [list, setList] = useState([])
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [total, setTotal] = useState()
    const [startDate, setStartDate] = useState('')
    const [typeList, setTypeList] = useState([])
    const [endDate, setEndDate] = useState('')
    const [mySwitch, setMySwitch] = useState(state && state.tag == 1 ? true : localStorage.state == "1" ? true : false)
    const [configList, setConfigList] = useState([])
    useEffect(() => {
            getReconList()
            console.log(data);
            getConfigSelectList()

    }, [currentPage, pageNum])
    const columns = [
        {
            title: '商户名称',
            dataIndex: 'superMerchantName',
            key: 'superMerchantName',
        },
        {
            title: '门店名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
        },

        {
            title: '对账日期',
            dataIndex: 'statisticsDate',
            key: 'statisticsDate',
            width: 250,
        },
        {
            title: '对账状态',
            dataIndex: 'status',
            key: 'status',
            render: (tex,row) =>{
                return (
                    <div>
                        {row.status == 0 ? '未对账' : ''}
                        {row.status == 1 ? '对账成功' : ''}
                        <div style={{color:'rgb(223, 3, 3)'}}>{row.status == 2 ? '金额不一致' : ''}</div>
                        {row.status == 3 ? '条数不一致' : ''}
                    </div>
                )
            }  
        },
        {
            title: '三方订单汇总',
            dataIndex: 'thirdTotalCount',
            key: 'thirdTotalCount',
        },
        {
            title: '订单汇总',
            dataIndex: 'totalCount',
            key: 'totalCount',
        },
        {
            title: '三方金额汇总',
            dataIndex: 'thirdTotalAmout',
            key: 'thirdTotalAmout',
        },
        {
            title: '金额汇总',
            dataIndex: 'totalAmouut',
            key: 'totalAmouut',
        },
        {
            title: '三方手续费',
            dataIndex: 'thirdTotalFeeAmount',
            key: 'thirdTotalFeeAmount',
        },
        {
            title: '手续费',
            dataIndex: 'totalFeeAmout',
            key: 'totalFeeAmout',
        },
    ];



    const getReconList = (values) => {
        let params = {
            ...values,
            currentPage,
            pageSize: pageNum,
            operatorId: localStorage.operatorId,
            startTime: form.getFieldValue('date') && form.getFieldValue('date')[0].format('YYYY-MM-DD HH:mm:ss') ? form.getFieldValue('date')[0].format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss'),
            endTime: form.getFieldValue('date') && form.getFieldValue('date')[1].format('YYYY-MM-DD HH:mm:ss') ? form.getFieldValue('date')[1].format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss'),
          
        };
        (async _ => {
            return await dgReconciliationRequest(params);
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setList(res.data.items)
                setTotal(res.data.totalCount)
            } else {
                message.error(res.msg)
            }
        });
    }

    const getConfigSelectList = () => {
        (async _ => {
            return await configSelectListRequest();
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setConfigList(res.data.fundSuperMerchantList)
            } else {
                message.error(res.msg)
            }
        });
    }
    const onChangPage = (currentPage, pageNum) => {
        setCurrentPage(currentPage, () => {
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        setPageNum((currentPage, pageNum), () => {
        })
    }
    const onFinish = (values) => {
        getReconList(values)
    };
    const inSevenDays = () => {
        form.setFieldsValue({
            date: [moment(moment().subtract(7, "days").format("YYYY-MM-DD"), dateFormat), moment(moment().format('YYYY-MM-DD'), dateFormat)]
        })
    }
    const yesterday = () => {
        form.setFieldsValue({
            date: [moment(moment().subtract(1, "days").format("YYYY-MM-DD"), dateFormat), moment(moment().subtract(1, "days").format('YYYY-MM-DD'), dateFormat)]
        })
    }
    const today = () => {
        form.setFieldsValue({
            date: [moment(moment().format('YYYY-MM-DD'), dateFormat), moment(moment().format('YYYY-MM-DD'), dateFormat)]
        })


    }
    const timeChange = (date) => {
        setStartDate(date && date[0].format("YYYY-MM-DD"))
        setEndDate(date && date[1].format('YYYY-MM-DD'))
    }


    return (
        <div style={{ background: '#ffffff', paddingTop: 1, marginTop: 16 }}>
            <Form
                form={form}
                {...layout}
                name="advanced_search"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                style={{ background: '#F7F8FA', margin: 16, padding: 16, paddingBottom: 0 }}
                initialValues={{
                    date: state && state.startTime ? [moment(state.startTime, dateFormat), moment(state.startTime, dateFormat)] :
                    [moment(moment().subtract(1, "days").format("YYYY-MM-DD"), dateFormat), moment(moment().subtract(1, "days").format('YYYY-MM-DD'), dateFormat)],
                }}
            >
                <Row style={{ marginBottom: '-1vh' }}>
                    <Col span={6} >
                        <Form.Item
                            name="superMerchantCode"
                            label="商户列表"
                        >
                            <Select placeholder="请选择"style={{ width: 180 }}
                            >
                                {
                                    configList.length > 0 ? configList.map((v, index) => {
                                        return <Option rowKey={index} key={v.superMerchantCode} value={v.superMerchantCode}>{v.superMerchantName}</Option>
                                    })
                                        : null
                                }

                            </Select>

                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="status"
                            label="对账状态"
                        >

                            <Select placeholder="请选择"
                                style={{ width: 180 }}
                            >
                                <Option key={0} value={0}>未对账</Option>
                                <Option key={1} value={1}>对账成功</Option>
                                <Option key={2} value={2}>金额不一致</Option>
                                <Option key={3} value={3}>条数不一致</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>

                <Row style={{ marginBottom: '-1vh' }}>
                    <Col span={6}>
                        <Form.Item
                            name="date"
                            label="账单时间："
                        >
                            <div style={{ display: 'flex', alignItems: 'center', width: 400, }}>
                                <Form.Item
                                    name="date"
                                    noStyle
                                >
                                    <RangePicker onChange={timeChange} style={{ width: 250 }} />
                                </Form.Item>
                                <Form.Item
                                    // name="date"
                                    noStyle
                                >
                                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }}
                                        onClick={yesterday} >昨天</span>
                                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }}
                                        onClick={inSevenDays} >近7天</span>
                                </Form.Item>
                            </div>



                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '-1vh' }}>
                    <Col span={6}>
                        <Form.Item
                            wrapperCol={{
                                offset: 6,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                筛选
                            </Button>
                            <Button type="link" onClick={() => {
                                form.resetFields();
                            }} >
                                重置筛选条件
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                locale={{
                    emptyText: "没有更多数据了"
                }}
                rowKey='id'
                style={{ marginTop: 16, margin: '0 16px' }}
                columns={columns}
                dataSource={list}
                scroll={{
                    x: 1600,
                }}
                pagination={{
                    total,
                    showSizeChanger: true,
                    current: currentPage,
                    pageSize: pageNum,
                    showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                    onChange: (k, v) => onChangPage(k, v),
                    onShowSizeChange: (k, v) => onChangPageNum(k, v),
                }}
            />

        </div >
    )
}
export default ReconciliactionList;