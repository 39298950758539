import React, { useState, useEffect, useReducer } from 'react'
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import './style.less'
import {
    Form,
    Row,
    Col,
    Button,
    DatePicker,
    Input,
    Select,
    message,
    Radio,
    Modal,
} from 'antd';
import {
    expenseTypeEditRequest,
    expenseEditRequest,
    settleMerchantListRequest,
    expenseTypeListRequest,
    accountListRequest,
} from '../../../api/api'
const dateFormat = 'YYYY-MM-DD';
const { Option } = Select;
const { RangePicker } = DatePicker;
const layout2 = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
};
const steps = [
    {
        title: '1.编辑基本信息',
        content: 'First-content',
    },
    {
        title: '2.编辑认证信息',
        content: 'Second-content',
    },
];
let children = []
for (let i = 1; i <= 31; i++) {
    children.push(<Option key={i}>{`${i}号`}</Option>);
}
const BillsAdd = () => {
    const [form] = Form.useForm()
    const [typeForm] = Form.useForm()
    const { state } = useLocation()
    const [radio, setRadio] = useState(state && state.expensePayType ? state.expensePayType : 1);
    const [datetype, setDateType] = useState('1');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalForm] = Form.useForm();
    const [merchantList, setMerchantList] = useState([])
    const [typeList, setTypeList] = useState([])
    const [payerMerchantName, setPayerMerchantName] = useState(state && state.payerMerchantName)
    const [receiptMerchantName, setReceiptMerchantName] = useState(state && state.receiptMerchantName)
    const [typeName, setTypeName] = useState(state && state.expenseTypeName ? state.expenseTypeName : '全部')
    const [expenseTypeId, setExpenseTypeId] = useState(state && state.expenseTypeId)
    const [intervalAmount, setIntervalAmount] = useState([])
    const [merchantCode, setMerchantCode] = useState(localStorage.merchantCode)
    const [zcTypeList, setzcTypeList] = useState([])
    const [srTypeList, setsrTypeList] = useState([])
    const [expenseExpireType, setExpenseExpireType] = useState(state && state.expenseExpireType ? state.expenseExpireType : 0)
    let [count, setCount] = useState(1);
    const navigate = useNavigate()

    useEffect(() => {
        console.log(state && state.expenseExpireType);
        settleMerchantList();
        zcaccountList(state && state.payerMerchantCode ? state.payerMerchantCode : localStorage.merchantCode)
        sraccountList(state && state.receiptMerchantCode ? state.receiptMerchantCode : localStorage.merchantCode)
        getExpenseTypeList();
    }, [intervalAmount])
    const onFinish = (values) => {
        console.log(values);
        const rangeValue1 = values['date1'];
        if (values.date1) {
            values.expireStartTime = rangeValue1 && rangeValue1[0].format('YYYY-MM-DD hh:mm:ss');
            values.expireEndTime = rangeValue1 && rangeValue1[1].format('YYYY-MM-DD hh:mm:ss');
        }
        if (values.date2) {
            values.expireStartTime = values.date2 && values.date2.format('YYYY-MM-DD hh:mm:ss');
            values.expireEndTime = null
        }
        if (values.date3) {
            values.expireStartTime = values.date3 && values.date3.format('YYYY-MM-DD hh:mm:ss');
            values.expireEndTime = null
        }
        delete values.date1;
        delete values.date2;
        delete values.date3;
        (async _ => {
            return await expenseEditRequest({
                ...values,
                merchantCode: localStorage.merchantCode,
                superMerchantName: localStorage.merchantName,
                superMerchantCode: localStorage.appid,
                payerMerchantName,
                expenseTypeId: expenseTypeId,
                receiptMerchantName,
                expenseTypeName: typeName,
                expensePaySectionJson: JSON.stringify(intervalAmount),
                // expenseTypeId,
                operator: localStorage.operatorname ? localStorage.operatorname : undefined,
                id: state && state.id
            });
        })().then(res => {
            if (res.code == 0) {
                navigate(-1)
            } else {
                message.error(res.msg)
            }
        });
    }
    const zcaccountList = (code) => {
        (async _ => {
            return await accountListRequest({
                merchantCode: code
            });
        })().then(res => {
            if (res.code == 0) {
                let arr = []
                res.data.listVos.map((v) => {
                    if (v.accountType == 1) {
                        arr.push(v)
                    }
                })
                setzcTypeList(arr)
            } else {
                message.error(res.msg)
            }
        });
    }
    const sraccountList = (code) => {
        (async _ => {
            return await accountListRequest({
                merchantCode: code
            });
        })().then(res => {
            if (res.code == 0) {
                let arr = []
                res.data.listVos.map((v) => {
                    if (v.accountType == 1 || v.accountType == 5) {
                        arr.push(v)
                    }
                })
                setsrTypeList(arr)
            } else {
                message.error(res.msg)
            }
        });
    }
    const cell = () => {
        navigate(-1)
    }
    const dateType = (data) => {
        setDateType(data)
    };
    const radioChange = (e) => {
        setRadio(e.target.value)
    }

    const getExpenseTypeList = () => {

        (async _ => {
            return await expenseTypeListRequest({
                merchantCode,
                superMerchantCode: localStorage.appid
            });
        })().then(res => {
            if (res.code == 0) {
                console.log(res)
                setTypeList(res.data)
            } else {

            }
        });
    }
    const settleMerchantList = () => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode: localStorage.merchantCode,
                superMerchantCode: localStorage.appid
            });
        })().then(res => {
            if (res.code == 0) {
                setMerchantList(res.data)
            } else {

            }
        });
    }

    const expenseTypeEdit = (data) => {
        (async _ => {
            return await expenseTypeEditRequest({
                ...data,
                merchantCode: localStorage.merchantCode,
                superMerchantCode: localStorage.appid
            });
        })().then(res => {
            if (res.code == 0) {
                getExpenseTypeList()
            } else {

            }
        });
    }

    const onCreate = (values) => {
        expenseTypeEdit(values)
        setIsModalVisible(false);

    };
    const onCancel = () => {
        setIsModalVisible(false);
    };
    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    };

    const getFields = () => {
        const children = [];
        for (let i = 0; i < count; i++) {
            children.push(
                <Col key={i}>
                    <Form.Item
                        name={`field-${i}`}
                        label={i == 0 ? '单据金额' : ' '}
                        colon={i == 0 ? true : false}
                    >
                        <Input style={{ width: 100 }}
                            onChange={(e) => {
                                let value = e.target.value;
                                danju1(value, i)
                            }} />-<Input style={{ width: 100 }}
                                onChange={(e) => {
                                    let value = e.target.value;
                                    danju2(value, i)
                                }} />
                        <Input addonAfter='%' style={{ width: 100 }}
                            onChange={(e) => {
                                let value = e.target.value;
                                danju3(value, i)
                            }}
                            value={intervalAmount.length > 0 ? intervalAmount[i] && intervalAmount[i].number1 : undefined}
                        />
                        <Input prefix="￥" placeholder='固额' style={{ width: 100 }}
                            onChange={(e) => {
                                let value = e.target.value;
                                danju4(value, i)
                            }}
                            value={intervalAmount.length > 0 ? intervalAmount[i] && intervalAmount[i].number2 : undefined}
                        />
                        {i == 0 ? <Button type="link" onClick={add}>添加</Button> : ''}
                        {i > 0 ? <Button type="link" onClick={() => {
                            // console.log(children)
                            del(i)
                            // return children.splice(i, 1)
                            // console.log(children.splice(i, 1))
                        }}>删除</Button> : ''}
                    </Form.Item>
                </Col>,
            );
        }
        return children;
    };
    const forceUpdate = useReducer((bool) => !bool)[1]
    const danju1 = (value, i) => {
        let obj = {
            startPrice: value
        }
        intervalAmount[i] = { ...intervalAmount[i], ...obj }
        setIntervalAmount(intervalAmount)
    }
    const danju2 = (value, i) => {
        let obj = {
            endPrice: value
        }
        intervalAmount[i] = { ...intervalAmount[i], ...obj }
        setIntervalAmount(intervalAmount)
    }
    const danju3 = (value, i) => {
        let obj = {
            number1: value,
            type: 2,
        }
        intervalAmount[i] = { ...intervalAmount[i], ...obj }
        intervalAmount[i].number2 ? intervalAmount[i].number2 = '' : intervalAmount[i].number2 = undefined
        setIntervalAmount(intervalAmount)
        forceUpdate()
    }
    const danju4 = (value, i) => {
        let obj = {
            number2: value,
            type: 1,
        }
        intervalAmount[i] = { ...intervalAmount[i], ...obj }
        intervalAmount[i].number1 ? intervalAmount[i].number1 = '' : intervalAmount[i].number1 = undefined
        setIntervalAmount(intervalAmount)
        forceUpdate()
    }


    const add = () => {
        if (count == 3) return false
        setCount(count + 1)
    }
    const del = (i) => {
        if (count > 1) {
            setCount(count - 1)
            intervalAmount.splice(i, 1)
        }
    }
    const typeModal = () => {
        setIsModalVisible(true)
    }
    const typeCancel = () => {
        setIsModalVisible(false)
    }
    const refresh = () => {
        getExpenseTypeList()
    }

    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            <div>

                <Form
                    name="dynamic_form_item"
                    form={form}
                    {...layout2}
                    initialValues={{
                        payerMerchantCode: state && state.payerMerchantCode,
                        receiptMerchantCode: state && state.receiptMerchantCode,
                        expenseTypeId: state && state.expenseTypeId,
                        expenseName: state && state.expenseName,
                        expensePayType: state && state.expensePayType.toString() ? state.expensePayType.toString() : '1',
                        expensePayAmount: state && state.expensePayAmount,
                        expensePayProportion: state && state.expensePayProportion,
                        settleType: state && state.settleType.toString(),
                        settleDay: state && state.settleDay,
                        settleWeek: state && state.settleWeek,
                        settleMonth: state && state.settleMonth,
                        payAccount: state && state.payAccount,
                        receiptAccount: state && state.receiptAccount,
                        busiType: state && state.busiType,
                        date1: state && state.expenseExpireType.toString() == '0' ? [moment(state && state.expireStartTime, dateFormat), moment(state && state.expireEndTime, dateFormat)] : undefined,
                        date2: state ? moment(state && state.expireStartTime, dateFormat) : undefined,
                        date3: state ? moment(state && state.expireStartTime, dateFormat) : undefined,
                        expenseExpireType: state && state.expenseExpireType.toString() ? state.expenseExpireType.toString() : '0'
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <div style={{ width: '100%', marginLeft: 20, marginTop: 20, marginBottom: 20 }} className='borderBottom'>基本信息</div>
                    <Row>
                        {
                            localStorage.hxState == 'true' ?
                                <Col span={12}>
                                    <Form.Item label="支出机构：" name="payerMerchantCode">
                                        <Select placeholder="请选择"
                                            style={{ width: 180 }}
                                            allowClear
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.includes(input)}
                                            onChange={(code) => {
                                                form.setFieldsValue({
                                                    payAccount: undefined
                                                })
                                                merchantList.map((v) => {
                                                    if (v.merchantCode == code) {
                                                        setPayerMerchantName(v.merchantName)
                                                        // setMerchantCode(code)
                                                    }
                                                    zcaccountList(code)
                                                })

                                            }}
                                        >
                                            {
                                                merchantList && merchantList.map((v) => {
                                                    return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                                })
                                            }

                                        </Select>
                                    </Form.Item>
                                </Col>
                                :
                                <Col span={12}>
                                    <Form.Item label="支出机构：" >
                                        {localStorage.merchantName}
                                    </Form.Item>
                                </Col>
                        }

                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="收入机构：" name="receiptMerchantCode">
                                <Select placeholder="请选择"
                                    style={{ width: 180 }}
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.includes(input)}
                                    onChange={(code) => {
                                        form.setFieldsValue({
                                            receiptAccount: undefined
                                        })
                                        merchantList.map((v) => {
                                            if (v.merchantCode == code) {
                                                setReceiptMerchantName(v.merchantName)
                                            }
                                        })
                                        sraccountList(code)
                                    }}
                                >
                                    {
                                        merchantList && merchantList.map((v) => {
                                            return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row>
                        <Col span={12}>
                            <Form.Item label="单据类型：" name="busiType">
                                <Select placeholder="请选择"
                                    style={{ width: 180 }}
                                    onChange={(code) => {

                                        typeList.map((v) => {
                                            if (code == v.businessType) {
                                                setTypeName(v.expenseTypeName)
                                                setExpenseTypeId(v.id)
                                            }
                                        })
                                    }}
                                >
                                    {
                                        typeList && typeList.map((v) => {
                                            return <Option rowKey={v.businessType} key={v.businessType} value={v.businessType}>{v.expenseTypeName}</Option>
                                        })
                                    }
                                </Select>
                                {/* <Button type="link" onClick={showModal}>新建类型</Button>
                        <Button type="link">刷新</Button> */}
                            </Form.Item>
                        </Col>
                        {/* <Col span={12} pull={6}>
                            <Form.Item >
                                <Button type="link" onClick={typeModal}>新建类型</Button>
                                <Button type="link" onClick={refresh}>刷新</Button>
                            </Form.Item>
                        </Col> */}
                    </Row>

                    <Row>
                        <Col span={12}>
                            <Form.Item label="单据名称：" name="expenseName">
                                <Input placeholder='请输入费用单名称' style={{ width: 180 }} />
                            </Form.Item>
                        </Col>
                    </Row>



                    <div style={{ width: '100%', marginTop: 20, marginLeft: 20, marginBottom: 20 }} className='borderBottom'>单据信息</div>

                    <Row>
                        <Col span={12}>
                            <Form.Item label="收支类型：" name="expensePayType">
                                <Radio.Group onChange={radioChange} >
                                    <Radio value="1"> 定额 </Radio>
                                    <Radio value="2"> 比例 </Radio>
                                    <Radio value="3"> 区间 </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            {
                                radio == 1 ?
                                    <Form.Item label="单据金额：" name="expensePayAmount">
                                        <Input prefix="￥" style={{ width: 180 }} />
                                    </Form.Item>
                                    : ''
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            {
                                radio == 2 ?
                                    <Form.Item label="单据金额：" name="expensePayProportion">
                                        <Input addonAfter='%' style={{ width: 180 }} />
                                    </Form.Item>
                                    : ''
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            {
                                radio == 3 ?
                                    getFields()

                                    : ''
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="结算周期：" name="settleType" rules={[
                                {
                                    required: false,
                                    message: '请选择结算周期!',
                                },
                            ]}>
                                <Select placeholder="请选择"
                                    style={{ width: 180 }}
                                    onChange={dateType}
                                >
                                    <Option value="1">日</Option>
                                    <Option value="2">周</Option>
                                    <Option value="3">月</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={12}>
                            {
                                datetype == 1 ?
                                    <Form.Item
                                        colon={false}
                                        label={<span style={{ color: '#ffffff' }}>1</span>}
                                        name="settleDay"
                                        rules={[
                                            {
                                                required: false,
                                                message: '请选择!',
                                            },
                                        ]}>
                                        <Input style={{ width: 180 }} placeholder='请输入几日' />
                                    </Form.Item> : ''
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            {
                                datetype == 2 ?
                                    <Form.Item
                                        colon={false}
                                        label={<span style={{ color: '#ffffff' }}>1</span>}
                                        name="settleWeek"
                                        rules={[
                                            {
                                                required: false,
                                                message: '请选择!',
                                            },
                                        ]}>
                                        <Select
                                            style={{ width: 180 }}
                                            placeholder='请选择星期几'
                                        >
                                            <Option key='1'>星期一</Option>
                                            <Option key='2'>星期二</Option>
                                            <Option key='3'>星期三</Option>
                                            <Option key='4'>星期四</Option>
                                            <Option key='5'>星期五</Option>
                                            <Option key='6'>星期六</Option>
                                            <Option key='7'>星期日</Option>
                                        </Select>
                                    </Form.Item> : ''
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            {
                                datetype == 3 ?
                                    <Form.Item
                                        colon={false}
                                        label={<span style={{ color: '#ffffff' }}>1</span>}
                                        name="settleMonth"
                                        rules={[
                                            {
                                                required: false,
                                                message: '请选择!',
                                            },
                                        ]}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Form.Item
                                                name="settleMonth"
                                                noStyle
                                            >
                                                <Select
                                                    style={{ width: 180 }}
                                                    placeholder='请选择'
                                                >
                                                    {children}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item noStyle >
                                                <span style={{ color: '#969799', fontSize: 12, marginLeft: 12 }}>
                                                    如果选择31日,某月不满足将在当月最后一日结算
                                                </span>
                                            </Form.Item>
                                        </div>
                                    </Form.Item> : ''
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="支出账户：" name="payAccount" >
                                <Select placeholder="请选择"
                                    style={{ width: 180 }}
                                // onChange={dateType}
                                >
                                    {
                                        zcTypeList && zcTypeList.map((v) => {
                                            return <Option rowKey={v.accountNo} key={v.accountNo} value={v.accountNo}>{v.accountName}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="收入账户：" name="receiptAccount" >
                                <Select placeholder="请选择"
                                    style={{ width: 180 }}
                                // onChange={dateType}
                                >
                                    {
                                        srTypeList && srTypeList.map((v) => {
                                            return <Option rowKey={v.accountNo} key={v.accountNo} value={v.accountNo}>{v.accountName}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="有效期类型" name="expenseExpireType" >
                                <Select placeholder="请选择"
                                    style={{ width: 180 }}
                                    onChange={(code) => {
                                        setExpenseExpireType(code)
                                    }}
                                >

                                    <Option key='0'>固定日期</Option>
                                    <Option key='1'>长期有效</Option>
                                    <Option key='2'>一次性费用</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ marginBottom: '-3vh' }}>
                        {
                            expenseExpireType == 0 ?
                                <Col span={12}>
                                    <Form.Item label="单据有效期：" name="date1" >
                                        <RangePicker disabledDate={disabledDate} style={{ width: 240 }} />
                                        {/* <span style={{ color: '#969799', fontSize: 12 }}>不满足结算周期的数据，会在结束日后一日生成</span> */}

                                    </Form.Item>
                                </Col>
                                : ''
                        }

                        {
                            expenseExpireType == 1 ?
                                <Col span={12}>
                                    <Form.Item
                                        name="date2"
                                        label="单据有效期："
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Form.Item
                                                name="date2"
                                                noStyle
                                            >
                                                <DatePicker disabledDate={disabledDate} style={{ width: 180 }} showToday={false} />
                                            </Form.Item>
                                            <Form.Item
                                                noStyle
                                            >
                                                <div style={{ marginLeft: 20 }}>长期有效</div>
                                            </Form.Item>

                                        </div>

                                    </Form.Item>
                                </Col>
                                : ''
                        }
                        {
                            expenseExpireType == 2 ?
                                <Col span={12}>
                                    <Form.Item
                                        name="date3"
                                        label="单据有效期："
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Form.Item
                                                name="date3"
                                                noStyle
                                            >
                                                <DatePicker disabledDate={disabledDate} style={{ width: 180 }} showToday={false} />
                                            </Form.Item>
                                            <Form.Item
                                                noStyle
                                            >
                                                <div style={{ marginLeft: 20 }}>一次性费用</div>
                                            </Form.Item>

                                        </div>

                                    </Form.Item>
                                </Col>
                                : ''
                        }
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                label={<span style={{ color: '#ffffff' }}>1</span>}
                                colon={false}
                            >
                                <span style={{ color: '#969799', fontSize: 12 }}>不满足结算周期的数据，会在结束日后一日生成</span>
                            </Form.Item>
                        </Col>
                    </Row>




                    <div style={{ width: '100%', marginTop: 20, marginLeft: 20, marginBottom: 20 }} className='borderBottom'>创建信息</div>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="创建人：">
                                {state && state.operator ? state.operator : localStorage.operatorname ? localStorage.operatorname : undefined}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="创建时间：">
                                {state && state.createTime ? state.createTime : moment().format('YYYY-MM-DD HH:mm:ss')}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Button type="primary" htmlType='submit'>保存</Button>
                            <Button style={{ marginLeft: 20 }} onClick={cell}>取消</Button>

                        </Col>
                    </Row>
                </Form>
                <Modal
                    visible={isModalVisible}
                    title="新建费用单类型"
                    okText="确定"
                    cancelText="取消"
                    onCancel={typeCancel}
                    onOk={() => {
                        modalForm
                            .validateFields()
                            .then((values) => {
                                onCreate(values);
                                modalForm.resetFields();
                            })
                            .catch((info) => {
                            });
                    }}
                >
                    <Form
                        form={modalForm}
                        layout="vertical"
                        name="form_in_modal"
                        initialValues={{
                            modifier: 'public',
                        }}
                    >
                        <Form.Item
                            name="expenseTypeName"
                            label="类型名称"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入类型名称',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                    </Form>
                </Modal>
            </div>



        </div>
    )
}

export default BillsAdd;