import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import {
    Form,
    Image,
    Input,
    Button,
    message,
    Row,
    Col,
    Typography,
    Select,
    InputNumber
} from 'antd';

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
};

const LnstitutionsInList = (props) => {
    const [form] = Form.useForm()
    const data = props && props.data && props.data;
    useEffect(() => {
    }, [])
    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            <Form
                name="basic"
                {...layout}
                style={{ width: 600 }}
                form={form}
            >
                <Form.Item
                    name="payerMerchantCode"
                    label="订单编号："
                >
                    {data.orderNo}
                </Form.Item>
                <Form.Item
                    label="批次号："
                >
                    {data.batchNo}
                </Form.Item>
                <Form.Item
                    name="payeeMerchantCode"
                    label="付款机构："
                >
                    {data.payerMerchantName}
                </Form.Item>
                <Form.Item
                    name="payeeMerchantCode"
                    label="付款账户："
                >
                    {data.payeeAccountType == 1 ? '店铺余额' : '钱包余额'}
                </Form.Item>
                <Form.Item label="账户余额："
                    name="amount"
                >
                    <span style={{ fontWeight: 600 }}>￥{data.payerAccountBalance}</span>元

                </Form.Item>
                <Form.Item
                    name="transferPurpose"
                    label="收款机构："
                >
                    {data.payeeMerchantName}
                </Form.Item>
                <Form.Item
                    name="remark"
                    label="收款账户："
                >
                    {data.payerAccountType == 1 ? '店铺余额' : '钱包余额'}
                </Form.Item>
                <Form.Item
                    name="remark"
                    label="转账金额："
                >
                    <span style={{ fontWeight: 600 }}>￥{data.amount}</span>元

                </Form.Item>
                <Form.Item
                    name="remark"
                    label="转账用途："
                >
                    {data.transferPurposeName}
                </Form.Item>
                <Form.Item
                    name="remark"
                    label="转账附言："
                >
                    {data.remark}
                </Form.Item>
                <Form.Item
                    name="remark"
                    label="转账操作人："
                >
                    {data.operaterName}
                </Form.Item>
                <Form.Item
                    label="失败原因"
                >
                    {data.errorMsg}
                </Form.Item>
                <Form.Item
                    name="remark"
                    label="转账发起时间："
                >
                    {data.transferBeginDate}

                </Form.Item>
                <Form.Item
                    name="remark"
                    label="转账成功时间："
                >
                    {data.transferFinishDate}
                </Form.Item>
            </Form>
            <div style={{ textAlign: 'right' }}>
                <Button onClick={() => {
                    props.closeDialog()
                }}>取消</Button>
            </div>


        </div >
    )
}

export default LnstitutionsInList;