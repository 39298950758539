import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Tabs, Table, message, Modal } from 'antd';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { balanceViewRequest, listByAccountRequest } from '../../../api/api'
import Detail from '../../StatementList/detail/index'
const { TabPane } = Tabs;
const AvailableBalance = () => {
    let navigate = useNavigate();
    const { state } = useLocation()
    const [list, setList] = useState([])
    const [data, setData] = useState([])
    const [accountTypeList, setAccountTypeList] = useState([0, 1])
    const [row, setRow] = useState(null)
    const [accountType, setAccountType] = useState(null)
    const { search } = useLocation()
    const [detailVisible, setDetailVisible] = useState(false);
    const superMerchantCode = localStorage.appid
    useEffect(() => {
        console.log(state)
        getListByAccount();
        balanceView();
    }, [accountTypeList])
    const detailShowModal = (data) => {
        setRow(data)
        setDetailVisible(true);
    };

    const detailHandleOk = () => {
        setDetailVisible(false);
    };

    const detailhandleCancel = () => {
        setDetailVisible(false);
    };

    const onChange = (key) => {
        console.log(key)
        setAccountType(key)
        if (!key) {
            setAccountTypeList([0, 1])
        } else {
            setAccountTypeList([Number(key)])

        }
    }
    const getListByAccount = (data) => {
        (async _ => {
            return await listByAccountRequest({
                ...data,
                currentPage: 1,
                pageSize: 10,
                merchantCode: state  && state.merchantCode ? state.merchantCode : localStorage.merchantCode,
                superMerchantCode,
                accountTypeList: accountTypeList,
            });
        })().then(res => {
            if (res.code == 0) {
                setList(res.data.lists)
            } else {

            }
        });
    }
    const balanceView = () => {
        (async _ => {
            return await balanceViewRequest({
                ...data,
                currentPage: 1,
                pageSize: 10,
                merchantCode: state  && state.merchantCode ? state.merchantCode : localStorage.merchantCode,
                superMerchantCode,
                accountTypeList: accountTypeList,
            });
        })().then(res => {
            if (res.code == 0) {
                setData(res.data)
            } else {

            }
        });
    }
    const columns = [
        {
            title: '入账时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '类型',
            dataIndex: 'busiType',
            key: 'busiType',
        },


        {
            title: '业务单号｜支付流水号',
            dataIndex: 'address',
            key: '2',
            render: (text, row) => (
                <div>
                    <div >{row.orderNo}</div>
                    <div style={{ color: '#969799' }}>{row.thirdTradeNo}</div>
                </div>
            ),
        },
        {
            title: '账户',
            dataIndex: 'accountType',
            key: 'accountType',
            render: (text, row) => {
                return (
                    <div>
                        {row.accountType == 0 ? '店铺余额待结算' : ''}
                        {row.accountType == 1 ? '店铺余额' : ''}
                        {row.accountType == 2 ? '钱包余额' : ''}
                        {row.accountType == 3 ? '信用可提现账户' : ''}
                        {row.accountType == 4 ? '钱包余额待结算' : ''}
                        {row.accountType == 5 ? '收益账户' : ''}
                        {row.accountType == 6 ? '授信账户' : ''}
                        {row.accountType == 7 ? '营销余额待结算' : ''}
                        {row.accountType == 8 ? '营销余额' : ''}
                    </div>
                )
            }
        },
        {
            title: '收支类型',
            dataIndex: 'address',
            key: '3',
            render: (text, row) => {
                return (
                    <div>{row.type == 0 ? '收入' : '支出'}</div>
                )
            }
        },
        {
            title: '金额(元)',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: '余额(元)',
            dataIndex: 'afCaBalance',
            key: 'afCaBalance',
        },
        {
            title: '支付方式',
            dataIndex: 'payType',
            key: 'payType',
            render: (text, row) => {
                return (
                    <div>
                        {row.payType == 0 ? '微信' : ''}
                        {row.payType == 1 ? '支付宝' : ''}
                        {row.payType == 2 ? '会员' : ''}
                        {row.payType == 3 ? '银行卡' : ''}
                        {row.payType == 4 ? '现金' : ''}
                        {row.payType == 5 ? '云闪付' : ''}
                        {row.payType == 6 ? '漕河泾(饿了么)' : ''}
                        {row.payType == 7 ? '组合支付' : ''}
                        {row.payType == 17 ? '翼支付' : ''}

                    </div>
                )
            }
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 100,
            align: 'right',
            render: (text, row) => (
                <>
                    <span style={{ color: '#165BD4', cursor: 'pointer' }} onClick={() => {
                        detailShowModal(row)
                        // navigate("/ws/statementList/statementListdetail", { replace: false, state: row })
                    }}>详情</span>
                </>

            ),

        },
    ];
    const goWithdrawal = () => {
        navigate('/ws/balance/withdrawal', { replace: false, state: { defaultActiveKey: 1, data: state, tag: 1, } })
    }
    const goRechargeRecord = () => {
        navigate('/ws/balance/recharge', { replace: false, state: { defaultActiveKey: 2 } })
    }
    const goWithdrawalRecord = () => {
        navigate('/ws/balance/withdrawal', { replace: false, state: { defaultActiveKey: 2, data: state, tag: 1, } })
    }
    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            <Row align='middle' style={{ background: '#F7F8FA', padding: 10, margin: 10 }}>
                <div style={{ background: '#145BD5', height: 14, width: 3 }}></div><div style={{ marginLeft: 10, fontWeight: 500 }}>店铺余额</div>
            </Row>
            <Row justify='space-between' style={{ background: '#F7F8FA', padding: 20, margin: '20px 10px' }}>
                <Col span={12}>
                    <div>
                        <span>店铺余额(元)</span>
                        {/* <Button type='link' onClick={goRechargeRecord}>充值记录</Button> */}
                        <Button type='link' onClick={goWithdrawalRecord}>提现记录</Button>

                    </div>
                    <div style={{ marginTop: 10, display: 'flex', alignItems: 'center' }}>
                        <span style={{ fontSize: 24, fontWeight: 500, }}>{data && data.availableBalance}</span>
                        {/* <Button style={{ marginLeft: 20 }} type="primary" onClick={goRecharge}>充值</Button> */}
                        <Button style={{ marginLeft: 20 }} onClick={goWithdrawal}>提现</Button>
                    </div>
                </Col>
                <Col span={12}>
                    <Row>
                        <Col span={12}>
                            <div>
                                <span>待结算金额(元)</span>
                                <Button type='link'
                                    onClick={() => {
                                        console.log(state);
                                        navigate('/ws/balance/settlementRecords', { replace: false, state: { ...state, billType: '0', } })
                                    }}
                                >结算记录</Button>
                                {/* <Link style={{ marginLeft: 20 }} to=''>结算记录</Link> */}
                            </div>
                            <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{data && data.settleBalance}</div>
                        </Col>
                        <Col span={12}>
                            <div>
                                <span>不可用余额(元)</span>
                                <Button type='link'
                                    onClick={() => {
                                        navigate('/ws/balance/availableAndAvailable', { replace: false, state: { tag: 1, ...state }, })
                                    }}
                                >明细</Button>
                            </div>
                            <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{data && data.freezenBalance}</div>
                        </Col>
                    </Row>
                </Col>
                {/* <Col span={6}>1</Col>
                <Col span={6}>1</Col> */}
            </Row>
            <Row justify='space-between' align='middle' style={{ background: '#F7F8FA', padding: 10, margin: 10 }}>
                <Col>
                    <div style={{ background: '#145BD5', height: 14, width: 3 }}></div>
                </Col>
                <Col>
                    <div style={{ marginLeft: 10, fontWeight: 500, textAlign: 'right' }}>最近收支记录</div>
                </Col>
                <Col flex={1} style={{ textAlign: 'right' }}>
                    <Button type='link'
                        onClick={() => {
                            navigate('/ws/statementList', {
                                replace: false,
                                state: { tag: 1, merchantCode: state && state.merchantCode, accountType: accountType && accountType.toString() }
                            })
                        }}
                    >查看收支交易</Button>
                </Col>
            </Row>
            <Row style={{ padding: "0px 10px" }}>
                <Tabs onChange={onChange} type="card">
                    <TabPane tab="全部" key=''>
                    </TabPane>
                    <TabPane tab="店铺余额待结算" key='0'>
                    </TabPane>
                    <TabPane tab="可用账户" key='1'>
                    </TabPane>
                    {/* <TabPane tab="已退款" key="2">
                    </TabPane>
                    <TabPane tab="已完成" key="4">
                    </TabPane> */}

                </Tabs>
            </Row>
            <Row style={{ padding: "0px 10px" }}>
                <Col span={24}>
                    <Table
                        locale={{
                            emptyText: "没有更多数据了"
                        }}
                        rowKey='id'
                        columns={columns}
                        dataSource={list}
                        pagination={false}
                    />
                </Col>

            </Row>
            <Modal
                title="详情"
                visible={detailVisible}
                onOk={detailHandleOk}
                onCancel={detailhandleCancel}
                width={1200}
                footer={null}
            >
                <Detail data={row} />
            </Modal>
        </div>
    );
}

export default AvailableBalance;