import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './style.less'
import {
    Form,
    Row,
    Col,
    Button,
    Table,
    DatePicker,
    Input,
    Tabs,
    message,
} from 'antd';
import { wsbankMerchantDetailRequest } from '../../../api/api'
const { TabPane } = Tabs;
// import host from '../../../util/host';
const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 16 },
};


const LnstitutionsInList = () => {
    let navigate = useNavigate()
    const { state } = useLocation()
    const [data, setData] = useState(null)
    useEffect(() => {
        merchantDetail(state)
    }, [])
    const merchantDetail = (data) => {

        (async _ => {
            return await wsbankMerchantDetailRequest(
                {
                    merchantCode: data.merchantCode
                }
            );
        })().then(res => {
            if (res.code == 0) {
                setData(res.data)
            } else {
                message.error(res.msg)
            }
        });
    }
    return (
        <div>
            <Form
                {...layout}
            >
                <div style={{ background: '#ffffff', padding: 10, marginTop: 10, }}>
                    <div className='borderBottom' style={{ marginBottom: 30 }}>基本信息</div>
                    <Form.Item
                        label="入驻机构"
                    >
                        <div>{data && data.merchantName}</div>
                    </Form.Item>

                    <Form.Item
                        label="入驻机构"
                    >
                        <div>网商银行</div>
                    </Form.Item>

                    <div className='borderBottom' style={{ marginBottom: 30 }}>入驻信息</div>
                    <Form.Item
                        label="商户类型"
                    >
                        <div>
                            {data && data.merchantType == '01' ? '自然人' : ''}
                            {data && data.merchantType == '02' ? '个体工商户' : ''}
                            {data && data.merchantType == '03' ? '企业商户' : ''}
                        </div>
                    </Form.Item>
                    <Form.Item
                        label="商户简称（网商侧）"
                    >
                        <div>{data && data.alias ? data.alias : '未知'}</div>
                    </Form.Item>
                    <Form.Item
                        label="法定代表人/负责人姓名"
                    >
                        <div>{data && data.principalPerson ? data.principalPerson : '未知'}</div>
                    </Form.Item>
                    <Form.Item
                        label="联系人姓名"
                    >
                        <div>{data && data.contactName ? data.contactName : '未知'}</div>
                    </Form.Item>
                    <Form.Item
                        label="联系人手机"
                    >
                        <div>{data && data.contactMobile ? data.contactMobile : '未知'}</div>
                    </Form.Item>

                    <div className='borderBottom' style={{ marginBottom: 30 }}>认证信息</div>
                    {/* <Form.Item
                        label="申请单号"
                    >
                        <div>{data && data.applyOrderNo ? data.applyOrderNo : '未知'}</div>
                    </Form.Item> */}
                    <Form.Item
                        label="余额支付"
                    >
                        <div>
                            {data && data.balancePayStatus == 0 ? '未设置' : ''}
                            {data && data.balancePayStatus == 1 ? '已设置' : ''}
                            {data && data.balancePayStatus == 2 ? '失败' : ''}
                        </div>
                    </Form.Item>
                    <Form.Item
                        label="余额支付免短信"
                    >
                        <div>
                            {data && data.balanceNoSmsStatus == 0 ? '未设置' : ''}
                            {data && data.balanceNoSmsStatus == 1 ? '已设置' : ''}
                            {data && data.balanceNoSmsStatus == 2 ? '失败' : ''}
                        </div>
                    </Form.Item>
                    <Form.Item
                        label="开始补单时间"
                    >
                        <div>
                            {data && data.openRepairTime ? data.openRepairTime : '未知'}
                        </div>
                    </Form.Item>
                    <div className='borderBottom' style={{ marginBottom: 30 }}>创建信息</div>
                    <Form.Item
                        label="创建人"
                    >
                        <div>
                            未知
                        </div>
                    </Form.Item>
                    <Form.Item
                        label="创建时间"
                    >
                        <div>
                            {data && data.createTime}
                        </div>
                    </Form.Item>
                </div>
            </Form>
        </div>
    )
}
export default LnstitutionsInList;
