import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
    Form,
    Row,
    Col,
    Button,
    Input,
    Select,
    Table,
    Popconfirm,
    message,
    Modal
} from 'antd';
import { expenseOrderDeleteByCostRuleNoRequest, settleMerchantListWithOutRequest, expenseDeleteRequest, settleMerchantListRequest, expenseOrderListRequest } from '../../../api/api'
import { useCallbackState } from '../../../util/useCallbackState'
import Edit from '../edit/index'
import './style.less'
const { Option } = Select;
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
};
const LnstitutionsInList = () => {
    const [list, setList] = useState([])
    const [merchantList, setMerchantList] = useState([])
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [total, setTotal] = useState(0)
    const [init, setInit] = useState(true)
    const [mySwitch, setMySwitch] = useState(localStorage.state == "1" ? true : false)
    const [editVisible, setEditVisible] = useState(false)
    const [detailVisible, setDetailVisible] = useState(false);
    const [row, setRow] = useState(null)
    const [shMerchantList, setShMerchantList] = useState([])
    const navigate = useNavigate()
    const { search } = useLocation()
    const columns = [
        {
            title: '费用单号',
            dataIndex: 'costRuleNo',
            key: 'costRuleNo',
        },
        {
            title: '支出机构',
            dataIndex: 'payerMerchantNames',
            key: 'payerMerchantNames',
        },
        // {
        //     title: '收入组织',
        //     dataIndex: 'expenseName',
        //     key: 'expenseName',
        // },
        {
            title: '收入机构',
            dataIndex: 'receiptMerchantNames',
            key: 'receiptMerchantNames',
            width: 200,
            render: (text, row) => (
                <div className='ellipsis'>
                    {
                        // console.log(row.expireTypeAndTimes.split('/'))
                        row.receiptMerchantNames
                    }
                </div>
            ),
        },
        {
            title: '单据名称',
            dataIndex: 'costName',
            key: 'costName',

        },
        // {
        //     title: '分账类型',
        //     dataIndex: 'shareTypeNames',
        //     key: 'shareTypeNames',
        // },
        // {
        //     title: '单据类型',
        //     dataIndex: 'businessTypeNos',
        //     key: 'businessTypeNos',
        //     render: (text, row) => (
        //         <div>
        //             {
        //                 // console.log(row.expireTypeAndTimes.split('/'))
        //                 row.businessTypeNos && row.businessTypeNos.split(',').map((v) => {
        //                     return <div>{v}</div>
        //                 })
        //             }
        //         </div>
        //     ),
        // },
        // {
        //     title: '收支类型',
        //     dataIndex: 'expenseReceiptTypeNames',
        //     key: 'expenseReceiptTypeNames',
        // },
        // {
        //     title: '金额',
        //     dataIndex: 'expensePaySectionJsons',
        //     key: 'expensePaySectionJsons',
        //     width: 200,
        //     render: (text, row) => (
        //         <>
        //             {
        //                 row.expensePaySectionJsons.split(',').map((v) => {
        //                     return <div>{v}</div>
        //                 })
        //             }

        //         </>

        //     ),
        // },
        // {
        //     title: '结算方式',
        //     dataIndex: 'settletypeAndValues',
        //     key: 'settletypeAndValues',
        //     render: (text, row) => (
        //         <div>
        //             {
        //                 row.settletypeAndValues.split(',').map(v => {
        //                     return <div>{v}</div>
        //                 })
        //             }
        //         </div>
        //     ),
        // },
        // {
        //     title: '单据有效期',
        //     dataIndex: 'expireTypeAndTimes',
        //     key: 'expireTypeAndTimes',
        //     width: 250,
        //     render: (text, row) => (
        //         <div>
        //             {
        //                 // console.log(row.expireTypeAndTimes.split('/'))
        //                 row.expireTypeAndTimes.split('/').map((v) => {
        //                     return <div>{v}</div>
        //                 })
        //             }
        //         </div>
        //     ),
        // },
        {
            title: '状态',
            dataIndex: 'costStatus',
            key: 'costStatus',
            render: (text, row) => (
                <div>
                    {row.costStatus == 0 ? '未开启' : ''}
                    {row.costStatus == 1 ? '进行中' : ''}
                    {row.costStatus == 2 ? '已停用' : ''}
                    {row.costStatus == 3 ? '已停用' : ''}
                    {row.costStatus == 4 ? '已停用' : ''}

                </div>
            ),
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 160,
            align: 'right',
            render: (text, row) => (
                <div style={{ textAlign: 'rigth' }}>
                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }} onClick={() => detailShowModal(row)}>详情</span>

                    {
                        row.costStatus == 1 || row.costStatus == 0 ?
                            <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }} onClick={() => goEdit(row)}>编辑</span>
                            : ''
                    }
                    {/* <Link style={{ marginLeft: 10 }} to="/ws/bills/detail" > </Link> */}
                    {
                        row.costStatus == 0 ?
                            <Popconfirm
                                title="是否确认删除?"
                                onConfirm={() => {
                                    expenseDelete(row)
                                }}
                                onCancel={() => {

                                }}
                                okText="是"
                                cancelText="否"
                            >
                                <a style={{ marginLeft: 10 }} href="#">删除</a>
                            </Popconfirm>
                            : ''
                    }
                </div>





            ),

        },
    ];

    useEffect(() => {
        if (init) {
            settleMerchantList();

        }
        expenseOrderList()
    }, [currentPage, pageNum])
    const detailShowModal = (row) => {
        navigate('/ws/cost/detail', { replace: false, state: row })
    };

    const expenseOrderList = (data) => {
        let params = {
            ...data,
            currentPage,
            pageSize: pageNum,
            superMerchantCode: localStorage.appid,
        };
        (async _ => {
            return await expenseOrderListRequest(params);
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setTotal(res.data.totalCount)
                setList(res.data.costVOs)
            } else {

            }
        });
    }
    const [form] = Form.useForm();

    const goEdit = (row) => {
        console.log(row);
        navigate('/ws/cost/edit', { replace: false, state: row })
    }
    const editCancel = () => {
        setEditVisible(false)
    }

    const settleMerchantList = () => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode: localStorage.merchantCode,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            if (res.code == 0) {
                setInit(false)
                setMerchantList(res.data)
            } else {

            }
        });
    }

    const expenseDelete = (row) => {
        (async _ => {
            return await expenseOrderDeleteByCostRuleNoRequest({ costRuleNo: row.costRuleNo, superMerchantCode: localStorage.appid });
        })().then(res => {
            if (res.code == 0) {
                expenseOrderList();
                message.success(res.msg)
            } else {
                message.error(res.msg)
            }
        });
    }
    const onFinish = (values) => {
        values.currentPage = 1;
        setCurrentPage(1)
        expenseOrderList(values)
    };
    const add = () => {
        navigate('/ws/Cost/add')
    }

    const onChangPage = (currentPage, pageNum) => {
        setCurrentPage(currentPage, () => {
            // getExpenseListRequest()
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        setPageNum((currentPage, pageNum), () => {
            // getExpenseListRequest()
        })
    }
    return (
        <div style={{ background: '#ffffff', padding: 16, marginTop: 16 }}>
            <Row>
                {localStorage.appid == localStorage.merchantCode ?
                    <Button type='primary' onClick={add}>新建费用单</Button>
                    : ''
                }
            </Row>

            <Form
                {...layout}
                form={form}
                onFinish={onFinish}
                initialValues={{
                    expenseStatus: null,
                    merchantCode: localStorage.merchantCode
                }}
                style={{ marginTop: 16, background: '#F7F8FA', padding: 16, paddingBottom: 0, }}
            >
                <Row style={{ marginBottom: '-1vh' }}>
                    {
                        localStorage.hxState == 'true' ?
                            <Col span={8}>
                                <Form.Item
                                    name="payerMerchantCode"
                                    label="支出机构"
                                >
                                    <Select placeholder="请选择"
                                        style={{ width: 200 }}
                                        allowClear
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.includes(input)}
                                        onChange={(code) => {
                                        }}
                                    >
                                        {
                                            merchantList && merchantList.map((v) => {
                                                return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                            })
                                        }

                                    </Select>
                                </Form.Item>
                            </Col>
                            :
                            <Col span={8}>
                                <Form.Item
                                    label="机构名称"
                                >
                                    {localStorage.merchantName}
                                </Form.Item>
                            </Col>
                    }
                    <Col span={8}>
                        <Form.Item
                            name="receiptMerchantCode"
                            label="收入机构"
                            rules={[{ required: false, message: '请选择收入机构!' }]}
                        >
                            <Select placeholder="请选择"
                                style={{ width: 200 }}
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}

                            >

                                {
                                    merchantList && merchantList.map((v) => {
                                        return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                    })
                                }

                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item
                            name="costRuleNo"
                            label="费用单号："
                        // style={{ marginLeft: 30 }}
                        >
                            <Input style={{ width: 200 }} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="costStatus"
                            label="单据状态："

                        // style={{ marginLeft: 30 }}
                        >
                            <Select style={{ width: 200 }} allowClear>
                                <Option value="0">未开启</Option>
                                <Option value="1">进行中</Option>
                                <Option value="2">已停用</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>

                <Row style={{ marginBottom: '-1.5vh' }}>
                    <Col span={8}>
                        <Form.Item
                            wrapperCol={{
                                offset: 6,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                筛选
                            </Button>
                            {/* <Button
                                style={{ margin: '0 8px' }}

                            >
                                导出
                            </Button>
                            <Button type="link"  >
                                查看已导出列表
                            </Button> */}
                            <Button type="link" onClick={() => {
                                form.resetFields();
                                expenseOrderList()
                            }} >
                                重置筛选条件
                            </Button>
                        </Form.Item>

                    </Col>
                </Row>
                <Row>

                </Row>
            </Form>

            <Table
                locale={{
                    emptyText: "没有更多数据了"
                }}
                style={{ marginTop: 20 }}
                columns={columns}
                dataSource={list}
                rowKey='costRuleNo'
                // scroll={{
                //     x: 1300,
                // }}
                pagination={{
                    total,
                    showSizeChanger: true,
                    current: currentPage,
                    pageSize: pageNum,
                    // position: ["bottomCenter"],
                    showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                    onChange: (k, v) => onChangPage(k, v),
                    onShowSizeChange: (k, v) => onChangPageNum(k, v),
                }}
            />
            <Modal
                title="编辑费用单"
                visible={editVisible}
                onCancel={editCancel}
                width={400}
                footer={null}
                getContainer={false}  //1.排除警告
            >
                <Edit
                    data={row}
                    closeDialog={() => {
                        setEditVisible(false)
                    }}
                    callback={(state) => {
                        console.log(state)

                        // message.success('批量修改成功！')
                    }}
                />
            </Modal>

        </div >
    )
}
export default LnstitutionsInList;