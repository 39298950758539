import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import NP from 'number-precision'
import {
    Form,
    Input,
    Switch,
    Row,
    Col,
    Button,
    Tabs,
    Table,
    message,
    Modal,
    Radio,
    Select,
    Space,
    Skeleton
} from 'antd';
import { settingDetailRequest, matchMerchantListRequest, settingEditRequest, settleMerchantListRequest } from '../../../api/api'
const Option = Select.Option
const layout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 18 },
};
const BillingDetails = (props) => {
    const [form] = Form.useForm();
    const [merchantList, setMerchantList] = useState([])
    const [merchantSetting, setMerchantSetting] = useState(0)
    const [data, setData] = useState(null)
    useEffect(() => {
        console.log(props);
        settleMerchantList();
        if (!props.data.add) {
            if (props.data.edit || props.data.detail) {
                settingDetail()
            }
        }

    }, [])

    const settleMerchantList = () => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode: localStorage.merchantCode,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            if (res.code == 0) {
                setMerchantList(res.data)
            } else {
                message.error(res.msg)
            }
        });
    }

    const settingDetail = () => {
        (async _ => {
            return await settingDetailRequest({
                merchantCode: localStorage.merchantCode,
                superMerchantCode: localStorage.appid,
                id: props.data.id
            });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                let data = res.data;
                setData(res.data);
                form.setFieldsValue({
                    merchantCodeArray: data.merchantCodeArray ? data.merchantCodeArray : undefined,
                    limitMinAmount: data.limitMinAmount,
                    limitEveryday: data.limitEveryday,
                    limiMaxAmount: data.limiMaxAmount,
                    walletWithdrawType: data.walletWithdrawType,
                    dgWithdrawType: data.dgWithdrawType,
                    dgCheckType: data.dgCheckType,
                    walletCheckType: data.walletCheckType,
                    merchantSetting: data.merchantSetting,
                })
                setMerchantSetting(data.merchantSetting)
            } else {
                message.error(res.msg)
            }
        });
    }

    const onFinish = (values) => {
        console.log(values);
        let params = {
            ...values,
            superMerchantCode: localStorage.appid,
            id: props.data.id,
        };
        (async _ => {
            return await settingEditRequest(params);
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                props.callback(true)
                props.closeDialog()
            } else {
                message.error(res.msg)
            }
        });
    };
    return (
        <div style={{
            background: '#ffffff', paddingBottom: 20,
            // display: 'flex', justifyContent: 'center' 
        }}>

            <Form form={form} name="basic" layout="horizontal" onFinish={onFinish}
                {...layout}
                autoComplete="off"
                initialValues={{ aa: 0, }}
            >
                <Form.Item
                    label="所属机构"
                    rules={[{ required: false, message: '请选择!' }]}
                >

                    <div style={{ display: 'flex' }}>
                        <Form.Item
                            name="merchantSetting"
                            rules={[{ required: true, message: '请选择!' }]}
                        >
                            <Select
                                placeholder="请选择"
                                style={{ width: 150 }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                                onChange={(e) => {
                                    console.log(e);
                                    setMerchantSetting(e)
                                }}
                                disabled={!props.data.edit}
                            >
                                <Option key={0} value={0}>全选</Option>
                                <Option key={1} value={1}>部分门店</Option>
                            </Select>
                        </Form.Item>
                        {
                            merchantSetting == 1 ?
                                <Form.Item
                                    name="merchantCodeArray"
                                    label=""
                                    style={{ marginLeft: 16 }}
                                >
                                    <Select placeholder="请选择"
                                        style={{ width: 150 }}
                                        onChange={(code) => {
                                        }}
                                        mode="multiple"
                                        allowClear
                                        optionFilterProp="children"
                                        showSearch
                                        filterOption={(input, option) => option.children.includes(input)}
                                    >

                                        {
                                            merchantList && merchantList.map((v) => {
                                                return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                            })
                                        }

                                    </Select>
                                </Form.Item> : null
                        }

                    </div>

                </Form.Item>
                <Form.Item
                    style={{ marginTop: '-20px' }}
                    label="提现限额"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <div style={{ display: 'flex' }}>
                        <Form.Item
                            name="aa"
                            rules={[{ required: true, message: '请选择!' }]}
                        >
                            单笔最小
                        </Form.Item>
                        <Form.Item
                            name="limitMinAmount"
                            rules={[{ required: false, message: '请选择!' }]}
                            style={{ marginLeft: 16 }}
                        >
                            <Input style={{ width: 150 }} disabled={!props.data.edit} />
                        </Form.Item>
                    </div>

                </Form.Item>
                <Form.Item
                    style={{ marginTop: '-20px' }}
                    label=" "
                    colon={false}
                    rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <div style={{ display: 'flex' }}>
                        <Form.Item
                            name="aa"
                            rules={[{ required: true, message: '请选择!' }]}
                        >
                            单笔最大
                           
                        </Form.Item>
                        <Form.Item
                            name="limiMaxAmount"
                            rules={[{ required: false, message: '请选择!' }]}
                            style={{ marginLeft: 16 }}
                        >
                            <Input style={{ width: 150 }} disabled={!props.data.edit} />
                        </Form.Item>
                    </div>

                </Form.Item>
                <Form.Item
                    style={{ marginTop: '-20px' }}
                    label=" "
                    colon={false}
                    rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <div style={{ display: 'flex' }}>
                        <Form.Item
                            name="aa"
                            rules={[{ required: true, message: '请选择!' }]}
                        >
                            当日额度
                           
                        </Form.Item>
                        <Form.Item
                            name="limitEveryday"
                            rules={[{ required: false, message: '请选择!' }]}
                            style={{ marginLeft: 16 }}
                        >
                            <Input style={{ width: 150 }} disabled={!props.data.edit} />
                        </Form.Item>
                    </div>

                </Form.Item>
                <Form.Item
                    style={{ marginTop: '-20px' }}
                    label="提现到账"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <div style={{ display: 'flex' }}>
                        <Form.Item
                            name="aa"
                            rules={[{ required: true, message: '请选择!' }]}
                        >
                            斗拱余额
                           
                        </Form.Item>
                        <Form.Item
                            name="dgWithdrawType"
                            rules={[{ required: true, message: '请选择!' }]}
                            style={{ marginLeft: 16 }}
                        >
                            <Select
                                placeholder="请选择"
                                style={{ width: 150 }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                                disabled={!props.data.edit}
                            >
                                {/* <Option key={0} value={0}>D0(有额外手续费)</Option> */}
                                <Option key={1} value={1}>D1(无手续费)</Option>
                            </Select>
                        </Form.Item>
                    </div>

                </Form.Item>
                <Form.Item
                    style={{ marginTop: '-20px' }}
                    label=" "
                    colon={false}
                    rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <div style={{ display: 'flex' }}>
                        <Form.Item
                            name="aa"
                            rules={[{ required: true, message: '请选择!' }]}
                        >
                            钱包余额
                          
                        </Form.Item>
                        <Form.Item
                            name="walletWithdrawType"
                            rules={[{ required: true, message: '请选择!' }]}
                            style={{ marginLeft: 16 }}
                        >
                            <Select
                                placeholder="请选择"
                                style={{ width: 150 }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                                disabled={!props.data.edit}
                            >
                                {/* <Option key={0} value={0}>D0(有额外手续费)</Option> */}
                                <Option key={1} value={1}>D1(无手续费)</Option>
                            </Select>
                        </Form.Item>
                    </div>

                </Form.Item>
                <Form.Item
                    style={{ marginTop: '-20px' }}
                    label="提现审核"
                    rules={[{ required: false, message: 'Please input your username!' }]}
                >
                    <div style={{ display: 'flex' }}>
                        <Form.Item
                            name="aa"
                            rules={[{ required: true, message: '请选择!' }]}
                        >
                            斗拱余额
                          
                        </Form.Item>
                        <Form.Item
                            name="dgCheckType"
                            rules={[{ required: true, message: '请选择!' }]}
                            style={{ marginLeft: 16 }}
                        >
                            <Select
                                placeholder="请选择"
                                style={{ width: 150 }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                                disabled={!props.data.edit}
                            >
                                <Option key={0} value={0}>手动审核「默认」</Option>
                                <Option key={1} value={1}>自动审核</Option>
                            </Select>
                        </Form.Item>
                    </div>

                </Form.Item>
                <Form.Item
                    style={{ marginTop: '-20px' }}
                    label=" "
                    colon={false}
                    rules={[{ required: false, message: 'Please input your username!' }]}
                >
                    <div style={{ display: 'flex' }}>
                        <Form.Item
                            name="aa"
                            rules={[{ required: true, message: '请选择!' }]}
                        >
                            钱包余额
                           
                        </Form.Item>
                        <Form.Item
                            name="walletCheckType"
                            rules={[{ required: true, message: '请选择!' }]}
                            style={{ marginLeft: 16 }}
                        >
                            <Select
                                placeholder="请选择"
                                style={{ width: 150 }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                                disabled={!props.data.edit}
                            >
                                <Option key={0} value={0}>手动审核「默认」</Option>
                                <Option key={1} value={1}>自动审核</Option>
                            </Select>
                        </Form.Item>
                    </div>

                </Form.Item>
                <Row>
                    <Col span={24} style={{ textAlign: 'right' }}>
                        {
                            props.data.edit ?
                                <Button
                                    type="primary"
                                    htmlType='submit'
                                >
                                    确认
                                </Button> : null
                        }


                        <Button
                            style={{ marginLeft: 20 }}
                            onClick={() => {
                                props.closeDialog();
                            }}
                        >
                            取消
                        </Button>
                    </Col>
                </Row>

            </Form>
        </div>

    )
}
export default BillingDetails;
