import React, { useState, useEffect } from 'react';
import {
    Form,
    Input,
    Switch,
    Row,
    Col,
    Button,
    Tabs,
    Table,
    message,
    Modal,
    Radio,
    Select,
    Space,
    Skeleton
} from 'antd';
import { merchantListRequest,dgCleanConfigRequest} from '../../../api/api'
import Icon, { WarningOutlined } from '@ant-design/icons';

const Option = Select.Option

const CleanDetail = (props )=>{
    const [form] = Form.useForm();
    const [superShow, setSuperShow] = useState(false);
    const onChange = e => {
        console.log('radio checked', e.target.value);
        if(e.target.value == 1){
            setSuperShow(false)
        }else{
            setSuperShow(true);
            configSelectList();
        }
    };
    const [merchantList, setMerchantList] = useState([])
    const [superMerchantName, setSuperMerchantName] = useState(null)
    const [superMerchantCode, setSuperMerchantCode] = useState('')

    //初始化
    useEffect(() => {
        console.log("=="+props.data.superMerchantCode);
        if (props.data && props.data.edit || props.data && props.data.detail) {
            form.setFieldsValue({
                superMerchantCode: props.data.superMerchantCode,
            })
            setSuperMerchantName(props.data.superMerchantName);
            setSuperMerchantCode(props.data.superMerchantCode);
        }
    }, [])

    const configSelectList = (props)=>{
        (async _ =>{
            return await merchantListRequest({
                superMerchantCode:superMerchantCode,
                currentPage: 1,
                pageSize: 100
            });
        })().then(res => {
            console.log(res.code)
                if (res.code == 0) {
                    setMerchantList(res.data.merchantListVos)
                } else {
                    message.error(res.msg)
                }
        });
    }
    const onFinish = (values) => {
        console.log('Success:', values);
        (async _ =>{
            return await dgCleanConfigRequest({
                "cleanType": values.cleanType,
                "merchantCode": values.merchantCode,
                "superMerchantCode": superMerchantCode
            });
        })().then( res => {
            console.log(res.code)
            if (res.code == 0) {
                props.callback(true);
                props.closeDialog();
                message.success(res.msg);
            } else {
                message.error(res.msg)
            }
        });
    }
    return(
        <div>
            
            <Row style={{
                marginTop: 5,
            }}>
                <p style={{color: '#FF0000'}}> <WarningOutlined style={{marginRight: '5px'}}/>清理配置后账户不能使用，请谨慎处理</p>
           <Form
              onFinish={onFinish}
              form={form}
              initialValues={{
                cleanType:1,
            }}
            >
            <Form.Item
                        name="superMerchantCode"
                        label="所属机构"
                        colon={false}
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                    <div>
                        {superMerchantName}
                    </div>
                    </Form.Item>
            <Form.Item  label="账户类型" name="cleanType"
                    rules={[{ required: true, message: 'Please input your username!' }]}
             >
                <Radio.Group onChange={onChange} >
                    <Radio value={1}>全部门店</Radio>
                    <Radio value={2}>单门店</Radio>
                </Radio.Group>
            </Form.Item>

            {
            superShow ?    
            <Form.Item
                name="merchantCode"
                label="所属机构"
                colon={false}
                rules={[{ required: true, message: '请选择所属门店' }]}
            >
                <Select placeholder="请选择"
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    style={{ width: 250 }}
                    onChange={(code) => {
                    }}
                    allowClear
                    optionFilterProp="children"
                    showSearch
                    filterOption={(input, option) => option.children.includes(input)}
                    disabled={props.data && props.data.detail}
                >
                    {
                        merchantList && merchantList.map((v) => {
                            return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                        })
                    }

                </Select>
            </Form.Item> 
            : 
            null
            }
              <Form.Item
                    wrapperCol={{
                    offset: 8,
                    span: 16,
                    }}
                >
                <Button type="primary" htmlType="submit">
                 确定
                </Button>
                <Button htmlType="button" style={{ marginLeft: 10 }} onClick={()=>{
                    props.closeDialog();
                    }}>
                    取消
                </Button>
            </Form.Item>
           
            </Form>
            </Row>
        </div>
       
    )
}
export default CleanDetail;
 