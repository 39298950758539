import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import './style.less'
import {
    Form,
    Image,
    Input,
    Button,
    message,

} from 'antd';
import { bookRechargeProcessRequest } from '../../../../api/api'
const { TextArea } = Input;
const LnstitutionsInList = (props) => {
    const [auditRemark, setAuditRemark] = useState(null)
    useEffect(() => {
        console.log(props)
    }, [])
    const bookRechargeProcess = (status) => {
        (async _ => {
            return await bookRechargeProcessRequest({
                status,
                auditRemark,
                auditOperaterId: localStorage.operatorId,
                auditOperaterName: localStorage.operatorname,
                merchantCode: localStorage.merchantCode,
                superMerchantCode: localStorage.appid,
                id: props && props.data.id,
            });
        })().then(res => {
            console.log(res)
            if (res.code == 0) {
                props.callback(true)
                props.closeDialog()
                message.success(res.msg)
            } else {
                message.error(res.msg)
            }
        });
    }
    const pass = (status) => {
        bookRechargeProcess(status)
    }
    const rejected = (status) => {
        bookRechargeProcess(status)
    }
    
    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            <div>
                <Form
                    name="basic"
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    autoComplete="off"
                >
                    <div style={{ width: '100%', marginLeft: 20, marginTop: 20, marginBottom: 20 }} className='borderBottom'>充值信息</div>
                    <Form.Item label="充值门店" >
                        {props && props.data.merchantName}
                    </Form.Item>
                    <Form.Item label="充值账户">
                        {props && props.data.accountType == 2 ? '钱包余额' : ''}
                    </Form.Item>
                    <Form.Item label="订单编号">
                        {props && props.data.orderNo}
                    </Form.Item>
                    <Form.Item label="申请时间">
                        {props && props.data.createTime}
                    </Form.Item>
                    <Form.Item label="金额">
                        {props && props.data.amount}
                    </Form.Item>


                    <Form.Item label="操作人">
                        {props && props.data.rechargeOperateName}
                    </Form.Item>

                    <Form.Item label="汇款凭证" >
                        <Image
                            width={100}
                            src={props && props.data.voucher}
                        />
                    </Form.Item>

                    <div style={{ width: '100%', marginTop: 20, marginLeft: 20, marginBottom: 20 }} className='borderBottom'>汇款账户</div>
                    <Form.Item label="开户姓名">
                        {props && props.data.bankType == 1 ? props.data.companyName : props.data.name}
                    </Form.Item>
                    <Form.Item label="汇款银行">
                        {props && props.data.bankName}
                    </Form.Item>
                    <Form.Item label="支行名称">
                        {props && props.data.bankBranchName}
                    </Form.Item>
                    <Form.Item label="银行卡号">
                        {props && props.data.bankCardNo}
                    </Form.Item>
                    <Form.Item label="备注">
                        <TextArea rows={4} onChange={(e) => {
                            setAuditRemark(e.target.value)
                        }} />
                    </Form.Item>
                </Form>
            </div>
            <div style={{ textAlign: 'right' }}>
                <Button onClick={() => { rejected(2) }}>驳回</Button>
                <Button type="primary" style={{ marginLeft: 20 }} onClick={() => { pass(1) }}>通过</Button>
            </div>


        </div>
    )
}

export default LnstitutionsInList;