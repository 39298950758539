import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, Row, Col, Table, Popconfirm, message, Modal } from 'antd';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { settleConfigListRequest, allMerchantListRequest, settleConfigListExcelRequest } from '../../../api/api'
import { useCallbackState } from '../../../util/useCallbackState'
import ExportList from '../../../components/exportList'
const { Option } = Select;
const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
};
const TradeSetting = () => {
    const [form] = Form.useForm();
    const { state } = useLocation()
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)
    const [total, setTotal] = useState(null)
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [merchantdataList, setMerchantdataList] = useState([])
    const [merchantCode, setMerchantCode] = useState(null)
    const [init, setInit] = useState(true)
    const [exportVisible, setExportVisible] = useState(false);
    const navigate = useNavigate()
    const { search } = useLocation()
    const columns = [
        {
            title: '机构名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
        },
        {
            title: '机构编码',
            dataIndex: 'merchantCode',
            key: 'merchantCode',
        },
        {
            title: '采购授信额度(元)',
            dataIndex: 'creditAmount',
            key: 'creditAmount',
        },

        {
            title: '操作',
            key: 'operation',
            align: 'right',
            width: 200,
            render: (text, row) => (
                <>
                    <span style={{ color: '#165BD4', cursor: 'pointer' }} onClick={() => {
                        navigate('/ws/tradeSetting/add', { replace: false, state: row })
                    }}>交易设置</span>
                    {/* <Link style={{ marginLeft: 10 }} to="/tradeSettingAdd">交易设置</Link> */}
                    <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }} onClick={() => {
                        navigate('/ws/tradeSetting/detail', { replace: false, state: row })
                    }}>详情</span>
                </>

            ),

        },
    ];
    useEffect(() => {
        getSettleConfigList()
        allMerchantList()
    }, [currentPage, pageNum])

    const onFinish = (values) => {
        setLoading(true)
        values.currentPage = 1
        getSettleConfigList(values)
    };

    const onChangPage = (currentPage, pageNum) => {
        setCurrentPage(currentPage, () => {
            // getSettleConfigList()
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        setPageNum((currentPage, pageNum), () => {
            // getSettleConfigList()
        })
    }

    const allMerchantList = () => {

        (async _ => {
            return await allMerchantListRequest(
                {
                    merchantCode: localStorage.merchantCode,
                    superMerchantCode: localStorage.appid,
                }
            );
        })().then(res => {
            if (res.code == 0) {
                setMerchantdataList(res.data)
                setInit(false)
            } else {
                message.error(res.msg)
            }
        });
    }
    const getSettleConfigList = (data) => {
        let params = {
            ...data,
            currentPage: data && data.currentPage ? data.currentPage : currentPage,
            pageSize: pageNum,
            codeName: merchantCode,
            superMerchantCode: localStorage.appid,
        };

        (async _ => {
            return await settleConfigListRequest(params);
        })().then(res => {
            if (res.code == 0) {
                setList(res.data.assetsVos)
                setTotal(res.data.totalCount)
                setLoading(false)
            } else {
                message.error(res.msg)
                setLoading(false)
            }
        });
    }
    const settleConfigListExcel = () => {
        let params = {
            operatorId: localStorage.operatorId,
            operatorName: localStorage.operatorname,
            superMerchantCode: localStorage.appid,
            codeName: merchantCode,
        };

        (async _ => {
            return await settleConfigListExcelRequest(params);
        })().then(res => {
            if (res.code == 0) {
                message.success(res.msg)
            } else {
                message.error(res.msg)
            }
        });
    }
    const exportHandleOk = () => {
        setExportVisible(false);
    };
    const exporthandleCancel = () => {
        setExportVisible(false);
    };
    return (
        <div style={{ background: '#ffffff', padding: 16, marginTop: 10 }}>
            {
                localStorage.hxState == 'true' ?
                    <Form
                        form={form}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish}
                        {...layout}
                        style={{ background: '#F7F8FA', padding: 16, paddingBottom: 0 }}
                    >

                        <Row style={{ marginBottom: '-1vh' }}>
                            <Col span={8}>
                                <Form.Item
                                    name="merchantCode"
                                    label="机构名称"
                                >
                                    <Select style={{ width: 200 }}
                                        onChange={(code) => {
                                            setMerchantCode(code)
                                        }}
                                        allowClear
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.includes(input)}

                                    >
                                        {
                                            merchantdataList && merchantdataList.map((v) => {
                                                return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{ marginBottom: '-1vh' }}>
                            <Col span={10}>
                                <Form.Item
                                    wrapperCol={{
                                        offset: 4,
                                        span: 16,
                                    }}
                                >
                                    <Button type="primary" htmlType="submit">
                                        筛选
                                    </Button>
                                    <Button
                                        style={{ margin: '0 8px' }}
                                        onClick={() => {
                                            settleConfigListExcel()
                                        }}
                                    >
                                        导出
                                    </Button>
                                    <Button type="link"
                                        onClick={() => {
                                            setExportVisible(true);
                                        }}
                                    >
                                        查看已导出列表
                                    </Button>
                                    <Button type="link" onClick={() => {
                                        form.resetFields();
                                    }} >
                                        重置筛选条件
                                    </Button>
                                </Form.Item>

                            </Col>
                        </Row>
                    </Form>
                    : null
            }
            <Table
                loading={loading}
                style={{ marginTop: 16 }}
                columns={columns}
                dataSource={list}
                rowKey="merchantId"
                locale={{
                    emptyText: "没有更多数据了"
                }}
                pagination={{
                    total,
                    showSizeChanger: true,
                    current: currentPage,
                    pageSize: pageNum,
                    // position: ["bottomCenter"],
                    showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                    onChange: (k, v) => onChangPage(k, v),
                    onShowSizeChange: (k, v) => onChangPageNum(k, v),
                }}
            />
            <Modal
                title="导出列表"
                visible={exportVisible}
                onOk={exportHandleOk}
                onCancel={exporthandleCancel}
                width={1200}
                footer={null}
                key={Math.random(10)}
            >
                <ExportList type={5} />
            </Modal>
        </div>


    )
}

export default TradeSetting;