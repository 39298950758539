import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useNavigate, useLocation } from 'react-router-dom';

import './style.less'
import {
    Form,
    Row,
    Col,
    Button,
    Table,
    DatePicker,
    Input,
    Select,
    message,
    Popconfirm,
    Tabs,
    Radio,
    Steps,
    Calendar,
} from 'antd';
import { allMerchantListRequest, merchantSelectRequest, merchantRegisterRequest, updateHuifuDgRequest } from '../../../api/api'
import { useCallbackState } from '../../../util/useCallbackState'
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const { Step } = Steps;
const { Option } = Select;
const layout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 16 },
};

const LnstitutionsInList = () => {
    const [form] = Form.useForm()
    const [datetype, setDateType] = useState('0');
    const [data, setData] = useCallbackState(null)
    const [merchantList, setMerchantList] = useState([])
    const [merchantDGList, setMerchantDGList] = useState([])
    const { state } = useLocation();

    const [dgMerchantId, setDgMerchantId] = useState(state && state.dgMerchantId)
    const [configureId, setConfigureId] = useState(state && state.configureId)
    const [configureName, setConfigureName] = useState(state && state.configureName)
    const [date, setDate] = useState(state && state.openRepairTime)

    const navigate = useNavigate()
    useEffect(() => {
        console.log(state)
        allMerchantList()
        form.setFieldsValue({
            merchantCode: state && state.merchantCode,
            configureId: state && state.configureId,
            payTransactionId: state && state.payTransactionId,
            payChannel: state && state.payChannel,
            douyinMerchantId: state && state.douyinMerchantId
        })
    }, [])
    useEffect(() => {
        console.log(state && state)
        if (state && state.ss == 2 || state.ss == 1) {
            merchantSelect(state && state.merchantCode)
        }
    }, [])
    const allMerchantList = () => {

        (async _ => {
            return await allMerchantListRequest(
                {
                    merchantCode: localStorage.merchantCode,
                    superMerchantCode: localStorage.appid,
                }
            );
        })().then(res => {
            if (res.code == 0) {
                setMerchantList(res.data)
            } else {
                message.error(res.msg)
            }
        });
    }

    const cell = () => {
        navigate(-1)
    }
    const merchantChange = (code) => {
        merchantSelect(code)
        form.setFieldsValue({
            dgMerchantId: undefined
        })
        setConfigureId(null)
        setConfigureName(null)
    }
    const merchantSelect = (code) => {
        (async _ => {
            return await merchantSelectRequest(
                {
                    merchantCode: code,
                    superMerchantCode: localStorage.appid,
                }
            );
        })().then(res => {
            if (res.code == 0) {
                console.log(res)
                setMerchantDGList(res.data)
            } else {
                message.error(res.msg)
            }
        });
    }
    const onFinish = (values) => {

        if (state && state.id) {
            edit(values)
        } else {
            save(values)
        }
    }
    const save = (values) => {
        (async _ => {
            return await merchantRegisterRequest(
                {
                    ...values,
                    superMerchantCode: localStorage.appid,
                    dgMerchantId,
                    configureId,
                    configureName,
                    openRepairTime: date,
                }
            );
        })().then(res => {
            if (res.code == 0) {
                navigate(-1)
            } else {
                message.error(res.msg)
            }
        });
    }
    const edit = (values) => {
        (async _ => {
            return await updateHuifuDgRequest(
                {
                    ...values,
                    superMerchantCode: localStorage.appid,
                    dgMerchantId,
                    configureId,
                    configureName,
                    openRepairTime: date,
                    id: state && state.id
                }
            );
        })().then(res => {
            if (res.code == 0) {
                navigate(-1)
            } else {
                message.error(res.msg)
            }
        });
    }
    const dateChange = (date) => {
        setDate(date.format('YYYY-MM-DD hh:mm:ss'))
    }
    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    };
    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            <div>
                <Form
                    form={form}
                    name="basic"
                    {...layout}
                    onFinish={onFinish}
                >
                    <Row align='middle' style={{ background: '#F7F8FA', padding: 10, margin: 10 }}>
                        <div style={{ background: '#145BD5', height: 14, width: 3 }}></div><div style={{ marginLeft: 10, fontWeight: 500 }}>基本信息</div>
                    </Row>
                    <Row style={{ marginTop: 30 }}>
                        <Col span={10}>
                            <Form.Item
                                label="入驻机构："
                                name="merchantCode"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please input your password!',
                                    },
                                ]}
                            >
                                <Select style={{ width: 200 }}
                                    disabled={state && state.ss == 1 || state && state.ss == 2}
                                    onChange={merchantChange}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.includes(input)}

                                >
                                    {
                                        merchantList && merchantList.map((v) => {
                                            return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row align='middle' style={{ background: '#F7F8FA', padding: 10, margin: 10 }}>
                        <div style={{ background: '#145BD5', height: 14, width: 3 }}></div><div style={{ marginLeft: 10, fontWeight: 500 }}>入驻信息</div>
                    </Row>
                    <Row style={{ marginTop: 30 }}>
                        <Col span={10}>
                            <Form.Item
                                label="斗拱商编"
                                name="configureId"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please input your password!',
                                    },
                                ]}
                            >
                                <Select style={{ width: 350 }}
                                    disabled={state && state.ss == 1}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.includes(input)}
                                    onChange={(code) => {
                                        merchantDGList && merchantDGList.map((v) => {
                                            if (v.configure_id == code) {
                                                setConfigureId(v.configure_id)
                                                setConfigureName(v.configure_name)
                                                setDgMerchantId(v.channel_merchant_no)
                                            }
                                        })
                                    }}
                                >
                                    {
                                        merchantDGList && merchantDGList.map((v) => {
                                            return <Option rowKey={v.configure_id} key={v.configure_id} value={v.configure_id}>{v.configure_name}/{v.channel_merchant_no}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* <Row style={{}}>
                        <Col span={10}>
                            <Form.Item
                                label="支付配置编号"
                                name="payTransactionId"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please input your password!',
                                    },
                                ]}
                            >
                                <Input placeholder='pay_transaction_id' style={{ width: 350 }} />
                            </Form.Item>
                        </Col>
                    </Row> */}
                    {/* <Row style={{}}>
                        <Col span={10}>
                            <Form.Item
                                label="支付通道"
                                name="payChannel"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please input your password!',
                                    },
                                ]}
                            >
                                <Input placeholder='pay_channel' style={{ width: 350 }} />
                            </Form.Item>
                        </Col>
                    </Row> */}
                   
                    <Row style={{}}>
                        <Col span={10}>
                            <Form.Item
                                label="注册状态："
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please input your password!',
                                    },
                                ]}
                            >
                                成功
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{}}>
                        <Col span={10}>
                            <Form.Item
                                label="账户余额："
                                name="gongyao"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please input your password!',
                                    },
                                ]}
                            >
                                初始余额
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row align='middle' style={{ background: '#F7F8FA', padding: 10, margin: 10 }}>
                        <div style={{ background: '#145BD5', height: 14, width: 3 }}></div><div style={{ marginLeft: 10, fontWeight: 500 }}>认证信息</div>
                    </Row>

                    <Row style={{ marginTop: 30 }}>
                        <Col span={10}>
                            <Form.Item
                                label="余额支付："
                                name="gongyao"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please input your password!',
                                    },
                                ]}
                            >
                                已开通
                            </Form.Item>
                        </Col>
                    </Row>
                    {
                        state && state.ss == 2 ?
                            <Row>
                                <Col span={10}>
                                    <Form.Item
                                        label="开始补单时间："
                                        name="openRepairTime"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Please input your password!',
                                            },
                                        ]}
                                    >
                                        {
                                            state && state.openRepairTime ?
                                                <span>{state.openRepairTime}</span>
                                                :
                                                <DatePicker showTime style={{ width: 200 }}
                                                    disabled={state && state.ss == 1}
                                                    disabledDate={disabledDate}
                                                    onChange={dateChange} />
                                        }

                                    </Form.Item>
                                </Col>
                            </Row> : ''
                    }

                    <Row align='middle' style={{ background: '#F7F8FA', padding: 10, margin: 10 }}>
                        <div style={{ background: '#145BD5', height: 14, width: 3 }}></div><div style={{ marginLeft: 10, fontWeight: 500 }}>创建信息</div>
                    </Row>
                    <Row style={{ marginTop: 30 }}>
                        <Col span={10}>
                            <Form.Item label="创建人：">
                                {data && data.operator ? data.operator : localStorage.operatorname ? localStorage.operatorname : undefined}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{}}>
                        <Col span={10}>
                            <Form.Item label="创建时间：">
                                {data && data.createTime ? data.createTime : moment().format('YYYY-MM-DD HH:mm:ss')}
                            </Form.Item>
                        </Col>
                    </Row>






                    <Row style={{ marginBottom: '-1vh' }}>
                        <Col span={8} style={{ textAlign: 'center' }}>
                            <Button type="primary" htmlType='submit'>保存</Button>
                            <Button style={{ marginLeft: 20 }} onClick={cell}>取消</Button>

                        </Col>
                    </Row>
                </Form>
            </div >



        </div >
    )
}

export default LnstitutionsInList;