import { Row, Col, Button, Form, Table, Select, message, Tabs } from 'antd';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { marketingAccountViewRequest, employeeAccountRequest, marketingViewRequest, settleMerchantListRequest, listByLoginNameRequest } from '../../../api/api'
import { useCallbackState } from '../../../util/useCallbackState'
const { Option } = Select;
const { TabPane } = Tabs;
const AvailableFunds = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { search } = useLocation()
    const { state } = useLocation()
    const [list, setList] = useState([])
    const [data, setData] = useState(null)
    const [merchantList, setMerchantList] = useState([])
    const [merchantCode, setMerchantCode] = useState(localStorage.merchantCode)
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [accountType, setAccountType] = useState(0)
    const [total, setTotal] = useState()
    const [init, setInit] = useState(true)
    const [employeeList, setEmployeeList] = useState([])


    useEffect(() => {
        console.log(state)
        marketingAccount();

        if (init) {
            settleMerchantList();
        }
    }, [currentPage, pageNum]);

    const columns = [
        {
            title: '商户名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
            align: 'left'
        },
        // {
        //     title: '记账余额(元)',
        //     dataIndex: 'availableBalance',
        //     key: 'availableBalance',
        //     align: 'left'
        // },
        {
            title: '营销可用余额(元)',
            dataIndex: 'availableBalance',
            key: 'availableBalance',
            align: 'left'
        },
        {
            title: '待结算金额(元)',
            dataIndex: 'settleBalance',
            key: 'settleBalance',
            align: 'left'
        },
        {
            title: '不可用余额(元)',
            dataIndex: 'freezenBalance',
            key: 'freezenBalance',
            align: 'left',
        },
        {
            title: '操作',
            key: 'operation',
            // fixed: 'right',
            width: 140,
            align: 'right',
            render: (text, row) => (
                <>
                    <span style={{ color: '#165BD4', cursor: 'pointer' }} onClick={() => {

                        navigate('/ws/storeMarketing', { replace: false, state: { ...row, tag: 1, accountType } })
                    }}>查看</span>
                    {/* <Link style={{ marginLeft: 10 }} to="/billsDetail">查看</Link> */}
                </>

            ),

        },
    ];
    const columns1 = [
        {
            title: '员工名称',
            dataIndex: 'employeeName',
            key: 'employeeName',
            align: 'left'
        },
        // {
        //     title: '记账余额(元)',
        //     dataIndex: 'availableBalance',
        //     key: 'availableBalance',
        //     align: 'left'
        // },
        {
            title: '营销可用余额(元)',
            dataIndex: 'availableBalance',
            key: 'availableBalance',
            align: 'left'
        },
        {
            title: '待结算金额(元)',
            dataIndex: 'settleBalance',
            key: 'settleBalance',
            align: 'left'
        },
        {
            title: '不可用余额(元)',
            dataIndex: 'freezenBalance',
            key: 'freezenBalance',
            align: 'left',
        },
        {
            title: '操作',
            key: 'operation',
            // fixed: 'right',
            width: 140,
            align: 'right',
            render: (text, row) => (
                <>
                    <span style={{ color: '#165BD4', cursor: 'pointer' }} onClick={() => {

                        navigate('/ws/storeMarketing', { replace: false, state: { row, tag: 1, accountType, employeeId: row.employeeId } })
                    }}>查看</span>
                    {/* <Link style={{ marginLeft: 10 }} to="/billsDetail">查看</Link> */}
                </>

            ),

        },
    ];
    const onFinish = (values) => {
        console.log(values);
        if (accountType == 0) {
            marketingAccount(values)
        } else {
            employeeAccount(values)
        }
    };
    const marketingAccount = (data) => {
        console.log(form.getFieldValue())
        let params = {
            currentPage: currentPage,
            pageSize: pageNum,
            merchantCode: localStorage.hxState == 'true' ? undefined : merchantCode,
            superMerchantCode: localStorage.appid,
            subMerchantCode: form.getFieldValue().subMerchantCode
        };
        (async _ => {
            return await marketingAccountViewRequest(params);
        })().then(res => {
            if (res.code == 0) {
                setData(res.data)
                setList(res.data.list)
                setTotal(res.data.totalCount)
            } else {

            }
        });
    }
    const employeeAccount = (data) => {
        let params = {
            ...data,
            currentPage: currentPage,
            pageSize: pageNum,
            merchantCode: localStorage.salesEmployeeId == 'null' ? localStorage.merchantCode : undefined,
            superMerchantCode: localStorage.appid,
            subMerchantCode: form.getFieldValue().subMerchantCode
        };
        (async _ => {
            return await employeeAccountRequest(params);
        })().then(res => {
            if (res.code == 0) {
                setData(res.data)
                setList(res.data.list)
                setTotal(res.data.totalCount)
            } else {

            }
        });
    }
    const employeeAccountList = (data) => {
        let params = {
            ...data,
            currentPage: currentPage,
            pageSize: 10000,
            merchantCode: localStorage.hxState == 'true' ? undefined : localStorage.merchantCode,
            superMerchantCode: localStorage.appid,
        };
        (async _ => {
            return await employeeAccountRequest(params);
        })().then(res => {
            if (res.code == 0) {
                setEmployeeList(res.data.list)
            } else {

            }
        });
    }
    const settleMerchantList = () => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode: localStorage.merchantCode,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            if (res.code == 0) {
                setInit(false)
                setMerchantList(res.data)
            } else {

            }
        });
    }
    const onChangPage = (currentPage, pageNum) => {
        setCurrentPage(currentPage, () => {
            // getBalanceAccountView()
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        setPageNum((currentPage, pageNum), () => {
            // getBalanceAccountView()
        })
    }

    const goRechargeRecord = () => {
        navigate('/ws/balance/recharge', { replace: false, state: { defaultActiveKey: 2 } })
    }
    const goWithdrawalRecord = () => {
        navigate('/ws/balance/withdrawal', { replace: false, state: { defaultActiveKey: 2, tag: 1, row: state } })
    }
    const goSettlementRecords = () => {
        navigate('/ws/balance/settlementRecords', { replace: false, state: { billType: 2, accountType: 7 } })
    }

    const onChange = (checked) => {
        console.log(checked);
        if (checked == 0) {
            marketingAccount()
        }
        if (checked == 1) {
            employeeAccount()
            employeeAccountList()
        }
        setAccountType(checked)
    };
    return (
        <div style={{ background: '#ffffff', padding: 16, marginTop: 16 }}>
            <Tabs
                style={{ marginTop: 0, }}
                onChange={onChange} type="card" defaultValue="">
                {/* <TabPane tab="记账余额" key="">
</TabPane> */}
                <TabPane tab="机构" key="0">
                </TabPane>
                <TabPane tab="员工" key="1">
                </TabPane>
            </Tabs>
            <Row justify='space-between' style={{ background: '#F7F8FA', padding: 16, }}>
                {/* <Col span={6}>
                    <div><span>记账余额(元)</span>
                        <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 20 }} onClick={goWithdrawalRecord}>明细</span>
                    </div>
                    <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{data && data.availableBalance}</div>
                </Col> */}
                <Col span={6}>
                    <div><span>营销可用余额(元)</span>
                        {/* <Button type='link' onClick={goRechargeRecord}>充值记录</Button> */}
                        <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 20 }} onClick={goWithdrawalRecord}>提现记录</span>
                    </div>
                    <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{data && data.availableBalance}</div>
                </Col>

                <Col span={6}>
                    <div><span>待结算金额(元)</span>
                        {/* <Button type='link' onClick={goRechargeRecord}>充值记录</Button> */}
                        {
                            accountType == 0 ?
                                <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 20 }} onClick={goSettlementRecords}>结算记录</span>
                                : null
                        }
                    </div>
                    <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{data && data.settleBalance}</div>
                </Col>
                <Col span={6}>
                    <div><span>不可用余额(元)</span>
                        {/* <Button type='link' onClick={goRechargeRecord}>充值记录</Button> */}
                        <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 20 }} onClick={() => {
                            navigate('/ws/balance/availableAndAvailable', { replace: false, state: { tag: 1, }, })
                        }}>明细</span>
                    </div>
                    <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{data && data.freezenBalance}</div>
                </Col>

            </Row>

            <Row style={{ paddingTop: 16 }}>

                <Form form={form}
                    name="basic"
                    layout="inline" onFinish={onFinish}
                    initialValues={{ subMerchantCode: null, employeeId: null }}
                >
                    {
                        accountType == 0 ?
                            <Form.Item
                                name="subMerchantCode"
                                label="商户名称："
                                rules={[{ required: false, message: 'Please input your username!' }]}
                            >
                                <Select
                                    placeholder="请选择"
                                    style={{ width: 250 }}
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.includes(input)}
                                >
                                    <Option key={null} value={null}>全部</Option>
                                    {
                                        merchantList && merchantList.map((v) => {
                                            return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                        })
                                    }

                                </Select>
                            </Form.Item> :
                            <Form.Item
                                name="employeeId"
                                label="员工名称："
                                rules={[{ required: false, message: 'Please input your username!' }]}
                            >
                                <Select
                                    placeholder="请选择"
                                    style={{ width: 250 }}
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.includes(input)}
                                >
                                    <Option key={null} value={null}>全部</Option>
                                    {
                                        employeeList && employeeList.map((v) => {
                                            return <Option rowKey={v.employeeId} key={v.employeeId} value={v.employeeId}>{v.employeeName}</Option>
                                        })
                                    }

                                </Select>
                            </Form.Item>
                    }


                    <Form.Item shouldUpdate>
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            筛选
                        </Button>
                        {/* <Button
                            style={{ marginLeft: 20 }}
                        >
                            导出
                        </Button>
                        <Button
                            style={{ marginLeft: 20 }}
                            type="link"
                        >
                            查看已导出列表
                        </Button> */}
                        <Button
                            style={{ marginLeft: 20 }}
                            type="link"
                            onClick={() => {
                                form.setFieldsValue({
                                    subMerchantCode: null
                                })
                                marketingAccount()
                            }}
                        >
                            重置筛选条件
                        </Button>
                    </Form.Item>

                </Form>
            </Row>

            <Table
                locale={{
                    emptyText: "没有更多数据了"
                }}
                style={{ marginTop: 20 }}
                columns={accountType == 0 ? columns : columns1}
                dataSource={list}
                rowKey=""
                pagination={{
                    total,
                    showSizeChanger: true,
                    current: currentPage,
                    pageSize: pageNum,
                    // position: ["bottomCenter"],
                    showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                    onChange: (k, v) => onChangPage(k, v),
                    onShowSizeChange: (k, v) => onChangPageNum(k, v),
                }}
            />
        </div >
    )
}
export default AvailableFunds