import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
    Form,
    Row,
    Col,
    Button,
    Input,
    Select,
    Table,
    Popconfirm,
    message,
    Modal,
    DatePicker,
    Radio
} from 'antd';
import {
    bookAccountListRequest,
    bookAccountSetDefaultRequest
} from '../../../api/api'
import { useCallbackState } from '../../../util/useCallbackState'
const { Option } = Select;
const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
};

const LnstitutionsInList = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [total, setTotal] = useState()
    const [list, setList] = useState([])
    const superMerchantCode = localStorage.appid
    const { search, state } = useLocation()
    const [value, setValue] = useState(1);

    useEffect(() => {
        bookAccountList()

    }, [currentPage, pageNum])
    const columns = [
        {
            title: '默认',
            dataIndex: 'ifDefault',
            key: 'ifDefault',
            render: (text, row) => {
                return (
                    <div>
                        <Radio.Group
                            onChange={(e) => {
                                console.log(e);
                                bookAccountSetDefault(row)
                            }}
                            value={row.ifDefault == 1 ? 1 : 0}>
                            <Radio value={1}></Radio>
                        </Radio.Group>
                    </div>
                )
            }
        },
        {
            title: '账户名称',
            dataIndex: 'accountName',
            key: 'accountName',
        },
        {
            title: '账户描述',
            dataIndex: 'accountDescription',
            key: 'accountDescription',
        },
        {
            title: '订单类型',
            dataIndex: 'orderTypes',
            key: 'orderTypes',
            render: (text, row) => {
                return (
                    <div>
                        {row.orderTypes == 1 ? '充值' : ''}
                    </div>
                )


            }
        },
        {
            title: '银行卡',
            dataIndex: 'bankCardNo',
            key: 'bankCardNo',
            render: (text, row) => {
                return (
                    <div>
                        {row.bankName}*{row.bankCardNo}{row.bankType == 1 ? '(对公)' : '(对私)'}
                    </div>
                )
            }
        },
        {
            title: '状态',
            dataIndex: 'openRepairTime',
            key: 'openRepairTime',
            render: (text, row) => {
                return (
                    <div>
                        {row.accountStatus == 1 ? '已启用' : '已停用'}
                    </div>
                )
            }
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 140,
            align: 'right',
            render: (text, row) => (
                <>
                    <span style={{ color: '#165BD4', cursor: 'pointer', marginRight: 10 }} onClick={() => {
                        navigate('/ws/account/detail', { replace: false, state: row })
                    }}>详情</span>
                    <span style={{ color: '#165BD4', cursor: 'pointer' }} onClick={() => {
                        row.ss = 2
                        navigate('/ws/account/add', { replace: false, state: row })
                    }}>编辑</span>

                </>

            ),

        },
    ];

    const bookAccountList = (values) => {

        (async _ => {
            return await bookAccountListRequest({
                ...values,
                merchantCode: localStorage.merchantCode,
                superMerchantCode,
                currentPage,
                pageSize: pageNum
            });
        })().then(res => {
            console.log(res)
            if (res.code == 0) {
                setList(res.data.lists)
                setTotal(res.data.totalCount)
            } else {
            }
        });
    }

    const onFinish = (values) => {
        values.currentPage = 1;
        bookAccountList(values)
    };




    const bookAccountSetDefault = (row) => {
        (async _ => {
            return await bookAccountSetDefaultRequest({
                id: row.id,
                ifDefault: 1,
            });
        })().then(res => {
            console.log(res)
            if (res.code == 0) {
                bookAccountList()
            } else {
                message.error(res.msg)
            }
        });
    }

    const goAdd = () => {
        navigate('/ws/account/add')
    }

    const onChangPage = (currentPage, pageNum) => {
        setCurrentPage(currentPage, () => {
            // merchantList()
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        setPageNum((currentPage, pageNum), () => {
            // merchantList()
        })
    }

    return (
        <div style={{ background: '#ffffff', padding: 16, marginTop: 16 }}>
            <Row style={{ marginBottom: '-1vh' }}>
                <Button type='primary' onClick={goAdd}>添加账户</Button>
            </Row>
            <Form
                form={form}
                {...layout}
                name="advanced_search"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{
                    status: null
                }}
                style={{ marginTop: 16, background: '#F7F8FA', padding: 16, paddingBottom: 0 }}
            >
                <Row style={{ marginTop: 20, background: '#F7F8FA', marginBottom: '-1vh' }}>
                    <Col span={8}>
                        <Form.Item
                            name="accountName"
                            label="账户名称"
                        >
                            <Input style={{ width: 200 }} />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item
                            name="accountStatus"
                            label="账户状态"
                        >
                            <Select style={{ width: 200 }}>
                                <Option value={null}>全部</Option>
                                <Option value="1">已启用</Option>
                                <Option value="2">已停止</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '-1vh' }}>
                    <Col span={8} >
                        <Form.Item
                            wrapperCol={{
                                offset: 5,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                筛选
                            </Button>
                            {/* <Button
                                style={{ margin: '0 8px' }}

                            >
                                导出
                            </Button>
                            <Button type="link"  >
                                查看已导出列表
                            </Button> */}
                            <Button type="link" onClick={() => {
                                form.resetFields();
                            }} >
                                重置筛选条件
                            </Button>
                        </Form.Item>


                    </Col>
                </Row>
            </Form>

            <Table
                locale={{
                    emptyText: "没有更多数据了"
                }}
                style={{ marginTop: 20 }}
                columns={columns}
                dataSource={list}
                rowKey="id"
                pagination={{
                    total,
                    showSizeChanger: true,
                    current: currentPage,
                    pageSize: pageNum,
                    // position: ["bottomCenter"],
                    showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                    onChange: (k, v) => onChangPage(k, v),
                    onShowSizeChange: (k, v) => onChangPageNum(k, v),
                }}
            />
        </div>
    )
}
export default LnstitutionsInList;