let host = 'https://apifund.yuemiaoai.com';
// if (process.env.NODE_ENV == "development") {
//     host = 'https://prefund.yuemiaoai.com'
// }
// if(process.env.NODE_ENV == "production") {
//     host = 'https://prefund.yuemiaoai.com'
// }


export default host;
