
import React, { useState, useEffect, } from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import QRCode from 'qrcode.react';
import {
    Form,
    Row,
    Col,
    Button,
    Table,
    DatePicker,
    Input,
    Select,
    message,
    Popconfirm,
    Tabs,
    Radio,
    Upload,
    Image,
    Modal,
    InputNumber
} from 'antd';
import { settleMerchantListRequest, toAddRequest, bookRechargeSaveRequest, bookRechargeListRequest, listProcessRequest, balanceViewRequest, merchantDetailRequest } from '../../../api/api'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import host from '../../../util/host';
import { useCallbackState } from '../../../util/useCallbackState'
import Detail from './detail/index'
import host2 from '../../../util/host2'
const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;

const FormItem = Form.Item;
const Search = Input.Search;
const dateFormat = 'YYYY-MM-DD';
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
};
const formItemLayout = {
    labelCol: {
        xs: {
            span: 8,
        },
        sm: {
            span: 2,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
};

const Recharge = () => {
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const { state } = useLocation();
    const { search } = useLocation()
    const [startDate, setStartDate] = useState(moment(moment().format('YYYY-MM-DD'), dateFormat).format("YYYY-MM-DD"))
    const [data, setData] = useState(null)
    const [endDate, setEndDate] = useState(moment(moment().format('YYYY-MM-DD'), dateFormat).format("YYYY-MM-DD"))
    const [activeKey, setActiveKey] = useState('1')
    const [merchantList, setMerchantList] = useState([])
    const [merchantCode, setMerchantCode] = useState(state && state.merchantCode ? state.merchantCode : localStorage.merchantCode)
    const [pgmerchantCode, setPgMerchantCode] = useState(state && state.merchantCode ? state.merchantCode : localStorage.merchantCode)
    const [subMerchantCode, setSubMerchantCode] = useState(localStorage.hxState == 'true' ? null : localStorage.merchantCode)
    const [merchantName, setMerchantName] = useState(localStorage.merchantName)
    const [img, setImg] = useState(null)
    const [list, setList] = useState([])
    const [lists, setLists] = useState([])
    const [cardList, setCardList] = useState([])
    const [cardData, setCardData] = useState(null)
    const [value1, setValue1] = useState(1)
    const [value2, setValue2] = useState(0)
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [total, setTotal] = useState()
    const [detailVisible, setDetailVisible] = useState(false);
    const [row, setRow] = useState(null)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [mianqian, setMianqian] = useState(false)
    const [qrCode, setQrcode] = useState(null)
    const [amount, setAmount] = useState(0)
    const [pg, setPg] = useState(null)
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    const beforeUpload = (file) => {
        // setImg(file)
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('请上传JPG/PNG文件!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('图片大小不能大于2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            setLoading(false);
            setImageUrl(info.file.response.data);
            // getBase64(info.file.originFileObj, (url) => {

            // });
        }
    };
    useEffect(() => {
            settleMerchantList()
            toAdd();
            // merchantDetail()
            if (value1 == 1) {
                getBalanceView()
            }
            // if (localStorage.hxState == 'true') {
            // setActiveKey(2)
            bookRechargeList()
            // }
    }, [currentPage, pageNum]) //所有更新都执行
    useEffect(() => {
        merchantDetail();
    }, [pgmerchantCode]) //所有更新都执行


    function confirm(e) {
        (async _ => {
            return await listProcessRequest({
                auditOperaterId: localStorage.operatorId,
                auditOperaterName: localStorage.operatorname,
                merchantCode,
                superMerchantCode: localStorage.appid,
                ids: selectedRowKeys,
            });
        })().then(res => {
            if (res.code == 0) {
                bookRechargeList()
                message.success(`审核成功${res.data}条`)
            } else {
                message.error(res.msg)
            }
        });
    }
    const merchantDetail = () => {
        (async _ => {
            return await merchantDetailRequest({
                merchantCode: pgmerchantCode
            });
        })().then(res => {
            if (res.code == 0) {
                setPg(res.data)
                // setList(res.data.list)
            } else {
                setPg(null)
            }
        });
    }
    const getBalanceView = (data) => {
        let params = {
            // currentPage: currentPage,
            // pageSize: pageNum,
            merchantCode: data ? data : merchantCode,
            superMerchantCode: localStorage.appid,
        };
        (async _ => {
            return await balanceViewRequest(params);
        })().then(res => {
            if (res.code == 0) {
                setData(res.data)
                setAmount(res.data.availableBalance)

                // setList(res.data.list)
            } else {

            }
        });
    }
    function cancel(e) {

    }
    const onFinish1 = (values) => {
        values.currentPage = 1
        setCurrentPage(1)
        bookRechargeList(values)
    }
    const bookRechargeList = (data) => {
        let values = form2.getFieldValue();
        const rangeValue = values['date'];
        values.startTime = rangeValue && rangeValue[0].format('YYYY-MM-DD HH:mm:ss');
        values.endTime = rangeValue && rangeValue[1].format('YYYY-MM-DD HH:mm:ss');
        (async _ => {
            return await bookRechargeListRequest({
                ...values,
                subMerchantCode: subMerchantCode,
                merchantCode: merchantCode,
                superMerchantCode: localStorage.appid,
                currentPage: data ? data.currentPage : currentPage,
                pageSize: pageNum,
            });
        })().then(res => {
            if (res.code == 0) {
                setLists(res.data.lists)
                setTotal(res.data.totalCount)
            } else {

            }
        });
    }
    const settleMerchantList = () => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            if (res.code == 0) {
                setMerchantList(res.data)
            } else {

            }
        });
    }
    const toAdd = (data) => {
        (async _ => {
            return await toAddRequest({
                merchantCode: data ? data : merchantCode,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            if (res.code == 0) {
                res.data.list.map((v) => {
                    if (v.ifDefault == 1) {
                        setCardList([v])
                        setCardData(v)
                    }
                    form1.setFieldsValue({
                        card: v.bankCardId
                    })
                })
                setData(res.data)
                setList(res.data.list)
            } else {
                message.info(res.msg)
            }
        });
    }
    const detailShowModal = (data) => {
        setRow(data)
        setDetailVisible(true);
    };

    const detailHandleOk = () => {
        setDetailVisible(false);
    };

    const detailhandleCancel = () => {
        setDetailVisible(false);
    };
    const columns = (_this) => {
        return [
            {
                title: '商户名称',
                dataIndex: 'merchantName',
                key: 'merchantName',
                width: 200
            },
            {
                title: '时间',
                dataIndex: 'createTime',
                key: 'createTime',
                width: 200
            },
            {
                title: '金额（元）',
                dataIndex: 'amount',
                key: 'amount',
            },
            {
                title: '充值状态',
                dataIndex: 'rechargeStatus',
                key: 'rechargeStatus',
                render: (text, row) => {
                    return (
                        <div>
                            {row.rechargeStatus == 0 ? '充值中' : ''}
                            {row.rechargeStatus == 1 ? '充值成功' : ''}
                            {row.rechargeStatus == 2 ? '充值失败' : ''}
                        </div>
                    )

                }
            },
            {
                title: '审核状态',
                dataIndex: 'auditStatus',
                key: 'auditStatus',
                render: (text, row) => {
                    return (
                        <div>
                            {row.rechargeStatus == 0 ? '未审核' : ''}
                            {row.rechargeStatus == 1 ? '已审核' : ''}
                            {row.rechargeStatus == 2 ? '审核失败' : ''}
                        </div>
                    )

                }
            },
            {
                title: '支付方式',
                dataIndex: 'rechargeFundType',
                key: 'rechargeFundType',
                render: (text, row) => {
                    return (
                        <div>
                            {row.rechargeFundType == 0 ? '线上充值' : ''}
                            {row.rechargeFundType == 1 ? '线下充值' : ''}
                        </div>
                    )

                }
            },
            {
                title: '汇款账户',
                dataIndex: 'accountName',
                key: 'accountName',
            },
            {
                title: '汇款凭证',
                dataIndex: 'voucher',
                key: 'voucher',
                render: (text, row) => {
                    return (
                        <div>
                            <Image
                                width={50}
                                height={50}
                                src={row.voucher}
                            />
                        </div>
                    )
                }
            },
            {
                title: '操作人',
                dataIndex: 'rechargeOperateName',
                key: 'rechargeOperateName',
                width: 250,
            },
            {
                title: '业务单号｜支付流水号',
                dataIndex: 'orderNo',
                key: 'orderNo',
                width: 250,
                render: (text, row) => {
                    return (
                        <div>
                            <div>{row.orderNo}</div>
                            <div>{row.thirdTradeNo}</div>
                        </div>
                    )
                }
            },
            {
                title: '审核人',
                dataIndex: 'auditOperaterName',
                key: 'auditOperaterName',
                width: 250,
            },
            {
                title: '审核时间',
                dataIndex: 'auditTime',
                key: 'auditTime',
                width: 250,
            },
            {
                title: '操作',
                dataIndex: 'dome',
                key: 'dome',
                fixed: 'right',
                render: (text, row) => {
                    return (
                        <div>
                            {
                                row.auditStatus == 0 && localStorage.hxState == 'true' && row.rechargeFundType != 0 ?
                                    <a onClick={() => {
                                        detailShowModal(row)
                                    }}>审核</a> : ''
                            }
                        </div>

                    )
                }
            },
        ]
    }

    const onFinish = (values) => {
        // console.log(values, '......values......', data)
        delete values.img;
        if (value2 == 1) {
            let params = {
                ...values,
                accountName: cardData && cardData.accountName,
                companyName: cardData && cardData.companyName ? cardData.companyName : cardData.name,
                bankName: cardData && cardData.bankName,
                bankCardNo: cardData && cardData.bankCardNo,
                bankBranchName: cardData && cardData.bankBranchName ? cardData.bankBranchName : cardData.phone,
                bankType: cardData && cardData.bankType,
                bookAccountNo: cardData && cardData.accountNo,
                bookAccountName: cardData && cardData.accountName,
                voucher: imageUrl,
                merchantCode: data && data.merchantCode,
                merchantName: data && data.merchantName,
                superMerchantCode: data && data.superMerchantCode,
                rechargeOperaterId: localStorage.operatorId,
                rechargeOperateName: localStorage.operatorname,
                payType: values.rechargeFundType == 1 ? 3 : undefined,
            };
            (async _ => {
                return await bookRechargeSaveRequest(params);
            })().then(res => {
                if (res.code == 0) {
                    bookRechargeList()
                    setActiveKey(2)
                    // setValue1(1)
                    // setValue2(0)
                    message.success(res.msg)
                } else {
                    message.error(res.msg)
                }
            });
        } else {
            // setQrcode(`https://preclubb2b.liantuofu.com/fund/?&appId=${subMerchantCode}&operatorId=${localStorage.operatorId}&operatorName=${localStorage.operatorName}&merchantCode=${subMerchantCode}&merchantName=${merchantName}&amount=${data && data.totalAmount}&token=${localStorage.token}`)
            setQrcode(`${host2}/?&appId=${localStorage.appid}&operatorId=${localStorage.operatorId}&operatorName=${localStorage.operatorname}&merchantCode=${pgmerchantCode}&merchantName=${merchantName}&amount=${values.amount}&token=${localStorage.token}&accountType=${values.accountType}`)

            setMianqian(true)
        }



    }

    const onChange = (key) => {
        setActiveKey(key)
        if (key == 1) {
            toAdd()

        }
        if (key == 2) {
            form2.setFieldsValue({
                date: [moment(moment().format('YYYY-MM-DD'), dateFormat), moment(moment().format('YYYY-MM-DD'), dateFormat)]
            })
            bookRechargeList()
        }

    };
    const inSevenDays = () => {
        form2.setFieldsValue({
            date: [moment(moment().subtract(7, "days").format("YYYY-MM-DD"), dateFormat), moment(moment().format('YYYY-MM-DD'), dateFormat)]
        })
    }
    const nearlyThirtyDays = () => {
        form2.setFieldsValue({
            date: [moment(moment().subtract(30, "days").format("YYYY-MM-DD"), dateFormat), moment(moment().format('YYYY-MM-DD'), dateFormat)]
        })
    }
    const timeChange = (date) => {
        setStartDate(date && date[0].format("YYYY-MM-DD"))
        setEndDate(date && date[1].format('YYYY-MM-DD'))
    }

    const accountTypeChange = (e) => {
        if (e.target.value == 0) {
            getBalanceView()
        } else {
            toAdd(subMerchantCode)
        }

        setValue1(e.target.value)
    }
    const rechargeFundTypeChange = (e) => {
        setValue2(e.target.value)
        list.map((v) => {
            if (v.bankCardId == e.target.value) {
                setCardList([v])
                setCardData(v)
            }
        })
    }
    const onChangPage = (currentPage, pageNum) => {
        setCurrentPage(currentPage, () => {
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        setPageNum((currentPage, pageNum), () => {
        })
    }
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys)
        },
        getCheckboxProps: (record) => ({
            disabled: record.auditStatus !== 0,
            // Column configuration not to be checked
            name: record.name,
        }),
    };
    const mianqianHandleCancel = () => {
        setMianqian(false);
    };
    return (
        <div style={{ background: '#ffffff', paddingBottom: 20, width: '100%' }}>

            {/* <Row style={{ marginTop: 20, width: '100%' }} > */}
            <Tabs activeKey={activeKey.toString()} onChange={onChange} size="large" tabBarStyle={{ paddingLeft: 20 }} style={{ padding: 10, marginTop: 10 }} tabBarGutter={100}>
                <TabPane tab="充值" key="1">
                    <Form
                        form={form1}
                        name="time_related_controls"
                        {...formItemLayout}
                        onFinish={onFinish}
                        autoComplete="off"
                        initialValues={{
                            accountType: 1,
                            rechargeFundType: 0,
                            merchantCode: localStorage.merchantCode,
                        }}
                    >
                        {
                            localStorage.hxState == 'true' ?
                                <Form.Item
                                    name="merchantCode"
                                    label="商户"
                                >
                                    <Select placeholder="请选择"
                                        style={{ width: 250 }}
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.includes(input)}
                                        onChange={(e) => {
                                            setSubMerchantCode(e)
                                            setPgMerchantCode(e)
                                            merchantList && merchantList.map((v) => {
                                                if (v.merchantCode == e) {
                                                    setMerchantName(v.merchantName)
                                                }
                                            })
                                            if (value1 == 1) {
                                                getBalanceView(e)
                                            } else {
                                                toAdd(e)
                                            }

                                        }}
                                    >

                                        {
                                            merchantList && merchantList.map((v) => {
                                                return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                            })
                                        }

                                    </Select>
                                </Form.Item> :
                                <Form.Item
                                    name="merchantCode"
                                    label="商户："
                                >
                                    {localStorage.merchantName}
                                </Form.Item>
                        }
                        <Form.Item
                            name="accountType"
                            label="充值账户"
                            rules={[{ required: true, message: '请选择收款账户!' }]}
                        >
                            <Radio.Group onChange={accountTypeChange} value={value1}>
                                {
                                    pg && pg.payTransactionId && pg.payChannel ?
                                        <Radio value={1}>店铺余额</Radio> : null
                                }

                                <Radio value={2}>钱包余额</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            name="rechargeFundType"
                            label="汇款账户"
                            rules={[{ required: true, message: '请选择收款账户!' }]}
                        >
                            <Radio.Group onChange={rechargeFundTypeChange} value={value2}>
                                <Radio value={0}>在线支付</Radio>
                                {
                                    value1 == 2 ? <Radio value={1}>线下汇款</Radio> : null
                                }

                            </Radio.Group>
                        </Form.Item>
                        {value1 == 2 && value2 == 1 ?
                            <Form.Item
                                name="card"
                                colon={false}
                                label={<span style={{ color: '#fff' }}>账户名称</span>}
                                rules={[{ required: false, message: '请选择收款账户!' }]}
                            >
                                <Radio.Group onChange={rechargeFundTypeChange} value={value2}>
                                    {
                                        list.map((v, index) => {
                                            return (
                                                <Radio key={index} value={v.bankCardId}>{v.accountName}</Radio>
                                            )
                                        })
                                    }

                                </Radio.Group>
                            </Form.Item>
                            : null
                        }

                        {
                            value1 == 2 && value2 == 1 && cardList.length > 0 ?
                                <Form.Item
                                    colon={false}
                                    label={<span style={{ color: '#fff' }}>账户名称</span>}
                                >
                                    <div style={{ background: '#ffffff', display: 'flex' }}>
                                        {
                                            cardList.map((v, index) => {
                                                return (
                                                    <div className='bankCard' key={index}>
                                                        <div className='bankCard-top'>
                                                            <div className='title'>
                                                                <div className='name'>{v.bankName}</div>
                                                                <div className='type'>{v.bankType == 1 ? '对公' : '对私'}</div>
                                                            </div>
                                                            <div className='main'>
                                                                <div className='gsName'>{v.companyName}</div>
                                                                <div className='card'>{v.bankCardNo}</div>
                                                                <div className='cardName'>{v.bankBranchName}</div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Form.Item> : ''
                        }

                        {
                            value1 == 1 ?
                                <Form.Item
                                    name="d"
                                    label="店铺余额"
                                >
                                    <span>{amount}</span>元

                                </Form.Item>
                                :
                                <Form.Item
                                    name="d"
                                    label="钱包余额"
                                >
                                    <span>{data && data.totalAmount}</span>元

                                </Form.Item>
                        }

                        <Form.Item
                            name="amount"
                            label="充值金额"
                            rules={[{ required: true, message: '请输入充值金额!' }]}
                        >
                            <InputNumber
                                style={{ width: 200 }}
                                min={0.01}
                                precision={2}
                                addonAfter={'元'}
                            />
                        </Form.Item>
                        {
                            value1 == 2 && value2 == 1 ?
                                <Form.Item
                                    style={{ marginBottom: '-0.2vh' }}
                                    name="img"
                                    label="银行回单"
                                    rules={[{ required: true, message: '请上传银行回单!' }]}
                                >
                                    <Upload
                                        name="avatar"
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        action={`${host}/bookRecharge/imgUpload`}
                                        headers={{ 'token': localStorage.token, }}
                                        beforeUpload={beforeUpload}
                                        onChange={handleChange}
                                        data={file => ({ // data里存放的是接口的请求参数
                                            img: file,
                                            merchantCode: localStorage.merchantCode
                                        })}
                                    >
                                        {imageUrl ? (
                                            <img
                                                src={imageUrl}
                                                alt="avatar"
                                                style={{
                                                    width: '100%',
                                                    height: 100
                                                }}
                                            />
                                        ) : (
                                            uploadButton
                                        )}

                                    </Upload>

                                </Form.Item> : null
                        }
                        {
                            value1 == 2 && value2 == 1 ?
                                <Form.Item

                                    label={<span style={{ color: '#fff' }}>提示</span>}
                                    rules={[{ required: false, message: '请上传银行回单!' }]}
                                    colon={false}
                                >
                                    <span style={{ color: 'red' }}>图片不能大于2M</span>

                                </Form.Item> : null
                        }

                        <Form.Item
                            style={{ marginLeft: 30 }}
                        >
                            <Button style={{ marginLeft: 80 }} type='primary' htmlType='submit'>确认充值</Button>
                        </Form.Item>

                    </Form>

                </TabPane>

                <TabPane tab="充值记录" key="2">
                    <Form
                        form={form2}
                        {...layout}
                        name="advanced_search"
                        className="ant-advanced-search-form"
                        onFinish={onFinish1}
                        style={{ background: '#F7F8FA', padding: 20, }}
                        initialValues={{
                            merchantCode: state && state.merchantCode,
                        }}
                    >
                        <Row>
                            <Col span={8}>
                                {
                                    localStorage.hxState == 'true' ?
                                        <Form.Item
                                            name="merchantCode"
                                            label="商户："
                                        >
                                            <Select placeholder="请选择"
                                                style={{ width: 250 }}
                                                showSearch
                                                allowClear
                                                optionFilterProp="children"
                                                filterOption={(input, option) => option.children.includes(input)}
                                                onChange={(e) => {
                                                    setSubMerchantCode(e)
                                                }}
                                            >
                                                <Option key={null} value={null}>全部</Option>
                                                {
                                                    merchantList && merchantList.map((v) => {
                                                        return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                                    })
                                                }

                                            </Select>
                                        </Form.Item> :
                                        <Form.Item
                                            name="merchantCode"
                                            label="商户："
                                        >
                                            {localStorage.merchantName}
                                        </Form.Item>
                                }

                            </Col>
                            <Col span={8} >
                                <Form.Item
                                    name="orderNo"
                                    label="业务单号"
                                >
                                    <Input style={{ width: 250 }} placeholder='请输入' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '-1vh' }}>
                            <Col span={12} pull={1}>
                                <Form.Item
                                    name="date"
                                    label="充值时间："
                                    wrapperCol={{
                                        offset: 0,
                                        span: 20,
                                    }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Form.Item
                                            name="date"
                                            noStyle
                                        >
                                            <RangePicker style={{ width: 250 }} />
                                        </Form.Item>
                                        <Form.Item
                                            noStyle
                                        >
                                            <Button onClick={inSevenDays} type="link">近7天</Button>
                                            <Button onClick={nearlyThirtyDays} type="link">近30天</Button>
                                        </Form.Item>

                                    </div>

                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8} >
                                <Form.Item
                                    name="accountType"
                                    label="充值账户"
                                >
                                    <Select style={{ width: 250 }}>
                                        <Option value="1">店铺余额</Option>
                                        <Option value="2">钱包余额</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="rechargeStatus"
                                    label="充值状态："
                                >
                                    <Select style={{ width: 250 }}>
                                        <Option value="0">充值中</Option>
                                        <Option value="1">充值成功 </Option>
                                        <Option value="2">充值失败 </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8} >
                                <Form.Item
                                    name="rechargeFundType"
                                    label="支付方式："
                                >
                                    <Select style={{ width: 250 }}>
                                        <Option value="0">线上充值</Option>
                                        <Option value="1">线下充值 </Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                        </Row>

                        <Row>
                            <Col span={8}>
                                <Form.Item
                                    wrapperCol={{
                                        offset: 6,
                                        span: 18,
                                    }}
                                >
                                    <Button type="primary" htmlType="submit">
                                        筛选
                                    </Button>
                                    <Button type="link" onClick={() => {
                                        form2.resetFields();
                                    }} >
                                        重置筛选条件
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>


                    </Form>
                    <Table
                        rowSelection={{
                            ...rowSelection,
                        }}
                        locale={{
                            emptyText: "没有更多数据了"
                        }}
                        style={{ marginTop: 10 }}
                        // loading={loading}
                        rowKey="id"
                        dataSource={lists}
                        columns={columns(this)}
                        scroll={{ x: 2600 }}
                        pagination={{
                            total,
                            showSizeChanger: true,
                            current: currentPage,
                            pageSize: pageNum,
                            showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                            onChange: (k, v) => onChangPage(k, v),
                            onShowSizeChange: (k, v) => onChangPageNum(k, v),
                        }}
                        footer={() => {
                            return (
                                localStorage.hxState == 'true' && selectedRowKeys.length > 0 ?
                                    <Popconfirm
                                        title="确认批量审核通过?"
                                        onConfirm={confirm}
                                        onCancel={cancel}
                                        okText="是"
                                        cancelText="否"
                                    >
                                        <Button type="primary">批量审核通过</Button>
                                    </Popconfirm>


                                    : null
                            )
                        }}
                    />
                </TabPane>
            </Tabs >

            <Modal
                title="充值审核"
                visible={detailVisible}
                onOk={detailHandleOk}
                onCancel={detailhandleCancel}
                width={600}
                footer={null}
                key={Math.random(10)}
            >
                <Detail data={row}
                    callback={(state) => {
                        if (state) {
                            bookRechargeList()

                        }
                    }}
                    closeDialog={() => {
                        setDetailVisible(false)
                    }} />
            </Modal>
            <Modal
                width={300}
                title="请用微信扫描二维码" visible={mianqian} onCancel={mianqianHandleCancel}
                footer={null}
            >
                <Row justify='center'>
                    <QRCode
                        value={qrCode} //value参数为字符串类型
                        size={200} //二维码的宽高尺寸
                        fgColor="#000000"  //二维码的颜色
                    />
                </Row>

            </Modal>
        </div >
    )
}
export default Recharge;