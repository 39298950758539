import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import NP from 'number-precision'
import {
    Form,
    Input,
    Switch,
    Row,
    Col,
    Button,
    Tabs,
    Table,
    message,
    Modal,
    Radio,
    Select,
    Space,
    Skeleton
} from 'antd';
import { configSelectListRequest, matchMerchantListRequest, editSetModelRequest, settleMerchantListRequest } from '../../../api/api'
import { useCallbackState } from '../../../util/useCallbackState'
const Option = Select.Option
const layout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
};
const BillingDetails = (props) => {
    const [form] = Form.useForm();
    const [merchantList, setMerchantList] = useState([])
    const [superMerchantName, setSuperMerchantName] = useState(null)

    useEffect(() => {
        configSelectList();
    }, [])
    useEffect(() => {
        console.log(props.data.superMerchantName);
        if (props.data && props.data.edit || props.data && props.data.detail) {
            form.setFieldsValue({
                superMerchantCode: props.data.superMerchantCode,
                cashType: props.data.cashType,
                purchaseType: props.data.purchaseType,
                paycardType: props.data.paycardType,
                feeRate: props.data.feeRate,
                withdrawStartAmt: props.data.withdrawStartAmt,
                incomeRate: props.data.incomeRate,

            })
            setSuperMerchantName(props.data.superMerchantName);
        }
    }, [])
    const configSelectList = (data) => {
        (async _ => {
            return await configSelectListRequest();
        })().then(res => {
            if (res.code == 0) {
                setMerchantList(res.data.fundSuperMerchantList)
            } else {
                message.error(res.msg)
            }
        });
    }

    const onFinish = (values) => {
        (async _ => {
            return await editSetModelRequest(values);
        })().then(res => {
            if (res.code == 0) {
                props.callback(true);
                props.closeDialog();
                message.success(res.msg);
            } else {
                message.error(res.msg)
            }
        });

    };
    return (
        <div style={{ background: '#ffffff', paddingBottom: 20 }}>


            <Row style={{
                marginTop: 16,
            }}>

                <Form form={form} name="basic" {...layout} onFinish={onFinish}
                    autoComplete="off"
                    initialValues={{ subMerchantCode: null }}
                >
                    { props.data && props.data.edit ? 
                    <Form.Item
                        name="superMerchantCode"
                        label="所属机构"
                        colon={false}
                        rules={[{ required: false, message: 'Please input your username!' }]}
                    >
                    <div>
                    {superMerchantName}                             
                    </div>
                    </Form.Item>
                    :
                    <Form.Item
                        name="superMerchantCode"
                        label="所属机构"
                        colon={false}
                        rules={[{ required: false, message: 'Please input your username!' }]}
                    >
                        <Select placeholder="请选择"
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            style={{ width: 250 }}
                            onChange={(code) => {
                            }}
                            allowClear
                            optionFilterProp="children"
                            showSearch
                            filterOption={(input, option) => option.children.includes(input)}
                            disabled={props.data && props.data.detail}
                        >

                            {
                                merchantList && merchantList.map((v) => {
                                    return <Option rowKey={v.superMerchantCode} key={v.superMerchantCode} value={v.superMerchantCode}>{v.superMerchantName}</Option>
                                })
                            }

                        </Select>
                    </Form.Item>
                    }
                    <Form.Item
                        name="cashType"
                        label="收单模式"
                        colon={false}
                        rules={[{ required: false, message: 'Please input your username!' }]}
                    >
                        <Select
                            placeholder="请选择"
                            style={{ width: 250 }}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.includes(input)}
                            disabled={props.data && props.data.detail}
                        >
                            <Option key={0} value={0}>门店收单</Option>
                            <Option key={1} value={1}>总部统收</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="purchaseType"
                        label="采购模式"
                        colon={false}
                        rules={[{ required: false, message: 'Please input your username!' }]}
                    >
                        <Select
                            placeholder="请选择"
                            style={{ width: 250 }}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.includes(input)}
                            disabled={props.data && props.data.detail}
                        >
                            <Option key={0} value={0}>总部统收</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="feeRate"
                        label="提现手续费"
                        colon={false}
                        rules={[{ required: false, message: 'Please input your username!' }]}
                    >
                        <Input
                            style={{ width: 250 }}
                            disabled={props.data && props.data.detail}
                            suffix="%" 
                        >
                        </Input>
                    </Form.Item>
                    <Form.Item
                        name="withdrawStartAmt"
                        label="起提金额"
                        colon={false}
                        rules={[{ required: false, message: 'Please input your username!' }]}
                    >
                        <Input
                            style={{ width: 250 }}
                            disabled={props.data && props.data.detail}
                        >
                        </Input>
                    </Form.Item>
                    <Form.Item
                        name="incomeRate"
                        label="入金手续费"
                        colon={false}
                        rules={[{ required: false, message: 'Please input your username!' }]}
                    >
                        <Input
                            style={{ width: 250 }}
                            disabled={props.data && props.data.detail}
                            suffix="%" 
                        >
                        </Input>
                    </Form.Item>
                    <Form.Item shouldUpdate style={{ textAlign: 'center' }}>
                        {
                            props.data && props.data.edit || props.data && !props.data.edit && props.data && !props.data.detail ?
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                >
                                    确认
                                </Button> : null
                        }

                        <Button
                            style={{ marginLeft: 20 }}
                            onClick={() => {
                                props.closeDialog();
                            }}
                        >
                            取消
                        </Button>
                    </Form.Item>

                </Form>
            </Row>
        </div>

    )
}
export default BillingDetails;
