import React, { useEffect } from 'react';
import {
    Table,
} from 'antd';



const StatementListDetail = (props) => {
    useEffect(() => {
    }, [])


    const columns = [
        {
            title: '类型',
            dataIndex: 'busiType',
            key: 'busiType',
        },
        // {
        //     title: '商品',
        //     dataIndex: 'merchantCode',
        //     key: 'merchantCode',
        // },
        {
            title: '收款方',
            dataIndex: 'payeeMerchantName',
            key: 'payeeMerchantName',
            render: (text, row) => {
                return (
                    <div>
                      {row.payeeMerchantName}/{row.payeeMerchantCode}
                    </div>
                )
            }
        },
        {
            title: '付款方',
            dataIndex: 'payMerchantName',
            key: 'payMerchantName',
            render: (text, row) => {
                return (
                    <div>
                      {row.payMerchantName}/{row.payMerchantCode}
                    </div>
                )
            }
        },
        {
            title: '支付方式',
            dataIndex: 'payType',
            key: 'payType',
            render: (text, row) => {
                return (
                    <div>
                        {row && row.payType == 0 ? '微信' : ''}
                        {row && row.payType == 1 ? '支付宝' : ''}
                        {row && row.payType == 2 ? '会员' : ''}
                        {row && row.payType == 3 ? '银行卡' : ''}
                        {row && row.payType == 4 ? '现金' : ''}
                        {row && row.payType == 5 ? '云闪付' : ''}
                        {row && row.payType == 6 ? '漕河泾(饿了么)' : ''}
                        {row && row.payType == 7 ? '组合支付' : ''}
                        {row && row.payType == 17 ? '翼支付' : ''}

                    </div>
                )
            }
        },
        {
            title: '金额(元)',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: '渠道',
            dataIndex: 'orderSourceName',
            key: 'orderSourceName',
        },

    ];
    const columns1 = [
        {
            title: '单号',
            dataIndex: 'orderNo',
            key: 'orderNo',
            render: (text, row) => {
                return (
                    <div>
                        <div>
                            业务单号：
                        </div>
                        <div>
                            {row && row.orderNo}
                        </div>
                        <div>
                            支付流水单号：
                        </div>
                        <div>
                            {row && row.thirdTradeNo}
                        </div>
                        <div>
                            结算单号：
                        </div>
                        <div>
                            {row && row.logOrderNo}
                        </div>
                    </div>
                )
            }
        },
        {
            title: '进度',
            dataIndex: 'tradeTime',
            key: 'tradeTime',
            render: (text, row) => {
                return (
                    <div>入账时间:{text}</div>
                )
            }
        },
        {
            title: '财务(元)',
            dataIndex: 'settleDay',
            key: 'settleDay',
            render: (text, row) => {
                return (
                    <div>
                        <div>
                            账务主体:{row && row.merchantName}
                        </div>
                        <>
                            <>
                                {row.type == '0' ?
                                    <div>
                                        {row.payeeAccountType == 0 ? '店铺余额待结算:' : ''}
                                        {row.payeeAccountType == 1 ? '可用账户:' : ''}
                                        {row.payeeAccountType == 2 ? '钱包账户:' : ''}
                                        {row.payeeAccountType == 3 ? '信用可提现账户:' : ''}
                                        {row.payeeAccountType == 4 ? '钱包余额待结算:' : ''}
                                        {row.payeeAccountType == 5 ? '收益账户:' : ''}
                                        {row.payeeAccountType == 6 ? '授信账户:' : ''}
                                        {row && row.type == 0 ? '收入' : '支出'}
                                        {row && row.amount}
                                    </div>
                                    :
                                    <div>
                                        {row.accountType == 0 ? '店铺余额待结算:' : ''}
                                        {row.accountType == 1 ? '可用账户:' : ''}
                                        {row.accountType == 2 ? '钱包账户:' : ''}
                                        {row.accountType == 3 ? '信用可提现账户:' : ''}
                                        {row.accountType == 4 ? '钱包余额待结算:' : ''}
                                        {row.accountType == 5 ? '收益账户:' : ''}
                                        {row.accountType == 6 ? '授信账户:' : ''}
                                        {row && row.type == 0 ? '收入' : '支出'}
                                        {row && row.amount}
                                    </div>
                                }
                            </>
                        </>
                        {/* <div>
                            {row && row.accountType == 0 ? '店铺余额待结算: ' : ''}
                            {row && row.accountType == 1 ? '可用账户: ' : ''}
                            {row && row.accountType == 2 ? '钱包账户: ' : ''}
                            {row && row.accountType == 3 ? '信用可提现账户: ' : ''}
                            {row && row.accountType == 4 ? '钱包余额待结算: ' : ''}
                            {row && row.accountType == 5 ? '收益账户: ' : ''}
                            {row && row.accountType == 6 ? '授信账户: ' : ''}
                            {row && row.type == 0 ? '收入' : '支出'}
                            {row && row.amount}
                        </div> */}

                    </div>
                )
            }
        },

    ];
    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            <Table
                // loading={loading}
                // style={{ marginTop: 20 }}
                columns={columns}
                dataSource={!!props && [props.data]}
                rowKey="id"
                pagination={false}
                locale={{
                    emptyText: "没有更多数据了"
                }}
            />

            <Table
                // loading={loading}
                style={{ marginTop: 20 }}
                columns={columns1}
                dataSource={!!props && [props.data]}
                rowKey="id"
                pagination={false}
                locale={{
                    emptyText: "没有更多数据了"
                }}
            />
        </div>
    )
}
export default StatementListDetail;
