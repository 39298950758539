import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import {
    Form,
    Row,
    Col,
    Button,
    Table,
    Modal,
} from 'antd';
import { expenseOrderDetailRequest, listByOrderNoRequest } from '../../../api/api'
import './style.less'
const CostDetail = (props) => {
    useEffect(() => {
        console.log(props);
    }, [])

    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            {
                props && props.data.map((v) => {
                    console.log(v)
                    return <div style={{ textAlign: 'center', fontWeight: 600, fontSize: 16 }}>{v}</div>
                })
            }

        </div>
    )
}

export default CostDetail;