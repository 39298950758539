import React, { useState, useEffect, useReducer } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import {
    Form,
    Row,
    Col,
    Button,
    Input,
    Select,
    Table,
    Popconfirm,
    DatePicker,
    Modal,
    message
} from 'antd';
import { getBusiListExportRequest, exportListRequest, checkInRequest } from '../../api/api'
import { useCallbackState } from '../../util/useCallbackState'
import { downFileWithBlob } from '../../util/tools'
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};
const { RangePicker } = DatePicker;
const ExportList = (data) => {
    const [form] = Form.useForm();
    const { state } = useLocation();
    const [list, setList] = useState([])
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [total, setTotal] = useState()
    const [mySwitch, setMySwitch] = useState(state && state.tag == 1 ? true : localStorage.state == "1" ? true : false)
    const superMerchantCode = localStorage.appid;
    const [busiList, setBusiList] = useState([])
    const { search } = useLocation()
    const navigate = useNavigate()
    const forceUpdate = useReducer((bool) => !bool)[1]
    useEffect(() => {
        if (mySwitch) {
            getBusiListExport()
            getExportList({ type: data.type, merchantCode: data.merchantCode })
        } else {
            checkIn()
        }

    }, [currentPage, pageNum, mySwitch])
    const columns = [
        {
            title: '报表归属',
            dataIndex: 'name',
            key: 'name',
            width: 150
        },
        {
            title: '时间区间',
            dataIndex: 'dateZone',
            key: 'dateZone',
        },

        {
            title: '申请时间',
            dataIndex: 'exportTime',
            key: 'exportTime',
            width: 250,
        },
        {
            title: '任务状态',
            dataIndex: 'statusName',
            key: 'statusName',
            width: 150
        },
        {
            title: '操作人',
            dataIndex: 'operatorName',
            key: 'operatorName',
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 150,
            align: 'right',
            render: (text, row) => (
                <>
                    {
                        row.statusName == '成功' ?
                            <span style={{ color: '#165BD4', cursor: 'pointer' }} onClick={() => {
                                window.open(row.downloadUrl)
                            }}>下载</span> : null
                    }
                </>
            ),

        },
    ];

    const checkIn = () => {
        (async _ => {
            return await checkInRequest(search.split('=')[1]);
        })().then(res => {
            if (res.code == 0) {
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('merchantCode', res.data.merchantCode);
                localStorage.setItem('merchantName', res.data.merchantName);
                localStorage.setItem('merchantId', res.data.merchantId);
                localStorage.setItem('username', res.data.username);
                localStorage.setItem('appid', res.data.superMerchantCode);
                localStorage.setItem('partnerid', res.data.partnerId);
                localStorage.setItem('operatorname', res.data.operatorName);
                localStorage.setItem('operatorId', res.data.operatorId); localStorage.setItem('salesEmployeeId', (res.data.salesEmployeeId));
                localStorage.setItem('hxState', (res.data.superMerchantCode === res.data.merchantCode));
                setMySwitch(true)
            } else {
                message.error('用户失效，请重新登录！')
            }
        });
    }




    const getExportList = (values) => {
        let params = {
            ...values,
            currentPage,
            pageSize: pageNum,
            merchantCode: localStorage.merchantCode,
            superMerchantCode: localStorage.appid,
        };
        (async _ => {
            return await exportListRequest(params);
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setList(res.data.lists)
                setTotal(res.data.totalCount)
            } else {
                message.error(res.msg)
            }
        });
    }

    const getBusiListExport = () => {
        (async _ => {
            return await getBusiListExportRequest();
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setBusiList(res.data)
            } else {
                message.error(res.msg)
            }
        });
    }
    const onChangPage = (currentPage, pageNum) => {
        setCurrentPage(currentPage, () => {
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        setPageNum((currentPage, pageNum), () => {
        })
    }
    const onFinish = (values) => {
        getExportList(values)
    };



    return (
        <div style={{ background: '#ffffff', paddingTop: 1, marginTop: 16 }}>
            <Table
                locale={{
                    emptyText: "没有更多数据了"
                }}
                rowKey='id'
                style={{ marginTop: 16, margin: '0 16px' }}
                columns={columns}
                dataSource={list}
                scroll={{
                    x: 1400,
                }}
                pagination={{
                    total,
                    showSizeChanger: true,
                    current: currentPage,
                    pageSize: pageNum,
                    // position: ["bottomCenter"],
                    showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                    onChange: (k, v) => onChangPage(k, v),
                    onShowSizeChange: (k, v) => onChangPageNum(k, v),
                }}
            />

        </div >
    )
}
export default ExportList;