import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  Form,
  Input,
  Switch,
  Row,
  Col,
  Button,
  Tabs,
  Table,
  Modal,
  Radio,
  Space,
  message
} from 'antd';
import { creditViewRequest, creditAccountRepayRequest, balanceViewRequest } from '../../../api/api.js'


const { TabPane } = Tabs;
const FormItem = Form.Item;
const Search = Input.Search;



const StoreValue = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [radioValue, setRadioValue] = useState(1);
  const [list, setList] = useState([])
  const [data, setData] = useState(null)
  const [yue, setYue] = useState(null)
  const [num, setNum] = useState(null)
  const [status, setStatus] = useState(false)
  const [mySwitch, setMySwitch] = useState(localStorage.state == "1" ? true:false)
  let navigate = useNavigate();
  const { state } = useLocation()
  const { search } = useLocation()
  useEffect(() => {
      getCreditView()
  }, [])

  const onChange = (checked) => {
    getCreditView({ busiType: checked });
  };
  const getCreditView = (data) => {
    (async _ => {
      return await creditViewRequest(
        {
          ...data,
          merchantCode: state && state.merchantCode ? state.merchantCode : localStorage.merchantCode,
          superMerchantCode: localStorage.appid,
          pageSize: 10,
          currentPage: 1,
        }
      );
    })().then(res => {
      if (res.code == 0) {
        // navigate('/ws/tradeSetting/list')
        setList(res.data.list)
        setData(res.data)
      } else {
        message.error(res.msg)
      }
    });
  }
  const balanceAccountView = () => {

    (async _ => {
      return await balanceViewRequest(
        {
          merchantCode: state && state.merchantCode,
          superMerchantCode: localStorage.appid,

        }
      );
    })().then(res => {
      if (res.code == 0) {
        // navigate('/ws/tradeSetting/list')
        setYue(res.data)
      } else {
        message.error(res.msg)
      }
    });
  }
  const columns = [
    {
      title: '时间',
      dataIndex: 'createTime',
      key: 'createTime',
    },
    {
      title: '订单类型',
      dataIndex: 'busiType',
      key: 'busiType',
    },
    {
      title: '订单编号｜支付单号',
      dataIndex: 'orderNo',
      key: 'orderNo',
      render: (text, row) => {
        return (
          <div>
            <div>{row.orderNo}</div>
            <div style={{ color: '#969799' }}>{row.thirdTradeNo}</div>
          </div>
        )
      }
    },
    {
      title: '金额(元)',
      dataIndex: 'amount',
      key: 'amount',
      // render: (text, row) => {
      //   return (
      //     <div>{row.amount}</div>
      //   )
      // }
    },
    {
      title: '状态',
      dataIndex: 'amount',
      key: 'amount',
      render: () => {
        return <div>完成</div>
      }
    },
    {
      title: '操作',
      key: 'action',
      align: 'right',
      render: (text, row) => (
        <>
          <Button type='link' onClick={() => {
            navigate('/ws/creditAccount/billingDetails', { replace: false, state: row })
          }}>查看</Button>
          {/* <Link style={{ marginLeft: 10 }} to="/ws/creditAccount/billingDetails">查看</Link> */}
        </>

      ),
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
    balanceAccountView();
    setNum(null)
  };

  const handleOk = () => {

    (async _ => {
      return await creditAccountRepayRequest(
        {
          merchantCode: state.merchantCode,
          superMerchantCode: localStorage.appid,
          appId: localStorage.appid,
          repayType: 0,
          totalAmount: num,
          sign: '12',
          random: '121',
        }
      );
    })().then(res => {
      if (res.code == 0) {
        getCreditView()
        setIsModalVisible(false);
        message.success(res.msg)
      } else {
        message.error(res.msg)
      }
    });

  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const radioChange = (e) => {
    setRadioValue(e.target.value);
  };
  return (
    <div style={{ height: '100vh', background: '#ffffff', paddingBottom: 20 }}>
      <Row align='middle' style={{ padding: 16, marginTop: 10 }}>
        <Row align='middle' style={{ padding: '10px 10px', width: '100%', height: '100%', background: '#F7F8FA' }}>
          <div style={{ background: '#145BD5', height: 14, width: 3, }}></div><div style={{ marginLeft: 10, fontWeight: 500 }}>授信余额</div>
        </Row>
      </Row>
      <Row justify='space-between' style={{ background: '#F7F8FA', padding: 20, margin: 16 }}>
        <Col span={8}>
          <div>
            <span>授信额度(元)</span>

          </div>
          <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{data && data.creditBalance}</div>
        </Col>
        <Col span={8}>
          <div><span>可用额度(元)</span></div>
          <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{data && data.availableBalance}</div>
        </Col>
        <Col span={8}>
          <div><span>待还款金额(元)</span>
            <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 20 }}
              onClick={() => {
                navigate('/ws/creditAccount/historyBill', { replace: false, state: { merchantCode: state && state.merchantCode ? state.merchantCode : localStorage.merchantCode } })
              }}
            >账单记录</span>
            <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 20 }}
              onClick={() => {
                navigate('/ws/statementList', { replace: false, state: { merchantCode: state && state.merchantCode ? state.merchantCode : localStorage.merchantCode } })
              }}
            >还款记录</span>
          </div>
          <Row align='middle' style={{ marginTop: 10 }}>
            <span style={{ fontSize: 24, fontWeight: 500 }}>{data && data.repaidBalance}</span>
            <Button style={{ marginLeft: 20 }} type="primary" onClick={showModal}>还款</Button>
          </Row>

        </Col>
      </Row>
      <Row align='middle' style={{ padding: 16, paddingTop: 0 }}>
        <Row align='middle' justify='space-between' style={{ padding: '10px 10px', width: '100%', height: '100%', background: '#F7F8FA' }}>
          <Row align='middle' >
            <div style={{ background: '#145BD5', height: 14, width: 3, }}></div><div style={{ marginLeft: 10, fontWeight: 500 }}>最近收支记录</div>
          </Row>

          <Col>
            <Button type='link'
              onClick={() => {
                navigate('/ws/statementList', { replace: false, state, })
              }}
            >查看收支交易</Button>
          </Col>
        </Row>
      </Row>
      <Row style={{ padding: 16 }}>
        <Tabs onChange={onChange} type="card" defaultActiveKey="">
          <TabPane tab="全部" key="">
          </TabPane>
          {/* <TabPane tab="交易完成" key="2">
          </TabPane> */}
          <TabPane tab="退款" key="5">
          </TabPane>
          <TabPane tab="手动还款" key="3">
          </TabPane>
          <TabPane tab="自动还款" key="4">
          </TabPane>
        </Tabs>
      </Row>
      <Row style={{ padding: "0px 10px" }}>
        <Col span={24}>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={list}
            pagination={false}
          />
        </Col>

      </Row>
      <Modal title="选择还款方式" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Radio.Group onChange={radioChange} value={radioValue}>
          <Radio value={1}><span>可用店铺余额(¥{yue && yue.availableBalance})</span></Radio>
        </Radio.Group>
        <div>
          <Input
            placeholder={`待还款金额${data && data.repaidBalance}`}
            style={{ width: 180, marginLeft: 24, marginTop: 10 }}
            value={num}
            onChange={(e) => {
              setNum(e.target.value)
              if (e.target.value > yue.availableBalance) {
                setStatus(true)
              } else {
                setStatus(false)
              }
            }}
          />
          <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 20 }}
            onClick={() => {
              setNum(data && data.repaidBalance)
            }}
          >全部</span>
          {
            status ? <span style={{ color: 'red', marginLeft: 20 }}>余额不足</span> : ''
          }

        </div>


        {/* <div>图片</div> */}
      </Modal>
    </div >

  )
}
export default StoreValue;
