
import { Button, Checkbox, Form, Input, message, Carousel, Tabs, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { loginRequest } from '../../api/api'
import './style.less';
import One from '../../images/1.png'
import Two from '../../images/2.png'
import Three from '../../images/3.png'
import open1 from '../../images/111.png'
import open2 from '../../images/222.png'
import open3 from '../../images/333.png'
import open4 from '../../images/444.png'
const Login = () => {
    const [value, setValue] = useState("0")
    const [status1, setStatus1] = useState(true)
    const [status2, setStatus2] = useState(false)
    const [status3, setStatus3] = useState(false)
    const [status4, setStatus4] = useState(false)
    const [check, setCheck] = useState(false)
    const navigate = useNavigate();
    const carouselRef = React.createRef();
    useEffect(() => {
        localStorage.clear();
    }, [])
    const onFinish = (values) => {
        if (check) {
            login(values)
        } else {
            message.error('请先同意用户协议再登录')
        }

    };



    const contentStyle = {
        margin: 0,
        height: '640px',
        color: '#fff',
        lineHeight: '640px',
        textAlign: 'center',
        background: '#364d79',
    };
    const login = (values) => {
        (async _ => {
            return await loginRequest(values);
        })().then(res => {

            localStorage.clear();
            if (res.code == 0) {
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('merchantCode', res.data.merchantCode);
                localStorage.setItem('merchantName', res.data.merchantName);
                localStorage.setItem('merchantId', res.data.merchantId);
                localStorage.setItem('username', res.data.username);
                localStorage.setItem('appid', res.data.superMerchantCode);
                localStorage.setItem('partnerid', res.data.partnerId);
                localStorage.setItem('operatorname', res.data.operatorName);
                localStorage.setItem('operatorId', res.data.operatorId);
                localStorage.setItem('salesEmployeeId', (res.data.salesEmployeeId));
                localStorage.setItem('hxState', (res.data.superMerchantCode === res.data.merchantCode));
                localStorage.setItem('state', 1)
                if (res.data.salesEmployeeId) {
                    navigate('/ws/balance/withdrawal')
                } else {
                    navigate('/ws')
                }

                // <Navigate to={} />
            } else {
                message.error(res.msg)
            }
        });
    }

    const beforeChange = (from, to) => {
        // console.log(to);
        setValue((to).toString())
    }
    return (
        <div className='login'>
            <div className='mainBox'>
                <div className='loginmain'>
                    <div className='login_left'>
                        <Carousel autoplay
                            beforeChange={beforeChange}
                            ref={carouselRef}
                            autoplaySpeed={5000}
                            effect="fade"
                        >
                            <div style={contentStyle} className='reb'>
                                <img src={One} style={{ width: '1200px', height: '640px' }}></img>
                                <div className='abs'>
                                    <div>企业钱包服务</div>
                                    <div className='jiacu'>商家专属资金平台</div>
                                    <div className='smallSize'>增长新收益，助力好生意</div>
                                </div>
                            </div>
                            <div style={contentStyle} className='reb'>
                                <img src={Two} style={{ width: '1200px', height: '640px' }}></img>
                                <div className='abs'>
                                    <div>企业钱包服务</div>
                                    <div className='jiacu'>商家专属资金平台</div>
                                    <div className='smallSize'>增长新收益，助力好生意</div>
                                </div>
                            </div>
                            <div style={contentStyle} className='reb'>
                                <img src={Three} style={{ width: '1200px', height: '640px' }}></img>
                                <div className='abs'>
                                    <div>企业钱包服务</div>
                                    <div className='jiacu'>商家专属资金平台</div>
                                    <div className='smallSize'>增长新收益，助力好生意</div>
                                </div>
                            </div>
                        </Carousel>
                        <div style={{ width: 600 }} className="tabsClass">
                            <Tabs
                                size="large"
                                activeKey={value}
                                tabBarGutter={100}
                                onChange={(e) => {
                                    setValue(e)
                                    carouselRef.current.goTo(e);
                                }}>
                                <Tabs.TabPane tab="资金平台" key="0">
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="资金账户" key="1">
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="资金清算" key="2">
                                </Tabs.TabPane>
                            </Tabs>
                        </div>
                    </div>
                    <div className='login_right'>

                        <Form
                            name="basic"

                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <Form.Item
                                label=" "
                                colon={false}
                                noStyle={true}
                            >
                                <h1>登录企业钱包</h1>
                                <p>已入驻商户可立即登录</p>
                            </Form.Item >
                            <div style={{ marginTop: 33 }}>
                                <Form.Item
                                    label=" "
                                    name="userName"
                                    colon={false}

                                    noStyle={true}
                                    rules={[
                                        {
                                            required: false,
                                            message: '请输入用户名称!',
                                        },
                                    ]}
                                >
                                    <Input style={{ width: 318, borderRadius: 5, height: 42 }} placeholder="用户名" />
                                </Form.Item >
                            </div>
                            <div style={{ marginTop: 16 }}>
                                <Form.Item
                                    label=" "
                                    colon={false}
                                    name="passWord"
                                    noStyle={true}
                                    // style={{ marginTop: '5vh' }}
                                    rules={[
                                        {
                                            required: false,
                                            message: '请输入密码!',
                                        },
                                    ]}
                                >
                                    <Input.Password style={{ width: 318, borderRadius: 5, height: 42 }} placeholder="密码" />
                                </Form.Item>
                            </div>
                            <div style={{ marginTop: 54 }}>
                                <Form.Item
                                    label=" "
                                    colon={false}
                                    noStyle={true}
                                // style={{ marginTop: '8vh' }}
                                >
                                    <Button type="primary" htmlType="submit" className='btn' >
                                        登录
                                    </Button>
                                </Form.Item>
                            </div>
                            <div style={{ fontSize: 14, textAlign: 'center', marginTop: 17 }}><span>没有账号?</span><span style={{ color: '#155BD4' }}>立即入驻</span></div>
                            <div style={{ fontSize: 14, textAlign: 'center', marginTop: 32, color: '#B5B8BC' }}>
                                <Checkbox onChange={(e) => {
                                    console.log(e);
                                    setCheck(e.target.checked)
                                }}></Checkbox> <span onClick={() => {
                                    navigate('/ws/agreeOn')
                                }}><span>已阅读并同意</span><span style={{ color: '#155BD4', marginLeft: 4, marginRight: 4 }}>用户协议</span>和<span style={{ color: '#155BD4', marginLeft: 4 }}>隐私条款</span><span style={{ marginLeft: 4 }}>无法登录？</span></span>
                            </div>
                        </Form >
                    </div>
                </div>
            </div>

            <div className='loginmain1'>
                <div className='smallMain'>
                    <div className='smallMain1'>
                        <div>
                            各行各业
                        </div>
                        <div>
                            都在用企业钱包
                        </div>
                    </div>
                    <div className='smallMain2' onMouseEnter={() => {
                        setStatus1(true)
                        setStatus2(false)
                        setStatus3(false)
                        setStatus4(false)
                    }}>
                        {
                            status1 ?
                                <div className='open'>
                                    <div className='open_left'>
                                        <div className='fontWeight'>零售</div>
                                        <div className='grey'>
                                            <span>连锁品牌</span>
                                            <span className='marginLeft' style={{ lineHeight: 3 }}>便利店</span>
                                            <span className='marginLeft'>商超</span>
                                        </div>
                                        <div>查看详情</div>
                                    </div>
                                    <div className='open_right'>
                                        <img className='openImg' src={open1}></img>
                                    </div>
                                </div> :
                                <div className='close1'>
                                    <div>零</div>
                                    <div>售</div>
                                </div>
                        }


                    </div>
                    <div className='smallMain3' onMouseEnter={() => {
                        setStatus1(false)
                        setStatus2(true)
                        setStatus3(false)
                        setStatus4(false)
                    }}>
                        {
                            status2 ?
                                <div className='open'>
                                    <div className='open_left'>
                                        <div className='fontWeight'>餐饮</div>
                                        <div className='grey'>
                                            <span>连锁品牌</span>
                                            <span className='marginLeft' style={{ lineHeight: 3 }}>中餐</span>
                                            <span className='marginLeft'>快餐</span>
                                        </div>
                                        <div>查看详情</div>
                                    </div>
                                    <div className='open_right'>
                                        <img className='openImg' src={open2}></img>
                                    </div>
                                </div> :
                                <div className='close2'>
                                    <div>餐</div>
                                    <div>饮</div>
                                </div>
                        }


                    </div>
                    <div className='smallMain4'
                        onMouseEnter={() => {
                            setStatus1(false)
                            setStatus2(false)
                            setStatus3(true)
                            setStatus4(false)
                        }}>
                        {
                            status3 ?
                                <div className='open'>
                                    <div className='open_left'>
                                        <div className='fontWeight'>综合</div>
                                        <div className='grey'>
                                            <span>MCN</span>
                                            <span className='marginLeft' style={{ lineHeight: 3 }}>商场</span>
                                            <span className='marginLeft'>运动健身</span>
                                        </div>
                                        <div>查看详情</div>
                                    </div>
                                    <div className='open_right'>
                                        <img className='openImg' src={open3}></img>
                                    </div>
                                </div> :
                                <div className='close3'>
                                    <div>综</div>
                                    <div>合</div>
                                </div>
                        }


                    </div>
                    <div className='smallMain5'
                        onMouseEnter={() => {
                            setStatus1(false)
                            setStatus2(false)
                            setStatus3(false)
                            setStatus4(true)
                        }}
                    >
                        {
                            status4 ?
                                <div className='open'>
                                    <div className='open_left'>
                                        <div className='fontWeight'>酒旅</div>
                                        <div className='grey'>
                                            <span>酒店</span>
                                            <span className='marginLeft' style={{ lineHeight: 3 }}>民宿</span>
                                            <span className='marginLeft'>景点</span>
                                            <span className='marginLeft'>展馆</span>
                                        </div>
                                        <div>查看详情</div>
                                    </div>
                                    <div className='open_right'>
                                        <img className='openImg' src={open4}></img>
                                    </div>
                                </div> :
                                <div className='close4'>
                                    <div>酒</div>
                                    <div>旅</div>
                                </div>
                        }


                    </div>
                </div>
            </div>
        </div >


    );
};

export default Login;