import React, { useState, useEffect, useReducer } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import {
    Form,
    Row,
    Col,
    Button,
    Input,
    Select,
    Table,
    Popconfirm,
    DatePicker,
    Modal,
    message,
    Cascader,
    Dropdown, Space
} from 'antd';
import { shopManageFeeListRequest, shopAreaListRequest1, shopAreaListAllRequest } from '../../api/api'
import { useCallbackState } from '../../util/useCallbackState'
import QRCode from 'qrcode.react';
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};
const { RangePicker } = DatePicker;
const ManagementFee = () => {
    const [form] = Form.useForm();
    const { state } = useLocation();
    const [typeList, setTypeList] = useState([])
    const [list, setList] = useState([])
    const [merchantCode, setMerchantCode] = useCallbackState(state && state.merchantCode ? state.merchantCode : localStorage.merchantCode)
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [total, setTotal] = useState()
    const { search } = useLocation()
    const navigate = useNavigate()
    const [options, setOptions] = useState([])
    const [area, setArea] = useState([])
    const [jigou, setJigou] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [mianqian, setMianqian] = useState(false)
    const [qrcode, setQrcode] = useState(undefined)
    const [qrcodeState, setQrcodeState] = useState(false)
    const [loading, setLoading] = useState(false)
    const forceUpdate = useReducer((bool) => !bool)[1]
    useEffect(() => {
            shopManageFeeList()
            shopAreaList()

    }, [currentPage, pageNum])
    const columns = [
        {
            title: '类型',
            dataIndex: 'manageMerchantType',
            key: 'manageMerchantType',
            render: (text, row) => (
                <div>
                    {row.manageMerchantType == 0 ? '良记订货门店' : ''}
                    {row.manageMerchantType == 1 ? '良记会员门店' : ''}
                </div>
            ),
        },
        {
            title: '区域',
            dataIndex: 'merchantArea',
            key: 'merchantArea',
        },
        {
            title: '机构组',
            dataIndex: 'orderNo',
            key: 'orderNo',
            width: 250,
            render: (text, row) => (
                <div>
                    {
                        row.merchantGroup.map((v, index) => {
                            return <div>{v.merchantName}</div>
                        })
                    }
                </div>
            ),
        },
        {
            title: '总部管理费',
            dataIndex: 'manageAmount',
            key: 'manageAmount',
        },
        {
            title: '分账机构',
            dataIndex: 'manageAmounts',
            key: 'manageAmounts',
            // ellipsis: true,
            render: (text, row) => {
                return (
                    <div>
                        {
                            row.manageAmounts.map((v, index) => {
                                return <span>{v.manageAmount}{row.manageAmounts.length - 1 > index ? '/' : ''}</span>
                            })
                        }
                        {row.manageAmounts.length > 0 ? <a onClick={(e) => {
                            setJigou(row.manageAmounts)
                            setMianqian(true)
                            // showModal()
                        }}>查看</a> : null}
                    </div>

                )
            }
        },
        {
            title: '采购货款(最小金额)',
            dataIndex: 'purchaseAmount',
            key: 'purchaseAmount',
        },
        {
            title: '采购货款返佣',
            dataIndex: 'purchaseTaxRate',
            key: 'purchaseTaxRate',
            render: (text, row) => {
                return (
                    <div>
                        {text}%
                    </div>
                )
            },
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (text, row) => {
                console.log(row);
                return (
                    <div>
                        {row.status == 0 ? '进行中' : ''}
                        {row.status == 1 ? '已停用' : ''}
                    </div>
                )
            },
        },
        {
            title: '未/已支付',
            dataIndex: 'payOrderRate',
            key: 'payOrderRate',
        },
        {
            title: '操作人',
            dataIndex: 'operatorName',
            key: 'operatorName',
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 150,
            render: (text, row) => (
                <>
                    <div>
                        <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }} onClick={() => {
                            navigate('/ws/managementFee/detail', { replace: false, state: row })
                            // navigate('/ws/statementList/statementListdetail', { replace: false, state: row })
                        }}>详情</span>
                        <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }} onClick={() => {
                            // navigate('/ws/statementList/statementListdetail', { replace: false, state: row })
                            row.edit = 2
                            navigate('/ws/managementFee/add', { replace: false, state: row })
                        }}>编辑</span>
                    </div>
                    <div>
                        <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }} onClick={() => {
                            navigate('/ws/managementFeeList', { replace: false, state: row })
                        }} >数据</span>
                        {/* <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }} onClick={() => {
                            // setQrcode(`https://preclubb2b.liantuofu.com/aa/?&appId=${row.superMerchantCode}&operatorId=${row.operatorId}&id=${row.id}&partnerMerchantCode=${row.merchantCode}`)
                            setQrcode(`https://clubb2b.liantuofu.com/share/?&appId=${row.superMerchantCode}&operatorId=${row.operatorId}&id=${row.id}&pcode=${row.superMerchantCode}`)
                            setQrcodeState(true)
                        }} >分享</span> */}
                    </div>
                </>
            ),
        },
    ];


    const mianqianHandleCancel = () => {
        setMianqian(false);
    };
    const qrcodeStateCancel = () => {
        setQrcodeState(false)
    }

    const shopAreaList = (code) => {
        (async _ => {
            return await shopAreaListAllRequest();
        })().then(res => {
            if (res.code == "SUCCESS") {
                res.result.map((v) => {
                    v.label = v.provinceName
                    v.value = v.provinceCode
                    v.children = v.cities.map((i) => {
                        return {
                            label: i.cityName,
                            value: i.cityCode,
                            id: i.id
                        }
                    })
                })
                setOptions(res.result)
            } else {
                message.error(res.msg)
            }
        });
    }




    const shopManageFeeList = (data) => {
        setLoading(true);
        let values = form.getFieldValue();
        (async _ => {
            return await shopManageFeeListRequest({
                merchantCode: localStorage.merchantCode,
                superMerchantCode: localStorage.appid,
                currentPage,
                pageSize: pageNum,
                ...data,
                ...values,
            });
        })().then(res => {
            if (res.code == "SUCCESS") {
                setList(res.result.items)
                setTotal(res.result.totalCount)
                setLoading(false);
            } else {
                setLoading(false);
                message.error(res.msg)
            }
        });
    }

    const onChangPage = (currentPage, pageNum) => {
        setCurrentPage(currentPage, () => {
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        setPageNum((currentPage, pageNum), () => {
        })
    }
    const onFinish = (values) => {
        values.currentPage = 1;
        values.cityName = area.length > 0 ? area[1].label : undefined;
        values.cityCode = area.length > 0 ? area[1].value : undefined;
        values.merchantArea = (area.length > 0 ? area[0].provinceName : undefined) + (area.length > 0 ? area[1].label : undefined);
        shopManageFeeList(values);

    };

    const add = () => {
        let row = {
            edit: 0
        }
        navigate('/ws/managementFee/add', { replace: false, state: row })
    }
    const areaChange = (value, selectedOptions) => {
        setArea(selectedOptions)


    };
    const reset = () => {
        form.setFieldsValue({
            manageMerchantType: undefined,
            status: undefined,
            accountType: undefined,
        })
        setArea([])
        let values = form.getFieldValue()
        values.currentPage = 1;
        console.log(values);
        shopManageFeeList(values)
    }
    return (
        <div style={{ background: '#ffffff', paddingTop: 1, padding: 16, marginTop: 16 }}>
            <Row>
                <Button type='primary' onClick={add}>新建</Button>
            </Row>
            <Form
                form={form}
                {...layout}
                name="advanced_search"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                style={{ background: '#F7F8FA', marginTop: 16, padding: 16, paddingBottom: 0, marginBottom: 16 }}
                initialValues={{
                }}
            >

                <Row style={{ marginBottom: '-1vh' }}>

                    <Col span={6}>
                        <Form.Item
                            name="manageMerchantType"
                            label="类型："
                        >

                            <Select placeholder="请选择"
                                style={{ width: 180 }}
                                allowClear
                                onChange={(code) => {
                                }}
                            >
                                <Option value={0} key={0}>良记订货门店</Option>
                                <Option value={1} key={1}>良记会员门店</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="status"
                            label="单据状态"
                        >

                            <Select placeholder="请选择"
                                style={{ width: 180 }}
                                allowClear
                            >
                                <Option rowKey={null} key={null} value={null}>全部</Option>
                                <Option rowKey={0} key={0} value={0}>进行中</Option>
                                <Option rowKey={1} key={1} value={1}>已停用</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6} >
                        <Form.Item
                            name="accountType"
                            label="区域"
                        >
                            <Cascader options={options}
                                onChange={areaChange} placeholder="请选择"
                                style={{ width: 180 }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '-1vh' }}>
                    <Col span={6}>
                        <Form.Item
                            wrapperCol={{
                                offset: 6,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                筛选
                            </Button>
                            <Button type="link" onClick={() => {
                                reset();
                            }} >
                                重置筛选条件
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
            <Table
                loading={loading}
                locale={{
                    emptyText: "没有更多数据了"
                }}
                rowKey='id'
                style={{ marginTop: 16 }}
                columns={columns}
                dataSource={list}
                scroll={{
                    x: 1600,
                }}
                pagination={{
                    total,
                    showSizeChanger: true,
                    current: currentPage,
                    pageSize: pageNum,
                    // position: ["bottomCenter"],
                    showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                    onChange: (k, v) => onChangPage(k, v),
                    onShowSizeChange: (k, v) => onChangPageNum(k, v),
                }}
            />
            <Modal
                width={300}
                title="机构组" visible={mianqian} onCancel={mianqianHandleCancel}
                footer={null}
            >
                {
                    jigou && jigou.map((v, index) => {
                        return (
                            <div>{v.splitMerchantName}:{v.manageAmount}</div>
                        )
                    })
                }

            </Modal>
            <Modal
                width={250}
                title="请扫描二维码" visible={qrcodeState} onCancel={qrcodeStateCancel}
                footer={null}
            >
                <QRCode
                    value={qrcode} //value参数为字符串类型
                    size={200} //二维码的宽高尺寸
                    fgColor="#000000"  //二维码的颜色
                />
            </Modal>
        </div >
    )
}
export default ManagementFee;