import React, { useEffect, useState, useReducer } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import {
    Form,
    Image,
    Input,
    Button,
    message,
    Row,
    Col,
    Typography,
    Select,
    InputNumber,
    Radio,
    Space,
    DatePicker
} from 'antd';
import { orderSaveRequest, settleMerchantListRequest, expenseOrderBusinessRequest } from '../../../api/api'
import { limitTowDecimals } from '../../../util/tools';
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};
const dateFormat = 'YYYY-MM-DD'
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;
const { Title } = Typography;
let children = []
for (let i = 1; i <= 31; i++) {
    children.push(<Option key={i}>{`${i}号`}</Option>);
}
const SubLedgerRule = (props) => {
    const [form] = Form.useForm()
    const [value, setValue] = useState(0);
    const [intervalAmount, setIntervalAmount] = useState([])
    const [radio, setRadio] = useState(0);
    const [radio1, setRadio1] = useState(0);
    const [shMerchantList, setShMerchantList] = useState([])
    const [checkCode, setCheckCode] = useState(0)
    const [datetype, setDateType] = useState('1');
    const [typeList, setTypeList] = useState([])
    const [typeNames, setTypeNames] = useState([])
    const [expireStartTime, setExpireStartTime] = useState(null)
    const [expireStartTimeStr, setExpireStartTimeStr] = useState(null)
    const [expireEndTime, setExpireEndTime] = useState(null)
    const [expireEndTimeStr, setExpireEndTimeStr] = useState(null)
    const [payeeMerchantNames, setPayeeMerchantNames] = useState([])
    const [typeChange, setTypeChange] = useState(0)
    let [count, setCount] = useState(1);
    let data = props ? props.data : null;
    useEffect(() => {
        console.log(props)
        expenseOrderBusiness();
        skSettleMerchantList()
    }, [])

    useEffect(() => {
        if (data && data.id) {

            form.setFieldsValue({
                shareType: data && Number(data.shareType),
                accountType: data && data.accountTypeErrorMsg ? undefined : props.accountType.toString(),
                businessTypeNosArry: data && data.businessTypeNosErrorMsg ? undefined : data && data.businessTypeNosArry,
                expenseReceiptType: data && data.expenseReceiptTypeErrorMsg ? undefined : (data && data.shareType == 1 ? 0 : data && Number(data.expenseReceiptType)),
                expensePaySectionJson: data && data.expensePaySectionJson,
                payeeMerchantCodesArry: data && data.payeeMerchantCodesErrorMsg ? undefined : data && data.payeeMerchantCodesArry,
                expireType: data && Number(data.expireType),
                shareMoneyType: data && data.shareMoneyType ? data.shareMoneyType : 0,
                settleType: data && data.settleType,
                settleValue: data && data.settleValue,

            })
            if (data && data.expireStartTimeErrorMsg) {

            } else {
                setExpireStartTime(data && data.expireStartTimeStr)
                setExpireStartTimeStr(data && data.expireStartTimeStr)
            }
            if (data && data.expireEndTimeErrorMsg) {

            } else {
                setExpireEndTime(data && data.expireEndTimeStr)
                setExpireEndTimeStr(data && data.expireEndTimeStr)
            }
            setValue(data && Number(data.expireType))
            setPayeeMerchantNames(data && data.payeeMerchantNames)
            setTypeNames(data && data.typeNames)
            setCheckCode(data && data.shareType)
            setRadio(data && data.expenseReceiptType)
            setIntervalAmount(JSON.parse(data && data.expensePaySectionJson))
            if (data && data.expenseReceiptType == 2 || data && data.expenseReceiptType == 3) {
                setCount(JSON.parse(data && data.expensePaySectionJson).length)

            }
        }

    }, [shMerchantList])
    useEffect(() => {
        if (data && data.costRuleNo) {
            form.setFieldsValue({
                businessTypeNosArry: data && data.businessTypeNosErrorMsg ? undefined : data && data.businessTypeNosArry,
            })
        }
    }, [typeList])

    const skSettleMerchantList = (code) => {
        (async _ => {
            return await settleMerchantListRequest({
                payerMerchantCodes: data && data.payerMerchantCodesArry.toString(),
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            if (res.code == 0) {
                // let index = res.data.findIndex((v) => { return v.merchantCode == localStorage.merchantCode })
                // res.data.splice(index, 1)
                setShMerchantList(res.data)
            } else {

            }
        });
    }
    const expenseOrderBusiness = () => {

        (async _ => {
            return await expenseOrderBusinessRequest({
                accountType: props && props.accountType,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setTypeList(res.data)
            } else {

            }
        });
    }
    const cancel = (status) => {
        props.closeDialog()
    }

    const onFinish = async () => {
        console.log(typeNames);
        // if (intervalAmount.length == 0) {
        //     console.log(111111)
        // };
        console.log(payeeMerchantNames);
        const values = await form.validateFields()  //2.表单验证并获取表单值
        console.log(values);
        values.expireStartTimeStr = expireStartTime;
        values.expireEndTimeStr = expireEndTime;
        values.expireStartTime = expireStartTimeStr;
        values.expireEndTime = expireEndTimeStr;
        values.typeNames = typeNames;
        values.payeeMerchantNames = payeeMerchantNames.toString();
        values.accountType = props && props.accountType;
        values.costRuleNo = data && data.costRuleNo ? data.costRuleNo : null
        values.costDetailNo = data && data.costDetailNo ? data.costDetailNo : null
        values.costDetailStatus = data && data.costDetailStatus

        values.payeeMerchantCodesArry = values.payeeMerchantCodesArry ? values.payeeMerchantCodesArry : data && data.payeeMerchantCodesArry
        values.id = data && data.id ? data.id : Math.random(10);
        if (values.expenseReceiptType == 0 || values.expenseReceiptType == 1) {
            values.expensePaySectionJson = values.expensePaySectionJson && values.expensePaySectionJson
        }
        if (values.expenseReceiptType == 2 || values.expenseReceiptType == 3) {
            console.log(intervalAmount);
            values.expensePaySectionJson = JSON.stringify(intervalAmount)
        }
        if (values.shareType == 1) {
            delete values.typeNames;
            delete values.businessTypeNosArry;
        }
        if (values.expenseReceiptType == 2 || values.expenseReceiptType == 3) {
            let ev = intervalAmount.every((v) => {
                return v.startPrice > 0 && v.endPrice > 0 && v.number > 0
            })
            if (!ev) {
                message.info('请填写单据金额')
                return;
            }
        }

        if (value == 0) {
            if (expireStartTime && expireEndTime) {
                props.callback(values)
                props.closeDialog()
            } else {
                message.info('请选择日期')
            }
        }
        if (value == 1) {
            if (expireStartTime) {
                props.callback(values)
                props.closeDialog()
            } else {
                message.info('请选择日期')
            }
        }
        if (value == 2) {
            if (expireStartTime) {
                props.callback(values)
                props.closeDialog()
            } else {
                message.info('请选择日期')
            }
        }
    }
    const getFields = () => {
        const children = [];
        for (let i = 0; i < count; i++) {
            children.push(
                <Col key={i}>
                    <Form.Item
                        name={`field-${i}`}
                        label={i == 0 ? '单据金额' : ' '}
                        colon={i == 0 ? true : false}
                    // rules={[{ required: true, message: '请输入转账金额!' }]}
                    >
                        <InputNumber
                            // prefix="￥"
                            placeholder='￥'
                            style={{ width: 100 }}
                            min={0.01}
                            formatter={limitTowDecimals}
                            parser={limitTowDecimals}
                            value={intervalAmount.length > 0 ? intervalAmount[i] && intervalAmount[i].startPrice : undefined}
                            onChange={(e) => {
                                console.log(e);
                                let value = e;
                                danju1(value, i)
                            }} />-<InputNumber
                            // prefix="￥"
                            placeholder='￥'
                            min={0.01} style={{ width: 100 }}
                            formatter={limitTowDecimals}
                            parser={limitTowDecimals}
                            value={intervalAmount.length > 0 ? intervalAmount[i] && intervalAmount[i].endPrice : undefined}
                            onChange={(e) => {
                                let value = e;
                                danju2(value, i)
                            }} />
                        <InputNumber

                            formatter={limitTowDecimals}
                            parser={limitTowDecimals}
                            min={0.01} max={100}
                            addonAfter={radio == 2 ? '%' : '元'} style={{ width: 100 }}
                            onChange={(e) => {
                                let value = e;
                                danju3(value, i)
                            }}
                            value={intervalAmount.length > 0 ? intervalAmount[i] && intervalAmount[i].number : undefined}
                        />
                        {i == 0 ? <Button type="link" onClick={add}>添加</Button> : ''}
                        {i > 0 ? <Button type="link" onClick={() => {
                            del(i)
                        }}>删除</Button> : ''}
                    </Form.Item>
                </Col>,
            );
        }
        return children;
    };

    const forceUpdate = useReducer((bool) => !bool)[1]
    const danju1 = (value, i) => {
        let obj = {
            startPrice: value
        }
        intervalAmount[i] = { ...intervalAmount[i], ...obj }
        setIntervalAmount(intervalAmount)
        forceUpdate()
    }
    const danju2 = (value, i) => {
        let obj = {
            endPrice: value
        }
        intervalAmount[i] = { ...intervalAmount[i], ...obj }
        setIntervalAmount(intervalAmount)
        forceUpdate()
    }
    const danju3 = (value, i) => {
        let obj = {
            number: value,
        }
        intervalAmount[i] = { ...intervalAmount[i], ...obj }
        // intervalAmount[i].number2 ? intervalAmount[i].number2 = '' : intervalAmount[i].number2 = undefined
        setIntervalAmount(intervalAmount)
        forceUpdate()
    }
    const add = () => {
        if (count == 3) return false
        setCount(count + 1)
    }
    const del = (i) => {
        if (count > 1) {
            setCount(count - 1)
            intervalAmount.splice(i, 1)
        }
    }

    const dateType = (data) => {
        setDateType(data)
    };
    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    };
    return (
        <div style={{ background: '#ffffff', padding: 10 }}>
            <Form
                name="basic"
                {...layout}
                style={{ width: 600 }}
                form={form}
                onFinish={onFinish}
                initialValues={{
                    shareType: 0,
                    expenseReceiptType: 0,
                    expireType: 0,
                }}
            >
                <Form.Item
                    name="shareType"
                    label="分账类型"
                    rules={[{ required: true, message: '请选择分类类型!' }]}
                >
                    <Radio.Group disabled={data && data.edit} onChange={(e) => {
                        console.log(e.target.value);
                        setCheckCode(e.target.value)
                        if (e.target.value == 0) {
                            form.setFieldsValue({
                                expenseReceiptType: 0,

                            })
                        }
                        form.setFieldsValue({
                            businessTypeNosArry: undefined,
                            expensePaySectionJson: undefined,
                            shareMoneyType: undefined,

                        })
                        if (e.target.value == 1) {

                            form.setFieldsValue({
                                expenseReceiptType: 0,
                                shareMoneyType: 0
                            })
                            setRadio(0)
                            setIntervalAmount([])
                        }
                    }} value={value}>
                        <Radio value={0}>按订单</Radio>
                        {
                            props && props.accountType == 1 || props && props.accountType == 2 ?
                                <Radio value={1}>按金额</Radio> : ''
                        }

                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="payeeMerchantCodesArry"
                    label="收入机构"
                    rules={[{ required: true, message: '请选择收入机构!' }]}
                >
                    <Select placeholder="请选择"
                        disabled={data && data.edit}
                        mode="multiple"
                        style={{ width: 400 }}
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.includes(input)}
                        onChange={(e) => {
                            console.log(e);
                            let arr = []
                            shMerchantList && shMerchantList.map((v) => {
                                e.map((i) => {
                                    if (i == v.merchantCode) {
                                        arr.push(v.merchantName)
                                    }
                                })
                            })
                            console.log(arr);
                            setPayeeMerchantNames(arr)
                        }}
                    >

                        {
                            shMerchantList && shMerchantList.map((v) => {
                                return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                            })
                        }

                    </Select>
                </Form.Item>
                <Form.Item
                    name="accountType"
                    label="收入账户"
                    rules={[{ required: true, message: '请选择收入账户!' }]}
                >
                    <Select
                        disabled={data && data.edit}
                        style={{ width: 200 }}
                        allowClear
                        placeholder="请选择">
                        {
                            props && props.accountType == 0 ?
                                <Option value="0">店铺待结算余额</Option> : null
                        }
                        {
                            props && props.accountType == 4 ?
                                <Option value="4">钱包待结算余额</Option> : null
                        }
                        {
                            props && props.accountType == 2 ?
                                <Option value="2">钱包余额</Option> : null
                        }
                        {
                            props && props.accountType == 1 && form.getFieldValue().payeeMerchantCodesArry && form.getFieldValue().payeeMerchantCodesArry.length == 0 && form.getFieldValue().payeeMerchantCodesArry && form.getFieldValue().payeeMerchantCodesArry[0] == localStorage.merchantCode ?
                                <>
                                    <Option value="1">店铺余额</Option>
                                    <Option value="2">收益余额</Option>
                                </> : null
                        }
                        {
                            props && props.accountType == 1 ?
                                <>
                                    <Option value="1">店铺余额</Option>
                                </> : null
                        }
                        {
                            props && props.accountType == 7 ?
                                <>
                                    <Option value="7">营销余额-待结算</Option>
                                </> : null
                        }
                    </Select>
                </Form.Item>
                {
                    checkCode == 0 ?
                        <Form.Item label="单据类型"
                            name="businessTypeNosArry"
                            rules={[{ required: true, message: '请输入转账金额!' }]}
                        >
                            <Select placeholder="请选择"
                                style={{ width: 400 }}
                                mode="multiple"
                                disabled={data && data.edit}
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                                onChange={(e) => {
                                    console.log(e)
                                    let arr = []
                                    typeList && typeList.map((v) => {

                                        e.map((i) => {
                                            if (i == v.businessTypeNo) {
                                                arr.push(v.businessTypeName)
                                            }
                                        })
                                    })
                                    console.log(arr)
                                    setTypeNames(arr)
                                }}
                            >

                                {
                                    typeList && typeList.map((v) => {
                                        return <Option rowKey={v.businessTypeNo} key={v.businessTypeNo} value={v.businessTypeNo}>{v.businessTypeName}</Option>
                                    })
                                }

                            </Select>
                        </Form.Item> : null
                }

                <Form.Item
                    name="expenseReceiptType"
                    label="收支类型"
                    rules={[{ required: true, message: '请选择转账用途!' }]}
                >
                    <Radio.Group disabled={data && data.edit} onChange={(e) => {
                        console.log(e);
                        form.setFieldsValue({
                            expensePaySectionJson: undefined
                        })
                        setIntervalAmount([])
                        setRadio(e.target.value)
                    }} value={value}>
                        <Radio value={0}>定额</Radio>
                        {
                            checkCode == 0 ?
                                <>
                                    <Radio value={1}>比例</Radio>
                                    <Radio value={2}>区间比例</Radio>
                                    <Radio value={3}>区间定额</Radio>
                                </> : null
                        }


                    </Radio.Group>
                </Form.Item>
                {
                    checkCode == 1 ?
                        <Row>
                            <Col span={8}>
                                <Form.Item
                                    {
                                    ...{
                                        labelCol: { span: 12 },
                                        wrapperCol: { span: 10 },
                                    }
                                    }
                                    name="shareMoneyType"
                                    label="单据金额"
                                    rules={[{ required: true, message: '请选择!' }]}
                                >
                                    <Select placeholder="请选择"
                                        disabled={data && data.edit}
                                        style={{ width: 100 }}
                                        allowClear
                                        onChange={(e) => {
                                            console.log(e)
                                            setTypeChange(e)
                                        }}
                                    >
                                        <Option key={0} value={0}>不足不扣</Option>
                                        <Option key={1} value={1}>补偿扣款</Option>

                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8} style={{ marginLeft: 10 }}>
                                <Form.Item
                                    name="expensePaySectionJson"
                                    label=""
                                    rules={[{ required: true, message: '请输入!' }]}
                                >
                                    <InputNumber placeholder='固额'
                                        disabled={data && data.edit}
                                        style={{ width: 180 }}
                                        formatter={limitTowDecimals}
                                        parser={limitTowDecimals}
                                    />
                                </Form.Item>
                            </Col>

                        </Row> : null
                }
                {
                    checkCode == 1 ?
                        <Form.Item
                            label=" "
                            colon={false}
                            style={{ marginTop: '-2vh' }}
                        >
                            <span style={{ color: '#969799', fontSize: 12 }}>支出账户余额不足{typeChange == 0 ? '不扣' : '持续补偿扣款'}</span>
                        </Form.Item> : null
                }

                {
                    checkCode == 1 ?
                        <Row style={{ marginTop: '-3vh' }}>
                            <Col span={8}>
                                <Form.Item
                                    {
                                    ...{
                                        labelCol: { span: 12 },
                                        wrapperCol: { span: 10 },
                                    }
                                    }
                                    name="settleType"
                                    label="结算周期"
                                    rules={[{ required: true, message: '请选择结算周期!' }]}
                                >
                                    <Select placeholder="请选择"
                                        disabled={data && data.edit}
                                        style={{ width: 100 }}
                                        allowClear
                                        onChange={dateType}
                                    >
                                        <Option value="1">日</Option>
                                        <Option value="2">周</Option>
                                        <Option value="3">月</Option>

                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8} style={{ marginLeft: 10 }}>
                                {
                                    datetype == 1 ?
                                        <Form.Item
                                            colon={false}
                                            label=""
                                            name="settleValue"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '请输入!',
                                                },
                                            ]}>
                                            <Input style={{ width: 180 }} disabled={data && data.edit} placeholder='请输入几日' />
                                        </Form.Item> : ''
                                }
                                {
                                    datetype == 2 ?
                                        <Form.Item
                                            colon={false}
                                            label=""
                                            name="settleValue"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '请输入!',
                                                },
                                            ]}>
                                            <Select
                                                style={{ width: 180 }}
                                                placeholder='请选择星期几'
                                                disabled={data && data.edit}
                                            >
                                                <Option key='1'>星期一</Option>
                                                <Option key='2'>星期二</Option>
                                                <Option key='3'>星期三</Option>
                                                <Option key='4'>星期四</Option>
                                                <Option key='5'>星期五</Option>
                                                <Option key='6'>星期六</Option>
                                                <Option key='7'>星期日</Option>
                                            </Select>
                                        </Form.Item> : ''
                                }
                                {
                                    datetype == 3 ?
                                        <Form.Item
                                            colon={false}
                                            label=""
                                            name="settleValue"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '请输入!',
                                                },
                                            ]}>
                                            <Form.Item
                                                name="settleMonth"
                                                noStyle
                                            >
                                                <Select
                                                    style={{ width: 180 }}
                                                    placeholder='请选择'
                                                    disabled={data && data.edit}
                                                >
                                                    {children}
                                                </Select>
                                            </Form.Item>
                                        </Form.Item> : ''
                                }
                            </Col>

                        </Row> : null
                }
                {
                    checkCode == 0 && radio == 0 ?
                        <Form.Item label="单据金额：" name="expensePaySectionJson"
                            rules={[{ required: true, message: '请输入!' }]}
                        >
                            <InputNumber
                                formatter={limitTowDecimals}
                                parser={limitTowDecimals}
                                min={0.01} prefix="￥" style={{ width: 180 }} disabled={data && data.edit} />
                        </Form.Item>
                        : ''
                }
                {
                    checkCode == 0 && radio == 1 ?
                        <Form.Item label="单据金额：" name="expensePaySectionJson"
                            rules={[{ required: true, message: '请输入!' }]}>
                            <InputNumber formatter={limitTowDecimals}
                                parser={limitTowDecimals} min={0.01} addonAfter='%' style={{ width: 180 }} disabled={data && data.edit} />
                        </Form.Item>
                        : ''
                }
                {
                    checkCode == 0 && radio == 3 || radio == 2 ?
                        getFields()

                        : ''
                }



                <Form.Item
                    name="expireType"
                    label="单据有效期"
                    rules={[{ required: true, message: '请选择!' }]}
                >
                    <Radio.Group disabled={data && data.edit} onChange={(e) => {
                        console.log('单据有效期')
                        if (e.target.value == 1 || e.target.value == 2) {
                            setExpireEndTime(null)
                        }
                        setValue(e.target.value)
                    }}>
                        <Space direction="vertical">
                            <Radio value={0}>
                                <>
                                    <DatePicker
                                        showToday={false}
                                        disabled={value != 0 || data && data.edit}
                                        disabledDate={disabledDate}
                                        style={{ width: 200 }}
                                        defaultValue={data && data.id && data.expireType == 0 ? moment(data.expireStartTime, dateFormat) : undefined}
                                        onChange={(date) => {
                                            console.log(date);
                                            setExpireStartTime(date && date.format('YYYY-MM-DD'))
                                        }} />   至 <DatePicker showToday={false} disabledDate={disabledDate} disabled={value != 0 || data && data.edit} style={{ width: 200 }}
                                            defaultValue={data && data.id && data.expireEndTime && data.expireType == 0 ? moment(data.expireEndTime, dateFormat) : undefined}
                                            onChange={(date) => {
                                                setExpireEndTime(date && date.format('YYYY-MM-DD'))
                                            }} />
                                </>
                            </Radio>
                            <Radio value={1}>
                                <>
                                    <DatePicker
                                        showToday={false}
                                        defaultValue={data && data.id && data.expireType == 1 ? moment(data.expireStartTime, dateFormat) : undefined}
                                        disabledDate={disabledDate}
                                        disabled={value != 1 || data && data.edit} style={{ width: 200 }} onChange={(date) => {
                                            setExpireStartTime(data && date.format('YYYY-MM-DD'))
                                        }} />   至 长期有效
                                </>

                            </Radio>
                            <Radio value={2}>
                                <>
                                    <DatePicker
                                        showToday={false}
                                        disabledDate={disabledDate}
                                        defaultValue={data && data.id && data.expireType == 2 ? moment(data.expireStartTime, dateFormat) : undefined}
                                        disabled={value != 2 || data && data.edit} style={{ width: 200 }} onChange={(date) => {
                                            setExpireStartTime(date && date.format('YYYY-MM-DD'))
                                        }} />   至 一次性费用
                                </>
                            </Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    style={{ marginTop: '-2vh' }}
                    label=" "
                    colon={false}
                    rules={[{ required: false, message: '请选择收入机构!' }]}
                >
                    <span style={{ color: '#969799', fontSize: 12 }}>不满足结算周期的数据，会在结束日后一日生成</span>
                </Form.Item>
            </Form >
            <div style={{ textAlign: 'right' }}>
                <Button onClick={() => { cancel() }}>取消</Button>
                {
                    data && !data.edit ?
                        <Button style={{ marginLeft: 20 }} type="primary" htmlType='submit' onClick={onFinish}>确定</Button> : ''
                }

            </div>


        </div >
    )
}

export default SubLedgerRule;