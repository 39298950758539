import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import {
    Form,
    Image,
    Input,
    Button,
    message,
    Row,
    Col,
    Typography,
    Select,
    InputNumber
} from 'antd';
import { orderSaveRequest, settleMerchantListRequest, getConstantsRequest } from '../../../../api/api'
import { limitTowDecimals } from '../../../../util/tools';
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
};
const { TextArea } = Input;
const { Option } = Select;
const { Title } = Typography;
const LnstitutionsInList = (props) => {
    const [form] = Form.useForm()
    const [merchantList, setMerchantList] = useState([])
    const [constants, setConstants] = useState(Array)
    const [payerMerchantName, setPayerMerchantName] = useState(String)
    const [payeeMerchantName, setPayeeMerchantName] = useState(String)
    const [transferPurposeName, setTransferPurposeName] = useState(String)
    const [errorNum, setErrorNum] = useState(0)
    let data = props ? props.data : null;
    useEffect(() => {
        console.log(props)
        if (data && data.payeeAccountTypeError || data && data.payeeMerchantCodeError || data && data.transferPurposeError) {
            setErrorNum(errorNum + 1)
        }
        getConstants()
        settleMerchantList()
    }, [])
    useEffect(() => {
        console.log(errorNum);
    }, [errorNum])
    useEffect(() => {
        console.log(11111);
        form.setFieldsValue({
            payeeMerchantCode: data && data.payeeMerchantCodeError ? undefined : data && data.payeeMerchantCode,
            amount: data && data.amount,
            remark: data && data.remark,
        })
    }, [merchantList])
    useEffect(() => {
        console.log(22222);
        form.setFieldsValue({
            transferPurpose: data && data.transferPurposeError ? undefined : data && data.transferPurpose.toString(),
        })
    }, [constants])
    const getConstants = () => {

        (async _ => {
            return await getConstantsRequest({
                constantType: "FD_TR_PURPOSE"
            });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setConstants(res.data.items)
            } else {
            }
        });
    }
    const cancel = (status) => {
        props.closeDialog()
    }
    const settleMerchantList = () => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode: localStorage.appid,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            if (res.code == 0) {
                // console.log(props.payerMerchantCode);
                // let index = res.data.findIndex((v) => { return v.merchantCode == localStorage.merchantCode })
                // console.log(index);
                // res.data.splice(index, 1)
                setMerchantList(res.data)
            } else {

            }
        });
    }
    const onFinish = async () => {
        const values = await form.validateFields()  //2.表单验证并获取表单值
        console.log(values)
        console.log(transferPurposeName);
        values.payeeMerchantName = payeeMerchantName ? payeeMerchantName : props.data.payeeMerchantName;
        values.transferPurposeName = transferPurposeName ? transferPurposeName : props.data.transferPurposeName;
        values.id = props.data && props.data.id ? props.data.id : Math.random(10);
        values.payerAccountType = props && props.value1;
        values.payeeAccountName = props && props.value1 == 1 ? '店铺余额' : '钱包余额'
        values.payeeMerchantCodeError = ""
        values.errorNum = errorNum
        props.callback(values)
        props.closeDialog()
    }
    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            <Form
                name="basic"
                {...layout}
                style={{ width: 600 }}
                form={form}
                onFinish={onFinish}
            >
                <div style={{ marginLeft: 20 }}>
                    <Title level={5}>收款账户信息</Title>
                </div>
                <Form.Item
                    name="payeeMerchantCode"
                    label="收款机构"
                    rules={[{ required: true, message: '请选择收入机构!' }]}
                >
                    <Select placeholder="请选择"
                        style={{ width: 250 }}
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.includes(input)}
                        onChange={(e) => {
                            merchantList && merchantList.map((v) => {
                                if (v.merchantCode == e) {
                                    setPayeeMerchantName(v.merchantName)
                                }
                            })
                        }}
                    >

                        {
                            merchantList && merchantList.map((v) => {
                                return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                            })
                        }

                    </Select>
                </Form.Item>
                <div style={{ marginLeft: 20, marginTop: 16 }}>
                    <Title level={5}>转账信息</Title>
                </div>
                <Form.Item label="收款账户">
                    {props && props.value1 && props.value1 == 1 ? '店铺余额' : '钱包余额'}
                </Form.Item>
                <Form.Item label="转账金额"
                    name="amount"
                    rules={[{ required: true, message: '请输入转账金额!' }]}
                >
                    <InputNumber placeholder='请输入'
                        style={{ width: 250 }}
                        formatter={limitTowDecimals}
                        parser={limitTowDecimals}
                        addonAfter="元" />
                </Form.Item>
                <Form.Item
                    name="amount"
                    colon={false}
                    label=" "
                    style={{ marginTop: '-2vh' }}
                >
                    可用余额:  <span style={{ fontWeight: 600 }}>￥{props && props.yue}</span>元
                </Form.Item>
                <Form.Item
                    name="transferPurpose"
                    label="转账用途"
                    rules={[{ required: true, message: '请选择转账用途!' }]}
                >
                    <Select placeholder="请选择"
                        style={{ width: 250 }}
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.includes(input)}
                        onChange={(e) => {
                            constants && constants.map((v) => {
                                if (v.constantCode == e) {
                                    setTransferPurposeName(v.constantName)
                                }
                            })
                        }}
                    >

                        {
                            constants && constants.map((v) => {
                                return <Option rowKey={v.constantCode} key={v.constantCode} value={v.constantCode}>{v.constantName}</Option>
                            })
                        }

                    </Select>
                </Form.Item>
                <Form.Item
                    name="remark"
                    label="转账附言"
                >
                    <TextArea
                        allowClear
                        autoSize={{
                            minRows: 3, maxRows: 6
                        }}
                        style={{ width: 250 }}
                        placeholder="最多可输入50个字，特殊字符会被过滤"
                    />
                </Form.Item>

            </Form>
            <div style={{ textAlign: 'right' }}>
                <Button onClick={() => { cancel() }}>取消</Button>
                <Button style={{ marginLeft: 20 }} type="primary" htmlType='submit' onClick={onFinish}>确定</Button>
            </div>


        </div >
    )
}

export default LnstitutionsInList;