import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
    Form,
    Row,
    Col,
    Button,
    Input,
    Select,
    Table,
    Popconfirm,
    DatePicker,
    message,
    Modal
} from 'antd';
import { creditHistoryRequest, settleMerchantListRequest } from '../../../api/api'
import { useCallbackState } from '../../../util/useCallbackState'
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
};
const CreditBalance = () => {
    const { state } = useLocation()
    const [list, setList] = useState();
    const [merchantList, setMerchantList] = useState([])
    const [merchantCode, setMerchantCode] = useState(state && state.merchantCode ? state.merchantCode : localStorage.merchantCode)
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [total, setTotal] = useState()
    const [init, setInit] = useState(true)
    const navigate = useNavigate()
    const columns = [
        {
            title: '账期',
            dataIndex: 'startDate',
            key: 'startDate',
            render: (text, row) => {
                return (
                    <div>{row.startDate.substring(0, 10)}~{row.endDate.substring(0, 10)}</div>
                )
            }
        },
        {
            title: '商户',
            dataIndex: 'merchantCode',
            key: 'merchantCode',
        },
        {
            title: '账户',
            dataIndex: 'accountType',
            key: 'accountType',
            render: (text, row) => {
                return (
                    <div>
                        授信账户
                        {/* {row.accountType == 0 ? '店铺余额待结算' : ''}
                        {row.accountType == 1 ? '可用账户' : ''}
                        {row.accountType == 2 ? '钱包账户' : ''}
                        {row.accountType == 3 ? '信用可提现账户' : ''}
                        {row.accountType == 4 ? '钱包余额待结算' : ''}
                        {row.accountType == 5 ? '收益账户' : ''}
                        {row.accountType == 6 ? '授信账户' : ''} */}
                    </div>
                )
            }
        },
        {
            title: '账单金额(元)',
            dataIndex: 'oweGoodsAmount',
            key: 'oweGoodsAmount',
            render: text => (
                <div>
                    {text}
                </div>
            ),
        },
        {
            title: '状态',
            dataIndex: 'billStatus',
            key: 'billStatus',
            render: (text, row) => {
                return <div>
                    {row.billStatus == 0 ? '未结算' : ''}
                    {row.billStatus == 1 ? '已结算' : ''}
                    {row.billStatus == 2 ? '结算失败' : ''}
                </div>
            }
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 300,
            align: 'right',
            render: (text, row) => (
                <>
                    <Button type='link' onClick={() => {
                        navigate('/ws/creditAccount/billingDetails', { replace: false, state: row })
                    }}>详情</Button>
                </>
            ),
        },
    ];
    useEffect(() => {
        getCreditHistory()
        console.log(state)
        if (init) {
            settleMerchantList()
        }
    }, [currentPage, pageNum])
    const [form] = Form.useForm();
    const onFinish = (values) => {
        values.settleDate = values.settleDate && values.settleDate.format(dateFormat)
        values.currentPage = 1
        getCreditHistory(values)
    };

    const settleMerchantList = () => {
        (async _ => {
            return await settleMerchantListRequest({
                merchantCode: localStorage.merchantCode,
                superMerchantCode: localStorage.appid
            });
        })().then(res => {
            if (res.code == 0) {
                setInit(false)
                setMerchantList(res.data)
            } else {

            }
        });
    }
    const getCreditHistory = (data) => {
        let params = {
            ...data,
            currentPage: data && data.currentPage ? data.currentPage : currentPage,
            pageSize: pageNum,
            merchantCode: merchantCode,
            superMerchantCode: localStorage.appid,
        };
        (async _ => {
            return await creditHistoryRequest(params);
        })().then(res => {
            if (res.code == 0) {
                setList(res.data)
                setTotal(res.data.totalCount)
            } else {
                message.error(res.msg)
            }
        });
    }
    const onChangPage = (currentPage, pageNum) => {
        setCurrentPage(currentPage, () => {
            // getCreditHistory()
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        setPageNum((currentPage, pageNum), () => {
            // getCreditHistory()
        })
    }
    return (
        <div style={{ background: '#ffffff', padding: 16, marginTop: 10 }}>
            <Form
                form={form}
                name="advanced_search"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                {...layout}
                initialValues={{
                    billStatus: null,
                    merchantCode: state && state.merchantCode ? state.merchantCode : null,
                }}
                style={{ background: '#F7F8FA', padding: 16 }}
            >
                <Row align="middle">
                    {
                        localStorage.hxState == 'true' ?
                            <Col span={8}>
                                <Form.Item
                                    name="merchantCode"
                                    label="商户名称："
                                    rules={[{ required: false, message: 'Please input your username!' }]}
                                >
                                    <Select placeholder="请选择"
                                        allowClear
                                        style={{ width: 200 }}
                                        onChange={(code) => {
                                            setMerchantCode(code)
                                        }}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.includes(input)}

                                    >
                                        <Option key={null} value={null}>全部</Option>
                                        {
                                            merchantList && merchantList.map((v) => {
                                                return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                            })
                                        }

                                    </Select>
                                </Form.Item>
                            </Col> :
                            <Col span={8}>
                                <Form.Item
                                    label="商户名称："
                                    rules={[{ required: false, message: 'Please input your username!' }]}
                                >
                                    {localStorage.merchantName}
                                </Form.Item>
                            </Col>
                    }

                    <Col span={8}>
                        <Form.Item
                            name="billStatus"
                            label="单据状态："
                        // style={{ marginLeft: 30 }}
                        >
                            <Select style={{ width: 200 }}>
                                <Option value={null}>全部</Option>
                                <Option value="0">未结算</Option>
                                <Option value="1">已结算</Option>
                                <Option value="2">结算失败</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="settleDate"
                            label="账期："
                        // style={{ marginLeft: 30 }}
                        >
                            <DatePicker style={{ width: 200 }} />
                        </Form.Item>
                    </Col>

                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item
                            wrapperCol={{
                                offset: 5,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                筛选
                            </Button>
                            <Button type="link" onClick={() => {
                                form.resetFields();
                            }}>
                                重置筛选条件
                            </Button>
                            {/* <Button
                                style={{ margin: '0 8px' }}

                            >
                                导出
                            </Button>
                            <Button type="link"  >
                                查看已导出列表
                            </Button>
                           
                            */}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                locale={{
                    emptyText: "没有更多数据了"
                }}
                style={{ marginTop: 20 }}
                columns={columns}
                dataSource={list}
                rowKey='id'
                scroll={{
                    x: 1300,
                }}
                pagination={{
                    total,
                    showSizeChanger: true,
                    current: currentPage,
                    pageSize: pageNum,
                    // position: ["bottomCenter"],
                    showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                    onChange: (k, v) => onChangPage(k, v),
                    onShowSizeChange: (k, v) => onChangPageNum(k, v),
                }}
            />

        </div>
    )
}
export default CreditBalance;