import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Form,
  Input,
  Switch,
  Row,
  Col,
  Button,
  Tabs,
  Table,
  Modal,
  Radio,
  Space,
  Skeleton,
  message
} from 'antd';
import { detailByAccountRequest, getConstantsRequest } from '../../../api/api'
import host from '../../../util/host';


const { TabPane } = Tabs;
const FormItem = Form.Item;
const Search = Input.Search;



const StatementDetails = (props) => {
  const state = props.data;

  const [topList, setTopList] = useState([])
  const [bottomList, setBottomList] = useState([])
  const [data, setData] = useState([])
  const [activeKey, setActiveKey] = useState(state && state.accountType.toString() ? state.accountType.toString() : '1')
  const [accountTypeList, setAccountTypeList] = useState([])
  let navigate = useNavigate();
  useEffect(() => {
    console.log(state);
    getStatisticsDayDetail(state)
    getConstants()
  }, [])
  const getConstants = () => {
    (async _ => {
      return await getConstantsRequest({
        constantType: "FD_ACCOUNT"
      });
    })().then(res => {
      console.log(res);
      if (res.code == 0) {
        setAccountTypeList(res.data.items)
      } else {
      }
    });
  }
  const onChange = (checked) => {
    setActiveKey(checked)
    getStatisticsDayDetail({ ...state, accountType: checked, })
  };
  const getStatisticsDayDetail = (data) => {
    (async _ => {
      return await detailByAccountRequest(
        {
          ...data,
        }
      );
    })().then(res => {
      if (res.code == 0) {
        let top = []
        let bottom = []
        res.data && res.data.list.map((v) => {
          if (v.tradeType == 0) {
            top.push(v)
          } else {
            bottom.push(v)
          }
        })
        setData(res.data)
        setTopList(top)
        setBottomList(bottom)
      } else {
        message.error(res.msg)
      }
    });
  }

  const columns = [
    {
      title: '收入类型',
      dataIndex: 'businessTradeTypeName',
      key: 'businessTradeTypeName',
      width: 200,
    },
    {
      title: '金额 (元)/笔数',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      width: 200,
      render: (text, row) => {
        return (
          <div>
            <div>
              {row.totalAmount}元
            </div>
            <div>
              {row.totalCount}笔
            </div>
          </div>
        )
      }
    },
    {
      title: '明细',
      key: 'action',
      align: 'right',
      width: 200,
      render: (text, row) => (
        <>
          <span style={{ color: '#165BD4', cursor: 'pointer' }} onClick={() => {
            navigate('/ws/statementList', {
              replace: false,
              state: {
                businessTradeType: row.businessTradeType,
                startTime: data && data.startTime,
                endTime: data && data.endTime,
                merchantCode: row.merchantCode,
                accountType: row.accountType.toString(),
                tag: 1,
              }
            })
          }}>查看</span>
        </>

      ),
    },
  ];
  const columns1 = [
    {
      title: '支出类型',
      dataIndex: 'businessTradeTypeName',
      key: 'businessTradeTypeName',
      width: 200,
    },
    {
      title: '金额 (元)/笔数',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      width: 200,
      render: (text, row) => {
        return (
          <div>
            <div>
              {row.totalAmount}元
            </div>
            <div>
              {row.totalCount}笔
            </div>
          </div>
        )
      }
    },
    {
      title: '明细',
      key: 'action',
      align: 'right',
      width: 200,
      render: (text, row) => (
        <>
          <span style={{ color: '#165BD4', cursor: 'pointer' }} onClick={() => {
            navigate('/ws/statementList', { replace: false, state: { businessTradeType: row.businessTradeType, startTime: data && data.startTime, endTime: data && data.endTime, merchantCode: row.merchantCode, accountType: row.accountType.toString(), } })
          }}>查看</span>
        </>

      ),
    },
  ];

  return (
    <div style={{ height: '100vh', background: '#ffffff', paddingBottom: 20 }}>

      <Row align='middle' style={{ width: '100%', padding: '10px 0', marginTop: 10, }}>
        <Row justify='space-between' style={{ width: '100%', background: '#F7F8FA', padding: 10, margin: '0 10px' }}>
          <Col span={12}>
            <div style={{ fontSize: 24, fontWeight: 500, }}>{data && data.startTime && data.startTime.substring(0, 10)}~{data && data.startTime && data.endTime.substring(0, 10)}账务汇总详情</div>
            <div>
              <span style={{ color: '#969799' }}>店铺名称：</span>
              <span>{data && data.merchantName}</span>
            </div>

          </Col>
          <Col span={12}>
            <div style={{ fontSize: 24, fontWeight: 500, color: '#F7F8FA' }}>1</div>
            <div>
              <span style={{ color: '#969799' }}>账单周期：</span>
              <span>{data && data.startTime}至{data && data.endTime}</span>
            </div>
          </Col>
        </Row>
      </Row>
      {/* <Row>
        <Button type='primary' style={{ marginLeft: 10, marginTop: 10 }}>打印汇总详情</Button>
      </Row> */}
      <Row style={{ padding: "0px 10px", marginTop: 10 }}>
        <Tabs activeKey={activeKey.toString()} onChange={onChange} type="card" >
          {/* {
            accountTypeList && accountTypeList.map((v) => {
              return  <TabPane tab={v.constantName} key={v.constantCode}> </TabPane>
            })
          } */}

          <TabPane tab="店铺余额" key="1">
          </TabPane>
          <TabPane tab="店铺余额-待结算" key="0">
          </TabPane>
          <TabPane tab="钱包余额" key="2">
          </TabPane>
          <TabPane tab="钱包余额-待结算" key="4">
          </TabPane>
          <TabPane tab="营销余额" key="8">
          </TabPane>
          <TabPane tab="营销余额-待结算" key="7">
          </TabPane>

          {
            localStorage.appid == state.merchantCode ?
              <TabPane tab="收益账户" key="5">
              </TabPane> : ''
          }

        </Tabs>
      </Row>
      <Row justify='space-between' style={{ background: '#F7F8FA', padding: 20, margin: 10, marginTop: 0 }}>
        <Col span={6}>
          <div>
            <span>本期期初余额(元)</span>
          </div>
          <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{data && data.beginningBalance}</div>
        </Col>
        <Col span={6}>
          <div><span>本期收入(元)</span></div>
          <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{data && data.totalIncomeAmount}</div>
        </Col>
        <Col span={6}>
          <div><span>本期支出(元)</span></div>
          <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{data && data.totalOutAmount}</div>

        </Col>
        <Col span={6}>
          <div><span>本期期末余额(元)</span></div>
          <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{data && data.endingBalance}</div>
        </Col>
      </Row>
      <Row align='middle' style={{ padding: '10px 10px', marginTop: 10 }}>
        <Row align='middle' justify='space-between' style={{ padding: '10px 10px', width: '100%', height: '100%', background: '#F7F8FA' }}>
          <Row align='middle' >
            <div style={{ background: '#145BD5', height: 14, width: 3, }}></div><div style={{ marginLeft: 10, fontWeight: 500 }}>本期收入</div>
          </Row>
        </Row>
      </Row>

      <Row style={{ padding: "0px 10px" }}>
        <Col span={24}>
          <Table
            locale={{
              emptyText: "没有更多数据了"
            }}
            rowKey='id'
            columns={columns}
            dataSource={topList}
            pagination={false}
            footer={() => {
              return <Row>
                <Col span={8}>合计:</Col>
                <Col span={14} style={{ marginLeft: 10 }}>
                  <div>{topList.length > 0 ? data && data.totalIncomeAmount : '0.00'}元</div>
                  <div>{topList.length > 0 ? data && data.totalIncomeCount : '0'}笔</div>
                </Col>
              </Row>
            }}
          />
        </Col>

      </Row>
      <Row align='middle' style={{ padding: '10px 10px', marginTop: 10 }}>
        <Row align='middle' justify='space-between' style={{ padding: '10px 10px', width: '100%', height: '100%', background: '#F7F8FA' }}>
          <Row align='middle' >
            <div style={{ background: '#145BD5', height: 14, width: 3, }}></div><div style={{ marginLeft: 10, fontWeight: 500 }}>本期支出</div>
          </Row>
        </Row>
      </Row>

      <Row style={{ padding: "0px 10px" }}>
        <Col span={24}>
          <Table
            locale={{
              emptyText: "没有更多数据了"
            }}
            rowKey='id'
            columns={columns1}
            dataSource={bottomList}
            pagination={false}
            footer={() => {
              return <Row>
                <Col span={8}>合计:</Col>
                <Col span={14} style={{ marginLeft: 10 }}>
                  <div>{bottomList.length > 0 ? data && data.totalOutAmount : '0.00'}</div>
                  <div>{bottomList.length > 0 ? data && data.totalOutCount : '0'}笔</div>
                </Col>
              </Row>
            }}
          />
        </Col>

      </Row>
    </div>

  )
}
export default StatementDetails;
