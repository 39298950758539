import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import './style.less'
import {
    Form,
    Image,
    Input,
    Button,
    message,

} from 'antd';
import { withdrawAuditRequest } from '../../../../api/api'
const { TextArea } = Input;
const LnstitutionsInList = (props) => {
    const [auditRemark, setAuditRemark] = useState(null)
    useEffect(() => {
        console.log(props)
    }, [])
    const bookRechargeProcess = (status) => {
        (async _ => {
            return await withdrawAuditRequest({
                status,
                remark: auditRemark,
                withdrawOrderNo: props && props.data.orderNo,
                employeeId: localStorage.operatorId,
                employeeName: localStorage.operatorname,
                merchantCode: props && props.data.merchantCode,
                superMerchantCode: localStorage.appid,
                // id: props && props.data.id,
            });
        })().then(res => {
            console.log(res)
            if (res.code == 0) {
                props.callback(true)
                props.closeDialog()
                message.success(res.msg)
            } else {
                message.error(res.msg)
            }
        });
    }
    const pass = (status) => {
        bookRechargeProcess(status)
    }
    const rejected = (status) => {
        bookRechargeProcess(status)
    }

    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            <div>
                <Form
                    name="basic"
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    autoComplete="off"
                >
                    {
                        props && props.data.withdrawType != 0 ?
                            <>
                                <div style={{ width: '100%', marginLeft: 20, marginTop: 20, marginBottom: 20 }} className='borderBottom'>银行信息</div>
                                <Form.Item label="开户姓名" >
                                    {props && props.data.payeeName}
                                </Form.Item>
                                <Form.Item label="提现银行">
                                    {props && props.data.bankName}
                                </Form.Item>
                                <Form.Item label="支行名称">
                                    {props && props.data.bankBranchName}
                                </Form.Item>
                                <Form.Item label="银行卡号">
                                    {props && props.data.bankCard}
                                </Form.Item>
                            </>
                            : null
                    }



                    <div style={{ width: '100%', marginTop: 20, marginLeft: 20, marginBottom: 20 }} className='borderBottom'>提现信息</div>
                    <Form.Item label="商户">
                        {props && props.data.merchantName}
                    </Form.Item>
                    <Form.Item label="提现类型">
                        {props && props.data.withdrawType == 0 ? '店铺余额' : null}
                        {props && props.data.withdrawType == 1 ? '收益账户' : null}
                        {props && props.data.withdrawType == 2 ? '钱包账户' : null}
                        {props && props.data.withdrawType == 8 ? '营销余额' : null}
                    </Form.Item>
                    <Form.Item label="订单编号">
                        {props && props.data.orderNo}
                    </Form.Item>
                    <Form.Item label="申请时间">
                        {props && props.data.createTime}
                    </Form.Item>
                    <Form.Item label="金额">
                        {props && props.data.withdrawAmount}
                    </Form.Item>
                    <Form.Item label="银行回单">
                        <Image
                            width={200}
                            src={props && props.data.completeProofImg}
                        />
                    </Form.Item>
                    <Form.Item label="附加信息">
                        {
                            props && props.data.xq == 1 ?
                                <span>{props && props.data.auditRemark}</span>
                                :
                                <TextArea rows={4} onChange={(e) => {
                                    setAuditRemark(e.target.value)
                                }} />
                        }

                    </Form.Item>
                </Form>
            </div>
            {
                props && props.data.xq == 1 ? null :
                    <div style={{ textAlign: 'right' }}>
                        <Button onClick={() => { rejected(1) }}>驳回</Button>
                        <Button type="primary" style={{ marginLeft: 20 }} onClick={() => { pass(0) }}>通过</Button>
                    </div>
            }



        </div>
    )
}

export default LnstitutionsInList;