import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import './style.less'
import {
    Form,
    Row,
    Col,
    Button,

} from 'antd';
const LnstitutionsInList = (props) => {

    let [count, setCount] = useState(1);
    const navigate = useNavigate()
    const { state } = useLocation();
    useEffect(() => {
        console.log(props)
    }, [])
    const cell = () => {
        navigate(-1)
    }

    const add = () => {
        if (count == 3) return false
        setCount(count + 1)
    }

    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            <div>
                <Form
                    name="basic"
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    autoComplete="off"
                >
                    <div style={{ width: '100%', marginLeft: 20, marginTop: 20, marginBottom: 20 }} className='borderBottom'>基本信息</div>
                    <Form.Item label="支出机构：" >
                        {props && props.data.payerMerchantName}
                    </Form.Item>
                    <Form.Item label="收入机构：">
                        {props && props.data.receiptMerchantName}
                    </Form.Item>
                    <Form.Item label="单据类型：">
                        {props && props.data.expenseTypeName}
                    </Form.Item>
                    <Form.Item label="单据名称：">
                        {props && props.data.expenseName}
                    </Form.Item>

                    <div style={{ width: '100%', marginTop: 20, marginLeft: 20, marginBottom: 20 }} className='borderBottom'>单据信息</div>
                    <Form.Item label="收支类型：">
                        {props && props.data.expensePayType == 1 ? '定额' : ''}
                        {props && props.data.expensePayType == 2 ? '比例' : ''}
                        {props && props.data.expensePayType == 3 ? '区间' : ''}
                    </Form.Item>
                    <Form.Item label="单据金额：" name="yinhang">
                        {props && props.data.expensePayType == 1 ? <span>{props.data.expensePayAmount}</span> : ''}
                        {props && props.data.expensePayType == 2 ? <span>{props.data.expensePayProportion}%</span> : ''}
                        {props && props.data.expensePayType == 3 ? <span>{props.data.expensePaySectionJson}</span> : ''}
                    </Form.Item>

                    <Form.Item label="结算方式：">
                        {props && props.data.settleType == 1 ? '日' : ''}
                        {props && props.data.settleType == 2 ? '周' : ''}
                        {props && props.data.settleType == 3 ? '月' : ''}
                    </Form.Item>

                    <Form.Item label="支出账户：" >
                        {props && props.data.payAccountName}
                    </Form.Item>
                    <Form.Item label="收入账户：" >
                        {props && props.data.receiptAccountName}
                    </Form.Item>
                    <Form.Item label="单据有效期：" >
                        {props && props.data.expireStartTime}-{props && props.data.expireEndTime}
                    </Form.Item>

                    <div style={{ width: '100%', marginTop: 20, marginLeft: 20, marginBottom: 20 }} className='borderBottom'>创建信息</div>
                    <Form.Item label="创建人：">
                        {props && props.data.operator ? props.data.operator : localStorage.operatorname ? localStorage.operatorname : undefined}
                    </Form.Item>
                    <Form.Item label="创建时间：">
                        {props && props.data.createTime ? props.data.createTime : moment().format('YYYY-MM-DD HH:mm:ss')}
                    </Form.Item>
                    {/* <Row>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Button style={{ marginLeft: 20 }} onClick={cell}>取消</Button>

                        </Col>
                    </Row> */}
                </Form>
            </div>



        </div>
    )
}

export default LnstitutionsInList;