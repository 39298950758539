
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Row, Col, Table, Popconfirm, Modal } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { expenseTypeListRequest, expenseTypeEditRequest, expenseTypeDeleteRequest } from '../../../api/api'
const BillsType = () => {
    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    const [, forceUpdate] = useState({}); // To disable submit button at the beginning.
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [typeName, setTypeName] = useState('')
    const [row, setRow] = useState('')
    const [list, setList] = useState([])
    useEffect(() => {
        getExpenseTypeListRequest()
    }, []);

    const onSearch = (value) => {
        getExpenseTypeListRequest({ expenseTypeName: value })
    };
    const { Search } = Input
    const [total, setTotal] = useState()
    const navigate = useNavigate()
    const columns = [
        {
            title: '类型名称',
            dataIndex: 'expenseTypeName',
            key: 'expenseTypeName',
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 140,
            align: 'right',
            render: (text, row) => (
                <>
                    {/* <Link to="/billsDetail">编辑</Link> */}
                    <Button type="link" onClick={() => showModal(row)}>编辑</Button>
                    <Popconfirm

                        title="是否确认删除?"
                        onConfirm={() => {
                            deleteType({ id: row.id })
                        }}
                        onCancel={() => {

                        }}
                        okText="是"
                        cancelText="否"
                    >
                        <a style={{ marginLeft: 10 }} href="#">删除</a>
                    </Popconfirm>
                </>

            ),

        },
    ];
    const deleteType = (id) => {

        (async _ => {
            return await expenseTypeDeleteRequest(id);
        })().then(res => {
            if (res.code == 0) {
                getExpenseTypeListRequest();
            } else {

            }
        });
    }
    const getExpenseTypeListRequest = (data) => {
        (async _ => {
            return await expenseTypeListRequest(
                {
                    ...data,
                    currentPage: 1,
                    pageSize: 10,
                    merchantCode: localStorage.merchantCode,
                    superMerchantCode: localStorage.appid
                }
            );
        })().then(res => {
            if (res.code == 0) {
                setList(res.data.expenseTypeVOs)
            } else {

            }
        });
    }
    const expenseTypeEdit = (data) => {
        (async _ => {
            return await expenseTypeEditRequest({
                ...data,
                id: row && row.id,
                merchantCode: localStorage.merchantCode,
                superMerchantCode: localStorage.appid
            });
        })().then(res => {
            if (res.code == 0) {
                getExpenseTypeListRequest()
            } else {

            }
        });
    }
    const showModal = (row) => {
        setRow(row)
        form.setFieldsValue({
            expenseTypeName: row.expenseTypeName
        })
        setIsModalVisible(true);
    };


    const onCreate = (values) => {
        expenseTypeEdit(values)
        setIsModalVisible(false);
        getExpenseTypeListRequest();
    };
    const onCancel = () => {
        setIsModalVisible(false);
    };
    const typeChange = (e) => {
        setTypeName(e.target.value)
    }
    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            <Modal
                visible={isModalVisible}
                title="新建费用单类型"
                okText="确定"
                cancelText="取消"
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            onCreate(values);
                        })
                        .catch((info) => {
                        });
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        modifier: 'public',
                    }}
                >
                    <Form.Item
                        name="expenseTypeName"
                        label="类型名称"
                        rules={[
                            {
                                required: true,
                                message: '请输入类型名称',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                </Form>
            </Modal>
            <Form name="horizontal_login" >
                <Row style={{ padding: 20 }} gutter={24} justify='space-between'>
                    <Form.Item>
                        <Button
                            type="primary"
                            onClick={showModal}
                        >
                            新建类型
                        </Button>
                    </Form.Item>
                    <Form.Item
                        name="expenseTypeName"
                    >
                        <Search
                            placeholder="搜索类型名称"
                            allowClear
                            enterButton="查询"
                            size="large"
                            onSearch={onSearch}
                        />

                    </Form.Item>
                </Row>
                <Table
                    locale={{
                        emptyText: "没有更多数据了"
                    }}
                    style={{ marginTop: 20 }}
                    columns={columns}
                    dataSource={list}
                    scroll={{
                        x: 1300,
                    }}
                    rowKey="id"
                    pagination={{
                        total,
                        // current: this.state.params.pageNo,
                        showSizeChanger: true,
                        // position: ["bottomCenter"],
                        // pageSize: this.state.params.pageSize,
                        showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                        onChange: (pageNo) => {
                            // this.loadData({ pageNo });
                        },
                        onShowSizeChange: (pageNo, pageSize) => {
                            // this.loadData({ pageNo, pageSize });
                        },
                    }}
                />
            </Form>

        </div>
    )
}
export default BillsType;