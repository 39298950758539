import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import NP from 'number-precision'
import {
  Row,
  Col,
  Table,
  message,
  Modal,
} from 'antd';
import { accountLogListRequest, relationRecordRequest } from '../../../../api/api'
import { useCallbackState } from '../../../../util/useCallbackState'
import Detail from '../../../StatementList/detail/index'


const BillingDetails = () => {
  const [list, setList] = useState([])
  const [data, setData] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useCallbackState(1)
  const [pageNum, setPageNum] = useCallbackState(10)
  const [guanlianList, setGuanlianList] = useState([])
  const [total, setTotal] = useState()
  const [detailVisible, setDetailVisible] = useState(false);
  const [row, setRow] = useState(null)
  let navigate = useNavigate();
  const { state } = useLocation();
  useEffect(() => {
    accountLogList()
  }, [pageNum, currentPage])
  const accountLogList = () => {
    let params = {
      merchantCode: state && state.merchantCode,
      subMerchantCode: state && state.merchantCode,
      accountType: state && state.accountType ? state.accountType : 0,
      startTime: state && state.startDate,
      endTime: state && state.endDate,
      superMerchantCode: localStorage.appid,
      pageSize: pageNum,
      currentPage: currentPage,
    };
    (async _ => {
      return await accountLogListRequest(params);
    })().then(res => {
      if (res.code == 0) {
        setList(res.data.lists)
        setTotal(res.data.totalCount)
      } else {
        message.error(res.msg)
      }
    });
  }
  const detailShowModal = (data) => {
    setRow(data)
    setDetailVisible(true);
  };

  const detailHandleOk = () => {
    setDetailVisible(false);
  };

  const detailhandleCancel = () => {
    setDetailVisible(false);
  };
  const columns = [
    {
      title: '类型',
      dataIndex: 'busiType',
      key: 'busiType',
    },
    {
      title: '入账时间',
      dataIndex: 'createTime',
      key: 'createTime',
    },

    {
      title: '订单编号｜支付单号',
      dataIndex: 'orderNo',
      key: 'orderNo',
      render: (text, row) => {
        return (
          <div>
            <div>{row.orderNo}</div>
            <div style={{ color: '#969799' }}>{row.thirdTradeNo}</div>
          </div>
        )
      }
    },
    {
      title: '账户',
      dataIndex: 'accountType',
      key: 'accountType',
      render: (text, row) => {
        return (
          <div>
            {row.accountType == 0 ? '店铺余额待结算' : ''}
            {row.accountType == 1 ? '可用账户' : ''}
            {row.accountType == 2 ? '钱包账户' : ''}
            {row.accountType == 3 ? '信用可提现账户' : ''}
            {row.accountType == 4 ? '钱包余额待结算' : ''}
            {row.accountType == 5 ? '收益账户' : ''}
            {row.accountType == 6 ? '授信账户' : ''}
            {row.accountType == 7 ? '营销余额待结算' : ''}
            {row.accountType == 8 ? '营销余额' : ''}
          </div>
        )
      }
    },
    {
      title: '收支类型',
      dataIndex: 'orderNo',
      key: 'orderNo',
      render: (text, row) => {
        return (
          <div>{row.type == 0 ? '收入' : '支出'}</div>
        )
      }
    },
    {
      title: '金额(元)',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, row) => (
        <div>
          <span style={{ color: 'red', marginRight: 10 }}>{row.errorSettleStatus != 0 ? '轧差' : null}</span>
          <span >{text}</span>
        </div>
      ),
    },
    {
      title: '余额(元)',
      dataIndex: 'afCaBalance',
      key: 'afCaBalance',
    },
    {
      title: '支付方式',
      dataIndex: 'payType',
      key: 'payType',
      render: (text, row) => {
        return (
          <div>
            {row.payType == 0 ? '微信' : ''}
            {row.payType == 1 ? '支付宝' : ''}
            {row.payType == 2 ? '会员' : ''}
            {row.payType == 3 ? '银行卡' : ''}
            {row.payType == 4 ? '现金' : ''}
            {row.payType == 5 ? '云闪付' : ''}
            {row.payType == 6 ? '漕河泾(饿了么)' : ''}
            {row.payType == 7 ? '组合支付' : ''}
            {row.payType == 17 ? '翼支付' : ''}

          </div>
        )
      }
    },
    {
      title: '操作',
      key: 'action',
      align: 'right',
      render: (text, row) => (
        <>
          <span style={{ color: '#165BD4', cursor: 'pointer' }} onClick={() => {
            detailShowModal(row)
            // navigate('/ws/statementList/statementListdetail', { replace: false, state: row })
          }}>详情</span>
          <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }} onClick={() => {
            showModal(row)
          }} >关联记录</span>
          {/* <Link style={{ marginLeft: 10 }} to="">关联记录</Link> */}
        </>

      ),
    },
  ];
  const columns1 = [
    {
      title: '类型',
      dataIndex: 'busiType',
      key: 'busiType',
    },
    {
      title: '业务单号｜支付流水号',
      dataIndex: 'address',
      key: '2',
      render: (text, row) => (
        <div>
          <div >{row.orderNo}</div>
          <div style={{ color: '#969799' }}>{row.thirdTradeNo}</div>
        </div>
      ),
    },
    {
      title: '账户',
      dataIndex: 'accountType',
      key: 'accountType',
      render: (text, row) => {
        return (
          <div>
            {row.accountType == 0 ? '店铺余额待结算' : ''}
            {row.accountType == 1 ? '可用账户' : ''}
            {row.accountType == 2 ? '钱包账户' : ''}
            {row.accountType == 3 ? '信用可提现账户' : ''}
            {row.accountType == 4 ? '钱包余额待结算' : ''}
            {row.accountType == 5 ? '收益账户' : ''}
            {row.accountType == 6 ? '授信账户' : ''}
            {row.accountType == 7 ? '营销余额待结算' : ''}
            {row.accountType == 8 ? '营销余额' : ''}
          </div>
        )
      }
    },
    {
      title: '收支类型',
      dataIndex: 'address',
      key: '3',
      render: (text, row) => {
        return (
          <div>{row.type == 0 ? '收入' : '支出'}</div>
        )
      }
    },
    {
      title: '金额(元)',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: '余额(元)',
      dataIndex: 'bfCaBalance',
      key: 'bfCaBalance',
    },
    {
      title: '支付方式',
      dataIndex: 'payType',
      key: 'payType',
      render: (text, row) => {
        return (
          <div>
            {row.payType == 0 ? '微信' : ''}
            {row.payType == 1 ? '支付宝' : ''}
            {row.payType == 2 ? '会员' : ''}
            {row.payType == 3 ? '银行卡' : ''}
            {row.payType == 4 ? '现金' : ''}
            {row.payType == 5 ? '云闪付' : ''}
            {row.payType == 6 ? '漕河泾(饿了么)' : ''}
            {row.payType == 7 ? '组合支付' : ''}
            {row.payType == 17 ? '翼支付' : ''}

          </div>
        )
      }
    },
  ];
  const relationRecord = (data) => {
    let params = {
      currentPage: currentPage,
      pageSize: pageNum,
      orderNo: data.orderNo
    };
    (async _ => {
      return await relationRecordRequest(params);
    })().then(res => {
      if (res.code == 0) {
        setGuanlianList(res.data.lists)
      } else {
        message.error(res.msg)
      }
    });
  }
  const showModal = (data) => {
    relationRecord(data)
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onChangPage = (currentPage, pageNum) => {
    setCurrentPage(currentPage, () => {
      // relationRecord()
    })
  }
  const onChangPageNum = (currentPage, pageNum) => {
    setPageNum((currentPage, pageNum), () => {
    })
  }
  return (
    <div style={{ height: '100vh', background: '#ffffff', paddingBottom: 20 }}>

      <Row align='middle' style={{ width: '100%', padding: '10px 0', marginTop: 10, }}>
        <Row justify='space-between' style={{ width: '100%', background: '#F7F8FA', padding: 10, margin: '0 10px' }}>
          <Col span={12}>
            <div style={{ fontSize: 18, fontWeight: 500, }}>账单日期：{state && state.startDate && state.startDate.substring(0, 10)} 账单汇总详情</div>
            <div>
              <span style={{ color: '#969799' }}>商户名称：</span>
              <span>{state && state.merchantName}</span>
            </div>

          </Col>
          <Col span={12}>
            <div style={{ fontSize: 18, fontWeight: 500, color: '#F7F8FA' }}>
              <span style={{ color: '#323233' }}>结算单号：{state && state.settleBillNo}</span>
              {/* <span>{state && state.startDate}~{state && state.endDate}</span> */}
            </div>
            <div>
              <span style={{ color: '#969799' }}>结算日期：</span>
              <span>{state && state.settleDate}</span>
            </div>
          </Col>
        </Row>
      </Row>
      <Row justify='space-between' style={{ background: '#F7F8FA', padding: 20, margin: 10 }}>
        <Col span={4}>
          <div>
            <span>本期结算金额(元)</span>
          </div>
          <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>
            <span>{state && state.totalSettleAmount}</span>
            {
              state && state.errorSettleStatus != 0 ?
                <span style={{ color: 'red', fontSize: 12, marginLeft: 10 }}>轧差{state && state.errorSettleAmount}</span>
                : null
            }

          </div>
        </Col>
        <Col span={4}>
          <div><span>本期实收金额(元)</span></div>
          <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>{state && state.totalReceiptAmount}</div>
        </Col>
        <Col span={4}>
          <div><span>本期退款(元)</span></div>
          <Row align='middle' style={{ marginTop: 10 }}>
            <span style={{ fontSize: 24, fontWeight: 500 }}>{state && (NP.plus(state.refundWechatAmount, state.refundAlipayAmount))}</span>

          </Row>

        </Col>
        <Col span={4}>
          <div><span>本期手续费金额(元)</span></div>
          <Row align='middle' style={{ marginTop: 10 }}>
            <span style={{ fontSize: 24, fontWeight: 500 }}>{state && (NP.minus(state.feeTradeAmount, state.refundFeeAmount))}</span>
          </Row>

        </Col>
        <Col span={4}>
          <div><span>费用单金额(元)</span></div>
          <Row align='middle' style={{ marginTop: 10 }}>
            <span style={{ fontSize: 24, fontWeight: 500 }}>{state && state.costFeeAmount}</span>
          </Row>

        </Col>
      </Row>
      <Row align='middle' style={{ padding: '10px 10px', marginTop: 10 }}>
        <Row align='middle' justify='space-between' style={{ padding: '10px 10px', width: '100%', height: '100%', background: '#F7F8FA' }}>
          <Row align='middle' >
            <div style={{ background: '#145BD5', height: 14, width: 3, }}></div><div style={{ marginLeft: 10, fontWeight: 500 }}>收支记录</div>
          </Row>
        </Row>
      </Row>

      <Row style={{ padding: "0px 10px" }}>
        <Col span={24}>
          <Table
            locale={{
              emptyText: "没有更多数据了"
            }}
            columns={columns}
            rowKey='id'
            dataSource={list}
            pagination={{
              total,
              showSizeChanger: true,
              current: currentPage,
              pageSize: pageNum,
              showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
              onChange: (k, v) => onChangPage(k, v),
              onShowSizeChange: (k, v) => onChangPageNum(k, v),
            }}
          />
        </Col>

      </Row>
      <Modal
        title="所有关联记录"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1200}
        footer={null}
      >
        <Table
          locale={{
            emptyText: "没有更多数据了"
          }}
          rowKey='id'
          style={{ marginTop: 20 }}
          columns={columns1}
          dataSource={guanlianList}
          pagination={{
            total,
            showSizeChanger: true,
            current: currentPage,
            pageSize: pageNum,
            // position: ["bottomCenter"],
            showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
            onChange: (k, v) => onChangPage(k, v),
            onShowSizeChange: (k, v) => onChangPageNum(k, v),
          }}
        />
      </Modal>
      <Modal
        title="详情"
        visible={detailVisible}
        onOk={detailHandleOk}
        onCancel={detailhandleCancel}
        width={1200}
        footer={null}
      >
        <Detail data={row} />
      </Modal>
    </div>

  )
}
export default BillingDetails;
