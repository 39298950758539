import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
    Form,
    Row,
    Col,
    Button,
    Input,
    Select,
    Table,
    Popconfirm,
    message,
    Modal,
    Typography,
    Upload,
    Tooltip,
    Space,
    Radio
} from 'antd';
import { listByLoginNameRequest, accountCreateRequest } from '../../../api/api'
import { useCallbackState } from '../../../util/useCallbackState'
import host from '../../../util/host';

import './style.less'
const { Option } = Select;
const { Title } = Typography;
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
};
const CostAdd = (props) => {
    const [data, setData] = useState(null)
    const [name, setName] = useState(null)
    useEffect(() => {
        console.log(localStorage.operatorId)
    }, [])



    const [form] = Form.useForm();



    const onFinish = (values) => {
    };


    const onSave = async () => {
        (async _ => {
            return await accountCreateRequest({
                employeeId: data && data.employeeId,
                merchantCode: localStorage.merchantCode,
                superMerchantCode: localStorage.appid,
                operaterId: localStorage.operatorId,
            });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                props.callback()
                props.closeDialog()
            } else {
                message.error(res.msg)
            }
        });

    }
    const listByLoginName = () => {
        if (name) {
            (async _ => {
                return await listByLoginNameRequest({
                    loginName: name,
                    merchantCode: localStorage.merchantCode,
                    superMerchantCode: localStorage.appid,
                });
            })().then(res => {
                console.log(res);
                if (res.code == 0) {
                    setData(res.data.employeeList && res.data.employeeList[0])
                } else {
                    message.error(res.msg)
                }
            });
        } else {
            message.error('请输入员工账号')
        }

    }


    return (
        <div style={{ background: '#ffffff', padding: 16, marginTop: 16 }}>


            <Form
                {...layout}
                form={form}
                onFinish={onFinish}
                initialValues={{
                    expenseStatus: null,
                    merchantCode: localStorage.merchantCode
                }}
                style={{ paddingBottom: 0 }}
            >
                {/* <Form.Item
                    name="costName"
                    label="同步方式"
                    rules={[
                        {
                            required: true,
                            message: '请输入单据名称!',
                        },
                    ]}
                // style={{ marginLeft: 30 }}
                >
                    <Radio.Group onChange={() => {

                    }} >
                        <Radio value={1}>手工同步</Radio>
                        <Radio value={2}>接口同步</Radio>
                    </Radio.Group>
                </Form.Item> */}
                <Form.Item
                    name="costName"
                    label="员工账号"
                    rules={[
                        {
                            required: false,
                            message: '请输入单据名称!',
                        },
                    ]}
                    style={{ marginBottom: 0 }}
                >
                    <div style={{ display: 'flex' }}>
                        <Form.Item
                            label=""
                            colon={false}
                            rules={[
                                {
                                    required: true,
                                    message: '请输入单据名称!',
                                },
                            ]}
                        >
                            <Input style={{ width: 200 }} placeholder="请输入" onChange={(e) => {
                                setName(e.target.value)
                            }} />
                        </Form.Item>
                        <Form.Item
                            label=" "
                            colon={false}
                            rules={[
                                {
                                    required: true,
                                    message: '请输入单据名称!',
                                },
                            ]}
                        // style={{ marginLeft: 30 }}
                        >
                            <Button onClick={() => {
                                listByLoginName()
                            }}>搜索</Button>
                        </Form.Item>
                    </div>

                </Form.Item>
                <Form.Item
                    name="accountType"
                    label="员工名称"
                    rules={[
                        {
                            required: false,
                            message: '请选择支出账户!',
                        },
                    ]}
                // style={{ marginLeft: 30 }}
                >
                    {data && data.employeeName}
                </Form.Item>
                <Form.Item
                    style={{ marginTop: 26 }}
                    name="payerMerchantCodesArry"
                    label="联系方式"
                    rules={[
                        {
                            required: false,
                            message: '请选择支出机构!',
                        },
                    ]}
                >
                    {data && data.mobile}
                </Form.Item>
                {/* <Form.Item
                    style={{ marginTop: 26 }}
                    name="payerMerchantCodesArry"
                    label="所属组织"
                    rules={[
                        {
                            required: false,
                            message: '请选择支出机构!',
                        },
                    ]}
                >
                    {data && data.mobile}
                </Form.Item> */}
                <Row>
                    <Col span={24} style={{ textAlign: 'center', marginTop: 16 }}>
                        <Button onClick={() => {
                            props.closeDialog()
                        }}>
                            取消
                        </Button>
                        <Button type="primary"
                            style={{ marginLeft: 16 }}
                            // disabled={aipv > 0}
                            onClick={() => {
                                onSave()
                            }}
                        >
                            保存
                        </Button>


                    </Col>
                </Row>
            </Form >

        </div >
    )
}
export default CostAdd;