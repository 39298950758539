import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
    Form,
    Row,
    Col,
    Button,
    Input,
    Select,
    Table,
    Popconfirm,
    message,
    Modal,
    DatePicker,
    Tooltip,

} from 'antd';
import {
    merchantListRequest,
    employeeListRequest,
    updateStatusByIdsRequest
} from '../../api/api'
import { useCallbackState } from '../../util/useCallbackState'
import Add from './add/index'
const { Option } = Select;
const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
};

const LnstitutionsInList = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [total, setTotal] = useState()
    const [list, setList] = useState([])
    const [data, setData] = useState(null)
    const [mianqian, setMianqian] = useState(false)
    const [qrCode, setQrcode] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [row, setRow] = useState(null)
    const [date, setDate] = useState(null)
    const [merchantdataList, setMerchantdataList] = useState([])
    const superMerchantCode = localStorage.appid
    const [init, setInit] = useState(true)
    const { search, state } = useLocation()
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [bankType, setBankType] = useState(null)

    useEffect(() => {
            employeeList()
    }, [currentPage, pageNum])
    const columns = [
        {
            title: '账户',
            dataIndex: 'employeeNo',
            key: 'employeeNo',
        },
        {
            title: '员工姓名',
            dataIndex: 'employeeName',
            key: 'employeeName',
        },
        {
            title: '联系方式',
            dataIndex: 'employeeMobile',
            key: 'employeeMobile',
        },
        {
            title: '所属机构/机构',
            dataIndex: 'merchantName',
            key: 'merchantName',
        },
        // {
        //     title: '所属部门/角色',
        //     dataIndex: 'accountOpenStatus',
        //     key: 'accountOpenStatus',
        // },
        {
            title: '操作人',
            dataIndex: 'operaterName',
            key: 'operaterName',
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        // {
        //     title: '状态',
        //     dataIndex: 'accountStatus',
        //     key: 'accountStatus',
        //     render: (text, row) => (
        //         <>
        //             {text == 0 ? '正常' : ''}
        //             {text == 1 ? '停用' : ''}
        //             {text == 2 ? '删除' : ''}
        //         </>

        //     ),
        // },
        {
            title: '入驻状态',
            dataIndex: 'accountSettleStatus ',
            key: 'accountSettleStatus ',
            render: (text, row) => (
                <>
                    {row.accountSettleStatus == 0 ? '未入驻' : ''}
                    {row.accountSettleStatus == 1 ? '已入驻' : ''}
                    {row.accountSettleStatus == 2 ? '入驻失败' : ''}
                </>

            ),
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            // width: 200,
            align: 'right',
            render: (text, row) => (
                <>
                    {
                        row.accountSettleStatus == 0 ?
                            <span style={{ color: '#165BD4', cursor: 'pointer', }} onClick={() => {
                                navigate('/ws/individualSettlement', { replace: false, state: row })
                            }}>入驻</span>
                            : null
                           
                    }
 {
                        row.accountSettleStatus == 1 ?
                            <span style={{ color: '#165BD4', cursor: 'pointer', }} >已入驻</span> : null
                    }
                    {
                        row.accountSettleStatus == 2 ?
                            <span style={{ color: '#165BD4', cursor: 'pointer', }} onClick={() => {
                                row.edit = 0
                                navigate('/ws/individualSettlement', { replace: false, state: row })
                            }}>编辑</span>
                            : ''
                            
                    }
                    {/* {
                        row.accountStatus == 0 || row.accountStatus != 2 ?
                            <Popconfirm
                                title="是否确认删除?"
                                onConfirm={() => {
                                    console.log('confirm');
                                    (async _ => {
                                        return await updateStatusByIdsRequest({
                                            employeeIdArr: [row.id],
                                            accountStatus: 2,
                                            superMerchantCode: localStorage.appid,
                                        });
                                    })().then(res => {
                                        if (res.code == 0) {
                                            console.log(res)
                                            employeeList()
                                        } else {
                                            message.error(res.msg)
                                        }
                                    });
                                }}
                                onCancel={() => {

                                }}
                                okText="是"
                                cancelText="否"
                            >
                                <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }}>删除</span>
                            </Popconfirm> : ''
                    } */}
                    {/* {
                        row.accountStatus == 0 || row.accountStatus == 1 ?
                            <Popconfirm
                                title="是否确认停用?"
                                onConfirm={() => {
                                    console.log('confirm');
                                    (async _ => {
                                        return await updateStatusByIdsRequest({
                                            employeeIdArr: [row.id],
                                            accountStatus: 1,
                                            superMerchantCode: localStorage.appid,
                                        });
                                    })().then(res => {
                                        if (res.code == 0) {
                                            console.log(res)
                                            employeeList()
                                        } else {
                                            message.error(res.msg)
                                        }
                                    });
                                }}
                                onCancel={() => {

                                }}
                                okText="是"
                                cancelText="否"
                            >
                                <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }}>停用</span>
                            </Popconfirm> : ''
                    } */}

                </>

            ),

        },
    ];

   
    const onFinish = (values) => {
        values.currentPage = 1;
        employeeList(values)
    };




    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const goAdd = () => {
        setIsModalVisible(true)
    }





    const mianqianHandleCancel = () => {
        setMianqian(false);
    };
    const onChangPage = (currentPage, pageNum) => {
        setCurrentPage(currentPage, () => {
            // merchantList()
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        setPageNum((currentPage, pageNum), () => {
            // merchantList()
        })
    }
    const employeeList = (data) => {
        let values = form.getFieldValue()
        let params = {
            ...data,
            ...values,
            currentPage: currentPage,
            pageSize: pageNum,
            superMerchantCode: localStorage.appid,
            merchantCode: localStorage.salesEmployeeId == 'null' ? localStorage.merchantCode : undefined,
            employeeId: localStorage.salesEmployeeId != 'null' ? localStorage.salesEmployeeId : null,
        };
        (async _ => {
            return await employeeListRequest(params);
        })().then(res => {
            if (res.code == 0) {
                console.log(res)
                setList(res.data.employeeVOS)
                setTotal(res.data.totalCount)
            } else {
                message.error(res.msg)
            }
        });
    }
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(selectedRowKeys);
            setSelectedRowKeys(selectedRowKeys)
        },
        onSelect: (record, selected, selectedRows, nativeEvent) => {
            console.log(record, selected, selectedRows, nativeEvent)

        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            console.log(selected);
        },
        onSelectNone: (a) => {
            console.log(a);
        },
        onSelectInvert: (selectedRowKeys) => {
            console.log(selectedRowKeys)
        },
        getCheckboxProps: (record) => ({
            // disabled: record.status !== 0,
        }),
        // getCheckboxProps: (record) => ({
        //     disabled: record.auditStatus !== 0,
        //     // Column configuration not to be checked
        //     name: record.name,
        // }),
    };
    const updateStatusByIds = () => {
        let params = {
            employeeIdArr: selectedRowKeys,
            // merchantCode: localStorage.merchantCode,
            accountStatus: 2,
            superMerchantCode: localStorage.appid,
        };
        (async _ => {
            return await updateStatusByIdsRequest(params);
        })().then(res => {
            if (res.code == 0) {
                console.log(res)
                employeeList()
            } else {
                message.error(res.msg)
            }
        });
    }
    return (
        <div style={{ background: '#ffffff', padding: 16, marginTop: 16 }}>
            {
                localStorage.salesEmployeeId == 'null' ?
                    <Row style={{ marginBottom: '-1vh' }}>
                        <Button type='primary' onClick={goAdd}>添加</Button>
                    </Row> : null
            }

            <Form
                form={form}
                {...layout}
                name="advanced_search"
                className="ant-advanced-search-form"
                onFinish={onFinish}
                initialValues={{
                    status: null
                }}
                style={{ marginTop: 16, background: '#F7F8FA', padding: 16, paddingBottom: 0 }}
            >

                <Row style={{ marginTop: 20, background: '#F7F8FA', marginBottom: '-1vh' }}>
                    <Col span={8}>
                        <Form.Item
                            name="employeeName"
                            label="员工名称"
                        >
                            <Input placeholder='请输入员工名称' style={{ width: 200 }} />
                        </Form.Item>
                    </Col>
                    {/* <Col span={8}>
                        <Form.Item
                            name="status"
                            label="机构类型"
                        >
                            <Select style={{ width: 200 }}>
                                <Option value={null}>全部</Option>
                                <Option value="0">待审核</Option>
                                <Option value="1">审核通过</Option>
                                <Option value="2">审核失败</Option>
                            </Select>
                        </Form.Item>
                    </Col> */}
                </Row>
                <Row style={{ marginBottom: '-1vh' }}>
                    <Col span={8} >
                        <Form.Item
                            wrapperCol={{
                                offset: 5,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                筛选
                            </Button>
                            {/* <Button
                                style={{ margin: '0 8px' }}

                            >
                                导出
                            </Button>
                            <Button type="link"  >
                                查看已导出列表
                            </Button> */}
                            <Button type="link" onClick={() => {
                                form.resetFields();
                                employeeList()
                            }} >
                                重置筛选条件
                            </Button>
                        </Form.Item>


                    </Col>
                </Row>
            </Form>

            <Table
                // rowSelection={{
                //     ...rowSelection,
                // }}
                locale={{
                    emptyText: "没有更多数据了"
                }}
                style={{ marginTop: 20 }}
                columns={columns}
                dataSource={list}
                rowKey="id"
                scroll={{
                    x: 1400,
                }}
                pagination={{
                    total,
                    showSizeChanger: true,
                    current: currentPage,
                    pageSize: pageNum,
                    // position: ["bottomCenter"],
                    showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                    onChange: (k, v) => onChangPage(k, v),
                    onShowSizeChange: (k, v) => onChangPageNum(k, v),
                }}
            // footer={() => {
            //     return (
            //         <div>
            //             <Popconfirm
            //                 title="是否确认批量删除？"
            //                 onConfirm={() => {
            //                     updateStatusByIds()

            //                 }}
            //                 onCancel={() => {
            //                     console.log('取消');
            //                 }}
            //                 okText="是"
            //                 cancelText="否"
            //             >
            //                 <Button style={{ marginLeft: 0 }} disabled={selectedRowKeys.length == 0}>批量删除</Button>
            //             </Popconfirm>

            //         </div>

            //     )
            // }}
            />
            <Modal
                width={500}
                footer={null}
                title="添加员工" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Add
                    closeDialog={() => {
                        setIsModalVisible(false)
                    }}
                    callback={(state) => {
                        console.log(state)
                        employeeList()
                        // message.success('批量修改成功！')
                    }}
                />
            </Modal>
        </div>
    )
}
export default LnstitutionsInList;