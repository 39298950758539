import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  Form,
  Input,
  Switch,
  Row,
  Col,
  Button,
  Tabs,
  Table,
  Modal,
  Radio,
  Space,
  Skeleton,
  Descriptions 
} from 'antd';
import host from '../../../util/host';

const { TabPane } = Tabs;
const FormItem = Form.Item;
const Search = Input.Search;

const TransactionDetails = () => {
  let navigate = useNavigate();
  const { state } = useLocation();
  useEffect(() => {
    console.log(state)
  }, [])
  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };
  const goAdd = () => {
    navigate('/initialConfigurationAdd')
  }
  const createNewArr = (data) => {
    return data.reduce((result, item) => {
      if (result.indexOf(item.name) < 0) {
        result.push(item.name)
      }
      return result
    }, []).reduce((result, code) => {
      const children = data.filter(item => item.name === code);
      result = result.concat(
        children.map((item, index) => ({
          ...item,
          rowSpan: index === 0 ? children.length : 0,
        }))
      )
      return result;
    }, [])
  }
  const columns = [
    {
      title: '订单编号/支付流水号',
      dataIndex: 'orderNo',
      key: 'orderNo',
      render: (text, row) => {
        return (
          <div>
            <div>{row.orderNo}</div>
            <div>{row.thirdTradeNo}</div>
          </div>
        )
      }
    },
    {
      title: '付款时间',
      dataIndex: 'tradeTime',
      key: 'tradeTime',
    },
    {
      title: '订单类型',
      dataIndex: 'orderType',
      key: 'orderType',
      render: (text, row) => {
        return (
          <div>
            {row.orderType == 0 ? '消费收银' : ''}
            {row.orderType == 1 ? '会员充值' : ''}
            {row.orderType == 2 ? '付费会员' : ''}
            {row.orderType == 3 ? '付费卡券' : ''}
            {row.orderType == 4 ? '线上商城' : ''}
            {row.orderType == 5 ? '活动订单' : ''}
            {row.orderType == 6 ? '押金订单' : ''}
            {row.orderType == 7 ? '酒店订单' : ''}
            {row.orderType == 8 ? '采购订单' : ''}
          </div>
        )
      }
    },
    {
      title: '实收金额(元)',
      dataIndex: 'receiptAmount',
      key: 'receiptAmount',
    },
    {
      title: '支付方式',
      dataIndex: 'payType',
      key: 'payType',
      render: (text, row) => (
        <div>
          {row.payType == 0 ? '微信' : ''}
          {row.payType == 1 ? '支付宝' : ''}
          {row.payType == 2 ? '会员' : ''}
          {row.payType == 3 ? '银行卡' : ''}
          {row.payType == 4 ? '现金' : ''}
          {row.payType == 5 ? '云闪付' : ''}
          {row.payType == 6 ? '漕河泾(饿了么)' : ''}
          {row.payType == 7 ? '组合支付' : ''}
          {row.payType == 17 ? '翼支付' : ''}
        </div>
      ),
    },
    {
      title: '付款人',
      dataIndex: 'memberName',
      key: 'memberName',
    },
    {
      title: '操作人',
      dataIndex: 'operatorName',
      key: 'operatorName',
    },


    {
      title: '下单店铺',
      dataIndex: 'merchantName',
      key: 'merchantName',
    },

  ];


  return (
    <div style={{ height: '100vh', background: '#ffffff', paddingBottom: 20 }}>
      <Descriptions title="订单信息" style={{padding:'12px'}} >
      <Descriptions.Item label="订单编号">{state.orderNo}</Descriptions.Item>
      <Descriptions.Item label="付款时间">{state.tradeTime}</Descriptions.Item>
      <Descriptions.Item label="订单类型">  {state.orderType == 0 ? '消费收银' : ''}
            {state.orderType == 1 ? '会员充值' : ''}
            {state.orderType == 2 ? '付费会员' : ''}
            {state.orderType == 3 ? '付费卡券' : ''}
            {state.orderType == 4 ? '线上商城' : ''}
            {state.orderType == 5 ? '活动订单' : ''}
            {state.orderType == 6 ? '押金订单' : ''}
            {state.orderType == 7 ? '酒店订单' : ''}
            {state.orderType == 8 ? '采购订单' : ''}
      </Descriptions.Item>
      <Descriptions.Item label="支付方式">
          {state.payType == 0 ? '微信' : ''}
          {state.payType == 1 ? '支付宝' : ''}
          {state.payType == 2 ? '会员' : ''}
          {state.payType == 3 ? '银行卡' : ''}
          {state.payType == 4 ? '现金' : ''}
          {state.payType == 5 ? '云闪付' : ''}
          {state.payType == 6 ? '漕河泾(饿了么)' : ''}
          {state.payType == 8 ? '组合支付' : ''}
          {state.payType == 17 ? '翼支付' : ''}
      </Descriptions.Item>
      <Descriptions.Item label="结算状态">
                {state && state.settleStatus == 0 ? '未结算' : ''}
                {state && state.settleStatus == 1 ? '已结算' : ''}
                {state && state.settleStatus == 2 ? '结算失败' : ''}
                {state && state.settleStatus == 3 ? '无需结算' : ''}
      </Descriptions.Item>
      <Descriptions.Item label="实收金额">{state.receiptAmount}</Descriptions.Item>
      <Descriptions.Item label="手续费">{state.thirdFee}</Descriptions.Item>
      <Descriptions.Item label="赠送金额">{state.memberGiveAmount}</Descriptions.Item>
      <Descriptions.Item label="付款时间">{state.tradeTime}</Descriptions.Item>
      <Descriptions.Item label="操作人">{state.operatorName}</Descriptions.Item>
      <Descriptions.Item label="交易门店">{state.merchantName}</Descriptions.Item>
      </Descriptions>
      <Row align='middle' style={{ padding: '10px 10px', marginTop: 10 }}>
        <Row align='middle' justify='space-between' style={{ padding: '10px 10px', width: '100%', height: '100%', background: '#F7F8FA' }}>
          <Row align='middle' >
            <div style={{ background: '#145BD5', height: 14, width: 3, }}></div><div style={{ marginLeft: 10, fontWeight: 500 }}>组合支付</div>
          </Row>
        </Row>
      </Row>

      <Row style={{ padding: "0px 10px" }}>
        <Col span={24}>
          <Table
            locale={{
              emptyText: "没有更多数据了"
            }}
            rowKey='id'
            columns={columns}
            dataSource={[state]}
            pagination={false}
          />
        </Col>
      </Row>
    </div>

  )
}
export default TransactionDetails;
