import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment'
import Compressor from 'compressorjs'
import {
    Form,
    Row,
    Col,
    Button,
    Table,
    DatePicker,
    Input,
    Select,
    message,
    Popconfirm,
    Tabs,
    Radio,
    Steps,
    Popover,
    Modal,
    Typography,
    Upload,
    Space,
    Cascader
} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
    areaListRequest,
    bankCardNameRequest,
    bookBankCardEditRequest,
    branchBankListRequest,
    authBaseDetailRequest
} from '../../api/api'

import host from '../../util/host';
import { buyCard } from '../../util/tools'
const { Option } = Select;
const { Step } = Steps;
const { Title } = Typography;
const dateFormat = 'YYYY/MM/DD';
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};
const EnterpriseCard = () => {
    const [imageData5, setImageData5] = useState();
    const [imageData6, setImageData6] = useState();
    const [loading5, setLoading5] = useState(false)
    const [loading6, setLoading6] = useState(false)
    const [options, setOptions] = useState([])
    const [bankCardList, setBankCardList] = useState([])
    const [bankName, setBankName] = useState(null)
    const [branchBankList, setBranchBankList] = useState([])
    const [editId, setEditId] = useState(null)
    const [cardType, setCardType] = useState(null)
    const superMerchantCode = localStorage.appid
    const navigate = useNavigate();
    const [form] = Form.useForm()
    const { state } = useLocation()
    useEffect(() => {
        console.log(state);
        areaList()
        bankCardName()
        if (state.edit == 0) {
            authBaseDetail()

        } else {
        }
    }, [])

    const onFinish = (values) => {
        console.log(values);
        (async _ => {
            return await bookBankCardEditRequest(
                {
                    ...values,
                    operaterId: localStorage.operatorId,
                    operaterName: localStorage.operatorname,
                    merchantCode: localStorage.merchantCode,
                    superMerchantCode,
                    bankCardAUrl: imageData5 && imageData5.liantuo_picture,
                    bankCardAId: imageData5 && imageData5.channel_picture_id,
                    bankCardBUrl: imageData6 && imageData6.liantuo_picture,
                    bankCardBId: imageData6 && imageData6.channel_picture_id,
                    provId: values.seat[0],
                    areaId: values.seat[1],
                    bankName,
                    id: editId ? editId : undefined,
                    openType: 0,
                }
            );
        })().then(res => {
            console.log(res)
            if (res.code == 0) {
                navigate(-1);
                message.success(res.msg)
            } else {
                message.error(res.msg)
            }
        });


    }
    const getBranchBankList = (code) => {
        (async _ => {
            return await branchBankListRequest({ bankCode: code });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setBranchBankList(res.data)
            } else {
                message.error(res.msg)
            }
        });
    }

    const bankCardName = () => {
        (async _ => {
            return await bankCardNameRequest();
        })().then(res => {
            if (res.code == 0) {
                res.data.map((v) => {
                    v.label = v.bankName
                    v.value = v.dgBankCode
                })
                setBankCardList(res.data)
            } else {

            }
        });
    }
    const authBaseDetail = () => {
        console.log(11111);
        (async _ => {
            return await authBaseDetailRequest({
                id: state && state.baseId,
                merchantCode: localStorage.merchantCode,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                let data = res.data;
                form.setFieldsValue({
                    openType: data && data.openType,
                    cardType: data && data.cardType,
                    bankCode: data && data.bankCode,
                    branchName: data && data.branchName,
                    cardNo: data && data.cardNo,
                    cardName: data && data.cardName,
                    seat: data.provId ? [data.provId, data.areaId] : undefined,
                    img5: {
                        liantuo_picture: res.data.bankCardAUrl,
                        channel_picture_id: res.data.bankCardAId
                    },
                    img6: {
                        liantuo_picture: res.data.bankCardBUrl,
                        channel_picture_id: res.data.bankCardBId
                    }
                })
                setBankName(state && state.bankName)
                setImageData5({
                    liantuo_picture: data.bankCardAUrl,
                    channel_picture_id: data.bankCardAId
                })
                setImageData6({
                    liantuo_picture: data.bankCardBUrl,
                    channel_picture_id: data.bankCardBId
                })
                setEditId(data.id)
                setCardType(data && data.cardType)
            } else {

            }
        });
    }


    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('请上传JPG/PNG文件!');
        }
        const isLt6M = file.size / 1024 / 1024 < 6;
        if (!isLt6M) {
            message.error('图片大小不能大于6MB!');
        }
        return isJpgOrPng && isLt6M;
    }
    const handleChange = (info) => {
        console.log(info.file)
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            // getBase64(info.file.originFileObj, (url) => {
            //     console.log(url)

            // });
        }
    };
    const areaList = () => {

        (async _ => {
            return await areaListRequest();
        })().then(res => {
            if (res.code == 0) {
                res.data.map((v) => {
                    v.label = v.provinceName
                    v.value = v.provinceCode
                    v.children = v.cities.map((i) => {
                        return {
                            label: i.cityName,
                            value: i.cityCode,
                        }

                    })
                })
                setOptions(res.data)

            } else {
            }
        });
    }
    const uploadButton5 = (
        <div>
            {loading5 ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    const uploadButton6 = (
        <div>
            {loading6 ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            <Form
                name="basic"
                {...layout}
                form={form}
                onFinish={onFinish}
                initialValues={{
                    shareType: 0,
                    expenseReceiptType: 0,
                    expireType: 0,
                    openType: 0
                }}
            >

                <Title level={5} style={{ marginTop: 16, marginLeft: 16, marginBottom: 20 }}>结算账户信息</Title>

                {/* <Form.Item
                    name="openType"
                    label="主体类型"
                    rules={[{ required: true, message: '请上传结算卡正面!' }]}
                >
                    <Radio.Group
                        onChange={() => {

                        }}
                        style={{
                            marginBottom: 16,
                        }}
                    >
                        <Radio.Button value={0}>企业</Radio.Button>
                        <Radio.Button value={1} style={{ marginLeft: 16 }}>个体户</Radio.Button>
                        <Radio.Button value={2} style={{ marginLeft: 16 }}>个人</Radio.Button>
                    </Radio.Group>

                </Form.Item> */}
                <Form.Item
                    name="cardType"
                    label="卡类型"
                    rules={[{ required: true, message: '请上传结算卡正面!' }]}
                >
                    <Select placeholder="请选择"
                        style={{ width: 200 }}
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.includes(input)}
                        onChange={(e) => {
                            setCardType(e)

                        }}
                    >
                        <Option rowKey={0} key={0} value={0}>对公</Option>
                        <Option rowKey={1} key={1} value={1}>对私</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="img5"
                    label="结算卡正面"
                    rules={[{ required: true, message: '请上传结算卡正面!' }]}
                >
                    <Upload
                        name="file"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={`${host}/channel/upload`}
                        headers={{ 'token': localStorage.token, }}
                        beforeUpload={beforeUpload}
                        onChange={(info) => {
                            if (info.file.status === 'uploading') {
                                setLoading5(true);
                                return;
                            }
                            if (info.file.status === 'done') {
                                console.log(info.file.response.data);
                                form.setFieldsValue({
                                    cardNo: form.getFieldsValue().cardNo ? form.getFieldsValue().cardNo : info.file.response.data.cardNo,
                                    bankCode: form.getFieldsValue().bankCode ? form.getFieldsValue().bankCode : info.file.response.data.bankCode,
                                })
                                setImageData5(info.file.response.data)
                                setLoading5(false);
                                getBranchBankList(info.file.response && info.file.response.data && info.file.response.data.bankCode)
                            }
                        }}
                        data={file => ({ // data里存放的是接口的请求参数
                            picture: file,
                            merchantCode: localStorage.merchantCode,
                            superMerchantCode: localStorage.appid,
                            pictureType: 18,
                        })}
                    >
                        {imageData5 && imageData5.liantuo_picture ? (
                            <img
                                src={imageData5 && imageData5.liantuo_picture}
                                alt="avatar"
                                style={{
                                    width: '100%',
                                    height: '100%'
                                }}
                            />
                        ) : (
                            uploadButton5
                        )}
                    </Upload>

                </Form.Item>
                <Form.Item
                    name="img6"
                    label="结算卡反面"
                    rules={[{ required: true, message: '请上传银行回单!' }]}
                >
                    <Upload
                        name="file"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={`${host}/channel/upload`}
                        headers={{ 'token': localStorage.token, }}
                        beforeUpload={beforeUpload}
                        onChange={(info) => {
                            if (info.file.status === 'uploading') {
                                setLoading6(true);
                                return;
                            }
                            if (info.file.status === 'done') {
                                console.log(info.file.response.data);
                                setImageData6(info.file.response.data)
                                setLoading6(false);
                            }
                        }}
                        data={file => ({ // data里存放的是接口的请求参数
                            picture: file,
                            merchantCode: localStorage.merchantCode,
                            superMerchantCode: localStorage.appid,
                            pictureType: 19,
                        })}
                    >
                        {imageData6 && imageData6.liantuo_picture ? (
                            <img
                                src={imageData6 && imageData6.liantuo_picture}
                                alt="avatar"
                                style={{
                                    width: '100%',
                                    height: '100%'
                                }}
                            />
                        ) : (
                            uploadButton6
                        )}

                    </Upload>

                </Form.Item>
                <Form.Item label="银行户名"
                    name="cardName"
                    rules={[{ required: true, message: '请输入银行户名' }]}
                >
                    <Input placeholder='请输入银行户名' style={{ width: 200 }} />
                </Form.Item>
                <Form.Item label="开户银行"
                    name="bankCode"
                    rules={[{ required: true, message: '请选择开户银行!' }]}
                >
                    <Select placeholder="请选择"
                        style={{ width: 200 }}
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.includes(input)}
                        onChange={(e) => {
                            getBranchBankList(e);
                            bankCardList && bankCardList.map((v) => {
                                if (e == v.dgBankCode) {
                                    setBankName(v.bankName)
                                }
                            })
                        }}
                    >
                        {
                            bankCardList && bankCardList.map((v) => {
                                return <Option rowKey={v.dgBankCode} key={v.dgBankCode} value={v.dgBankCode}>{v.bankName}</Option>
                            })
                        }



                    </Select>
                </Form.Item>
                {
                    cardType == 0 ?
                        <Form.Item label="开户支行"
                            name="branchName"
                            rules={[{ required: true, message: '请选择开户支行!' }]}
                        >
                            <Select placeholder="请选择"
                                style={{ width: 300 }}
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.includes(input)}
                            >
                                {
                                    branchBankList && branchBankList.map((v) => {
                                        return <Option rowKey={v.branchName} key={v.branchName} value={v.branchName}>{v.branchName}</Option>
                                    })
                                }



                            </Select>
                        </Form.Item> : null
                }

                <Form.Item
                    name="seat"
                    label="银行所在省市"
                    rules={[{ required: true, message: '请选择转账用途!' }]}
                >
                    <Cascader options={options}
                        placeholder="请选择"
                        style={{ width: 300 }}
                    />

                </Form.Item>
                <Form.Item
                    name="cardNo"
                    label="银行卡号"
                    rules={[{ required: true, message: '请输入银行卡号' }]}
                >
                    <Input placeholder="请输入银行卡号" style={{ width: 300 }} />
                </Form.Item>

                <Form.Item
                    label=" "
                    colon={false}
                >
                    <Button type="primary" htmlType='submit'>提交</Button>
                </Form.Item>
            </Form >




        </div >
    )
}

export default EnterpriseCard;