import React, { Component, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './style.less'
import {
    Form,
    Row,
    Col,
    Button,
    Table,
    DatePicker,
    Input,
    Tabs,
    Divider,
    message
} from 'antd';
import { settleConfigDetailRequest } from '../../../api/api'
const { TabPane } = Tabs;
// import host from '../../../util/host';



const TradeSettingDetail = () => {
    let navigate = useNavigate()
    const { state } = useLocation()
    const [data, setData] = useState(null)
    useEffect(() => {
        console.log(state);
        settleConfigDetail({ merchantCode: state.merchantCode })
    }, [])
    const settleConfigDetail = (data) => {
        (async _ => {
            return await settleConfigDetailRequest(data);
        })().then(res => {
            if (res.code == 0) {
                setData(res.data)
            } else {
                message.error(res.msg)
            }
        });
    }
    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            <Form
                name="basic"
                labelCol={{
                    span: 4,
                }}
                wrapperCol={{
                    span: 6,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <div style={{ width: '100%', marginLeft: 20, marginTop: 20, marginBottom: 20 }} className='borderBottom'>基本信息</div>
                <Form.Item
                    label="门店信息："
                >
                    {state && state.merchantName}/{state && state.merchantCode}
                </Form.Item>
                <Form.Item label="收银结算周期：">
                    {data && data.settlementType == 0 ? '日' : ''}
                    {data && data.settlementType == 1 ? '周' : ''}
                    {data && data.settlementType == 2 ? '月' : ''}
                    {data && data.settlementType == 3 ? '工作日' : ''}
                    {data && data.settlementType == 4 ? '汇总日' : ''}
                    <span style={{ marginLeft: 10 }}>{data && data.settlementValue}</span>
                </Form.Item>
                <Form.Item label="营销结算周期：">
                    {data && data.payCardSettlementType == 0 ? '日' : ''}
                    {data && data.payCardSettlementType == 1 ? '周' : ''}
                    {data && data.payCardSettlementType == 2 ? '月' : ''}
                    {data && data.payCardSettlementType == 3 ? '工作日' : ''}
                    {data && data.payCardSettlementType == 4 ? '汇总日' : ''}
                    <span style={{ marginLeft: 10 }}>{data && data.payCardSettlementValue}</span>
                </Form.Item>
                <Form.Item label="会员结算周期：">
                    {data && data.memberPaySettlementType == 0 ? '日' : ''}
                    {data && data.memberPaySettlementType == 1 ? '周' : ''}
                    {data && data.memberPaySettlementType == 2 ? '月' : ''}
                    {data && data.memberPaySettlementType == 3 ? '工作日' : ''}
                    {data && data.memberPaySettlementType == 4 ? '汇总日' : ''}
                    <span style={{ marginLeft: 10 }}>{data && data.memberPaySettlementValue}</span>
                </Form.Item>
                <Form.Item label="抖音结算周期：">
                    {data && data.douyinSettlementType == 0 ? '日' : ''}
                    {data && data.douyinSettlementType == 1 ? '周' : ''}
                    {data && data.douyinSettlementType == 2 ? '月' : ''}
                    {data && data.douyinSettlementType == 3 ? '工作日' : ''}
                    {data && data.douyinSettlementType == 4 ? '汇总日' : ''}
                    <span style={{ marginLeft: 10 }}>{data && data.douyinSettlementValue}</span>
                </Form.Item>
                <Form.Item label="美团结算周期：">
                    {data && data.meituanSettlementType == 0 ? '日' : ''}
                    {data && data.meituanSettlementType == 1 ? '周' : ''}
                    {data && data.meituanSettlementType == 2 ? '月' : ''}
                    {data && data.meituanSettlementType == 3 ? '工作日' : ''}
                    {data && data.meituanSettlementType == 4 ? '汇总日' : ''}
                    <span style={{ marginLeft: 10 }}>{data && data.meituanSettlementValue}</span>
                </Form.Item>
                <Form.Item label="饿了么结算周期：">
                    {data && data.eleSettlementType == 0 ? '日' : ''}
                    {data && data.eleSettlementType == 1 ? '周' : ''}
                    {data && data.eleSettlementType == 2 ? '月' : ''}
                    {data && data.eleSettlementType == 3 ? '工作日' : ''}
                    {data && data.eleSettlementType == 4 ? '汇总日' : ''}
                    <span style={{ marginLeft: 10 }}>{data && data.eleSettlementValue}</span>
                </Form.Item>
                <Form.Item label="钱包结算周期：">
                    {data && data.walletSettlementType == 0 ? '日' : ''}
                    {data && data.walletSettlementType == 1 ? '周' : ''}
                    {data && data.walletSettlementType == 2 ? '月' : ''}
                    {data && data.walletSettlementType == 3 ? '工作日' : ''}
                    {data && data.walletSettlementType == 4 ? '汇总日' : ''}
                    <span style={{ marginLeft: 10 }}>{data && data.walletSettlementValue}</span>
                </Form.Item>
                <div style={{ width: '100%', marginTop: 20, marginLeft: 20, marginBottom: 20 }} className='borderBottom'>会员交易手续费</div>
                <Form.Item label="发卡门店消费：">
                    {data && data.memberPayRate}%
                </Form.Item>
                <div style={{ width: '100%', marginTop: 20, marginLeft: 20, marginBottom: 20 }} className='borderBottom'>采购授信规则</div>
                <Form.Item label="采购授信额度：">
                    {data && data.creditAmount}
                </Form.Item>
                {/* <Form.Item label="卡类型：">
                    2022.04.28 15:30:23
                </Form.Item> */}
                <Form.Item label="账单日：">
                    周一
                </Form.Item>
                <Form.Item label="还款日：">
                    周一
                </Form.Item>
                <Form.Item label="授权有效期：">
                    {data && data.creditBeginTime}~{data && data.creditEndTime}
                </Form.Item>
                {/* <div style={{ width: '100%', marginLeft: 20, marginTop: 20, marginBottom: 20 }} className='borderBottom'>三方手续费规则</div>
                <Form.Item
                    label="微信手续费："
                >
                    20170418111507000100105
                </Form.Item>
                <Form.Item label="支付宝手续费：">
                    2022.04.28 15:30:23
                </Form.Item> */}

            </Form>
            {/* <Row>
                <Col span={8} style={{ textAlign: 'center' }}>
                    <Button onClick={goback}>返回</Button>
                </Col>
            </Row> */}
        </div>
    )
}
export default TradeSettingDetail;
