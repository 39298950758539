import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import NP from 'number-precision'
import {
    Form,
    Input,
    Switch,
    Row,
    Col,
    Button,
    Tabs,
    Table,
    message,
    Modal,
    Radio,
    Popconfirm,
    Select,
    Space,
    Skeleton
} from 'antd';
import { meituanBuchangRequest, eleDeleteOneRequest, meituanDeleteOneRequest, eleBuchangRequest, relationRecordRequest, dyBuchangRequest, meituanEleMerchantListRequest, onlineOrderListRequest, matchMerchantListRequest } from '../../../api/api'
import { useCallbackState } from '../../../util/useCallbackState'
const { Option } = Select;

const BillingDetails = (props) => {
    const [form] = Form.useForm();
    const [list, setList] = useState([])
    const [data, setData] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentPage, setCurrentPage] = useCallbackState(1)
    const [pageNum, setPageNum] = useCallbackState(10)
    const [merchantList, setMerchantList] = useState([])
    const [guanlianList, setGuanlianList] = useState([])
    const [total, setTotal] = useState()
    const [detailVisible, setDetailVisible] = useState(false);
    const [row, setRow] = useState(null)
    const [type, setType] = useState('3')
    const [loading, setLoading] = useState(false)
    let navigate = useNavigate();
    const { state } = useLocation();
    useEffect(() => {
        console.log(props);
        settleMerchantList()
    }, [])
    useEffect(() => {
        onlineOrderList()
    }, [pageNum, currentPage])
    const onFinish = (values) => {
        console.log(values);
        values.currentPage = 1;
        onlineOrderList(values)
    };
    const onlineOrderList = (data) => {
        let values = form.getFieldValue();
        let params = {
            ...data,
            ...values,
            superMerchantCode: localStorage.appid,
            merchantCode: localStorage.merchantCode,
            type: props && props.data && props.data.type,
            pageSize: pageNum,
            currentPage: currentPage,
            uploadNo: props && props.data && props.data.uploadNo,
        };
        (async _ => {
            return await onlineOrderListRequest(params);
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                setList(res.data.lists)
                setTotal(res.data.totalCount)
            } else {
                message.error(res.msg)
            }
        });
    }


    const dyBuchang = () => {
        setLoading(true);
        let params = {
            superMerchantCode: localStorage.appid,
            uploadNo: props && props.data && props.data.uploadNo,

        };
        (async _ => {
            return await dyBuchangRequest(params);
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                message.success(res.data)
                setLoading(false)
                props.callback(true)
                props.closeDialog()
            } else {
                message.error(res.msg)
                setLoading(false)
            }
        });
    }
    const meituanBuchang = () => {
        let params = {
            superMerchantCode: localStorage.appid,
            uploadNo: props && props.data && props.data.uploadNo,

        };
        (async _ => {
            return await meituanBuchangRequest(params);
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                message.success(res.data)
                setLoading(false)
                props.callback(true)
                props.closeDialog()
            } else {
                message.error(res.msg)
                setLoading(false)
            }
        });

    }
    const eleBuchang = () => {
        let params = {
            superMerchantCode: localStorage.appid,
            uploadNo: props && props.data && props.data.uploadNo,
        };
        (async _ => {
            return await eleBuchangRequest(params);
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                message.success(res.data)
                setLoading(false)
                props.callback(true)
                props.closeDialog()
            } else {
                message.error(res.msg)
                setLoading(false)
            }
        });

    }

    const columns = [
        {
            title: '结算ID',
            dataIndex: 'billId',
            key: 'billId',
        },
        {
            title: '门店名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
        },
        {
            title: '三方门店ID',
            dataIndex: 'thirdMerchantId',
            key: 'thirdMerchantId',
        },

        {
            title: '账单日期',
            dataIndex: 'billDate',
            key: 'billDate',
        },
        {
            title: '账单金额',
            dataIndex: 'billAmount',
            key: 'billAmount',
        },
        {
            title: '结算状态',
            dataIndex: 'settleStatus',
            key: 'settleStatus',
        },
        {
            title: '结算账期',
            dataIndex: 'settleDate',
            key: 'settleDate',
        },
        {
            title: '失败原因',
            dataIndex: 'errorMessage',
            key: 'errorMessage',
            fixed: 'right',
        },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            fixed: 'right',
            width: 100,
            render: (text, row) => {
                return (
                    <Popconfirm

                        title="是否确认删除?"
                        onConfirm={() => {
                            if (props.data && props.data.type == 1) {
                                deleteExcel(row)
                            }
                            if (props.data && props.data.type == 2) {
                                eleDeleteOne(row)
                            }
                        }}
                        onCancel={() => {

                        }}
                        okText="是"
                        cancelText="否"
                    >
                        {row.errorMessage ? <a href="#">删除</a> : null}
                    </Popconfirm>
                )
            }

        },
    ];


    const columns1 = [
        {
            title: '结算ID',
            dataIndex: 'billId',
            key: 'billId',
        },
        {
            title: '门店名称',
            dataIndex: 'merchantName',
            key: 'merchantName',
        },
        {
            title: '三方门店ID',
            dataIndex: 'thirdMerchantId',
            key: 'thirdMerchantId',
        },

        {
            title: '账单日期',
            dataIndex: 'billDate',
            key: 'billDate',
        },
        {
            title: '账单金额',
            dataIndex: 'billAmount',
            key: 'billAmount',
        },
        {
            title: '结算状态',
            dataIndex: 'settleStatus',
            key: 'settleStatus',
        },
        {
            title: '结算账期',
            dataIndex: 'settleDate',
            key: 'settleDate',
        },
        {
            title: '失败原因',
            dataIndex: 'errorMessage',
            key: 'errorMessage',
        },
        {
            title: '账单信息',
            dataIndex: 'remark',
            key: 'remark',
            fixed: 'right',
            render(_, row) {
                return {
                    children: row.remark ? row.remark.split('/')[1] : null,
                    props: {
                        rowSpan: row.rowSpan,
                    }
                }
            }
        },
    ];
    const eleDeleteOne = (data) => {
        (async _ => {
            return await eleDeleteOneRequest({
                id: data.id,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                // excelList()
                onlineOrderList()
                message.success(res.msg)
            } else {
                message.error(res.msg)
            }
        });
    }
    const deleteExcel = (data) => {
        (async _ => {
            return await meituanDeleteOneRequest({
                id: data.id,
                superMerchantCode: localStorage.appid,
            });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                // excelList()
                onlineOrderList()
                message.success(res.msg)
            } else {
                message.error(res.msg)
            }
        });
    }

    const relationRecord = (data) => {
        let params = {
            currentPage: currentPage,
            pageSize: pageNum,
            orderNo: data.orderNo
        };
        (async _ => {
            return await relationRecordRequest(params);
        })().then(res => {
            if (res.code == 0) {
                setGuanlianList(res.data.lists)
            } else {
                message.error(res.msg)
            }
        });
    }
    const showModal = (data) => {
        relationRecord(data)
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const onChangPage = (currentPage, pageNum) => {
        setCurrentPage(currentPage, () => {
            // relationRecord()
        })
    }
    const onChangPageNum = (currentPage, pageNum) => {
        setPageNum((currentPage, pageNum), () => {
            // relationRecord()
        })
    }
    const settleMerchantList = () => {
        (async _ => {
            return await meituanEleMerchantListRequest({
                superMerchantCode: localStorage.appid,
                uploadNo: props && props.data.uploadNo
            });
        })().then(res => {
            if (res.code == 0) {
                let arr = []
                if (res.data) {
                    for (let i in res.data) {
                        arr.push({
                            merchantCode: i,
                            merchantName: res.data[i]
                        })
                    };
                }
                setMerchantList(arr)
            } else {
                // message.error(res.msg)
            }
        });
    }
    //合并数组单元格
    const createNewArr = (data) => {
        return data.reduce((result, item) => {
            //首先将name字段作为新数组result取出
            if (result.indexOf(item.remark) < 0) {
                result.push(item.remark)
            }
            return result
        }, []).reduce((result, name) => {
            //将name相同的数据作为新数组取出，并在其内部添加新字段**rowSpan**
            const children = data.filter(item => item.remark === name);
            result = result.concat(
                children.map((item, index) => ({
                    ...item,
                    rowSpan: index === 0 ? children.length : 0,//将第一行数据添加rowSpan字段
                }))
            )
            return result;
        }, [])
    }
    return (
        <div style={{ background: '#ffffff', paddingBottom: 20 }}>


            <Row style={{
                marginTop: 16,
                marginLeft: 16
            }}>

                <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}
                    initialValues={{ subMerchantCode: null, errorStatus: 2 }}
                >

                    <Form.Item
                        name="subMerchantCode"
                        label=""
                        colon={false}
                        rules={[{ required: false, message: 'Please input your username!' }]}
                    >
                        <Select
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            placeholder="请选择"
                            style={{ width: 250 }}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.includes(input)}
                        >
                            <Option key={null} value={null}>全部</Option>
                            {
                                merchantList && merchantList.map((v) => {
                                    return <Option rowKey={v.merchantCode} key={v.merchantCode} value={v.merchantCode}>{v.merchantName}</Option>
                                })
                            }

                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="errorStatus"
                        label=""
                        colon={false}
                        rules={[{ required: false, message: 'Please input your username!' }]}
                    >
                        <Select
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            placeholder="请选择"
                            style={{ width: 250 }}
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.includes(input)}
                        >
                            <Option key={null} value={null}>全部</Option>
                            <Option key={1} value={1}>成功</Option>
                            <Option key={2} value={2}>失败</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            筛选
                        </Button>
                        <Button
                            style={{ marginLeft: 20 }}
                            type="link"
                            onClick={() => {
                                form.setFieldsValue({
                                    subMerchantCode: null,
                                    errorStatus: null
                                })
                                onlineOrderList();
                            }}
                        >
                            重置筛选条件
                        </Button>
                    </Form.Item>

                </Form>
            </Row>
            <Row style={{ padding: "0px 10px" }}>
                <Col span={24}>
                    {
                        props.data.view ?
                            <Table
                                locale={{
                                    emptyText: "没有更多数据了"
                                }}
                                columns={columns1}
                                rowKey='id'
                                dataSource={createNewArr(list)}
                                scroll={{ x: 1500 }}
                                pagination={{
                                    total,
                                    showSizeChanger: true,
                                    current: currentPage,
                                    pageSize: pageNum,
                                    showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                                    onChange: (k, v) => onChangPage(k, v),
                                    onShowSizeChange: (k, v) => onChangPageNum(k, v),
                                }}
                            /> :
                            <Table
                                locale={{
                                    emptyText: "没有更多数据了"
                                }}
                                columns={columns}
                                rowKey='id'
                                dataSource={list}
                                scroll={{ x: 1500 }}
                                pagination={{
                                    total,
                                    showSizeChanger: true,
                                    current: currentPage,
                                    pageSize: pageNum,
                                    showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,
                                    onChange: (k, v) => onChangPage(k, v),
                                    onShowSizeChange: (k, v) => onChangPageNum(k, v),
                                }}
                                footer={() => {
                                    return <div><Button type='primary' loading={loading} onClick={() => {
                                        if (props && props.data && props.data.type == 1) {
                                            meituanBuchang()
                                        }
                                        if (props && props.data && props.data.type == 0) {
                                            dyBuchang()
                                        }
                                        if (props && props.data && props.data.type == 2) {
                                            eleBuchang()
                                        }

                                    }}>补偿全部数据</Button> <span style={{ color: '#999999', fontSize: 14, marginLeft: 16 }}>配置抖音门店ID后，再补偿数据</span></div>
                                }}
                            />
                    }

                </Col>

            </Row>

        </div>

    )
}
export default BillingDetails;
