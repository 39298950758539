import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './style.less'
import {
  Form,
  Input,
  Switch,
  Row,
  Col,
  message
} from 'antd';
import wangshang from '../../../images/wangshang.png'
import dougong from '../../../images/dougong.png'
import { configDetailRequest } from '../../../api/api'



const FormItem = Form.Item;
const Search = Input.Search;



const StoreValue = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const { state } = useLocation()
  const [bankType, setBankType] = useState(null)
  const [disabled, setDisabled] = useState(false)
  const [stateId, setStateId] = useState(null)
  useEffect(() => {
    configDetail()
  }, [])

  const configDetail = () => {
    let params = {
      merchantCode: localStorage.appid,
      superMerchantCode: localStorage.merchantCode
    };
    (async _ => {
      return await configDetailRequest(params);
    })().then(res => {
      console.log(res)
      if (res.code == 0) {
        console.log(res.data.bankType == 0)
        setBankType(res.data.bankType)
        setStateId(res.data.id)
        if (res.data.bankType) {
          setDisabled(true)
        }
      } else {
        message.error(res.msg)
      }
    });
  }

  const onChange = (checked) => {
    console.log(checked)
    setBankType('0')

  };
  const onChange1 = (checked) => {
    console.log(checked)
    setBankType('1')

  };
  const goAdd = () => {
    navigate('/ws/initialConfiguration/add', { replace: false, state: { bankType: bankType ? bankType : '0', stateId } })
  }
  const goDougong = () => {
    navigate('/ws/initialConfiguration/add', { replace: false, state: { bankType: bankType ? bankType : '1', stateId } })
  }

  return (
    <div style={{ height: '100vh', background: '#ffffff', paddingBottom: 20 }}>
      <Row align='middle' style={{ padding: '10px 10px', marginTop: 10, borderBottom: '1px solid #EBEDF0' }}>
        <div style={{ background: '#145BD5', height: 14, width: 3 }}></div><div style={{ marginLeft: 10, fontWeight: 500 }}>资金结算</div>
      </Row>
      {/* <Row>

      </Row>
      <Row align='middle' style={{ padding: '10px 10px', marginTop: 10, borderBottom: '1px solid #EBEDF0' }}>
        <div style={{ background: '#145BD5', height: 14, width: 3 }}></div><div style={{ marginLeft: 10, fontWeight: 500 }}>云资金</div>
      </Row> */}
      <Row className='sybox storeValue' style={{ marginLeft: 16, marginRight: 16, }}>
        <Col span={1} style={{ width: 50, marginLeft: 20 }}><img src={wangshang} style={{ width: 50, }}></img></Col>
        <Col span={19}>
          <div>
            <span className='size'>网商云资金</span>
            {/* <Link to="/initialConfigurationAdd">点击申请</Link> */}
          </div>
          网商银行基于核心品牌企业、上下游供应商、经销商、终端门店之间的供应链生产关系，开发的一套数字供应链金融方案，
          以解决这些小微企业在供货回款、采购订货、铺货收款、加盟等生产经营全链路的信贷需求和综合资金管理需求。</Col>
        <Col span={2}>
          <div>
            <Switch checked={bankType == 0 ? true : false} onChange={onChange}
              style={{ marginRight: 20, marginTop: -30 }}
              disabled={disabled}
            />
            {
              bankType == 0 ?
                <div style={{ color: '#165BD4', cursor: 'pointer' }}
                  onClick={
                    goAdd
                  }
                > {stateId ? '已设置' : '去设置'}</div> : ''

            }

          </div>

        </Col>
      </Row>

      <Row align='middle' style={{ padding: '10px 10px', marginTop: 10, borderBottom: '1px solid #EBEDF0' }}>
        <div style={{ background: '#145BD5', height: 14, width: 3 }}></div><div style={{ marginLeft: 10, fontWeight: 500 }}>资金分账</div>
      </Row>
      <Row className='box storeValue' style={{ marginLeft: 16, marginRight: 16, }}>
        <Col span={1} style={{ width: 50, marginLeft: 20 }}><img src={dougong} style={{ width: 50, }}></img></Col>
        <Col span={19}>
          <div>
            <span className='size'>汇付斗拱</span>
            {/* <Link to="/initialConfigurationAdd">点击申请</Link> */}
          </div>
          提供集团到门店、供应商到商户等场景下的资金处理方式；支持个人、小微用户及企业用户，实时、延时、按固定或比例
          分账，并支持周期性结算，保证各方资金存入到指定银行卡。</Col>
        <Col span={2}>
          <div>
            <Switch checked={bankType == 1 ? true : false} onChange={onChange1}
              style={{ marginRight: 20, marginTop: -30 }}
              disabled={disabled}
            />
            {
              bankType == 1 ?
                <div style={{ color: '#165BD4', cursor: 'pointer' }}
                  onClick={
                    goDougong
                  }
                > {stateId ? '已设置' : '去设置'}</div> : ''

            }
          </div>

        </Col>
      </Row>
    </div>

  )
}
export default StoreValue;
