import React, { Component, useState, useEffect } from 'react';
import './index.less'
import {
  Form,
  Row,
  Col,
  Button,
  Table,
  DatePicker,
  Input,
  Select,
  message,
  Popconfirm,
  Tabs,
} from 'antd';
import { configDetailRequest } from '../../../api/api'
import { useLocation } from 'react-router-dom';

const { TabPane } = Tabs;
const { Option } = Select;

const layout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 16 },
};
const FormItem = Form.Item;
const Search = Input.Search;




const View = () => {
  const [list, setList] = useState([{ idx: 0 }])
  const [data, setData] = useState(null)
  const { state } = useLocation()

  useEffect(() => {
    getConfigDetail()
  }, [])



  const getConfigDetail = (data) => {

    (async _ => {
      return await configDetailRequest({
        ...data,
        merchantCode: localStorage.merchantCode,
        superMerchantCode: localStorage.appid,
      }
      );
    })().then(res => {
      if (res.code == 0) {
        setList(res.data.wsbankConfigVO);
        setData(res.data);
      } else {
        message.error(res.msg)
      }
    });
  }

  const onChange = (key) => {
  };
  const onSearch = (value) => {
    getConfigDetail({ value })
  }
  return (
    <div style={{ background: '#ffffff', paddingBottom: 20 }}>
      <Form
        className="ant-advanced-search-form"
        {...layout}
      >
        <Row style={{ marginTop: 20 }}>
          <Tabs defaultActiveKey="1" onChange={onChange} size="large" style={{ width: '100%', paddingLeft: 20 }}>
            <TabPane tab="初始信息" key="1">
              {/* <div className='borderBottom'>基本信息</div> */}
              <Form.Item
                label="分账银行">
                {state && state.bankType == 0 ? '网商银行' : '汇付斗拱'}
              </Form.Item>
              {
                state && state.bankType == 1 ?
                  <>
                    <Form.Item
                      label="门店名称"
                    >
                      {localStorage.merchantName}
                    </Form.Item>
                    <Form.Item
                      label="银行结算"
                    >
                      {data && data.zhongbangBankStatus == 0 ? '关闭' : '开启'}
                    </Form.Item>
                  </>

                  :
                  <div>
                    <Form.Item
                      label="合作方机构号："
                    >
                      {data && data.wsIsvOrgId}
                    </Form.Item>

                    <Form.Item
                      label="网商应用ID："
                    >
                      {data && data.wsAppid}
                    </Form.Item>
                    <Form.Item
                      label="ISV主站侧合作ID："
                    >
                      {data && data.wsPartnerId}
                    </Form.Item>
                    <Form.Item
                      label="接口请求地址："
                    >
                      <div style={{ wordBreak: ' break-all' }}>{data && data.wsReqUrl}</div>
                    </Form.Item>
                    <Form.Item
                      label="网商私钥："
                    >
                      <div style={{ wordBreak: ' break-all' }}>{data && data.wsPrivateKey}</div>
                    </Form.Item>
                    <Form.Item
                      label="网商公钥："
                    >
                      <div style={{ wordBreak: ' break-all' }}> {data && data.wsPublicKey}</div>
                    </Form.Item>
                    <Form.Item
                      label="sftp_host"
                    >
                      {data && data.sftpHost}
                    </Form.Item>
                    <Form.Item
                      label="sftp端口"
                    >
                      {data && data.sftpPort}
                    </Form.Item>
                    <Form.Item
                      label="sftp用户名"
                    >
                      {data && data.sftpUserName}
                    </Form.Item>
                    <Form.Item
                      label="sftp密码"
                    >
                      {data && data.sftpPassword}
                    </Form.Item>
                    <Form.Item
                      label="sftpDirectory"
                    >
                      {data && data.sftpDirectory}
                    </Form.Item>

                    <Form.Item
                      label="receipt_url"
                    >
                      {data && data.wsReceiptUrl}
                    </Form.Item>
                    <Form.Item
                      label="网商RSA"
                    >
                      <div style={{ wordBreak: ' break-all' }}>{data && data.wsRsaPublicKey}</div>

                    </Form.Item>
                  </div>
              }








              {/* <div className='borderBottom'>补单信息</div>
              <Row justify="center" style={{ marginBottom: 20, marginTop: 20 }}>
                <Col span={8} >
                  补单类型：三方对账文件
                </Col>
                <Col span={8}>
                  <Search
                    placeholder="请输入商务名称"
                    allowClear
                    enterButton="查询"
                    size="middle"
                    onSearch={onSearch}
                  />
                </Col>
              </Row> */}
              {/* <Row>
                <Table
                  size="middle"
                  style={{ marginTop: 10, width: '100%' }}
                  bordered
                  // loading={loading}
                  scroll={{ x: 2000 }}
                  rowKey='id'
                  dataSource={list}
                  columns={columns1}
                  pagination={false}
                // scroll={{ x: 900 }}
                />

              </Row> */}
            </TabPane>
            {/* <TabPane tab="操作记录" key="2">
              <Table
                style={{ marginTop: 10 }}
                showHeader={false}
                bordered
                // loading={loading}
                rowKey="id"
                dataSource={list}
                columns={columns}
                pagination={{
                  showSizeChanger: true,
                  showTotal: (total, range) => `${range[0]}-${range[1]} 共${total} 条`,

                }}
              // scroll={{x: 900}}
              />
            </TabPane> */}
          </Tabs>
        </Row>



      </Form>
    </div >
  )
}
export default View;
