import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment'
import './style.less'
import {
    Form,
    Row,
    Col,
    Button,
    Input,
    Select,
    message,
    Steps,
} from 'antd';
import {
    bookAccountSaveRequest,
    bankCardListRequest,
    bookAccountDetailRequest,
    listRequest
} from '../../../api/api'
const { Step } = Steps;
const { Option } = Select;
const LnstitutionsInList = () => {
    const [form] = Form.useForm();
    const { search, state } = useLocation()
    const [cardList, setCardList] = useState([])
    const [accountStatus, setAccountStatus] = useState(Number)
    const superMerchantCode = localStorage.appid
    const navigate = useNavigate();
    useEffect(() => {
        bankCardNameList()
        if (state && state.ss == 2) {
            bookAccountDetail()
        }

    }, [])

    const bookAccountDetail = () => {
        (async _ => {
            return await bookAccountDetailRequest({ id: state.id });
        })().then(res => {
            if (res.code == 0) {
                form.setFieldsValue({
                    accountName: res.data.accountName,
                    accountDescription: res.data.accountDescription,
                    orderTypes: res.data.orderTypes,
                    bankCardId: res.data.bankCardId
                })
                setAccountStatus(res.data.accountStatus)
            } else {
                message.error(res.msg)
            }
        });
    }
    const onFinish = (values) => {

        (async _ => {
            return await bookAccountSaveRequest(
                {
                    ...values,
                    merchantCode: localStorage.merchantCode,
                    superMerchantCode: localStorage.appid,
                    id: state && state.id ? state.id : undefined,
                    accountStatus: accountStatus ? accountStatus : undefined
                }
            );
        })().then(res => {
            if (res.code == 0) {
                navigate(-1)
                message.success(res.msg)
            } else {
                message.error(res.msg)
            }
        });
    }


    const bankCardNameList = () => {
        (async _ => {
            return await listRequest(
                {
                    merchantCode: localStorage.merchantCode,
                    superMerchantCode,
                    openStatus: 'S',
                    // currentPage: 1,
                    // pageSize: 1000,
                }
            );
        })().then(res => {
            if (res.code == 0) {
                setCardList(res.data.lists)
            } else {
                message.error(res.msg)
            }
        });
    }



    const cell = () => {
        navigate(-1)
    }



    return (
        <div style={{ background: '#ffffff', padding: 10, marginTop: 10 }}>
            <div>
                <Form
                    name="basic"
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        span: 6,
                    }}
                    form={form}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <div style={{ width: '100%', marginLeft: 20, marginTop: 20, marginBottom: 20 }} className='borderBottom'>基本信息</div>

                    <Form.Item
                        label="账户名称"
                        name="accountName"
                        rules={[
                            {
                                required: false,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input style={{ width: 184 }} placeholder="请输入账户名称" />
                    </Form.Item>
                    <div style={{ width: '100%', marginTop: 20, marginLeft: 20, marginBottom: 20 }} className='borderBottom'>入驻信息</div>
                    <Form.Item
                        label="账户描述"
                        name="accountDescription"
                    >
                        <Input.TextArea
                            style={{ width: 234 }}
                            autoSize={{ minRows: 3, maxRows: 6 }}
                            placeholder="请输入"
                        ></Input.TextArea>
                    </Form.Item>
                    <Col style={{ marginBottom: '-2vh' }}>
                        <Form.Item
                            name="orderTypes"
                            label="订单类型"
                            rules={[{ required: false, message: '请输入提现金额!' }]}
                        >
                            <div style={{ display: 'flex' }}>
                                <Form.Item
                                    name="orderTypes"
                                // noStyle
                                >
                                    <Select
                                        style={{ width: 234 }}
                                        placeholder="请选择"
                                    >
                                        <Option key={1} value={1}>充值</Option>
                                    </Select>
                                </Form.Item>
                                {/* <Form.Item
                                    noStyle
                                >
                                    <Button type='link'
                                        onClick={() => {
                                        }}>新建类型</Button>

                                </Form.Item>
                                <Form.Item
                                    noStyle
                                >
                                    <Button type='link'
                                        onClick={() => {
                                        }}>刷新</Button>

                                </Form.Item> */}
                            </div>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="bankCardId"
                            label="银行卡"
                            rules={[{ required: false, message: '请输入提现金额!' }]}
                        >
                            <div style={{ display: 'flex' }}>
                                <Form.Item
                                    name="bankCardId"
                                >
                                    <Select
                                        style={{ width: 370 }}
                                        placeholder="请选择"
                                    >

                                        {
                                            cardList && cardList.map((v) => {
                                                return <Option rowKey={v.id} key={v.id} value={v.id}>{v.bankName}{v.cardNo}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>

                            </div>
                        </Form.Item>
                    </Col>
                    {/* <Form.Item label="描述：">
                                --
                            </Form.Item> */}
                    <div style={{ width: '100%', marginTop: 20, marginLeft: 20, marginBottom: 20 }} className='borderBottom'>创建信息</div>
                    <Col>
                        <Form.Item label="创建人：">
                            {state && state.operator ? state.operator : localStorage.operatorname ? localStorage.operatorname : undefined}
                        </Form.Item>
                    </Col>
                    <Col >
                        <Form.Item label="创建时间：">
                            {state && state.createTime ? state.createTime : moment().format('YYYY-MM-DD HH:mm:ss')}
                        </Form.Item>
                    </Col>

                    <Row>
                        <Col span={12} style={{ textAlign: 'center' }}>
                            <Button type="primary"
                                htmlType="submit">保存</Button>
                            <Button style={{ marginLeft: 20 }}
                                onClick={cell}>取消</Button>
                        </Col>
                    </Row>
                </Form>
            </div>



        </div>
    )
}

export default LnstitutionsInList;