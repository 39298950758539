
import { Button, Checkbox, Form, Input, message } from 'antd';
import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { loginRequest } from '../../api/api'

const Login = () => {
    useEffect(() => {
    }, [])


    return (
        <div style={{ textAlign: 'center' }}>

            <h3>悦喵Ai注册及服务协议</h3>
            <p>欢迎您使用悦喵Ai服务（以下简称“服务”）。 提示：在您使用我们的服务之前，您应当认真阅读并遵守《悦喵Ai注册及服务协议》（以下简称“本协议”）以及《悦喵Ai服务隐私政策条款》。本协议是由悦喵Ai软件（包括悦喵Ai网站，PC端、移动端的各种软件及应用程序）用户（您或您代表的企业/商户）与悦喵Ai平台的运营方，即北京悦喵数字科技有限公司（以下简称悦喵Ai）之间，就使用悦喵Ai软件服务相关事宜共同缔结。本协议具有合同效力，您应当阅读并遵守本协议。请您务必审慎阅读、充分理解各条款内容，特别是以黑体加粗形式提示您注意的条款。
                一、协议内容及确认
                本协议内容包括本协议正文及所有悦喵Ai已经发布或将来可能\发布及修改的各项政策、规则、声明、通知、警示、提示、说明等（以下简称“规则”）。前述规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。
                当您按照注册页面提示填写信息、完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，本协议即产生法律约束力，您成为与悦喵金融订立具有法律约束力的协议之一方当事人。您承诺接受并遵守本协议的约定，届时您不应以未阅读本协议的内容主张本协议无效，或要求撤销本协议。
                对于悦喵Ai平台（包括和合作第三方一起）向您提供的服务，当您在相关页面上点击确认的时候，例如，您点击“开通服务”或类似文字的按钮，或者实际使用该服务时，请您事先仔细阅读本协议内容，您点击确认或实际使用服务即代表您接受协议的内容。
                根据国家法律法规变化及网站运营需要，悦喵Ai有权根据需要不时制订、修改本协议及相关规则，变更后的协议和规则一经公布，立即取代原协议及规则并自动生效。您可以在悦喵Ai平台随时查阅最新协议。如您不同意相关变更，应当立即停止使用服务，如您继续使用服务或在悦喵Ai软件进行任何操作活动，即表示您已接受经修订的协议和规则。
                如果您在任何时候对任何内容存在疑虑或异议，请您立即联系我们（联系电话4000-122-155），以便我们对相关内容进行解释和说明，使您能够充分理解，从而帮助您决定是否接受服务规则及使用我们的服务。
                二、服务内容
                1、悦喵Ai提供多种类的产品形态，包括手机客户端（iOS及Android版本）（具体以悦喵Ai提供的为准）、电脑软件、悦喵Ai硬件等。如您已注册使用一种产品形式的服务，则可以以同一账号使用其他种类产品，本协议自动适用于您对所有形态的软件和服务的使用。
                2、本服务内容包含门店移动支付服务（由具有支付相关服务资质的服务商提供，如使用可申请与支付服务商单独签订服务协议）、门店账目查询等一系列技术功能。这些功能服务会根据用户需求的变化、服务范围的扩展、服务提供方（合作伙伴）的要求或行业规定变化而被优化或修改，或因定期、不定期的维护而暂缓提供。
                3、同时，悦喵Ai保留在任何时候自行决定对服务或服务任何部分及其相关功能、应用软件变更、升级、修改、转移的权利。您同意，对于上述行为，悦喵Ai均不需通知，并且对您和任何第三人不承担任何责任，但如果该变化或功能直接影响您的实际权益，悦喵Ai需要得到您的确认（通过悦喵Ai软件进行人工操作，点击确认）。
                4、为向您提供整体解决方案，本协议项下的各项服务（包括未来将推出的服务）将由悦喵Ai及其合作公司自行或根据第三方合作方的授权分别或共同提供，或由第三方合作方提供。
                三、注册及账号管理
                1、您确认，在您完成注册程序或以其他悦喵Ai允许的方式实际使用服务时，您应当是具备完全民事权利能力和与所从事的民事行为相适应的行为能力的自然人、法人或其他组织。若您不具备前述主体资格，请勿使用服务，否则您及您的监护人应承担因此而导致的一切后果，且悦喵Ai有权注销或永久冻结您的账户，并向您及您的监护人索偿。如您代表一家公司或其他法律主体进行注册或以其他悦喵Ai允许的方式实际使用本服务，则您声明和保证，您有权代表该公司或法律主体，使该公司或该法律主体受本协议的约束。
                2、本服务需要您以实名进行登记。您注册时应提交真实、准确、完整和反映当前情况的身份及其他相关信息和资料，包括但不限于营业执照、法定代表人/负责人身份证、银行账户信息等，并根据业务需要在该等文件上加盖公章。
                3、悦喵Ai有权向支付合作方提供您的上述材料和信息，并为自身或支付合作方的要求对上述材料的真实性和有效性进行核实。当悦喵Ai或支付合作方为您提供回访服务或进行终端相关业务检查时，您应积极配合悦喵Ai和支付合作方的相关人员工作，如悦喵Ai或支付合作方提出合理的签字、盖章和其他要求，您不得拒绝。悦喵Ai和支付合作方有权审查您提交的资质证明材料，如经审查，悦喵Ai或支付合作方认为您不具备或部分不具备申请资质，悦喵Ai或支付合作方均有权拒绝向您提供服务。
                4、您对所提供材料和信息的准确性、完整性、合法性和真实性承担责任。在任何信息发生变更时，立即书面通知到悦喵Ai。您在注册材料中填写的通讯地址和方式为双方同意，任何书面通知只要发往以上地址，均视为已送达。
                5、您理解并同意，您可以使用您提供或确认的手机号或者悦喵Ai允许的其它方式作为账号进行注册，并且您承诺注册的账号名称、头像和简介等信息中不得出现违法和不良信息，不得冒充他人，不得未经许可为他人注册，不得以可能导致其他用户误认的方式注册账号，不得使用可能侵犯他人权益的用户名（包括但不限于涉嫌商标权、名誉权侵权等），否则悦喵Ai有权不予注册或停止服务并收回账号，因此产生的损失由您自行承担。
                6、您了解并同意，您在悦喵Ai平台注册的账号所有权归属于悦喵Ai，注册完成后，您仅获得账号使用权。账号使用权仅归属于初始申请注册人，不得以任何方式转让或供予非您门店经营相关的他人使用，否则，悦喵Ai有权立即不经通知收回该账号，由此带来的因您使用本服务产生的全部数据、信息等被清空、丢失等的损失，您应自行承担。
                7、在您成功注册后，悦喵Ai将根据账号和密码确认您的身份。您应妥善保管您的终端设备及账户和密码，并对利用该账户和密码所进行的一切活动（包括但不限于网上点击同意、提交各类业务操作、购买服务等）负全部责任。您承诺，在密码或账户遭到未获授权的使用，或者发生其他任何安全问题时，将立即通知悦喵Ai，且您同意并确认，悦喵Ai不对上述情形产生的任何直接或间接的遗失或损害承担责任。除非有法律规定或司法裁定，且征得悦喵Ai的同意，否则，您的账户、密码不得以任何方式转让、赠与或继承（与账户相关的财产权益除外）。
                8、悦喵Ai根据本协议收回或取消账号后，有权自行对账号相关内容及信息以包括但不限于删除等方式进行处理，且无需就此向用户承担任何责任。
                四、服务使用规范
                1、您承诺，使用悦喵Ai服务时，您将严格遵守本协议所列明的各项规则及规范要求。
                2、您充分了解并同意，悦喵Ai仅为您提供软件服务（且相应移动支付服务将由具备移动支付资质的第三方提供），您应自行对利用服务从事的所有行为及结果承担责任。相应地，您应了解，使用服务可能发生来自他人非法或不当行为（或信息）的风险，您应自行判断及行动，并自行承担相应的风险。
                3、您承诺不会利用本服务进行任何违法或不当的活动，包括但不限于下列行为:
                3.1上载、传送或分享含有下列内容之一的信息：(a)反对宪法所确定的基本原则的；(b)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；(c)损害国家荣誉和利益的；(d)煽动民族仇恨、民族歧视、破坏民族团结的；(e)破坏国家宗教政策，宣扬邪教和封建迷信的；(f)散布谣言，扰乱社会秩序，破坏社会稳定的；(g)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；(h)侮辱或者诽谤他人，侵害他人合法权利的；(i)含有虚假、诈骗、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；(j)含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；
                3.2冒充任何人或机构，或以虚伪不实的方式陈述或谎称与任何人或机构有关；
                3.3伪造标题或以其他方式操控识别资料，使人误认为该内容为悦喵Ai或其关联公司所传送；
                3.4将依据任何法律或合约或法定关系（例如由于雇佣关系和依据保密合约所得知或揭露之内部资料、专属及机密资料）知悉但无权传送之任何内容加以上载、传送或分享；
                3.5将涉嫌侵害他人权利（包括但不限于著作权、专利权、商标权、商业秘密等知识产权）之内容上载、传送或分享；
                3.6跟踪或以其它方式骚扰他人,或通过本服务向好友或其他用户发送大量信息；进行任何超出正常的好友或用户之间内部或外部信息沟通、交流等目的的行为；
                3.7将任何广告、推广信息、促销资料、垃圾邮件、滥发信件、连锁信件、直销或其它任何形式的劝诱资料加以上载、传送或分享；供前述目的使用的专用区域或专用功能除外；
                3.8从事任何违反中国法律、法规、规章、政策、规范性文件以及支付机构规定的行为，例如信用卡及第三方机构提供的信用额度套现交易、他人支付账号的盗刷交易、为获得支付机构的补贴而进行的无正常交易实质的交易等。
                4、您承诺不对本软件和服务从事以下行为：
                4.1将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之资料，加以上载、张贴、发送电子邮件或以其他方式传送； 4.2干扰或破坏本服务或与本服务相连线之服务器和网络，或违反任何法律法规及违反关于本服务连线网络之规定、程序、政策或规范等；
                4.3通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；
                4.4自行、授权他人或利用第三方软件对本软件及其组件、模块、数据等进行干扰，反向工程。
                5、您应自行承担因使用服务所产生的费用，包括但不限于上网流量费、通信服务费等。
                6、您同意并接受悦喵Ai无须时时监控您上载、传送或分享的资料及信息，但悦喵Ai有权对您使用服务的情况进行审查、监督并采取相应行动，包括但不限于删除信息、中止或终止服务，及向有关机关报告。
                7、您承诺不以任何形式使用本服务侵犯悦喵Ai的商业利益，或从事任何可能对悦喵Ai造成损害或不利于悦喵Ai的行为。
                8、您了解并同意，在悦喵Ai服务提供过程中，悦喵Ai及其关联公司或其授权单位和个人有权以各种方式投放各种商业性广告或其他任何类型的推广信息，同时，您同意接受以电子邮件、短信或其他方式向您发送的上述广告或推广信息。
                9、您可以在悦喵Ai平台上根据实际需求添加门店、终端、管理员，但您对上述添加行为以及之后的操作行为承担责任，如果由于您添加上述账号从而导致损失，由您自行承担或赔偿。
                10、您充分了解并同意，您必须为自己注册帐号下的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。您应对使用本服务时接触到的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。悦喵Ai无法且不会对您因前述风险而导致的任何损失或损害承担责任。
                11、如果悦喵Ai发现或收到他人举报您有违反本协议约定的，悦喵Ai有权不经通知随时对相关内容进行删除、屏蔽，并采取包括但不限于收回账号，通知合作方冻结账户资金，限制、暂停、终止您使用部分或全部本服务，追究法律责任等措施。
                12、如您使用服务平台发布的信息内容违法违规，悦喵Ai有权视情采取警示、限制功能、暂停更新、关闭账号等处置措施，保存记录并向有关主管部门报告。
                五、服务费用
                1、 商户注册成功后，悦喵Ai将收取商户因使用相关服务而发生的服务费。因服务的任何免费试用或免费功能和服务不应视为悦喵Ai放弃后续收费的权利。悦喵Ai有权以书面形式通知您收费标准，若您继续使用则表述您接受悦喵Ai的服务，需按悦喵Ai公布的收费标准支付费用。
                2、不同服务的费用（包括费用的具体支付方式）以各服务的具体条款确定。
                3、悦喵Ai有权根据实际情况单方面调整服务费用标准及收费方式，并以公告形式通知您，而无需获得您的事先同意，如您不同意收费应当立即停止服务的使用，否则使用则视为您已同意并应当缴纳费用。
                六、移动支付服务
                1、悦喵Ai合作服务方可提供移动支付相关服务，如您使用，需提出申请并与合作服务方签署相关服务协议。
                2、根据您的申请，悦喵Ai将根据支付服务所对应的支付合作方的要求对您的资质进行初步审查并提交支付合作方，是否开通上述支付服务以及开通哪些支付服务，以支付合作方的审查结果为准。
                3、您无论使用的以上任一或全部服务，均应遵守本协议项下的各项约定，以及所有适用法律法规和监管部门、结算机构、支付合作方的规定和要求。
                4、使用限制
                您了解并同意，支付合作方可以根据业务场景需求及自身业务需要，制定相关限制条款，包括但不限于消费者一次性/累计支付上限，您一次性/累计收款金额上限等。具体以支付合作方公示的结果为准。
                七、特别授权
                一旦您违反本协议，或与悦喵Ai及悦喵Ai合作伙伴（共同合作为您提供服务）签订的其他协议的约定，悦喵Ai有权以任何方式通知悦喵Ai关联公司及悦喵Ai合作伙伴，要求其对您的权益采取限制措施，包括但不限于要求悦喵Ai关联公司、合作伙伴中止、终止对您提供部分或全部服务，且在其经营或实际控制的任何网站公示您的违约情况。
                悦喵Ai有权根据您上述违约行为的影响、解决进展来决定部分或全部服务恢复的时间。
                八、第三方应用
                1、悦喵Ai允许第三方应用接入服务，您可以在悦喵Ai直接获得各种第三方应用服务。您了解并同意，悦喵Ai仅作为平台提供者，相关应用由该第三方独立提供，悦喵Ai不对您对该应用的使用承担任何责任。
                2、您了解并同意，如悦喵Ai对服务或其部分做出调整、中止或终止而对第三方应用服务产生影响，悦喵Ai不承担任何责任。
                3、您通过第三方应用或服务使用时，悦喵Ai可能会调用第三方系统或者通过第三方支持您的使用或访问，使用或访问的结果由该第三方提供。
                4、第三方应用也可以通过调用悦喵Ai提供的接口获得您在悦喵Ai平台的信息，但必须得到您的授权同意。
                九、服务中止或终止
                1、您同意，鉴于本协议约定服务的特殊性，悦喵Ai有权随时中止、终止或致使中止、终止服务或其任何部分；对于免费服务之中止或终止，悦喵Ai无需向您发出通知。
                2、您了解并同意，悦喵Ai可能定期或不定期地对提供网络服务的平台设备、设施和软硬件进行维护或检修，如因此类情况而造成收费服务在合理时间内的中止，悦喵Ai无需承担责任，但应尽可能事先进行通告。
                3、如存在下列违约情形之一，悦喵Ai可立即对用户中止或终止服务，并要求用户赔偿损失：
                3.1用户违反本协议约定的注册义务或使用规范的；
                3.2用户使用收费服务时未按规定支付相应服务费。
                十、隐私政策
                1、您在服务注册的账户具有密码保护功能，以确保您基本信息资料的安全，请您妥善保管账户及密码信息。
                2、悦喵Ai勤勉尽职地采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您存储的信息和通信内容不会被泄漏、毁损或者丢失。悦喵Ai会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，悦喵Ai将在任何时候尽力做到使您的信息不被泄漏、毁损或丢失，但同时也请您注意在信息网络上不存在绝对完善的安全措施，请妥善保管好相关信息。
                3、悦喵Ai有权根据法律规定和业务情况决定您在本软件及服务中数据的最长储存期限、服务器中数据的最大存储空间等，您应当保管好终端、账号及密码，并妥善保管相关信息和内容，悦喵Ai对您自身原因导致的数据丢失或被盗以及在本软件及服务中相关数据的删除或储存失败不承担责任。
                4、悦喵Ai将依法保障您在使用本服务过程中的知情权和选择权，如为提供服务之目的而需要开启收集地理位置、读取通讯录、使用摄像头、启用录音等功能，将向您明示并取得您的同意。
                5、其他隐私条款见《悦喵Ai服务隐私政策条款》。
                十一、知识产权
                1、您了解及同意，除非悦喵Ai另行声明，本协议项下服务包含的所有产品、技术、软件、程序、数据及其他信息（包括但不限于文字、图像、图片、照片、音频、视频、图表、色彩、版面设计、电子文档）的所有知识产权（包括但不限于版权、商标权、专利权、商业秘密等）及相关权利均归悦喵Ai或其关联公司所有。
                2、您应保证，除非取得悦喵Ai书面授权，对于上述权利您不得（并不得允许任何第三人）实施包括但不限于出租、出借、出售、散布、复制、修改、转载、汇编、发表、出版、还原工程、反向汇编、反向编译，或以其它方式发现原始码等的行为。
                3、服务涉及的Logo（包括但不限于“悦喵Ai”）等文字、图形及其组成，以及悦喵Ai其他标识、徽记、产品和服务名称均为悦喵Ai及其关联公司在中国和其它国家的商标，用户未经悦喵Ai书面授权不得以任何方式展示、使用或作其他处理，也不得向他人表明您有权展示、使用、或作其他处理。
                4、您理解并同意授权悦喵Ai在宣传和推广中使用您的名称、商标、标识，但仅限于表明您属于我们的客户或合作伙伴。
                十二、有限责任
                1、服务将按“现状”和按“可得到”的状态提供。悦喵Ai在此明确声明对服务不作任何明示或暗示的保证，包括但不限于对服务的可适用性，没有错误或疏漏，持续性，准确性，可靠性，适用于某一特定用途之类的保证，声明或承诺。
                2、悦喵Ai对服务所涉的技术和信息的有效性，准确性，正确性，可靠性，质量，稳定，完整和及时性均不作承诺和保证。
                3、悦喵Ai系统在下列状况发生时会无法正常运作，所以下列情形中悦喵Ai无法向您提供服务。您对此充分理解并同意悦喵Ai无须就下述情况所导致服务无法正常提供时给您造成的各种影响或损失承担责任：
                3.1我们公告的系统停机维护期间。
                3.2电信设备出现故障不能进行数据传输的。
                3.3发生台风、地震、海啸、洪水、停电、战争、恐怖袭击及其他不可抗力事件造成我们系统障碍不能执行业务的。
                3.4由于黑客攻击、电信部门技术调整或故障、网站升级、第三方方面的问题等原因而造成我们的服务中断或者延迟。
                4、不论是否可以预见，不论是源于何种形式的行为，悦喵Ai不对由以下原因造成的任何特别的，直接的，间接的，惩罚性的，突发性的或有因果关系的损害或其他任何损害（包括但不限于利润或利息的损失，营业中止，资料灭失）承担责任：
                4.1使用或不能使用服务；
                4.2通过服务购买或获取任何产品，样品，数据，信息或进行交易等，或其他可替代上述行为的行为而产生的费用；
                4.3未经授权的存取或修改数据或数据的传输；
                4.4第三方通过服务所作的陈述或行为；
                4.5其它与服务相关事件，包括疏忽等，所造成的损害。
                5、您充分了解并同意，鉴于互联网体制及环境的特殊性，您在服务中分享的信息及个人资料有可能会被他人复制、转载、擅改或做其它非法用途；您在此已充分意识此类风险的存在，并确认此等风险应完全由您自行承担，悦喵Ai对此不承担任何责任。
                6、您了解并同意，在使用服务过程中可能存在来自任何他人的包括威胁性的、诽谤性的、令人反感的或非法的内容或行为或对他人权利的侵犯（包括知识产权）及匿名或冒名的信息的风险，基于服务使用规范所述，该等风险应由您自行承担，悦喵Ai对此不承担任何责任。
                十三、违约责任及赔偿
                1、如果悦喵Ai发现或收到他人举报您有违反本协议约定的，经核实后悦喵Ai有权不经通知随时对相关内容进行删除、屏蔽，并采取包括但不限于限制、暂停、终止您使用账号及服务，限制、暂停、终止您使用部分或全部本服务，追究法律责任等措施。
                2、您同意，由于您通过服务上载、传送或分享之信息、使用本服务其他功能、违反本协议、或您侵害他人任何权利因而衍生或导致任何第三人向悦喵Ai及其关联公司提出任何索赔或请求，或悦喵Ai及其关联公司因此而发生任何损失，您同意将足额进行赔偿（包括但不限于合理律师费）。
                十四、有效通知
                1、悦喵Ai向您发出的任何通知，可采用电子邮件、页面公开区域公告、个人网络区域提示、手机短信或常规信件等方式，且该等通知应自发送之日视为已向用户送达或生效。
                2、您同意，您向悦喵Ai发出的任何通知应发至悦喵Ai对外正式公布告知用户的电子邮件、通信地址、传真号码等联系信息，或使用其他悦喵Ai认可的其他通知方式进行送达。
                十五、争议解决及其他
                1、本协议之解释与适用，以及与本协议有关的争议，均应依照中华人民共和国法律予以处理。双方因本协议发生纠纷，应本着友好协商的原则商议解决，若协商不成，任何一方均有权提交上海仲裁委员会进行仲裁。除非另有规定，仲裁期间双方应当继续履行协议规定的义务。
                2、如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余部份则仍具有法律效力。
                3、悦喵Ai于用户过失或违约时放弃本协议规定的权利的，不得视为其对用户的其他或以后同类之过失或违约行为弃权。
                4、本协议应取代双方此前就本协议任何事项达成的全部口头和书面协议、安排、谅解和通信。
                5、悦喵Ai有权根据业务调整情况将本协议项下的全部权利义务一转移给其关联公司，转让将以本协议规定的方式通知，用户承诺对此不持有异议。
                甲方：（盖章）北京悦喵数字科技有限公司
                日期：
                乙方：（用户签名/盖章）
                日期：
            </p>
        </div>
    );
};

export default Login;