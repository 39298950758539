import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import NP from 'number-precision'
import {
    Form,
    Input,
    Switch,
    Row,
    Col,
    Button,
    Tabs,
    Table,
    message,
    Modal,
    Radio,
    Select,
    Space,
    Skeleton,
    Popconfirm
} from 'antd';
import { submitreconciliationRequest, matchMerchantListRequest, submitreconciliationELERequest, deleteExcelRequest, ListZBRequest, ListDYRequest, submitreconciliationMTRequest } from '../../../api/api'
import { useCallbackState } from '../../../util/useCallbackState'
import Buchang from '../buchang/index'
import Buchangmt from '../buchangmt/index'
import DetailZB from '../detailDR/index'

const Option = Select.Option

const BillingDetails = (props) => {
    const [form] = Form.useForm();
    const { state } = useLocation();
    const [listZB, setListZB] = useState([])
    const [listDY, setListDY] = useState([])
    const [data, setData] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [merchantList, setMerchantList] = useState([])
    const [guanlianList, setGuanlianList] = useState([])
    const [detailVisible, setDetailVisible] = useState(false);
    const [detailVisiblemt, setDetailVisiblemt] = useState(false);
    const [detailVisible1, setDetailVisible1] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(null)
    const [loading, setLoading] = useState(false)
    const [row, setRow] = useState(null)
    const [type, setType] = useState(props && props.data.type)
    let navigate = useNavigate();
    useEffect(() => {
        console.log(props.data);
        settleMerchantList();
    }, [])
    useEffect(() => {
        ListZB()
        ListDY()

    }, [])
    const ListZB = () => {
        (async _ => {
            return await ListZBRequest({ id: props.data && props.data.id, type });
        })().then(res => {
            if (res.code == 0) {
                if (res.data.list.length > 0) {
                    res.data.list.push({
                        totalName: '合计',
                        totalAmount: res.data.totalAmount,
                        totalCount: res.data.totalCount,
                    })
                }
                console.log(res.data.list);
                setListZB(res.data.list)
            } else {
                message.error(res.msg)
            }
        });
    }
    const ListDY = () => {
        (async _ => {
            return await ListDYRequest({ id: props.data && props.data.id, type });
        })().then(res => {
            if (res.code == 0) {
                if (res.data.listFile.length > 0) {
                    res.data.listFile.push({
                        totalName: '合计',
                        totalAmount: res.data.totalAmount,
                        totalCount: res.data.totalCount,
                    })
                }
                setSubmitStatus(res.data.submitStatus)
                setListDY(res.data.listFile)
                setData(res.data)
            } else {
                message.error(res.msg)
            }
        });
    }
    const settleMerchantList = () => {
        (async _ => {
            return await matchMerchantListRequest({
                superMerchantCode: localStorage.appid,
                uploadNo: props.data && props.data.uploadNo
            });
        })().then(res => {
            if (res.code == 0) {
                let arr = []
                if (res.data) {
                    for (let i in res.data) {
                        arr.push({
                            merchantCode: i,
                            merchantName: res.data[i]
                        })
                    };
                }
                setMerchantList(arr)
            } else {
                // message.error(res.msg)
            }
        });
    }

    const submitreconciliation = () => {
        setLoading(true);
        (async _ => {
            return await submitreconciliationRequest({ id: props.data && props.data.id });
        })().then(res => {
            if (res.code == 0) {
                message.success(res.msg)
                props.callback(true)
                props.closeDialog()
                setLoading(false);
            } else {
                setLoading(false);
                message.error(res.msg)
            }
        });
    }
    const submitreconciliationMT = () => {
        setLoading(true);
        (async _ => {
            return await submitreconciliationMTRequest({ id: props.data && props.data.id });
        })().then(res => {
            if (res.code == 0) {
                message.success(res.msg)
                setLoading(false);

                props.callback(true)
                props.closeDialog()
            } else {
                setLoading(false);
                message.error(res.msg)
            }
        });
    }
    const submitreconciliationELE = () => {
        setLoading(true);
        (async _ => {
            return await submitreconciliationELERequest({ id: props.data && props.data.id });
        })().then(res => {
            if (res.code == 0) {
                message.success(res.msg)
                setLoading(false);

                props.callback(true)
                props.closeDialog()
            } else {
                setLoading(false);
                message.error(res.msg)
            }
        });
    }

    const columns1 = [
        {
            title: '单据类型',
            dataIndex: 'totalName',
            key: 'totalName',
            render: (text, row) => (
                <>
                    {
                        row.totalName ?
                            <div>{row.totalName}</div>
                            :
                            <div>
                                <div>{row.busiType == 41 ? '美团入账' : ''}</div>
                                <div>{row.busiType == 42 ? '饿了么入账' : ''}</div>
                                <div>{row.busiType == 43 ? '抖音入账' : ''}</div>
                                <div>{row.busiType == 44 ? '未知' : ''}</div>
                            </div>
                    }
                </>

            ),
        },
        {
            title: '订单号',
            dataIndex: 'remittanceOrderId',
            key: 'remittanceOrderId',
        },
        {
            title: '金额 (元)/笔数',
            dataIndex: 'transAmt',
            key: 'transAmt',
            render: (text, row) => (
                <>
                    {
                        <div>
                            {
                                row.totalName ?
                                    <div>{row.totalAmount}/{row.totalCount}笔</div>
                                    :
                                    <div>{row.transAmt}/1笔</div>
                            }
                        </div>
                    }
                </>

            ),
        },
        {
            title: '操作',
            dataIndex: 'operate',
            key: 'operate',
            fixed: 'right',
            render: (text, row) => {
                return (
                    <div>
                        {
                            !row.totalName ?
                                <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }} onClick={() => {
                                    setRow(row)
                                    setDetailVisible1(true)
                                }} >查看</span> : null
                        }

                    </div>
                )
            }
        },
    ];
    const columns2 = [
        {
            title: '文件名称',
            dataIndex: 'fileName',
            key: 'fileName',
            // width: 200,
            render: (text, row) => (
                <div>{row.totalName ? row.totalName : row.fileName}</div>
            ),
        },
        {
            title: '匹配门店',
            dataIndex: 'matchMerchantNum',
            key: 'matchMerchantNum',
            render: (text, row) => {
                return (
                    <div>
                        {
                            !row.totalName ?
                                <div>
                                    {
                                        row.merchantNum > row.matchMerchantNum ?
                                            <>
                                                <div style={{ color: 'red' }}> {row.matchMerchantNum}/{row.merchantNum}</div>
                                                <div style={{ color: 'red', fontSize: 11 }}> 未匹配到门店ID</div>
                                            </>

                                            :
                                            <span> {row.matchMerchantNum}/{row.merchantNum}</span>
                                    }
                                </div> : null
                        }
                    </div>
                )
            }
        },

        {
            title: '匹配订单',
            dataIndex: 'matchOrderNum',
            key: 'matchOrderNum',
            width: 250,
            render: (text, row) => {
                return (
                    <div>
                        {
                            !row.totalName ?
                                <div>
                                    <span style={{ color: row.orderNum > row.matchOrderNum ? 'red' : '' }}>{row.matchOrderNum}/{row.orderNum}</span>
                                    {
                                        row.orderNum > row.matchOrderNum ?
                                            <a
                                                style={{ marginLeft: 6 }}
                                                onClick={() => {
                                                    row.view = false
                                                    row.mt = props.data && props.data.mt
                                                    row.dy = props.data && props.data.dy
                                                    row.type = props.data && props.data.type
                                                    setRow(row)
                                                    if (props.data && props.data.type == 1 || props.data && props.data.type == 2) {
                                                        setDetailVisiblemt(true)
                                                    }
                                                    if (props.data && props.data.type == 0) {
                                                        setDetailVisible(true)
                                                    }

                                                }}>补偿</a> : null
                                    }
                                </div> : null
                        }
                    </div>
                )
            }
        },
        {
            title: '异常订单',
            dataIndex: 'realErrorCount',
            key: 'realErrorCount',
            // width: 200,
            render: (text, row) => (
                <>
                    {
                        row.realErrorCount > 0 ?
                            <div style={{ color: 'red' }}>{row.realErrorCount}</div>
                            :
                            <div>{row.realErrorCount}</div>
                    }
                </>

            ),
        },
        {
            title: '金额 (元)/笔数',
            dataIndex: 'tuangouNum',
            key: 'tuangouNum',
            // width: 200,
            render: (text, row) => (
                <>
                    {
                        row.totalName ?
                            <div>{row.totalAmount}/{row.totalCount}笔</div>
                            :
                            <div>  {row.orderAmount}/{row.orderNum}笔</div>
                    }
                </>

            ),
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 200,
            align: 'right',
            render: (text, row) => (
                <>
                    {
                        !row.totalName ?
                            <div>
                                <Popconfirm

                                    title="是否确认删除?"
                                    onConfirm={() => {
                                        deleteExcel(row)
                                    }}
                                    onCancel={() => {

                                    }}
                                    okText="是"
                                    cancelText="否"
                                >
                                    <a href="#">删除</a>
                                </Popconfirm>

                                <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }} onClick={() => {
                                    window.open(row.fileUrl)
                                }} >下载</span>
                                <span style={{ color: '#165BD4', cursor: 'pointer', marginLeft: 10 }} onClick={() => {
                                    row.view = true
                                    row.type = props.data && props.data.type
                                    setRow(row)
                                    if (props.data && props.data.type == 2 || props.data && props.data.type == 1) {
                                        setDetailVisiblemt(true)
                                    }
                                    if (props.data && props.data.type == 0) {
                                        setDetailVisible(true)
                                    }
                                }}>查看</span>
                            </div> : null
                    }
                </>
            ),

        },
    ];
    const deleteExcel = (data) => {
        (async _ => {
            return await deleteExcelRequest({
                uploadNo: data.uploadNo,
                superMerchantCode: localStorage.appid,
                type,
            });
        })().then(res => {
            console.log(res);
            if (res.code == 0) {
                ListDY()
            } else {
                message.error(res.msg)
            }
        });
    }

    const detailHandleOk = () => {
        setDetailVisible(false)
    }
    const detailhandleCancel = () => {
        ListZB()
        ListDY()
        setDetailVisible(false)
    }
    const detailHandleOmt = () => {
        setDetailVisible(false)
    }
    const detailhandleCancelmt = () => {
        ListZB()
        ListDY()
        setDetailVisiblemt(false)
    }
    const detailHandleOk1 = () => {
        setDetailVisible1(false)
    }
    const detailhandleCancel1 = () => {
        setDetailVisible1(false)
    }
    const showModal = (data) => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div style={{ background: '#ffffff', paddingBottom: 20 }}>

            <Row align='middle' style={{ width: '100%', padding: '10px 0', marginTop: 10, }}>
                <Row justify='space-between' style={{ width: '100%', background: '#F7F8FA', padding: 10, margin: '0 10px' }}>
                    <Col span={12}>
                        <div style={{ fontSize: 18, fontWeight: 500, }}>日期:{props && props.data.statisticsDate}</div>
                        <div>
                            <span style={{ color: '#969799' }}>店铺名称：</span>
                            <span>{props.data && props.data.superMerchantName}</span>
                        </div>

                    </Col>
                </Row>

            </Row>
            <Row justify='space-around' style={{ background: '#F7F8FA', padding: 20, margin: 10, marginTop: 0 }}>
                <Col span={8}>
                    <div>
                        <span>银行入账(元)</span>
                    </div>
                    <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}>
                        <span>{data && data.zhongBangAmt}</span>
                    </div>
                </Col>
                {
                    props.data && props.data.type == 0 ?
                        <Col span={8}>
                            <div><span>抖音导入(元)</span></div>
                            <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}> <span>{data && data.dyAmt}</span></div>
                        </Col> : null
                }
                {
                    props.data && props.data.type == 1 ?
                        <Col span={8}>
                            <div><span>美团导入(元)</span></div>
                            <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}> <span>{data && data.dyAmt}</span></div>
                        </Col>
                        : null
                }

                {
                    props.data && props.data.type == 2 ?
                        <Col span={8}>
                            <div><span>饿了么导入(元)</span></div>
                            <div style={{ fontSize: 24, fontWeight: 500, marginTop: 10 }}> <span>{data && data.dyAmt}</span></div>
                        </Col> : null
                }

                <Col span={8}>
                    <div><span>差额(元)</span></div>
                    <Row align='middle' style={{ marginTop: 10 }}>
                        <span style={{ fontSize: 24, fontWeight: 500 }}>
                            <span>{data && data.balanceAmt}</span>
                        </span>

                    </Row>

                </Col>
            </Row>
            <Row align='middle' style={{ background: '#F7F8FA', padding: 10, margin: 10 }}>
                <div style={{ background: '#145BD5', height: 14, width: 3 }}></div><div style={{ marginLeft: 10, fontWeight: 500 }}>银行入账</div>
            </Row>
            <Row style={{ padding: "0px 10px", marginTop: 16 }}>
                <Col span={24}>
                    <Table
                        locale={{
                            emptyText: "没有更多数据了"
                        }}
                        columns={columns1}
                        rowKey='id'
                        dataSource={listZB}
                        // scroll={{ x: 2000 }}
                        pagination={false}
                    />
                </Col>

            </Row>
            {
                props.data && props.data.type == 1 ?
                    <Row align='middle' style={{ background: '#F7F8FA', padding: 10, margin: 10 }}>
                        <div style={{ background: '#145BD5', height: 14, width: 3 }}></div><div style={{ marginLeft: 10, fontWeight: 500 }}>美团导入</div>
                    </Row> : null
            }
            {
                props.data && props.data.type == 0 ?
                    <Row align='middle' style={{ background: '#F7F8FA', padding: 10, margin: 10 }}>
                        <div style={{ background: '#145BD5', height: 14, width: 3 }}></div><div style={{ marginLeft: 10, fontWeight: 500 }}>抖音导入</div>
                    </Row> : null
            }
            {
                props.data && props.data.type == 2 ?
                    <Row align='middle' style={{ background: '#F7F8FA', padding: 10, margin: 10 }}>
                        <div style={{ background: '#145BD5', height: 14, width: 3 }}></div><div style={{ marginLeft: 10, fontWeight: 500 }}>饿了么导入</div>
                    </Row> : null
            }

            <Row style={{ padding: "0px 10px", marginTop: 16 }}>
                <Col span={24}>
                    <Table
                        locale={{
                            emptyText: "没有更多数据了"
                        }}
                        columns={columns2}
                        rowKey='id'
                        dataSource={listDY}
                        // scroll={{ x: 2000 }}
                        pagination={false}
                    />
                </Col>

            </Row>
            <Row style={{ margin: '10px', marginTop: 20, align: 'middle' }}>
                <Col><Button type="primary" htmlType="submit"
                    disabled={submitStatus == 2}
                    loading={loading}
                    onClick={() => {
                        if (props.data && props.data.type == 1) {
                            submitreconciliationMT()
                        }
                        if (props.data && props.data.type == 0) {
                            submitreconciliation()
                        }
                        if (props.data && props.data.type == 2) {
                            submitreconciliationELE()
                        }

                    }}>确认账单</Button> </Col>
                <Col><div style={{ color: '#999999', fontSize: 14, marginTop: 5, marginLeft: 16 }}>确认无误后将进入钱包待结算.</div></Col>

            </Row>
            <Modal
                title={row && row.view ? '查看' : "未匹配数据"}
                visible={detailVisible}
                onOk={detailHandleOk}
                onCancel={detailhandleCancel}
                width={1200}
                footer={null}
                key={Math.random(10)}
            >
                <Buchang data={row}
                    callback={(state) => {
                        if (state) {
                            // excelList()
                            ListZB()
                            ListDY()
                        }
                    }}
                    closeDialog={() => {
                        setDetailVisible(false)
                    }}
                />

            </Modal>
            <Modal
                title={row && row.view ? '查看' : "未匹配数据"}
                visible={detailVisiblemt}
                onOk={detailHandleOmt}
                onCancel={detailhandleCancelmt}
                width={1200}
                footer={null}
                key={Math.random(10)}
            >
                <Buchangmt data={row}
                    callback={(state) => {
                        if (state) {
                            ListZB()
                            ListDY()
                        }
                    }}
                    closeDialog={() => {
                        setDetailVisiblemt(false)
                    }}
                />

            </Modal>
            <Modal
                title="详情"
                visible={detailVisible1}
                onOk={detailHandleOk1}
                onCancel={detailhandleCancel1}
                width={1200}
                footer={null}
            >
                <DetailZB data={row} />
            </Modal>
        </div>

    )
}
export default BillingDetails;
